// for Docker nginx
// [ 開發吃這個環境變數 ]  *********************************************
export const environment = {
  production: false,
  // tslint:disable-next-line:quotemark
  // tslint:disable-next-line:no-string-literal
  isEnvironment: 'Environment.ts => ts',
  // apiUrl: 'http://10.2.107.198:7777/smartFactory/apis',
  // apiUrl: 'http://10.2.201.207:5555/smartFactory/apis',
  apiUrl: 'http://10.10.35.40:4001/smartFactory/apis',
  // apiUrl: 'http://10.2.106.155:5555/smartFactory/apis',
  // apiUrl: 'http://localhost:5555/smartFactory/apis',

  dotnetApiUrl: 'http://10.10.34.52/api',

  // tslint:disable-next-line:no-string-literal
  apiCctvIpsfUrl: window['env'].apiCctvIpsfUrl || 'default',
  // tslint:disable-next-line:no-string-literal
  cctvSmtWorkShop01: window['env'].cctvSmtWorkShop01 || 'default',
  // tslint:disable-next-line:no-string-literal
  cctvAisleSideDoor02: window['env'].cctvAisleSideDoor02 || 'default',

  // tslint:disable-next-line:no-string-literal
  gfAtePath: window['env'].gfAteDataUrl || 'default',
  // tslint:disable-next-line:no-string-literal
  gfSmtPath: window['env'].gfSmtDataUrl || 'default',
  // tslint:disable-next-line:no-string-literal
  gfLoadingPath: window['env'].gfLoadingDataUrl || 'default',

  // tslint:disable-next-line:no-string-literal
  debug: window['env'].debug || false
};

