import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { DataFormatService } from '../_services/data-format.service';
import { SpinnerService } from '../_services/spinner.service';
import { interval } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

// angular-material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// forTestData
import { ForTestDataService } from '../_services/for-test-data.service';

// common
import * as moment from 'moment';
import { Chart } from '../../../node_modules/chart.js';

@Component({
  selector: 'app-drop-rate-overview',
  templateUrl: './drop-rate-overview.component.html',
  styleUrls: ['./drop-rate-overview.component.scss']
})
export class DropRateOverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public clock: RealTimeClockService,
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public testData: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,
  ) {
    this.isSvLoading.loading = true;
  }

  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  apiEndTime = moment().startOf('hour');
  displayTime = moment().startOf('hour');
  diffDays = 0;

  MOCK_DATA = this.testData.dropRateMockData;
  apiResData = [];

  chartArray = [];
  lineColor = ['#11e834', '#ffe500', '#00ffe0'];
  lineName = ['5S-A', '5S-B', '6S-A', '6S-B', '6S-C', '6S-D'];

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  private rxTimerStop() {
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 intervalSeconds Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.apiEndTime = moment().startOf('hour');
      this.displayTime = moment(this.apiEndTime).subtract(this.diffDays, 'days');
      this.isSvLoading.loading = true;
      this.getApiData(this.displayTime.valueOf());
    });
  }

  pickDate(sign: string) {
    this.apiEndTime = moment().startOf('hour');
    if (sign === '-') {
      this.diffDays++;
    }
    if (sign === '+') {
      this.diffDays--;
    }
    this.isSvLoading.loading = true;
    this.displayTime = moment(this.apiEndTime).subtract(this.diffDays, 'days');
    this.getApiData(this.displayTime.valueOf());
  }

  getColor(index) {
    return index >= this.lineColor?.length ? null : this.lineColor[index];
  }

  ngOnInit(): void {
    this.apiResData = this.MOCK_DATA;
    this.apiResData = this.addEmptyData(this.apiResData);
    setTimeout(() => { this.buildChartData(this.apiResData); }, 50);
    // this.rxTimerStart();
  }

  ngAfterViewInit(): void {
    // this.buildChartData(this.apiResData);
    this.getApiData(this.apiEndTime.valueOf());
  }

  ngOnDestroy(): void {
    if (this.isSvLoading.loading) { this.isSvLoading.loading = false; }
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  addEmptyData(resObj) {
    return resObj.map(item => {
      if (item.compare.length < 3) {
        item.compare.push({
          equipName: 'N/A',
          monthPn: null,
          monthDpr: null,
          weekPn: null,
          weekDpr: null,
          dayPn: null,
          dayDpr: null,
        });
      }
      if (item.nowStatus.length < 3) {
        item.nowStatus.push({
          equipInx: 2,
          equipName: 'N/A',
          dpr: null,
          dpn: null,
          pn: null,
        });
      }
      if (item.dayHistory.length < 3) {
        item.dayHistory.push({
          equipName: 'N/A',
          dpr: 'N/A',
          dpn: 'N/A',
          pn: 'N/A',
        });
      }
      return item;
    });
  }

  buildChartData(res) {
    if (this.chartArray.length > 0) { this.chartArray.forEach(e => e.destroy()); }
    res.forEach((item) => {
      const lineData = [];
      item.chart.forEach((equip, index) => {
        const obj = {
          fill: false,
          label: equip?.equipName,
          borderColor: this.getColor(index),
          backgroundColor: this.getColor(index),
          tension: 0.1,
          data: equip?.data.map((chart) => {
            if (chart.value) { chart.value = chart.value?.toFixed(); }
            return { x: chart.time, y: chart.value };
          })
        };
        lineData.push(obj);
        // console.log('obj:', obj);
      });
      this.initChart(item?.lineName, { datasets: lineData });
      // console.log('lineData:', lineData);
    });
  }

  initChart(id, dataObj) {
    this.chartArray.push(new Chart(id, {
      type: 'line',
      data: dataObj,
      options: {
        tooltips: {
          mode: 'label',
          titleAlign: 'center',
          intersect: false,
          titleFontSize: 16,
          bodyFontSize: 16,
          position: 'nearest'
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 70,
            left: 10,
            top: 15,
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              type: 'time',
              time: {
                unit: false,
                stepSize: 1,
                displayFormats: {
                  hour: 'HH',
                },
                tooltipFormat: 'YYYY/MM/DD HH:mm'
              },
              ticks: {
                min: 0,
                // max: 24,
                fontSize: 16,
                stepSize: 1,
                fontColor: '#BABABA',
                padding: 2,
                callback(label, index, labels) {
                  return label.replace('AM', '').replace('PM', '');
                }
              },
              // 設定 底線
              gridLines: {
                display: false,
                color: '#868686',
                drawBorder: false,
              },
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 1050,
                // stepSize: 350,
                maxTicksLimit: 5,
                fontSize: 16,
                fontColor: '#BABABA',
                padding: 10,
              },
              // 設定 底線
              gridLines: {
                display: true,
                color: '#868686',
                drawBorder: false,
                drawTicks: true,
                lineWidth: 1,
              },
            }
          ]
        },

        plugins: {
          p1: false,   // disable plugin 'p1' for this instance
        },
      },
      plugins: [{
        beforeDraw: (chart) => {
          const ctx = chart.chart.ctx;
          ctx.restore();
          ctx.font = 14 + 'px Roboto';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#BABABA';
          ctx.fillText('(o’clock)', chart.chartArea.right + 11, chart.chartArea.bottom + 22);
          ctx.save();
          ctx.restore();
          ctx.font = 14 + 'px Roboto';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#BABABA';
          ctx.fillText('(ppm)', chart.chartArea.left - 56, chart.chartArea.bottom + 15);
          ctx.save();
        }
      }],
    }));
  }

  getApiData(time) {
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    this.renewedTime = moment().valueOf();
    this.apiResData = [];
    // time='2022-11-02';
    this.apiSvc.getDropRateOverviewData(time).pipe(
      timeout(this.intervalSeconds * 0.9 * 1000),
      tap(res => {
        console.log('getDropRateOverviewData:', res);
        this.snapshotTime = this.renewedTime;
        this.apiResData = res;
        this.apiResData = this.addEmptyData(this.apiResData);
        setTimeout(() => { this.buildChartData(this.apiResData); }, 50);
      }),
    ).subscribe({
      error: error => {
        this.isSvLoading.loading = false;
        console.log('catchError: 錯誤 - ', error);
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.getApiData(this.displayTime.valueOf());
  }

  // Download pop-up event
  onClickDownload(filter: string) {
    console.log('onClickDownload: ', filter);
    this.dialog.open(DropRateOverviewDownloadDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
      height: '280px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        option: filter
      },
    });
  }
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './dialog/app-download-dialog.component.html',
  styleUrls: ['./dialog/app-download-dialog.component.scss']
})
export class DropRateOverviewDownloadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DropRateOverviewDownloadDialogComponent>,
    public apiSvc: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: { option: string }) { }

  ngOnInit() { }

  onClickDownload(option: string) {
    // call download api
    return window.open(`${this.apiSvc.APIUrl}/download/dropRate`);
  }
}
