<div class="container-fluid mo_quality">
  <div class="title_group">
    <h1 class="mo_quality_title">
      <a
        class="mo_table_link"
        [routerLink]="['/smartFactory/web/quality-overview']"
      >
        <img
          class="btn_back"
          src="../../assets/img/smart_factory/btn_back.svg"
          alt="arrow"
        />
      </a>
      MO Quality
    </h1>
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
        <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!isLogin">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="isLogin">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>

  <div class="mo_table_group">
    <div class="mo_table mo_table_moNo" style="flex-grow: 1.8">
      <div class="mo_table_title">MO No.</div>
      <div class="mo_table_data">{{ urlParamsObj.sn }}</div>
    </div>
    <div class="mo_table mo_table_partNo" style="flex-grow: 1.8">
      <div class="mo_table_title">Part No.</div>
      <div class="mo_table_data">{{ urlParamsObj.partNo }}</div>
    </div>
    <div class="mo_table mo_table_status" style="flex-grow: 1.8">
      <div class="mo_table_title">Status</div>
      <div class="mo_table_data">{{ urlParamsObj.status }}</div>
    </div>
    <div class="mo_table mo_table_startTime" style="flex-grow: 1.8">
      <div
        class="mo_table_title"
        matTooltipPosition="below"
        [matTooltip]="'Start Time'"
      >
        Start T.
      </div>
      <div class="mo_table_data">
        {{
          urlParamsObj.startTime === "-"
            ? urlParamsObj.startTime
            : (urlParamsObj.startTime | date: "yyyy/M/dd HH:mm")
        }}
      </div>
    </div>
    <div class="mo_table mo_table_endTime" style="flex-grow: 1.8">
      <div
        class="mo_table_title"
        matTooltipPosition="below"
        [matTooltip]="'End Time'"
      >
        End T.
      </div>
      <div class="mo_table_data">
        {{
          urlParamsObj.endTime === "-"
            ? urlParamsObj.endTime
            : (urlParamsObj.endTime | date: "yyyy/M/dd HH:mm")
        }}
      </div>
    </div>
    <div class="mo_table mo_table_inputQty" style="flex-grow: 0.8">
      <div
        class="mo_table_title"
        matTooltipPosition="below"
        [matTooltip]="'Input Qty'"
      >
        INP
      </div>
      <div class="mo_table_data">
        {{
          urlParamsObj.inputQty === "-"
            ? urlParamsObj.inputQty
            : (urlParamsObj.inputQty | number)
        }}
      </div>
    </div>
    <div class="mo_table mo_table_defectQty" style="flex-grow: 0.8">
      <div class="mo_table_title">Defect Qty</div>
      <div class="mo_table_data">
        {{
          urlParamsObj.defectQuantity === "-"
            ? urlParamsObj.defectQuantity
            : (urlParamsObj.defectQuantity | number)
        }}
      </div>
    </div>
    <div class="mo_table mo_table_avgrty" style="flex-grow: 0.8">
      <div class="mo_table_title">Avg RTY</div>
      <div class="mo_table_data">
        {{
          urlParamsObj.avgRty === "-"
            ? urlParamsObj.avgRty
            : (+urlParamsObj.avgRty * 100 | number: "0.2-2")
        }}%
      </div>
    </div>
    <div class="mo_table mo_table_lowestYield" style="flex-grow: 1.8">
      <div class="mo_table_title">Lowest Yield</div>
      <div class="mo_table_data">
        {{ urlParamsObj.lowestYield === "-" ? "-" : urlParamsObj.lowestYield }}
      </div>
    </div>
  </div>

  <div class="mo_tab_group">
    <input
      checked
      type="radio"
      name="radio_control"
      id="radio1"
      class="penal_control"
    />
    <input
      type="radio"
      name="radio_control"
      id="radio2"
      class="penal_control"
    />
    <input
      type="radio"
      name="radio_control"
      id="radio3"
      class="penal_control"
    />

    <div class="tab_group">
      <label
        for="radio1"
        (click)="
          isSvLoading.loading = true; getPerformanceData(urlParamsObj.sn)
        "
      >
        Station Performance
      </label>

      <!-- <label
        for="radio2"
        (click)="getHourlyData(isOrderNumber, begingTime, before24Time)"
      >
        Hourly Data
      </label> -->

      <label
        for="radio3"
        (click)="isSvLoading.loading = true; getDefectStationNameData()"
      >
        Defect Ranking
      </label>
    </div>

    <div class="conten_group">
      <!-- Station Performance % -->
      <div class="content content_performance">
        <!-- API 回傳沒資料 -->
        <div class="performance_no_data" *ngIf="getStationDataNoData">
          <span>No Data</span>
        </div>

        <!-- ATE 子層 -->
        <div
          class="performance_child_item_box"
          [ngClass]="{ performance_child_item_box_show: isStationChildShowing }"
        >
          <div class="item_box_content">
            <p class="item_title">
              <img
                src="../../assets/img/smart_factory/btn_back.svg"
                alt="arrow"
                class="btn_back"
                (click)="isStationChildShowing = !isStationChildShowing"
              />
              {{ isStationChildTitle }}
            </p>
            <div class="item_box_groups">
              <!-- API 回傳沒資料 -->
              <div
                class="performance_no_data"
                *ngIf="getStationChildDataNoData"
              >
                <span>No Data</span>
              </div>

              <div
                class="performance_item"
                *ngFor="let item of stationPerformanceChildDataArr"
              >
                <div class="station_title">
                  {{ item.stationName }}
                </div>
                <div class="text_box">
                  <div class="text_group">
                    <div class="text_title">Total Qty</div>
                    <div class="text_value">
                      {{ item.totalQty | number }}
                    </div>
                  </div>
                  <div class="text_group">
                    <div class="text_title">FPY</div>
                    <div class="text_value">
                      {{ item.fpy | number: "0.2-2" }}
                      %
                    </div>
                  </div>
                </div>

                <div class="text_box">
                  <div class="text_group">
                    <div class="text_title">Pass Qty</div>
                    <div class="text_value">
                      {{ item.passQty | number }}
                    </div>
                  </div>
                </div>

                <div class="text_box">
                  <div class="text_group">
                    <div class="text_title">Defect Qty</div>
                    <div class="text_value">
                      {{ item.defectQty | number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ATE 父層 -->
        <div class="performance_father_item_box">
          <div
            class="performance_item"
            *ngFor="let item of stationPerformanceDataArr"
            (click)="
              openStationChildContent(item.functionName);
              isStationChildTitle = item.functionName
            "
          >
            <div class="station_title">
              {{ item.functionName }}
            </div>
            <div class="text_box">
              <div class="text_group">
                <div class="text_title">Total Qty</div>
                <div class="text_value">
                  {{ item.totalQty | number }}
                </div>
              </div>
              <div class="text_group">
                <div class="text_title">FPY</div>
                <div class="text_value">
                  {{ item.fpy | number: "0.2-2" }}
                  %
                </div>
              </div>
            </div>

            <div class="text_box">
              <div class="text_group">
                <div class="text_title">Pass Qty</div>
                <div class="text_value">
                  {{ item.passQty | number }}
                </div>
              </div>
              <!-- <div class="text_group">
              <div class="text_title">DPPM</div>
              <div class="text_value">
                0
                {{ item.dppm | number }}
              </div>
            </div> -->
            </div>

            <div class="text_box">
              <div class="text_group">
                <!-- <div class="text_title">Fail Qty</div> -->
                <div class="text_title">Defect Qty</div>
                <div class="text_value">
                  {{ item.defectQty | number }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Hourly Data -->
      <div class="content content_data">
        <div class="hourly_no_data" *ngIf="getHourlyDataNoData">
          <span>No Data</span>
        </div>
        <div class="data_chart_group" *ngFor="let item of chartHourlyDataArr">
          <div class="data_chart_title">{{ item.chartTitle }}</div>
          <div class="data_chart_img">
            <canvas
              baseChart
              height="85"
              [datasets]="item.barChartData"
              [labels]="item.barChartLabels"
              [options]="item.barChartOptions"
              [legend]="item.barChartLegend"
              [chartType]="item.barChartType"
              [colors]="item.chartColors"
              (chartHover)="item.chartHovered($event)"
              (chartClick)="item.chartClicked($event)"
            >
            </canvas>
            <div class="chart_label_group">
              <p class="chart_output">( Output )</p>
              <p class="chart_last24hr">( Last 24hr )</p>
              <p class="chart_yield">( Yield )</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Defect Ranking -->
      <div class="content content_defect_ranking">
        <div class="data_chart_group">
          <!-- select -->
          <div class="defect_station_select">
            <label>Station</label>
            <select (change)="callRankingTop10($event.target.value)">
              <!-- <option selected="selected">
                {{ isDefectFirstStationName }}
              </option> -->
              <option
                *ngFor="let item of rankStationName"
                [selected]="item === defectRankingStationName"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <!-- Ranking group -->
          <div class="defect_ranking_groups">
            <div class="ranking_title_groups">
              <div class="title_ranking_group">
                <!-- <div class="title_ranking">Ranking</div> -->
                <div class="title_cause">Event</div>
                <div class="title_count">Count</div>
                <div class="title_percent">%</div>
              </div>

              <div
                class="title_ranking_group"
                *ngIf="dataDefectRankOutstripFive"
              >
                <!-- <div class="title_ranking">Ranking</div> -->
                <div class="title_cause">Event</div>
                <div class="title_count">Count</div>
                <div class="title_percent">%</div>
              </div>
            </div>

            <ul class="ranking_bar_groups">
              <li
                class="ranking_bar"
                *ngFor="let item of rankBar; let idx = index"
              >
                <div class="bar_number">{{ idx + 1 }}</div>

                <div
                  class="bar_color"
                  [style]="'width:' + item.defectPercentage + '%'"
                ></div>
                <div class="bar_cause">
                  <p *ngFor="let name of item?.causeName">{{ name }}</p>
                </div>
                <div class="bar_count">{{ item.defectQty }}</div>
                <div class="bar_percent">
                  {{ item.defectPercentage | number: "0.2-2" }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
