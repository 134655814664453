<div style="padding-left: 15px">
  <h1 class="mat-body-1 color-silver" style="margin-bottom: 10px">標準工時</h1>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">Cycle Time</h3>
  <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
    {{
      EfficiencyData.cycleTime === "-"
        ? "-"
        : (EfficiencyData.cycleTime | number: "1.0-2")
    }}s
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">Output Qty</h3>
  <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
    {{
      EfficiencyData.output === "-"
        ? "-"
        : (EfficiencyData.output | number: "1.0-2")
    }}
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Standard IE Changeover
  </h3>
  <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
    {{
      EfficiencyData.stdLineChangeTime === "-"
        ? "-"
        : (EfficiencyData.stdLineChangeTime | number: "1.0-2")
    }}s
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">Operator</h3>
  <div
    class="mat-body-2 color-silver"
    style="margin-bottom: 10px"
    [style.color]="EfficiencyData.isOperatorMes ? '#ff3a3a' : '#bababa'"
  >
    {{
      EfficiencyData.operator === "-"
        ? "-"
        : (EfficiencyData.operator | number: "1.0-2")
    }}
  </div>
  <h1
    class="mat-body-1 color-silver"
    style="padding-top: 5px; margin-bottom: 10px"
  >
    實際工時
  </h1>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Duration ({{ this.dataFormatSvc.secToDDhhmmss(totalDurationTime) }})
  </h3>
  <div *ngFor="let item of EfficiencyData.durationList">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Rest Time (Act) ({{ this.dataFormatSvc.secToDDhhmmss(totalRestTimeInAct) }})
  </h3>
  <div *ngFor="let item of EfficiencyData.restTimeInAct">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Rest Time (Changeover) ({{
      this.dataFormatSvc.secToDDhhmmss(totalRestTimeInChangeover)
    }})
  </h3>
  <div *ngFor="let item of EfficiencyData.restTimeInChangeover">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Changeover ({{ this.dataFormatSvc.secToDDhhmmss(totalChangeOverTime) }})
  </h3>
  <div *ngFor="let item of EfficiencyData.changeOverList">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Non-Production (Act) ({{
      this.dataFormatSvc.secToDDhhmmss(totalNonProdTimeTimeByAct)
    }})
  </h3>
  <div *ngFor="let item of EfficiencyData.nonProdTimeListByAct">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Non-Production (Changeover) ({{
      this.dataFormatSvc.secToDDhhmmss(totalNonProdTimeTimeByChangeover)
    }})
  </h3>
  <div *ngFor="let item of EfficiencyData.nonProdTimeListByChangeover">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    RD Production (D09) ({{
      this.dataFormatSvc.secToDDhhmmss(totalRdProductionTime)
    }})
  </h3>
  <div *ngFor="let item of EfficiencyData.rdProduction">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">
    Non-Barcode Production (N08) ({{
      this.dataFormatSvc.secToDDhhmmss(totalNonBarcodeProductionTime)
    }})
  </h3>
  <div *ngFor="let item of EfficiencyData.nonBarcodeProduction">
    <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
      {{ item.from | date: "yyyy/MM/dd HH:mm:ss a" }} ~
      {{ item.to | date: "yyyy/MM/dd HH:mm:ss a" }} ({{
        this.dataFormatSvc.secToDDhhmmss(item.duration)
      }})
    </div>
  </div>
  <h3 class="mat-body-1 color-white" style="margin-bottom: 10px">M-DownTime</h3>
  <div class="mat-body-2 color-silver" style="margin-bottom: 10px">
    {{ EfficiencyData.machineDownTime }}s
  </div>
</div>
