<div
  (window:resize)="onWindowResize($event)"
  [ngStyle]="{
    width: getInnerWidth + 'px',
    height: getInnerHeight + 'px',
    overflow: 'hidden',
    position: 'relative'
  }"
>
  <div
    class="main_board"
    style="transform-origin: left top; transform: scale(0.5)"
    [style.transform]="'scale(' + scaleWindowSize + ')'"
  >
    <!-- Title & menu -->
    <div class="title_group">
      <div style="display: flex">
        <img
          style="width: 114px; margin-right: 24px"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
        <h1 class="mat-title color-silver">AcBel Plant Overview</h1>
      </div>

      <div class="time">
        <h3 class="mat-subheading-2 color-silver">
          {{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}
        </h3>
        <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
          <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
        </button>
        <div class="login-menu" *ngIf="isMenuOpen">
          <!-- <button routerLink="/menu">
            <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
            Dashboard Menu
          </button> -->
          <button
            onclick="window.open('https://1e.tdc.tw/smartFactory/web/equipment-efficiency-overview','_blank')"
          >
            <div class="info-img">1E</div>
            Production Efficiency Overview
          </button>
          <button
            onclick="window.open('https://www-smart-building.apitech.com.tw/admin/environment/','_blank')"
          >
            <div class="info-img">3E</div>
            Environment Overview
          </button>
          <button
            onclick="window.open('https://www-smart-building.apitech.com.tw/admin/waterMeter/','_blank')"
          >
            <div class="info-img">3E</div>
            Water Use Overview
          </button>
          <button
            onclick="window.open('https://www-smart-building.apitech.com.tw/admin/energy/','_blank')"
          >
            <div class="info-img">3E</div>
            Power Overview
          </button>
          <button
            onclick="window.open('https://www-smart-building.apitech.com.tw/admin/electricity/','_blank')"
          >
            <div class="info-img">3E</div>
            Electricity Meter Overview
          </button>
          <button
            onclick="window.open('http://10.10.35.35:3000/warehouse-overview','_blank')"
          >
            <div class="info-img">eWH</div>
            Warehouse Overview
          </button>
          <button
            onclick="window.open('http://10.10.35.35:3000/material-picking-overview','_blank')"
          >
            <div class="info-img">eWH</div>
            Material-picking Overview
          </button>
          <button
            onclick="window.open('http://10.10.35.35:3000/agv-cloud/vehicle-0001','_blank')"
          >
            <div class="info-img">eWH</div>
            AGV-cloud
          </button>
          <!--<button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
            <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
          </button>
          <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
            <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign
            out
          </button>-->
        </div>
      </div>
    </div>

    <!-- container -->
    <ng-container
      *ngIf="
        apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer');
        else PermissionDenied
      "
    >
      <div class="container_box">
        <!-- 廠區圖區塊 -->
        <div class="floor_box">
          <!-- Alarms box -->
          <div class="alarms_box">
            <div class="alarm_title">
              <h4 class="mat-body-1 color-silver">Alarms</h4>
              <!-- <button>
                <img
                  style="width: 30px"
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button> -->
            </div>

            <!-- alarm group -->
            <div class="alarm_group">
              <!-- item -->
              <div class="alarm_item">
                <!-- light & line name -->
                <div class="item_title">
                  <span class="light_red"></span>
                  <p class="mat-caption color-white">SS-A-SP-ElAi</p>
                </div>

                <!-- alarm logTime & btn icon group -->
                <div class="item_contain">
                  <!-- alarm logTime -->
                  <p class="mat-caption color-white">2023/08/01 12:00</p>
                  <!-- btn icon group -->
                  <div class="item_icon_group">
                    <!-- Button report first -->
                    <div
                      class="icon_btn"
                      (click)="
                        this.OpenPopupObj.isReport = !this.OpenPopupObj.isReport
                      "
                    ></div>

                    <!-- Button report again -->
                    <button
                      class="icon_btn"
                      (click)="
                        this.OpenPopupObj.isReportAgain =
                          !this.OpenPopupObj.isReportAgain;
                        (this.OpenPopupObj.isRemove === true
                          ? !this.OpenPopupObj.isRemove
                          : this.OpenPopupObj.isRemove)
                      "
                    ></button>
                    <!-- <div class="icon_btn icon_floor"></div> -->

                    <!-- Button location -->
                    <button
                      class="icon_btn icon_floor"
                      [disabled]="isAlarmShining"
                    ></button>

                    <!-- Button remove -->
                    <button
                      class="icon_btn icon_remove"
                      (click)="
                        this.OpenPopupObj.isRemove = !this.OpenPopupObj.isRemove
                      "
                    ></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Popup => Remove alarm item -->
            <div
              class="popup_style"
              [ngClass]="{
                popup_isRemove: OpenPopupObj.isRemove
              }"
            >
              <div class="pop_title mb_14">
                <p class="mat-caption color-white text_des text_a_c">
                  Remove this alarm?
                </p>
              </div>

              <div class="text_group mb_14">
                <h4 class="mat-body-2 color-silver">Alarm time</h4>
                <h5 class="mat-caption color-white">
                  {{ reportJSON.logTime | date: "yyyy/MM/dd HH:mm" }}
                </h5>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Alarm item</div>
                <div class="mat-caption color-white">{{ reportJSON.name }}</div>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Last report time</div>
                <div
                  class="mat-caption color-white"
                  *ngIf="reportJSON.reportTime === null"
                >
                  -
                </div>
                <div
                  class="mat-caption color-white"
                  *ngIf="reportJSON.reportTime !== null"
                >
                  {{ reportJSON.reportTime | date: "YYYY/MM/dd HH:mm" }}
                </div>
              </div>

              <!-- Button -->
              <div class="button_group">
                <div
                  class="b_style b_yes"
                  (click)="
                    this.OpenPopupObj.isRemove = !this.OpenPopupObj.isRemove
                  "
                >
                  Yes
                </div>
                <div
                  class="b_style b_no"
                  (click)="
                    this.OpenPopupObj.isRemove = !this.OpenPopupObj.isRemove
                  "
                >
                  No
                </div>
              </div>
            </div>

            <!-- Popup  => Report again -->
            <div
              class="popup_style"
              [ngClass]="{ popup_isReportAgain: OpenPopupObj.isReportAgain }"
            >
              <div class="pop_title mb_14">
                <p class="mat-caption color-white text_des text_a_c">
                  Report this alarm again?
                </p>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Alarm time</div>
                <div class="mat-caption color-white">
                  {{ reportJSON.logTime | date: "YYYY/MM/dd HH:mm" }}
                </div>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Alarm item</div>
                <div class="mat-caption color-white">{{ reportJSON.name }}</div>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Last report time</div>
                <div class="mat-caption color-white">
                  {{ reportJSON.reportTime | date: "YYYY/MM/dd HH:mm" }}
                </div>
              </div>

              <!-- Button -->
              <div class="button_group">
                <div
                  class="b_style b_yes"
                  (click)="
                    alarmReport(reportJSON.sourceId, reportJSON.dbLogTime)
                  "
                >
                  Yes
                </div>
                <div
                  class="b_style b_no"
                  (click)="
                    this.OpenPopupObj.isReportAgain =
                      !this.OpenPopupObj.isReportAgain
                  "
                >
                  No
                </div>
              </div>
            </div>

            <!-- Popup => Report this alarm -->
            <div
              class="popup_style"
              [ngClass]="{ popup_isReport: OpenPopupObj.isReport }"
            >
              <div class="pop_title mb_14">
                <p class="mat-caption color-white text_a_c">
                  Report this alarm?
                </p>
                <p class="text_tip c_white"></p>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Alarm time</div>
                <div class="mat-caption color-white">
                  {{ reportJSON.logTime | date: "YYYY/MM/dd HH:mm" }}
                </div>
              </div>

              <div class="text_group mb_14">
                <div class="mat-body-2 color-silver">Alarm item</div>
                <div class="mat-caption color-white">{{ reportJSON.name }}</div>
              </div>

              <!-- Button -->
              <div class="button_group">
                <div
                  class="b_style b_yes"
                  (click)="
                    alarmReport(reportJSON.sourceId, reportJSON.dbLogTime)
                  "
                >
                  Yes
                </div>
                <div
                  class="b_style b_no"
                  (click)="
                    this.OpenPopupObj.isReport = !this.OpenPopupObj.isReport
                  "
                >
                  No
                </div>
              </div>
            </div>
          </div>

          <!-- Kanban group -->
          <div class="kanban_group mat-subheading-2 color-silver">
            <mat-chip-list
              class="mat-chip-list-stacked"
              aria-label="Kanban selection title"
            >
              <mat-chip
                disableRipple="true"
                class="width-fit selection_title"
                style="border: 2px solid #bababa"
                (click)="isKanbanSwitchClick = !isKanbanSwitchClick"
                >Select Kanban<span class="dropdown_btn">
                  <img
                    class="select_down_btn"
                    src="../../assets/img/smart_factory/select_down.svg"
                    alt="select_down" /></span
              ></mat-chip>
            </mat-chip-list>
            <mat-chip-list
              [ngStyle]="{
                visibility: isKanbanSwitchClick ? 'visible' : 'hidden',
                opacity: isKanbanSwitchClick ? 1 : 0
              }"
              class="mat-chip-list-stacked"
              aria-label="Kanban selection"
            >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/equip-alarm-all"
                  [queryParams]="{ section: 'SMT' }"
                  target="_blank"
                  >Equipments Alarm</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/production-overview-management"
                  target="_blank"
                  >Production Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/close-order-overview"
                  target="_blank"
                  >MES Closed Orders Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/quality-overview"
                  target="_blank"
                  >Quality Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/drop-rate-overview"
                  target="_blank"
                  >SMT Drop Rate Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  routerLink="/smartFactory/web/repair-management"
                  target="_blank"
                  >Defective Production Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/environment/"
                  target="_blank"
                  >Environment Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/energy/"
                  target="_blank"
                  >Energy Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >Alert Overview</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                >MO Energy Overview
              </mat-chip>
              <mat-chip disableRipple="true" class="width-fit"
                ><a href="https://10.10.177.51:4000" target="_blank"
                  >Smart-CCTV</a
                ></mat-chip
              >
              <mat-chip disableRipple="true" class="width-fit"
                ><a href="http://10.10.35.35:3000/agv-overview" target="_blank"
                  >AGV Overview</a
                ></mat-chip
              >
              <!-- <mat-chip disableRipple="true" class="width-fit"
                ><a href="https://www.google.com/" target="_blank">Google</a>
              </mat-chip> -->
            </mat-chip-list>
          </div>

          <!-- Upload file group -->
          <div class="upload_group" *ngIf="apiSvc.ifSSO('1E-Editor')">
            <mat-drawer-container hasBackdrop="false">
              <mat-drawer #drawer mode="side">
                <button class="upload_item" (click)="onClickUpload('SMT標工')">
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>SMT標工</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("smtStdTime")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('各製程段標準換線工時')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>各製程段<br />標準換線工時</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("stdChangeTime")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('MO標準工期')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>MO標準工期</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("moStdDuration")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('MO逾期天數扣除假日')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>MO逾期天數<br />扣除假日</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("nonWorkingDate")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('SDC站點更新上傳')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>SDC<br />站點更新上傳</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("sdcStationData")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('設備資料訊息')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>設備資料訊息</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("deviceId")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
                <button
                  class="upload_item"
                  (click)="onClickUpload('樓層區域名稱')"
                >
                  <img
                    src="../../../assets/img/btn_upload.svg"
                    alt="download_button"
                  />
                  <p>樓層區域名稱</p>
                  <p class="time">
                    {{
                      getlatestFileUploadTime("displayName")
                        | date: "HH:mm a yyyy/MM/dd"
                    }}
                  </p>
                </button>
              </mat-drawer>

              <div class="expand_button">
                <button
                  type="button"
                  mat-icon-button
                  (click)="
                    drawer.toggle();
                    isUploadGroupExpanded = !isUploadGroupExpanded
                  "
                >
                  <img
                    *ngIf="!isUploadGroupExpanded"
                    src="../../../assets/img/btn_arrow_left.svg"
                    alt="arrow_left_button"
                  />
                  <img
                    *ngIf="isUploadGroupExpanded"
                    src="../../../assets/img/btn_arrow_right.svg"
                    alt="arrow_right_button"
                  />
                </button>
              </div>
            </mat-drawer-container>
          </div>

          <!-- Floor group -->
          <div class="floor_level">
            <!-- Factory(A) Selection -->
            <mat-chip-list aria-label="Factory selection">
              <mat-chip
                [ngClass]="{ alarm: checkBuildingAlarm('Factory-A') }"
                class="level_title"
                disableRipple="true"
                (click)="setFloorLevel(null)"
                >Factory(A)</mat-chip
              >
              <mat-chip disabled="true">B2</mat-chip>
              <mat-chip disabled="true">B1</mat-chip>
              <mat-chip
                class="selected_hover floor_1f"
                [matBadge]="getFloorAlarmCount('1F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '1F',
                  alarm: checkFloorAlarm('1F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('1F')"
                >1F</mat-chip
              >
              <mat-chip
                class="selected_hover floor_2f"
                [matBadge]="getFloorAlarmCount('2F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '2F',
                  alarm: checkFloorAlarm('2F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('2F')"
                >2F</mat-chip
              >
              <mat-chip
                class="selected_hover floor_3f"
                [matBadge]="getFloorAlarmCount('3F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '3F',
                  alarm: checkFloorAlarm('3F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('3F')"
                >3F</mat-chip
              >
              <mat-chip
                class="selected_hover floor_4f"
                [matBadge]="getFloorAlarmCount('4F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '4F',
                  alarm: checkFloorAlarm('4F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('4F')"
                >4F</mat-chip
              >
              <mat-chip
                class="selected_hover floor_5f"
                [matBadge]="getFloorAlarmCount('5F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '5F',
                  alarm: checkFloorAlarm('5F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('5F')"
                >5F</mat-chip
              >
              <mat-chip
                class="selected_hover floor_6f"
                [matBadge]="getFloorAlarmCount('6F', 'Factory-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  selected: floorLevel === '6F',
                  alarm: checkFloorAlarm('6F', 'Factory-A')
                }"
                disableRipple="true"
                (click)="setFloorLevel('6F')"
                >6F</mat-chip
              >
              <mat-chip disabled="true">7F</mat-chip>
              <mat-chip disabled="true">8F</mat-chip>
              <mat-chip disabled="true">9F</mat-chip>
            </mat-chip-list>

            <mat-divider
              *ngIf="floorLevel === null"
              style="margin: 32px 10px; border-top-color: #bababa"
            ></mat-divider>

            <!-- Building(A) Selection -->
            <mat-chip-list
              *ngIf="floorLevel === null"
              aria-label="BuildingA selection"
              style="margin-bottom: 40px"
            >
              <mat-chip
                [ngClass]="{ alarm: checkBuildingAlarm('Building-A') }"
                class="level_title"
                disableRipple="true"
                (click)="isBuildingSwitchClick = !isBuildingSwitchClick"
                >Building(A)</mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('B2F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('B2F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >B2</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('B1F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('B1F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >B1</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('1F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('1F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >1F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('2F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('2F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >2F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('3F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('3F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >3F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('4F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('4F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >4F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('5F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('5F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >5F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('6F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('6F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >6F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('7F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('7F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >7F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('8F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('8F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >8F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('9F', 'Building-A')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('9F', 'Building-A')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >9F</a
                ></mat-chip
              >
            </mat-chip-list>

            <!-- Building(B) Selection -->
            <mat-chip-list
              *ngIf="floorLevel === null"
              aria-label="BuildingB selection"
              [ngStyle]="{
                visibility: isBuildingSwitchClick ? 'visible' : 'hidden',
                opacity: isBuildingSwitchClick ? 1 : 0
              }"
            >
              <mat-chip
                [ngClass]="{ alarm: checkBuildingAlarm('Building-B') }"
                class="level_title"
                disableRipple="true"
                >Building(B)</mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('1F', 'Building-B')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('1F', 'Building-B')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >1F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('2F', 'Building-B')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('2F', 'Building-B')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >2F</a
                ></mat-chip
              >
              <mat-chip
                [matBadge]="getFloorAlarmCount('3F', 'Building-B')"
                matBadgeColor="warn"
                matBadgeOverlap="true"
                matBadgeSize="large"
                [ngClass]="{
                  alarm: checkFloorAlarm('3F', 'Building-B')
                }"
                disableRipple="true"
                ><a
                  href="https://www-smart-building.apitech.com.tw/admin/alarmOverview/"
                  target="_blank"
                  >3F</a
                ></mat-chip
              >
            </mat-chip-list>
          </div>

          <!-- Img box -->
          <!-- Building home page -->
          <div
            *ngIf="floorLevel === null"
            class="img_group building_default floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          ></div>

          <!-- Building 1F -->
          <div
            *ngIf="floorLevel === '1F'"
            class="img_group building_1f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>

          <!-- Building 2F -->
          <div
            *ngIf="floorLevel === '2F'"
            class="img_group building_2f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>

          <!-- Building 3F -->
          <div
            *ngIf="floorLevel === '3F'"
            class="img_group building_3f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>

          <!-- Building 4F -->
          <div
            *ngIf="floorLevel === '4F'"
            class="img_group building_4f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>

          <!-- Building 5F -->
          <div
            *ngIf="floorLevel === '5F'"
            class="img_group building_5f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- SMT Devices Box -->
            <div
              *ngFor="let device of SMT"
              class="devices_box"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.deviceName }}
            </div>

            <!-- LOD Devices Box -->
            <div
              *ngFor="let device of LOD"
              class="devices_box"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.deviceName }}
            </div>

            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>

          <!-- Building 6F -->
          <div
            *ngIf="floorLevel === '6F'"
            class="img_group building_6f floor_1f floor_2f floor_3f floor_4f floor_5f floor_6f"
          >
            <!-- SMT Devices Box -->
            <div
              *ngFor="let device of SMT"
              class="devices_box"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.deviceName }}
            </div>

            <!-- LOD Devices Box -->
            <div
              *ngFor="let device of LOD"
              class="devices_box"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.deviceName }}
            </div>

            <!-- Area Groups Box -->
            <div
              *ngFor="let device of area"
              class="area_box"
              [ngClass]="{ 'shadow-animation': device.area === alarmArea }"
              [ngStyle]="{
                'top.px': device.xTop,
                'left.px': device.yLeft,
                'width.px': device.widthScalePx,
                'height.px': device.heightScalePx,
                'font-size.px': device.areaName === 'Line End' ? '18' : '22',
                'background-color': getEquipmentStatusLight(device?.status)
              }"
            >
              {{ device.areaName }}
            </div>
          </div>
        </div>

        <!-- 數據區塊 -->
        <div class="data_box">
          <!-- SAP MO Status Detail -->
          <div class="mo_detail">
            <div class="mo_overview">
              <h5 class="mat-display-1 color-white" style="text-align: center">
                SAP
              </h5>
              <h5 class="mat-body-1 color-white" style="text-align: center">
                MO Status Overview
              </h5>
            </div>
            <div class="content_box">
              <div class="box">
                <p class="mat-body-2 color-silver">MO Closed (Month)</p>
                <p class="mat-headline color-white">
                  168
                </p>
              </div>
              <div style="border: 1px solid #e5e5e5; height: 140px"></div>
              <div class="box">
                <p class="mat-body-2 color-silver">MO to be Processed</p>
                <p class="mat-headline color-white">
                  688
                </p>
              </div>
            </div>
            <div
              style="
                border: 1px solid #e5e5e5;
                width: 790px;
                margin: 15px 48px 15px 62px;
              "
            ></div>
            <h5
              class="mat-subheading-1 color-silver"
              style="text-align: center"
            >
              MO Status Detail (Month)
              <a
                class="debug"
                target="_blank"
                *ngIf="isOpenDebugMode"
                (click)="downloadFile()"
              >
                下載
              </a>
            </h5>
            <div class="pie_group">
              <!-- pie chart -->
              <div class="pie_chart" *ngFor="let item of sapPieChartDataArr">
                <canvas
                  baseChart
                  [datasets]="item.pieChartData"
                  [labels]="item.pieChartLabels"
                  [chartType]="item.pieChartType"
                  [options]="item.pieChartOptions"
                  [colors]="item.donutColors"
                >
                </canvas>
              </div>
              <!-- pie title & num -->
              <div class="pie_title_group">
                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_green"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Closed
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      6
                        | number: "0.2-2"
                    }}% (<span>{{
                      66
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_yellow"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Closed (Overdue)
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      8
                        | number: "0.2-2"
                    }}% (<span>{{
                      88
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_brown"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      In Progress
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      16
                        | number: "0.2-2"
                    }}% (<span>{{
                      168
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_red"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Overdue
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      80
                        | number: "0.2-2"
                    }}% (<span>{{
                      666
                    }}</span
                    >)
                  </div>
                </div>
              </div>
            </div>
            <p class="mat-subheading-2 color-silver" style="text-align: center">
              On Time Delivery Rate
              <span class="mat-headline color-white" style="margin-left: 36px">
                {{
                  +66.66
                }}
              </span>
              <span class="rate_percent">%</span>
            </p>
          </div>

          <!-- MES MO Status Detail -->
          <div class="mo_detail">
            <div class="mo_overview">
              <h5 class="mat-display-1 color-white" style="text-align: center">
                MES
              </h5>
              <h5 class="mat-body-1 color-white" style="text-align: center">
                MO Status Overview
              </h5>
            </div>
            <div class="content_box">
              <div class="box">
                <p class="mat-body-2 color-silver">MO Closed (Month)</p>
                <p class="mat-headline color-white">
                  998
                </p>
              </div>
              <div style="border: 1px solid #e5e5e5; height: 140px"></div>
              <div class="box">
                <p class="mat-body-2 color-silver">MO to be Processed</p>
                <p class="mat-headline color-white">
                  866
                </p>
              </div>
            </div>
            <div
              style="
                border: 1px solid #e5e5e5;
                width: 790px;
                margin: 15px 48px 15px 62px;
              "
            ></div>
            <h5
              class="mat-subheading-1 color-silver"
              style="text-align: center"
            >
              MO Status Detail (Month)
              <a
                class="debug"
                target="_blank"
                *ngIf="isOpenDebugMode"
                (click)="downloadFile()"
              >
                下載
              </a>
            </h5>
            <div class="pie_group">
              <!-- pie chart -->
              <div class="pie_chart" *ngFor="let item of mesPieChartDataArr">
                <canvas
                  baseChart
                  [datasets]="item.pieChartData"
                  [labels]="item.pieChartLabels"
                  [chartType]="item.pieChartType"
                  [options]="item.pieChartOptions"
                  [colors]="item.donutColors"
                >
                </canvas>
              </div>
              <!-- pie title & num -->
              <div class="pie_title_group">
                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_green"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Closed
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      8
                        | number: "0.2-2"
                    }}% (<span>{{
                      16
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_yellow"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Closed (Overdue)
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      6
                        | number: "0.2-2"
                    }}% (<span>{{
                      12
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_brown"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      In Progress
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      50
                        | number: "0.2-2"
                    }}% (<span>{{
                      100
                    }}</span
                    >)
                  </div>
                </div>

                <div class="pie_item">
                  <div class="pie_color">
                    <span class="pie_c pie_bg_red"></span>
                    <p class="mat-caption color-silver" style="width: 200px">
                      Overdue
                    </p>
                  </div>
                  <div
                    class="mat-body-2 color-white"
                    style="width: 220px; text-align: right"
                  >
                    {{
                      36
                        | number: "0.2-2"
                    }}% (<span>{{
                      72
                    }}</span
                    >)
                  </div>
                </div>
              </div>
            </div>
            <p class="mat-subheading-2 color-silver" style="text-align: center">
              On Time Delivery Rate
              <span class="mat-headline color-white" style="margin-left: 36px">
                {{
                  99.99
                }}
              </span>
              <span class="rate_percent">%</span>
            </p>
          </div>

          <!-- Alarm Record -->
          <div class="alarm_record">
            <div class="block_title_group">
              <h5 class="mat-display-1 color-white" style="text-align: center">
                Alarm Record
              </h5>
              <h5 class="mat-body-1 color-white" style="text-align: center">
                Equipment
              </h5>
            </div>
            <div class="chart_group">
              <div class="chart" *ngFor="let item of newAlarmChartDataArr">
                <!-- <p
                  class="mat-body-1 color-silver"
                  style="text-align: center; margin-bottom: 5px"
                >
                  {{ item.chartTitle }}
                </p> -->
                <div class="chart_img">
                  <canvas
                    baseChart
                    height="100"
                    [datasets]="item.barChartData"
                    [options]="item.barChartOptions"
                    [colors]="item.chartColors"
                    [legend]="item.barChartLegend"
                    [chartType]="item.barChartType"
                    (chartHover)="item.chartHovered($event)"
                    (chartClick)="item.chartClicked($event)"
                  >
                  </canvas>
                </div>
                <!-- <span class="chart_Y_name">Alarm Times</span> -->
              </div>
              <!-- <div class="chart"></div> -->
              <!-- <div class="chart"></div> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #PermissionDenied>
      <div style="text-align: center; font-size: 48px">Permission denied</div>
    </ng-template>

    <!-- Auto Refresh -->
    <div class="refresh">
      <!-- Kanban Renewed & Snap Shot -->
      <div class="update_time">
        <p class="renewed">
          <span class="title">Kanban Renewed:</span>
          <span class="value">{{
            renewedTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
        <p class="snapshot">
          <span class="title">Snap Shot:</span>
          <span class="value">{{
            snapshotTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
      </div>
      <button class="relaod_btn" (click)="reloadCurrentPageClick()">
        <img src="../../assets/img/btn_relaod.svg" alt="reload" />
      </button>
      <div class="refresh_btn">
        <label>
          <span class="text">Auto Refresh</span>
          <input
            type="checkbox"
            class="checkbox"
            [checked]="pageAutoRefresh"
            (change)="switchPageAutoRefresh()"
          />
          <span class="btn-box">
            <span class="btn"></span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
