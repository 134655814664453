import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';


import { ApiService } from '../_services/apiSvc.service';
import { HttpClient } from '@angular/common/http';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SaveMonoInformationService } from '../_services/save-mono-information.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

// RxJS
import { fromEvent, interval, Observable } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-phase3-mo-overview',
  templateUrl: './phase3-mo-overview.component.html',
  styleUrls: ['./phase3-mo-overview.component.scss'],
  providers: [DatePipe]
})
export class Phase3MoOverviewComponent implements OnInit, OnDestroy {

  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  urlParamsObj = {
    sn: undefined,
    partNo: undefined,
    status: undefined,
    startTime: undefined,
    endTime: undefined,
    inputQty: undefined,
    defectQuantity: undefined,
    avgRty: undefined,
    lowestYield: undefined
  };

  saveMoInfoObj;

  begingTime;
  before24Time;

  getStationDataNoData = false;
  getStationChildDataNoData = false;
  getHourlyDataNoData = false;

  isStationChildShowing = false;
  isStationChildTitle: string = null;
  stationPerformanceDataArr: any = [
  ];
  stationPerformanceChildDataArr: any = [];
  defectRankingStationName: string = null;

  // ng2-charts
  public createArr: any = [];
  public chartHourlyDataArr: any = [];
  // public chartHourlyDataArr: any = [
  //   {
  //     chartTitle: 'ATE hiPotTest2',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [10, 15, 18, 31, 21, 1, 51, 1, 21, 51, 21, 31, 1, 61, 1, 51, 15, 19, 81, 1, 51, 18, 16, 14,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [10, 0, 20, 0, 40, 0, 20, 10, 50, 0, 20, 0, 78, 15, 6, 80, 20, 20, 0, 0, 0, 80, 0, 80,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               // min: 0,
  //               // max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               // min: 0,
  //               // max: 40,
  //               stepSize: 7,
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       // console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       // console.log(event, active);
  //     }
  //   },

  //   {
  //     chartTitle: 'ATE functionTest1',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 17, 13, 8, 10, 0, 0, 0,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     }
  //   },

  //   {
  //     chartTitle: 'ATE functionTest2',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     }
  //   }
  // ];

  rankStationName: any = [];
  rankBar: any = [
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
    {
      causeName: [],
      defectQty: '-',
      defectPercentage: '0',
    },
  ];

  dataDefectRankOutstripFive = false;

  constructor(
    // Service
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public moSvc: SaveMonoInformationService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,

    // Pipe
    private datePipe: DatePipe,

    // Rout
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,

  ) {

    this.route.queryParams.subscribe((params) => {
      // console.log('params:', params);
      this.urlParamsObj = Object.assign(params);
      console.log('this.urlParamsObj:', this.urlParamsObj);
    });

    // 直接進入 Mo Quality 導回 Quality Overview
    if (this.urlParamsObj.sn === null) {
      this.router.navigate(['/smartFactory/web/phase3-quality-overview']);
    }

    this.isSvLoading.loading = true;
  }


  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.getAllApifn();
    });
  }

  ngOnInit(): void {
    this.rxTimerStart();
    this.renewedTime = new Date().valueOf();
    this.getPerformanceData(this.urlParamsObj.sn);
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // get beging & endTime
  getBegingEndTime() {
    this.before24Time = new Date().toISOString();
    this.begingTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString();
    console.log('this.begingTime:', this.begingTime);
    console.log('this.before24Time:', this.before24Time);
  }

  callRankingTop10(stationName) {
    this.isSvLoading.loading = true;
    this.rankBar = [];
    console.log('stationName:', stationName);
    this.defectRankingStationName = stationName;
    this.getDefectRankingTop10Data(this.urlParamsObj.sn, stationName);
  }

  // Group chart data by function name
  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  // 打開 Station Performance children
  openStationChildContent(stationName) {
    this.isSvLoading.loading = true;
    console.log('stationName:', stationName);
    this.isStationChildShowing = !this.isStationChildShowing;

    // Call Station Performance 子層API
    this.getPerformanceChildData(this.urlParamsObj.sn, stationName);
  }

  // API Station Performance 資料
  getPerformanceData(orderNum) {
    this.apiSvc.getPhase3MoQualityStationPerformanceData(orderNum).subscribe(res => {
      console.log('getPerformanceData res:', res);
      this.snapshotTime = this.renewedTime;
      // res = this.forTestDataSvc.stationPerformancesData;

      if (res.length === 0) {
        console.log('getPerformanceData res is Empty');
        this.getStationDataNoData = true;
        return;
      }
      if (res.length !== 0) {
        this.getStationDataNoData = false;
      }

      res.map((item) => {
        item.fpy = (Math.fround(item.fpy * 100));
        return item;
      });
      this.stationPerformanceDataArr = res;
      this.isSvLoading.loading = false;
    });
  }

  // API Station Performance Child 資料
  getPerformanceChildData(orderNum, functionName) {
    this.apiSvc.getPhase3MoQualityStationPerformanceChildData(orderNum, functionName).subscribe(res => {
      console.log('getPerformanceChildData res:', res);
      this.snapshotTime = this.renewedTime;
      // res = this.forTestDataSvc.stationPerformancesChildData;

      if (res.length === 0) {
        console.log('getPerformanceChildData res is Empty');
        this.getStationChildDataNoData = true;
        this.isSvLoading.loading = false;
        return;
      }
      if (res.length !== 0) {
        this.getStationChildDataNoData = false;
      }

      res.map((item) => {
        item.fpy = (Math.fround(item.fpy * 100));
        return item;
      });
      this.stationPerformanceChildDataArr = res;
      this.isSvLoading.loading = false;
    });
  }

  // API Defect Ranking station Name 資料
  getDefectStationNameData() {
    this.rankStationName = [];
    this.apiSvc.getPhase3MoQualityDefectStationListData(this.urlParamsObj.sn).subscribe(res => {
      console.log('getDefectStationNameData res:', res);
      this.snapshotTime = this.renewedTime;
      // this.rankStationName = this.forTestDataSvc.moQualityDefectRankStationName;
      this.rankStationName = res;

      if (res.length === 0) {
        this.isSvLoading.loading = false;
        return;
      }
      // this.rankStationName = res;
      console.log('this.rankStationName:', this.rankStationName);

      res.sort((a, b) => a - b);
      if (!this.defectRankingStationName) { this.defectRankingStationName = res[0]; }
      // res.shift();

      this.callRankingTop10(this.defectRankingStationName);

      this.isSvLoading.loading = false;
    });
  }

  // API Defect Ranking Top10 資料
  getDefectRankingTop10Data(orderNum, stationName) {
    this.apiSvc.getPhase3MoQualityDefectTopTenData(orderNum, stationName).subscribe(res => {
      // res = this.forTestDataSvc.moQualityDefectRankTopTen;

      console.log('getDefectRankingTop10Data res => 11111', res);

      res.map((item) => {
        item.defectPercentage = +item.defectPercentage * 100;
        return item;
      });

      this.rankBar = res.sort((a, b) => {
        return b.defectPercentage - a.defectPercentage || b.defectQty - a.defectQty || b.causeName.localeCompare(a.causeName);
      });
      +res.length > 5 ? this.dataDefectRankOutstripFive = true : this.dataDefectRankOutstripFive = false;

      this.rankBar = this.rankBar.map((item) => {
        // 依照';'切割字串
        if (item?.causeName) {
          item.causeName = _.split(item?.causeName, ';');
        }
        // 判斷第一個單字 null 去除
        if (item?.causeName.length > 0) {
          item.causeName = item.causeName.map((name) => {
            const firstWord = _.cloneDeep(name).slice(0, 4);
            if (firstWord === 'null') { name = name.substring(4); }
            return name;
          });
        }
        // ';'切割後補回';'
        if (item?.causeName.length > 1) {
          item.causeName = item.causeName.map((name) => {
            const lastChar = name.charAt(name.length - 1);
            if (lastChar !== ';') { name = name + ';'; }
            return name;
          });
        }
        // 剔除誤加';'
        if (item?.causeName) {
          item.causeName = item.causeName.filter(item => item !== ';');
        }
        return item;
      });
      this.isSvLoading.loading = false;
    });
  }

  // Update the data
  getAllApifn() {
    this.renewedTime = new Date().valueOf();
    this.getPerformanceData(this.urlParamsObj.sn);
    this.getPerformanceChildData(this.urlParamsObj.sn, this.isStationChildTitle);
    this.getDefectStationNameData();
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.getAllApifn();
  }

}
