var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input } from '@angular/core';
import { OwlDateTimeComponent } from './date-time-picker.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
var OwlDateTimeTriggerDirective = (function () {
    function OwlDateTimeTriggerDirective(changeDetector) {
        this.changeDetector = changeDetector;
        this.stateChanges = Subscription.EMPTY;
    }
    Object.defineProperty(OwlDateTimeTriggerDirective.prototype, "disabled", {
        get: function () {
            return this._disabled === undefined ? this.dtPicker.disabled : !!this._disabled;
        },
        set: function (value) {
            this._disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeTriggerDirective.prototype, "owlDTTriggerDisabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    OwlDateTimeTriggerDirective.prototype.ngOnInit = function () {
    };
    OwlDateTimeTriggerDirective.prototype.ngOnChanges = function (changes) {
        if (changes.datepicker) {
            this.watchStateChanges();
        }
    };
    OwlDateTimeTriggerDirective.prototype.ngAfterContentInit = function () {
        this.watchStateChanges();
    };
    OwlDateTimeTriggerDirective.prototype.ngOnDestroy = function () {
        this.stateChanges.unsubscribe();
    };
    OwlDateTimeTriggerDirective.prototype.handleClickOnHost = function (event) {
        if (this.dtPicker) {
            this.dtPicker.open();
            event.stopPropagation();
        }
    };
    OwlDateTimeTriggerDirective.prototype.watchStateChanges = function () {
        var _this = this;
        this.stateChanges.unsubscribe();
        var inputDisabled = this.dtPicker && this.dtPicker.dtInput ?
            this.dtPicker.dtInput.disabledChange : observableOf();
        var pickerDisabled = this.dtPicker ?
            this.dtPicker.disabledChange : observableOf();
        this.stateChanges = merge(pickerDisabled, inputDisabled)
            .subscribe(function () {
            _this.changeDetector.markForCheck();
        });
    };
    __decorate([
        Input('owlDateTimeTrigger'),
        __metadata("design:type", OwlDateTimeComponent)
    ], OwlDateTimeTriggerDirective.prototype, "dtPicker", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [Boolean])
    ], OwlDateTimeTriggerDirective.prototype, "disabled", null);
    __decorate([
        HostBinding('class.owl-dt-trigger-disabled'),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OwlDateTimeTriggerDirective.prototype, "owlDTTriggerDisabledClass", null);
    __decorate([
        HostListener('click', ['$event']),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], OwlDateTimeTriggerDirective.prototype, "handleClickOnHost", null);
    OwlDateTimeTriggerDirective = __decorate([
        Directive({
            selector: '[owlDateTimeTrigger]',
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef])
    ], OwlDateTimeTriggerDirective);
    return OwlDateTimeTriggerDirective;
}());
export { OwlDateTimeTriggerDirective };
