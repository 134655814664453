<div class="container-fluid new_quality_overview_container">
  <div class="container-fluid title_group">
    <h1 class="title_name">Quality Overview</h1>

    <!-- <p>{{ DATA | json }}</p> -->

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container-fluid container_box">
    <div class="table_group">
      <!-- search group-->
      <div class="search_group">
        <div class="search">
          <img
            class="search_icon"
            src="../../assets/img/smart_factory/bi_search.svg"
            alt="search-icon"
          />
          <input matInput placeholder="Search" #input />
        </div>

        <!-- 下拉選單 Status -->
        <!-- <div class="select_tag">
          <label>Status</label>
          <select (change)="getModelName($event.target.value)">
            <option selected="selected">All</option>
            <option *ngFor="let item of allStatusName">{{ item }}</option>
            <option>Loading</option>
            <option>ATE</option>
            <option>ASSY</option>
            <option>PKG</option>
            <option *ngFor="let item of rankingModelNameArray" [value]="item">
            {{ item }}
          </option>
          </select>
        </div> -->

        <!-- 日期篩選器 -->
        <div class="date_range_picker">
          <h6 class="date_title">Date Filter:</h6>

          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>

            <mat-date-range-input
              [formGroup]="range"
              [min]="minDate"
              [max]="maxDate"
              [dateFilter]="familyDayFilter"
              [rangePicker]="picker"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="End date"
                (dateChange)="getRangeDate(range.value.start, range.value.end)"
              />
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>

            <mat-date-range-picker
              #picker
              startView="month"
              [startAt]="startDate"
            ></mat-date-range-picker>
          </mat-form-field>

          <div class="date_group">
            <p class="date_value">
              {{
                range.value.start === null
                  ? "- - -"
                  : (range.value.start | date: "YYYY/MM/dd")
              }}
            </p>
            <span class="date_span"> ~ </span>
            <p class="date_value">
              {{
                range.value.end === null
                  ? "- - -"
                  : (range.value.end | date: "YYYY/MM/dd")
              }}
            </p>
            <div class="default_button" (click)="setDefaultRangeDate()">
              Default
            </div>
          </div>
        </div>

        <!-- select 欄位篩選器 -->
        <!-- <div class="select">
          <img
            class="select_icon"
            src="../../../assets/img/btn_table_column.svg"
            alt="Group"
            (click)="selectClickIcon()"
          />
          <div
            class="select_choice"
            [ngClass]="{ select_choice_show: selected }"
            *ngIf="displayedColumns as item"
          >
            <p class="select_tip">Column display :</p>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="duration"
                id=""
              />
              <span class="checkbox_span"></span>
              Duration
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="section"
                id=""
              />
              <span class="checkbox_span"></span>
              Section
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="line"
                id=""
              />
              <span class="checkbox_span"></span>
              Line
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="targetOutput"
                id=""
              />
              <span class="checkbox_span"></span>
              Mo Qty
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="side"
                id=""
              />
              <span class="checkbox_span"></span>
              Side
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="startTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Start Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="endTime"
                id=""
              />
              <span class="checkbox_span"></span>
              End Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="restTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Rest Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="downTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Down Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="changeover"
                id=""
              />
              <span class="checkbox_span"></span>
              Changeover
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="actProductionTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Act Production Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="operators"
                id=""
              />
              <span class="checkbox_span"></span>
              Operators
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="actTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Act Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="cycleTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Cycle Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="standardTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Standard Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="output"
                id=""
              />
              <span class="checkbox_span"></span>
              Output Qty
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="efficiency"
                id=""
              />
              <span class="checkbox_span"></span>
              Efficiency
            </label>
          </div>
        </div> -->
      </div>

      <div class="table_scroll_box">
        <!-- 第一層 Table -->
        <div class="table_container mat-elevation-z8">
          <!-- API RES 沒資料 -->
          <div *ngIf="outDataSource.data.length === 0" class="api_res_no_data">
            No Data
          </div>

          <!-- Table 吃 DataSource資料 -->
          <mat-table
            #table
            *ngIf="outDataSource.data.length > 0"
            [dataSource]="outDataSource"
            multiTemplateDataRows
            matSort
            #sort="matSort"
            matSortActive="avgrty"
            matSortDirection="asc"
            (matSortChange)="sortData($event)"
          >
            <!-- MO No. Column -->
            <ng-container matColumnDef="moNo">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 1.6"
              >
                MO No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.6"
              >
                <div
                  class="card_link title_value"
                  (click)="getMoItem(element)"
                  [routerLink]="['/smartFactory/web/new-mo-overview']"
                  [queryParams]="{
                    partNo: element.partNo,
                    sn: element.moNo,
                    inputQty: element.inputQty,
                    defectQuantity: element.defectQty,
                    avgRty: element.avgrty,
                    lowestYield: element.lowestYield
                  }"
                >
                  {{ dataFormatSvc.checkValDash(element.moNo) }}
                </div>
              </mat-cell>
            </ng-container>

            <!-- Part No. Column -->
            <ng-container matColumnDef="partNo">
              <mat-header-cell
                mat-sort-header
                disableClear="true"
                start="desc"
                *matHeaderCellDef
                class=""
                style="flex-grow: 1.7"
              >
                Part No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1.7"
              >
                {{ dataFormatSvc.checkValDash(element.partNo) }}
              </mat-cell>
            </ng-container>

            <!-- Mo Qty Column -->
            <ng-container matColumnDef="moQty">
              <mat-header-cell
                *matHeaderCellDef
                disableClear="true"
                start="desc"
                class=""
                style="flex-grow: 1"
              >
                MO Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1"
              >
                <span class="cell_text_right">
                  {{ dataFormatSvc.checkValDash(element.formattedMoQty) }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Input Qty Column -->
            <ng-container matColumnDef="inputQty">
              <mat-header-cell
                *matHeaderCellDef
                disableClear="true"
                start="desc"
                class=""
                style="flex-grow: 1"
              >
                Input Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1"
              >
                <span class="cell_text_right">
                  {{ dataFormatSvc.checkValDash(element.formattedInputQty) }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Defect Qty Column -->
            <ng-container matColumnDef="defectQty">
              <mat-header-cell
                *matHeaderCellDef
                disableClear="true"
                start="desc"
                class=""
                style="flex-grow: 1"
              >
                Defect Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1"
              >
                <span class="cell_text_right">
                  {{ dataFormatSvc.checkValDash(element.formattedDefectQty) }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- AVG. RTY Column -->
            <tr matColumnDef="avgrty">
              <mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                disableClear="true"
                start="desc"
                style="flex-grow: 1"
              >
                Avg RTY
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1"
              >
                <span class="cell_text_right" [title]="element.avgrty">
                  {{ dataFormatSvc.checkValDash(element.formattedAvgRty) }} %
                </span>
              </mat-cell>
            </tr>

            <!-- Lowest Yield Column -->
            <tr matColumnDef="lowestYield">
              <mat-header-cell
                *matHeaderCellDef
                disableClear="true"
                start="desc"
                style="flex-grow: 1.6"
              >
                Lowest Yield
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.6"
              >
                <span class="" style="width: 80%" [title]="element.lowestYield">
                  {{ dataFormatSvc.checkValDash(element.formattedLowestYield) }}
                </span>
              </mat-cell>
            </tr>

            <!-- 第二層 table   Start ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->
            <ng-container matColumnDef="expandedDetail">
              <mat-cell
                *matCellDef="let row"
                class="innerTable"
                [@detailExpand]="expandHandler(row)"
              >
                <mat-table
                  [dataSource]="row.children"
                  multiTemplateDataRows
                  matSort
                  #innerTables
                  #innerSort="matSort"
                  matSortActive=""
                  matSortDirection=""
                  style="width: 100%"
                >
                  <!-- action Column -->
                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.2">
                      <!-- action -->
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element"
                      style="flex-grow: 0.2"
                    ></mat-cell>
                  </ng-container>

                  <!-- line Column -->
                  <ng-container matColumnDef="line">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Line
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ dataFormatSvc.checkValDash(element.line) }}
                    </mat-cell>
                  </ng-container>

                  <!-- Section Column -->
                  <ng-container matColumnDef="section">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ element.section }}
                    </mat-cell>
                  </ng-container>

                  <!-- Side Column -->
                  <ng-container matColumnDef="side">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Side
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ dataFormatSvc.checkValDash(element.side) }}
                    </mat-cell>
                  </ng-container>

                  <!-- Input Qty Column -->
                  <ng-container matColumnDef="inputQty">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Input Qty
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      <span class="cell_text_right">
                        {{
                          dataFormatSvc.checkValDash(element.formattedInputQty)
                        }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <!-- Defect Qty Column -->
                  <ng-container matColumnDef="defectQty">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Defect Qty
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      <span class="cell_text_right">
                        {{
                          dataFormatSvc.checkValDash(element.formattedDefectQty)
                        }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <!-- RTY Column -->
                  <ng-container matColumnDef="rty">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      RTY
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element"
                      class="cell_text_right"
                      style="flex-grow: 1"
                    >
                      <span class="cell_text_right">
                        {{ dataFormatSvc.checkValDash(element.formattedRty) }} %
                      </span>
                    </mat-cell>
                  </ng-container>

                  <!-- Lowest Yield Column -->
                  <ng-container matColumnDef="lowestYield">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1.5"
                    >
                      Lowest Yield
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1.5">
                      <span class="cell_text_right">
                        {{
                          dataFormatSvc.checkValDash(
                            element.formattedLowestYield
                          )
                        }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="innerDisplayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: innerDisplayedColumns"
                    matRipple
                    class="element-row"
                  >
                  </mat-row>
                </mat-table>
              </mat-cell>
            </ng-container>
            <!-- 第二層 table   END ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->

            <!-- action "^" -->
            <ng-container matColumnDef="action">
              <mat-header-cell
                *matHeaderCellDef
                style="flex: none; width: 35px"
              >
                <!-- action -->
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                style="flex: none; width: 35px"
              >
                <!-- 判斷有沒有 children -->
                <ng-container *ngIf="element.children.length !== 0">
                  <button mat-icon-button [@iconExpand]="iconSwitch(element)">
                    <mat-icon>
                      <img
                        src="/assets/img/smart_factory/Arrow_right.svg"
                        alt=""
                      />
                    </mat-icon>
                  </button>
                </ng-container>
              </mat-cell>
            </ng-container>

            <!-- 表頭 sticky: true => fix ".mat-header-row" -->
            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              matRipple
              class="element-row"
              (click)="expendClick(row)"
              [ngStyle]="{
                'background-color': row.isExpanded ? '#747474' : '#383838'
              }"
            >
            </mat-row>
            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              style="overflow: hidden"
              class="example-detail-row"
            >
            </mat-row>
          </mat-table>
        </div>
      </div>
      <!-- page navigation-->
      <mat-paginator
        class="paginator"
        #MatPaginator
        [showFirstLastButtons]="true"
        [length]="isTotalPageNum"
        [pageIndex]="queryObj.offset - 1"
        [pageSize]="12"
        (page)="onPaginateChange($event)"
      >
      </mat-paginator>
    </div>
  </div>

  <!-- Auto Refresh -->
  <div class="refresh">
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
