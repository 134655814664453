import { Component, OnDestroy, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { timer, Subject, interval, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../_services/apiSvc.service';

import { ActivatedRoute, Router } from '@angular/router';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import * as moment from 'moment';
import numeral from 'numeral';
import * as _ from 'lodash';
import { EquipmentStatus } from '../_variable/main-panel-type';

@Component({
  selector: 'app-phase3-equip-alarm',
  templateUrl: './phase3-equip-alarm.component.html',
  styleUrls: ['./phase3-equip-alarm.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class Phase3EquipAlarmComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedMoments = [new Date(), new Date()];
  minDate = new Date(2021, 6, 1, 0, 0);
  maxDate = moment().startOf('day').add(1, 'days').toDate();
  isDateTimePickerDefault = true;
  isMenuOpen = false;
  isLogin = false;

  updatingDataSubscription: Subscription | null = null;
  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  orderInfoObj = {
    Section: null,
    Type: null,
    Sn: null,
    Side: null
  };

  isApiResEmptyArrShowNoData = false;

  btnTypeActiveObj = [
    {
      key: 'Mo',
      active: false,
    },
    {
      key: 'Line',
      active: false,
    }
  ];

  sectionNameArr = [];
  allOptionNameArr = [];

  sectionDisplayMappingObj = {
    SMT: 'SMT',
    LOD: 'LOD',
    TEST: 'Equipments'
  };

  apiParamsObj = {
    type: 'Line',
    sectionName: null || 'SMT',
    selectOptionName: null,
    countRankingSelectEquip: null,
    timeRankingSelectEquip: null,
    startTime: null,
    endTime: null,
  };

  equipAlarmDataCompose = {
    equipInfo: null,
    latestAlarm: null,
    alarmCode: null,
    latestAlarmTime: null,
    alarmDuration: null,
    lineRunning: null,
    lineIdle: null,
    lineDownTime: null,
    balanceRate: null,
    lineRunningSec: null,
    lineIdleSec: null,
    lineDownTimeSec: null,
    lineRunningFormatted: null,
    lineIdleFormatted: null,
    lineDownTimeFormatted: null,

    chartBgColorArr: this.dataFormatSvc.chartColorsArr,
    allEquipNameArr: [],
    allDownTimeArr: [],
    allErrRecordArr: [],

    // Error Record 表格
    allUtilizationArr: [],
    allDownTimeRateArr: [],
    allIdleRateArr: [],

    // Pie Chart 圓餅圖
    pieChartErrorRecordInfo: [],
    allErrorRecordPercentArr: []
  };

  // Ranking Top 5
  rankingModelNameArray = [];
  rankingListCountData = [];
  rankingListTimeData = [];


  // Pie-Chart => Down Time START***************************************
  public pieChartDataArr: any = [
    {
      pieChartType: 'pie',
      // Pie
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
        },
        // label 文字
        legend: {
          display: false,
        },
        // Pie 顯示單位百分比
        tooltips: {
          enabled: true, // 關閉 tooltips
          bodyFontSize: 24,
        }
      },
      // pie color
      donutColors: [
        {
          backgroundColor: this.equipAlarmDataCompose.chartBgColorArr
        }
      ],
      // pieChartLabels: [
      //   'CM3020',
      //   'CM3070',
      //   'SP-1',
      //   'SP-2',
      //   'SP-3',
      //   'SP-4',
      //   'SP-5',
      //   'SP-6',
      //   'SP-7',
      // ],
      pieChartLabels: [],
      pieChartData: [{ data: [] }]
    }
  ];
  // Pie-Chart => Down Time END***************************************

  // Bar-Chart => Error Record START***************************************
  public barChartDataArr: any = [
    {
      chartTitle: 'ATE hiPotTest2',
      barChartType: 'bar',
      // Pie
      plugins: [{
        beforeDraw(chart) {
          const ctx = chart.chart.ctx;
          ctx.restore();
          ctx.font = 20 + 'px Roboto';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#bababa';
          const height = chart.chartArea.bottom + 25;
          ctx.fillText('(mins)', 0, height);
          ctx.save();
        }
      }],
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false,  // not show unit name "kWh" / 移除 阿舍預設 "kWh"
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
        // label 文字
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 24,
                fontSize: 20,
                stepSize: 10,
                fontColor: '#BABABA',
                // padding: 2,
                // X軸文字換行
                callback(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(' ');
                  } else {
                    return label;
                  }
                }
              },
              // 設定 底線
              gridLines: {
                display: false,
                color: '#868686',
                drawBorder: false,
              },
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 40,
                fontSize: 20,
                // stepSize: 100,
                maxTicksLimit: 5,
                fontColor: '#BABABA',
                padding: 12
              },
              // 設定 底線
              gridLines: {
                display: true,
                color: '#868686',
                drawBorder: false,
                drawTicks: true,
                lineWidth: 2,
              },
            }
          ]
        },
        tooltips: {
          // 設定 Unix
          // callbacks: {
          //   label: (item) => `${item.yLabel} mins`,
          // },
          titleFontSize: 24,
          bodyFontSize: 24,
          borderColor: 'white'
        },
      },
      // pie color
      donutColors: [
        {
          backgroundColor: this.equipAlarmDataCompose.chartBgColorArr,
        },
      ],
      barChartLegend: true,
      barChartLabels: [],
      barChartData: [
        {
          data: [],
        },
      ],
    }
  ];
  // Bar-Chart => Error Record END***************************************


  // Error Record 表格
  typeLineTableDataArr = [
    {
      name: 'Running Rate',
      equipValArr: [],
    },
    {
      name: 'Downtime Rate',
      equipValArr: [],
    },
    {
      name: 'Idle Rate',
      equipValArr: [],
    },
    {
      name: 'Error Record',
      equipValArr: [],
    },
  ];

  typeMoTableDataArr = [
    {
      name: 'Utilization',
      equipValArr: this.equipAlarmDataCompose.allUtilizationArr,
    },
    {
      name: 'DownTime rate',
      equipValArr: this.equipAlarmDataCompose.allDownTimeRateArr,
    },
    {
      name: 'Idle rate',
      equipValArr: this.equipAlarmDataCompose.allIdleRateArr,
    },
    {
      name: 'Error record',
      equipValArr: this.equipAlarmDataCompose.allErrRecordArr,
    },
  ];

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      if (this.isDateTimePickerDefault) {
        this.defaultDateTime();
      }
      else {
        this.isSvLoading.loading = true;
        this.cleanEquipAlarmDataComposeArrData();
        this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
      }
    });
  }

  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,

    // route
    private route: ActivatedRoute,
    private router: Router,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;

    // queryParams 改變時, window.reload 畫面 / 按鈕切換 ByMO/ByLine
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.getUrlParam();
    if (this.isDateTimePickerDefault) { this.initialDateTime(); }
    else { this.selectedMoments = [moment(this.apiParamsObj.startTime).toDate(), moment(this.apiParamsObj.endTime).toDate()]; }
    this.rxTimerStart();
  }

  ngAfterViewInit() {
    this.getAllApiFn(this.equipAlarmDataCompose);
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = false;
    this.cleanEquipAlarmDataComposeArrData();
    this.selectedMoments = this.clearDateTimeMinites(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeEqual(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeLimit(this.selectedMoments);
    console.log('時間篩選器 選取時間 過濾後:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
    this.apiParamsObj.startTime = this.selectedMoments[0].valueOf();
    this.apiParamsObj.endTime = this.selectedMoments[1].valueOf();
    this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
  }

  // Default DateRangePicker 設定 預設的 時間, 24小時內的資料
  defaultDateTime() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = true;
    this.cleanEquipAlarmDataComposeArrData();
    this.initialDateTime();
    this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
  }

  // 初始化時間, 24小時內
  initialDateTime() {
    if (this.isDateTimePickerDefault) {
      const currentHour = moment().get('hour');
      const endTime = moment().startOf('day').set('hour', currentHour).add(1, 'hours');
      const startTime = _.cloneDeep(endTime).subtract(1, 'day');
      this.selectedMoments = [startTime.toDate(), endTime.toDate()];
      console.log('時間篩選器 預設時間:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
      this.apiParamsObj.startTime = startTime.valueOf();
      this.apiParamsObj.endTime = endTime.valueOf();
    }
  }

  // 檢查選到的時間區間是否相同, 若相同取其時間往前24小時的時間區間
  checkDateTimeRangeEqual(timeArray) {
    if (timeArray[0].valueOf() === timeArray[1].valueOf()) {
      timeArray[0] = moment(timeArray[0]).subtract(1, 'day').toDate();
    }
    return timeArray;
  }

  // 將所選時間的分鐘歸零
  clearDateTimeMinites(timeArray) {
    return timeArray.map(item => {
      return item = moment(item).set('minute', 0).toDate();
    });
  }

  // 檢查所選時間區間是否超過 31 天, 若超過將 endTime 改為 startTime + 31 天
  checkDateTimeRangeLimit(timeArray) {
    const startTime = moment(timeArray[0]);
    let endTime = moment(timeArray[1]);
    const dayDiff = endTime.diff(startTime, 'days', true);   // true => 取到小數位
    console.log('dayDiff:', dayDiff);
    if (dayDiff > 31) {
      const hour = startTime.get('hour');
      endTime = _.cloneDeep(startTime).add(31, 'day').set('hour', hour);
      timeArray[0] = startTime.toDate();
      timeArray[1] = endTime.toDate();
    }
    return timeArray;
  }

  // 設定 DateTimePicker 可被選的時間區間, 從 2021/07/01 00:00 到 明天 00:00
  defaultDateTimeRange() {
    this.minDate = new Date(2021, 6, 1, 0, 0);
    this.maxDate = moment().startOf('day').add(1, 'days').toDate();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // Url 參數設定
  getUrlParam() {
    this.apiParamsObj.sectionName = this.route.snapshot.queryParamMap.get('section');
    this.apiParamsObj.selectOptionName = this.route.snapshot.queryParamMap.get('line');
    console.log('getUrlParam Line:', this.apiParamsObj.selectOptionName);
    this.apiParamsObj.startTime = this.route.snapshot.queryParamMap.get('startTime');
    this.apiParamsObj.endTime = this.route.snapshot.queryParamMap.get('endTime');
    if (this.apiParamsObj.startTime && this.apiParamsObj.endTime) {
      this.isDateTimePickerDefault = false;
      this.apiParamsObj.startTime = Number(this.apiParamsObj.startTime);
      this.apiParamsObj.endTime = Number(this.apiParamsObj.endTime);
    }
  }

  // 回傳燈號顏色
  // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
  checkEquipLightColor(num) {
    if (num === null) { return; }
    if (+num === 0) { return 'equip_light_running'; }
    if (+num === 1) { return 'equip_light_idle'; }
    if (+num === 2) { return 'equip_light_alarm'; }
  }

  // 清空陣列資料
  cleanEquipAlarmDataComposeArrData() {
    this.equipAlarmDataCompose.allEquipNameArr = [];
    this.equipAlarmDataCompose.allDownTimeArr = [];
    this.equipAlarmDataCompose.allErrRecordArr = [];

    this.equipAlarmDataCompose.pieChartErrorRecordInfo = [];
    this.equipAlarmDataCompose.allErrorRecordPercentArr = [];

    this.equipAlarmDataCompose.allUtilizationArr = [];
    this.equipAlarmDataCompose.allDownTimeRateArr = [];
    this.equipAlarmDataCompose.allIdleRateArr = [];

    // 清空表格資料
    this.typeLineTableDataArr.map((x) => {
      x.equipValArr = [];
    });
  }

  // 設定預設 Equip Option
  setDefaultEquipOptionName(value) {
    console.log('setDefaultEquipOptionName value:', value);
    this.apiParamsObj.countRankingSelectEquip = value;
    this.apiParamsObj.timeRankingSelectEquip = value;
  }

  // 切換 Sction & Call API
  switchSection(selectedSection: string) {
    if (selectedSection === 'TEST') {
      if (this.isDateTimePickerDefault) {
        this.router.navigate(['/smartFactory/web/phase3-ate']);
      }
      else {
        this.router.navigate(['/smartFactory/web/phase3-ate'], { queryParams: { startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime } });
      }
      return;
    }
    else {
      if (this.isDateTimePickerDefault) {
        this.router.navigate(['/smartFactory/web/equip-alarm-all'], { queryParams: { section: selectedSection } });
      }
      else {
        this.router.navigate(['/smartFactory/web/equip-alarm-all'], { queryParams: { section: selectedSection, startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime } });
      }
    }
    // this.isSvLoading.loading = true;
    // this.cleanEquipAlarmDataComposeArrData();
    // this.apiParamsObj.countRankingSelectEquip = undefined; // 清除暫存設備
    // this.apiParamsObj.timeRankingSelectEquip = undefined;
    // this.apiParamsObj.sectionName = selectedSection;
    // console.log('switchSection 下拉選單切換:', selectedSection, this.apiParamsObj);

    // // 切換 Section 時 option 的選項預設為第一個 option
    // this.getAllOptionName$(this.apiParamsObj.sectionName).subscribe(
    //   () => {
    //     // 等 getAllOptionName$ API 回來才拿得到 第一筆 Option Name
    //     this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
    //   });
  }

  // 切換 Option & Call API
  switchSelectOptionName(selectedLine: string) {
    if (selectedLine === 'All') {
      if (this.isDateTimePickerDefault) {
        this.router.navigate(['/smartFactory/web/equip-alarm-all'], { queryParams: { section: this.apiParamsObj.sectionName } });
      }
      else {
        this.router.navigate(['/smartFactory/web/equip-alarm-all'], { queryParams: { section: this.apiParamsObj.sectionName, startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime } });
      }
    }
    else {
      this.isSvLoading.loading = true;
      this.cleanEquipAlarmDataComposeArrData();
      this.apiParamsObj.countRankingSelectEquip = undefined; // 清除暫存設備
      this.apiParamsObj.timeRankingSelectEquip = undefined;
      this.apiParamsObj.selectOptionName = selectedLine;
      console.log('switchSelectOptionName 下拉選單切換:', selectedLine, this.apiParamsObj);

      this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
    }
  }

  // 切換 Equip Option
  switchEquipOptionName(value, filter: string) {
    this.isSvLoading.loading = true;
    // this.cleanEquipAlarmDataComposeArrData();
    console.log('switchEquipOptionName filter:', filter);
    console.log('switchEquipOptionName value:', value);
    if (filter === 'count') {
      this.apiParamsObj.countRankingSelectEquip = value;
      this.getErrorRankingCountData(this.apiParamsObj.selectOptionName, this.apiParamsObj.countRankingSelectEquip, this.apiParamsObj.startTime, this.apiParamsObj.endTime);
    }
    if (filter === 'time') {
      this.apiParamsObj.timeRankingSelectEquip = value;
      this.getErrorRankingTimeData(this.apiParamsObj.selectOptionName, this.apiParamsObj.timeRankingSelectEquip, this.apiParamsObj.startTime, this.apiParamsObj.endTime);
    }
  }

  // format 資料處理
  pipeTranslateInfo(obj: any) {
    this.dataFormatSvc.objValueNulltoDash(obj);
    console.log('format 資料處理 obj:', obj);

    let totalLineStatusTime = 0;

    const countTotalErrorCount = obj.equipInfo.reduce((pre, cur) => {
      return pre + cur.recordNum;
    }, 0);

    // Data Formatted
    if (obj.latestAlarmTime !== '-') {
      obj.latestAlarmTime = this.dataFormatSvc.pipeDate(obj?.latestAlarmTime, 'YYYY/MM/dd HH:mm');
    }
    if (obj.alarmDuration !== '-') {
      obj.alarmDuration = this.dataFormatSvc.secToDDhhmmss(obj?.alarmDuration);
    }

    if (obj.lineRunningSec !== '-') {
      totalLineStatusTime += obj.lineRunningSec;
      obj.lineRunningFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineRunningSec);
    }
    else {
      obj.lineRunningFormatted = '-';
    }
    if (obj.lineIdleSec !== '-') {
      totalLineStatusTime += obj.lineIdleSec;
      obj.lineIdleFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineIdleSec);
    }
    else {
      obj.lineIdleFormatted = '-';
    }
    if (obj.lineDownTimeSec !== '-') {
      totalLineStatusTime += obj.lineDownTimeSec;
      obj.lineDownTimeFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineDownTimeSec);
    }
    else {
      obj.lineDownTimeFormatted = '-';
    }

    if (obj.lineRunningSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineRunning = this.dataFormatSvc.pipeNumber((+obj?.lineRunningSec / totalLineStatusTime * 100), '0.2-2');
    }
    else {
      obj.lineRunning = '-';
    }
    if (obj.lineIdleSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineIdle = this.dataFormatSvc.pipeNumber((+obj?.lineIdleSec / totalLineStatusTime * 100), '0.2-2');
    }
    else {
      obj.lineIdleSec = '-';
    }
    if (obj.lineDownTimeSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineDownTime = this.dataFormatSvc.pipeNumber(numeral(100).subtract(obj?.lineRunning).subtract(obj?.lineIdle).value(), '0.2-2');
    }
    else {
      obj.lineDownTime = '-';
    }

    obj.equipInfo.map((x, idx) => {

      obj.allEquipNameArr.push(x.equip === null ? '-' : x.equip);
      obj.allDownTimeArr.push(x.downtime === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtime / 60), '0.2-2'));
      obj.allErrRecordArr.push(x.recordNum);
      obj.allErrorRecordPercentArr.push(x.recordNum === null ? '-' : this.dataFormatSvc.pipeNumber((x.recordNum / countTotalErrorCount) * 100, '0.2-2'));

      // pie chart
      obj.pieChartErrorRecordInfo.push({
        color: obj.chartBgColorArr[idx],
        name: x.equip === null ? '-' : x.equip,
        recordNum: x.recordNum === null ? '-' : this.dataFormatSvc.pipeNumber(x.recordNum, '0.0'),
        percent: x.recordNum === null ? '-' : this.dataFormatSvc.pipeNumber((x.recordNum / countTotalErrorCount) * 100, '0.2-2'),
      });

      // Error Record Table
      obj.allUtilizationArr.push(x.utilization === null ? '-' : this.dataFormatSvc.pipeNumber((x.utilization * 100), '0.2-2'));
      obj.allDownTimeRateArr.push(x.downtimeRate === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtimeRate * 100), '0.2-2'));
      obj.allIdleRateArr.push(x.idleRate === null ? '-' : this.dataFormatSvc.pipeNumber((x.idleRate * 100), '0.2-2'));
      x.lastAlarmCode = x.lastAlarmCode === null ? 'None' : x.lastAlarmCode;
      return x;
    });

    this.typeLineTableDataArr[0].equipValArr = obj.allUtilizationArr;
    this.typeLineTableDataArr[1].equipValArr = obj.allDownTimeRateArr;
    this.typeLineTableDataArr[2].equipValArr = obj.allIdleRateArr;
    this.typeLineTableDataArr[3].equipValArr = obj.allErrRecordArr;

    console.log('this.typeLineTableDataArr:', this.typeLineTableDataArr);
  }

  // 更新 圖表資料
  updateAllChartData(obj) {
    // 圓餅圖
    this.pieChartDataArr[0].pieChartLabels = obj.allEquipNameArr;
    this.pieChartDataArr[0].pieChartData[0].data = obj.allErrorRecordPercentArr;

    // 柱狀圖
    this.barChartDataArr[0].barChartLabels = obj.allEquipNameArr;
    obj.allDownTimeArr = obj.allDownTimeArr.map((item) => item.replace(/,/g, ''));
    this.barChartDataArr[0].barChartData[0].data = obj.allDownTimeArr;
  }

  // API get all sectionName
  getAllSectionName$(type) {
    return this.apiSvc.getEquipAlarmSection(type).pipe(
      tap(res => {
        // console.log('getAllSectionName row data res:', res);
        // res = this.forTestDataSvc.allSectionName;
        res.reverse();  // smt -> lod
        res.push('TEST');  // ADD TEST SECTION
        this.sectionNameArr = res;
        console.log('拿到所有 Section Name res:', res);
      }),
    );
  }

  // API get all optionName
  getAllOptionName$(section) {
    return this.apiSvc.getEquipAlarmOption(section).pipe(
      tap(res => {
        this.allOptionNameArr = res;
        this.allOptionNameArr.unshift('All');
        console.log('拿到所有 Option Name res:', res);
        // this.apiParamsObj.selectOptionName = res[0];
        // console.log('API 回傳 Line 第一筆:', this.apiParamsObj.selectOptionName);
      }),
    );
  }


  // API type 的所有資料 ( API 要等 getAllSectionName$ ＆ getAllOptionName$ 拿到資料 )
  getEquipAlarmData$(infoObj) {
    const type = this.apiParamsObj.type;
    const section = this.apiParamsObj.sectionName;
    const selectValue = this.apiParamsObj.selectOptionName;
    const startTime = this.apiParamsObj.startTime;
    const endTime = this.apiParamsObj.endTime;

    return this.apiSvc.getEquipAlarmTypeInfoData(type, section, selectValue, startTime, endTime).pipe(
      tap(res => {
        const isApiEquipAlarmResEmptyArr = res.equipInfo.length === 0;
        // console.log('getEquipAlarmData row data res:', res);
        // res = this.forTestDataSvc.equipResDataArr;

        if (isApiEquipAlarmResEmptyArr) {
          this.isApiResEmptyArrShowNoData = true;
        } else {
          this.isApiResEmptyArrShowNoData = false;
        }

        this.equipAlarmDataCompose = { ...this.equipAlarmDataCompose, ...res };
        console.log('所有資料 this.equipAlarmDataCompose:', this.equipAlarmDataCompose);

        this.pipeTranslateInfo(this.equipAlarmDataCompose);
        this.updateAllChartData(this.equipAlarmDataCompose);
      }),
    );
  }

  // API get all Equip Name ( API 要等 getAllOptionName$ 拿到資料 )
  getAllEquipName$(type, section, selecedOption) {
    return this.apiSvc.getPhase3EquipAlarmAllEquipName(type, section, selecedOption).pipe(
      tap(res => {
        const isApiAllEquipNameResEmptyArr = res === null;
        // console.log('getAllEquipName row data res:', res);
        // res = this.forTestDataSvc.rankingModelNameArray;

        if (isApiAllEquipNameResEmptyArr) {
          this.rankingModelNameArray = this.forTestDataSvc.rankingModelNameArrEmpty;
          this.rankingListCountData = this.forTestDataSvc.rankingListDataEmptyPhase3;
          this.rankingListTimeData = this.forTestDataSvc.rankingListDataEmptyPhase3;
          return;
        }
        this.rankingModelNameArray = [];
        this.rankingModelNameArray[0] = { equip: 'All', sourceId: '' };
        for (const item of res) {
          this.rankingModelNameArray.push(item);
        }
        // 設定預設
        this.apiParamsObj.countRankingSelectEquip = this.apiParamsObj.countRankingSelectEquip || this.rankingModelNameArray[0].sourceId;
        this.apiParamsObj.timeRankingSelectEquip = this.apiParamsObj.timeRankingSelectEquip || this.rankingModelNameArray[0].sourceId;
        console.log('拿到所有設備名稱 this.equipAlarmDataCompose:', this.equipAlarmDataCompose);
      }),
    );
  }

  // API => Rankin Count Data Top5
  getErrorRankingCountData(selecedOption, equipName, startTime, endTime, sortType = 'count') {
    this.apiSvc.getPhase3EquipAlarmRanking(sortType, selecedOption, equipName, startTime, endTime).pipe(
      take(1),
      tap((res) => {
        const isApiRankTopTenEmptyArr = res === null;
        // res = this.forTestDataSvc.rankingListCountData;
        console.log('getErrorRankingCountData res:', res);
        if (isApiRankTopTenEmptyArr) {
          this.rankingListCountData = this.forTestDataSvc.rankingListDataEmptyPhase3;
          return;
        }

        const addAllCountNumber = res.reduce((previousValue, currentValue) => {
          return previousValue + (+currentValue.count);
        }, 0);

        res.map((item) => {
          item.percent = this.dataFormatSvc.pipeNumber((item.count / addAllCountNumber) * 100, '0.2-2');
          item.count = this.dataFormatSvc.pipeNumber((item.count), '0.2-2');
          return item;
        });

        this.rankingListCountData = res;
      })).subscribe((x) => {
        this.isSvLoading.loading = false;
      });
  }

  // API => Rankin Time Data Top5
  getErrorRankingTimeData(selecedOption, equipName, startTime, endTime, sortType = 'time') {
    this.apiSvc.getPhase3EquipAlarmRanking(sortType, selecedOption, equipName, startTime, endTime).pipe(
      take(1),
      tap((res) => {
        const isApiRankTopTenEmptyArr = res === null;
        // res = this.forTestDataSvc.rankingListTimeData;
        console.log('getErrorRankingTimeData res:', res);
        if (isApiRankTopTenEmptyArr) {
          this.rankingListTimeData = this.forTestDataSvc.rankingListDataEmptyPhase3;
          return;
        }

        const addAllCountNumber = res.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.stayTime;
        }, 0);

        res.map((item) => {
          item.percent = this.dataFormatSvc.pipeNumber((item.stayTime / addAllCountNumber) * 100, '0.2-2');
          item.stayTime = this.dataFormatSvc.secToDDhhmmss(item.stayTime);
          return item;
        });

        this.rankingListTimeData = res;
      })).subscribe((x) => {
        this.isSvLoading.loading = false;
      });
  }

  // API All Equip alarm data
  getAllApiFn(Obj) {
    this.renewedTime = moment().valueOf();
    return this.getAllSectionName$(this.apiParamsObj.type).pipe(
      switchMap(res => this.getAllOptionName$(
        this.apiParamsObj.sectionName)
      ),
      switchMap(res => this.getEquipAlarmData$(Obj)),
      switchMap(res => this.getAllEquipName$(
        this.apiParamsObj.type,
        this.apiParamsObj.sectionName,
        this.apiParamsObj.selectOptionName)
      ),
    ).subscribe((res) => {
      this.getErrorRankingCountData(
        this.apiParamsObj.selectOptionName,
        this.apiParamsObj.countRankingSelectEquip,
        this.apiParamsObj.startTime,
        this.apiParamsObj.endTime);

      this.getErrorRankingTimeData(
        this.apiParamsObj.selectOptionName,
        this.apiParamsObj.timeRankingSelectEquip,
        this.apiParamsObj.startTime,
        this.apiParamsObj.endTime);
      this.snapshotTime = this.renewedTime;
      console.log('最後 this.apiParamsObj:', this.apiParamsObj);
    });
  }

  // API Updat Data
  getUpdatDataApiFn(infoObj, selectOptionName) {
    if (this.updatingDataSubscription != null) {
      this.updatingDataSubscription.unsubscribe();
    }
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    this.renewedTime = moment().valueOf();
    this.updatingDataSubscription = this.getEquipAlarmData$(infoObj).pipe(
      switchMap(res => this.getAllEquipName$(
        infoObj.type,
        infoObj.sectionName,
        selectOptionName
      )
      ),
    ).subscribe((res) => {
      this.getErrorRankingCountData(
        infoObj.selectOptionName,
        infoObj.countRankingSelectEquip,
        infoObj.startTime,
        infoObj.endTime
      );
      this.getErrorRankingTimeData(
        infoObj.selectOptionName,
        infoObj.timeRankingSelectEquip,
        infoObj.startTime,
        infoObj.endTime
      );
      if (this.pageAutoRefresh) { this.rxTimerStart(); }
      this.updateAllChartData(this.equipAlarmDataCompose);
      this.snapshotTime = this.renewedTime;
    });
    return this.updatingDataSubscription;
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.cleanEquipAlarmDataComposeArrData();
    this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
  }

  // Efficiency pop-up event
  onClickDownload(filter: string, paramObj) {
    console.log('onClickDownload: ', filter, paramObj);
    this.dialog.open(Phase3EquipAlarmDownloadDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
      height: '280px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        option: filter,
        startTime: paramObj.startTime,
        endTime: paramObj.endTime,
        line: paramObj.selectOptionName,
        section: paramObj.sectionName,
      },
    });
  }

  onClickExpandList(paramObj) {
    console.log('onClickExpandList:', paramObj);
    this.dialog.open(Phase3ExpandListDialogDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '800px',
      maxHeight: 'calc(80vh, 32px)',
      autoFocus: false,
      data: {
        startTime: paramObj.startTime,
        endTime: paramObj.endTime,
        line: paramObj.selectOptionName
      },
    });
  }
}

interface DownloadParam extends ExpandListApiParam {
  option: string;
  section: string;
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './dialog/app-download-dialog.component.html',
  styleUrls: ['./dialog/app-download-dialog.component.scss']
})
export class Phase3EquipAlarmDownloadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<Phase3EquipAlarmDownloadDialogComponent>,
    public apiSvc: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DownloadParam) { }

  ngOnInit() {
    console.log('ngOnInit data:', this.data);
  }

  onClickDownload(option: string) {
    if (option === 'Error Record') {
      console.log('download option:', option);
      // call download Error Record api
      return window.open(`${this.apiSvc.APIUrl}/download/AlarmCodeList?startTime=${this.data.startTime}&endTime=${this.data.endTime}&line=${this.data.line}`);
    }
    else if (option === 'Equipment Data') {
      console.log('download option:', option);
      // call download Equipment Data api
      return window.open(`${this.apiSvc.APIUrl}/download/alarmEquipsInfo?type=Line&section=${this.data.section}&select=${this.data.line}&startTime=${this.data.startTime}&endTime=${this.data.endTime}`);
    }
  }
}

const EXPAND_LIST_COLUMNS: string[] = ['sourceId', 'status', 'alarmCode', 'time'];
interface ExpandListApiParam {
  startTime: number;
  endTime: number;
  line: string;
}

interface ExpandListApiRes {
  sourceId: string;
  time: string;
  status: string;
  alarmCode: string;
}

@Component({
  selector: 'app-expand-list-dialog',
  templateUrl: './dialog/app-expand-list-dialog.component.html',
  styleUrls: ['./dialog/app-expand-list-dialog.component.scss']
})
export class Phase3ExpandListDialogDataDialogComponent implements OnInit, AfterViewInit {
  constructor(
    public apiSvc: ApiService,
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<Phase3ExpandListDialogDataDialogComponent>,
    public isSvLoading: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: ExpandListApiParam,
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = EXPAND_LIST_COLUMNS;
  listDataSource = new MatTableDataSource();

  ngOnInit() {
    console.log('ngOnInit data:', this.data);
  }

  ngAfterViewInit() {
    this.apiSvc.getPhase3ErrorRecordExpandList(this.data.startTime, this.data.endTime, this.data.line).pipe(
      tap((res: ExpandListApiRes[]) => {
        console.log('ExpandListApiRes:', res);
        this.listDataSource.data = res;
      })
    ).subscribe();
  }

  sortChange(sortState: Sort) {
    this.listDataSource.sort = this.sort;
  }

  getEquipmentStatusLight(status: EquipmentStatus) {
    switch (status) {
      case EquipmentStatus.RUNNING:
        return '#0E8E58';
      case EquipmentStatus.IDLE:
        return '#E3B953';
      case EquipmentStatus.ALARM:
        return '#D2574A';
      default:
        return '#2A2A2A';
    }
  }

}
