import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root',
})

export class CsvHelperService {

  constructor(
    private papa: Papa,
  ) { }

  // regexDate = /^20\d{2}-\d{1,2}-\d{1,2}$/m;
  regexModel = /^[\w-+]*$/m;
  regexSide = /^[A-B]{1}$/m;
  regexLineName = /^[A-Z0-9]{1}[\w\s\u4e00-\u9fff-]*$/m;
  regexGroupName = /^\w{1}[\w\s-]*$/m;
  regexStationName = /^\w{1}[\w\s\u4e00-\u9fff-]*$/m;
  regexLocationId = /^[A-Z]{1}-[\w]*-[0-9]{2}$/m;
  regexLocationName = /^\w{1}[\w\s-]*$/m;
  regexSourceId = /^\w{1}[\w\s-]*$/m;
  regexType = /^[A-Z]*$/m;

  sectionName = ['SMT', 'LOD', 'CAS', 'FAL', 'PKA'];

  getCsvData = (file, delimiter = ',', isHeader = true) => {
    return new Promise<object[]>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event: any) => {
        const csv = event.target.result; // Content of CSV file
        const data = [];
        this.papa.parse(csv, {
          skipEmptyLines: true,
          header: isHeader,
          delimiter,
          error: (err) => {
            console.log('Parsed error:', err);
          },
          step: (results, parser) => {
            data.push(results.data);
            // console.log('Row data:', results.data);
            // console.log('Row errors:', results.errors);
          },
          complete: (results) => {
            console.log('Parsed complete:', data);
            resolve(data);
          },
        });
      };
    });
  }

  // SMT標工 (smtStdTime)
  getSmtStdTime = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    // 前2筆資料略過不上傳(客戶註解用的欄位)
    for (let i = 0; i < 2; i++) {
      data.shift();
    }
    try {
      this.checkRegex(data, 'partNo', this.regexModel);
      this.checkRegex(data, 'side', this.regexSide);
      this.checkRegex(data, 'line', this.regexLineName);
      this.checkNumber(data, 'cycleTimeMin');
      this.checkNumber(data, 'changeOverMin');
      return data;
    }
    catch (ex) {
      console.log('getSmtStdTime catch error:', ex);
      return null;
    }
  }

  // 各製程段標準換線工時 (stdChangeTime)
  getStdChageTime = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkRegex(data, 'partNo', this.regexModel);
      this.checkRegex(data, 'side', this.regexSide);
      this.checkNumber(data, 'changeOverMin');
      this.checkSection(data, 'section');
      return data;
    }
    catch (ex) {
      console.log('getStdChageTime catch error:', ex);
      return null;
    }
  }

  // MO標準工期 (moStdDuration)
  getMoStdDuration = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkRegex(data, 'partNo', this.regexModel);
      this.checkNumber(data, 'overdueInDay');
      return data;
    }
    catch (ex) {
      console.log('getMoStdDuration catch error:', ex);
      return null;
    }
  }

  // MO逾期天數扣除假日 (nonWorkingDate)
  getNonWorkingDate = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkDate(data, 'nonWorkingDate');
      return data;
    }
    catch (ex) {
      console.log('getNonWorkingDate catch error:', ex);
      return null;
    }
  }

  // SDC站點更新上傳 (sdcStationData)
  getSdcStationData = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkRegex(data, 'section', this.regexGroupName);
      this.checkRegex(data, 'line', this.regexLineName);
      this.checkRegex(data, 'station', this.regexStationName);
      this.checkBoolean(data, 'enable');
      return data;
    }
    catch (ex) {
      console.log('getSdcStationData catch error:', ex);
      return null;
    }
  }

  // 設備資料訊息 (deviceId)
  getDeviceId = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkRegex(data, 'sourceId', this.regexSourceId);
      // this.checkSection(data, 'section');  // deviceId 不檢查 section
      this.checkRegex(data, 'line', this.regexLineName);
      this.checkNumber(data, 'order');
      this.checkRegex(data, 'deviceType', this.regexType);
      this.checkRegex(data, 'locationId', this.regexLocationId);
      this.checkBoolean(data, 'enable');
      return data;
    }
    catch (ex) {
      console.log('getDeviceId catch error:', ex);
      return null;
    }
  }

  // 樓層區域名稱 (displayName)
  getDisplayNameData = async (context, delimiter = ',', isHeader = true) => {
    const data = await this.getCsvData(context, delimiter, isHeader);
    try {
      this.checkRegex(data, 'locationId', this.regexLocationId);
      // this.checkRegex(data, 'name', this.regexLocationName);
      return data;
    }
    catch (ex) {
      console.log('getDisplayNameData catch error:', ex);
      return null;
    }
  }

  checkRegex = (data, propertyName, regexStr) => {
    const regex = regexStr;
    let regexCount = 0;

    data.forEach(x => {
      if (Reflect.has(x, propertyName)) {
        const value = Reflect.get(x, propertyName);
        if (regex.test(value)) {
          regexCount++;
        }
        else {
          console.log('regex type error:', value);
        }
      }
    });
    console.log(`regexCount:${regexCount}, lenght:${data.length}`);
    if (regexCount === data.length) {
      return;
    }
    throw new Error(`Column (${propertyName}) not match`);
  }

  checkSection(data, propertyName) {
    let regexCount = 0;
    data.forEach(x => {
      if (Reflect.has(x, propertyName)) {
        const value = Reflect.get(x, propertyName);
        if (this.sectionName.some(item => item === value)) {
          regexCount++;
        }
        else {
          console.log(`Error section:${value}`);
        }
      }
    });

    if (regexCount === data.length) {
      return;
    }
    throw new Error(`Column (${propertyName}) not match`);
  }

  checkDate = (data, propertyName) => {
    let regexCount = 0;
    data.forEach(x => {
      if (Reflect.has(x, propertyName)) {
        const value = Reflect.get(x, propertyName);
        if (moment(value).isValid()) {
          regexCount++;
        }
        else {
          console.log(`Error date:${value}`);
        }
      }
    });

    if (regexCount === data.length) {
      return;
    }
    throw new Error(`Column (${propertyName}) not match`);
  }

  checkNumber = (data, propertyName) => {
    let regexCount = 0;
    data.forEach(x => {
      if (Reflect.has(x, propertyName)) {
        const value = Reflect.get(x, propertyName);
        if (this.isNumeric(value)) {
          regexCount++;
        }
      }
    });

    if (regexCount === data.length) {
      return;
    }
    throw new Error(`Column (${propertyName}) not match`);
  }

  checkBoolean = (data, propertyName) => {
    let regexCount = 0;
    data.forEach(x => {
      if (Reflect.has(x, propertyName)) {
        const value = Reflect.get(x, propertyName);
        if (this.isBoolean(value)) {
          regexCount++;
        }
      }
    });

    if (regexCount === data.length) {
      return;
    }
    throw new Error(`Column (${propertyName}) not match`);
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  isBoolean(n) {
    return (n === 'TRUE' || n === 'FALSE');
  }
}
