import { RealTimeClockService } from './../_services/real-time-clock.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../_services/apiSvc.service';

import { DatePipe } from '@angular/common';

// import * as Chart from 'chart.js';
import { Chart } from '../../../node_modules/chart.js';

// 套件名稱重複
// import { Chart as Gauge } from 'jscharting';
import * as JSC from 'jscharting';
import { SpinnerService } from '../_services/spinner.service';
JSC.defaults({ baseUrl: 'assets/js/dist' });


@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  providers: [DatePipe]
})
export class EnvironmentComponent implements OnInit, OnDestroy {
  temperatureLinechart;
  humidityLinechart;
  PM25Linechart;
  CO2Linechart;

  temperatureGaugechart;
  humidityGaugechart;
  PM25Gaugechart;
  CO2Gaugechart;

  currentValue = {
    t: 0,
    h: 0,
    p: 0,
    c: 0
  };

  gaugeConfig = {
    backColor: ['#101010', .8],
    title: {
      fontSize: 50,
      fontColor: '#FFF',
    },
    tick: {
      fontSize: 23,
      fontColor: '#FFF',
    },
    value: {
      fontSize: 60,
      fontColor: '#FFF',
    },
    unit: {
      fontSize: 28,
      fontColor: '#FFF',
    }
  };

  updateTimer;
  timeFrame = '1d';
  timeScope = '15m';

  curType = 'assy';
  currentTimeTmp;

  constructor(
    private datePipe: DatePipe,
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService
  ) {
    this.isSvLoading.loading = true;
  }

  ngOnInit() {
    this.initTemperatureChart();
    this.initHumidityChart();
    this.initPM25Chart();
    this.initCO2Chart();

    this.getEnvironmentData(this.timeFrame, this.timeScope);

    this.updateTimer = setInterval(() => {
      this.getCurrentData();
    }, 30000);
  }

  // 初始化 儀表板
  initTemperatureChart() {
    this.temperatureGaugechart = new JSC.Chart('gauge_T', {
      debug: false,
      legend_visible: false,
      defaultTooltip_enabled: false,
      xAxis_spacingPercentage: 0.5,
      box_fill: this.gaugeConfig.backColor,
      title: {
        position: 'full',
        padding: 10,
        boxVisible: false,
        label: {
          text: 'Temperature',
          color: this.gaugeConfig.title.fontColor,
          style_fontSize: this.gaugeConfig.title.fontSize,
          style_fontWeight: 'normal', // only 'bold' / 'normal'
          align: 'center'
        }
      },
      annotations: [{
        label_text: '\u2103',
        position: 'inside bottom right',
        outline_visible: false,
        margin: [-20, 15],
        label: {
          color: this.gaugeConfig.unit.fontColor,
          style_fontSize: this.gaugeConfig.unit.fontSize,
        }
      }],
      xAxis: {
        defaultTick: {
          gridLine_color: '#5F5F5F',
        },
      },
      yAxis: [{
        id: 'ax1',
        scale_range: [20, 30],
        defaultTick: {
          padding: 5,
          enabled: false,
          label: {
            color: this.gaugeConfig.tick.fontColor,
            style_fontSize: this.gaugeConfig.tick.fontSize,
            style_fontWeight: 'normal'
          }
        },
        customTicks: [22, 28],
        line: {
          width: 5,
          breaks: {},
          color: 'smartPalette:pal1',
        },
      }],
      defaultSeries: {
        mouseTracking_enabled: false,
        type: 'gauge column roundcaps',
        innerSize: '60%',
        shape: {
          label: {
            margin: [15, 0],
            text: '%value',
            align: 'center',
            verticalAlign: 'middle',
            style_fontSize: this.gaugeConfig.value.fontSize,
          },
        },
      },
      series: [{
        yAxis: 'ax1',
        palette: {
          id: 'pal1',
          pointValue: '%value',
          ranges: [
            { value: [20, 22], color: '#ff0000' },
            { value: [22, 28], color: '#00FF19' },
            { value: [28, 30], color: '#ff0000' },
          ],
        },
        shape_label: {
          text: '{%value+20}',
          color: '#FFF'
        },
        points: [['x', [20, 0]]]
      }]
    });

    this.temperatureLinechart = new Chart('canvas_T', {
      type: 'LineWithLine',
      data: {
        labels: [],
        datasets: [{
          label: '\u2103 / hour',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: true,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(5, 255, 0)',
          borderWidth: 3
        }, {
          label: '',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 3
        }]
      },
      options: {
        title: {
          display: false,
          text: 'Temperature History Today',
          fontColor: '#FFF',
        },
        tooltips: {
          // mode: 'label',
          titleAlign: 'center',
          intersect: false,
          callbacks: {
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: 'rgb(5, 255, 0)',
              };
            },
            label(tooltipItem, data) {
              return `  Temperature:  ${tooltipItem.value} \u2103`;
            }
          }
        },
        legend: {
          position: 'bottom',
          align: 'end',
          labels: {
            fontColor: '#FFF',
            fontSize: 20,
            boxWidth: 0,
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 6,
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH',
                day: 'MM/DD'
              }
            },
            distribution: 'linear',
            ticks: {
              fontColor: '#FFF',
              fontSize: 18,
              padding: 15,
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }],
          yAxes: [{
            ticks: {
              min: 10,
              max: 35,
              stepSize: 2.5,
              fontColor: '#FFF',
              beginAtZero: true,
              padding: 15,
              fontSize: 18,
              callback(value, index) {
                if (index % 2 === 0) { return value; }
                return '';
              },
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }]
        },
        annotation: {
          annotations: [
            // {
            //   id: 'box',
            //   type: 'box',
            //   yScaleID: 'y-axis-0',
            //   yMin: 30,
            //   yMax: 35,
            //   backgroundColor: 'rgba(255, 0, 0, 0.3)',
            //   // borderColor: 'rgb(255, 0, 0)',
            //   borderWidth: 0.5
            // },
            // {
            //   type: 'box',
            //   yScaleID: 'y-axis-0',
            //   yMin: 20,
            //   yMax: 25,
            //   borderWidth: 0.5,
            //   backgroundColor: 'rgba(255, 255, 0, 0.3)',
            // }
          ],
        },
        plugins: {
          p1: false   // disable plugin 'p1' for this instance
        }
      }
    });
  }
  // 初始化 儀表板
  initHumidityChart() {
    this.humidityGaugechart = new JSC.Chart('gauge_H', {
      debug: false,
      legend_visible: false,
      defaultTooltip_enabled: false,
      xAxis_spacingPercentage: 0.5,
      box_fill: this.gaugeConfig.backColor,
      title: {
        position: 'full',
        padding: 10,
        boxVisible: false,
        label: {
          text: 'Humidity',
          color: this.gaugeConfig.title.fontColor,
          style_fontSize: this.gaugeConfig.title.fontSize,
          style_fontWeight: 'normal', // only 'bold' / 'normal'
          align: 'center'
        }
      },
      annotations: [{
        label_text: '%',
        position: 'inside bottom right',
        outline_visible: false,
        margin: [-20, 15],
        label: {
          color: this.gaugeConfig.unit.fontColor,
          style_fontSize: this.gaugeConfig.unit.fontSize,
        }
      }],
      xAxis: {
        defaultTick: {
          gridLine_color: '#5F5F5F',
        },
      },
      yAxis: [{
        id: 'ax1',
        scale_range: [20, 100],
        defaultTick: {
          padding: 5,
          enabled: false,
          label: {
            color: this.gaugeConfig.tick.fontColor,
            style_fontSize: this.gaugeConfig.tick.fontSize,
            style_fontWeight: 'normal'
          }
        },
        customTicks: [40, 80],
        line: {
          width: 5,
          breaks: {},
          color: 'smartPalette:pal1',
        },
      }],
      defaultSeries: {
        mouseTracking_enabled: false,
        type: 'gauge column roundcaps',
        innerSize: '60%',
        shape: {
          label: {
            margin: [15, 0],
            text: '%value',
            align: 'center',
            verticalAlign: 'middle',
            style_fontSize: this.gaugeConfig.value.fontSize,
          },
        },
      },
      series: [{
        yAxis: 'ax1',
        palette: {
          id: 'pal1',
          pointValue: '%value',
          ranges: [
            { value: [20, 40], color: '#ff0000' },
            { value: [40, 80], color: '#00FF19' },
            { value: [80, 100], color: '#ff0000' },
          ],
        },
        shape_label: {
          text: '{%value+20}',
          color: '#FFF'
        },
        points: [['x', [20, 20]]]
      }]
    });

    this.humidityLinechart = new Chart('canvas_H', {
      type: 'LineWithLine',
      data: {
        labels: [],
        datasets: [{
          label: '% / hour',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: true,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(5, 255, 0)',
          borderWidth: 3
        }, {
          label: '',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 3
        }]
      },
      options: {
        title: {
          display: false,
          text: 'Humidity History Today',
          fontColor: '#FFF',
        },
        tooltips: {
          titleAlign: 'center',
          intersect: false,
          callbacks: {
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: 'rgb(5, 255, 0)',
              };
            },
            label(tooltipItem, data) {
              return `  Humidity:  ${tooltipItem.value} %H`;
            }
          }
        },
        legend: {
          position: 'bottom',
          align: 'end',
          labels: {
            fontColor: '#FFF',
            fontSize: 20,
            boxWidth: 0,
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 6,
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH',
                day: 'MM/DD'
              }
            },
            distribution: 'linear',
            ticks: {
              fontColor: '#FFF',
              fontSize: 18,
              padding: 15,
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }],
          yAxes: [{
            ticks: {
              min: 20,
              max: 100,
              stepSize: 20,
              fontColor: '#FFF',
              beginAtZero: true,
              padding: 15,
              fontSize: 18,
              callback(value, index) {
                if (index % 2 === 0) { return value; }
                return '';
              },
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }]
        },
        annotation: {
          annotations: [],
        },
        plugins: {
          p1: false   // disable plugin 'p1' for this instance
        }
      }
    });
  }
  // 初始化 儀表板
  initPM25Chart() {
    this.PM25Gaugechart = new JSC.Chart('gauge_P', {
      debug: false,
      legend_visible: false,
      defaultTooltip_enabled: false,
      xAxis_spacingPercentage: 0.5,
      box_fill: this.gaugeConfig.backColor,
      title: {
        position: 'full',
        padding: 10,
        boxVisible: false,
        label: {
          text: 'PM2.5',
          color: this.gaugeConfig.title.fontColor,
          style_fontSize: this.gaugeConfig.title.fontSize,
          style_fontWeight: 'normal', // only 'bold' / 'normal'
          align: 'center'
        }
      },
      annotations: [{
        label_text: 'μg/m<sup>3<sup>',
        position: 'inside bottom right',
        outline_visible: false,
        margin: [-20, 10],
        label: {
          color: this.gaugeConfig.unit.fontColor,
          style_fontSize: this.gaugeConfig.unit.fontSize,
        }
      }],
      xAxis: {
        defaultTick: {
          gridLine_color: '#5F5F5F',
        },
      },
      yAxis: [{
        id: 'ax1',
        scale_range: [0, 300],
        defaultTick: {
          padding: 5,
          enabled: false,
          label: {
            color: this.gaugeConfig.tick.fontColor,
            style_fontSize: this.gaugeConfig.tick.fontSize,
            style_fontWeight: 'normal'
          }
        },
        customTicks: [200],
        line: {
          width: 5,
          breaks: {},
          color: 'smartPalette:pal1',
        },
      }],
      defaultSeries: {
        mouseTracking_enabled: false,
        type: 'gauge column roundcaps',
        innerSize: '60%',
        shape: {
          label: {
            margin: [15, 0],
            text: '%value',
            align: 'center',
            verticalAlign: 'middle',
            style_fontSize: this.gaugeConfig.value.fontSize,
          },
        },
      },
      series: [{
        yAxis: 'ax1',
        palette: {
          id: 'pal1',
          pointValue: '{%yValue}',
          ranges: [
            { value: [0, 200], color: '#00FF19' },
            { value: [200, 300], color: '#ff0000' },
          ],
        },
        shape_label: {
          text: '%value',
          color: '#FFF'
        },
        points: [['x', 0]],
      }]
    });

    this.PM25Linechart = new Chart('canvas_P', {
      type: 'LineWithLine',
      data: {
        labels: [],
        datasets: [{
          label: 'μg/m3 / hour',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: true,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(5, 255, 0)',
          borderWidth: 3
        }, {
          label: '',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 3
        }]
      },
      options: {
        title: {
          display: false,
          text: 'PM2.5 ppm History Today',
          fontColor: '#FFF',
        },
        tooltips: {
          titleAlign: 'center',
          intersect: false,
          callbacks: {
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: 'rgb(5, 255, 0)',
              };
            },
            label(tooltipItem, data) {
              return `  PM2.5:  ${tooltipItem.value} mg/m3`;
            }
          }
        },
        legend: {
          position: 'bottom',
          align: 'end',
          labels: {
            fontColor: '#FFF',
            fontSize: 20,
            boxWidth: 0,
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 6,
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH',
                day: 'MM/DD'
              }
            },
            distribution: 'linear',
            ticks: {
              fontColor: '#FFF',
              fontSize: 18,
              padding: 15,
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,
              max: 300,
              stepSize: 20,
              fontColor: '#FFF',
              beginAtZero: true,
              padding: 15,
              fontSize: 18,
              callback(value, index) {
                if (index % 2 === 0) { return value; }
                return '';
              },
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }]
        },
        annotation: {
          annotations: [],
        },
        plugins: {
          p1: false   // disable plugin 'p1' for this instance
        }
      }
    });
  }
  // 初始化 儀表板
  initCO2Chart() {
    this.CO2Gaugechart = new JSC.Chart('gauge_C', {
      debug: false,
      legend_visible: false,
      defaultTooltip_enabled: false,
      xAxis_spacingPercentage: 0.5,
      box_fill: this.gaugeConfig.backColor,
      title: {
        position: 'full',
        padding: 10,
        boxVisible: false,
        label: {
          text: 'CO2',
          color: this.gaugeConfig.title.fontColor,
          style_fontSize: this.gaugeConfig.title.fontSize,
          style_fontWeight: 'normal', // only 'bold' / 'normal'
          align: 'center'
        }
      },
      annotations: [{
        label_text: 'PPM',
        position: 'inside bottom right',
        outline_visible: false,
        margin: [-20, 15],
        label: {
          color: this.gaugeConfig.unit.fontColor,
          style_fontSize: this.gaugeConfig.unit.fontSize,
        }
      }],
      xAxis: {
        defaultTick: {
          gridLine_color: '#5F5F5F',
        },
      },
      yAxis: [{
        id: 'ax1',
        scale_range: [0, 1500],
        defaultTick: {
          padding: 5,
          enabled: false,
          label: {
            color: this.gaugeConfig.tick.fontColor,
            style_fontSize: this.gaugeConfig.tick.fontSize,
            style_fontWeight: 'normal'
          }
        },
        customTicks: [1000],
        line: {
          width: 5,
          breaks: {},
          color: 'smartPalette:pal1',
        },
      }],
      defaultSeries: {
        mouseTracking_enabled: false,
        type: 'gauge column roundcaps',
        innerSize: '60%',
        shape: {
          label: {
            margin: [15, 0],
            text: '%value',
            align: 'center',
            verticalAlign: 'middle',
            style_fontSize: this.gaugeConfig.value.fontSize,
          },
        },
      },
      series: [{
        yAxis: 'ax1',
        palette: {
          id: 'pal1',
          pointValue: '{%yValue}',
          ranges: [
            { value: [0, 1000], color: '#00FF19' },
            { value: [1000, 1500], color: '#ff0000' },
          ],
        },
        shape_label: {
          text: '%value',
          color: '#FFF'
        },
        points: [['x', 0]],
      }]
    });

    this.CO2Linechart = new Chart('canvas_C', {
      type: 'LineWithLine',
      data: {
        labels: [],
        datasets: [{
          label: 'PPM / hour',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: true,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(5, 255, 0)',
          borderWidth: 3
        }, {
          label: '',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(211, 255, 210, 0.4)',
          borderColor: 'rgb(255, 0, 0)',
          borderWidth: 3
        }]
      },
      options: {
        title: {
          display: false,
          text: 'CO2 ppm History Today',
          fontColor: '#FFF',
        },
        tooltips: {
          titleAlign: 'center',
          intersect: false,
          callbacks: {
            labelColor(tooltipItem, chart) {
              return {
                backgroundColor: 'rgb(5, 255, 0)',
              };
            },
            label(tooltipItem, data) {
              return `  CO2:  ${tooltipItem.value} ppm`;
            }
          }
        },
        legend: {
          position: 'bottom',
          align: 'end',
          labels: {
            fontColor: '#FFF',
            fontSize: 20,
            boxWidth: 0,
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 6,
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH',
                day: 'MM/DD'
              }
            },
            distribution: 'linear',
            ticks: {
              fontColor: '#FFF',
              fontSize: 18,
              padding: 15,
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,
              max: 1500,
              stepSize: 250,
              fontColor: '#FFF',
              beginAtZero: true,
              padding: 15,
              fontSize: 18,
              callback(value, index) {
                if (index % 2 === 0) { return value; }
                return '';
              },
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }]
        },
        annotation: {
          annotations: [],
        },
        plugins: {
          p1: false   // disable plugin 'p1' for this instance
        }
      }
    });
  }

  // chart 更新資料
  addData(chart, data) {
    chart.data.datasets[0].data.push(data);
    // chart.update();
  }

  // 畫下面四個chart表直的紅線
  addMark(chart, time) {
    const data = {
      drawTime: 'beforeDatasetsDraw', // 讓紅線壓在綠線後面
      type: 'box',
      xScaleID: 'x-axis-0',
      xMin: time,
      xMax: time,
      borderWidth: 2,
      borderColor: 'rgba(252, 3, 3, 0.25)',
      backgroundColor: 'rgba(252, 3, 3, 0.25)',
    };
    chart.options.annotation.annotations.push(data);
  }

  changeScale(scale) {
    this.isSvLoading.loading = true;
    // tslint:disable-next-line: curly
    // if (scale === this.timeFrame) return;

    this.timeFrame = scale;
    this.timeScope = scale === '1d' ? '15m' : '1d';

    this.temperatureLinechart.data.datasets[0].data = [];
    this.temperatureLinechart.data.datasets[1].data = [];
    this.temperatureLinechart.data.labels = [];
    this.temperatureLinechart.options.annotation.annotations = [];

    this.humidityLinechart.data.datasets[0].data = [];
    this.humidityLinechart.data.datasets[1].data = [];
    this.humidityLinechart.data.labels = [];
    this.humidityLinechart.options.annotation.annotations = [];

    this.PM25Linechart.data.datasets[0].data = [];
    this.PM25Linechart.data.datasets[1].data = [];
    this.PM25Linechart.data.labels = [];
    this.PM25Linechart.options.annotation.annotations = [];

    this.CO2Linechart.data.datasets[0].data = [];
    this.CO2Linechart.data.datasets[1].data = [];
    this.CO2Linechart.data.labels = [];
    this.CO2Linechart.options.annotation.annotations = [];

    this.getEnvironmentData(this.timeFrame, this.timeScope);
  }

  // 拿所有表格的即時資料
  getCurrentData() {
    this.apiSvc.getEnvironmentCurrentData(this.curType).subscribe(
      res => {
        // toFixed 小數第一位
        this.currentValue.t = +(res.temperature / 1).toFixed(1);
        this.currentValue.h = +(res.humidity / 1).toFixed(1);
        this.currentValue.p = +(res.pm25 / 1).toFixed(1);
        this.currentValue.c = +(res.co2 / 1).toFixed(1);

        // 畫上四個圈的值
        this.temperatureGaugechart.series(0).options({ points: [['x', [20, this.currentValue.t]]] });
        this.humidityGaugechart.series(0).options({ points: [['x', [20, this.currentValue.h]]] });
        this.PM25Gaugechart.series(0).options({ points: [['x', +this.currentValue.p]] });
        this.CO2Gaugechart.series(0).options({ points: [['x', +this.currentValue.c]] });


        // 下方四個 chart 表超標畫紅線
        // if (this.currentValue.t <= 22 || this.currentValue.t >= 28) {
        //   this.temperatureGaugechart.series(0).options({ shape_label: { color: '#ff0000' }});
        // } else {
        //   this.temperatureGaugechart.series(0).options({ shape_label: { color: '#FFF' }});
        // }

        // if (this.currentValue.h <= 40 || this.currentValue.h >= 80) {
        //   this.humidityGaugechart.series(0).options({ shape_label: { color: '#ff0000' }});
        // } else {
        //   this.humidityGaugechart.series(0).options({ shape_label: { color: '#FFF' }});
        // }

        // if (this.currentValue.p >= 50) {
        //   this.PM25Gaugechart.series(0).options({ shape_label: { color: '#ff0000' }});
        // } else {
        //   this.PM25Gaugechart.series(0).options({ shape_label: { color: '#FFF' }});
        // }

        // if (this.currentValue.c >= 1000) {
        //   this.CO2Gaugechart.series(0).options({ shape_label: { color: '#ff0000' }});
        // } else {
        //   this.CO2Gaugechart.series(0).options({ shape_label: { color: '#FFF' }});
        // }


        // 畫下面四個表的點 (this.timeFrame 是一天才需要畫點)
        if (this.timeFrame === '1d' && this.currentTimeTmp !== res.exportTime) {
          // 一分鐘到了之後把時間丟進 currentTimeTmp
          this.currentTimeTmp = res.exportTime;

          this.addData(this.temperatureLinechart, { t: this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: this.currentValue.t });
          this.addData(this.humidityLinechart, { t: this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: this.currentValue.h });
          this.addData(this.PM25Linechart, { t: this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: this.currentValue.p });
          this.addData(this.CO2Linechart, { t: this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: this.currentValue.c });


          // 畫日圖的目前值的紅線
          if (+(res.temperature / 1).toFixed(1) <= 22 || +(res.temperature / 1).toFixed(1) >= 28) {
            this.addMark(this.temperatureLinechart, this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'));
          }
          if (+(res.humidity / 1).toFixed(1) <= 40 || +(res.humidity / 1).toFixed(1) >= 80) {
            this.addMark(this.humidityLinechart, this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'));
          }
          if (+(res.pm25 / 1).toFixed(1) >= 50) {
            this.addMark(this.PM25Linechart, this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'));
          }
          if (+(res.co2 / 1).toFixed(1) >= 1000) {
            this.addMark(this.CO2Linechart, this.datePipe.transform(res.exportTime, 'yyyy-MM-dd HH:mm:ss'));
          }

          this.temperatureLinechart.update();
          this.humidityLinechart.update();
          this.PM25Linechart.update();
          this.CO2Linechart.update();
        }
        this.isSvLoading.loading = false;
      },
      error => {
        console.error('[error log] apiSvc temperature', error);
      }
    );
  }

  // 拿環境歷史資料 history (只會變動下方四個 chart)
  getEnvironmentData(timeFrame, timeScope) {
    this.apiSvc.getEnvironmentHistoryData('temperature,humidity,pm25,co2', timeFrame, timeScope, this.curType).subscribe(
      res => {
        const history = res.historyData;
        // 排序時間
        history.sort((a, b) => {
          const c = new Date(a.exportTime);
          const d = new Date(b.exportTime);
          return +c - +d;
        });

        // 畫歷史資料
        for (const data of history) {
          this.addData(this.temperatureLinechart, { t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.temperature * 1) / 1).toFixed(1) });
          this.addData(this.humidityLinechart, { t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.humidity * 1) / 1).toFixed(1) });
          this.addData(this.PM25Linechart, { t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.pm25 * 1) / 1).toFixed(1) });
          this.addData(this.CO2Linechart, { t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.co2 * 1) / 1).toFixed(1) });
          // 判斷畫線是不是有超標(畫紅線)
          if (timeFrame === '1d') {
            if (+(data.temperature / 1).toFixed(1) <= 22 || +(data.temperature / 1).toFixed(1) >= 28) {
              this.addMark(this.temperatureLinechart, this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'));
            }
            if (+(data.humidity / 1).toFixed(1) <= 40 || +(data.humidity / 1).toFixed(1) >= 80) {
              this.addMark(this.humidityLinechart, this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'));
            }
            if (+(data.pm25 / 1).toFixed(1) >= 50) {
              this.addMark(this.PM25Linechart, this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'));
            }
            if (+(data.co2 / 1).toFixed(1) >= 1000) {
              this.addMark(this.CO2Linechart, this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'));
            }

          }
        }

        // 畫限值(周月 紅線)
        if (this.timeFrame !== '1d') {
          const peakData = res.historyMaxData;
          // 排序時間
          peakData.sort((a, b) => {
            const c = new Date(a.exportTime);
            const d = new Date(b.exportTime);
            return +c - +d;
          });

          // 畫紅線
          for (const data of peakData) {
            this.temperatureLinechart.data.datasets[1].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.temperature * 1) / 1).toFixed(1) });
            this.humidityLinechart.data.datasets[1].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.humidity * 1) / 1).toFixed(1) });
            this.PM25Linechart.data.datasets[1].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.pm25 * 1) / 1).toFixed(1) });
            this.CO2Linechart.data.datasets[1].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.co2 * 1) / 1).toFixed(1) });
          }
        }

        // 重設chart表的參數 (Reset)
        if (this.timeFrame === '1d') {
          this.temperatureLinechart.data.labels = [`${this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd')} 00:00:00`, `${this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')} 24:00:00`];
          this.temperatureLinechart.options.scales.xAxes[0].time.unit = 'hour';
          this.temperatureLinechart.options.scales.xAxes[0].time.unitStepSize = 6;
          this.temperatureLinechart.data.datasets[0].label = '\u2103 / hour';

          this.humidityLinechart.data.labels = [`${this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd')} 00:00:00`, `${this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')} 24:00:00`];
          this.humidityLinechart.options.scales.xAxes[0].time.unit = 'hour';
          this.humidityLinechart.options.scales.xAxes[0].time.unitStepSize = 6;
          this.humidityLinechart.data.datasets[0].label = '% / hour';

          this.PM25Linechart.data.labels = [`${this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd')} 00:00:00`, `${this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')} 24:00:00`];
          this.PM25Linechart.options.scales.xAxes[0].time.unit = 'hour';
          this.PM25Linechart.options.scales.xAxes[0].time.unitStepSize = 6;
          this.PM25Linechart.data.datasets[0].label = 'μg/m3 / hour';

          this.CO2Linechart.data.labels = [`${this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd')} 00:00:00`, `${this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')} 24:00:00`];
          this.CO2Linechart.options.scales.xAxes[0].time.unit = 'hour';
          this.CO2Linechart.options.scales.xAxes[0].time.unitStepSize = 6;
          this.CO2Linechart.data.datasets[0].label = 'PPM / hour';
        } else {
          this.temperatureLinechart.data.labels = [this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd'), this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')];
          this.temperatureLinechart.options.scales.xAxes[0].time.unit = 'day';
          this.temperatureLinechart.options.scales.xAxes[0].time.unitStepSize = 1;
          this.temperatureLinechart.data.datasets[0].label = '\u2103 / day';

          this.humidityLinechart.data.labels = [this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd'), this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')];
          this.humidityLinechart.options.scales.xAxes[0].time.unit = 'day';
          this.humidityLinechart.options.scales.xAxes[0].time.unitStepSize = 1;
          this.humidityLinechart.data.datasets[0].label = '% / day';

          this.PM25Linechart.data.labels = [this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd'), this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')];
          this.PM25Linechart.options.scales.xAxes[0].time.unit = 'day';
          this.PM25Linechart.options.scales.xAxes[0].time.unitStepSize = 1;
          this.PM25Linechart.data.datasets[0].label = 'μg/m3 / day';

          this.CO2Linechart.data.labels = [this.datePipe.transform(history[0].exportTime, 'yyyy-MM-dd'), this.datePipe.transform(history[history.length - 1].exportTime, 'yyyy-MM-dd')];
          this.CO2Linechart.options.scales.xAxes[0].time.unit = 'day';
          this.CO2Linechart.options.scales.xAxes[0].time.unitStepSize = 1;
          this.CO2Linechart.data.datasets[0].label = 'PPM / day';
        }

        this.temperatureLinechart.update();
        this.humidityLinechart.update();
        this.PM25Linechart.update();
        this.CO2Linechart.update();

        this.getCurrentData();
      },
      error => {
        console.error('[error log] apiSvc temperature', error);
      }
    );

  }

  onChange(value) {
    this.curType = value;
    console.log('curType:', this.curType);
    this.changeScale(this.timeFrame);
  }

  ngOnDestroy() {
    // 停掉計時器
    clearInterval(this.updateTimer);
  }
}
