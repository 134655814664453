var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Optional, ViewChild } from '@angular/core';
import { animate, animateChild, keyframes, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { FocusTrapFactory } from '@angular/cdk/a11y';
import { BasePortalOutlet, CdkPortalOutlet } from '@angular/cdk/portal';
var zoomFadeIn = { opacity: 0, transform: 'translateX({{ x }}) translateY({{ y }}) scale({{scale}})' };
var zoomFadeInFrom = {
    opacity: 0,
    transform: 'translateX({{ x }}) translateY({{ y }}) scale({{scale}})',
    transformOrigin: '{{ ox }} {{ oy }}'
};
var OwlDialogContainerComponent = (function (_super) {
    __extends(OwlDialogContainerComponent, _super);
    function OwlDialogContainerComponent(changeDetector, elementRef, focusTrapFactory, document) {
        var _this = _super.call(this) || this;
        _this.changeDetector = changeDetector;
        _this.elementRef = elementRef;
        _this.focusTrapFactory = focusTrapFactory;
        _this.document = document;
        _this.ariaLabelledBy = null;
        _this.animationStateChanged = new EventEmitter();
        _this.isAnimating = false;
        _this.state = 'enter';
        _this.params = {
            x: '0px',
            y: '0px',
            ox: '50%',
            oy: '50%',
            scale: 0
        };
        _this.elementFocusedBeforeDialogWasOpened = null;
        return _this;
    }
    Object.defineProperty(OwlDialogContainerComponent.prototype, "config", {
        get: function () {
            return this._config;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerClass", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerTabIndex", {
        get: function () {
            return -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerId", {
        get: function () {
            return this._config.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerRole", {
        get: function () {
            return this._config.role || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerAriaLabelledby", {
        get: function () {
            return this.ariaLabelledBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerAriaDescribedby", {
        get: function () {
            return this._config.ariaDescribedBy || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDialogContainerComponent.prototype, "owlDialogContainerAnimation", {
        get: function () {
            return { value: this.state, params: this.params };
        },
        enumerable: true,
        configurable: true
    });
    OwlDialogContainerComponent.prototype.ngOnInit = function () {
    };
    OwlDialogContainerComponent.prototype.attachComponentPortal = function (portal) {
        if (this.portalOutlet.hasAttached()) {
            throw Error('Attempting to attach dialog content after content is already attached');
        }
        this.savePreviouslyFocusedElement();
        return this.portalOutlet.attachComponentPortal(portal);
    };
    OwlDialogContainerComponent.prototype.attachTemplatePortal = function (portal) {
        throw new Error('Method not implemented.');
    };
    OwlDialogContainerComponent.prototype.setConfig = function (config) {
        this._config = config;
        if (config.event) {
            this.calculateZoomOrigin(event);
        }
    };
    OwlDialogContainerComponent.prototype.onAnimationStart = function (event) {
        this.isAnimating = true;
        this.animationStateChanged.emit(event);
    };
    OwlDialogContainerComponent.prototype.onAnimationDone = function (event) {
        if (event.toState === 'enter') {
            this.trapFocus();
        }
        else if (event.toState === 'exit') {
            this.restoreFocus();
        }
        this.animationStateChanged.emit(event);
        this.isAnimating = false;
    };
    OwlDialogContainerComponent.prototype.startExitAnimation = function () {
        this.state = 'exit';
        this.changeDetector.markForCheck();
    };
    OwlDialogContainerComponent.prototype.calculateZoomOrigin = function (event) {
        if (!event) {
            return;
        }
        var clientX = event.clientX;
        var clientY = event.clientY;
        var wh = window.innerWidth / 2;
        var hh = window.innerHeight / 2;
        var x = clientX - wh;
        var y = clientY - hh;
        var ox = clientX / window.innerWidth;
        var oy = clientY / window.innerHeight;
        this.params.x = x + "px";
        this.params.y = y + "px";
        this.params.ox = ox * 100 + "%";
        this.params.oy = oy * 100 + "%";
        this.params.scale = 0;
        return;
    };
    OwlDialogContainerComponent.prototype.savePreviouslyFocusedElement = function () {
        var _this = this;
        if (this.document) {
            this.elementFocusedBeforeDialogWasOpened = this.document.activeElement;
            Promise.resolve().then(function () { return _this.elementRef.nativeElement.focus(); });
        }
    };
    OwlDialogContainerComponent.prototype.trapFocus = function () {
        if (!this.focusTrap) {
            this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
        }
        if (this._config.autoFocus) {
            this.focusTrap.focusInitialElementWhenReady();
        }
    };
    OwlDialogContainerComponent.prototype.restoreFocus = function () {
        var toFocus = this.elementFocusedBeforeDialogWasOpened;
        if (toFocus && typeof toFocus.focus === 'function') {
            toFocus.focus();
        }
        if (this.focusTrap) {
            this.focusTrap.destroy();
        }
    };
    __decorate([
        ViewChild(CdkPortalOutlet),
        __metadata("design:type", CdkPortalOutlet)
    ], OwlDialogContainerComponent.prototype, "portalOutlet", void 0);
    __decorate([
        HostBinding('class.owl-dialog-container'),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerClass", null);
    __decorate([
        HostBinding('attr.tabindex'),
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerTabIndex", null);
    __decorate([
        HostBinding('attr.id'),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerId", null);
    __decorate([
        HostBinding('attr.role'),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerRole", null);
    __decorate([
        HostBinding('attr.aria-labelledby'),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerAriaLabelledby", null);
    __decorate([
        HostBinding('attr.aria-describedby'),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerAriaDescribedby", null);
    __decorate([
        HostBinding('@slideModal'),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], OwlDialogContainerComponent.prototype, "owlDialogContainerAnimation", null);
    __decorate([
        HostListener('@slideModal.start', ['$event']),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], OwlDialogContainerComponent.prototype, "onAnimationStart", null);
    __decorate([
        HostListener('@slideModal.done', ['$event']),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], OwlDialogContainerComponent.prototype, "onAnimationDone", null);
    OwlDialogContainerComponent = __decorate([
        Component({
            selector: 'owl-dialog-container',
            template: "<ng-template cdkPortalOutlet></ng-template>",
            animations: [
                trigger('slideModal', [
                    transition('void => enter', [
                        style(zoomFadeInFrom),
                        animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style('*')),
                        animate('150ms', keyframes([
                            style({ transform: 'scale(1)', offset: 0 }),
                            style({ transform: 'scale(1.05)', offset: 0.3 }),
                            style({ transform: 'scale(.95)', offset: 0.8 }),
                            style({ transform: 'scale(1)', offset: 1.0 })
                        ])),
                        animateChild()
                    ], { params: { x: '0px', y: '0px', ox: '50%', oy: '50%', scale: 1 } }),
                    transition('enter => exit', [
                        animateChild(),
                        animate(200, style(zoomFadeIn))
                    ], { params: { x: '0px', y: '0px', ox: '50%', oy: '50%' } })
                ])
            ]
        }),
        __param(3, Optional()), __param(3, Inject(DOCUMENT)),
        __metadata("design:paramtypes", [ChangeDetectorRef,
            ElementRef,
            FocusTrapFactory, Object])
    ], OwlDialogContainerComponent);
    return OwlDialogContainerComponent;
}(BasePortalOutlet));
export { OwlDialogContainerComponent };
