import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from '../_services/spinner.service';
import { DatePipe } from '@angular/common';
import { Chart } from '../../../node_modules/chart.js';
import { ApiService } from '../_services/apiSvc.service';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { fork } from 'cluster';
import { result, set } from 'lodash';
import { RealTimeClockService } from '../_services/real-time-clock.service';


// 更新頻率 => 後端每 15min / 前端每 30min Call API
// 左側圓餅圖 歷史資料 (總數及百分比),  更新頻率 => 後端每 15min / 前端每 30min, Call API
// 右側上方   即時資料 三個區塊,        更新頻率 => 後端每 15min / 前端每 30min, Call API
// 右側下方   Chart 表,                更新頻率 => 後端每 15min / 前端每 30min, Call API

@Component({
  selector: 'app-electricity',
  templateUrl: './electricity.component.html',
  styleUrls: ['./electricity.component.scss'],
  providers: [DatePipe]
})
export class ElectricityComponent implements OnInit, OnDestroy {

  // isLoggedIn: Observable<boolean>;
  // load;

  powerConsumptionPie;
  allElectricityHistory;
  curFloor;
  updateTimer;
  timeFrame = '1d';
  timeScope = '15m';

  currentTimeTmp;
  oldDateTime = new Date();
  newDateTime = null;

  currentValue = {
    L: 0,
    A: 0,
    P: 0,
    L_All: 0,
    A_All: 0,
    P_All: 0,
    L_P: '0',
    A_P: '0',
    P_P: '0',
    All: 0
  };

  constructor(
    private isSvLoading: SpinnerService,
    private datePipe: DatePipe,
    public apiSvc: ApiService,
    public clock: RealTimeClockService
  ) {
    this.isSvLoading.loading = true;
  }

  ngOnInit() {

    // 初始化
    this.initPowerConsumptionPie();
    this.initAllElectricityHistory();

    // API
    this.changeScale(this.timeFrame);

    // 30 secs update Data
    this.updateTimer = setInterval(() => {
      console.clear();
      this.checkSameDayAndUpdate();
    }, 1000 * 30);

    // this.reloadWindow();
  }

  // reload / per 12 hour
  reloadWindow() {
    setTimeout(() => {
      this.ngOnInit();
    }, 615000);
  }

  // 初始化圓餅圖
  initPowerConsumptionPie() {
    this.powerConsumptionPie = new Chart('canvas_Pie', {
      type: 'doughnut',
      data: {
        labels: ['SMT', 'AC', 'ATE'],
        datasets: [{
          data: [0, 0, 0],
          backgroundColor: ['#F2E205', '#11C3C3', '#f15056'],
          borderWidth: 0,
          borderColor: '#FFF'
        }]
      },
      options: {
        title: {
          display: false,
          text: 'Daily Percentage Of Power Consumption',
          position: 'top',
          fontSize: 14,
          fontColor: '#d8d9da',
          padding: 20
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            fontColor: '#d8d9da',
            padding: 15,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => {
                  const ds = data.datasets[0];
                  const arc = chart.getDatasetMeta(0).data[i];
                  const custom = arc && arc.custom || {};
                  const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                  const arcOpts = chart.options.elements.arc;
                  const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                  const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                  const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                  const value = chart.config.data.datasets[chart.getDatasetMeta(0).data[i]._datasetIndex].data[chart.getDatasetMeta(0).data[i]._index];
                  const dataset = data.datasets[chart.getDatasetMeta(0).data[i]._datasetIndex];
                  const sum = dataset.data.reduce((previousValue, curValue, curIndex, array) => {
                    return previousValue + curValue;
                  });
                  const percent = Math.round(((value / sum) * 100));
                  return {
                    text: `${label} </br> ${percent} %`,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                    index: i
                  };
                });
              } else {
                return [];
              }
            }
          }
        },
        tooltips: {
          titleFontSize: 16,
          bodyFontSize: 16,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              // 計算總和
              const sum = dataset.data.reduce((previousValue, curValue, curIndex, array) => {
                return previousValue + curValue;
              });
              const currentValue = dataset.data[tooltipItem.index];
              const percent = ((currentValue / sum) * 100).toFixed(1);
              return `${data.labels[tooltipItem.index]} : ${currentValue} K (${percent}%)`;
            }
          }
        },
      },
    });
  }

  // 初始化 History
  initAllElectricityHistory() {
    this.allElectricityHistory = new Chart('canvas_History', {
      type: 'LineWithLine',
      data: {
        labels: [],
        datasets: [{
          label: 'SMT',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(242, 226, 5, 0.4)',
          borderColor: 'rgb(242, 226, 5)',
          borderWidth: 2
        }, {
          label: 'AC',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(17, 195, 195, 0.4)',
          borderColor: 'rgb(17, 195, 195)',
          borderWidth: 2
        }, {
          label: 'ATE',
          lineTension: 0,
          pointRadius: 0,
          data: [],
          fill: false,
          backgroundColor: 'rgba(195, 17, 145, 0.4)',
          borderColor: 'rgb(195, 17, 145)',
          borderWidth: 2
        }]
      },
      options: {
        title: {
          display: false,
          text: 'Daily Power Consumption For Last 30 Days',
          fontColor: '#FFF',
        },
        tooltips: {
          mode: 'label',
          titleAlign: 'center',
          intersect: false,
          callbacks: {
            label(tooltipItem, data) {
              if (tooltipItem.value >= 1000) {
                return `  ${data.datasets[tooltipItem.datasetIndex].label}: ${(tooltipItem.value / 1000).toFixed(3)} Mil`;
              } else if (+tooltipItem.value !== 0) {
                return `  ${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value} K`;
              } else {
                return `  ${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`;
              }
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start',
          labels: {
            fontColor: '#FFF',
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 6,
              displayFormats: {
                hour: 'HH',
                day: 'MM/DD'
              }
            },
            distribution: 'linear',
            ticks: {
              fontColor: '#FFF',
              fontSize: 20,
              padding: 15,
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,
              // max: 1300,
              // stepSize: 500,
              fontColor: '#FFF',
              padding: 15,
              fontSize: 18,
              callback(value, index, values) {
                if (value >= 1000) {
                  return (value / 1000).toFixed(1) + ' Mil';
                } else {
                  return `${value} K`;
                }
              }
            },
            gridLines: {
              drawTicks: false,
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5
            }
          }]
        },
        plugins: {
          p1: false   // disable plugin 'p1' for this instance
        }
      }
    });
  }

  // {exportTime: "2021-04-27 14:45:00", electricity: "6"}

  // 計算百分比 - 更新圓餅圖
  calculationRatio() {
    this.currentValue.All = this.currentValue.L_All + this.currentValue.A_All + this.currentValue.P_All;
    this.currentValue.L_P = ((this.currentValue.L_All / this.currentValue.All) * 100).toFixed(1);
    this.currentValue.A_P = ((this.currentValue.A_All / this.currentValue.All) * 100).toFixed(1);
    this.currentValue.P_P = ((this.currentValue.P_All / this.currentValue.All) * 100).toFixed(1);
  }

  // API getElectricityHistoryData - 拿當天整天資料 並 更新圓餅圖
  getAllDay() {
    const smt = this.apiSvc.getElectricityHistoryData('smt', '1d', '15m');
    const air = this.apiSvc.getElectricityHistoryData('air', '1d', '15m');
    const ate = this.apiSvc.getElectricityHistoryData('ate', '1d', '15m');

    forkJoin([smt, air, ate]).pipe().subscribe((results) => {
      console.log('getAllDay HistoryData results:', results);
      // results[0] from smt api1
      {
        this.currentValue.L_All = +results[0].total;
        this.calculationRatio();
        this.powerConsumptionPie.data.datasets[0].data[0] = this.currentValue.L_All;
      }
      // results[1] from air api2
      {
        this.currentValue.A_All = +results[1].total;
        this.calculationRatio();
        this.powerConsumptionPie.data.datasets[0].data[1] = this.currentValue.A_All;
      }
      // results[2] from ate api3
      {
        this.currentValue.P_All = +results[2].total;
        this.calculationRatio();
        this.powerConsumptionPie.data.datasets[0].data[2] = this.currentValue.P_All;
      }
      this.powerConsumptionPie.update();
      this.isSvLoading.loading = false;
    });
  }


  // API getElectricityCurrentData 拿 realTime 資料
  getCurrentData() {
    const smt = this.apiSvc.getElectricityCurrentData('smt');
    const ate = this.apiSvc.getElectricityCurrentData('ate');
    const air = this.apiSvc.getElectricityCurrentData('air');

    forkJoin([smt, ate, air]).pipe().subscribe((results) => {
      console.log('getCurrentData 即時資料 => ', results);
      // console.log('this.currentTimeTmp:', this.currentTimeTmp);

      //  results[0] from smt api1
      console.log('SMT 即時資料 => ', results[0].data[0]);
      if (results[0].data.length === 0) {
        console.log('ATE 即時資料, API回傳空資料 =>  ', results[0]);
      }
      this.currentValue.L = results[0].data[0] ? +results[0].data[0].electricity : 0;
      console.log('SMT => ', this.currentValue.L, 'kWh');

      // 檢查資料回傳是空的時候，不要處理 exportTime
      if (results[0].data.length !== 0) {
        this.allElectricityHistory.data.datasets[0].data.push({ t: this.datePipe.transform(results[0].data[0].exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((results[0].data[0].electricity * 1) / 1).toFixed(1) });
        if (this.currentTimeTmp !== results[0].data[0].exportTime) {

          this.currentValue.All = this.currentValue.L_All + this.currentValue.L;
          this.calculationRatio();

          this.powerConsumptionPie.data.datasets[0].data[0] = this.currentValue.L_All;
        }
      }

      //  results[1] from ate api2
      console.log('ATE 即時資料 => ', results[1].data[0]);
      if (results[1].data.length === 0) {
        console.log('ATE 即時資料, API回傳空資料 =>  ', results[1]);
      }
      this.currentValue.P = results[1].data[0] ? +results[1].data[0].electricity : 0;
      console.log('ATE => ', this.currentValue.P, 'kWh');

      // 檢查資料回傳是空的時候，不要處理 exportTime
      if (results[1].data.length !== 0) {
        this.allElectricityHistory.data.datasets[1].data.push({ t: this.datePipe.transform(results[1].data[0].exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((results[1].data[0].electricity * 1) / 1).toFixed(1) });

        if (this.currentTimeTmp !== results[1].data[0].exportTime) {
          this.currentValue.All = this.currentValue.A_All + this.currentValue.A;
          this.calculationRatio();

          this.powerConsumptionPie.data.datasets[0].data[1] = this.currentValue.A_All;
        }
      }


      //  results[2] from air api3
      console.log('AIR 即時資料 => ', results[2].data[0]);
      if (results[2].data.length === 0) {
        console.log('AIR 即時資料, API回傳空資料 =>  ', results[2]);
      }
      this.currentValue.A = results[2].data[0] ? +results[2].data[0].electricity : 0;
      console.log('ATE => ', this.currentValue.A, 'kWh');

      // 檢查資料回傳是空的時候，不要處理 exportTime
      if (results[2].data.length !== 0) {
        this.allElectricityHistory.data.datasets[2].data.push({ t: this.datePipe.transform(results[2].data[0].exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((results[2].data[0].electricity * 1) / 1).toFixed(1) });

        if (this.currentTimeTmp !== results[2].data[0].exportTime) {
          this.currentValue.All = this.currentValue.A_All + this.currentValue.A;
          this.calculationRatio();

          this.powerConsumptionPie.data.datasets[0].data[2] = this.currentValue.A_All;
        }
      }


      // History 暫停顯示即時更新的資料，全部都顯示歷史資料 ( 因為後端是15min才更新資料，每30秒呼叫沒有意義 )
      // this.allElectricityHistory.update();
      this.powerConsumptionPie.update();
      this.currentTimeTmp = results[0].data[0].exportTime;
      this.getAllDay();

    });

  }

  // API getElectricityHistoryData - 拿歷史資料
  getHistoryData(timeFrame, timeScope) {
    const smt = this.apiSvc.getElectricityHistoryData('smt', timeFrame, timeScope);
    const air = this.apiSvc.getElectricityHistoryData('air', timeFrame, timeScope);
    const ate = this.apiSvc.getElectricityHistoryData('ate', timeFrame, timeScope);

    forkJoin([smt, air, ate]).pipe().subscribe(
      (results) => {
        console.log('getHistoryData results:', results);
        // results[0] from smt api1
        {
          if (results[0].data.length !== 0) {
            console.log('SMT 歷史資料 => ', results[0].data);
            results[0].data.sort((a, b) => {
              const c = new Date(a.exportTime);
              const d = new Date(b.exportTime);
              return +c - +d;
            });
            for (const data of results[0].data) {
              this.allElectricityHistory.data.datasets[0].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.electricity * 1) / 1).toFixed(1) });
            }
            if (this.timeFrame === '1d') {
              this.allElectricityHistory.data.labels = [`${this.datePipe.transform(results[0].data[0].exportTime, 'yyyy-MM-dd')} 00:00:00`, `${this.datePipe.transform(results[0].data[results[0].data.length - 1].exportTime, 'yyyy-MM-dd')} 24:00:00`];
            } else {
              this.allElectricityHistory.data.labels = [this.datePipe.transform(results[0].data[0].exportTime, 'yyyy-MM-dd'), this.datePipe.transform(results[0].data[results[0].data.length - 1].exportTime, 'yyyy-MM-dd')];
            }
          }
        }

        //  results[1] from air api2
        {
          if (results[1].data.length !== 0) {
            console.log('AIR 歷史資料 => ', results[1].data);
            results[1].data.sort((a, b) => {
              const c = new Date(a.exportTime);
              const d = new Date(b.exportTime);
              return +c - +d;
            });
            for (const data of results[1].data) {
              this.allElectricityHistory.data.datasets[1].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.electricity * 1) / 1).toFixed(1) });
            }
          }
        }

        //  results[2] from ate api3
        {
          if (results[2].data.length !== 0) {
            console.log('ATE 歷史資料 => ', results[2].data);
            results[2].data.sort((a, b) => {
              const c = new Date(a.exportTime);
              const d = new Date(b.exportTime);
              return +c - +d;
            });
            for (const data of results[2].data) {
              this.allElectricityHistory.data.datasets[2].data.push({ t: this.datePipe.transform(data.exportTime, 'yyyy-MM-dd HH:mm:ss'), y: ((data.electricity * 1) / 1).toFixed(1) });
            }
          }
        }

        this.allElectricityHistory.update();
        // 拿到歷史資料，再拿 realTime 資料
        this.getCurrentData();
      });

  }

  // Start All API => getCurrentData()
  changeScale(scale) {
    this.isSvLoading.loading = true;
    // tslint:disable-next-line: curly
    // if (scale === this.timeFrame) return;
    this.timeFrame = scale;
    this.timeScope = scale === '1d' ? '15m' : '1d';
    this.allElectricityHistory.data.datasets[0].data = [];
    this.allElectricityHistory.data.datasets[1].data = [];
    this.allElectricityHistory.data.datasets[2].data = [];

    this.allElectricityHistory.data.labels = [];
    if (this.timeFrame === '1d') {
      this.allElectricityHistory.options.scales.xAxes[0].time.unit = 'hour';
      this.allElectricityHistory.options.scales.xAxes[0].time.unitStepSize = 6;
    } else {
      this.allElectricityHistory.options.scales.xAxes[0].time.unit = 'day';
      this.allElectricityHistory.options.scales.xAxes[0].time.unitStepSize = 1;
    }
    // 拿歷史資料
    this.getHistoryData(this.timeFrame, this.timeScope);
  }

  // check same day
  checkSameDayAndUpdate() {
    this.newDateTime = new Date();
    // this.newDateTime = 'Fri Feb 26 2021 11:34:09 GMT+0800';
    console.log('this.oldDateTime:', this.oldDateTime);
    console.log('this.newDateTime:', this.newDateTime);

    const datesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

    const checkSameDay = datesAreOnSameDay(this.newDateTime, this.oldDateTime);
    console.log('checkSameDay:', checkSameDay);

    if (checkSameDay) {
      console.log('Update on Same Date');
      this.getCurrentData();
    } else {
      console.log('Update on different Date');
      window.location.reload();
    }
  }

  // 關閉計時器
  ngOnDestroy() {
    clearInterval(this.updateTimer);
  }

}

