import { Component, OnDestroy, OnInit } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { timer, Subject, interval, Subscription, } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../_services/apiSvc.service';

import { ActivatedRoute, Router } from '@angular/router';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import * as moment from 'moment';
import { time } from 'console';
import numeral from 'numeral';

@Component({
  selector: 'app-equipment-alarm',
  templateUrl: './equipment-alarm.component.html',
  styleUrls: ['./equipment-alarm.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class EquipmentAlarmComponent implements OnInit, OnDestroy {


  updatingDataSubscription: Subscription | null = null;
  pageAutoRefresh = true;
  intervalSeconds = 60;
  rxTimer$;

  orderInfoObj = {
    Section: null,
    Type: null,
    Sn: null,
    Side: null
  };

  isApiResEmptyArrShowNoData = false;
  byType: string;
  byTypeMappingObj = {
    line: 'Line',
    mo: 'Mo',
  };

  btnDateSetActiveObj = [
    {
      key: 'Day',
      active: false,
    },
    {
      key: 'Week',
      active: false,
    },
    {
      key: 'Month',
      active: false,
    },
  ];
  btnTypeActiveObj = [
    {
      key: 'Mo',
      active: false,
    },
    {
      key: 'Line',
      active: false,
    }
  ];
  saveChoiceDayString = 'Week';

  changeSectionName;
  sectionNameArr = [];
  allOptionNameArr = [];

  apiParamsObj = {
    type: null,
    sectionName: null || 'SMT',
    selectOptionName: null,
    selectEquip: null,
    startTime: null,
    endTime: null,
  };

  equipAlarmDataCompose = {
    equipInfo: null,
    latestAlarm: null,
    alarmCode: null,
    latestAlarmTime: null,
    alarmDuration: null,
    lineRunning: null,
    lineIdle: null,
    lineDownTime: null,
    lineRunningSec: null,
    lineIdleSec: null,
    lineDownTimeSec: null,
    lineRunningFormatted: null,
    lineIdleFormatted: null,
    lineDownTimeFormatted: null,

    chartBgColorArr: this.dataFormatSvc.chartColorsArr,
    allEquipNameArr: [],
    allDownTimeArr: [],
    allErrRecordArr: [],

    // Error Record 表格
    allUtilizationArr: [],
    allDownTimeRateArr: [],
    allIdleRateArr: [],

    // Pie Chart 圓餅圖
    pieChartDownTimeInfo: [],
    allDownTiemPercentArr: []
  };

  // Ranking Top 10
  rankingModelNameArray = [];
  rankingListData = [];


  // Pie-Chart => Down Time START***************************************
  public pieChartDataArr: any = [
    {
      pieChartType: 'pie',
      // Pie
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
        },
        // label 文字
        legend: {
          display: false,
        },
        // Pie 顯示單位百分比
        tooltips: {
          enabled: true, // 關閉 tooltips
          bodyFontSize: 24,
        }
      },
      // pie color
      donutColors: [
        {
          backgroundColor: this.equipAlarmDataCompose.chartBgColorArr
        }
      ],
      // pieChartLabels: [
      //   'CM3020',
      //   'CM3070',
      //   'SP-1',
      //   'SP-2',
      //   'SP-3',
      //   'SP-4',
      //   'SP-5',
      //   'SP-6',
      //   'SP-7',
      // ],
      pieChartLabels: [],
      pieChartData: [{ data: [] }]
    }
  ];
  // Pie-Chart => Down Time END***************************************

  // Bar-Chart => Error Record START***************************************
  public barChartDataArr: any = [
    {
      chartTitle: 'ATE hiPotTest2',
      barChartType: 'bar',
      // Pie
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false,   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
        // label 文字
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 24,
                fontSize: 24,
                stepSize: 10,
                fontColor: '#BABABA',
                padding: 2,
                // X軸文字換行
                callback(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(' ');
                  } else {
                    return label;
                  }
                }
              },
              // 設定 底線
              gridLines: {
                display: false,
                color: '#868686',
                drawBorder: false,
              },
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 40,
                fontSize: 20,
                stepSize: 100,
                fontColor: '#BABABA',
                padding: 15
              },
              // 設定 底線
              gridLines: {
                display: true,
                color: '#868686',
                drawBorder: false,
                drawTicks: true,
                lineWidth: 2,
              },
            }
          ]
        },
        tooltips: {
          // 設定 Unix
          callbacks: {
            // label: (item) => `${item.yLabel} GB`,
          },
          titleFontSize: 24,
          bodyFontSize: 24,
          borderColor: 'white'
        },
      },
      // pie color
      donutColors: [
        {
          backgroundColor: this.equipAlarmDataCompose.chartBgColorArr,
        },
      ],
      barChartLegend: true,
      barChartLabels: [],
      barChartData: [
        {
          data: [],
        },
      ],
    }
  ];
  // Bar-Chart => Error Record END***************************************


  // Error Record 表格
  typeLineTableDataArr = [
    {
      name: 'Running rate',
      equipValArr: [],
    },
    {
      name: 'DownTime rate',
      equipValArr: [],
    },
    {
      name: 'Idle rate',
      equipValArr: [],
    },
    {
      name: 'Error record',
      equipValArr: [],
    },
  ];

  typeMoTableDataArr = [
    {
      name: 'Utilization',
      equipValArr: this.equipAlarmDataCompose.allUtilizationArr,
    },
    {
      name: 'DownTime rate',
      equipValArr: this.equipAlarmDataCompose.allDownTimeRateArr,
    },
    {
      name: 'Idle rate',
      equipValArr: this.equipAlarmDataCompose.allIdleRateArr,
    },
    {
      name: 'Error record',
      equipValArr: this.equipAlarmDataCompose.allErrRecordArr,
    },
  ];

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {

    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      if (this.byType === 'Mo') {
        this.isSvLoading.loading = false;
      }
      else {
        this.isSvLoading.loading = true;

        this.cleanEquipAlarmDataComposeArrData();
        this.switchDateRangeTime(this.saveChoiceDayString);
        // console.log('Sort排序 Call API:', this.queryObj);
        // this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
      }
    });
  }

  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,

    // route
    private route: ActivatedRoute,
    private router: Router,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;

    // queryParams 改變時, window.reload 畫面 / 按鈕切換 ByMO/ByLine
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.getUrlTypeAndSetTitle();
    this.setDefaultBtnActive('Week', this.apiParamsObj.type);
    this.setDefaultDateRangeTime('Week');
    // this.byType = this.byTypeMapping[this.byType];
  }

  ngOnInit(): void {
    this.rxTimerStart();
    if (this.byType === 'Mo') {
      this.isSvLoading.loading = false;

      this.orderInfoObj.Section = this.route.snapshot.queryParamMap.get('section');
      this.orderInfoObj.Type = this.route.snapshot.queryParamMap.get('type');
      this.orderInfoObj.Sn = this.route.snapshot.queryParamMap.get('sn');
      this.orderInfoObj.Side = this.route.snapshot.queryParamMap.get('side');

      console.log('this.orderInfoObj: ', this.orderInfoObj);
    }
    else {
      this.getAllApiFn(this.equipAlarmDataCompose);
    }
  }

  ngOnDestroy() {
    this.rxTimer$.unsubscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // Url拿到 Type MO/Line
  getUrlTypeAndSetTitle() {
    const getUrlParametrType = this.route.snapshot.queryParamMap.get('type').toLocaleLowerCase();
    this.byType = this.byTypeMappingObj[getUrlParametrType];
    this.apiParamsObj.type = this.byType;
  }

  // 回傳燈號顏色
  // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
  checkEquipLightColor(num) {
    if (num === null) { return; }
    if (+num === 0) { return 'equip_light_running'; }
    if (+num === 1) { return 'equip_light_idle'; }
    if (+num === 2) { return 'equip_light_alarm'; }
  }

  // 清空陣列資料
  cleanEquipAlarmDataComposeArrData() {
    this.equipAlarmDataCompose.allEquipNameArr = [];
    this.equipAlarmDataCompose.allDownTimeArr = [];
    this.equipAlarmDataCompose.allErrRecordArr = [];

    this.equipAlarmDataCompose.pieChartDownTimeInfo = [];
    this.equipAlarmDataCompose.allDownTiemPercentArr = [];

    this.equipAlarmDataCompose.allUtilizationArr = [];
    this.equipAlarmDataCompose.allDownTimeRateArr = [];
    this.equipAlarmDataCompose.allIdleRateArr = [];

    // 清空表格資料
    this.typeLineTableDataArr.map((x) => {
      x.equipValArr = [];
    });
  }

  // 設定預設 Title section name
  setDefaultSectionTitle(name) {
    this.apiParamsObj.sectionName = name;
    this.sectionNameArr.map(x => {
      x.isActive = false;
      if (x.sectionName === name) {
        x.isActive = true;
      }
      return x;
    });
  }

  // 設定預設 按鈕 Active
  setDefaultBtnActive(date, type) {
    this.switchDateRangeBtn(date, 'btnDateSetActiveObj');
    this.switchDateRangeBtn(type, 'btnTypeActiveObj');
  }

  // 設定預設 時間區間 startTime / endTime
  setDefaultDateRangeTime(str) {
    console.log('setDefaultDateRangeTime str => ', str);

    const nowTimeMoment = moment().valueOf();
    const toDayAM24TimeMoment = moment().startOf('day').hours(8).valueOf();

    const beforeTodayAM8 = nowTimeMoment < toDayAM24TimeMoment;
    const afterTodayAM8 = nowTimeMoment >= toDayAM24TimeMoment;
    const monthFirstDayBeforeTodayAM8 = moment().startOf('month').hours(8).valueOf() > nowTimeMoment;

    if (beforeTodayAM8) {
      const timeRangeObj = {
        Day: {
          startTime: moment().startOf('day').hours(8).subtract(1, 'days').valueOf(),
          endTime: moment().valueOf(),
        },
        Week: {
          startTime: moment().startOf('day').hours(8).subtract(8, 'days').valueOf(),
          endTime: moment().valueOf(),
        },
        Month: {
          startTime: moment().startOf('month').hours(8).valueOf(),
          endTime: moment().valueOf(),
        }
      };
      this.apiParamsObj.startTime = timeRangeObj[str].startTime;
      this.apiParamsObj.endTime = timeRangeObj[str].endTime;

    }

    if (beforeTodayAM8 && monthFirstDayBeforeTodayAM8) {
      const timeRangeObj = {
        Day: {
          startTime: moment().startOf('day').hours(8).subtract(1, 'days').valueOf(),
          endTime: moment().valueOf(),
        },
        Week: {
          startTime: moment().startOf('day').hours(8).subtract(8, 'days').valueOf(),
          endTime: moment().valueOf(),
        },
        Month: {
          startTime: moment().startOf('month').subtract(1, 'months').hours(8).valueOf(),
          endTime: moment().valueOf(),
        }
      };
      this.apiParamsObj.startTime = timeRangeObj[str].startTime;
      this.apiParamsObj.endTime = timeRangeObj[str].endTime;

    }

    if (afterTodayAM8) {
      const timeRangeObj = {
        Day: {
          startTime: moment().startOf('day').hours(8).valueOf(),
          endTime: moment().valueOf(),
        },
        Week: {
          startTime: moment().startOf('day').hours(8).subtract(7, 'days').valueOf(),
          endTime: moment().valueOf(),
        },
        Month: {
          startTime: moment().startOf('month').hours(8).valueOf(),
          endTime: moment().valueOf(),
        }
      };
      this.apiParamsObj.startTime = timeRangeObj[str].startTime;
      this.apiParamsObj.endTime = timeRangeObj[str].endTime;
    }

    // console.log('設定時間區間 Call API => ', this.equipAlarmDataCompose);
  }

  // 設定預設 Equip Option
  setDefaultEquipOptionName(value) {
    console.log('setDefaultEquipOptionName value:', value);
    this.apiParamsObj.selectEquip = value;
  }

  // 切換 Title section & Call API
  switchSectionTitle(name) {
    this.isSvLoading.loading = true;
    this.cleanEquipAlarmDataComposeArrData();
    this.apiParamsObj.selectEquip = undefined; // 清除暫存設備

    this.apiParamsObj.sectionName = name;
    // this.apiParamsObj.selectOptionName = name;
    this.sectionNameArr.map(x => {
      x.isActive = false;
      if (x.sectionName === name) {
        x.isActive = true;
      }
      return x;
    });

    // 切換 Section 時 option 的選項預設為第一個 option
    this.getAllOptionName$(this.apiParamsObj.sectionName).pipe(
      tap((res) => {
        console.log(' 切換Title section res:', res);
        this.apiParamsObj.selectOptionName = res[0];
      })
    ).subscribe((x) => {
      // 等 getAllOptionName$ API 回來才拿得到 第一筆 Option Name
      this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
    });
  }

  // 切換 Option & Call API
  switchSelectOptionName(e) {
    this.isSvLoading.loading = true;
    this.cleanEquipAlarmDataComposeArrData();
    this.apiParamsObj.selectEquip = undefined; // 清除暫存設備

    console.log('switchSelectOptionName e:', e);
    this.apiParamsObj.selectOptionName = e;

    console.log('下拉選單 Call API => ', this.apiParamsObj);
    this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
  }

  // 切換 時間區間 & Call API
  switchDateRangeTime(str) {
    this.isSvLoading.loading = true;
    this.saveChoiceDayString = str;

    this.cleanEquipAlarmDataComposeArrData();

    this.setDefaultDateRangeTime(this.saveChoiceDayString);

    console.log('切換時間區間 Call API => ', this.apiParamsObj);
    this.getUpdatDataApiFn(this.apiParamsObj, this.apiParamsObj.selectOptionName);
  }

  // 切換 Equip Option
  switchEquipOptionName(value) {
    this.isSvLoading.loading = true;
    // this.cleanEquipAlarmDataComposeArrData();
    console.log('switchEquipOptionName value:', value);
    this.apiParamsObj.selectEquip = value;

    this.getRankTopTenData(this.apiParamsObj.type, this.apiParamsObj.sectionName, this.apiParamsObj.selectOptionName, this.apiParamsObj.selectEquip, this.apiParamsObj.startTime, this.apiParamsObj.endTime);
  }

  // 日期 Range 按鈕切換 active
  switchDateRangeBtn(str, arr) {
    this[arr].map((x) => {
      x.active = false;
      if (x.key === str) { x.active = true; }
      return x;
    });

  }

  // format 資料處理
  pipeTranslateInfo(obj: any) {
    this.dataFormatSvc.objValueNulltoDash(obj);
    console.log('format 資料處理 obj:', obj);

    let totalLineStatusTime = 0;

    const countTotalDownTime = obj.equipInfo.reduce((pre, cur) => {
      return pre + cur.downtime;
    }, 0);

    // Data Formatted
    if (obj.latestAlarmTime !== '-') {
      obj.latestAlarmTime = this.dataFormatSvc.pipeDate(obj?.latestAlarmTime, 'YYYY-MM-dd HH:mm:ss');
    }
    if (obj.alarmDuration !== '-') {
      obj.alarmDuration = this.dataFormatSvc.secToDDhhmmss(obj?.alarmDuration);
    }

    if (obj.lineRunningSec !== '-') {
      totalLineStatusTime += obj.lineRunningSec;
      obj.lineRunningFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineRunningSec);
    }
    else {
      obj.lineRunningFormatted = '-';
    }
    if (obj.lineIdleSec !== '-') {
      totalLineStatusTime += obj.lineIdleSec;
      obj.lineIdleFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineIdleSec);
    }
    else {
      obj.lineIdleFormatted = '-';
    }
    if (obj.lineDownTimeSec !== '-') {
      totalLineStatusTime += obj.lineDownTimeSec;
      obj.lineDownTimeFormatted = this.dataFormatSvc.secToDDhhmmss(obj?.lineDownTimeSec);
    }
    else {
      obj.lineDownTimeFormatted = '-';
    }

    if (obj.lineRunningSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineRunning = this.dataFormatSvc.pipeNumber((+obj?.lineRunningSec / totalLineStatusTime * 100), '0.2-2');
    }
    else {
      obj.lineRunning = '-';
    }
    if (obj.lineIdleSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineIdle = this.dataFormatSvc.pipeNumber((+obj?.lineIdleSec / totalLineStatusTime * 100), '0.2-2');
    }
    else {
      obj.lineIdleSec = '-';
    }
    if (obj.lineDownTimeSec !== '-' && totalLineStatusTime !== 0) {
      obj.lineDownTime = this.dataFormatSvc.pipeNumber(numeral(100).subtract(obj?.lineRunning).subtract(obj?.lineIdle).value(), '0.2-2');
    }
    else {
      obj.lineDownTime = '-';
    }

    obj.equipInfo.map((x, idx) => {

      obj.allEquipNameArr.push(x.equip === null ? '-' : x.equip);
      obj.allDownTimeArr.push(x.downtime === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtime / 60), '0.0'));
      obj.allErrRecordArr.push(x.recordNum);
      obj.allDownTiemPercentArr.push(x.downtime === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtime / countTotalDownTime) * 100, '0.2-2'));

      // pie chart
      obj.pieChartDownTimeInfo.push({
        color: obj.chartBgColorArr[idx],
        name: x.equip === null ? '-' : x.equip,
        downtime: x.downtime === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtime / 60), '0.2-2'),
        percent: x.downtime === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtime / countTotalDownTime) * 100, '0.2-2'),
      });

      // Error Record Table
      obj.allUtilizationArr.push(x.utilization === null ? '-' : this.dataFormatSvc.pipeNumber((x.utilization * 100), '0.2-2'));
      obj.allDownTimeRateArr.push(x.downtimeRate === null ? '-' : this.dataFormatSvc.pipeNumber((x.downtimeRate * 100), '0.2-2'));
      obj.allIdleRateArr.push(x.idleRate === null ? '-' : this.dataFormatSvc.pipeNumber((x.idleRate * 100), '0.2-2'));
      return x;
    });

    this.typeLineTableDataArr[0].equipValArr = obj.allUtilizationArr;
    this.typeLineTableDataArr[1].equipValArr = obj.allDownTimeRateArr;
    this.typeLineTableDataArr[2].equipValArr = obj.allIdleRateArr;
    this.typeLineTableDataArr[3].equipValArr = obj.allErrRecordArr;

    console.log('this.typeLineTableDataArr:', this.typeLineTableDataArr);
  }

  // 更新 圖表資料
  updateAllChartData(obj) {
    // 圓餅圖
    this.pieChartDataArr[0].pieChartLabels = obj.allEquipNameArr;
    this.pieChartDataArr[0].pieChartData[0].data = obj.allDownTiemPercentArr;

    // 柱狀圖
    this.barChartDataArr[0].barChartLabels = obj.allEquipNameArr;
    this.barChartDataArr[0].barChartData[0].data = obj.allErrRecordArr;
  }

  // API get all sectionName
  getAllSectionName$(type) {
    return this.apiSvc.getEquipAlarmSection(type).pipe(
      tap(res => {
        // console.log('getAllSectionName row data res:', res);
        // res = this.forTestDataSvc.allSectionName;
        this.sectionNameArr = [];
        res.map((x) => {
          this.sectionNameArr.push({
            sectionName: x,
            isActive: false,
          });
        });
        this.setDefaultSectionTitle(this.apiParamsObj.sectionName);
        console.log('拿到所有 Section Name res:', res);
      }),
    );
  }

  // API get all optionName
  getAllOptionName$(section) {
    return this.apiSvc.getEquipAlarmOption(section).pipe(
      tap(res => {
        this.allOptionNameArr = res;
        this.apiParamsObj.selectOptionName = res[0];
        console.log('拿到所有 Option Name res:', res);
        console.log('API 回傳 Line 第一筆:', this.apiParamsObj.selectOptionName);
      }),
    );
  }

  // API type 的所有資料 ( API 要等 getAllSectionName$ ＆ getAllOptionName$ 拿到資料 )
  getEquipAlarmData$(infoObj) {
    const type = this.apiParamsObj.type;
    const section = this.apiParamsObj.sectionName;
    const selectValue = this.apiParamsObj.selectOptionName;
    const startTime = this.apiParamsObj.startTime;
    const endTime = this.apiParamsObj.endTime;

    return this.apiSvc.getEquipAlarmTypeInfoData(type, section, selectValue, startTime, endTime).pipe(
      tap(res => {
        const isApiEquipAlarmResEmptyArr = res.equipInfo.length === 0;
        // console.log('getEquipAlarmData row data res:', res);
        // res = this.forTestDataSvc.equipResDataArr;
        this.setDefaultDateRangeTime(this.saveChoiceDayString);

        if (isApiEquipAlarmResEmptyArr) {
          this.isApiResEmptyArrShowNoData = true;
        } else {
          this.isApiResEmptyArrShowNoData = false;
        }

        this.equipAlarmDataCompose = { ...this.equipAlarmDataCompose, ...res };
        console.log('所有資料 this.equipAlarmDataCompose:', this.equipAlarmDataCompose);

        this.pipeTranslateInfo(this.equipAlarmDataCompose);
        this.updateAllChartData(this.equipAlarmDataCompose);
      }),
    );
  }

  // API get all Equip Name ( API 要等 getAllOptionName$ 拿到資料 )
  getAllEquipName$(type, section, selecedOption) {
    return this.apiSvc.getEquipAlarmAllEquipName(type, section, selecedOption).pipe(
      tap(res => {
        const isApiAllEquipNameResEmptyArr = res === null;
        // console.log('getAllEquipName row data res:', res);
        // res = this.forTestDataSvc.rankingModelNameArray;

        if (isApiAllEquipNameResEmptyArr) {
          this.rankingModelNameArray = this.forTestDataSvc.rankingModelNameArrEmpty;
          this.rankingListData = this.forTestDataSvc.rankingListDataEmpty;
          return;
        }

        this.rankingModelNameArray = res;
        // 設定預設
        this.apiParamsObj.selectEquip = this.apiParamsObj.selectEquip || this.rankingModelNameArray[0];
        console.log('拿到所有設備名稱 this.equipAlarmDataCompose:', this.equipAlarmDataCompose);
      }),
    );
  }

  // API => Rankin top 10 Data
  getRankTopTenData(type, section, selecedOption, equipName, startTime, endTime) {
    this.apiSvc.getEquipAlarmRankingTopTen(type, section, selecedOption, equipName, startTime, endTime).pipe(
      take(1),
      tap((res) => {
        const isApiRankTopTenEmptyArr = res === null;
        // res = this.forTestDataSvc.rankingListData;
        console.log('getRankTopTenData res:', res);
        if (isApiRankTopTenEmptyArr) {
          this.rankingListData = this.forTestDataSvc.rankingListDataEmpty;
          return;
        }

        const addAllCountNumber = res.reduce((previousValue, currentValue) => {
          return previousValue + (+currentValue.count);
        }, 0);

        res.map((item) => {
          item.percent = this.dataFormatSvc.pipeNumber((item.count / addAllCountNumber) * 100, '0.2-2');
          item.count = this.dataFormatSvc.pipeNumber((item.count), '0.2-2');
          return item;
        });

        this.rankingListData = res;
        console.log('this.rankingListData:', this.rankingListData);
      })).subscribe((x) => {
        this.isSvLoading.loading = false;
      });
  }

  // API All Equip alarm data
  getAllApiFn(Obj) {

    return this.getAllSectionName$(this.apiParamsObj.type).pipe(
      switchMap(res => this.getAllOptionName$(
        this.apiParamsObj.sectionName)
      ),
      switchMap(res => this.getEquipAlarmData$(Obj)),
      switchMap(res => this.getAllEquipName$(
        this.apiParamsObj.type,
        this.apiParamsObj.sectionName,
        this.apiParamsObj.selectOptionName)
      ),
    ).subscribe((res) => {
      this.getRankTopTenData(
        this.apiParamsObj.type,
        this.apiParamsObj.sectionName,
        this.apiParamsObj.selectOptionName,
        this.apiParamsObj.selectEquip,
        this.apiParamsObj.startTime,
        this.apiParamsObj.endTime);

      console.log('最後 this.apiParamsObj:', this.apiParamsObj);
    });
  }

  // API Updat Data
  getUpdatDataApiFn(infoObj, selectOptionName) {

    if (this.updatingDataSubscription != null) {
      this.updatingDataSubscription.unsubscribe();
    }
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    this.updatingDataSubscription = this.getEquipAlarmData$(infoObj).pipe(
      switchMap(res => this.getAllEquipName$(
        infoObj.type,
        infoObj.sectionName,
        selectOptionName
      )
      ),
    ).subscribe((res) => {
      if (this.pageAutoRefresh) { this.rxTimerStart(); }
      this.getRankTopTenData(
        infoObj.type,
        infoObj.sectionName,
        infoObj.selectOptionName,
        infoObj.selectEquip,
        infoObj.startTime,
        infoObj.endTime
      );
      this.updateAllChartData(this.equipAlarmDataCompose);
    });

    return this.updatingDataSubscription;
  }

  // TODO: ByMO


}
