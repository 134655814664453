<div class="defective_container">
  <div class="title_group">
    <h1 class="title_name">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      Defective Product Management
    </h1>
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container_box">
    <!-- 下拉選單 Catagory -->
    <div class="catagory">
      <div class="catagory_select_tag">
        <label for="">Category</label>
        <select (change)="getModelName($event.target.value)">
          <option selected="selected">All</option>
          <option>Electrical Defect</option>
          <option>Cosmetic Defect</option>
          <option>ICT & FPT / AOI Defect</option>
        </select>
      </div>
    </div>

    <div class="mo_box">
      <!-- Mo Finished Output -->
      <div class="output_groups">
        <div class="output_board mo_output">
          <div class="output_titleName">
            Units Accumulated<br />
            Today
          </div>
          <div class="output_number">
            {{ defactiveMoObeject.moFinishedOutput }}
            <!-- <span class="output_unit">units</span> -->
          </div>
        </div>
        <div class="output_board mo_repair">
          <div class="output_titleName">Total Units to be<br />Repaired</div>
          <div class="output_number">
            {{ defactiveMoObeject.moInRepair }}
            <!-- <span class="output_unit">units</span> -->
          </div>
        </div>
        <div class="output_board mo_time">
          <div class="output_titleName">Total Stay Time</div>
          <div class="output_number">
            <span
              >{{ stayTimeDay }} d {{ stayTimeHr }} h <br />
              {{ stayTimeMin }} m {{ stayTimeSec }} s
            </span>
            <!-- {{ defactiveMoObeject.moCycleTime | number: "0.1-2" }} -->
            <!-- <span class="output_unit">
              {{ defactiveMoObeject.moCycleTimeUnit }}
            </span> -->
          </div>
        </div>
        <div class="output_board mo_today">
          <div class="output_titleName">Units Repaired Today</div>
          <div class="output_number">
            {{ defactiveMoObeject.moRepairToday }}
            <!-- <span class="output_unit">units</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <!-- Mo search Tables -->
    <div class="mo_table">
      <div class="table_group">
        <!-- search group-->
        <div class="search_group">
          <div class="search">
            <img
              class="search_icon"
              src="../../assets/img/smart_factory/bi_search.svg"
              alt="search-icon"
            />
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
              #input
            />
          </div>
        </div>

        <div class="table_scroll_box">
          <!-- 第一層 Table -->
          <div class="table_container mat-elevation-z8">
            <!-- Table 吃 DataSource資料 -->
            <mat-table
              #table
              [dataSource]="dataSource"
              multiTemplateDataRows
              matSort
              #sort="matSort"
            >
              <!-- MONo Column -->
              <ng-container matColumnDef="order">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  MO No.
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.order }}
                </mat-cell>
              </ng-container>

              <!-- Start_Time Column -->
              <ng-container matColumnDef="startTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Start Time
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.startTime }}
                </mat-cell>
              </ng-container>

              <!-- End_Time Column -->
              <ng-container matColumnDef="endTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  End Time
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.endTime }}
                </mat-cell>
              </ng-container>

              <!-- timeToRepair Column -->
              <ng-container matColumnDef="timeToRepair">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Time to Repair
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span class="cell_text_right">
                    {{ element.timeToRepair }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Defect Qty. Column -->
              <ng-container matColumnDef="defect_qty">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Defect Qty.
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.defect_qty | number }}
                </mat-cell>
              </ng-container>

              <!-- 第二層 table -->
              <!-- Expanded Content Column - The detail row is made up of this one column -->
              <ng-container matColumnDef="expandedDetail">
                <!-- <mat-cell *matCellDef="let detail">
                    The symbol for {{detail.Customer}} is {{detail.Customer}}
                  </mat-cell> -->

                <!-- <mat-cell
                    *matCellDef="let row"
                    style="padding: 0; width: 100%"
                    class="innerTable"
                    (click)="detailExpandController(row)"
                    [@detailExpand]="
                      row === expandedElement ? 'expanded' : 'collapsed'
                    "
                  > -->
                <mat-cell
                  *matCellDef="let row"
                  style="padding: 0; width: 100%"
                  class="innerTable"
                  [@detailExpand]="
                    row.selfID === expandedElement.selfID &&
                    expandedElement.isExpanded
                      ? 'expanded'
                      : 'collapsed'
                  "
                >
                  <mat-table
                    [dataSource]="row.units"
                    multiTemplateDataRows
                    style="width: 100%"
                    matSort
                    #innerSort="matSort"
                  >
                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <!-- action -->
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <!-- {{ element.action }} -->
                      </mat-cell>
                    </ng-container>

                    <!-- Unit Column -->
                    <ng-container matColumnDef="serialNumber">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Unit
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{ element.serialNumber }}
                      </mat-cell>
                    </ng-container>

                    <!-- Start_Time Column -->
                    <ng-container matColumnDef="startTime">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Start Time
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{
                          element.startTime === null
                            ? "-"
                            : (element.startTime | to_local_timezone)
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- End_Time Column -->
                    <ng-container matColumnDef="endTime">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        End Time
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{
                          element.endTime === null || element.endTime === ""
                            ? "-"
                            : (element.endTime | to_local_timezone)
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- Defect_Code Column -->
                    <ng-container matColumnDef="defectCode">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Defect Code
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{
                          element.defectCode === null ||
                          element.defectCode === ""
                            ? "-"
                            : element.defectCode
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- Root Cause Column -->
                    <!-- <ng-container matColumnDef="rootCause">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Root Cause
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          {{
                            element.rootCause === null
                              ? "-"
                              : element.rootCause
                          }}
                        </mat-cell>
                      </ng-container> -->

                    <!-- <ng-container
                        matColumnDef="{{ innerColumn }}"
                        *ngFor="let innerColumn of innerDisplayedColumns"
                      >
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{ innerColumn }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          {{ element[innerColumn] }}
                        </mat-cell>
                      </ng-container> -->

                    <mat-header-row
                      *matHeaderRowDef="innerDisplayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: innerDisplayedColumns"
                      matRipple
                      class="element-row"
                    >
                    </mat-row>
                  </mat-table>
                </mat-cell>
              </ng-container>

              <!-- action "^" -->
              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>
                  <!-- action -->
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <!-- 判斷有沒有 children -->
                  <ng-container *ngIf="element.units">
                    <button
                      mat-icon-button
                      *ngIf="expandedElement.selfID !== element.selfID"
                      [class.expanded]="expandedElement === element"
                      (click)="expendClick(element)"
                    >
                      <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                      <mat-icon>
                        <img
                          src="/assets/img/smart_factory/Arrow_right.svg"
                          alt=""
                        />
                      </mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      *ngIf="expandedElement.selfID === element.selfID"
                      [class.expanded]="expandedElement === false"
                      (click)="expandedElement = false"
                    >
                      <!-- <mat-icon>keyboard_arrow_up</mat-icon> -->
                      <mat-icon>
                        <img
                          src="/assets/img/smart_factory/Arrow_down.svg"
                          alt=""
                        />
                      </mat-icon>
                    </button>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <!-- 表頭 sticky: true => fix ".mat-header-row" -->
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
                matRipple
                class="element-row"
              >
              </mat-row>
              <mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                style="overflow: hidden"
                class="example-detail-row"
              >
              </mat-row>
            </mat-table>
          </div>

          <!-- page navigation-->
          <mat-paginator
            class="paginator"
            #MatPaginator
            [showFirstLastButtons]="true"
            [length]="dataSource"
            [pageIndex]="0"
            [pageSize]="10"
          >
          </mat-paginator>
        </div>
      </div>
    </div>

    <!-- Defect Ranking -->
    <div class="rank_box">
      <div class="defect_box_title">Root Cause Ranking</div>

      <div class="select_tag model_select">
        <label>Model</label>
        <select (change)="getModelName($event.target.value)">
          <option selected="selected">
            {{ isRootFirstModelName }}
          </option>
          <option *ngFor="let item of rankingModelNameArray" [value]="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="root_cause_box">
        <div class="defect_data_list">
          <div
            class="defect_data_group"
            *ngFor="let item of rankingListData; let i = index"
          >
            <p class="list_number">{{ i + 1 }}</p>
            <div class="defect_cause_group">
              <p class="defect_data_cause">{{ item.dutyCode }}</p>
              <!-- <p class="defect_data_occurrence">
                Count : {{ item.occurrence | number }}
              </p> -->
            </div>
            <div class="defect_num_group">
              <p class="defect_data_percent">
                {{ item.percent | number: "0.2-2" }} <span>%</span>
              </p>
              <p class="defect_data_occurrence">
                ({{ item.occurrence | number }})
              </p>
            </div>
            <div
              class="red_bar"
              [style]="'width:' + [item.percent] + '%'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
