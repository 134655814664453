import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, concatMap } from 'rxjs/operators';


// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { DataFormatService } from '../_services/data-format.service';
import { SpinnerService } from '../_services/spinner.service';
import { interval, of } from 'rxjs';

// interface
import { AteApiData, StationInfo, StationEfficiencyInfo } from '../core/models/ateEquipEfficiency.model';
// forTestData
import { ForTestDataService } from '../_services/for-test-data.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
// Getting route information
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-ate-equip-efficiency',
  templateUrl: './ate-equip-efficiency.component.html',
  styleUrls: ['./ate-equip-efficiency.component.scss']
})
export class AteEquipEfficiencyComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly canGoBack: boolean;

  constructor(
    public clock: RealTimeClockService,
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public testData: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // check previousNavigation object is null or not
    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
    this.isSvLoading.loading = true;
  }

  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  queryObj: {
    section: string;
    sn: string;
    line: string;
    side: string;
    startTime: number;
    endTime: number;
    groupname: string;
  } = {
      section: undefined,
      sn: undefined,  // Sn: 'CAT0AJ220013',
      line: undefined,
      side: undefined,
      startTime: undefined,
      endTime: undefined,
      groupname: undefined
    };

  apiResponseObj: AteApiData = {
    sn: undefined,
    product: undefined,
    startTime: undefined,
    moStartTime: undefined,
    moEndTime: undefined,
    targetOutput: undefined,
    output: undefined,
    achieveRate: undefined,
    stationInfo: []
  };

  ateEquipFunction: string[] = [];

  ateStationData: StationEfficiencyInfo[] = [];

  tableDataSource: StationInfo[] = [];
  displayedColumns: string[] = ['itemName', 'equipmentQty', 'startTime', 'toBeTestedQty', 'outputQty', 'failQty', 'efficiency', 'cycleTime', 'avgCycleTime'];

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  isRangeStartTime = this.range.controls.start.value;
  isRangeEndTime = this.range.controls.end.value;

  startDate = moment();
  minDate = undefined;
  maxDate = moment();

  backClicked() {
    if (this.canGoBack) {
      this.location.back();
    } else {
      this.router.navigate(['/menu']);
    }
  }

  // DateRangePicker filter 六日不能選
  familyDayFilter(date: moment.Moment): boolean {
    const day = date.day();
    return true;
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate(dateRangeStart, dateRangeEnd) {
    if (dateRangeStart === null || dateRangeEnd === null) { return; }
    this.isSvLoading.loading = true;
    this.queryObj.startTime = moment(this.range.value.start).startOf('day').hours(8).valueOf();
    this.queryObj.endTime = moment(this.range.value.end).endOf('day').add(1, 'days').hours(7).valueOf();
    this.startDate = moment(this.queryObj.endTime).subtract(1, 'days');
    console.log('選取日期 this.queryObj:', this.queryObj);
    this.apiGetAteEquipEfficiencyInfo$(this.queryObj).subscribe();
  }

  // DateRangePicker 設定 Defaulte range
  setDefaultRangeDate() {
    this.isSvLoading.loading = true;
    // formGroup setValue
    this.range.setValue({
      start: '', // 預設 startTime 為後端判斷，傳 All
      end: '',
    });
    this.queryObj.startTime = undefined;
    this.queryObj.endTime = moment().valueOf();
    this.startDate = moment();
    console.log('預設日期 this.queryObj:', this.queryObj);
    this.apiGetAteEquipEfficiencyInfo$(this.queryObj).subscribe();
  }

  private rxTimerStop() {
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.apiGetAteEquipEfficiencyInfo$(this.queryObj).subscribe();
    });
  }

  ngOnInit(): void {
    this.queryObj.section = this.route.snapshot.queryParamMap.get('section');
    this.queryObj.line = this.route.snapshot.queryParamMap.get('line');
    this.queryObj.sn = this.route.snapshot.queryParamMap.get('sn');
    this.queryObj.side = this.route.snapshot.queryParamMap.get('side');
    this.rxTimerStart();
    this.apiGetAteEquipEfficiencyInfo$(this.queryObj).subscribe();
  }

  ngAfterViewInit() {
    // For Test MOCK DATA
    // this.apiResponseObj = this.testData.ateEfficiencyByMoData;
    // this.ateStationData = this.testData.ateStationEfficiencyData;
    // this.isSvLoading.loading = false;
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // Switch Station Function
  switchEquipFunction(selectedStation: string) {
    console.log('switchEquipFunction:', selectedStation);
    this.queryObj.groupname = selectedStation;
    this.isSvLoading.loading = true;
    this.apiGetStationEfficiencyInfo$(this.queryObj).subscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // API Get Data
  apiGetAteEquipEfficiencyInfo$(queryObj) {
    console.log('queryObj:', queryObj);
    this.renewedTime = moment().valueOf();
    this.pageAutoRefresh === true ? this.rxTimerStop() : this.pageAutoRefresh = false;
    const { section, sn, line, side, startTime, endTime } = queryObj;
    return this.apiSvc.getAteEquipEfficiencyData(section, sn, line, side, startTime, endTime).pipe(
      tap((res: AteApiData) => {
        if (!res) {
          console.log('apiGetAteEquipEfficiencyInfo res is null!!!');
          this.isSvLoading.loading = false;
        }
        console.log('apiGetAteEquipEfficiencyInfo res:', res);
        this.snapshotTime = this.renewedTime;

        this.apiResponseObj = res;
        this.tableDataSource = this.apiResponseObj?.stationInfo;

        this.minDate = this.apiResponseObj.moStartTime === null ? moment() : moment(this.apiResponseObj.moStartTime).hours() >= 8 ? moment(this.apiResponseObj.moStartTime) : moment(this.apiResponseObj.moStartTime).subtract(1, 'days');

        this.maxDate = this.apiResponseObj.moEndTime === null ? moment() : moment(this.apiResponseObj.moEndTime).hours() >= 8 ? moment(this.apiResponseObj.moEndTime) : moment(this.apiResponseObj.moEndTime).subtract(1, 'days');
      }),
      switchMap(res => this.apiSvc.getAteEquipStationFunction(sn).pipe(
        tap((res) => {
          console.log('getAteEquipStationFunction: ', res);
          if (!res) { res = []; }
          this.ateEquipFunction = res;
          if (!queryObj.groupname) { res.length >= 0 ? this.queryObj.groupname = res[0] : this.queryObj.groupname = null; }
          this.pageAutoRefresh === true ? this.rxTimerStart() : this.pageAutoRefresh = false;
        })
      )),
      concatMap(res => this.apiGetStationEfficiencyInfo$(this.queryObj))
    );
  }

  // API Get Station Efficiency
  apiGetStationEfficiencyInfo$(queryObj) {
    const { sn, groupname, startTime, endTime } = queryObj;
    if (!groupname) {
      console.log('groupname is null!!!');
      this.ateStationData = [];
      this.isSvLoading.loading = false;
      return of();
    }
    return this.apiSvc.getAteEquipStationEfficiency(sn, groupname, startTime, endTime).pipe(
      tap((res: StationEfficiencyInfo[]) => {
        console.log('getAteEquipStationEfficiency: ', res);
        this.ateStationData = res;
        this.ateStationData.sort((a, b) => b.efficiency - a.efficiency);
        this.isSvLoading.loading = false;
      })
    );
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.apiGetAteEquipEfficiencyInfo$(this.queryObj).subscribe();
  }
}
