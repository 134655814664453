import numeral from 'numeral';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiService, DotnetApi } from 'src/app/_services/apiSvc.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { DataFormatService } from 'src/app/_services/data-format.service';
import { Option } from 'src/components/dropdown/dropdown.component';
import { TextInputComponent } from 'src/components/text-input/text-input.component';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';

const PollingIntervalInSeconds = 300;

const TABLE_PAGE_SIZE = 10;

const MO_TABLE_COLUMNS = {
  _id: 'Part No.',
  ttlTotal: 'Total',
  ttlInput: 'TTL Input',
  ttlComp: 'TTL COMP.',
  function1: '#1',
  function2: '#2',
  function3: '#3',
  function4: '#4',
  function5: '#5'
};

type MoTableDataType = {
  index: string;
  _id: string;
  ttlTotal: number,
  ttlInput: number,
  ttlComp: number,
  count: number;
  function1: string;
  function2: string;
  function3: string;
  function4: string;
  function5: string;
  groupNameRank: {
    groupName: string;
    count: number;
  }[];
};

class Poller {
  constructor() { }

  timer: ReturnType<typeof setInterval> | null = null;

  reset(callback: () => void, milliSeconds: number) {
    if (this.timer != null) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      callback();
    }, milliSeconds);
  }

  stop() {
    if (this.timer == null) { return; }
    clearInterval(this.timer);
  }
}

@Component({
  selector: 'app-phase3-repair-management',
  templateUrl: './phase3-repair-management.component.html',
  styleUrls: ['./phase3-repair-management.component.scss']
})
export class Phase3RepairManagementComponent implements OnInit, OnDestroy {

  constructor(
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public apiSvc: ApiService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,
  ) {
    this.apiPoller = new Poller();
  }

  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;

  renewedTime;
  snapshotTime;

  apiPoller: Poller;

  readonly tableConfig = {
    pageSize: TABLE_PAGE_SIZE,
  };

  filterOptions: {
    category?: string;
    model?: string;
  } = {};

  categories: Option[] | undefined = undefined;

  models: Option[] | undefined = undefined;

  defectiveMoObject: DotnetApi.DefectiveProductManagement.DefectiveInfo | undefined = undefined;

  allSectionName = [
    { columnName: null, displayName: 'All' },
    { columnName: 'SMT', displayName: 'SMT' },
    { columnName: 'LOD', displayName: 'LOD' },
    { columnName: 'ASY', displayName: 'ASSY' },
    { columnName: 'PKG', displayName: 'PKG' },
    { columnName: 'TEST', displayName: 'TEST' },
  ];

  moTable: {
    columns: Record<string, string>;
    columnsToDisplay: string[];
    data: MoTableDataType[];
    pageIndex: number;
    total: number;
    readonly pageSize: number;
    loading: boolean;
    onChangePage: (obj: { pageIndex: number }) => Promise<void>;
    onChangeValue: (value: string) => Promise<void>;
    onChangeSection: (value: string) => Promise<void>;
    sortData: (event) => Promise<void>;
    text?: string;
    section?: string;
    sort?: string,
    descending?: boolean,
  } = {
      columns: MO_TABLE_COLUMNS,
      columnsToDisplay: Object.keys(MO_TABLE_COLUMNS),
      data: [],
      pageIndex: 0,
      total: 0,
      pageSize: TABLE_PAGE_SIZE,
      loading: false,
      onChangePage: async ({ pageIndex }) => {
        this.moTable = {
          ...this.moTable,
          loading: true,
          pageIndex
        };
        const { category, model } = this.filterOptions;
        const { text } = this.moTable;
        await this.update(category, model, pageIndex, text);
        this.moTable = {
          ...this.moTable,
          loading: false,
        };
      },
      onChangeValue: async (text: string) => {
        if (typeof text !== 'string') { return; }
        const upperText = text.toUpperCase();
        // if (this.moTable.text === upperText) { return; }
        const { category, model } = this.filterOptions;
        this.moTable = {
          ...this.moTable,
          loading: true,
        };
        if (text.length === 0) {
          this.moTable = {
            ...this.moTable,
            text: undefined,
            loading: false,
          };
        } else {
          this.moTable = {
            ...this.moTable,
            text: upperText,
            loading: false,
          };
        }
        await this.update(category, model, 0, this.moTable.text);
        if (this.moTablePaginator) {
          this.moTablePaginator.firstPage();
        }
      },
      onChangeSection: async (value) => {
        console.log('value:', value);
        this.moTable = {
          ...this.moTable,
          loading: true,
        };
        const { category, model } = this.filterOptions;
        this.moTable.section = value;
        await this.update(category, model, 0, this.moTable.text);
        this.moTable = {
          ...this.moTable,
          loading: false,
        };
        if (this.moTablePaginator) {
          this.moTablePaginator.firstPage();
        }
      },
      sortData: async (event) => {
        console.log('sortData event:', event);
        this.moTable = {
          ...this.moTable,
          loading: true,
        };
        const { category, model } = this.filterOptions;
        this.moTable.sort = event.active;
        this.moTable.descending = event.direction === 'desc' ? true : false;
        await this.update(category, model, 0, this.moTable.text);
        this.moTable = {
          ...this.moTable,
          loading: false,
        };
        if (this.moTablePaginator) {
          this.moTablePaginator.firstPage();
        }
      }
    };

  displayedColumns: string[] = ['index', 'mo', 'product', 'count', 'duractionDay'];

  overdueMoDataSource = new MatTableDataSource();
  overdueMoList = {
    filter: 'almostOverdue',
    limit: 10,
    offset: 0,
    total: 0,

    list: []
  };
  overdueMoSort = {
    sort: null,
    descending: null,
  };

  @ViewChild('textInput') textInput: TextInputComponent;
  @ViewChild('moTablePaginator') moTablePaginator: MatPaginator;

  subscribeCategories() {
    return new Promise<Option[]>((resolve) => {
      this.apiSvc.getDefectiveProductManagementCategories().subscribe(res => {
        resolve(res.map(org => ({
          label: org.name,
          value: org.value,
          selected: false,
        })));
      });
    });
  }

  subscribeModels(category?: string) {
    return new Promise<Option[]>((resolve) => {
      this.apiSvc.getDefectiveProductManagementModels(category).subscribe(res => {
        resolve([{
          label: 'All',
          value: '',
          selected: true,
        }, ...res.map(org => ({
          label: org.name,
          value: org.value,
          selected: false,
        }))]);
      });
    });
  }

  subscribeDefectiveInfo(category?: string, model?: string) {
    return new Promise<DotnetApi.DefectiveProductManagement.DefectiveInfo>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveInfo(category, model)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  subscribeOverdueMOData(offset: number, limit: number, filter: string, sort?: string, descending?: boolean, category?: string, model?: string) {
    return new Promise<any>((resolve) => {
      this.apiSvc.getDefectiveProductManagementOverdueMOData(offset, limit, filter, sort, descending, category, model).subscribe(res => {
        resolve(res);
      });
    });
  }

  subscribeWipRank(pageIndex: number = 0, section?: string, text?: string, sort?: string, descending?: boolean, category?: string, model?: string) {
    return new Promise<any>((resolve) => {
      this.apiSvc.getDefectiveProductManagementWipRank(pageIndex * TABLE_PAGE_SIZE, TABLE_PAGE_SIZE, section, text, sort, descending, category, model).subscribe(res => {
        resolve(res);
      });
    });
  }

  // Update the rendering part.
  async update(category?: string, model?: string, pageIndex?: number, text?: string) {
    this.isSvLoading.loading = true;
    if (this.pageAutoRefresh) { this.apiPoller.stop(); }
    this.renewedTime = moment().valueOf();
    const f = () => {
      return Promise.all([
        this.subscribeDefectiveInfo(category, model),
        this.subscribeOverdueMOData(this.overdueMoList.offset, this.overdueMoList.limit, this.overdueMoList.filter, this.overdueMoSort.sort, this.overdueMoSort.descending, category, model),
        this.subscribeWipRank(pageIndex, this.moTable.section, text, this.moTable.sort, this.moTable.descending, category, model)
      ]).then(([
        res1,
        res2,
        res3,
      ]) => {
        this.snapshotTime = this.renewedTime;
        this.defectiveMoObject = res1;
        this.overdueMoList = res2;
        this.overdueMoDataSource.data = this.overdueMoList?.list;
        this.moTable.total = res3?.total;
        this.moTable.data = res3?.data.map((obj: MoTableDataType) => {
          const rObj = { ...obj };
          for (let index = 0; index < rObj.groupNameRank.length && index < 5; index++) {
            switch (index) {
              case 0:
                rObj.function1 = this.dataFormatSvc.AddSpaceBeforeFunctionNameNumber(rObj.groupNameRank[index]?.groupName) + ' : ' + rObj.groupNameRank[index]?.count;
                break;
              case 1:
                rObj.function2 = this.dataFormatSvc.AddSpaceBeforeFunctionNameNumber(rObj.groupNameRank[index]?.groupName) + ' : ' + rObj.groupNameRank[index]?.count;
                break;
              case 2:
                rObj.function3 = this.dataFormatSvc.AddSpaceBeforeFunctionNameNumber(rObj.groupNameRank[index]?.groupName) + ' : ' + rObj.groupNameRank[index]?.count;
                break;
              case 3:
                rObj.function4 = this.dataFormatSvc.AddSpaceBeforeFunctionNameNumber(rObj.groupNameRank[index]?.groupName) + ' : ' + rObj.groupNameRank[index]?.count;
                break;
              case 4:
                rObj.function5 = this.dataFormatSvc.AddSpaceBeforeFunctionNameNumber(rObj.groupNameRank[index]?.groupName) + ' : ' + rObj.groupNameRank[index]?.count;
                break;
              default:
                return;
            }
          }
          return rObj;
        });
        this.isSvLoading.loading = false;
      });
    };
    if (this.pageAutoRefresh) {
      this.apiPoller.reset(() => {
        f().then(() => undefined);
      }, PollingIntervalInSeconds * 1000);
    }
    return f();
  }

  async onChange(type: 'category' | 'model', data?: Option) {
    let {
      category,
      model,
    } = this.filterOptions;
    switch (type) {
      case 'category':
        this.textInput.input.nativeElement.value = '';
        this.moTable.section = null;
        this.moTable.text = null;
        this.isSvLoading.loading = true;
        category = data?.value;
        this.filterOptions = {
          ...this.filterOptions,
          category,
          model: undefined
        };
        this.models = await this.subscribeModels(category);
        await this.update(category, undefined, 0, this.moTable.text);
        this.isSvLoading.loading = false;
        if (this.moTablePaginator) {
          this.moTablePaginator.firstPage();
        }
        this.moTable.pageIndex = 0;
        break;
      case 'model':
        this.textInput.input.nativeElement.value = '';
        this.moTable.section = null;
        this.moTable.text = null;
        this.isSvLoading.loading = true;
        model = data?.value;
        this.filterOptions = {
          ...this.filterOptions,
          category,
          model,
        };
        await this.update(category, model, 0, this.moTable.text);
        this.isSvLoading.loading = false;
        if (this.moTablePaginator) {
          this.moTablePaginator.firstPage();
        }
        this.moTable.pageIndex = 0;
        break;
      default:
    }
  }

  ngOnInit() {
    console.log(moment().format('YYYY/MM/DD HH'));
    this.moTable.section = null;
    setTimeout(() => {
      this.isSvLoading.loading = true;
      const self = this;
      Promise.all([
        this.subscribeCategories(),
        this.subscribeModels()
      ]).then(([categories, models]) => {
        self.categories = categories;
        self.models = models;
        return self.update();
      }).then(() => {
        this.isSvLoading.loading = false;
      });
    }, 0);
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.apiPoller.stop(); }
  }

  overdueMoDataOnPaginateChange(e) {
    const { category, model } = this.filterOptions;
    this.isSvLoading.loading = true;
    this.subscribeOverdueMOData((e.pageIndex) * 10, e.pageSize, this.overdueMoList.filter, this.overdueMoSort.sort, this.overdueMoSort.descending, category, model).then((res) => {
      this.overdueMoList = res;
      this.overdueMoDataSource.data = this.overdueMoList.list;
      this.isSvLoading.loading = false;
    });
  }

  overdueMoSelection(filter: string) {
    const { category, model } = this.filterOptions;
    this.isSvLoading.loading = true;
    this.subscribeOverdueMOData(0, 10, filter, this.overdueMoSort.sort, this.overdueMoSort.descending, category, model).then((res) => {
      this.overdueMoList = res;
      this.overdueMoDataSource.data = this.overdueMoList.list;
      this.isSvLoading.loading = false;
    });
  }

  overdueMoDataSortData(event) {
    // const ex = { active: 'mo', direction: 'desc' };
    console.log('overdueMoDataSortData:', event);
    this.overdueMoSort.sort = event.active;
    this.overdueMoSort.descending = event.direction === 'desc' ? true : false;

    const { category, model } = this.filterOptions;
    this.isSvLoading.loading = true;
    this.subscribeOverdueMOData(0, 10, this.overdueMoList.filter, this.overdueMoSort.sort, this.overdueMoSort.descending, category, model).then((res) => {
      this.overdueMoList = res;
      this.overdueMoDataSource.data = this.overdueMoList.list;
      this.isSvLoading.loading = false;
    });
  }

  overdueMoSelectionStatus(filter: string) {
    return this.overdueMoList.filter === filter ? 'selected' : '';
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    const { category, model } = this.filterOptions;
    const { pageIndex, text } = this.moTable;
    if (isStopRefresh) { this.apiPoller.stop(); }
    if (isOpenRefresh) { this.update(category, model, pageIndex, text); }
  }

  reloadCurrentPageClick() {
    const { category, model } = this.filterOptions;
    const { pageIndex, text } = this.moTable;
    this.update(category, model, pageIndex, text);
  }

  getBarChartData() {
    return (this.defectiveMoObject?.wipHistory ?? []).map(wip => ({
      x: wip.name,
      y: wip.qty,
    }));
  }

  onClickTotalWip(element, popupFilter: string, functionName?: string) {
    console.log('onClickTotalWip:', element);
    if (popupFilter === 'function') {
      if (!functionName) { return; }
    }
    this.dialog.open(Phase3WipTableDialogDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '1690px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        mo: element?.mo,
        partNo: element?.product,
        filter: popupFilter,
        moData: element?.data,
        rankingPartNo: element?._id,
        function: functionName,
        category: this.filterOptions?.category,
      },
    });
  }

  onClickDownload(filter: string) {
    console.log('onClickDownload: ', filter);
    this.dialog.open(Phase3RepairDownloadDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
      height: '280px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        option: filter
      },
    });
  }
}

const WIP_TABLE_COLUMNS: string[] = ['unitNo', 'startTime', 'endTime', 'defectCode', 'partNo', 'moNo', 'section', 'side', 'function'];

type WipTableDataType = {
  serialNumber: string;
  startTime: string;
  endTime: string
  defectCode: string;
  product: string;
  mo: string;
  section: string;
  side: string;
  groupName: string;
};

interface WipTableDialogData {
  mo: string;
  partNo: string;
  filter: string;
  moData: MoData[];
  rankingPartNo: string;
  function: string;
  category: string;
}

type MoData = {
  mo: string;
  serialNumber: string;
};

@Component({
  selector: 'app-wip-table-dialog',
  templateUrl: './dialog/app-total-wip-table-dialog.component.html',
  styleUrls: ['./dialog/app-total-wip-table-dialog.component.scss']
})
export class Phase3WipTableDialogDataDialogComponent implements OnInit {
  constructor(
    public apiSvc: ApiService,
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<Phase3WipTableDialogDataDialogComponent>,
    public isSvLoading: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: WipTableDialogData,
  ) { }

  displayedColumns = WIP_TABLE_COLUMNS;
  wipDataSource = new MatTableDataSource();
  wipData = {
    limit: 10,
    offset: 0,
    total: 0,
    data: []
  };

  moArrayEncodeString: string;
  selectedCategory: string = null;

  wipGroupData = [];

  subscribeDefectUnitsByMo(mo: string, partNo: string, offset = 0, limit = 10, category = this.selectedCategory) {
    return new Promise<any>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectUnitsByMo(mo, partNo, offset, limit, category).subscribe(res => {
        resolve(res);
      });
    });
  }

  subscribeDefectUnitsByMoSnFunc(mo: string, partNo: string, func?: string, offset = 0, limit = 10, category = this.selectedCategory) {
    return new Promise<any>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectUnitsByMoSnFunc(mo, partNo, func, offset, limit, category).subscribe(res => {
        resolve(res);
      });
    });
  }

  ngOnInit() {
    this.selectedCategory = this.data?.category;
    console.log('this.data.filter:', this.data.filter);
    if (this.data.filter === 'overdueWip') {
      this.subscribeDefectUnitsByMo(this.data.mo, this.data.partNo).then((res) => {
        this.wipData = res;
        this.wipGroupData = res?.groupNameCount || [];
        console.log('ngOnInit wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
      });
    }
    else if (this.data.filter === 'rankingWip') {
      this.moArrayEncodeString = JSON.stringify(this.data.moData);
      this.subscribeDefectUnitsByMoSnFunc(this.moArrayEncodeString, this.data.rankingPartNo).then((res) => {
        this.wipData = res;
        this.wipGroupData = res?.count || [];
        console.log('ngOnInit wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
      });
    }
    else if (this.data.filter === 'function') {
      this.moArrayEncodeString = JSON.stringify(this.data.moData);
      this.subscribeDefectUnitsByMoSnFunc(this.moArrayEncodeString, this.data.rankingPartNo, this.data.function).then((res) => {
        this.wipData = res;
        console.log('ngOnInit wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
      });
    }
  }

  wipDataOnPaginateChange(e) {
    this.isSvLoading.loading = true;
    console.log('this.data.filter:', this.data.filter);
    if (this.data.filter === 'overdueWip') {
      this.subscribeDefectUnitsByMo(this.data.mo, this.data.partNo, (e.pageIndex) * 10, e.pageSize).then((res) => {
        this.wipData = res;
        this.wipGroupData = res?.groupNameCount || [];
        console.log('PaginateChange wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
        this.isSvLoading.loading = false;
      });
    }
    else if (this.data.filter === 'rankingWip') {
      this.subscribeDefectUnitsByMoSnFunc(this.moArrayEncodeString, this.data.rankingPartNo, null, (e.pageIndex) * 10, e.pageSize).then((res) => {
        this.wipData = res;
        this.wipGroupData = res?.count || [];
        console.log('PaginateChange wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
        this.isSvLoading.loading = false;
      });
    }
    else if (this.data.filter === 'function') {
      this.subscribeDefectUnitsByMoSnFunc(this.moArrayEncodeString, this.data.rankingPartNo, this.data.function, (e.pageIndex) * 10, e.pageSize).then((res) => {
        this.wipData = res;
        console.log('PaginateChange wipData:', this.wipData);
        this.wipDataSource.data = this.wipData.data;
        this.isSvLoading.loading = false;
      });
    }
  }
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './dialog/app-download-dialog.component.html',
  styleUrls: ['./dialog/app-download-dialog.component.scss']
})
export class Phase3RepairDownloadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<Phase3RepairDownloadDialogComponent>,
    public apiSvc: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: { option: string }) { }

  ngOnInit() { }

  onClickDownload(option: string) {
    if (option === 'WIP (RC)') {
      console.log('download option:', option);
      return window.open(`${this.apiSvc.APIUrl}/download/wipQtyRc`);
    }
    else if (option === 'Wait For Repair (Production Line)') {
      console.log('download option:', option);
      return window.open(`${this.apiSvc.APIUrl}/download/wipQtyNg`);
    }
    else if (option === 'WIP Ranking') {
      console.log('download option:', option);
      return window.open(`${this.apiSvc.APIUrl}/download/wipRanking`);
    }
    else if (option === 'Overdue MO List') {
      console.log('download option:', option);
      return window.open(`${this.apiSvc.APIUrl}/download/overdue`);
    }
  }
}
