<div class="c">
  <div class="title_group">
    <h1 class="title_name">
      <button class="logo-btn" style="margin-right: 24px" title="logo">
        <img
          style="width: 114px"
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        /></button
      >SMT Drop Rate Overview
    </h1>
    <!-- <img
      class="down_icon_svg"
      src="../../assets/img/dro_down.svg"
      alt="menu"
      (click)="Down()"
    /> -->
    <div class="time">
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
        <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign
          out
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div class="container_box">
      <!-- 左邊 -->
      <div class="l">
        <!-- Line -->
        <div class="line" *ngFor="let apidata of apidata$ | async">
          <div class="lt">{{ apidata.lineName }}</div>
          <div class="lbox">
            <!-- P START -->
            <div class="ls">
              <div class="lso"></div>
              <div class="lsl"></div>
            </div>
            <!-- P -->
            <div class="lp" *ngIf="apidata.equip[0] !== undefined">
              <div class="lpt">
                <div class="lptt">CM1</div>
                <div class="lptb">{{ apidata.lineName }}</div>
              </div>
              <div
                class="lpo"
                [ngClass]="isStatus(apidata.equip[0].status)"
              ></div>
              <div class="lpl"></div>
              <div class="lpc">
                <div class="lpct">
                  <span *ngIf="apidata.equip[0].equipCode !== undefined">{{
                    apidata.equip[0].equipCode
                  }}</span>
                </div>
                <div class="lpcd">
                  <div class="lpcdt">Drop Rate</div>
                  <div class="lpcdd">
                    <span *ngIf="apidata.equip[0].dropRate !== undefined"
                      >{{
                        dataFormatSvc.checkValDash(ppm(apidata.equip[0].dropRate))
                      }}
                      ppm</span
                    >
                  </div>
                </div>
                <div class="lpcl">
                  <div class="lpclt">Label</div>
                  <div class="lpcll lpcll_g"></div>
                </div>
              </div>
            </div>
            <!-- P -->
            <div class="lp" *ngIf="apidata.equip[1] !== undefined">
              <div class="lpt">
                <div class="lptt">CM2</div>
                <div class="lptb">{{ apidata.lineName }}</div>
              </div>
              <div
                class="lpo"
                [ngClass]="isStatus(apidata.equip[1].status)"
              ></div>
              <div class="lpl"></div>
              <div class="lpc">
                <div class="lpct">
                  <span *ngIf="apidata.equip[1].equipCode !== undefined">{{
                    apidata.equip[1].equipCode
                  }}</span>
                </div>
                <div class="lpcd">
                  <div class="lpcdt">Drop Rate</div>
                  <div class="lpcdd">
                    <span *ngIf="apidata.equip[1].dropRate !== undefined"
                      >{{
                        dataFormatSvc.checkValDash(ppm(apidata.equip[1].dropRate))
                      }}
                      ppm</span
                    >
                  </div>
                </div>
                <div class="lpcl">
                  <div class="lpclt">Label</div>
                  <div class="lpcll lpcll_y"></div>
                </div>
              </div>
            </div>
            <!-- P -->
            <div class="lp" *ngIf="apidata.equip[2] !== undefined">
              <div class="lpt">
                <div class="lptt">CM3</div>
                <div class="lptb">{{ apidata.lineName }}</div>
              </div>
              <div
                class="lpo"
                [ngClass]="isStatus(apidata.equip[2].status)"
              ></div>
              <div class="lpl"></div>
              <div class="lpc">
                <div class="lpct">
                  <span *ngIf="apidata.equip[2].equipCode !== undefined">{{
                    apidata.equip[2].equipCode
                  }}</span>
                </div>
                <div class="lpcd">
                  <div class="lpcdt">Drop Rate</div>
                  <div class="lpcdd">
                    <span *ngIf="apidata.equip[2].dropRate !== undefined"
                      >{{
                        dataFormatSvc.checkValDash(ppm(apidata.equip[2].dropRate))
                      }}
                      ppm</span
                    >
                  </div>
                </div>
                <div class="lpcl">
                  <div class="lpclt">Label</div>
                  <div class="lpcll lpcll_b"></div>
                </div>
              </div>
            </div>
            <!-- P END -->
            <div class="le">
              <div class="leo"></div>
              <div class="lel"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右邊 -->
      <div class="r">
        <div class="TimeTool">
          <div class="BFTS" (click)="L()"><div class="BFTSL">&lsaquo;</div></div>
          <div class="BFT">{{ displayDatePicker | date: "MM/dd/YYYY" }}</div>
          <div
            class="BFTA"
            (click)="R()"
            [ngStyle]="{ 'pointer-events': isNewestDate ? 'none' : 'auto' }"
          >
            <div class="BFTSR">&rsaquo;</div>
          </div>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasA"></canvas>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasB"></canvas>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasC"></canvas>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasD"></canvas>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasE"></canvas>
        </div>
        <div class="chart-container">
          <canvas id="RCanvasF"></canvas>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center;font-size: 48px;">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
