<div class="page-container">
  <div class="header">
    <div class="left-group">
      <button class="logo-btn" title="logo">
        <img
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>
      <h1>SMT Drop Rate Overview</h1>
    </div>
    <div class="right-group">
      <button
        style="height: fit-content; margin: auto 48px"
        (click)="onClickDownload('Download')"
      >
        <img src="../../../assets/img/btn_download.svg" alt="download_button" />
      </button>
      <h3>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</h3>
      <button class="menu-btn" title="menu" (click)="isMenuOpen = !isMenuOpen">
        <img src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="../menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div class="info-container">
      <div class="datepicker fs-24">
        <button (click)="pickDate('-')">
          <img src="../../assets/img/btn_before.svg" alt="previous date" />
        </button>
        <span>{{ displayTime.valueOf() | date: "MM/dd/YYYY" }}</span>
        <button (click)="pickDate('+')" [disabled]="diffDays === 0">
          <img src="../../assets/img/btn_next.svg" alt="next date" />
        </button>
      </div>
      <div *ngIf="apiResData?.length == 0" class="no-data">No Data.</div>
      <div class="line-group" *ngIf="apiResData?.length > 0">
        <div class="line-box" *ngFor="let item of apiResData; let idx = index">
          <div class="kpi-group">
            <div class="compare-info">
              <table>
                <tr>
                  <th colspan="4" class="fs-24" style="color: #2a2a2a">
                    {{ item?.lineName }}
                  </th>
                </tr>
                <tr>
                  <td
                    rowspan="2"
                    style="
                      width: 14.3%;
                      background: #404040;
                      border-right: 1px solid #6f6f6f;
                    "
                  ></td>
                  <td class="fs-18" style="width: 28.5%">Month</td>
                  <td class="fs-18" style="width: 28.5%">Week</td>
                  <td class="fs-18" style="width: 28.5%">Day</td>
                </tr>
                <tr style="background: #6c6c6c">
                  <td class="fs-12">
                    PN./ DPR<span class="fs-10">(ppm)</span>
                  </td>
                  <td class="fs-12">
                    PN./ DPR<span class="fs-10">(ppm)</span>
                  </td>
                  <td class="fs-12">
                    PN./ DPR<span class="fs-10">(ppm)</span>
                  </td>
                </tr>
                <tr *ngFor="let compareItem of item?.compare">
                  <td class="fs-10">{{ compareItem?.equipName }}</td>
                  <td class="fs-12" *ngIf="compareItem?.equipName !== 'N/A'">
                    {{
                      dataFormatSvc.checkValDash(
                        compareItem?.monthPn | number: "1.0-0"
                      )
                    }}/{{
                      dataFormatSvc.checkValDash(
                        compareItem?.monthDpr | number: "1.0-0"
                      )
                    }}
                  </td>
                  <td class="fs-12" *ngIf="compareItem?.equipName === 'N/A'">
                    N/A
                  </td>
                  <td class="fs-12" *ngIf="compareItem?.equipName !== 'N/A'">
                    {{
                      dataFormatSvc.checkValDash(
                        compareItem?.weekPn | number: "1.0-0"
                      )
                    }}/{{
                      dataFormatSvc.checkValDash(
                        compareItem?.weekDpr | number: "1.0-0"
                      )
                    }}
                  </td>
                  <td class="fs-12" *ngIf="compareItem?.equipName === 'N/A'">
                    N/A
                  </td>
                  <td class="fs-12" *ngIf="compareItem?.equipName !== 'N/A'">
                    {{
                      dataFormatSvc.checkValDash(
                        compareItem?.dayPn | number: "1.0-0"
                      )
                    }}/{{
                      dataFormatSvc.checkValDash(
                        compareItem?.dayDpr | number: "1.0-0"
                      )
                    }}
                  </td>
                  <td class="fs-12" *ngIf="compareItem?.equipName === 'N/A'">
                    N/A
                  </td>
                </tr>
              </table>
            </div>
            <div class="status-info">
              <div
                class="info-box"
                *ngFor="let statusItem of item?.nowStatus; let i = index"
              >
                <div class="item-name fs-16">
                  <p>CM{{ statusItem?.equipInx + 1 }}</p>
                  <p class="device-name">{{ statusItem?.equipName }}</p>
                </div>
                <div class="item-status">
                  <div
                    class="status-circle"
                    [ngStyle]="{
                      background:
                        statusItem?.equipName === 'N/A'
                          ? '#404040'
                          : statusItem?.dpr >= 700
                          ? '#ff3a3a'
                          : '#11e834'
                    }"
                  ></div>
                </div>
                <div class="item-info">
                  <table *ngIf="statusItem?.equipName !== 'N/A'">
                    <tr>
                      <td>DPR.</td>
                      <td
                        [ngStyle]="{
                          color: statusItem?.dpr >= 700 ? '#ff3a3a' : '#fff'
                        }"
                      >
                        {{
                          dataFormatSvc.checkValDash(
                            statusItem?.dpr | number: "1.0-0"
                          )
                        }}
                      </td>
                      <td>ppm</td>
                    </tr>
                    <tr>
                      <td>DPN.</td>
                      <td>
                        {{
                          dataFormatSvc.checkValDash(
                            statusItem?.dpn | number: "1.0-0"
                          )
                        }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>PN.</td>
                      <td>
                        {{
                          dataFormatSvc.checkValDash(
                            statusItem?.pn | number: "1.0-0"
                          )
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </table>
                  <div
                    *ngIf="statusItem?.equipName === 'N/A'"
                    style="
                      height: calc(100% - 20px);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p class="fs-16" style="color: #bababa">N/A</p>
                  </div>
                  <div
                    class="color-info"
                    [ngStyle]="{
                      background:
                        statusItem?.equipName === 'N/A'
                          ? '#6F6F6F'
                          : getColor(i)
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-group">
            <div class="chart-box">
              <canvas [id]="lineName[idx]"></canvas>
            </div>
            <div class="now-data-box">
              <table>
                <tr>
                  <th colspan="2" class="fs-18">Now</th>
                </tr>
                <tr *ngFor="let nowItem of item?.dayHistory">
                  <td class="fs-10">{{ nowItem?.equipName }}</td>
                  <td class="fs-8">
                    <div style="height: 18px">
                      <span>DPR:</span>
                      <span
                        [ngStyle]="{
                          color: nowItem?.dpr >= 700 ? '#ff3a3a' : '#fff'
                        }"
                        >{{
                          nowItem?.dpr !== "N/A"
                            ? dataFormatSvc.checkValDash(
                                nowItem?.dpr | number: "1.0-0"
                              )
                            : "N/A"
                        }}</span
                      >
                      <span style="color: #bababa">ppm</span>
                    </div>
                    <div style="height: 13px; background: #4b4b4b">
                      <span>DPN</span>
                      <span>/</span>
                      <span>PN.</span>
                    </div>
                    <div style="height: 14px">
                      <span>{{
                        nowItem?.dpn !== "N/A"
                          ? dataFormatSvc.checkValDash(
                              nowItem?.dpn | number: "1.0-0"
                            )
                          : "N/A"
                      }}</span>
                      <span>/</span>
                      <span>{{
                        nowItem?.pn !== "N/A"
                          ? dataFormatSvc.checkValDash(
                              nowItem?.pn | number: "1.0-0"
                            )
                          : "N/A"
                      }}</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>
  <!-- Auto Refresh -->
  <div class="refresh" style="min-width: 1460px">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
