<select *ngIf="!multiple" (change)="onChange($event)" [disabled]="disabled">
  <option *ngFor="let option of options" [value]="option.value">
    {{ option.label }}
  </option>
</select>

<mat-form-field *ngIf="multiple" floatLabel="always">
  <form [formGroup]="dispForm">
    <mat-label></mat-label>
    <mat-select
      formControlName="optionList"
      [placeholder]="'All'"
      [disabled]="disabled"
      multiple
      (openedChange)="onChange($event)"
    >
      <mat-select-trigger>
        <span style="font-size: 24px">{{
          optionList.value ? optionList.value[0] : ""
        }}</span>
        <span
          *ngIf="(optionList.value?.length || 0) > 1"
          style="font-size: 20px; opacity: 0.75"
        >
          (+{{ (optionList.value?.length || 0) - 1 }}
          {{ optionList.value?.length === 2 ? "other" : "others" }})
        </span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of options"
        [value]="option.value"
        style="font-size: 20px"
        >{{ option.value }}</mat-option
      >
    </mat-select>
  </form>
</mat-form-field>
