<div class="container-fluid equipment_efficiency_overview">
  <!-- Top Menu side -->
  <div class="title_group">
    <!-- Title -->
    <div class="title_box">
      <h1
        class="wip_overview_title"
        (click)="changeSectionName = !changeSectionName"
      >
        {{ isNowSectionName }} Route WIP Overview
        <div class="circle">
          <div class="triangle"></div>
        </div>
        <div class="choice_title" *ngIf="changeSectionName">
          <ng-container *ngFor="let item of sectionNameArr">
            <p
              class="choice_text"
              *ngIf="item.isActive === false"
              (click)="clickWipTitle(item.sectionName)"
            >
              {{ item.sectionName }}
            </p>
          </ng-container>
        </div>
      </h1>
    </div>

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="/assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <!-- Slide View -->
  <div class="container_box">
    <div
      class="row noData"
      [ngClass]="{ noDataShow: resItemDataArr.length === 0 }"
    >
      <p>No data.</p>
    </div>

    <div class="line_info_group">
      <!-- Card line UI -->
      <div
        class="line_box"
        *ngFor="let item of resItemDataArr; let idx = index"
      >
        <!-- line Info box -->
        <div class="col-2 info_text_block">
          <div class="title">MO No.</div>
          <div class="title_value">{{ item.moNo }}</div>

          <div class="title">Part No.</div>
          <div class="title_value">{{ item.product }}</div>

          <div class="title">MO Qty</div>
          <div class="title_value">{{ item.targetOutput | number }}</div>

          <div class="title">Line</div>
          <div class="title_value">{{ item.line }}</div>

          <div class="title">Start Time</div>
          <div class="title_value">
            {{ item.startTime | date: "yyyy/M/dd HH:mm" }}
          </div>
        </div>

        <!-- line point box-->
        <div class="col-10 line_point_block">
          <!-- Card 資訊 -->
          <ng-container>
            <div
              class="line_point_group"
              [ngClass]="{ multiple_line: item.lines.length > 1 }"
              *ngFor="let itemChild of item.lines"
            >
              <div class="line_title">
                {{ this.dataFormatSvc.checkValDash(itemChild.lineName) }} ,
                <span
                  >Side :
                  {{
                    this.dataFormatSvc.checkValDash(itemChild.sideName)
                  }}</span
                >
              </div>

              <div class="point_group">
                <!-- 站點 => 第一站 -->
                <div class="point_start">
                  {{ itemChild.inputCount | number }}
                </div>

                <!-- 中間站點 -->
                <div class="point_box_group">
                  <div
                    class="point_box"
                    *ngFor="
                      let itemPoint of itemChild.pointStation;
                      last as last
                    "
                    [ngClass]="{ point_box_end: last }"
                  >
                    <div class="point">
                      <div class="point_circle"></div>
                      <div class="point_name">{{ itemPoint.stationName }}</div>
                    </div>

                    <div class="line">
                      <div class="line_num" [ngClass]="{ end_line_num: last }">
                        {{ itemPoint.countNum }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Card line UI -->
      <!-- <div class="row line_box">
        line Info box
        <div class="col-2 info_text_block">
          <div class="title">MO No.</div>
          <div class="title_value">DE1BH01QL004</div>

          <div class="title">Part No.</div>
          <div class="title_value">RLOD-PBH0014C0G-44</div>

          <div class="title">MO Qty</div>
          <div class="title_value">12000</div>

          <div class="title">Line</div>
          <div class="title_value">Line A</div>

          <div class="title">Start Time</div>
          <div class="title_value">2021/08/05 05:23</div>
        </div>

        line point box
        <div class="col-10 line_point_block">
          Card 資訊
          <div class="line_point_group multiple_line">
            <div class="line_title">
              SMT-A,
              <span>Side : A</span>
            </div>

            <div class="point_group">
              站點 => 第一站
              <div class="point_start">6960</div>

              中間站點
              <div class="point_box_group">
                <div class="point_box">
                  <div class="point">
                    <div class="point_circle"></div>
                    <div class="point_name">SMT-IN</div>
                  </div>
                  <div class="line">
                    <div class="line_num">201</div>
                  </div>
                </div>

                <div class="point_box">
                  <div class="point">
                    <div class="point_circle"></div>
                    <div class="point_name">SMT-IN</div>
                  </div>
                  <div class="line">
                    <div class="line_num">201</div>
                  </div>
                </div>

                <div class="point_box">
                  <div class="point">
                    <div class="point_circle"></div>
                    <div class="point_name">SMT-IN</div>
                  </div>
                  <div class="line">
                    <div class="line_num">201</div>
                  </div>
                </div>

                <div class="point_box point_box_end">
                  <div class="point">
                    <div class="point_circle"></div>
                    <div class="point_name">SMT-END</div>
                  </div>
                  <div class="line">
                    <div class="line_num end_line_num">201</div>
                  </div>
                </div>
              </div>

              站點 => 最後一站
              <div class="point_end">746</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- Footer -->
  <div class="foot">
    <div class="page_num">
      <img
        [ngClass]="{ page_arrow_disabled: isPageNum === 1 }"
        class="page_arrow page_arrow_left"
        src="assets/img/page_arrow_left.svg"
        alt=""
        (click)="
          swapPageNum('-1'); getAllApiFn(sectionNameToParameters, isPageNum, 3)
        "
      />
      <p class="number">{{ isPageNum }} / {{ isTotalPageNum }}</p>
      <img
        [ngClass]="{ page_arrow_disabled: isPageNum === isTotalPageNum }"
        class="page_arrow page_arrow_right"
        src="assets/img/page_arrow_right.svg"
        alt=""
        (click)="
          swapPageNum('+1'); getAllApiFn(sectionNameToParameters, isPageNum, 3)
        "
      />
    </div>

    <!-- Auto swap -->
    <div class="auto_swap" (click)="this.choiceSecShow = !this.choiceSecShow">
      <!-- {{ isSaveSec }} sec /  -->
      Auto swap
      <img class="swap_img" src="assets/img/arrow_swap_top.svg" alt="" />
      <div class="sec_group" *ngIf="choiceSecShow">
        <p
          class="btn_sec"
          *ngFor="let item of swapSecArr"
          (click)="swapSecs(item.secNum)"
        >
          <img *ngIf="item.isActive" src="assets/img/swap_check.svg" alt="" />
          <span *ngIf="item.isActive === false"> </span>
          {{ item.secNum === "Stop" ? item.secNum : item.secNum * 10 }}
          <span *ngIf="item.secNum !== 'Stop'">sec</span>
        </p>
        <!-- <p class="btn_sec" (click)="swapSecs(3)">
            <img src="assets/img/swap_check.svg" alt="" />
            30 sec
          </p> -->
      </div>
    </div>
  </div>
</div>
