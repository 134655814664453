<div class="production_hours_container">
  <div class="title_group">
    <h1 class="title_name">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      SORT Table
    </h1>

    <!-- <p>{{ DATA | json }}</p> -->

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container_box">
    <div class="table_group">
      <!-- search group-->
      <div class="search_group">
        <div class="search">
          <img
            class="search_icon"
            src="../../assets/img/smart_factory/bi_search.svg"
            alt="search-icon"
          />
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search"
            #input
          />
        </div>
      </div>

      <div class="table_scroll_box">
        <!-- 第一層 Table -->
        <div class="table_container mat-elevation-z8">
          <!-- Table 吃 DataSource資料 -->
          <mat-table
            #table
            [dataSource]="dataSource"
            multiTemplateDataRows
            matSort
            #sort="matSort"
            matSortActive="OrderStartTime"
            matSortDirection="desc"
            (matSortChange)="sortData($event)"
          >
            <!-- Customer Column -->
            <ng-container matColumnDef="customer">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = dataIndex">
                {{ element.customer }}
              </mat-cell>
            </ng-container>

            <!-- Part No. Column -->
            <ng-container matColumnDef="product">
              <mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class=""
                style="flex-grow: 1.6"
              >
                Part No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
                class=""
                style="flex-grow: 1.6"
              >
                {{ element.product }}
              </mat-cell>
            </ng-container>

            <!-- MONo Column -->
            <ng-container matColumnDef="order">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex-grow: 1.5"
              >
                MO No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
                style="flex-grow: 1.5"
              >
                {{ element.order }}
              </mat-cell>
            </ng-container>

            <!-- Section Column -->
            <ng-container matColumnDef="section">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex-grow: 1"
              >
                Section
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1"
              >
                {{ element.section }}
              </mat-cell>
            </ng-container>

            <!-- side Column -->
            <ng-container matColumnDef="side">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex-grow: 0.5"
              >
                side
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 0.5"
              >
                {{ element.side }}
              </mat-cell>
            </ng-container>

            <!-- Start Time Column -->
            <ng-container matColumnDef="orderStartTime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex_15percent"
              >
                Start time
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class="flex_15percent"
              >
                <span>
                  {{ element.orderStartTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Operators Column -->
            <ng-container matColumnDef="operators">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Operators
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = dataIndex">
                <span class="cell_text_right">
                  {{
                    +element.operators < 0 ? 0 : (element.operators | number)
                  }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Cycle Time(IE) Column -->
            <ng-container matColumnDef="ieStandardTime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex-grow: 1"
              >
                <!-- Std Time(IE) -->
                Cycle Time<br />(IE)(min)
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
                style="flex-grow: 1"
              >
                <span class="cell_text_right">
                  {{ element.ieStandardTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Std Hr.(M) Column -->
            <ng-container matColumnDef="mStandardTime">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Std Time(M)
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = dataIndex">
                <span class="cell_text_right">
                  {{
                    +element.mStandardTime < 0 || element.mStandardTime === null
                      ? "0.0"
                      : (element.mStandardTime | number: "0.1-1")
                  }}
                  mins
                </span>
              </mat-cell>
            </ng-container>

            <!-- Act Time Column -->
            <ng-container matColumnDef="actualTime">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Act Time <br />(min)
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
              >
                <span class="cell_text_right">
                  {{ element.actualTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Efficiency Column -->
            <ng-container matColumnDef="performance">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex-grow: 1.3"
              >
                Efficiency
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
                style="flex-grow: 1.3"
              >
                <span
                  class="cell_text_right"
                  [ngClass]="{
                    c_yellow:
                      +element.performance !== 0
                        ? !+element.performance || +element.performance > 100
                        : false,
                    c_red: +element.performance === 0
                  }"
                >
                  {{ element.performance }} %
                </span>
              </mat-cell>
            </ng-container>

            <!-- MO_Quantity Column -->
            <ng-container matColumnDef="MO_Quantity">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                MO Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                [attr.rowspan]="getRowSpan('order', i)"
                [style.opacity]="getRowSpan('order', i) ? '' : 0.1"
              >
                <span class="cell_text_right">
                  {{ element.MO_Quantity }}
                </span>

                <!-- 數據異常 -->
                <!-- <span
                  class="cell_text_right"
                  [ngClass]="{
                    warn_c_red: element.MO_Quantity < element.Finish_Quantity
                  }"
                >
                  {{ element.MO_Quantity }}
                </span> -->
              </mat-cell>
            </ng-container>

            <!-- Output Qty Column -->
            <ng-container matColumnDef="Finish_Quantity">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Output Qty
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = dataIndex">
                <span class="cell_text_right">
                  {{ element.Finish_Quantity }}
                </span>

                <!-- 數據異常 -->
                <!-- <span
                  class="cell_text_right"
                  [ngClass]="{
                    warn_c_red: element.MO_Quantity < element.Finish_Quantity
                  }"
                >
                  {{ element.Finish_Quantity | number }}
                </span> -->
              </mat-cell>
            </ng-container>

            <!-- 表頭 sticky: true => fix ".mat-header-row" -->
            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              matRipple
              class="element-row"
            >
            </mat-row>
          </mat-table>
        </div>

        <!-- page navigation-->
        <mat-paginator
          class="paginator"
          #MatPaginator
          [showFirstLastButtons]="true"
          [length]="dataSource"
          [pageIndex]="0"
          [pageSize]="12"
          (page)="onPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
