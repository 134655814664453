<div class="equipment_alarm">
  <ng-container
    *ngIf="
      apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer');
      else PermissionDenied
    "
  >
    <div class="title_group">
      <div class="title_box">
        <!-- Title -->
        <div class="title_box">
          <button class="logo-btn" style="margin-right: 24px" title="logo">
            <img
              style="width: 114px"
              routerLink="/smartFactory/web/main-board"
              src="../../assets/img/homeico.png"
              alt="logo"
            />
          </button>
          <h1 class="wip_overview_title">Equipments Alarm</h1>
        </div>

        <!-- Category dropdown -->
        <div class="dropdown_box">
          <label>Category:</label>
          <!-- TODO: get sections by API -->
          <select (change)="switchSection($event.target.value)">
            <option
              *ngFor="let item of sectionNameArr"
              [selected]="item === apiParamsObj.sectionName"
              [value]="item"
            >
              {{ sectionDisplayMappingObj[item] }}
            </option>
          </select>
        </div>

        <!-- time -->
        <div class="time">
          <!-- <p>10:23 <span>AM</span></p> -->
          <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
          <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
            <img
              class="menu-bt"
              src="../../assets/img/btn_menu.svg"
              alt="menu"
            />
          </button>
          <div class="login-menu" *ngIf="isMenuOpen">
            <button routerLink="/menu">
              <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
              Dashboard Menu
            </button>
            <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
              <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
            </button>
            <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
              <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign
              out
            </button>
          </div>
        </div>
      </div>

      <!-- Line & Time-Range group -->
      <div class="line_box">
        <!-- 下拉選單 Line -->
        <div class="select_tag">
          <label>Line</label>
          <select (change)="switchSelectOptionName($event.target.value)">
            <option
              *ngFor="let item of allOptionNameArr"
              [selected]="item === apiParamsObj.selectOptionName"
            >
              {{ item }}
            </option>
          </select>
        </div>

        <!-- Computation between Time range -->
        <div class="time_range">
          <label>Date Range:</label>
          <input
            [(ngModel)]="selectedMoments"
            [min]="minDate"
            [max]="maxDate"
            [selectMode]="'range'"
            [owlDateTime]="dtPicker"
            (dateTimeChange)="getRangeDate()"
          />
          <mat-datepicker-toggle
            [owlDateTimeTrigger]="dtPicker"
            style="margin-left: 12px"
          ></mat-datepicker-toggle>
          <img
            class="default_button"
            src="../../../assets/img/btn_date_default.svg"
            alt="Default"
            style="margin-left: 12px"
            (click)="defaultDateTime()"
          />
        </div>
      </div>
      <owl-date-time
        #dtPicker
        (afterPickerOpen)="defaultDateTimeRange()"
      ></owl-date-time>
    </div>

    <div class="info_container">
      <div class="info_group">
        <div class="info_kpi">
          <p class="title">Avg. Line Running %</p>
          <p class="kpi">
            {{
              dataFormatSvc.checkValDash(
                apiResData?.runningRate?.rate | percent: "1.2-2"
              )
            }}
          </p>
        </div>
        <div class="info_chart">
          <p>Line Running %</p>
          <div class="chart_box">
            <canvas id="runningRate"></canvas>
          </div>
        </div>
      </div>
      <div class="info_group">
        <div class="info_kpi">
          <p class="title">Avg. Line Idle %</p>
          <p class="kpi">
            {{
              dataFormatSvc.checkValDash(
                apiResData?.idleRate?.rate | percent: "1.2-2"
              )
            }}
          </p>
        </div>
        <div class="info_chart">
          <p>Line Idle %</p>
          <div class="chart_box">
            <canvas id="idleRate"></canvas>
          </div>
        </div>
      </div>
      <div class="info_group">
        <div class="info_kpi">
          <p class="title">Avg. Line Downtime %</p>
          <p class="kpi">
            {{
              dataFormatSvc.checkValDash(
                apiResData?.downtimeRate?.rate | percent: "1.2-2"
              )
            }}
          </p>
        </div>
        <div class="info_chart">
          <p>Line Downtime %</p>
          <div class="chart_box">
            <canvas id="downtimeRate"></canvas>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
