<div class="environment">
  <div class="feature1">
    <!-- <div class="menu-bt" routerLink="/menu">
      <img src="../../assets/img/menu.svg" />
    </div> -->
    <div class="feature_title">Environment</div>
    <div class="select-type">
      <select (change)="onChange($event.target.value)" [value]="curType">
        <option value="assy">ASSY</option>
        <option value="loading">Loading</option>
        <!-- <option value="C">Zone C</option> -->
      </select>
    </div>
    <!-- <div class="float-right">樓層 : 1 樓</div> -->

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="feature2">
    <div class="gauge-container">
      <div
        id="gauge_T"
        class="gauge-chart"
        [ngClass]="{ warning: currentValue.t <= 22 || currentValue.t >= 28 }"
      ></div>
    </div>

    <div class="gauge-container">
      <div
        id="gauge_H"
        class="gauge-chart"
        [ngClass]="{ warning: currentValue.h <= 40 || currentValue.h >= 80 }"
      ></div>
    </div>

    <div class="gauge-container">
      <div
        id="gauge_P"
        class="gauge-chart"
        [ngClass]="{ warning: currentValue.p >= 200 }"
      ></div>
      <div class="img-content" *ngIf="currentValue.p >= 200">
        <img src="../../assets/img/fan.gif" />
      </div>
    </div>

    <div class="gauge-container">
      <div
        id="gauge_C"
        class="gauge-chart"
        [ngClass]="{ warning: currentValue.c >= 1000 }"
      ></div>
    </div>
  </div>

  <div class="feature3">
    <div class="float-left">History</div>
    <div class="float-right dayCtr">
      <div
        class="time-bt"
        [ngClass]="{ select: timeFrame == '1d' }"
        (click)="changeScale('1d')"
      >
        Day
      </div>
      <div
        class="time-bt"
        [ngClass]="{ select: timeFrame == '1w' }"
        (click)="changeScale('1w')"
      >
        Week
      </div>
      <div
        class="time-bt"
        [ngClass]="{ select: timeFrame == '1M' }"
        (click)="changeScale('1M')"
      >
        Month
      </div>
    </div>
  </div>

  <div class="feature4">
    <!-- <div class='chart-container' [ngClass]="{'warning': currentValue.t <= 22 || currentValue.t >= 28}"> -->
    <div class="chart-container">
      <canvas id="canvas_T" height="200">{{ temperatureLinechart }}</canvas>
    </div>
    <!-- <div class='chart-container' [ngClass]="{'warning': currentValue.h <= 40 || currentValue.h >= 80}"> -->
    <div class="chart-container">
      <canvas id="canvas_H" height="200">{{ humidityLinechart }}</canvas>
    </div>
    <!-- <div class='chart-container' [ngClass]="{'warning': currentValue.p >= 50}"> -->
    <div class="chart-container">
      <canvas id="canvas_P" height="200">{{ humidityLinechart }}</canvas>
    </div>
    <!-- <div class='chart-container' [ngClass]="{'warning': currentValue.c >= 1000}"> -->
    <div class="chart-container">
      <canvas id="canvas_C" height="200">{{ humidityLinechart }}</canvas>
    </div>
  </div>
</div>
