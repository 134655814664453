import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { DataSource } from '@angular/cdk/collections';
import { fromEvent, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, } from 'rxjs/operators';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { animate, query, state, style, transition, trigger } from '@angular/animations';

import { HttpClient, } from '@angular/common/http';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

// import sort keys variables
import { SORT_KEY, SORT_DIRECTION } from 'src/app/_variable/quality-overview-types';

// app module.ts｀
// 日期
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
// Component
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; // 日期
import * as moment from 'moment';
import { SaveMonoInformationService } from '../_services/save-mono-information.service';

@Component({
  // 設定 changeDetection 屬性,當設定為 OnPush 時，
  // 只有在元件的 @Input 變更，且真正有變更時，才會進行變更偵測。
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-quality-overview',
  templateUrl: './new-quality-overview.component.html',
  styleUrls: ['./new-quality-overview.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('iconExpand', [
      state('collapsed, void', style({ transform: 'rotate(90deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})
export class NewQualityOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,

    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
    // 監測所有元件控制變更時機
    private changeDetectorRef: ChangeDetectorRef,
    private moSvc: SaveMonoInformationService,

    private FB: FormBuilder,
  ) {
    this.isSvLoading.loading = true;
  }

  pageAutoRefresh = true;
  intervalSeconds = 60;
  rxTimer$;

  range = new FormGroup({
    start: new FormControl(),
    // start: new FormControl(moment(new Date().setHours(8, 0, 0)).subtract(7, 'days')),
    end: new FormControl(),
    // end: new FormControl(moment(new Date().setHours(7, 59, 0))),
  });

  isRangeStartTime = this.range.controls.start.value;
  isRangeEndTime = this.range.controls.end.value;

  startDate = moment();
  minDate = moment().subtract(7, 'days');
  maxDate = moment();

  isTotalPageNum = null;
  queryObj = {
    search: null,
    startTime: null,
    endTime: null,
    sort: 'AVGRTY',
    offset: 1,
    limit: 12,
  };

  // dataSource: MatTableDataSource<UserData>;
  DATA = [];
  outDataSource = new MatTableDataSource(); // 初始化MatTable 表格資料
  usersData = [];
  expandedElement: any = {};

  allStatusName = [
    'SMT',
    'Loading',
    'ATE',
    'ASSY',
    'Packing'
  ];

  // Select icon choices
  selected = false;
  newData = [];

  filterValue = '';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;
  @ViewChild('input') input: ElementRef;


  // 第一層標題 - ( 要有子項目增加 'action' )
  // displayedColumns: string[] = ['action', 'product', 'moNo', 'moQty', 'inputQty', 'defectQty', 'avgrty', 'lowestYield'];
  displayedColumns: string[] = ['action', 'moNo', 'partNo', 'moQty', 'inputQty', 'defectQty', 'avgrty', 'lowestYield'];



  // 第二層標題
  // innerDisplayedColumns: string[] = ['action', 'line', 'section', 'side', 'actProductionTime', 'moQty', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'rty', 'timeToRepair', 'overdue'];
  innerDisplayedColumns: string[] = ['action', 'line', 'section', 'side', 'inputQty', 'defectQty', 'rty', 'lowestYield'];


  isRuningOrder;
  spans = [];
  displayedColumnsArrIndex: string[] = ['action', 'moNo', 'partNo', 'moQty', 'inputQty', 'defectQty', 'avgrty', 'lowestYield'];
  innerDisplayedColumnsArrIndex: string[] = ['action', 'line', 'section', 'side', 'inputQty', 'defectQty', 'rty', 'lowestYield'];


  // DateRangePicker filter 六日不能選
  familyDayFilter(date: moment.Moment): boolean {
    const day = date.day();
    // return !this.filter.find(x => moment(x) === date);
    // return date === moment(new Date(2021, 9, 8).getTime());
    // return day !== 6 && day !== 0;
    // return day !== 0;
    return true;
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate(dateRangeStart, dateRangeEnd) {
    if (dateRangeStart === null || dateRangeEnd === null) { return; }
    this.isSvLoading.loading = true;
    this.queryObj.startTime = moment(this.range.value.start).startOf('day').hours(8).valueOf();
    this.queryObj.endTime = moment(this.range.value.end).endOf('day').add(1, 'days').hours(7).valueOf();
    console.log('選取日期 this.queryObj:', this.queryObj);
    console.log('getRangeDate Call API:');
    this.queryObj.offset = 1;
    this.getAllApiFn(this.queryObj);
  }

  // DateRangePicker 設定 Defaulte range
  setDefaultRangeDate() {
    this.isSvLoading.loading = true;
    // formGroup setValue
    this.range.setValue({
      start: '', // 預設 startTime 為後端判斷，傳 All
      // start: moment(new Date().setHours(8, 0, 0)).subtract(7, 'days'),
      end: '',
      // end: moment(new Date().setHours(7, 59, 0)),
    });
    // this.queryObj.startTime = new Date(this.range.value.start).getTime();
    this.queryObj.startTime = null;
    this.queryObj.endTime = null;
    // console.log('this.range.value.end:', new Date(this.range.value.end).setDate(new Date(this.range.value.end).getDate() + 1));
    // var tomorrow = moment(today).add(1, 'days');
    // console.log('this.range.value.end:', new Date(moment(this.range.value.end).add(1, 'days').valueOf()));
    console.log('預設日期 this.queryObj:', this.queryObj);
    console.log('getRangeDate Call API:');
    this.queryObj.offset = 1;
    this.getAllApiFn(this.queryObj);
  }


  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      console.log('Sort排序 Call API:', this.queryObj);
      this.getAllApiFn(this.queryObj);
    });
  }

  ngOnInit(): void {
    this.rxTimerStart();
    // 子工單 Duration 預設隱藏
    this.selectCheckbox({ target: { value: 'duration', checked: false } });
  }

  ngAfterViewInit() {
    // 呼叫API => 將回傳陣列資料 傳進 MatTableDataSource 的 data
    console.log('ngAfterViewInit this.queryObj:', this.queryObj);
    this.getAllApiFn(this.queryObj);

    // Search Debounce timeout
    this.applyFilter$().subscribe();


    // this.outDataSource.paginator = this.paginator;
    // this.outDataSource.sort = this.sort;
    this.outDataSource.sortingDataAccessor = (data, header) => data[header];
    this.outDataSource.filter = this.filterValue.trim().toString().toLowerCase();

  }

  ngOnDestroy() {
    this.rxTimer$.unsubscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  moStatusBgColor(str) {
    if (str === 'Waiting') { return 'status_color_gray'; }
    if (str === 'In production') { return 'status_color_orange'; }
    if (str === 'Not in production') { return 'status_color_blue'; }
    if (str === 'Complete') { return 'status_color_green'; }

  }

  // to Unix time
  toUnixTime(str) {
    return new Date(str).getTime();
  }

  // 分頁切換
  swapPageNum(str) {
    if (str === '+1') { this.queryObj.offset++; }
    if (str === '-1') { this.queryObj.offset--; }
  }

  // Table 箭頭 方向切換
  iconSwitch(element) {
    // 預設 關閉
    return element.isExpanded ? 'collapsed' : 'expanded';
  }

  // 子層展開
  expendClick(element) {
    console.log('expendClick ******************');
    // console.log('oldelement:', oldelement);
    if (element.children.length === 0) {
      element.isExpanded = false;
    }
    element.isExpanded = !element.isExpanded;
    if (element !== this.expandedElement) {
      this.expandedElement.isExpanded = false;
    }
    this.expandedElement = element;
  }

  expandHandler(row) {
    if (row.children.length === 0) { return 'collapsed'; }
    return row.selfID === this.expandedElement.selfID && this.expandedElement.isExpanded ? 'expanded' : 'collapsed';
  }

  // 子層 展開設定
  setChildExpanded(arr) {
    return arr.map((item) => {
      // set item expanded status
      item.isExpanded = false;
      item.selfID = item.partNo + '-' + item.moNo;
      return item;
    });
  }

  // Efficiency color class
  efficiencyTextColor(val) {
    if (+val === 0 || val === '-') { return; }
    if (+val < 98) { return 'c_red'; }
    if (+val >= 102) { return 'c_yellow'; }
  }

  // table event sort排序 觸發排序事件 merge cell function --- step 3
  sortData(element) {
    console.log('sortData element:', element);
    this.isSvLoading.loading = true;
    this.queryObj.sort = SORT_KEY[element.active] + SORT_DIRECTION[element.direction];
    // console.log('sort排序 this.queryObj:', this.queryObj.sort);
    console.log('Sort排序 Call API:', this.queryObj);
    this.getAllApiFn(this.queryObj);

  }

  // table event 換頁觸發 Paginate Change
  onPaginateChange(e) {
    console.log('onPaginateChange e:', e);
    this.isSvLoading.loading = true;
    this.queryObj.offset = e.pageIndex + 1;
    console.log('換頁觸發 this.queryObj:', this.queryObj.offset);
    console.log('Sort排序 Call API:', this.queryObj);
    this.getAllApiFn(this.queryObj);

    // setTimeout(() => {
    //   this.isSvLoading.loading = false;
    // }, 900);
  }

  // 搜尋 table event Search input filter
  applyFilter$() {
    return fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((text) => {
          this.isSvLoading.loading = true;
          // console.log('this.input.nativeElement.value', this.input.nativeElement.value);
          this.queryObj.search = this.input.nativeElement.value.trim().toLowerCase();
          this.queryObj.offset = 1;
          console.log('搜尋 Call API => ', this.queryObj);
          this.getAllApiFn(this.queryObj);
        })
      );
  }

  // check mono. from mo service
  getMoItem(item) {
    console.log('item:', item);
    // this.moSvc.customer = item.customer === '-' ? '-' : item.customer;
    this.moSvc.product = item.partNo === '-' ? '-' : item.partNo;
    this.moSvc.order = item.moNo === '-' ? '-' : item.moNo;
    // this.moSvc.startTime = item.startTime === '-' ? '-' : item.startTime;
    this.moSvc.inputQty = item.inputQty === '-' ? '-' : item.inputQty;
    this.moSvc.defectQuantity = item.defectQty === '-' ? '-' : item.defectQty;
    this.moSvc.avgrty = item.avgrty === '-' ? '-' : item.avgrty * 100;
    this.moSvc.lowestYield = item.lowestYield === '-' ? '-' : item.lowestYield;
    // console.log('item.criticalStation:', item.criticalStation);
  }

  // subscribe 訂閱 陣列變動， 拿到 row data 更新排序的陣列
  subscribeDataSource() {
    return this.outDataSource.connect().pipe().subscribe(
      (newSortArr) => {
        // 將新 Array 放回 this.DATA
        this.usersData = newSortArr;
      });
  }

  // Pipe 資料處理
  pipeTranslateInfo(arr: any) {
    return arr.map((x) => {
      const xx = { ...x };
      if (xx.moQty !== '-') {
        xx.formattedMoQty = this.dataFormatSvc.pipeNumber(+xx.moQty, '');
      }
      if (xx.inputQty !== '-') {
        xx.formattedInputQty = this.dataFormatSvc.pipeNumber(+xx.inputQty, '');
      }
      if (xx.defectQty !== '-') {
        xx.formattedDefectQty = this.dataFormatSvc.pipeNumber(+xx.defectQty, '');
      }
      if (xx.avgrty !== '-') {
        xx.formattedAvgRty = this.dataFormatSvc.pipeNumber(+(xx.avgrty * 100), '0.2-2');
      }
      if (xx.lowestYield !== '-') {
        xx.formattedLowestYield = xx.lowestYield;
      }

      this.dataFormatSvc.nullToDash(xx.children);
      // 子層 Formater
      xx.children.map((i) => {
        i.moNo = xx.moNo;
        if (i.inputQty !== '-') {
          i.formattedInputQty = this.dataFormatSvc.pipeNumber(i.inputQty, '');
        }
        if (i.defectQty !== '-') {
          i.formattedDefectQty = this.dataFormatSvc.pipeNumber(i.defectQty, '');
        }
        if (i.rty !== '-') {
          i.formattedRty = this.dataFormatSvc.pipeNumber(+(i.rty * 100), '0.2-2');
        }
        if (i.lowestYield !== '-') {
          i.formattedLowestYield = i.lowestYield;
        }

      });
      return xx;
    });
  }

  // check Act Porduciton Time Warning (工作區段)
  checkChildActProductionTimeWarning(arr) {
    // ActPorducitonTime (工作區段) => ActPorducitonTime > cycleTime * outputQty
    return arr.map((x) => {
      const xx = { ...x };
      xx.children.map((i) => {
        if (i.actProductionTime > i.standardTime) {
          i.actProductionWarning = true;
        } else {
          i.actProductionWarning = false;
        }
      });
      return xx;
      // ActPorducitonTime (MO) => 工作區段有 warning show icon
    });
  }

  // check Act Porduciton Time Warning (MO)
  checkMoActProductionTimeWarning(arr) {
    // ActPorducitonTime (MO) => 工作區段有 warning show icon
    return arr.map((x) => {
      const xx = { ...x };
      xx.children.map((i) => {
        if (i.actProductionWarning === true) {
          xx.actProductionWarning = true;
        } else {
          xx.actProductionWarning = false;
        }
      });
      return xx;
    });
  }

  // select click icon
  selectClickIcon() {
    this.selected = !this.selected;
    // console.log(this.selected);
  }

  // select click checkbox (母工單)
  // selectCheckbox(e) {
  //   // console.log('e:checked ====>', e.target.value);
  //   // console.log('e:checked ====>', e.target.checked);
  //   const getValue = e.target.value;
  //   if (e.target.checked) {
  //     // e.target.checked = false;．
  //     this.displayedColumns.push(getValue);
  //     // Mapping 更新 index 排序
  //     this.displayedColumns.sort((a, b) => {
  //       return this.displayedColumnsArrIndex.indexOf(a) - this.displayedColumnsArrIndex.indexOf(b);
  //     });
  //     console.log('this.displayedColumns:', this.displayedColumns);
  //   }
  //   if (e.target.checked === false) {
  //     this.displayedColumns = this.displayedColumns.filter((item) => item !== getValue);
  //     // this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
  //   }
  // }

  // select click checkbox(子工單)
  selectCheckbox(e) {
    // console.log('e:checked ====>', e.target.value);
    // console.log('e:checked ====>', e.target.checked);
    const getValue = e.target.value;
    if (e.target.checked) {
      // e.target.checked = false;．
      this.innerDisplayedColumns.push(getValue);
      // Mapping 更新 index 排序
      this.innerDisplayedColumns.sort((a, b) => {
        return this.innerDisplayedColumnsArrIndex.indexOf(a) - this.innerDisplayedColumnsArrIndex.indexOf(b);
      });
      console.log('this.innerDisplayedColumns:', this.innerDisplayedColumns);
    }
    if (e.target.checked === false) {
      this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
      // this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
    }
  }

  // build res.children Arr to new MatTableDataSource
  buildChildArrToMatTableData(res) {
    // empty orginal arr
    this.usersData = [];

    // 將要 子層陣列 轉化成 MatTableDataSource
    res.forEach((item) => {
      const checkIsArray = item.children.length > 0 && Array.isArray(item.children);
      // console.log('item:', item);
      if (checkIsArray) {
        this.usersData = [
          ...this.usersData, { ...item, children: new MatTableDataSource(item.children) },
        ];
      } else {
        this.usersData = [...this.usersData, item];
      }
    });

    // 將組合好的 usersData 塞進初始化 MatTableDataSource 完成的 outDataSource
    this.outDataSource.data = this.usersData;

    // 更新 Sort 排序資料 index
    this.changeDetectorRef.detectChanges();
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
    );

    return this.usersData;
  }

  // API Get All Status Name
  getAllStatusName() {
    return this.apiSvc.getNewProductOverviewStatusName();
  }

  // API Table Data Source
  getTableData$(dataObj) {
    return this.apiSvc.getQualityData(
      dataObj.search,
      dataObj.startTime,
      dataObj.endTime,
      dataObj.sort,
      dataObj.offset,
      dataObj.limit
    ).pipe(
      map((res) => {
        // console.log('getTableData row data => ', res);
        // res = this.forTestDataSvc.newEquipOverviewResTestData;
        console.log('getTableData res:', res);

        this.isTotalPageNum = res.total;
        // if (res.msg) {
        //   console.log(`%cMSG => ${res.msg}`, 'background: #222;font-size:18px; color: red');
        // }

        return res.data ?? [];
      }),
      tap((res) => {
        // console.log('newData =>', res);
      }),
      map((res) => this.dataFormatSvc.nullToDash(res)),
      map((res) => this.pipeTranslateInfo(res)),
      map((res) => this.checkChildActProductionTimeWarning(res)),
      map((res) => this.checkMoActProductionTimeWarning(res)),
      map((res) => this.setChildExpanded(res)),
      tap(res => {
        console.log('getTableData$ Formatted res =>', res);
      }),
    );
  }

  // Call API
  getAllApiFn(dataObj) {
    return this.getTableData$(dataObj).pipe(
      tap(res => {
        console.log('getTableData -> ', res);
        // this.allStatusName = this.forTestDataSvc.newProductOverviewStatusNameArr;
      }),
      map((res => this.buildChildArrToMatTableData(res))),
    ).subscribe((res) => {
      // this.subscribeDataSource();
      // changeDetectorRef detectChanges 監測 Dom 元素變更檢測
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.isSvLoading.loading = false;
      }, 800);
    });
  }

}
