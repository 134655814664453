export enum SORT_KEY {
  product = 'PRODUCT',
  order = 'ORDER',
  section = 'SECTION',
  side = 'SIDE',
  orderStartTime = 'STARTTIME',
  ieStandardTime = 'CYCLETIME',
  actualTime = 'ACTUALTIME',
  efficiency = 'EFFICIENCY',
  moQuantity = 'TARGETOUTPUT',
  finishQuantity = 'OUTPUTQTY'
}

export enum SORT_DIRECTION {
  asc = '',
  desc = '-',
}

// 母工單 Sort key
export enum F_SORT_KEY {
  sn = 'SN',
  product = 'PRODUCT',
  targetOutput = 'TARGETOUTPUT',
  startTime = 'STARTTIME',
  endTime = 'ENDTIME'
}

// 子工單 Sort key
export enum Child_SORT_KEY {
  section = 'SECTION',
  line = 'LINE',
  moQty = 'TARGETOUTPUT',
  side = 'SIDE',
  startTime = 'STARTTIME',
  endTime = 'ENDTIME',
  restTime = 'RESTTIME',
  stop = 'STOP',
  lineChange = 'LINECHANGE',
  actProductionTime = 'ACTPRODUCTIONTIEM',
  operators = 'OPERATORS',
  actTime = 'ACTTIME',
  cycleTime = 'CYCLETIME',
  standardTime = 'STANDARDTIME',
  ouput = 'OUTPUT',
  efficiency = 'EFFICIENCY',
}
