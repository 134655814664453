import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

type OuterTableColumnKey = string;
type OuterTableColumnValue = string;
type OuterTableColumns = Record<OuterTableColumnKey, OuterTableColumnValue>;

type InnerTableColumnKey = string;
type InnerTableColumnValue = string;
type InnerTableColumns = Record<InnerTableColumnKey, InnerTableColumnValue>;

@Component({
  selector: 'app-custom-component-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent {

  constructor() { }

  @Input() outerTableColumns: OuterTableColumns;
  @Input() outerTableColumnsToDisplay: OuterTableColumnKey[];
  @Input() outerTableHeaderSticky = false;

  @Input() innerTableColumns: InnerTableColumns;
  @Input() innerTableColumnsToDisplay: InnerTableColumnKey[];

  @Input() outerTableDataSource: object[];
  @Input() innerTableDataSource: object[] | null;

  @Output() changeExpandedElement = new EventEmitter<object | null>();

  expandedElement: object | null;

  @Input() getOuterTextColor: (key: string, element: object) => 'red' | 'yellow' | null = () => null;
  @Input() getInnerTextColor: (key: string, element: object) => 'red' | 'yellow' | null = () => null;

  expandable() {
    return this.innerTableDataSource != null;
  }

  clickOuterTableRow(element: object | null) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.changeExpandedElement.emit(element);
  }
}
