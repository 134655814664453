<div class="container-fluid equip_efficiency_container">
  <div class="container-fluid title_group">
    <h1 class="title_name">Equipment Efficiency</h1>

    <!-- <p>{{ DATA | json }}</p> -->

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container-fluid container_box">
    <!-- Order Info -->
    <div class="order_info_box">
      <p class="title">ORDER Information</p>

      <div class="text_group">
        <p class="key">MO No.</p>
        <p class="value">{{ dataFormatSvc.checkValDash(resInfo.sn) }}</p>
      </div>

      <div class="text_group">
        <p class="key">Start Time</p>
        <p class="value">{{ dataFormatSvc.checkValDash(resInfo.startTime) }}</p>
      </div>

      <div class="text_group">
        <p class="key">Production Time</p>
        <p class="value">
          {{ dataFormatSvc.checkValDash(resInfo.actProductionTime) }}
        </p>
      </div>

      <div class="text_group">
        <p class="key">Part No.</p>
        <p class="value">{{ dataFormatSvc.checkValDash(resInfo.product) }}</p>
      </div>

      <div class="text_group">
        <p class="key">Line</p>
        <p class="value">{{ dataFormatSvc.checkValDash(resInfo.line) }}</p>
      </div>

      <div class="text_group">
        <p class="key">Current Side</p>
        <p class="value">{{ dataFormatSvc.checkValDash(resInfo.side) }}</p>
      </div>
    </div>

    <!-- Order Value -->
    <div class="order_value_box">
      <div class="order_value_group">
        <p class="title">
          {{ orderInfoObj.Sn }} > {{ orderInfoObj.Section }}
          <span>( {{ orderInfoObj.Side }} Side )</span>
        </p>

        <div class="value_group_box">
          <div class="value_group">
            <p class="value_group_title">MO Qty. (pcs)</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.targetOutput) }}
            </p>
          </div>

          <div class="value_group">
            <p class="value_group_title">Input Qty. (pcs)</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.input) }}
            </p>
          </div>

          <div class="value_group">
            <p class="value_group_title">Output Qty. (pcs)</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.output) }}
            </p>
          </div>

          <div class="value_group">
            <p class="value_group_title">Achieve Rate</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.achieveRate) }}%
            </p>
          </div>

          <div class="value_group">
            <p class="value_group_title">Output Yield</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.outYield) }}%
            </p>
          </div>

          <div class="value_group">
            <p class="value_group_title">Efficiency</p>
            <p class="value_group_num">
              {{ dataFormatSvc.checkValDash(resInfo.efficiency) }}%
            </p>
          </div>
        </div>
      </div>

      <div class="order_table">
        <table class="table table-striped table-dark">
          <thead>
            <tr>
              <th scope="col"></th>
              <th
                scope="col"
                *ngFor="let item of tableInfo.tableEquipNameArr"
                style="text-align: center"
              >
                <!-- 設備燈號 => 1) Running - 綠色 2) Alarm - 紅色 3) Idle - 黃色 -->
                <span
                  class="equip_light"
                  [ngClass]="checkEquipLightColor(item.status)"
                >
                </span>
                {{ dataFormatSvc.checkValDash(item.equipName) }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of tableDataArr">
              <td class="col-3" style="text-align: left">
                {{ dataFormatSvc.checkValDash(item.name) }}
              </td>

              <td
                style="text-align: center"
                *ngFor="let itemChild of item.equipValArr"
              >
                {{ dataFormatSvc.checkValDash(itemChild) }}
                <span *ngIf="item.name === 'Efficiency'">%</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- <div class="equip_info">
      <div class="row">
        <h1 class="constructing">Constructing...</h1>
      </div>
    </div>

    <div class="info">
      <h6>Section =>{{ this.Section }}</h6>
      <h6>Line => {{ this.Line }}</h6>
      <h6>Order => {{ this.Order }}</h6>
    </div> -->
</div>
