import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  loading = false;
  isError = false;
  constructor(private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      login: this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
      }),
    });
  }
  get f() { return this.formGroup.controls; }

  submit() {
    this.loading = true;
    this.isError = false;
    const userName = this.f.login.value.username;
    const passWord = this.f.login.value.password;

    setTimeout(() => {
      if (userName === 'admin' && passWord === 'admin') {
        this.router.navigate(['/login'], { queryParams: { token: 'B1C80225-5EDC-D7C5-34A5-114AACC626AB', account: 'admin', role: 1} });
      } else if (userName === 'user1' && passWord === 'user1') {
        this.router.navigate(['/login'], { queryParams: { token: 'B1C80225-5EDC-D7C5-34A5-114AACC626AB', account: 'user1', role: 2} });
      } else {
        this.isError = true;
        this.router.navigate(['/login'], { queryParams: { state: 'fail' } });
      }
      this.loading = false;
    }, 2000);
  }
}
