<div style="margin-bottom: 25px">
  <span
    *ngIf="data.filter === 'overdueWip'"
    class="mat-subheading-2 color-silver"
  >
    {{ data.partNo }}
  </span>
  <span
    *ngIf="data.filter === 'rankingWip' || 'function'"
    class="mat-subheading-2 color-silver"
  >
    {{ data.rankingPartNo }}
  </span>
  <span *ngIf="data.function" class="mat-subheading-2 color-silver">
    <span style="margin: 0 10px; color: #fff">></span>
    {{ dataFormatSvc.AddSpaceBeforeFunctionNameNumber(data.function) }}
  </span>
</div>

<!-- Function Station Group 途程 -->
<div class="wip-group">
  <span *ngIf="wipGroupData.length > 1" class="line"></span>
  <div class="" *ngFor="let data of wipGroupData">
    <div class="station-group">
      <div class="mat-body-2 color-white title">
        {{ dataFormatSvc.AddSpaceBeforeFunctionNameNumber(data.groupName) }}
      </div>
      <div class="mat-body-2 color-silver station">
        <span class="count">{{ data.count }}</span>
      </div>
    </div>
  </div>
</div>

<div class="table_scroll_box">
  <!-- 第一層 Table -->
  <div class="table_container mat-elevation-z0">
    <!-- API RES 沒資料 -->
    <div *ngIf="wipDataSource.data.length === 0">
      <h4
        class="mat-subheading-1 color-white"
        style="width: 100%; text-align: center; margin: 32px 0"
      >
        No data found.
      </h4>
    </div>

    <!-- Table 吃 DataSource資料 -->
    <mat-table
      #table
      *ngIf="wipDataSource.data.length > 0"
      [dataSource]="wipDataSource"
      multiTemplateDataRows
    >
      <!-- Unit Column -->
      <ng-container matColumnDef="unitNo">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 1.9"
        >
          Unit
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 1.9"
        >
          {{ dataFormatSvc.checkValDash(element.serialNumber) }}
        </mat-cell>
      </ng-container>

      <!-- Start Time Column -->
      <ng-container matColumnDef="startTime">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 1.3"
        >
          Start Time
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 1.3"
        >
          {{
            dataFormatSvc.checkValDash(
              element.startTime === null
                ? element.startTime
                : dataFormatSvc.pipeDate(element.startTime, "yyyy/MM/dd HH:mm")
            )
          }}
        </mat-cell>
      </ng-container>

      <!-- End Time Column -->
      <ng-container matColumnDef="endTime">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 1.3"
        >
          End Time
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 1.3"
        >
          {{
            dataFormatSvc.checkValDash(
              element.endTime === null
                ? element.endTime
                : dataFormatSvc.pipeDate(element.endTime, "yyyy/MM/dd HH:mm")
            )
          }}
        </mat-cell>
      </ng-container>

      <!-- Defect Code Column -->
      <ng-container matColumnDef="defectCode">
        <mat-header-cell
          disableClear="true"
          start="desc"
          *matHeaderCellDef
          class=""
          style="flex-grow: 0.5"
        >
          Code
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 0.5"
        >
          {{ dataFormatSvc.checkValDash(element.defectCode) }}
        </mat-cell>
      </ng-container>

      <!-- Part No. Column -->
      <ng-container matColumnDef="partNo">
        <mat-header-cell
          disableClear="true"
          start="desc"
          *matHeaderCellDef
          class=""
          style="flex-grow: 1.4"
        >
          Part No.
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 1.4"
        >
          {{ dataFormatSvc.checkValDash(element.product) }}
        </mat-cell>
      </ng-container>

      <!-- MO No. Column -->
      <ng-container matColumnDef="moNo">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          style="flex-grow: 1.1"
        >
          MO No.
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          style="flex-grow: 1.1"
        >
          {{ dataFormatSvc.checkValDash(element.mo) }}
        </mat-cell>
      </ng-container>

      <!--  Section Column -->
      <ng-container matColumnDef="section">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 0.6"
        >
          Section
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 0.6"
        >
          {{ dataFormatSvc.checkValDash(element.section) }}
        </mat-cell>
      </ng-container>

      <!-- Side Column -->
      <ng-container matColumnDef="side">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 0.4"
        >
          Side
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 0.4"
        >
          {{ dataFormatSvc.checkValDash(element.side) }}
        </mat-cell>
      </ng-container>

      <!-- Function Column -->
      <ng-container matColumnDef="function">
        <mat-header-cell
          *matHeaderCellDef
          disableClear="true"
          start="desc"
          class=""
          style="flex-grow: 0.9"
        >
          Function
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = dataIndex"
          class=""
          style="flex-grow: 0.9"
        >
          {{
            element.function === null
              ? dataFormatSvc.checkValDash(element.groupName)
              : dataFormatSvc.AddSpaceBeforeFunctionNameNumber(
                  element.groupName
                )
          }}
        </mat-cell>
      </ng-container>

      <!-- 表頭 sticky: true => fix ".mat-header-row" -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>
  </div>
</div>

<!-- page navigation-->
<mat-paginator
  class="paginator"
  #MatPaginator
  *ngIf="wipDataSource.data.length > 0"
  [showFirstLastButtons]="true"
  [length]="wipData.total"
  [pageIndex]="wipData.offset / 10"
  [pageSize]="10"
  (page)="wipDataOnPaginateChange($event)"
>
</mat-paginator>
