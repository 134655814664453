<table
  mat-table
  [dataSource]="outerTableDataSource"
  multiTemplateDataRows
  class="mat-elevation-z0"
  style="width: 100%; overflow-x: auto"
>
  <ng-container
    matColumnDef="{{ outerColumn }}"
    *ngFor="let outerColumn of outerTableColumnsToDisplay; let i = index"
  >
    <th mat-header-cell *matHeaderCellDef [class.outer]="expandable()">
      {{ outerTableColumns[outerColumn] }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [class.text-color-red]="getOuterTextColor(outerColumn, element) === 'red'"
      [class.text-color-yellow]="
        getOuterTextColor(outerColumn, element) === 'yellow'
      "
      [class.icon-arrow-right]="
        expandable() && i === 0 && expandedElement !== element
      "
      [class.icon-arrow-down]="
        expandable() && i === 0 && expandedElement === element
      "
    >
      {{ element[outerColumn] }}
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let row"
      [attr.colspan]="outerTableColumnsToDisplay.length"
    >
      <div
        [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
      >
        <table
          mat-table
          [dataSource]="innerTableDataSource"
          style="width: 100%"
        >
          <ng-container
            matColumnDef="{{ innerColumn }}"
            *ngFor="let innerColumn of innerTableColumnsToDisplay"
          >
            <th mat-header-cell *matHeaderCellDef [class.inner]="expandable()">
              {{ innerTableColumns[innerColumn] }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class.text-color-red]="
                getInnerTextColor(innerColumn, element) === 'red'
              "
              [class.text-color-yellow]="
                getInnerTextColor(innerColumn, element) === 'yellow'
              "
            >
              {{ element[innerColumn] }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="innerTableColumnsToDisplay"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: innerTableColumnsToDisplay"
          ></tr>
        </table>
      </div>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="
      outerTableColumnsToDisplay;
      sticky: outerTableHeaderSticky
    "
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: outerTableColumnsToDisplay"
    class="element-row"
    [class.expanded-row]="expandedElement === element"
    (click)="clickOuterTableRow(element)"
  ></tr>
  <ng-container *ngIf="expandable()">
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </ng-container>
</table>
