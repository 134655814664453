import { ForTestDataService } from './../_services/for-test-data.service';
import { catchError, tap, timeout } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/_services/apiSvc.service';
import { DotnetApi } from 'src/app/_services/apiSvc.service';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { forkJoin, interval, throwError } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { CsvHelperService } from 'src/app/_services/csv-helper.service';
import { group } from '@angular/animations';
import { EquipmentStatus, SensorStatus } from '../_variable/main-panel-type';
import * as moment from 'moment';
import * as _ from 'lodash';
import numeral from 'numeral';

// api interface
import { Phase3AlarmLineChartApi, EquipStatusApi, Phase3MoDetailApi, ActivedAlarms, PostAlarmReport, PostAlarmRemove } from '../core/models/newMainBoard.model';

@Component({
  selector: 'app-phase3-main-board',
  templateUrl: './phase3-main-board.component.html',
  styleUrls: ['./phase3-main-board.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class Phase3MainBoardComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public service: DotnetApi.MainPanel.Service,
    private httpClient: HttpClient,
    public testData: ForTestDataService,
    public clock: RealTimeClockService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {
    this.isSvLoading.loading = true;
  }

  isOpenDebugMode = false;
  isMenuOpen = false;
  isLogin = false;

  getInnerWidth = window.innerWidth;
  scaleWindowSize = this.getInnerWidth / 3765;
  getInnerHeight = 2160 * this.scaleWindowSize;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  // Alarm List START**************
  OpenPopupObj: {
    isReport: boolean;
    isReportAgain: boolean;
    isRemove: boolean;
  } = {
      isReport: false,
      isReportAgain: false,
      isRemove: false,
    };

  reportJSON: {
    sourceId: string;
    isReport: boolean;
    isDelete: boolean;
    name: string;
    logTime: string;
    reportTime: string;
    dbLogTime: number;
  } = {
      sourceId: null,
      isReport: null,
      isDelete: null,
      name: null,
      logTime: null,
      reportTime: null,
      dbLogTime: null
    };

  reportDotnetApiJSON: {
    sourceId: string;
    alarmTime: number;
  } = {
      sourceId: null,
      alarmTime: null
    };

  alarmData: {
    sourceId: string;
    name: string;
    logTime: string;
    reportTime: string;
    isReport: boolean;
    isDelete: boolean;
    dbLogTime: number;
    line: string;
    floor: string;
    area: string;
  }[] = [{
    sourceId: null,
    name: null,
    logTime: null,
    reportTime: null,
    isReport: null,
    isDelete: null,
    dbLogTime: null,
    line: null,
    floor: null,
    area: null
  }];
  // Alarm List END**************

  // MO status Overview & Detail START**************
  moStatusDetailObj: Phase3MoDetailApi = {
    mes: {
      closeMo: null,
      toBeProcessed: null,
      detail: {
        closed: {
          onTime: null,
          onTimeRate: null,
          overdue: null,
          overdueRate: null
        },
        inProgress: {
          onTime: null,
          onTimeRate: null,
          overdue: null,
          overdueRate: null
        },
        onTimeRatio: null
      }
    },
    sap: {
      closeMo: null,
      toBeProcessed: null,
      detail: {
        closed: {
          onTime: null,
          onTimeRate: null,
          overdue: null,
          overdueRate: null
        },
        inProgress: {
          onTime: null,
          onTimeRate: null,
          overdue: null,
          overdueRate: null
        },
        onTimeRatio: null
      }
    }
  };
  // MO status Overview & Detail END**************

  // Pie-Chart => Mo Status Detail START***************************************
  public sapPieChartDataArr = [
    {
      pieChartType: 'pie',
      // Pie
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
        },
        // label 文字
        legend: {
          display: false,
        },
        // Pie 顯示單位百分比
        tooltips: {
          enabled: false, // 關閉 tooltips
        }
      },
      // pie color
      donutColors: [
        {
          backgroundColor: ['#11E834', '#FFE500', '#CD911C', '#FF3A3A']
        }
      ],
      pieChartLabels: [
        'Closed',
        'Closed (Overdue)',
        'In Progress',
        'Overdue'
      ],
      //pieChartData: [{ data: [] }]
      pieChartData: [{ data: [66, 88, 168, 666] }]
    }
  ];

  public mesPieChartDataArr = [
    {
      pieChartType: 'pie',
      // Pie
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
        },
        // label 文字
        legend: {
          display: false,
        },
        // Pie 顯示單位百分比
        tooltips: {
          enabled: false, // 關閉 tooltips
        }
      },
      // pie color
      donutColors: [
        {
          backgroundColor: ['#11E834', '#FFE500', '#CD911C', '#FF3A3A']
        }
      ],
      pieChartLabels: [
        'Closed',
        'Closed (Overdue)',
        'In Progress',
        'Overdue'
      ],
      //pieChartData: [{ data: [] }]
      pieChartData: [{ data: [6, 8, 50, 36] }]
    }
  ];
  // Pie-Chart => Mo Status Detail END***************************************

  // Alarm-Chart => Alarm Record START***************************************
  public newAlarmChartDataArr: {
    chartTitle: string;
    barChartType: string;
    barChartLegend: boolean;
    barChartData: {
      data: {
        x: string;
        y: number;
      }[];
      fill: boolean;
      borderColor: string;
      borderWidth: number;
      yAxisID: string;
    }[];
    barChartOptions: {
      responsive: boolean;
      elements: {
        line: {
          tension: number;
        };
      };
      scales: {
        xAxes: {
          display: boolean;
          type: string;
          time: {
            unit: boolean;
            stepSize: number;
            displayFormats: {
              hour: string;
            };
            tooltipFormat: string;
          };
          scaleLabel: {
            display: boolean;
          };
          ticks: {
            stepSize: number;
            fontColor: string;
            padding: number;
            fontSize: number;
            maxRotation: number;
          };
          gridLines: {
            drawTicks: boolean;
            color: string;
            lineWidth: number;
          };
        }[];
        yAxes: {
          position: string;
          id: string;
          display: boolean;
          labelString: string;
          ticks: {
            min: number;
            fontColor: string;
            stepSize: number;
            padding: number;
            fontSize: number;
          };
          gridLines: {
            drawTicks: boolean;
            color: string;
            lineWidth: number;
          };
          scaleLabel: {
            display: boolean;
            labelString: string;
            fontColor: string;
            fontSize: number;
          };
        }[];
        pan: {
          enabled: boolean;
          mode: string;
        };
      };
      tooltips: {
        titleFontSize: number;
        bodyFontSize: number;
        borderColor: string;
      };
      plugins: {
        p1: boolean;
      };
      legend: {
        display: boolean;
      }
    };
    chartColors: {
      backgroundColor: string;
    }[];
    chartClicked;
    chartHovered;
  }[] = [];
  // Alarm-Chart => Alarm Record START***************************************

  // Devices Status START****************************************************
  SMT = [];
  LOD = [];
  area = [];
  // Devices Status END******************************************************

  // Floor Selection START**************
  floorAlarmGroups = {
    'Factory-A': [],
    'Building-A': [],
    'Building-B': [],
  };

  isKanbanSwitchClick = false;
  isBuildingSwitchClick = false;
  floorLevel: string = null;
  alarmArea: string = null;
  isAlarmShining = false;
  // Floor Selection END****************

  // Upload File START************************************************
  latestFileUploadTimeArray = [];
  isUploadGroupExpanded = false;

  getlatestFileUploadTime(fileName) {
    return this.latestFileUploadTimeArray.find(item => item.name === fileName)?.uploadTime;
  }

  setlatestFileUploadTime(fileName) {
    this.latestFileUploadTimeArray = this.latestFileUploadTimeArray.map(item => {
      if (item.name === fileName) {
        item.uploadTime = moment().valueOf();
      }
      return item;
    });
    console.log('this.latestFileUploadTimeArray:', this.latestFileUploadTimeArray);
  }
  // Upload File END**************************************************

  setFloorLevel(floor: string) {
    this.floorLevel = floor;
    if (!this.floorLevel) { return; }
    this.isSvLoading.loading = true;
    // console.log('this.floorLevel:', this.floorLevel);
    this.SMT = [];
    this.LOD = [];
    this.area = [];
    this.alarmArea = null;
    this.apiSvc.getPhase3MainBoardMapLineData(this.floorLevel).pipe(
      timeout(this.intervalSeconds * 0.9 * 1000),
      tap(res => this.getMainPanelEquipmentStatus(res)),
      catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
    ).subscribe({
      error: error => {
        console.log('catchError: 錯誤 - ', error);
      },
      complete: () => {
        this.isSvLoading.loading = false;
      }
    });
  }

  switchAlarmLocation(floor: string, location: string) {
    if (this.floorLevel !== floor) {
      this.setFloorLevel(floor);
      this.alarmArea = location;
    }
    else {
      this.alarmArea = location;
    }
    this.isAlarmShining = true;
    const cleanArea = setTimeout(() => {
      this.alarmArea = null;
      this.isAlarmShining = false;
    }, 3000);
  }

  checkFloorAlarm(floor: string, building: string) {
    const floorAlarmItem = this.floorAlarmGroups[building]?.find(
      (item) => {
        return item?.floor === floor;
      });
    return floorAlarmItem?.hasAlarm;
  }

  checkBuildingAlarm(building: string) {
    const floorAlarmItem = this.floorAlarmGroups[building]?.find(
      (item) => {
        return item?.hasAlarm === true;
      });
    return floorAlarmItem?.hasAlarm;
  }

  getFloorAlarmCount(floor: string, building: string) {
    const floorAlarmItem = this.floorAlarmGroups[building]?.find(
      (item) => {
        return item?.floor === floor;
      });
    return floorAlarmItem?.alarmTime;
  }

  createFloorSelectionHoverListener() {
    [].forEach.call(document.querySelectorAll('.mat-chip.selected_hover'), (button) => {
      button.addEventListener('mouseenter', (event) => {
        const className = event.target?.classList[4];
        // console.log('className:', className);
        const div = document.querySelectorAll<HTMLElement>('div.' + className)[0];
        const device = div.querySelectorAll<HTMLElement>('.devices_box');
        const area = div.querySelectorAll<HTMLElement>('.area_box');
        // console.log('div:', div);
        // console.log('device:', device);
        if (className === 'floor_1f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_1F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
        if (className === 'floor_2f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_2F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
        if (className === 'floor_3f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_3F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
        if (className === 'floor_4f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_4F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
        if (className === 'floor_5f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_5F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
        if (className === 'floor_6f') {
          div.style.backgroundImage = 'url(/assets/img/phase3MainBoard/Abuilding_6F.png)';
          div.style.backgroundPosition = 'center';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '0'; });
            area?.forEach(element => { element.style.opacity = '0'; });
          }
        }
      });
      button.addEventListener('mouseout', (event) => {
        const className = event.target?.classList[4];
        // console.log('className:', className);
        // switch building would cause the value to be undefined
        if (className) {
          const div = document.querySelectorAll<HTMLElement>('div.' + className)[0];
          const device = div.querySelectorAll<HTMLElement>('.devices_box');
          const area = div.querySelectorAll<HTMLElement>('.area_box');
          // console.log('div:', div);
          // console.log('device:', device);
          div.style.background = '';
          if (device || area) {
            device?.forEach(element => { element.style.opacity = '1'; });
            area?.forEach(element => { element.style.opacity = '1'; });
          }
        }
      });
    });
  }

  downloadFile() {
    return window.open(`${this.apiSvc.APIUrl}/download/moStatusDetail`);
    // return window.open('http://localhost:5555/smartFactory/apis/download/moStatusDetail');
  }

  // Open Debug Mode, press " ctl + alt + shilt + m "
  @HostListener('window:keydown', ['$event']) keydownEvent(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.altKey && event.shiftKey && event.keyCode === 77) {
      console.log('OPEN DEBUG MODE');
      this.isOpenDebugMode = !this.isOpenDebugMode;
    }
  }

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.getAllApiFn();
    });
  }

  @HostListener('window:resize', ['$event'])

  onWindowResize(event) {
    this.getInnerWidth = event.target.innerWidth;
    this.scaleWindowSize = this.getInnerWidth / 3765;
    this.getInnerHeight = 2160 * this.scaleWindowSize;
  }

  ngOnInit(): void {
    this.rxTimerStart();
  }

  ngAfterViewInit() {
    this.getAllApiFn();
    this.createFloorSelectionHoverListener();
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  getEquipmentStatusLight(status: EquipmentStatus) {
    switch (status) {
      case EquipmentStatus.RUNNING:
        return '#0E8E58';
      case EquipmentStatus.IDLE:
        return '#E3B953';
      case EquipmentStatus.ALARM:
        return '#D2574A';
      default:
        return '#505050';
    }
  }

  getSensorStatusLight(status: SensorStatus) {
    switch (status) {
      case SensorStatus.NORMAL:
        return 'img_light_green';
      case SensorStatus.ALARM:
        return 'img_light_red';
      default:
        return undefined;
    }
  }

  // Alarm => 通報或刪除
  apiReportIssue(status, item: any) {
    console.log('apiReportIssue status:', status);
    console.log('apiReportIssue item:', item);

    // reset popupShowObj
    this.OpenPopupObj = {
      isReport: false,
      isReportAgain: false,
      isRemove: false,
    };

    // resete obj
    this.reportJSON = {
      sourceId: null,
      isReport: null,
      isDelete: null,
      name: null,
      logTime: null,
      reportTime: null,
      dbLogTime: null
    };

    this.reportJSON = {
      sourceId: item.sourceId,
      isReport: item.isReport,
      isDelete: item.isDelete,
      name: item.name,
      logTime: item.logTime,
      reportTime: item.reportTime,
      dbLogTime: item.dbLogTime
    };

    if (status === 'isreport') {
      // console.log('isreport ================');
      this.reportJSON.isReport = !item.isReport;
    }
    if (status === 'reportAgain') {
      // console.log('reportAgain ================');
      this.reportJSON.isReport = item.isReport;
    }
    if (status === 'remove') {
      // console.log('remove ================');
      this.reportJSON.isDelete = !item.isDelete;
    }

  }

  // build line chart Array
  buildLinCharArr(resDataArr) {
    // console.log('resDataArr:', resDataArr);
    this.newAlarmChartDataArr = [];
    for (const element of resDataArr) {
      this.newAlarmChartDataArr.push(
        {
          chartTitle: element.chartTitle,
          barChartType: 'line',
          barChartLegend: true,
          barChartData: [
            {
              // type: 'line',
              data: element.barChartData,
              //data: [{x:"1",y:8},{x:"2",y:6},{x:"3",y:9},{x:"4",y:5},{x:"5",y:6},{x:"6",y:6},{x:"7",y:8},{x:"8",y:15},{x:"9",y:2},{x:"10",y:1}],
              // label: 'Accepted',
              fill: false,
              borderColor: '#fff',
              borderWidth: 2,
              yAxisID: 'y-axis-1',
            }
          ],
          barChartOptions: {
            responsive: true,
            elements: {
              line: {
                tension: 0
              }
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  type: 'time',
                  time: {
                    // isoWeekday: true,
                    // unit: 'hour',
                    unit: false,
                    stepSize: 1,
                    displayFormats: {
                      hour: 'HH',
                      // week: 'D MMM'
                    },
                    tooltipFormat: 'HH:mm'
                  },
                  scaleLabel: {
                    display: true,
                    // labelString: 'Date'
                  },
                  ticks: {
                    // min: 0,
                    // max: 24,
                    // fontSize: 10,
                    stepSize: 1,
                    fontColor: '#BABABA',
                    padding: 15,
                    fontSize: 24,
                    maxRotation: 0
                  },
                  gridLines: {
                    drawTicks: false,
                    color: '#6A6A6A',
                    lineWidth: 1
                  }
                }
              ],
              yAxes: [
                {
                  position: 'left',
                  id: 'y-axis-1',
                  display: true,
                  labelString: 'value',
                  ticks: {
                    min: 0,
                    // max: 8,
                    fontColor: '#BABABA',
                    stepSize: 2,
                    padding: 15,
                    fontSize: 24,
                  },
                  gridLines: {
                    drawTicks: false,
                    color: '#6A6A6A',
                    lineWidth: 1
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Alarm Times',
                    fontColor: '#BABABA',
                    fontSize: 24,
                  }
                }
              ],
              pan: {
                enabled: true,
                mode: 'xy'
              },
            },
            tooltips: {
              titleFontSize: 30,
              bodyFontSize: 34,
              borderColor: 'white'
            },
            plugins: {
              p1: false   // not show unit name "kWh"
            },
            legend: {
              display: false // hide chart label name
            },
          },
          chartColors: [
            // all colors in order
            { backgroundColor: '#fff' },
            { backgroundColor: '#fff' }
          ],

          // Chart events
          chartClicked({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          },

          chartHovered({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          }
        }
      );

    }
    // console.log('this.newAlarmChartDataArr:', this.newAlarmChartDataArr);
  }

  subscribeAlarmRecords(startTime: number, endTime: number) {
    return new Promise<Phase3AlarmLineChartApi>((resolve) => {
      this.apiSvc.getPhase3MainBoardAlarmLineChartData(startTime, endTime).subscribe(data => {
        resolve(data);
      });
    });
  }

  subscribeEquipmentStatus(floor: string) {
    return new Promise<EquipStatusApi>((resolve) => {
      this.apiSvc.getPhase3MainBoardMapLineData(floor).subscribe(data => {
        resolve(data);
      });
    });
  }

  subscribeMoDetail(startTime: number) {
    return new Promise<Phase3MoDetailApi>((resolve) => {
      this.apiSvc.getPhase3MoDetailData(startTime).subscribe(data => {
        resolve(data);
      });
    });
  }

  subscribeActiveAlarms() {
    return new Promise<ActivedAlarms[]>((resolve) => {
      this.apiSvc.getMainBoardActivedAlarms().subscribe(data => {
        resolve(data);
      });
    });
  }

  // API report alarm issue 已通報-回傳
  reportAnnounceData(reportJSON: PostAlarmReport) {
    this.isSvLoading.loading = true;
    this.apiSvc.postMainBoardAlarmReport(reportJSON).subscribe((data) => {
      console.log('POST_ALARM_REPORT: ', data);
      if (data) {
        // reset popupShowObj
        this.OpenPopupObj = {
          isReport: false,
          isReportAgain: false,
          isRemove: false,
        };

        // call api to update the list
        this.apiSvc.getMainBoardActivedAlarms().pipe(
          timeout(this.intervalSeconds * 0.9 * 1000),
          tap(res => this.getMainPanelActivedAlarms(res))
        ).subscribe({
          error: error => {
            console.log('catchError: 錯誤 - ', error);
          },
          complete: () => {
            this.isSvLoading.loading = false;
          }
        });

        alert('通報成功');

      } else {
        alert('通報失敗 => API回傳成功，但通報失敗');
      }
    });
  }

  // API remove alarm issue 已通報-回傳
  removeAnnounceData(reportJSON: PostAlarmRemove) {
    this.isSvLoading.loading = true;
    this.apiSvc.postMainBoardAlarmRemove(reportJSON).subscribe((data) => {
      console.log('POST_ALARM_REMOVE: ', data);
      if (data) {
        // reset popupShowObj
        this.OpenPopupObj = {
          isReport: false,
          isReportAgain: false,
          isRemove: false,
        };

        // call api to update the list
        this.apiSvc.getMainBoardActivedAlarms().pipe(
          timeout(this.intervalSeconds * 0.9 * 1000),
          tap(res => this.getMainPanelActivedAlarms(res))
        ).subscribe({
          error: error => {
            console.log('catchError: 錯誤 - ', error);
          },
          complete: () => {
            this.isSvLoading.loading = false;
          }
        });

        alert('通報成功');

      } else {
        alert('通報失敗 => API回傳成功，但通報失敗');
      }
    });
  }

  // reportIssue
  alarmReport(sourceId: string, logtime: number) {
    this.reportDotnetApiJSON.sourceId = sourceId;
    this.reportDotnetApiJSON.alarmTime = logtime;
    this.reportAnnounceData(this.reportDotnetApiJSON);
    console.log('API Report: ', this.reportDotnetApiJSON);
  }

  // removeIssue
  alarmRemove(sourceId: string, logtime: number) {
    this.reportDotnetApiJSON.sourceId = sourceId;
    this.reportDotnetApiJSON.alarmTime = logtime;
    this.removeAnnounceData(this.reportDotnetApiJSON);
    console.log('API Remove: ', this.reportDotnetApiJSON);
  }

  getMainPanelAlarmRecords(alarmRecord: Phase3AlarmLineChartApi) {
    console.log('getAlarmLineChart res:', alarmRecord);

    const equipmentObj: { chartTitle: string; barChartData: { x: string; y: number; }[]; } = { chartTitle: null, barChartData: [] };
    const resArray: { chartTitle: string; barChartData: { x: string; y: number; }[]; }[] = [];

    equipmentObj.chartTitle = 'Equipment';
    alarmRecord.equipmentAlarms.map((o) => { equipmentObj.barChartData.push({ x: o.label, y: o.value }); });
    resArray.push(equipmentObj);

    this.buildLinCharArr(resArray);
    console.log('lineChartArray: ', resArray);
  }


  getMainPanelActivedAlarms(activedAlarms: DotnetApi.MainPanel.ActivedAlarms[]) {
    console.log('ActiveAlarms: ', activedAlarms);
    this.alarmData.length = 0;
    for (const alarmRecord of activedAlarms) {
      let lastReportTime: string = null;
      if (alarmRecord.lastReportTime !== null) {
        lastReportTime = this.datePipe.transform(alarmRecord.lastReportTime, 'yyyy/M/dd HH:mm');
      }
      const obj = {
        sourceId: alarmRecord.sourceId,
        name: alarmRecord.line + ' - ' + alarmRecord.deviceType + ' - ' + alarmRecord.deviceName,
        logTime: this.datePipe.transform(alarmRecord.alarmTime, 'yyyy/MM/dd HH:mm'),
        reportTime: lastReportTime,
        isReport: alarmRecord.isReported,
        isDelete: false,
        dbLogTime: alarmRecord.alarmTime,
        line: alarmRecord.line,
        floor: alarmRecord.floor,
        area: alarmRecord.area
      };
      this.alarmData.push(obj);
    }
  }

  getPhase3FloorStatus3E(buildingObj) {
    console.log('FloorStatus3E API:', buildingObj);
    const result = buildingObj.buildingInfo.map((i) => {
      const title = (() => {
        if (i.building === 'A') { return 'Building-A'; }
        if (i.building === 'B') { return 'Building-B'; }
        return null;
      })();
      const alarm = i.floor.map((f) => {
        const { floor, alarm } = f;
        const alarmTime = (() => {
          if (isFinite(alarm.count)) { return alarm.count; }
          return null;
        })();
        return { floor, alarmTime, hasAlarm: alarmTime ? true : false };
      });
      return { [title]: alarm };
    }).reduce((pre, cur) => {
      return Object.assign(pre, cur);
    }, {});
    console.log('FloorStatus3E result obj:', result);
    this.floorAlarmGroups['Building-A'] = Object.assign(result['Building-A']);
    this.floorAlarmGroups['Building-B'] = Object.assign(result['Building-B']);
  }

  getPhase3FloorStatus1E(factoryObj) {
    console.log('FloorStatus1E:', factoryObj);
    this.floorAlarmGroups['Factory-A'] = Object.assign(factoryObj['Factory-A']);
  }

  getMainPanelEquipmentStatus(status: EquipStatusApi) {
    console.log('EquipmentStatus: ', status);
    this.SMT = status?.SMT;
    this.LOD = status?.LOD;
    this.area = status?.area;
  }

  getMainPanelMoDetail(moDetail: Phase3MoDetailApi) {
    console.log('getMoDetailData:', moDetail);
    this.moStatusDetailObj = moDetail;

    // get the total count to caculate the %
    let sapTotal: number;
    sapTotal = moDetail.sap.detail.closed.onTime + moDetail.sap.detail.closed.overdue + moDetail.sap.detail.inProgress.onTime + moDetail.sap.detail.inProgress.overdue;

    let mesTotal: number;
    mesTotal = moDetail.mes.detail.closed.onTime + moDetail.mes.detail.closed.overdue + moDetail.mes.detail.inProgress.onTime + moDetail.mes.detail.inProgress.overdue;

    // clear pieChartData
    this.sapPieChartDataArr[0].pieChartData[0].data = [];
    this.mesPieChartDataArr[0].pieChartData[0].data = [];

    // caculate the sap/mes closed onTimeRate and put into pieChart
    this.moStatusDetailObj.sap.detail.closed.onTimeRate = numeral(moDetail.sap.detail.closed.onTime).divide(sapTotal).multiply(100).value();
    this.sapPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.sap.detail.closed.onTime);

    this.moStatusDetailObj.mes.detail.closed.onTimeRate = numeral(moDetail.mes.detail.closed.onTime).divide(mesTotal).multiply(100).value();
    this.mesPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.mes.detail.closed.onTime);

    // caculate the sap/mes closed overdueRate and put into pieChart
    this.moStatusDetailObj.sap.detail.closed.overdueRate = numeral(moDetail.sap.detail.closed.overdue).divide(sapTotal).multiply(100).value();
    this.sapPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.sap.detail.closed.overdue);

    this.moStatusDetailObj.mes.detail.closed.overdueRate = numeral(moDetail.mes.detail.closed.overdue).divide(mesTotal).multiply(100).value();
    this.mesPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.mes.detail.closed.overdue);

    // caculate the sap/mes inProgress onTimeRate and put into pieChart
    this.moStatusDetailObj.sap.detail.inProgress.onTimeRate = numeral(moDetail.sap.detail.inProgress.onTime).divide(sapTotal).multiply(100).value();
    this.sapPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.sap.detail.inProgress.onTime);

    this.moStatusDetailObj.mes.detail.inProgress.onTimeRate = numeral(moDetail.mes.detail.inProgress.onTime).divide(mesTotal).multiply(100).value();
    this.mesPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.mes.detail.inProgress.onTime);

    // caculate the sap/mes inProgress overdueRate and put into pieChart
    this.moStatusDetailObj.sap.detail.inProgress.overdueRate = numeral(moDetail.sap.detail.inProgress.overdue).divide(sapTotal).multiply(100).value();
    this.sapPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.sap.detail.inProgress.overdue);

    this.moStatusDetailObj.mes.detail.inProgress.overdueRate = numeral(moDetail.mes.detail.inProgress.overdue).divide(mesTotal).multiply(100).value();
    this.mesPieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.mes.detail.inProgress.overdue);

    // multiply the sap/mes onTimeRatio * 100 to be %
    this.moStatusDetailObj.sap.detail.onTimeRatio = numeral(moDetail.sap.detail.onTimeRatio).multiply(100).value();

    this.moStatusDetailObj.mes.detail.onTimeRatio = numeral(moDetail.mes.detail.onTimeRatio).multiply(100).value();
  }

  getAllApiFn() {
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    const now = moment().valueOf();
    const before24hr = moment().subtract(1, 'days').startOf('hour').valueOf();
    console.log('now, before24hr', [now, before24hr]);
    this.renewedTime = now;
    forkJoin([
      this.apiSvc.getPhase3MainBoardAlarmLineChartData(before24hr, now).pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getMainPanelAlarmRecords(res)),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
      this.apiSvc.getPhase3MoDetailData(now.valueOf()).pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getMainPanelMoDetail(res)),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
      this.apiSvc.getMainBoardActivedAlarms().pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getMainPanelActivedAlarms(res)),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
      this.apiSvc.getPhase3MainBoard3EFloorAlarms().pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getPhase3FloorStatus3E(res)),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
      this.apiSvc.getPhase3MainBoard1EFloorAlarms().pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getPhase3FloorStatus1E(res)),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
      this.apiSvc.getPhase3LatestFileUploadTime().pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.latestFileUploadTimeArray = res),
        catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      ),
    ]).subscribe({
      next: (res) => {
        // console.log('forkJoin res:', res);
        this.snapshotTime = this.renewedTime;
      },
      error: error => {
        console.log('catchError: 錯誤 - ', error);
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.getAllApiFn();
  }

  // Upload pop-up event
  onClickUpload(filter: string) {
    console.log('onClickDownload: ', filter);
    const dialogRef = this.dialog.open(Phase3MainBoardUploadDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '980px',
      height: '280px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        option: filter
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('afterClosed result:', result);
        this.setlatestFileUploadTime(result);
      }
    });
  }

}

type SmtStdTime = {
  partNo: string;
  name: string;
  side: string;
  line: string;
  cycleTimeMin: number;
  changeOverMin: number;
}[];

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './dialog/app-upload-dialog.component.html',
  styleUrls: ['./dialog/app-upload-dialog.component.scss']
})
export class Phase3MainBoardUploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<Phase3MainBoardUploadDialogComponent>,
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public csv: CsvHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { option: string }) { }

  intervalSeconds = 60;
  hasUploadError = false;
  hasUploadSuccess = false;
  fileRowNumber = 0;
  selectedFile = null;

  display: FormControl = new FormControl('', Validators.required);
  fileStore: FileList;
  fileList: Array<string> = [];

  container = document.querySelector<HTMLElement>('.custom-dialog-container .mat-dialog-container');

  ngOnInit() { }

  // upload file api
  uploadFileApi(category: string, jsonObj: object) {
    this.isSvLoading.loading = true;
    this.apiSvc.postPhase3MainBoardUplaodFile(category, jsonObj)
      .pipe(
        timeout(this.intervalSeconds * 0.9 * 1000),
        tap(res => this.getUploadAPiResponse(res, category)),
        // catchError(async (error) => console.log('API RESPONSE ERROR: ', error))
      )
      .subscribe({
        error: error => {
          console.log('catchError: 錯誤 - ', error);
          this.isSvLoading.loading = false;
          this.hasUploadError = true;
          this.container.style.border = '4px solid #FF3A3A';
        },
        complete: () => {
          this.isSvLoading.loading = false;
        }
      });
  }

  getUploadAPiResponse(res, category) {
    console.log('uploadFileApi res: ', res);
    if (res) {
      // alert('上傳成功');
      this.selectedFile = category;
      this.hasUploadSuccess = true;
      this.container.style.border = '4px solid #0E8E58';
    } else {
      // alert('上傳失敗 => API回傳成功，但上傳失敗');
      this.hasUploadError = true;
      this.container.style.border = '4px solid #FF3A3A';
    }
  }

  handleFileInputChange(l: FileList): void {
    this.fileStore = l;
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.display.patchValue(`${f.name}${count}`);
    } else {
      this.display.patchValue('');
    }
  }

  handleSubmit(): void {
    const fd = new FormData();
    this.fileList = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.fileStore.length; i++) {
      fd.append('files', this.fileStore[i], this.fileStore[i].name);
      this.fileList.push(this.fileStore[i].name);
    }

    // do submit ajax
    console.log('FormData:', fd.get('files'));
    const file = this.fileStore[0];

    switch (this.data.option) {
      case 'SMT標工':
        this.csv.getSmtStdTime(file).then((data: SmtStdTime) => {
          if (data?.length >= 1) {
            data = data.map(item => {
              const { partNo, name, side, line, cycleTimeMin, changeOverMin } = item;
              return { partNo, name, side, line, cycleTimeMin, changeOverMin };
            });
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('smtStdTime', { data: encodedString });
          }
          else {
            console.log('getSmtStdTime data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case '各製程段標準換線工時':
        this.csv.getStdChageTime(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('stdChangeTime', { data: encodedString });
          }
          else {
            console.log('getStdChageTime data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case 'MO標準工期':
        this.csv.getMoStdDuration(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('moStdDuration', { data: encodedString });
          }
          else {
            console.log('getMoStdDuration data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case 'MO逾期天數扣除假日':
        this.csv.getNonWorkingDate(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('nonWorkingDate', { data: encodedString });
          }
          else {
            console.log('getNonWorkingDate data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case 'SDC站點更新上傳':
        this.csv.getSdcStationData(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('sdcStationData', { data: encodedString });
          }
          else {
            console.log('getSdcStationData data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case '設備資料訊息':
        this.csv.getDeviceId(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('deviceId', { data: encodedString });
          }
          else {
            console.log('getDeviceId data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      case '樓層區域名稱':
        this.csv.getDisplayNameData(file).then(data => {
          if (data?.length >= 1) {
            const encodedString = JSON.stringify(data);
            console.log('encodedString:', encodedString);
            this.fileRowNumber = data.length + 1;
            this.uploadFileApi('displayName', { data: encodedString });
          }
          else {
            console.log('getDisplayNameData data error!!!');
            this.hasUploadError = true;
            this.container.style.border = '4px solid #FF3A3A';
          }
        });
        break;

      default:
        break;
    }
  }
}
