import { interval,Observable,Subscription } from 'rxjs';
import { map, tap, } from 'rxjs/operators';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {OnInit, AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { DataFormatService } from '../_services/data-format.service';
import {MatPaginator} from '@angular/material/paginator';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ApiService } from '../_services/apiSvc.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { environment } from '../../environments/environment';

import { Chart } from '../../../node_modules/chart.js';
import * as JSC from 'jscharting';
JSC.defaults({ baseUrl: 'assets/js/dist' });

/**
 * @title Table with sorting
 */
@Component({
  selector: 'app-phase3-dro',
  templateUrl: './phase3-dro.component.html',
  styleUrls: ['./phase3-dro.component.scss'],
})

export class Phase3DroComponent implements OnInit, AfterViewInit {
  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;
  APIUrl: string;
  myChart = [];
  isNewestDate = true;

  isMenuOpen = false;
  isLogin = false;

  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public dataFormatSvc: DataFormatService,
  ) { this.isSvLoading.loading = true; }
  ngAfterViewInit() {
    this.APIUrl = environment.apiUrl;
  }


  apidata$: Observable<string>;
  RCV=['RCanvasA','RCanvasB','RCanvasC','RCanvasD','RCanvasE','RCanvasF'];
  currentHour = moment().get('hour');
  DefT=moment().startOf('day').set('hour', this.currentHour).add(2, 'hours').subtract(1, 'day');
  displayDatePicker=_.cloneDeep(this.DefT).add(1,'days').valueOf();
  ngOnInit() {
    //this.initChart('RCanvas',this.d);
    this.rxTimerStart();
    this.apidata$ = this.apiSvc.getDroEQ();
    this.getDroHAr(moment(this.DefT).valueOf());
  }
  L() {
    this.isNewestDate = false;
    this.DefT=moment(this.DefT).subtract(1,'days');
    this.isSvLoading.loading = true;
    this.getDroHAr(moment(this.DefT).valueOf());
    this.displayDatePicker=_.cloneDeep(this.DefT).add(1,'days').valueOf();
  }
  R() {
    this.DefT=moment(this.DefT).add(1,'days');
    this.isSvLoading.loading = true;
    this.getDroHAr(moment(this.DefT).valueOf());
    this.displayDatePicker=_.cloneDeep(this.DefT).add(1,'days').valueOf();
    if(moment().diff(this.DefT, 'days', true) < 1) { this.isNewestDate = true; }
  }
  Down(){
    window.open(this.APIUrl+'/download/dropRateHistory','_break');
  }
  getDroHAr(date) {
    return this.apiSvc.getDroH(date).subscribe( d=> {
      //console.log(d);
      let c=['#11E834','#FFE500','#00FFE0'];
      let ar=[];
      if(this.myChart.length > 0) { this.myChart.forEach(e => e.destroy()); }
      d.forEach((e,j) =>{
        let arr=[];
        e.equip.forEach((es,i) => {
          let datasets = new Object();
          datasets['fill']=false;
          datasets['label']=es.equipCode;
          datasets['borderColor']=c[i];
          datasets['backgroundColor']=c[i];
          datasets['tension']=0.1;
          let arrs=[];
          es.data.forEach((esd,f) => {
            if(esd.value){esd.value=esd.value.toFixed(3);}
            arrs[f]={x: esd.time,y:esd.value};
          });
          datasets['data']=arrs;
          arr[i]=datasets;
          //console.log(e.lineName,es.equipCode,datasets);
        });

        let o = new Object();
        let os = new Object();
        o['datasets']=arr;
        os['lineName']=e.lineName;
        os['data']=o;
        this.initChart(this.RCV[j],o);
        ar[j]=os;
      });
      this.isSvLoading.loading = false;
      return ar;
    })
  }
  initChart(id,d) {
    //const labels = Utils.months({count: 7});
    this.myChart.push(new Chart(id, {
          //debug: false,
          //legend_visible: false,
          //defaultTooltip_enabled: false,
          type: "line",
          data:d,
          options: {
            tooltips: {
              mode: 'label',
              titleAlign: 'center',
              intersect: false,
              titleFontSize: 16,
              bodyFontSize: 16,
              position: 'nearest'
            },
            legend: {
              display: false
            },
            //animation: false,
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                  right: 80,
              }
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  type: 'time',
                  time: {
                      unit: false,
                      stepSize: 1,
                      displayFormats: {
                        hour: 'HH',
                      },
                      tooltipFormat: 'YYYY/MM/DD HH:mm'
                  },
                  ticks: {
                    min: 0,
                    // max: 24,
                    fontSize: 16,
                    stepSize: 1,
                    fontColor: '#BABABA',
                    padding: 2,
                    callback(label, index, labels) {
                      return label.replace("AM","").replace("PM","");
                    }
                  },
                  // 設定 底線
                  gridLines: {
                    display: false,
                    color: '#868686',
                    drawBorder: false,
                  },
                }
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                    max: 1050,
                    stepSize: 350,
                    fontSize: 16,
                    fontColor: '#BABABA',
                    padding: 15,
                  },
                  // 設定 底線
                  gridLines: {
                    display: true,
                    color: '#868686',
                    drawBorder: false,
                    drawTicks: true,
                    lineWidth: 2,
                  },
                }
              ]
            },
    
            plugins: {
              p1: false,   // disable plugin 'p1' for this instance
            },
          },
          plugins: [{
            beforeDraw : function(chart) {
              const ctx = chart.chart.ctx;
              ctx.restore();
              ctx.font = 14 + 'px Roboto';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#BABABA';
              ctx.fillText('(o’clock)', chart.chartArea.right + 11, chart.chartArea.bottom + 22);
              ctx.save();
              ctx.restore();
              ctx.font = 16 + 'px Roboto';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#BABABA';
              ctx.fillText('(ppm)', 10, chart.chartArea.top + 18);
              ctx.save();
            }
          }],
        }));

/*
    var simplePlugin = {
      beforeDraw(chart) {
        const ctx = chart.chart.ctx;
        ctx.restore();
        ctx.font = 20 + 'px sans-serif';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#000000';
        ctx.fillText('(mins)', 20, 20);
        ctx.save();
      }
    };

    // Let Chart.js know about the new plugin
    Chart.pluginService.register(simplePlugin);*/
  }

  ppm(num){
    return _.isFinite(num) ? parseFloat(num).toFixed(3) : null;
  }
  isStatus(s) {
    if(s=='null'){return ''}
    if(s==1){return 'pcg'}
    if(s==2){return 'pcy'}
    if(s==3){return 'pcr'}
  }


  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }
  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.apidata$ = this.apiSvc.getDroEQ();
      this.getDroHAr(moment(this.DefT).valueOf());
    });
  }

}
