<h1 mat-dialog-title class="title">{{ data.option }}</h1>
<button mat-icon-button mat-dialog-close class="close_btn">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="content"
  >Export the report to your device?</mat-dialog-content
>
<mat-dialog-actions class="footer">
  <button
    mat-button
    [mat-dialog-close]="true"
    class="save_btn"
    (click)="onClickDownload(data.option)"
  >
    Save as
  </button>
  <button mat-button mat-dialog-close class="cancel_btn">Cancel</button>
</mat-dialog-actions>
