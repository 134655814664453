<div class="container-fluid close_order_container">
  <div class="container-fluid title_group">
    <h1 class="title_name">
      <button class="logo-btn" title="logo">
        <img
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>
      MES Closed Orders Overview
    </h1>

    <!-- <p>{{ DATA | json }}</p> -->
    <div style="width: 650px; display: flex; justify-content: space-between">
      <!-- download group -->
      <div class="download_group">
        <button class="icon_group" (click)="onClickDownload('MO')">
          <img
            class="download_button"
            src="../../../assets/img/btn_download.svg"
            alt="download_button"
          />
          <div class="text">MO</div>
        </button>
        <button class="icon_group" (click)="onClickDownload('SEC')">
          <img
            class="download_button"
            src="../../../assets/img/btn_download.svg"
            alt="download_button"
          />
          <div class="text">SEC</div>
        </button>
      </div>

      <div class="time">
        <!-- <p>10:23 <span>AM</span></p> -->
        <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
        <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
          <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
        </button>
        <div class="login-menu" *ngIf="isMenuOpen">
          <button routerLink="/menu">
            <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
            Dashboard Menu
          </button>
          <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
            <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
          </button>
          <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
            <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign
            out
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div class="container-fluid container_box">
      <div class="table_group">
        <!-- search group-->
        <div class="search_group">
          <div class="search">
            <img
              class="search_icon"
              src="../../assets/img/smart_factory/bi_search.svg"
              alt="search-icon"
            />
            <input
              matInput
              placeholder="Search"
              #input
              (keyup.enter)="onEnterSearch(input.value)"
              [disabled]="isSvLoading.loading"
            />
          </div>

          <!-- 下拉選單 Status -->
          <!-- <div class="select_tag">
            <label>Status</label>
            <select (change)="getModelName($event.target.value)">
              <option selected="selected">All</option>
              <option *ngFor="let item of allStatusName">{{ item }}</option>
              <option>Loading</option>
              <option>ATE</option>
              <option>ASSY</option>
              <option>PKG</option>
              <option *ngFor="let item of rankingModelNameArray" [value]="item">
              {{ item }}
            </option>
            </select>
          </div> -->

          <div style="display: flex">
            <!-- 子層全部 展開/關閉 -->
            <div class="switch_expand">
              <label for="expand" class="checkbox_gourp">
                Expand:
                <input
                  type="checkbox"
                  name=""
                  id="expand"
                  class="checkbox_input"
                  [checked]="isExpandChildStatus"
                  [(ngModel)]="isExpandChildStatus"
                  (change)="switchAllChildExpanded()"
                />
                <span class="checkbox_span"></span>
              </label>
            </div>

            <!-- 日期篩選器 -->
            <div class="date_range_picker">
              <h6 class="date_title">Date range:</h6>

              <div class="date_group">
                <p class="date_value">
                  {{
                    range.value.start === null
                      ? "- - -"
                      : (range.value.start | date: "YYYY/MM/dd")
                  }}
                </p>
                <span class="date_span"> ~ </span>
                <p class="date_value">
                  {{
                    range.value.end === null
                      ? "- - -"
                      : (range.value.end | date: "YYYY/MM/dd")
                  }}
                </p>
              </div>

              <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>

                <mat-date-range-input
                  [formGroup]="range"
                  [min]="minDate"
                  [max]="maxDate"
                  [dateFilter]="familyDayFilter"
                  [rangePicker]="picker"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                    (dateChange)="
                      getRangeDate(range.value.start, range.value.end)
                    "
                  />
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker">
                  <!-- <mat-icon matDatepickerToggleIcon>
                  <img src="../../../assets/img/btn_table_column.svg" />
                </mat-icon> -->
                </mat-datepicker-toggle>

                <mat-date-range-picker
                  #picker
                  startView="month"
                  [startAt]="startDate"
                ></mat-date-range-picker>
              </mat-form-field>

              <img
                class="default_button"
                src="../../../assets/img/btn_date_default.svg"
                alt="Group"
                (click)="setDefaultRangeDate()"
                style="margin-left: 12px"
              />
              <!-- <div class="default_button" (click)="setDefaultRangeDate()">
              Default
            </div> -->
            </div>
          </div>
          <!-- select 欄位篩選器 -->
          <!-- <div class="select">
            <img
              class="select_icon"
              src="../../../assets/img/btn_table_column.svg"
              alt="Group"
              (click)="selectClickIcon()"
            />
            <div
              class="select_choice"
              [ngClass]="{ select_choice_show: selected }"
              *ngIf="displayedColumns as item"
            >
              <p class="select_tip">Column display :</p>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="duration"
                  id=""
                />
                <span class="checkbox_span"></span>
                Duration
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="section"
                  id=""
                />
                <span class="checkbox_span"></span>
                Section
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="line"
                  id=""
                />
                <span class="checkbox_span"></span>
                Line
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="targetOutput"
                  id=""
                />
                <span class="checkbox_span"></span>
                Mo Qty
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="side"
                  id=""
                />
                <span class="checkbox_span"></span>
                Side
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="startTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Start Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="endTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                End Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="restTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Rest Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="downTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Down Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="changeover"
                  id=""
                />
                <span class="checkbox_span"></span>
                Changeover
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="actProductionTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Act Production Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="operators"
                  id=""
                />
                <span class="checkbox_span"></span>
                Operators
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="actTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Act Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="cycleTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Cycle Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="standardTime"
                  id=""
                />
                <span class="checkbox_span"></span>
                Standard Time
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="output"
                  id=""
                />
                <span class="checkbox_span"></span>
                Output Qty
              </label>

              <label class="checkbox_gourp">
                <input
                  (change)="selectCheckbox($event)"
                  checked
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  value="efficiency"
                  id=""
                />
                <span class="checkbox_span"></span>
                Efficiency
              </label>
            </div>
          </div> -->
        </div>

        <div class="table_scroll_box">
          <!-- 第一層 Table -->
          <div class="table_container mat-elevation-z8">
            <!-- API RES 沒資料 -->
            <div
              *ngIf="outDataSource.data.length === 0"
              class="api_res_no_data"
            >
              No Data
            </div>

            <!-- Table 吃 DataSource資料 -->
            <mat-table
              #table
              *ngIf="outDataSource.data.length > 0"
              [dataSource]="outDataSource"
              multiTemplateDataRows
              matSort
              #sort="matSort"
              matSortActive="startTime"
              matSortDirection="desc"
              (matSortChange)="sortData($event)"
            >
              <!-- MONo Column -->
              <ng-container matColumnDef="sn">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.95"
                >
                  MO No.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.95"
                >
                  <div *ngIf="!element?.mainNo">
                    {{ dataFormatSvc.checkValDash(element.sn) }}
                  </div>
                  <div
                    *ngIf="element?.mainNo && element.sn === element?.mainNo"
                    style="width: 100%"
                  >
                    <p class="main_mo">
                      <span>{{ dataFormatSvc.checkValDash(element.sn) }}</span>
                      <span class="main_tag">main</span>
                    </p>
                  </div>
                  <div
                    *ngIf="element?.mainNo && element.sn !== element?.mainNo"
                  >
                    <p class="sub_title">
                      {{ dataFormatSvc.checkValDash(element.mainNo) }}-
                    </p>
                    <p>{{ dataFormatSvc.checkValDash(element.sn) }}</p>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Part No. Column -->
              <ng-container matColumnDef="product">
                <mat-header-cell
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  *matHeaderCellDef
                  class=""
                  style="flex-grow: 2.1"
                >
                  Part No.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 2.1"
                >
                  {{ dataFormatSvc.checkValDash(element.product) }}
                </mat-cell>
              </ng-container>

              <!-- SAP Status Column -->
              <ng-container matColumnDef="erpStatus">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.3"
                >
                  SAP Status
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.3"
                >
                  {{ dataFormatSvc.checkValDash(element.erpStatus) }}
                </mat-cell>
              </ng-container>

              <!-- Act Production Time Column -->
              <ng-container matColumnDef="actProductionTime">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 1.65"
                  matTooltipPosition="below"
                  [matTooltip]="'Act Prod Time'"
                >
                  Act Prod T.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 1.65"
                  [title]="element.actProductionTime + 's'"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(
                        element.formattedActProductionTime
                      )
                    }}
                  </span>
                  <img
                    *ngIf="element.actProductionWarning"
                    class="waringIcon"
                    src="/assets/img/table_waring.svg"
                    alt="warning"
                  />
                </mat-cell>
              </ng-container>

              <!-- Mo Qty Column -->
              <ng-container matColumnDef="targetOutput">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 0.75"
                  matTooltipPosition="below"
                  [matTooltip]="'MO Qty'"
                >
                  MO
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 0.75"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(element.formattedTargetOutput)
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Input Qty Column -->
              <ng-container matColumnDef="input">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 0.75"
                  matTooltipPosition="below"
                  [matTooltip]="'Input Qty'"
                >
                  INP
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 0.75"
                >
                  <span>
                    {{ dataFormatSvc.checkValDash(element.formattedInput) }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Output Qty Column -->
              <ng-container matColumnDef="output">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 0.75"
                  matTooltipPosition="below"
                  [matTooltip]="'Output Qty'"
                >
                  OUT
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 0.75"
                >
                  <span>
                    {{ dataFormatSvc.checkValDash(element.formattedOutput) }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Achieve Rate Column -->
              <ng-container matColumnDef="achieveRate">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 1.1"
                  matTooltipPosition="below"
                  [matTooltip]="'Achieve Rate'"
                >
                  ACHV R.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 1.1"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(element.formattedAchieveRate)
                    }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Output Yield Column -->
              <ng-container matColumnDef="outputYield">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class=""
                  style="flex-grow: 1.1"
                  matTooltipPosition="below"
                  [matTooltip]="'Output Yield'"
                >
                  OUT YLD
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class=""
                  style="flex-grow: 1.1"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(element.formattedOutputYield)
                    }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Efficiency Column -->
              <tr matColumnDef="efficiency">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.1"
                  matTooltipPosition="below"
                  [matTooltip]="'Efficiency'"
                >
                  EFF
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.1"
                >
                  <span [title]="element.efficiency">
                    {{
                      dataFormatSvc.checkValDash(element.formattedEfficiency)
                    }}
                    %
                  </span>
                </mat-cell>
              </tr>

              <!-- AVG. RTY Column -->
              <tr matColumnDef="avgrty">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1"
                >
                  Avg RTY
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1"
                >
                  <span [title]="element.avgrty">
                    {{ dataFormatSvc.checkValDash(element.formattedAvgRty) }} %
                  </span>
                </mat-cell>
              </tr>

              <!-- Overdue Date Column -->
              <ng-container matColumnDef="overdueDate">
                <mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.45"
                >
                  Overdue Date
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.45"
                >
                  <span [title]="element.overdueDate">
                    {{
                      dataFormatSvc.checkValDash(element.formattedOverdueDate)
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Time to repair Column -->
              <tr matColumnDef="timeToRepair">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.6"
                >
                  Time To<br />Repair
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.6"
                >
                  <span
                    class="cell_text_right"
                    style="width: 80%"
                    [title]="element.timeToRepair"
                  >
                    {{
                      dataFormatSvc.checkValDash(element.formattedTimeToRepair)
                    }}
                  </span>
                </mat-cell>
              </tr>

              <!-- Overdue Column -->
              <tr matColumnDef="overdue">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  style="flex-grow: 1.2"
                >
                  Overdue<br />Qty.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.2"
                >
                  <span
                    class="cell_text_right"
                    style="width: 80%"
                    [title]="element.overdue"
                  >
                    {{ dataFormatSvc.checkValDash(element.formattedOverdue) }}
                  </span>
                </mat-cell>
              </tr>

              <!-- 第二層 table   Start ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->
              <ng-container matColumnDef="expandedDetail">
                <mat-cell
                  *matCellDef="let row"
                  class="innerTable"
                  [@detailExpand]="expandHandler(row)"
                >
                  <mat-table
                    [dataSource]="row.child"
                    multiTemplateDataRows
                    matSort
                    #innerTables
                    #innerSort="matSort"
                    matSortActive=""
                    matSortDirection=""
                    style="width: 100%"
                  >
                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: none; width: 35px"
                      >
                        <!-- action -->
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: none; width: 35px"
                      ></mat-cell>
                    </ng-container>

                    <!-- line Column -->
                    <ng-container matColumnDef="line">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.95"
                      >
                        Line
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 1.95"
                      >
                        {{ dataFormatSvc.checkValDash(element.line) }}
                      </mat-cell>
                    </ng-container>

                    <!-- Section Column -->
                    <ng-container matColumnDef="section">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 2.1"
                        matTooltipPosition="below"
                        [matTooltip]="'Section'"
                      >
                        SEC
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 2.1"
                      >
                        {{ element.section }}
                      </mat-cell>
                    </ng-container>

                    <!-- Side Column -->
                    <ng-container matColumnDef="side">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.3"
                      >
                        Side
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 1.3"
                      >
                        {{ dataFormatSvc.checkValDash(element.side) }}
                      </mat-cell>
                    </ng-container>

                    <!-- Act Production Time Column -->
                    <ng-container matColumnDef="actProductionTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.65"
                        matTooltipPosition="below"
                        [matTooltip]="'Act Prod Time'"
                      >
                        Act Prod T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        matTooltipPosition="below"
                        [matTooltip]="
                          'standardTime : ' + element.standardTime + ' s'
                        "
                        style="flex-grow: 1.65"
                      >
                        <span
                          [ngClass]="{ c_red: element.actProductionWarning }"
                        >
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedActProductionTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Mo Qty Column -->
                    <ng-container matColumnDef="targetOutput">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 0.75"
                        matTooltipPosition="below"
                        [matTooltip]="'MO Qty'"
                      >
                        MO
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 0.75"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedTargetOutput
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Input Qty Column -->
                    <ng-container matColumnDef="input">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 0.75"
                        matTooltipPosition="below"
                        [matTooltip]="'Input Qty'"
                      >
                        INP
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 0.75"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(element.formattedInput)
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Output Qty Column -->
                    <ng-container matColumnDef="output">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 0.75"
                        matTooltipPosition="below"
                        [matTooltip]="'Output Qty'"
                      >
                        OUT
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 0.75"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(element.formattedOutput)
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Achieve Rate Column -->
                    <ng-container matColumnDef="achieveRate">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.1"
                        matTooltipPosition="below"
                        [matTooltip]="'Achieve Rate'"
                      >
                        ACHV R.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 1.1"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedAchieveRate
                            )
                          }}%
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Output Yield Column -->
                    <ng-container matColumnDef="outputYield">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.1"
                        matTooltipPosition="below"
                        [matTooltip]="'Output Yield'"
                      >
                        OUT YLD
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 1.1"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedOutputYield
                            )
                          }}%
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- efficiency Column -->
                    <ng-container matColumnDef="efficiency">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 1.1"
                        matTooltipPosition="below"
                        [matTooltip]="'Efficiency'"
                      >
                        EFF
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex-grow: 1.1"
                      >
                        <span
                          (click)="onClickEfficiency(element)"
                          [title]="element.efficiency"
                        >
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedEfficiency
                            )
                          }}
                          %
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- RTY Column -->
                    <ng-container matColumnDef="rty">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        style="flex-grow: 2.5"
                      >
                        RTY
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="cell_text_right"
                        style="flex-grow: 2.5"
                      >
                        <span>
                          {{ dataFormatSvc.checkValDash(element.formattedRty) }}
                          %
                        </span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="innerDisplayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: innerDisplayedColumns"
                      matRipple
                      class="element-row"
                    >
                    </mat-row>
                  </mat-table>
                </mat-cell>
              </ng-container>
              <!-- 第二層 table   END ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->

              <!-- action "^" -->
              <ng-container matColumnDef="action">
                <mat-header-cell
                  *matHeaderCellDef
                  style="flex: none; width: 35px"
                >
                  <!-- action -->
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  style="flex: none; width: 35px"
                >
                  <!-- 判斷有沒有 children -->
                  <ng-container *ngIf="element.child.length !== 0">
                    <button mat-icon-button [@iconExpand]="iconSwitch(element)">
                      <mat-icon>
                        <img
                          src="/assets/img/smart_factory/Arrow_right.svg"
                          alt=""
                        />
                      </mat-icon>
                    </button>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <!-- 表頭 sticky: true => fix ".mat-header-row" -->
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
                matRipple
                class="element-row"
                (click)="rowClickSwitchIsExpanded(row)"
              >
              </mat-row>
              <mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                style="overflow: hidden"
                class="example-detail-row"
              >
              </mat-row>
            </mat-table>
          </div>
        </div>

        <!-- page navigation-->
        <mat-paginator
          class="paginator"
          #MatPaginator
          [showFirstLastButtons]="true"
          [length]="isTotalPageNum"
          [pageIndex]="queryObj.offset - 1"
          [pageSize]="12"
          (page)="onPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>
  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
