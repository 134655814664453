import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppComponent } from './app.component';
import { EnvironmentComponent } from './environment/environment.component';
import { ElectricityComponent } from './electricity/electricity.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { MainBoardComponent } from './main_board/main-board/main-board.component';
import { ProductionOverviewComponent } from './production-overview/production-overview/production-overview.component';
import { CctvComponent } from './cctv/cctv.component';
import { WipOverviewComponent } from './wip-overview/wip-overview.component';
import { EquipmentEfficiencyOverviewComponent, EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent } from './equipment-efficiency-overview/equipment-efficiency-overview.component';
import { NewMainBoardComponent } from './new-main-board/new-main-board.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { BarChartComponent } from '../components/bar-chart/bar-chart.component';
import { TableComponent } from '../components/table/table.component';
import { TextInputComponent } from '../components/text-input/text-input.component';

// Agular-Material
import { CdkTableModule } from '@angular/cdk/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QualityOverviewComponent } from './quality-overview/quality-overview.component';
import { MoQualityComponent } from './mo-quality/mo-quality.component';
// import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MatDialogModule } from '@angular/material/dialog';

// plugin
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MomentModule } from 'ngx-moment';
import { ChartsModule } from 'ng2-charts';
import { ProductionHoursComponent } from './production-hours/production-hours.component';
import { DefectiveProductComponent } from './defective-product/defective-product.component';

// custom Pipe
import { SecondsToHhmmssPipe } from './pipe/seconds-to-hhmmss.pipe';
import { TranToLocalTimeZonePipe } from './pipe/tran-to-local-TimeZone.pipe';
import { SortTableComponent } from './sort-table/sort-table.component';

// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// CookieService
import { CookieService } from 'ngx-cookie-service';
import { EquipEfficiencyComponent } from './equip-efficiency/equip-efficiency.component';
import { NewProductionHoursComponent, ProductionHoursEfficiencyApiDataDialogComponent } from './new-production-hours/new-production-hours.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NewProductionOverviewComponent, ProductionOverviewEfficiencyApiDataDialogComponent } from './new-production-overview/new-production-overview.component';
import { CloseOrderOverviewComponent, CloseOrderEfficiencyApiDataDialogComponent } from './close-order-overview/close-order-overview.component';
import { EquipmentAlarmComponent } from './equipment-alarm/equipment-alarm.component';
import { NewRepairManagementComponent, RootCauseUnitListDialogComponent } from './new-repair-management/new-repair-management.component';
import { NewQualityOverviewComponent } from './new-quality-overview/new-quality-overview.component';
import { NewMoOverviewComponent } from './new-mo-overview/new-mo-overview.component';
import { NewWipOverviewComponent } from './new-wip-overview/new-wip-overview.component';
import { AteEquipEfficiencyComponent } from './ate-equip-efficiency/ate-equip-efficiency.component';
import { Phase3ProductionOverviewComponent, Phase3ProductionOverviewEfficiencyApiDataDialogComponent, ProductionOverviewDownloadDialogComponent } from './phase3-production-overview/phase3-production-overview.component';
import { Phase3EquipAlarmComponent, Phase3EquipAlarmDownloadDialogComponent, Phase3ExpandListDialogDataDialogComponent } from './phase3-equip-alarm/phase3-equip-alarm.component';
import { Phase3RepairManagementComponent, Phase3WipTableDialogDataDialogComponent, Phase3RepairDownloadDialogComponent } from './phase3-repair-management/phase3-repair-management.component';
import { Phase3QualityOverviewComponent } from './phase3-quality-overview/phase3-quality-overview.component';
import { Phase3MoOverviewComponent } from './phase3-mo-overview/phase3-mo-overview.component';
import { Phase3EquipmentEfficiencyOverviewComponent, Phase3EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent } from './phase3-equipment-efficiency-overview/phase3-equipment-efficiency-overview.component';
import { Phase3CloseOrderOverviewComponent, Phase3CloseOrderEfficiencyApiDataDialogComponent, Phase3CloseOrderDownloadDialogComponent } from './phase3-close-order-overview/phase3-close-order-overview.component';
import { Phase3MainBoardComponent, Phase3MainBoardUploadDialogComponent } from './phase3-main-board/phase3-main-board.component';
import { Phase3WipOverviewComponent } from './phase3-wip-overview/phase3-wip-overview.component';

import { Phase3AteComponent } from './phase3-ate/phase3-ate.component';
import { Phase3DroComponent } from './phase3-dro/phase3-dro.component';
import { DropRateOverviewComponent, DropRateOverviewDownloadDialogComponent } from './drop-rate-overview/drop-rate-overview.component';
import { Phase3EquipAlarmAllComponent } from './phase3-equip-alarm-all/phase3-equip-alarm-all.component';

// DatePicker Formate 顯示日期格式
export const TW_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD-08:00'
  },
  display: {
    dateInput: 'YYYY/MM/DD-08:00',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY/MM/DD-08:00',
    monthYearA11yLabel: 'YYYY MMM'
  }
};
// DateTimePicker Formate 顯示日期格式
export const TW_FORMATS_DATE_TIME = {
  parseInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hourCycle: 'h23' },
  fullPickerInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hourCycle: 'h23' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    EnvironmentComponent,
    ElectricityComponent,
    MainBoardComponent,
    ProductionOverviewComponent,
    QualityOverviewComponent,
    MoQualityComponent,
    CctvComponent,
    ProductionHoursComponent,
    DefectiveProductComponent,
    SecondsToHhmmssPipe,
    TranToLocalTimeZonePipe,
    WipOverviewComponent,
    EquipmentEfficiencyOverviewComponent,
    NewMainBoardComponent,
    SortTableComponent,
    EquipEfficiencyComponent,
    NewProductionHoursComponent,
    NewProductionOverviewComponent,
    CloseOrderOverviewComponent,
    CloseOrderEfficiencyApiDataDialogComponent,
    ProductionHoursEfficiencyApiDataDialogComponent,
    ProductionOverviewEfficiencyApiDataDialogComponent,
    EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent,
    EquipmentAlarmComponent,
    NewRepairManagementComponent,
    RootCauseUnitListDialogComponent,
    DropdownComponent,
    ProgressBarComponent,
    BarChartComponent,
    TableComponent,
    TextInputComponent,
    NewQualityOverviewComponent,
    NewMoOverviewComponent,
    NewWipOverviewComponent,
    AteEquipEfficiencyComponent,
    Phase3ProductionOverviewComponent,
    Phase3ProductionOverviewEfficiencyApiDataDialogComponent,
    ProductionOverviewDownloadDialogComponent,
    Phase3EquipAlarmComponent,
    Phase3EquipAlarmDownloadDialogComponent,
    Phase3ExpandListDialogDataDialogComponent,
    Phase3RepairManagementComponent,
    Phase3WipTableDialogDataDialogComponent,
    Phase3RepairDownloadDialogComponent,
    Phase3QualityOverviewComponent,
    Phase3MoOverviewComponent,
    Phase3EquipmentEfficiencyOverviewComponent,
    Phase3EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent,
    Phase3CloseOrderOverviewComponent,
    Phase3CloseOrderEfficiencyApiDataDialogComponent,
    Phase3CloseOrderDownloadDialogComponent,
    Phase3MainBoardComponent,
    Phase3MainBoardUploadDialogComponent,
    Phase3WipOverviewComponent,
    Phase3AteComponent,
    Phase3DroComponent,
    DropRateOverviewComponent,
    DropRateOverviewDownloadDialogComponent,
    Phase3EquipAlarmAllComponent,
  ],
  exports: [
    LoginComponent,
    MenuComponent,
    EnvironmentComponent,
    ElectricityComponent,
    MainBoardComponent,
    ProductionOverviewComponent,
    QualityOverviewComponent,
    MoQualityComponent,
    CctvComponent,
    ProductionHoursComponent,
    DefectiveProductComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    // import ngs-moment
    MomentModule,
    ChartsModule,

    // import ngx-mat-select-search
    // NgxMatSelectSearchModule,

    // import Material Start*****
    CdkTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatMomentDateModule,
    MatCardModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatChipsModule,
    MatBadgeModule,
    MatSidenavModule,
    // MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatDialogModule,
    // import Material End*****

    // import ng-circle-progress
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      space: -10,
      toFixed: 2,
      maxPercent: 100,
      // subtitle: 'FPY',
      showSubtitle: false,
      subtitleColor: '#ffffff',
      subtitleFontSize: '30',
      subtitleFontWeight: '300',
      unitsColor: '#ffffff',
      titleColor: '#ffffff',
      titleFontSize: '50',
      titleFontWeight: '300',
      unitsFontSize: '35',
      unitsFontWeight: '300',
      outerStrokeGradient: false,
      outerStrokeColor: '#00F0FF',
      outerStrokeWidth: 10,
      innerStrokeWidth: 10,
      innerStrokeColor: '#2a2a2a',
      animateTitle: false,
      showTitle: true,
      showUnits: true,
      showBackground: false,
      responsive: true,
    })
  ],
  providers: [
    CookieService,
    DatePipe,
    DecimalPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' }, // 中文化 DatePicker
    { provide: MAT_DATE_FORMATS, useValue: TW_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ZH-TW' }, // 中文化 DateTimePicker
    { provide: OWL_DATE_TIME_FORMATS, useValue: TW_FORMATS_DATE_TIME },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

