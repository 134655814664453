import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { DataSource } from '@angular/cdk/collections';
import { interval } from 'rxjs';
import { map, tap, } from 'rxjs/operators';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiService } from 'src/app/_services/apiSvc.service';

import { HttpClient, } from '@angular/common/http';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';

@Component({
  selector: 'app-sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})
export class SortTableComponent implements OnInit, AfterViewInit, OnDestroy {

  upDateTimer;
  // 計時器 15秒 Call API 更新資料
  rxTimer$ = interval(15000).subscribe((data) => {
    // this.getTableData$().pipe().subscribe((res) => {
    //   console.log('getTableData$ res:', res);

    //   this.DATA = res;
    //   this.dataSource = new MatTableDataSource<any>(this.DATA);

    //   this.isSvLoading.loading = false;
    //   this.ngAfterViewInit();
    // });
  });

  // dataSource: MatTableDataSource<UserData>;
  dataSource;
  expandedElement: any = {};

  filterValue = '';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  // 第一層標題 - ( 要有子項目增加 'active' )
  displayedColumns: string[] = ['product', 'order', 'section', 'side', 'ieStandardTime', 'actualTime', 'performance', 'MO_Quantity', 'Finish_Quantity'];


  mergeCell = [
    {
      Finish_Quantity: 500,
      MO_Quantity: 117,
      actualTime: 45,
      customer: 'AMAZON',
      ieStandardTime: 1,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL004',
      orderEndTime: null,
      orderStartTime: '2021-04-26T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: 'RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-04-25T16:00:00.000Z',
      status: 3,
      side: 'A',
    },
    {
      Finish_Quantity: 122,
      MO_Quantity: 11,
      actualTime: 25,
      customer: 'AMAZON',
      ieStandardTime: 5,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL004',
      orderEndTime: null,
      orderStartTime: '2021-04-30T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: 'RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-04-25T16:00:00.000Z',
      status: 3,
      side: 'B',
    },
    {
      Finish_Quantity: 333,
      MO_Quantity: 10,
      actualTime: 254,
      customer: 'AMAZON',
      ieStandardTime: 6,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL110',
      orderEndTime: null,
      orderStartTime: '2021-05-20T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: '2__RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-04-25T16:00:00.000Z',
      status: 3,
      side: 'A',
    },
    {
      Finish_Quantity: 444,
      MO_Quantity: 200,
      actualTime: 45,
      customer: 'AMAZON',
      ieStandardTime: 4,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL110',
      orderEndTime: null,
      orderStartTime: '2021-05-25T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: '2__RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-04-25T16:00:00.000Z',
      status: 3,
      side: 'B',
    },
    {
      Finish_Quantity: 555,
      MO_Quantity: 129,
      actualTime: 66,
      customer: 'AMAZON',
      ieStandardTime: 1,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL220',
      orderEndTime: null,
      orderStartTime: '2021-07-25T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: '3__RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-04-25T16:00:00.000Z',
      status: 3,
      side: 'A',
    },
    {
      Finish_Quantity: 78,
      MO_Quantity: 500,
      actualTime: 100,
      customer: 'AMAZON',
      ieStandardTime: 7,
      mStandardTime: null,
      operators: 3,
      order: 'CE4GJ02AL330',
      orderEndTime: null,
      orderStartTime: '2021-05-25T14:18:03.000Z',
      performance: 0.09247842170160296,
      product: '4__RLOD-GEJ002-00BG-1',
      scheduleDay: '2021-06-25T16:00:00.000Z',
      status: 3,
      side: 'A',
    },
  ];

  spans = [];
  DATA = this.mergeCell;

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
  }

  ngOnInit(): void {

    // this.getTableData$().pipe().subscribe((res) => {
    //   console.log('getTableData$ res:', res);

    //   this.DATA = res;
    //   this.dataSource = new MatTableDataSource<any>(this.DATA);

    //   this.isSvLoading.loading = false;
    //   this.ngAfterViewInit();
    // }
    // );

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // subscribe 訂閱 陣列變動， 拿到 row data 更新排序的陣列
    this.dataSource.connect().pipe().subscribe((newSortArr) => {
      // 將新 Array 放回 this.DATA
      // console.log('newSortArr:', newSortArr);
      this.DATA = newSortArr;
      // this.ngOnInit();
    });

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filterValue.trim().toLowerCase();

      // Merge cell
      this.cacheSpan('order', d => d.order);
    }, 600);
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
    this.rxTimer$.unsubscribe();
  }

  // 觸發排序事件 merge cell function --- step 3
  sortData(item) {
    // 清空排序 array
    this.spans = [];

    // Merge cell
    setTimeout(() => {
      // Merge cell
      this.cacheSpan('order', d => d.order);
    }, 600);
  }

  /**
   * merge row array  --- step 1
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.DATA.length;) {
      const currentValue = accessor(this.DATA[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.DATA.length; j++) {
        if (currentValue !== accessor(this.DATA[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }

  }

  // step 2  ---  merge row array
  getRowSpan(col, index) {
    const result = this.spans[index] && this.spans[index][col];
    // console.log('result: *********************', result);
    return result;
  }

  // 換頁觸發 Paginate Change
  onPaginateChange(e) {
    // console.log('onPaginateChange e:', e);
    this.sortData('onPaginateChange');
  }


  // Search input filter
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.sortData('onPaginateChange');
  }

  // null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }

  // Pipe 資料處理
  pipeTranslateInfo(arr) {
    arr.map((x) => {
      if (x.orderStartTime !== '-') {
        x.orderStartTime = this.datePipe.transform(x.orderStartTime, 'yyyy/M/dd HH:mm');
      }
      if (x.ieStandardTime !== '-') {
        x.ieStandardTime = this.numberPipe.transform(
          +x.ieStandardTime < 0 ? 0 : +x.ieStandardTime, '0.1-1'
        );
      }
      if (x.actualTime !== '-') {
        x.actualTime = this.numberPipe.transform(+(x.actualTime / 60), '0.1-1');
      }

      x.performance = +x.actualTime === 0.0 ? 0 : 0.01 > +x.ieStandardTime / +x.actualTime ? 0.01 : +x.ieStandardTime / +x.actualTime;
      x.performance = this.numberPipe.transform(+(x.performance * 100), '0.2-2');
      // console.log('x.performance:', +x.performance);
      // console.log('x.performance:', !!(+x.performance));

      if (x.MO_Quantity !== '-') {
        x.MO_Quantity = this.numberPipe.transform(x.MO_Quantity);
      }

      if (x.Finish_Quantity !== '-') {
        x.Finish_Quantity = this.numberPipe.transform(x.Finish_Quantity);
      }

      return x;
    });

    return arr;
  }


  // Call API
  getTableData$() {
    // return this.apiSvc.getProductHourTableData().pipe(
    //   tap((res) => { console.log('row data =>', res); }),
    //   map((res) => this.pipeTranslateInfo(res)),
    //   map((res) => this.nullToDash(res)),
    // );
  }

}
