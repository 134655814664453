<div class="cctv_container">
  <div class="title_group">
    <h1 class="cctv_title">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      CCTV
    </h1>
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container_box">
    <!-- cam -->
    <div class="cctv_cam">
      <div class="cam_group">
        <div class="cam_box cam_1">
          <div class="cam_video">
            <canvas #video_01></canvas>
          </div>
          <div class="cam_title">
            <p><span>Camera No.: </span> WorkShop-1</p>
            <p><span>Area: </span>Loading Line-D</p>
          </div>
        </div>

        <div class="cam_box cam_2">
          <div class="cam_video">
            <canvas #video_02></canvas>
          </div>
          <div class="cam_title">
            <p><span>Camera No.: </span>SideDoor-1</p>
            <p><span>Area: </span>Aisle</p>
          </div>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="cctv_table">
      <div class="table_title">Alarm History</div>
      <div class="table_cell_name table_title_name">
        <div class="table_title_time">Time</div>
        <div class="table_title_no" (click)="sortData('No')">Camera No.</div>
        <div class="table_title_area">Area</div>
      </div>

      <!-- table box -->
      <div class="table_group">
        <!-- table cell -->
        <button
          class="table_cell_name"
          *ngFor="let item of cctvData; trackBy: trackByFn"
          (click)="showPopUp(item)"
        >
          <div
            class="table_cell_bgColor"
            [id]="item.time"
            [ngClass]="{ table_cell_bgColor_active: item.active }"
          >
            <div class="table_time">
              {{
                item.occuranceTime | amLocal | amDateFormat: "YYYY/MM/DD HH:mm"
              }}
            </div>
            <div class="table_no">
              {{ item.noInArea }}
            </div>
            <div class="table_area">{{ item.area }}</div>
          </div>
        </button>
      </div>

      <p class="tips">*Click an item for more information.</p>

      <!-- popup box -->
      <div
        class="popup_box"
        [ngClass]="{ popup_box_show: popupShow }"
        *ngIf="cctvData as item"
      >
        <div class="popup_mask"></div>

        <div class="popup_content" *ngIf="popupData as item">
          <div class="popup_close" (click)="showPopUp('close')">
            <img src="/assets/img/smart_factory/close.svg" alt="close" />
          </div>
          <div class="text_box">
            <div class="text_group">
              <div class="text_titile">
                Time:
                <span>{{
                  item.occuranceTime
                    | amLocal
                    | amDateFormat: "YYYY/MM/DD HH:mm"
                }}</span>
              </div>
              <!-- <div class="text_word">
                {{
                  item.occuranceTime
                    | amLocal
                    | amDateFormat: "YYYY/MM/DD HH:mm"
                }}
              </div> -->
            </div>
            <div class="text_group">
              <div class="text_titile">
                No.: <span>{{ item.noInArea }}</span>
              </div>
              <!-- <div class="text_word">{{ item.noInArea }}</div> -->
            </div>
            <div class="text_group">
              <div class="text_titile">
                Area: <span>{{ item.area }}</span>
              </div>
              <!-- <div class="text_word">{{ item.area }}</div> -->
            </div>
          </div>
          <div
            class="popup_video"
            *ngIf="popupShow"
            [ngStyle]="{
              'background-image':
                'url(' + apiCctvIpsfUrl + '/' + item.imageHash + ')'
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
