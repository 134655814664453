import { Component, OnDestroy, OnInit } from '@angular/core';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { timer, Subject, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../_services/apiSvc.service';

@Component({
  selector: 'app-wip-overview',
  templateUrl: './wip-overview.component.html',
  styleUrls: ['./wip-overview.component.scss']
})
export class WipOverviewComponent implements OnInit, OnDestroy {

  subject = new Subject();

  changeWIP;
  isNowWipName: any;
  wipNameArr = [
    {
      wipName: 'SMT',
      isActive: false,
    },
    {
      wipName: 'Loading',
      isActive: false,
    },
    {
      wipName: 'ATE',
      isActive: false,
    }
  ];

  isPageNum = 1;
  isTotalPageNum = 3;
  resItemDataArr = [
    {
      lineName: 'SMT Line A',
      moNum: 'DE1BH01QL004 (A)',
      product: 'RLOD-PBH0014C0G-16',
      moQty: '12000',
      isPinItem: true,
      linePointArr: [],
      pointInsideNum: 6,
      pointInsideArr: [
        {
          num: 172,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: 'SP1',
          lineNameMultipleEnd: 'CM3010',
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 28,
          numWarn: true,
          gray_point: [{}],
          lineNameSingle: 'V/I',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 31,
          numWarn: false,
          gray_point: [{}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 44,
          numWarn: false,
          gray_point: [{}, {}],
          lineNameSingle: 'Reflow',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 57,
          numWarn: false,
          gray_point: [{}],
          lineNameSingle: 'V/I',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 63,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}],
          lineNameSingle: 'FPT2',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        }
      ],
      startPointObj: {
        num: 6317,
        numWarn: true,
        iconStatus: 2,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
      endPointObj: {
        num: 5423,
        numWarn: false,
        iconStatus: 1,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
    },
    {
      lineName: 'SMT Line B',
      moNum: 'DE1BH01QL005 (B)',
      product: 'RLOD-PBH0014C0G-16',
      moQty: '12000',
      isPinItem: false,
      linePointArr: [],
      pointInsideNum: 4,
      pointInsideArr: [
        {
          num: 172,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: 'SP1',
          lineNameMultipleEnd: 'CM3010',
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 28,
          numWarn: true,
          gray_point: [{}],
          lineNameSingle: 'V/I',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 31,
          numWarn: false,
          gray_point: [{}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 63,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}],
          lineNameSingle: 'FPT2',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        }
      ],
      startPointObj: {
        num: 6317,
        numWarn: true,
        iconStatus: 2,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
      endPointObj: {
        num: 5423,
        numWarn: false,
        iconStatus: 1,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
    },
    {
      lineName: 'SMT Line C',
      moNum: 'DE1BH01QL006 (C)',
      product: 'RLOD-PBH0014C0G-16',
      moQty: '12000',
      isPinItem: false,
      linePointArr: [],
      pointInsideNum: 8,
      pointInsideArr: [
        {
          num: 172,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: 'SP1',
          lineNameMultipleEnd: 'CM3010',
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 31,
          numWarn: false,
          gray_point: [{}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 63,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}],
          lineNameSingle: 'FPT2',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 28,
          numWarn: true,
          gray_point: [{}],
          lineNameSingle: 'V/I',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 31,
          numWarn: false,
          gray_point: [{}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        },
        {
          num: 28,
          numWarn: true,
          gray_point: [{}],
          lineNameSingle: 'V/I',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 1,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 63,
          numWarn: false,
          gray_point: [{}, {}, {}, {}, {}, {}],
          lineNameSingle: 'FPT2',
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: true,
        },
        {
          num: 31,
          numWarn: false,
          gray_point: [{}, {}, {}],
          lineNameSingle: null,
          lineNameMultipleStart: null,
          lineNameMultipleEnd: null,
          iconStatus: 2,  // 1 => icon01 , 2 => icon02
          iconBgWarn: false,
        }
      ],
      startPointObj: {
        num: 6317,
        numWarn: true,
        iconStatus: 2,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
      endPointObj: {
        num: 5423,
        numWarn: false,
        iconStatus: 1,  // 1 => icon01 , 2 => icon02
        iconBgWarn: true,
      },
    }
  ];

  openPopup = false;
  popupMode = '1'; // 0 default, 1 pin, 2 cancel


  timerFn;
  isNowSecSave;
  choiceSecShow;
  swapSecArr = [
    {
      secNum: 3,
      isActive: false,
    },
    {
      secNum: 6,
      isActive: false,
    },
    {
      secNum: 9,
      isActive: false,
    },
    {
      secNum: 12,
      isActive: false,
    }
  ];



  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService
  ) {
    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 500);
  }

  ngOnInit(): void {
    this.swapSecs(3);
    this.clickWipTitle('SMT');
  }

  ngOnDestroy() {
    this.timerFn.unsubscribe();
  }


  private rxTimerStop() {
    console.log('Fomate sec time **********************');
    this.subject.next();
  }


  secTimer(sec) {
    this.isNowSecSave = sec;
    console.log('this.isNowSecSave:', this.isNowSecSave);

    this.timerFn = timer(0, sec * 10000).pipe(
      takeUntil(this.subject)
    ).subscribe(
      (t) => {
        console.log(`sec => ${sec} ,`, t);
        this.getApi();
        // this.rxTimerStop();

      }
    );
  }

  swapSecs(sec) {
    // check sec array
    // for (let i = 0; i < this.swapSecArr.length; i++) {
    //   if (+sec === +this.swapSecArr[i].secNum) {
    //     this.swapSecArr[i].isActive = true;
    //   } else {
    //     this.swapSecArr[i].isActive = false;
    //   }
    // }

    for (const item of this.swapSecArr) {
      if (+sec === +item.secNum) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    }

    this.rxTimerStop();

    console.log('sec:', sec);
    this.secTimer(sec);
  }



  //
  clickWipTitle(name) {
    this.isNowWipName = name;
    this.wipNameArr.map(x => {
      x.isActive = false;
      if (x.wipName === name) {
        x.isActive = true;
      }
      return x;
    });
    console.log('this.isNowWipName ****************** => ', this.isNowWipName);
    console.log('this.wipNameArr:', this.wipNameArr);

    this.swapSecs(this.isNowSecSave);
  }


  // call API method
  getApi() {
    this.apiSvc.getMainBoardMoListData().subscribe(
      (res) => {
        this.isSvLoading.loading = true;

        console.log('API res => ', res);

        setTimeout(() => {
          this.isSvLoading.loading = false;
        }, 500);
      }
    );
  }

}
