import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/_services/apiSvc.service';

import { forkJoin } from 'rxjs';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { SpinnerService } from 'src/app/_services/spinner.service';

@Component({
  selector: 'app-main-board',
  templateUrl: './main-board.component.html',
  styleUrls: ['./main-board.component.scss']
})
export class MainBoardComponent implements OnInit, OnDestroy {

  upDateTimer;

  closeMoNum = 88888;
  totalMoNum = 99999;
  totalDayPercent;

  begingTime = '2020-12-07T07:55:39.811Z';
  before24Time = '2020-12-07T20:55:39.811Z';

  elec: any = {
    elec_icon_svg_active: true,
    elec_bg: true,
    elec_msg_active: true,
    Energy: {
      Energy_red_light: true,
      Energy_data: [
        {
          sensorId: 'meter-ate-2',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          mod: 'energy'
        },
        {
          sensorId: 'meter-ate-3',
          status_red_light: false,
          isReportIssue: false,
          isReported: false,
          mod: 'energy'
        },
      ]
    },
  };

  cctv: any = {
    cctv_icon_svg_active: true,
    cctv_bg: true,
    cctv_msg_active: true,
    cctvData: {
      cctvData_red_light: false,
      cctvData_data: [
        {
          station: 'cam_1',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
        },
        {
          station: 'cam_2',
          status_red_light: false,
          isReportIssue: false,
          isReported: false,
        },
      ]
    },
  };

  equipment: any = {
    equipment_icon_svg_active: true,
    equipment_bg: true,
    equipment_msg_active: true,
    SMT: {
      SMT_red_light: true,
      SMT_data: [
        {
          station: 'SP',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'SPI',
          status_red_light: false,
          isReportIssue: false,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'CM1',
          status_red_light: true,
          isReportIssue: true,
          isReported: true,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'CM2',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'CM3',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'CM4',
          status_red_light: false,
          isReportIssue: false,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
        {
          station: 'Reflow',
          status_red_light: true,
          isReportIssue: true,
          isReported: true,
          processSection: 'SMT',
          area: 'LineB'
        }
      ]
    },
    ATE: {
      ATE_red_light: false,
      ATE_data: [
        {
          station: 'SP',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          processSection: 'SMT',
          area: 'LineB'
        },
      ]
    },
    Load: {
      Load_red_light: true,
      Load_data: [
        {
          station: 'W/S',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          processSection: 'Loading',
          area: 'LineD'
        },
      ]
    },
  };

  env: any = {
    env_icon_svg_active: true,
    env_bg: true,
    env_msg_active: true,
    Environment: {
      Environment_red_light: true,
      Environment_data: [
        {
          sensorId: 'sensor-assy',
          status_red_light: true,
          isReportIssue: true,
          isReported: false,
          mod: 'environment'
        },
        {
          sensorId: 'sensor-loading',
          status_red_light: false,
          isReportIssue: false,
          isReported: false,
          mod: 'environment'
        },
      ]
    },
  };

  moProgressData: any = [
    {
      mo_no: 'CD3OD20CS001',
      percent: 80,
    },
    {
      mo_no: 'CD2OD20001',
      percent: 30,
    },
    {
      mo_no: 'CD4OD20BL001',
      percent: 100,
    },
    {
      mo_no: 'CD1OD20DS001',
      percent: 0,
    },
    {
      mo_no: 'CD4OD20BL001',
      percent: 100,
    },
    {
      mo_no: 'CD1OD20DS001',
      percent: 10,
    },
    {
      mo_no: 'CD2OD20001',
      percent: 30,
    },
    {
      mo_no: 'CD4OD20BL001',
      percent: 100,
    },
    {
      mo_no: 'CD1OD20DS001',
      percent: 40,
    },
    {
      mo_no: 'CD4OD20BL001',
      percent: 50,
    },
    {
      mo_no: 'CD1OD20DS001',
      percent: 0,
    }
  ];

  befor24HourData: any = [
    {
      time: '2020-10-21T08:00:31Z',
      name: 'CM1',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020-10-21T08:00:00Z',
      name: 'CM1',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020-11-18T02:32:44.000+00:00',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: true,
    },
    {
      time: '2020-11-18T02:32:44.000+00:00',
      name: 'CM2',
      status: 'Alert',
      isReport: false,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: false,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: true,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: false,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: true,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: false,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: true,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: false,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: false,
    },
    {
      time: '2020/08/07 13:20',
      name: 'CM2',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/09 13:20',
      name: 'CM3',
      status: 'Alert',
      isReport: true,
    },
    {
      time: '2020/08/10 13:20',
      name: 'CM4',
      status: 'Waiting',
      isReport: true,
    }
  ];

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    private httpClient: HttpClient,
    public clock: RealTimeClockService
  ) {
  }

  ngOnInit() {

    this.getAllMapMsg();
    this.getMoListData();
    this.getBegingEndTime();
    this.getTimListData(this.begingTime, this.before24Time);

    this.upDateTimer = setInterval(() => {
      this.getAllMapMsg();
      this.getMoListData();
      this.getTimListData(this.begingTime, this.before24Time);
    }, 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
  }

  iconClick(name: string) {
    this[name][name + '_icon_svg_active'] = !this[name][name + '_icon_svg_active'];
    this[name][name + '_bg'] = !this[name][name + '_bg'];
    this[name][name + '_msg_active'] = !this[name][name + '_msg_active'];
    this[name][name + '_msg_light'] = !this[name][name + '_msg_light'];
    // console.log('this[name]:', this[name]);
  }

  sortMoNoData(arr) {
    this[arr] = arr.sort((a, b) => {
      return b.achieveRate - a.achieveRate;
    });
  }

  trackByFn(index, item) {
    return item.name;
  }

  openReportQuestion(item) {
    // console.log('item:', item);
    item.isReportIssue = false;
    item.openQuestion = true;
  }

  apiReportIssue(item: any) {
    // console.log('item:', item);

    const itemType = (item: any) => {
      const isEquipment = item.processSection;
      const isSensor = item.sensorId;
      if (isEquipment) {
        return 'equipment';
      }
      if (isSensor) {
        return 'sensor';
      }
    };
    // console.log('itemType:', itemType(item));

    const reportJSON = {
      type: itemType(item),
      sensorId: item.sensorId,
      mod: item.mod,
      processSection: item.processSection,
      area: item.area,
      station: item.station
    };
    // const reportJSON = {
    //   type: 'equipment',
    //   sensorId: 'meter-ate-2',
    //   mod: 'energy',
    //   processSection: 'Loading',
    //   area: 'LineD',
    //   station: 'W/S'
    // };
    console.log('reportJSON:', reportJSON);
    this.repoAnnounceData(reportJSON);
    item.openQuestion = false;
    item.isReported = true;
  }

  cancelAapiRepo(item) {
    // console.log('item:', item);
    item.isReportIssue = true;
    item.openQuestion = false;
  }

  // get beging & endTime
  getBegingEndTime() {
    this.before24Time = new Date().toISOString();
    this.begingTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString();
    // console.log('this.begingTime:', this.begingTime);
    // console.log('this.before24Time:', this.before24Time);
  }

  // set alert msg data
  setAlertLightReport(arr) {
    arr.map((item) => {
      item.isReportIssue = item.isReported === false ? true : false;
      if (+item.status !== 0) {
        item.status_red_light = true;
      } else {
        item.status_red_light = false;
      }
      return item;
    });
  }

  // API map data
  getAllMapMsg() {
    const haveAlert = (item) => +item.status !== 0;
    const SMT = this.apiSvc.getMainBoardMapMsgEquipmentData('SMT', 'LineB');
    const Loading = this.apiSvc.getMainBoardMapMsgEquipmentData('Loading', 'LineD');
    const Energy = this.apiSvc.getMainBoardMapMsgSensorData('energy');
    const Environment = this.apiSvc.getMainBoardMapMsgSensorData('environment');

    forkJoin([SMT, Loading, Energy, Environment]).subscribe((result) => {
      this.setAlertLightReport(result[0]);
      this.setAlertLightReport(result[1]);
      this.setAlertLightReport(result[2]);
      this.setAlertLightReport(result[3]);

      this.equipment.SMT.SMT_data = result[0];
      this.equipment.SMT.SMT_red_light = result[0].some(haveAlert);
      console.log('this.equipment.SMT:', this.equipment.SMT);

      this.equipment.Load.Load_data = result[1];
      this.equipment.Load.Load_red_light = result[1].some(haveAlert);
      // console.log('this.equipment.Load:', this.equipment.Load);

      this.elec.Energy.Energy_data = result[2];
      this.elec.Energy.Energy_red_light = result[2].some(haveAlert);
      // console.log('this.elec.Energy:', this.elec.Energy);

      this.env.Environment.Environment_data = result[3];
      this.env.Environment.Environment_red_light = result[3].some(haveAlert);
      // console.log('this.env.Environment:', this.env.Environment);
    });

  }

  // API repo issue 已通報-回傳
  repoAnnounceData(reportJSON) {
    this.apiSvc.getMainBoardReportIssue(reportJSON).subscribe((res) => {
      // console.log('res:', res);
      const isSuccess = res.isReported === true;
      if (isSuccess) {
        alert('通報成功');
      } else {
        alert('通報失敗 => API回傳成功，但通報失敗');
      }

    });
  }

  // API MO lists
  getMoListData() {
    this.apiSvc.getMainBoardMoListData().subscribe((res) => {
      console.log('getMoListData res:', res);
      this.closeMoNum = res.completed;
      this.totalMoNum = res.ordersQuantity;
      this.totalDayPercent = Math.round((this.closeMoNum / this.totalMoNum) * 100);

      this.moProgressData = res.orders.sort((a, b) => a.status.localeCompare(b.status));

      console.log('this.moProgressData:', this.moProgressData);

      this.isSvLoading.loading = false;
    });

  }

  // API time lists 現在時間以前24h
  getTimListData(startTime, endTime) {
    this.apiSvc.getMainBoardTimListData(startTime, endTime).subscribe((res) => {
      // console.log('getTimListData res:', res);
      res.map((item) => {
        if (item.status === 2 || -10) {
          item.status = 'Alert';
        }
      });

      // 取前100比資料
      // res = res.slice(0, 99);
      // console.log('getTimListData res:', res);

      this.befor24HourData = res.sort((a, b) => {
        return b.time.localeCompare(a.time);
      });
    });
  }

}


