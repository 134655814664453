<h1 mat-dialog-title class="title">Error Record List</h1>
<button mat-icon-button mat-dialog-close class="close_btn">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="content">
  <div class="table_scroll_box">
    <!-- 第一層 Table -->
    <div class="table_container mat-elevation-z0">
      <!-- API RES 沒資料 -->
      <div *ngIf="listDataSource.data.length === 0">
        <h4
          class="title"
          style="width: 100%; text-align: center; margin: 32px 0"
        >
          No data found.
        </h4>
      </div>

      <!-- Table 吃 DataSource資料 -->
      <mat-table
        #table
        *ngIf="listDataSource.data.length > 0"
        [dataSource]="listDataSource"
        matSort
        (matSortChange)="sortChange($event)"
        multiTemplateDataRows
      >
        <!-- Equipment ID Column -->
        <ng-container matColumnDef="sourceId">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear="true"
            start="desc"
            class=""
            style="flex-grow: 1.5"
          >
            Equipment ID
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = dataIndex"
            class=""
            style="flex-grow: 1.5"
          >
            {{ dataFormatSvc.checkValDash(element.sourceId) }}
          </mat-cell>
        </ng-container>

        <!-- Equipment ID Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear="true"
            start="desc"
            class=""
            style="flex-grow: 0.7"
          >
            Status
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = dataIndex"
            class=""
            style="flex-grow: 0.7"
          >
            <div
              class="status_circle"
              [ngStyle]="{
                'background-color': getEquipmentStatusLight(element.status)
              }"
            ></div>
          </mat-cell>
        </ng-container>

        <!-- Equipment ID Column -->
        <ng-container matColumnDef="alarmCode">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear="true"
            start="desc"
            class=""
            style="flex-grow: 1"
          >
            Error Code
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = dataIndex"
            class=""
            style="flex-grow: 1"
          >
            {{ dataFormatSvc.checkValDash(element.alarmCode) }}
          </mat-cell>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="time">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear="true"
            start="desc"
            class=""
            style="flex-grow: 1.5"
          >
            Start Time
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = dataIndex"
            class=""
            style="flex-grow: 1.5"
          >
            {{
              dataFormatSvc.checkValDash(
                element.time === null
                  ? element.time
                  : dataFormatSvc.pipeDate(element.time, "yyyy/MM/dd HH:mm")
              )
            }}
          </mat-cell>
        </ng-container>

        <!-- 表頭 sticky: true => fix ".mat-header-row" -->
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-dialog-content>
