<h1 *ngIf="!hasUploadError && !hasUploadSuccess" mat-dialog-title class="title">
  {{ data.option }}
</h1>
<button
  *ngIf="!hasUploadError && !hasUploadSuccess"
  mat-icon-button
  mat-dialog-close
  class="close_btn"
>
  <mat-icon>close</mat-icon>
</button>
<!-- 初始狀態內容，選取上傳檔案 -->
<mat-dialog-content
  *ngIf="!hasUploadError && !hasUploadSuccess"
  class="content"
>
  <mat-form-field style="width: 100%">
    <mat-label>File Input</mat-label>
    <button mat-icon-button matPrefix (click)="f_input.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
    <input type="text" readonly matInput [formControl]="display" />
    <input
      type="file"
      accept=".csv"
      hidden
      #f_input
      (change)="handleFileInputChange(f_input.files)"
    />
    <mat-error>this field is required</mat-error>
  </mat-form-field>
</mat-dialog-content>
<!-- 上傳成功內容 -->
<mat-dialog-content *ngIf="hasUploadSuccess" class="info">
  <h2 class="info-title" style="margin-bottom: 10px">
    Your file was successfully uploaded
  </h2>
  <h3 class="info-content">
    Total renewed {{ fileRowNumber }} rows (including column header).<br />Your
    Kanbans were also renewed.
  </h3>
</mat-dialog-content>
<!-- 上傳失敗內容 -->
<mat-dialog-content *ngIf="hasUploadError" class="info">
  <h2 class="info-title" style="margin-bottom: 30px">Uploading Failed.</h2>
  <h3 class="info-content">Please check your csv. file again.</h3>
</mat-dialog-content>
<!-- 初始狀態按鈕 - Submit / Cancel -->
<mat-dialog-actions *ngIf="!hasUploadError && !hasUploadSuccess" class="footer">
  <button
    mat-button
    class="save_btn"
    [disabled]="display.invalid"
    (click)="handleSubmit()"
  >
    Submit
  </button>
  <button mat-button mat-dialog-close class="cancel_btn">Cancel</button>
</mat-dialog-actions>
<!-- 上傳成功按鈕 - Done  -->
<mat-dialog-actions *ngIf="hasUploadSuccess" class="footer">
  <button
    mat-button
    [mat-dialog-close]="selectedFile"
    style="color: #fff; background: #0e8e58"
  >
    Done
  </button>
</mat-dialog-actions>
<!-- 上傳失敗按鈕 - OK  -->
<mat-dialog-actions *ngIf="hasUploadError" class="footer">
  <button mat-button mat-dialog-close style="color: #fff; background: #d2574a">
    OK
  </button>
</mat-dialog-actions>
