import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from 'src/app/_services/apiSvc.service';

// import { map } from "lodash";
import * as _ from 'lodash';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';

// 設定 Docker 變數 => API URL
import { environment } from '../../../environments/environment';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { interval, Observable, fromEvent } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

// import variables
import { SORT_KEY, SORT_DIRECTION } from 'src/app/_variable/production-overview-types';

@Component({
  // 設定 changeDetection 屬性,當設定為 OnPush 時，
  // 只有在元件的 @Input 變更，且真正有變更時，才會進行變更偵測。
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-production-overview',
  templateUrl: './production-overview.component.html',
  styleUrls: ['./production-overview.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})

export class ProductionOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

  // sortBy = `${SORT_KEY.OrderStartTime}${SORT_DIRECTION.desc}`;
  // sortBy: {
  //   outsideSort: SORT_KEY;
  //   outsidDirection: SORT_DIRECTION;
  //   insideSort: SORT_KEY;
  //   insidDirection: SORT_DIRECTION;
  // } = {
  //     outsideSort: SORT_KEY.startTime,
  //     outsidDirection: SORT_DIRECTION.desc,
  //     insideSort: SORT_KEY.startTime,
  //     insidDirection: SORT_DIRECTION.desc
  //   };

  upDateTimer;
  // 計時器 30秒 Call API 更新資料
  rxTimer$ = interval(60000).subscribe((data) => {
    // console.log('interval data => ', `${data}`);
    this.isSvLoading.loading = true;
    this.getAllApiFn();
  });

  // Grafana ATE URL = '';
  gfAteUrl = 'http://Grafana/ATE:9002';
  gfSmtUrl = 'http://Grafana/SMT:9002';
  gfLoadingUrl = 'http://Grafana/Loading:9002';

  gfUrlMap = {
    ate: {
      line1: 'http://Grafana/ATE:9002'
    },
    smt: {
      line1: 'http://Grafana/SMT:9002'
    },
    loading: {
      line1: 'http://Grafana/Loading:9002'
    }
  };

  // dataSource: MatTableDataSource<UserData>;
  DATA = [];
  outDataSource = new MatTableDataSource(); // 初始化MatTable 表格資料
  usersData = [];
  expandedElement: any = {};

  // Select icon choices
  selected = false;
  newData = [];

  filterValue = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;


  // 第一層標題
  // ['Part No.', 'MO No.', 'Status', 'Start Time', 'End Time', 'Duration', 'MO Qty', 'Input Qty', 'Output Qty','Achieve Rate'];
  displayedColumns: string[] = ['action', 'product', 'order', 'orderStatus', 'startTime', 'endTime', 'productTime', 'targetOutput', 'input', 'output', 'achieveRate'];

  // 第二層標題
  // ['Line', 'Section', 'Side', 'Status', 'Start Time', 'End Time', 'Duration', 'MO Qty', 'Input Qty','Output Qty',Achieve Rate];
  innerDisplayedColumns: string[] = ['line', 'section', 'side', 'orderStatus', 'startTime', 'endTime', 'productTime', 'targetOutput', 'input', 'output', 'achieveRate'];


  isRuningOrder;
  spans = [];
  displayedColumnsArrIndex: string[] = ['product', 'order', 'orderStatus', 'startTime', 'endTime', 'productTime', 'targetOutput', 'input', 'output', 'achieveRate'];


  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,

    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isSvLoading.loading = true;
    this.gfAteUrl = environment.gfAtePath;
    this.gfSmtUrl = environment.gfSmtPath;
    this.gfLoadingUrl = environment.gfLoadingPath;
  }

  ngOnInit() {
    // this.getAllApiFn();
    this.getProductOverviewGrafanaAPI$().subscribe((x) => {
      console.log('getProductOverviewGrafanaAPI => ', x);
    });
  }

  ngAfterViewInit() {
    // 呼叫API => 將回傳陣列資料 傳進 MatTableDataSource 的 data
    this.getAllApiFn();

    this.outDataSource.paginator = this.paginator;
    this.outDataSource.sort = this.sort;
    this.outDataSource.sortingDataAccessor = (data, header) => data[header];
    this.outDataSource.filter = this.filterValue.trim().toString().toLowerCase();


    // Merge cell ( 以工單號檢查有無重複 )
    // this.cacheSpan('MoNo', d => d.MoNo);
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
    this.rxTimer$.unsubscribe();
  }

  // 子層展開
  expendClick(element) {
    const oldelement = element;
    // console.log('element:', oldelement);
    element.isExpanded = true;
    // this.saveElement = element;
    this.expandedElement = element;

    // 更新 Sort 排序資料 index
    this.changeDetectorRef.detectChanges();
    // console.log('this.innerTables:', this.innerTables);
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
      // ((table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index])
    );
  }

  // 子層 展開設定
  setChildExpanded(arr) {
    return arr.map((item) => {
      // set item expanded status
      item.isExpanded = false;
      item.selfID = item.product + '-' + item.order;
      return item;
    });
  }

  /**
   * step 1  ---  merge row array
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheSpan(key, accessor) {
    // console.log('cacheSpan this.DATA:', this.DATA);
    for (let i = 0; i < this.DATA.length;) {
      const currentValue = accessor(this.DATA[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.DATA.length; j++) {
        if (currentValue !== accessor(this.DATA[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
    this.isSvLoading.loading = false;
  }

  // step 2  ---  merge row array
  getRowSpan(col, index) {
    const result = this.spans[index] && this.spans[index][col];
    // console.log('result: *********************', result);
    return result;
  }

  // 觸發排序事件 merge cell function --- step 3
  sortData(element) {
    console.log('sortData element:', element);
    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }

  // 換頁觸發 Paginate Change
  onPaginateChange(e) {
    console.log('onPaginateChange e:', e);
    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }

  // Search input filter
  applyFilter(event: Event) {
    this.isSvLoading.loading = true;
    this.filterValue = (event.target as HTMLInputElement).value;
    this.outDataSource.filter = this.filterValue.trim().toString().toLowerCase();

    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }

  // subscribe 訂閱 陣列變動， 拿到 row data 更新排序的陣列
  subscribeDataSource() {
    return this.outDataSource.connect().pipe().subscribe((newSortArr) => {
      this.usersData = newSortArr;
    });
  }

  // null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }


  // Pipe 資料處理
  pipeTranslateInfo(deepCopyArr) {
    // const deepCopyArr = JSON.parse(JSON.stringify(arr));
    deepCopyArr.map((x) => {
      if (x.startTime !== '-') {
        x.startTime = this.datePipe.transform(x.startTime, 'yyyy/M/dd HH:mm');
      }
      if (x.endTime !== '-') {
        x.endTime = this.datePipe.transform(x.endTime, 'yyyy/M/dd HH:mm');
      }
      if (x.productTime !== '-') {
        x.formattedProductTime = this.numberPipe.transform(x.productTime / 86400, '0.1-1');
      }
      if (x.targetOutput !== '-') {
        x.formattedTargetOutput = this.numberPipe.transform(x.targetOutput);
      }
      if (x.input !== '-') {
        x.formattedInput = this.numberPipe.transform(x.input);
      }
      if (x.output !== '-') {
        x.formattedOutput = this.numberPipe.transform(x.output);
      }
      if (x.achieveRate !== '-') {
        x.formattedAchieveRate = this.numberPipe.transform(x.achieveRate * 100, '0.2-2');
      }
      this.nullToDash(x.child);
      // 子層 Formater
      x.child.map((i) => {
        if (i.startTime !== '-') {
          i.startTime = this.datePipe.transform(i.startTime, 'yyyy/M/dd HH:mm');
        }
        if (i.endTime !== '-') {
          i.endTime = this.datePipe.transform(i.endTime, 'yyyy/M/dd HH:mm');
        }
        if (i.productTime !== '-') {
          i.formattedProductTime = this.numberPipe.transform(i.productTime / 86400, '0.1-1');
        }
        if (i.targetOutput !== '-') {
          i.formattedTargetOutput = this.numberPipe.transform(i.targetOutput);
        }
        if (i.input !== '-') {
          i.formattedInput = this.numberPipe.transform(i.input);
        }
        if (i.output !== '-') {
          i.formattedOutput = this.numberPipe.transform(i.output);
        }
        if (i.achieveRate !== '-') {
          i.formattedAchieveRate = this.numberPipe.transform(i.achieveRate * 100, '0.2-2');
        }
      });
      return x;
    });
    return deepCopyArr;
  }


  // Mappin 目前 running 中的工單，並加上 Grafana 連結
  mappingGrafanaLink(arr) {
    arr.map((x) => {
      if (x.Section === 'ATE') {
        x.gfUrlPath = this.gfAteUrl + '&var-MO=' + x.MoNo.split('(')[0];
      }
      if (x.Section === 'SMT') {
        this.isRuningOrder.map((item) => {
          const isMONo = x.MoNo.split('(')[0];
          if (item.order === isMONo) {
            x.gfUrlPath = this.gfSmtUrl;
          }
        });
      }
      if (x.Section === 'Loading') {
        const isMONo = x.MoNo.split('(')[0];
        this.isRuningOrder.map((item) => {
          if (item.order === isMONo) {
            x.gfUrlPath = this.gfLoadingUrl;
          }
        });
      }
      return x;
    });

    return arr;
  }

  // select click icon
  selectClickIcon() {
    this.selected = !this.selected;
    // console.log(this.selected);
  }

  // click checkbox
  selectCheckbox(e) {
    // console.log('e:checked ====>', e.target.value);
    // console.log('e:checked ====>', e.target.checked);
    const getValue = e.target.value;
    if (e.target.checked) {
      // e.target.checked = false;
      this.displayedColumns.push(getValue);
      this.displayedColumns.sort((a, b) => {
        return this.displayedColumnsArrIndex.indexOf(a) - this.displayedColumnsArrIndex.indexOf(b);
      });
      // this.innerDisplayedColumns.push(getValue);
    }
    if (e.target.checked === false) {
      this.displayedColumns = this.displayedColumns.filter((item) => item !== getValue);
      // this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
    }
  }

  // build res.child Arr to new MatTableDataSource
  buildChildArrToMatTableData(res) {
    // empty orginal arr
    this.usersData = [];

    // 將要 子層陣列 轉化成 MatTableDataSource
    res.forEach((item) => {
      const checkIsArray = item.child.length > 0 && Array.isArray(item.child);
      if (checkIsArray) {
        this.usersData = [
          ...this.usersData, { ...item, child: new MatTableDataSource(item.child) },
        ];
      } else {
        this.usersData = [...this.usersData, item];
      }
    });

    // console.log('this.usersData:', this.usersData);
    // 將組合好的 usersData 塞進初始化 MatTableDataSource 完成的 outDataSource
    this.outDataSource.data = this.usersData;

    // 更新 Sort 排序資料 index
    this.changeDetectorRef.detectChanges();
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
    );

    return this.usersData;
  }

  // API Grafana 連結
  getProductOverviewGrafanaAPI$() {
    return this.apiSvc.getProductOverviewGrafanaLink().pipe(
      map(res => res)
      // map(res => { this.isRuningOrder = res; })
    );
    // [{ processSection: 'SMT', area: 'LineB', order: 'CE3AH15AL007' }];
  }

  // API Table row data
  getProductOverviewAPI$() {
    return this.apiSvc.getProductOverviewData().pipe(
      // shareReplay(1),
      tap(res => {
        console.log('row data res:', res);
      }),
      shareReplay(1),
      map(res => this.nullToDash(res)),
      map(res => this.pipeTranslateInfo(res)),
      map(res => this.setChildExpanded(res)),
      // tap(res => {
      //   console.log('getProductOverviewData res:', res);
      // }),
    );
  }

  // Call API
  getAllApiFn() {
    return this.getProductOverviewGrafanaAPI$().pipe(
      switchMap((res) => this.getProductOverviewAPI$()),
      map((res => this.buildChildArrToMatTableData(res))),
      tap(res => {
        console.log('getAllApiFn -> ', res);
      }),
    ).subscribe(
      (res) => {
        // this.subscribeDataSource();
        // changeDetectorRef detectChanges 監測 Dom 元素變更檢測
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          this.isSvLoading.loading = false;
        }, 800);
      }
    );
  }

}
