import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LunardateService } from './_services/lunardate.service';
import { Chart } from '../../node_modules/chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { Event, Router, NavigationEnd, ResolveStart, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SpinnerService } from './_services/spinner.service';

// import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'smart-factory';
  load;
  // isLoading;
  isLoading$: Observable<any> = this.isSvLoading.refreshLoading$;

  exampleStr: string;
  constructor(
    private router: Router,
    private activePoint: ActivatedRoute,
    public isSvLoading: SpinnerService,
    private daytoCN: LunardateService,
    private titleService: Title,
  ) {

  }

  ngOnInit() {

    const namedChartAnnotation = ChartAnnotation;
    namedChartAnnotation.id = 'annotation';
    Chart.pluginService.register(namedChartAnnotation);

    this.router.events.pipe(
      filter((event: Event) => event instanceof RoutesRecognized),
      map((event: RoutesRecognized) => {
        let data = null;
        let route = event.state.root;

        while (route) {
          data = route.data || data;
          route = route.firstChild;
        }

        return data.title;
      }),
    ).subscribe((ttl: string) => {
      this.titleService.setTitle(ttl + ' - Smart Factory');
    });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   if (event.url === '/smartFactory/web/menu' || event.url === '/menu') {
    //     // console.log('event.url:', event.url);
    //     // this.isSvLoading.loading = true;
    //     // setTimeout(() => {
    //     //   this.isSvLoading.loading = false;
    //     // }, 500);
    //     return;
    //   } else {
    //     // console.log('event.url:', event.url);
    //     this.isSvLoading.loading = true;
    //     // setTimeout(() => {
    //     //   this.isSvLoading.loading = false;
    //     // }, 3000);
    //   }
    // });


    // plugins 的 pl , 顯示kwh
    Chart.pluginService.register({
      id: 'p1',
      beforeDraw(chart) {
        // tslint:disable-next-line: one-variable-per-declaration
        const width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'white';

        // tslint:disable-next-line: one-variable-per-declaration
        const text = 'kWh',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    });

    Chart.defaults.LineWithLine = Chart.defaults.line;
    Chart.controllers.LineWithLine = Chart.controllers.line.extend({
      draw(ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          // tslint:disable-next-line: one-variable-per-declaration
          const activePoint = this.chart.tooltip._active[0],
            ctx = this.chart.ctx,
            x = activePoint.tooltipPosition().x,
            topY = this.chart.scales['y-axis-0'].top,
            bottomY = this.chart.scales['y-axis-0'].bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = 'rgb(255, 0, 0)';
          ctx.stroke();
          ctx.restore();
        }
      }
    });

    this.daytoCN.getChineseDay();
  }
}
