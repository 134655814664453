import { Injectable } from '@angular/core';
import { ApiService } from './apiSvc.service';

@Injectable({
  providedIn: 'root'
})
export class LunardateService {
  words = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
  adds = ['', '十', '百', '千', '萬', '億', '十', '百', '千'];

  chineseDay;
  constructor(public apiSvc: ApiService) { }

  changeToCN(num: number) {
    if (this.words[num]) {
      return this.words[num];
    } else if (num > 10 && num < 20) {
      const numStr = num.toString();
      const n = numStr.substring(1, 2);
      const result = this.adds[1] + this.words[n];
      return result;
    } else if (num > 10) {
      let result = '';
      const numStr = num.toString();
      for (let i = 0; i < numStr.length; ++i) {
        const n = numStr.substring(i, i + 1);
        const m = numStr.length - i - 1;
        if (n === '0' && m === 0) { break; }
        result += this.words[n] + this.adds[m];
      }
      return result;
    } else {
      return '零';
    }
  }

  getChineseDay() { }
}
