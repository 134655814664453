<div
  class="custom-progress-bar-wrapper"
  [style.height]="height"
  [style.backgroundColor]="bgColor"
>
  <div
    [style.backgroundColor]="color"
    [style.width]="value+'%'"
  >
  </div>
</div>
