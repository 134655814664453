import { interval, Subscription } from 'rxjs';
import { map, tap, } from 'rxjs/operators';
import { OnDestroy, OnInit, AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { MatPaginator } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/apiSvc.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { DataFormatService } from '../_services/data-format.service';
import * as moment from 'moment';
import * as _ from 'lodash';

export interface PeriodicElement {
  floor: string;
  equipCode: string;
  equipName: string;
  runLight: boolean;
  idleLight: boolean;
  errorLight: boolean;
  downTime: string;
  runRate: string;
  idleRate: string;
  downRate: string; // string number
  pic: string;
  errorCode: string;
}


/**
 * @title Table with sorting
 */
@Component({
  selector: 'app-phase3-ate',
  templateUrl: './phase3-ate.component.html',
  styleUrls: ['./phase3-ate.component.scss'],
})

export class Phase3AteComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    private route: ActivatedRoute,
    private router: Router,
    public dataFormatSvc: DataFormatService,
  ) {
    this.isSvLoading.loading = true;
  }
  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  isMenuOpen = false;
  isLogin = false;

  displayedColumns: string[] = ['floor', 'deviceType', 'deviceName', 'runLight', 'idleLight', 'errorLight', 'runRate', 'idleRate', 'downRate', 'downTime', 'pic', 'errorCode'];
  dataSource = new MatTableDataSource();
  List = {
    offset: 0,
    total: 0,
    sort: 'downTime',
    descending: true,
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedMoments = [new Date(), new Date()];
  minDate = new Date(2021, 6, 1, 0, 0);
  maxDate = moment().startOf('day').add(1, 'days').toDate();
  isDateTimePickerDefault = true;
  updatingDataSubscription: Subscription | null = null;
  apiParamsObj = {
    type: null,
    sectionName: null || 'TEST',
    selectOptionName: null,
    countRankingSelectEquip: null,
    timeRankingSelectEquip: null,
    startTime: null,
    endTime: null,
  };

  sectionNameArr = ['SMT', 'LOD', 'TEST'];
  sectionDisplayMappingObj = {
    SMT: 'SMT',
    LOD: 'LOD',
    TEST: 'Equipments'
  };

  FOptArr = ['All', '1F', '2F', '3F', '4F', '5F', '6F'];
  DTArr = [];
  FOptTmp = '';
  DTTmp = 'all';

  ngOnInit(): void {
    this.getUrlParam();
    if (this.isDateTimePickerDefault) { this.initialDateTime(); }
    else { this.selectedMoments = [moment(this.apiParamsObj.startTime).toDate(), moment(this.apiParamsObj.endTime).toDate()]; }
    this.rxTimerStart();
    this.SWSctOptF('All');
  }
  SWSctOptF(s: string) {
    if (s === 'All') { s = 'all'; }
    this.FOptTmp = s;
    this.DTArr = ['-'];
    this.apiSvc.getAtef(s).subscribe(d => {
      this.DTArr = d;
      d.unshift('All');
      this.SWSctOptDT(d[0]);
    });
  }
  SWSctOptDT(s: string) {
    this.isSvLoading.loading = true;
    this.DTTmp = s;
    this.List.offset = 0;
    this.Sct();
    // console.log('DT',this.apiParamsObj.startTime,this.apiParamsObj.endTime,this.FOptTmp,this.DTTmp);
  }
  onChangePage(s) {
    this.isSvLoading.loading = true;
    this.List.offset = s.pageIndex * 12;
    this.Sct();
  }
  SctSort(e) {
    this.isSvLoading.loading = true;
    this.List.offset = 0;
    this.List.sort = e.active;
    this.List.descending = e.direction === 'desc' ? true : false;
    this.Sct();
  }
  Sct() {
    this.renewedTime = moment().valueOf();
    this.apiSvc.getAte(this.apiParamsObj.startTime, this.apiParamsObj.endTime, this.FOptTmp, this.DTTmp, this.List.sort, this.List.descending, this.List.offset, 12).subscribe(d => {
      this.snapshotTime = this.renewedTime;
      this.dataSource.data = d?.data;
      // this.List.offset=d?.offset;
      this.List.total = d?.total;
      this.isSvLoading.loading = false;
      console.log('Sct DT', d);
    });
  }

  /*
    參數
    startTime:
    endTime:
    offset: 頁碼
    limit: 預設12，一頁幾項
    sortBy: equipCode, equipName, nrunLight, idleLight, errorLight, downTime, runRate, idleRate, downRate

    加上了floor, deviceType, PIC, ErrorCode欄位
    參數 {startTime, endTime, floor, deviceType, sort, descending, offset, limit}
    篩選器 floor, deviceType
    sort: 可以用floor, deviceType, deviceName, runLight, idleLight, errorLight, runRate, idleRate, downRate, downTime
    descending: 降冪 true/false
    */

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = false;

    this.selectedMoments = this.clearDateTimeMinites(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeEqual(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeLimit(this.selectedMoments);
    console.log('時間篩選器 選取時間 過濾後:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
    this.apiParamsObj.startTime = this.selectedMoments[0].valueOf();
    this.apiParamsObj.endTime = this.selectedMoments[1].valueOf();
    this.Sct();
  }

  // Default DateRangePicker 設定 預設的 時間, 24小時內的資料
  defaultDateTime() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = true;
    this.initialDateTime();
    this.List.offset = 0;
    this.Sct();
  }

  // 初始化時間, 24小時內
  initialDateTime() {
    const currentHour = moment().get('hour');
    const endTime = moment().startOf('day').set('hour', currentHour).add(1, 'hours');
    const startTime = _.cloneDeep(endTime).subtract(1, 'day');
    this.selectedMoments = [startTime.toDate(), endTime.toDate()];
    console.log('時間篩選器 預設時間:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
    this.apiParamsObj.startTime = startTime.valueOf();
    this.apiParamsObj.endTime = endTime.valueOf();
  }


  // 檢查選到的時間區間是否相同, 若相同取其時間往前24小時的時間區間
  checkDateTimeRangeEqual(timeArray) {
    if (timeArray[0].valueOf() === timeArray[1].valueOf()) {
      timeArray[0] = moment(timeArray[0]).subtract(1, 'day').toDate();
    }
    return timeArray;
  }

  // 將所選時間的分鐘歸零
  clearDateTimeMinites(timeArray) {
    return timeArray.map(item => {
      return item = moment(item).set('minute', 0).toDate();
    });
  }

  // 檢查所選時間區間是否超過 31 天, 若超過將 endTime 改為 startTime + 31 天
  checkDateTimeRangeLimit(timeArray) {
    const startTime = moment(timeArray[0]);
    let endTime = moment(timeArray[1]);
    const dayDiff = endTime.diff(startTime, 'days', true);   // true => 取到小數位
    console.log('dayDiff:', dayDiff);
    if (dayDiff > 31) {
      const hour = startTime.get('hour');
      endTime = _.cloneDeep(startTime).add(31, 'day').set('hour', hour);
      timeArray[0] = startTime.toDate();
      timeArray[1] = endTime.toDate();
    }
    return timeArray;
  }

  // 設定 DateTimePicker 可被選的時間區間, 從 2021/07/01 00:00 到 明天 00:00
  defaultDateTimeRange() {
    this.minDate = new Date(2021, 6, 1, 0, 0);
    this.maxDate = moment().startOf('day').add(1, 'days').toDate();
  }

  // 切換 Section dropdown
  switchSection(selectedSection: string) {
    const paramsObj = this.isDateTimePickerDefault ? { queryParams: { section: selectedSection } } : { queryParams: { section: selectedSection, startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime, } };
    if (selectedSection === 'SMT') {
      this.router.navigate(['/smartFactory/web/equip-alarm-all'], { ...paramsObj });
      return;
    }
    if (selectedSection === 'LOD') {
      this.router.navigate(['/smartFactory/web/equip-alarm-all'], { ...paramsObj });
      return;
    }
  }

  // Url 參數設定
  getUrlParam() {
    this.apiParamsObj.startTime = this.route.snapshot.queryParamMap.get('startTime');
    this.apiParamsObj.endTime = this.route.snapshot.queryParamMap.get('endTime');
    if (this.apiParamsObj.startTime && this.apiParamsObj.endTime) {
      this.isDateTimePickerDefault = false;
      this.apiParamsObj.startTime = Number(this.apiParamsObj.startTime);
      this.apiParamsObj.endTime = Number(this.apiParamsObj.endTime);
    }
  }


  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }
  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.Sct();
    });
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.Sct();
  }

}
