export enum FontColor {
  WHITE = '#FFFFFF',
  RED = '#FF3A3A',
  GREY = '#BABABA',
}

export enum BarChartColor {
  PURPLE = '#AC4DA5',
  BLUE = '#3A78BB',
  RED = '#D2574A',
  ORANGE = '#E1894C',
  CYAN = '#86CEDE',
}

export enum ProgressBarColor {
  GREY = '#484848',
  BLUE = '#13557A',
}

export enum BorderColor {
  GREY = '#868686',
}

export enum Common {
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  BACKGROUND = '#2A2A2A',
  DROPDOWN = '#4B4B4B',
  INPUT = '#4B4B4B',
  BANNER = '#383838',
  NESTED_TABLE = '#C4C4C4',
  POPUP_TABLE = '#383838',
}
