import { group } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, timeout, } from 'rxjs/operators';


// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { DataFormatService } from '../_services/data-format.service';
import { SpinnerService } from '../_services/spinner.service';
import { interval } from 'rxjs';

// interface
import { AteApiData } from '../core/models/ateEquipEfficiency.model';
// forTestData
import { ForTestDataService } from '../_services/for-test-data.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
// Getting route information
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { type } from 'os';
import { words } from 'lodash';

type wipData = {
  mo: string;
  product: string;
  targetOutput: number;
  line: string[];
  startTime: number;
  daysRemainingUntilClosing: number;
  routeInfo: wipItem[];
};

type wipItem = {
  status: number;
  output: number;
  index: string;
  groupName: string;
};

@Component({
  selector: 'app-phase3-wip-overview',
  templateUrl: './phase3-wip-overview.component.html',
  styleUrls: ['./phase3-wip-overview.component.scss']
})

export class Phase3WipOverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly canGoBack: boolean;

  constructor(
    public clock: RealTimeClockService,
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public testData: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // check previousNavigation object is null or not
    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
    this.isSvLoading.loading = true;
  }

  MOCK_DATA: wipData;

  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  paramMo: string;

  wipApiData: wipData = {
    mo: null,
    product: null,
    targetOutput: null,
    line: [],
    startTime: null,
    daysRemainingUntilClosing: null,
    routeInfo: []
  };
  wipItemArray = [];

  private rxTimerStop() {
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe(() => {
      this.isSvLoading.loading = true;
      this.subscribeApiWipData();
    });
  }

  backClicked() {
    if (this.canGoBack) {
      this.location.back();
    } else {
      this.router.navigate(['/menu']);
    }
  }

  ngOnInit(): void {
    this.paramMo = this.route.snapshot.queryParamMap.get('mo');
    this.rxTimerStart();
    this.subscribeApiWipData();
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  remainingBgColor(num: number) {
    if (num === null) { return 'transparent'; }
    else if (num === 2) { return '#0e8e58'; }  // 剩下2~3天-綠燈, 呈現[+2]
    else if (num === 1) { return '#E3B953'; }  // 剩下1~2天-黃燈, 呈現[+1]
    else if (num <= 0) { return '#FF3A3A'; }  // 剩下0~1天-紅燈, 呈現[+0], 超時-紅燈, 呈現[-1~無限大]
    else { return 'transparent'; }
  }

  wipStatusColor(status: number) {
    if (status === 1) { return { 'background-color': '#3A71FF', color: '#FFF' }; }  // 藍燈:必過站，生產完成(當工單生產數量與目標生產 數量一致)
    else if (status === 2) { return { 'background-color': '#0E8E58', color: '#FFF' }; }  // 綠燈:必過站，生產中(當工單生產數量小於目標生產 數量一致)
    else if (status === 3) { return { 'background-color': '#FFFFFF', color: '#1D1D1D' }; }  // 白燈:必過站，尚未進行投產(當工單生產數量=0)
    else if (status === 4) { return { 'background-color': '#404040', color: '#505050' }; }  // 深灰色 : 非必過站(無生產資料)
    else { return { 'background-color': '#1d1d1d', color: '#505050', border: '1px solid #505050' }; }  // undefined status
  }

  productFontSize() {
    if (this.wipApiData.product === null) { return; }
    if (this.wipApiData.product.length >= 15 && this.wipApiData.line.length >= 5) { return { 'font-size': '24px' }; }
  }

  lineFontSize() {
    if (this.wipApiData.line.length === 0) { return; }
    const stringLength = this.wipApiData.line.join(', ').length;
    if (stringLength > 35 && stringLength <= 45) { return { 'font-size': '24px', 'line-height': '35px' }; }
    else if (stringLength > 45 && stringLength <= 70) { return { 'font-size': '24px', 'line-height': '26px' }; }
    else if (stringLength > 70) { return { 'font-size': '22px', 'line-height': '24.5px' }; }
  }

  groupNameFormat(str: string) {
    if (!str) { return; }
    let id = '';
    let name = '';
    const words = str.split(' ');
    if (_.isFinite(+words[0])) {
      id = words[0];
      for (let i = 1; i < words.length; i++) {
        name += words[i] + ' ';
      }
      return { groupId: id, groupName: name };
    } else {
      return { groupId: null, groupName: str };
    }
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  subscribeApiWipData() {
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    this.renewedTime = moment().valueOf();
    this.apiSvc.getPhase3WipOverviewData(this.paramMo).pipe(
      timeout(this.intervalSeconds * 0.9 * 1000),
      tap((res: wipData) => {
        console.log('wipData res:', res);
        this.snapshotTime = this.renewedTime;
        this.wipApiData = res;
        this.wipItemArray = _.chunk(this.wipApiData.routeInfo, 10);
        this.wipItemArray = this.wipItemArray.map(ary => {
          return ary.map((item) => {
            item.groupName = this.groupNameFormat(item.groupName);
            return item;
          });
        });
      })
    ).subscribe({
      error: error => {
        this.isSvLoading.loading = false;
        console.log('catchError: 錯誤 - ', error);
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.subscribeApiWipData();
  }

}
