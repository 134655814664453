import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';
import { timer, Subject, interval, } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// angular-material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import numeral from 'numeral';

// interface
import { EquipEfficiencyOverview, Info } from '../core/models/equipEfficiencyOverview.model';
import { EfficiencyApiData, DetailListType } from '../core/models/popupInfo.model';

@Component({
  selector: 'app-equipment-efficiency-overview',
  templateUrl: './equipment-efficiency-overview.component.html',
  styleUrls: ['./equipment-efficiency-overview.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class EquipmentEfficiencyOverviewComponent implements OnInit, OnDestroy {


  subject = new Subject();
  pageAutoRefresh = true;
  intervalSeconds = 60;
  rxTimer$;

  changeSectionName;
  isNowSectionName: string;
  pingSaveLineName: string;
  sectionNameArr = [];

  equipsLightsObj = {
    // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
    0: 'green',
    1: 'yellow',
    2: 'red',
  };

  nowPageNum = 1;
  isTotalPageNum = 1;  // from api response totalPage

  resLineNameArr;

  resItemDataArr: Info[] = [];
  // resItemDataArr = [
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line A',
  //     sn: 'DE1BH01QL004 (A)',
  //     product: 'RLOD-PBH0014C0G-44',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '999',
  //     output: '873',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.0',
  //     actualTime: '10.2',
  //     estEndTime: 1634092810384,
  //     efficiency: '1.060',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: false,
  //     // processSection: 'SMT',
  //     line: 'Line B',
  //     sn: 'DE1BH01QL005 (B)',
  //     product: 'RLOD-PBH0014C0G-44',
  //     side: 'B',
  //     targetOutput: '12000',
  //     input: '1000',
  //     output: '1000',
  //     achieveRate: '97.30',
  //     totalItems: 6,
  //     standardTime: '10.0',
  //     actualTime: '10.2',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.888',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.974',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.0524',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.9624',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '1.1524',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.524',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.241',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '0.224',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '1.124',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  //   {
  //     compareEstEndTime: true,
  //     // processSection: 'SMT',
  //     line: 'Line C',
  //     sn: 'DE1BH01QL114 (C)',
  //     product: 'RLOD-PBH0014C0G-33',
  //     side: 'A',
  //     targetOutput: '12000',
  //     input: '2500',
  //     output: '1900',
  //     achieveRate: '97.30',
  //     totalItems: 3,
  //     standardTime: '10.01',
  //     actualTime: '10.22',
  //     estEndTime: 1634092810384,
  //     efficiency: '1.524',
  //     equips: [
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'yellow',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'red',
  //         name: 'CM2070-2',
  //       },
  //       {
  //         status: 'green',
  //         name: 'CM2070-33',
  //       },
  //     ]
  //   },
  // ];

  openPopup = false;
  popupMode = '1'; // 0 default, 1 pin, 2 cancel

  timerFn;
  isSaveSec;
  choiceSecShow;
  swapSecArr = [
    {
      secNum: 'Stop',
      isActive: false,
    },
    {
      secNum: 30,
      isActive: false,
    },
    {
      secNum: 60,
      isActive: false,
    },
    {
      secNum: 180,
      isActive: false,
    }
  ];

  // 選到的 Name.toString()
  apiEncodeFilterStr: string;
  apiEncodeFilterStackStr: string;

  // 搜集選到的 Name
  // selectSectionNameArr = [
  //   {
  //     section: 'SMT', line: 'SMT-A'
  //   },
  //   {
  //     section: 'SMT', line: 'SMT-B'
  //   },
  //   {
  //     section: 'SMT', line: 'SMT-C'
  //   },
  //   {
  //     section: 'PKG', line: 'PACK-A'
  //   }
  // ];
  selectSectionNameArr = [];
  selectSectionNameStackArr = []; // stack 暫存狀態用
  // selectSectionNameArr = [ {section: 'SMT,line: SMT-A},{ section: SMT, line: SMT - B }]

  viewStackLineNameArr = []; // stack 暫存狀態用
  viewLineNameStatusArr = [
    {
      section: 'SMT',
      sectionSelected: false,
      lines: [
        {
          lineName: 'SMT-AASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-DASY-CASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'Loaing',
      sectionSelected: false,
      lines: [
        {
          lineName: 'LOD-A',
          isChecked: false,
        },
        {
          lineName: 'LOD-BASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'LOD-C',
          isChecked: false,
        },
        {
          lineName: 'LOD-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'ASY',
      sectionSelected: false,
      lines: [
        {
          lineName: 'ASY-A',
          isChecked: false,
        },
        {
          lineName: 'ASY-BASY-CASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'PKG',
      sectionSelected: false,
      lines: [
        {
          lineName: 'PKG-A',
          isChecked: false,
        },
        {
          lineName: 'PKG-B',
          isChecked: false,
        },
        {
          lineName: 'PKG-C',
          isChecked: false,
        },
        {
          lineName: 'PKG-D',
          isChecked: false,
        },
      ]
    }
  ];


  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,
    // route
    private route: ActivatedRoute,
    private router: Router,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;
  }

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.subject.next();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    return this.timerFn = timer(0, 60 * 1000).pipe(
      takeUntil(this.subject)
    ).subscribe(
      (t) => {
        console.log(`計時器 sec => 60 ,`, t, '次數');
        // Call API
        this.checkSaveFilterLineNameArrLength();
        // console.log('計時器 －－－', this.apiEncodeFilterStr);
        this.getAllApiFn(this.apiEncodeFilterStackStr, this.nowPageNum, 3);
      }
    );
  }

  ngOnInit(): void {
    // this.getAllSectionName$().subscribe();
    // this.clickWipTitle('SMT');
    // this.getAllApiFn(null, 1, 3);
    this.swapSecs(30);
  }

  ngOnDestroy() {
    this.timerFn.unsubscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // 設定計時 & Call API
  secTimer(sec) {
    this.isSaveSec = sec;
    console.log('this.isSaveSec:', this.isSaveSec);
    const isSecStop = sec === 'Stop';
    const isSecSwitch = sec !== 'Stop';

    if (isSecStop) {
      console.log('sec:', sec);
      this.pageAutoRefresh = true;
      this.rxTimerStart();
      return;
    }

    if (isSecSwitch) {
      this.timerFn = timer(0, sec * 1000).pipe(
        takeUntil(this.subject)
      ).subscribe(
        (t) => {
          console.log(`計時器 sec => ${sec} ,`, t, '次數');
          // this.rxTimerStop();
          this.swapPageNum('+1');
          this.slidePageNum(this.nowPageNum);

          // this.saveIsCheckLineNameStatus('Yes');
          // this.openPopup = false;
          // Call API
          this.checkSaveFilterLineNameArrLength();
          // console.log('計時器 －－－', this.apiEncodeFilterStr);
          // this.getAllApiFn(this.apiEncodeFilterStr, this.nowPageNum, 3);
          this.getAllApiFn(this.apiEncodeFilterStackStr, this.nowPageNum, 3);
        }
      );
    }
  }

  // 切換秒數
  swapSecs(sec) {
    for (const item of this.swapSecArr) {
      if (sec === item.secNum) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    }
    this.rxTimerStop();

    console.log('swapSecs to sec:', sec);
    this.secTimer(sec);
  }

  // 分頁切換
  swapPageNum(str) {
    if (str === '+1') { this.nowPageNum++; }
    if (str === '-1') { this.nowPageNum--; }
  }

  // 確認輪播分頁
  slidePageNum(num) {
    if (num > this.isTotalPageNum) { this.nowPageNum = 1; }
    // if (num < 0) { this.nowPageNum = 1; }
    console.log('num:', num);
    console.log('this.nowPageNum:', this.nowPageNum);
  }

  // 檢查當前頁數 有沒有 大於總頁數／大於總頁數時顯示最後一頁
  checkPageNum() {
    // 目前頁數大於總頁數，顯示最後一頁
    if (this.nowPageNum > this.isTotalPageNum) { this.nowPageNum = this.isTotalPageNum; }
  }

  // 檢查 efficiency &  Add class color
  efficiencyFontColor(efficiency) {
    efficiency = efficiency.replace(/[,]+/g, '');  // 移除千分位符號
    if (efficiency === '-') { return; }
    if (efficiency < 98) { return 'box_red'; }
    if (efficiency > 102) { return 'box_yellow'; }
  }

  // Efficiency pop-up event
  onClickEfficiency(element) {
    console.log('onClickEfficiency: ', element);
    this.dialog.open(EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '920px',
      maxHeight: 'calc(100vh, 32px)',
      data: {
        efficiency: element.efficiency,
        cycleTime: element.cycleTime,
        output: element.output,
        stdLineChangeTime: element.stdLineChangeTime,
        operator: element.operator,

        durationList: element.details[0].durationList,
        restTimeInAct: element.details[0].restTimeInAct,
        restTimeInChangeover: element.details[0].restTimeInChangeover,
        changeOverList: element.details[0].changeOverList,
        nonProdTimeListByAct: element.details[0].nonProdTimeListByAct,
        nonProdTimeListByChangeover: element.details[0].nonProdTimeListByChangeover,
        nonFixedExceptionList: element.details[0].nonFixedExceptionList,
        rdProduction: element.details[0].rdProduction,
        nonBarcodeProduction: element.details[0].nonBarcodeProduction,
      },
    });
  }

  // 檢查 estEndTime &  Add class color
  estEndTimeFontColor(estEndTime) {
    if (estEndTime === '-') { return; }
    const nowTime = new Date().getTime();
    return nowTime > estEndTime ? 'box_red' : '';
  }

  mappingEquipStatus(arr) {
    // equipsLightsObj = {
    //   // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
    //   0: 'green',
    //   1: 'yellow',
    //   2: 'red',
    // };
    return arr.map((item) => ({
      ...item,
      status: this.equipsLightsObj[item.status]
    }));
  }

  // 暫時顯示的 selectCheckbox 狀態, 按下 yes 才真正更新／ no 不更新
  selectCheckbox(section, line) {
    console.log('section, line:', section, line);

    // click 切換 checkbox 狀態 true/false
    this.viewLineNameStatusArr.map((x) => {
      x.lines.map((item) => {
        // 改變 checkbox 狀態 true/false
        if (x.section === section && item.lineName === line) {
          item.isChecked = !item.isChecked;
        }
      });
    });

    // isChecked true => push 進 selectSectionNameArr / push 前清空陣列
    this.selectSectionNameArr = [];
    this.viewLineNameStatusArr.map((x) => {
      x.lines.map((item) => {
        if (item.isChecked) {
          this.selectSectionNameArr.push({
            section: x.section,
            line: item.lineName
          });
        }
      });
    });
  }

  // 暫存目前 勾選狀態
  saveIsCheckLineNameStatus(str) {
    if (str === 'Yes') {
      this.nowPageNum = 1;
      this.viewStackLineNameArr = JSON.parse(JSON.stringify(this.viewLineNameStatusArr));
      this.selectSectionNameStackArr = JSON.parse(JSON.stringify(this.selectSectionNameArr));
    }
    if (str === 'cancel') {
      this.viewLineNameStatusArr = JSON.parse(JSON.stringify(this.resLineNameArr));
      this.selectSectionNameArr = JSON.parse(JSON.stringify(this.selectSectionNameStackArr));
    }
  }

  // mapping lineName checkbox
  mappingLineNameCheckbox(arr) {
    this.resLineNameArr = [];

    // 給 線別加狀態 line checked
    this.resLineNameArr = arr.map((x) => {
      x.lines = x.lines.map((item) => {
        let includeLineName = null;
        // 與目前暫存的 checked狀態 重新比對
        includeLineName = this.selectSectionNameArr.some(i => x.section === i.section && item === i.line);
        const obj = {
          lineName: item,
          isChecked: includeLineName,
        };
        return obj;
      });
      return x;
    });
    // 給 section加狀態 line checked
    console.log('API回傳線別陣列:', this.resLineNameArr);

    // deepCopyArr
    this.viewLineNameStatusArr = JSON.parse(JSON.stringify(this.resLineNameArr));
    this.viewStackLineNameArr = JSON.parse(JSON.stringify(this.viewLineNameStatusArr));
  }

  // Encode 判斷選到的 Name 陣列有沒有資料,
  checkSaveFilterLineNameArrLength() {
    this.apiEncodeFilterStr = this.selectSectionNameArr.length === 0 ? null : JSON.stringify(this.selectSectionNameArr);
    this.apiEncodeFilterStackStr = this.selectSectionNameStackArr.length === 0 ? null : JSON.stringify(this.selectSectionNameStackArr);
  }


  // Pipe 資料處理
  pipeTranslateInfo(arr) {
    const deepCopyArr = JSON.parse(JSON.stringify(arr));
    deepCopyArr.map((x) => {
      x.totalItems = x.equips.length;
      x.compareEstEndTime = x.estEndTime;
      if (x.equips === '-') {
        x.equips = [
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          }
        ];
      }
      if (x.equips !== '-') {
        x.equips = this.mappingEquipStatus(x.equips);
        // console.log('x.equips:', x.equips);
      }
      if (x.targetOutput !== '-') {
        x.targetOutput = this.numberPipe.transform(x.targetOutput);
      }
      if (x.input !== '-') {
        x.input = this.numberPipe.transform(x.input);
      }
      if (x.moInput !== '-') {
        x.moInput = this.numberPipe.transform(x.moInput);
      }
      if (x.output !== '-') {
        x.output = this.numberPipe.transform(x.output);
      }
      if (x.moOutput !== '-') {
        x.moOutput = this.numberPipe.transform(x.moOutput);
      }
      if (x.achieveRate !== '-') {
        x.achieveRate = this.numberPipe.transform(x.achieveRate * 100, '0.2-2');
      }
      if (x.moAchieveRate !== '-') {
        x.moAchieveRate = this.numberPipe.transform(x.moAchieveRate * 100, '0.2-2');
      }
      if (x.standardTime !== '-') {
        x.formattedStandardTime = this.dataFormatSvc.secToDDhhmmss(x.standardTime);
      }
      // https://issue.ccau.co.th/issues/1576
      if (x.actProductionTime !== 0) {
        x.formattedActProductionTime = this.dataFormatSvc.secToDDhhmmss(x.actProductionTime);
      }
      else {
        const { section, input, output } = x;
        if (section === 'SMT') {
          x.formattedActProductionTime = (output === 0 || output === '-') ? '-' : this.dataFormatSvc.secToDDhhmmss(0);
        }
        if (section === 'LOD' || section === 'PKG' || section === 'ASY') {
          x.formattedActProductionTime = (input === 0 || input === '-') ? '-' : this.dataFormatSvc.secToDDhhmmss(0);
        }
      }
      if (x.estEndTime !== '-') {
        x.estEndTime = this.datePipe.transform(new Date(x.estEndTime), 'yyyy/M/dd HH:mm:ss');
      }
      if (x.efficiency !== '-') {
        x.efficiency = this.numberPipe.transform(x.efficiency * 100, '0.2-2');
      }
    });
    return deepCopyArr;
  }

  // API get all sectionName
  getAllSectionName$() {
    return this.apiSvc.getSectionData().pipe(
      tap(res => {
        // console.log('getAllSectionName row data res:', res);
        this.sectionNameArr = [];
        res.map((x) => {
          this.sectionNameArr.push({
            sectionName: x,
            isActive: false,
          });
        });
      }),
    );
  }

  // API get all lineName
  getAllLineName$() {
    return this.apiSvc.getAllLineNameData().pipe(
      tap(res => {
        console.log('getAllLineName row data res:', res);
        this.isSvLoading.loading = true;
      }),
    );
  }

  // API Card Data
  getCardData$(section, offset, limit) {
    return this.apiSvc.getEquipOverviewData(section, offset, limit).pipe(
      tap(res => {
        // console.log('getCardData row data res:', res);
      }),
    );
  }

  // API All
  getAllApiFn(section, offset, limit) {
    return this.getAllLineName$().pipe(
      tap((res) => {
        // res = JSON.parse(JSON.stringify(this.forTestDataSvc.allLinesArr));
        this.mappingLineNameCheckbox(res);
      }),
      switchMap((res) => this.getCardData$(section, offset, limit)),
    ).subscribe((res: EquipEfficiencyOverview) => {
      console.log('getAllApiFn res:', res);
      if (res.totalPage === 0) { res.totalPage++; }

      if (res.infos.length !== 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        this.resItemDataArr = this.dataFormatSvc.nullToDash(this.resItemDataArr);
        this.resItemDataArr = this.pipeTranslateInfo(this.resItemDataArr);
      }

      if (res.infos.length === 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        // this.resItemDataArr
        console.log('this.resItemDataArr:', this.resItemDataArr);
      }

      this.isSvLoading.loading = false;
    });
  }

}

@Component({
  selector: 'app-efficiency-api-data-dialog',
  templateUrl: './dialog/app-efficiency-api-data-dialog.component.html',
  styleUrls: ['./dialog/app-efficiency-api-data-dialog.component.scss']
})
export class EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent implements OnInit {
  constructor(
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent>,
    public dialogEmployee: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EfficiencyApiData,
  ) { }

  EfficiencyData: EfficiencyApiData = {
    efficiency: null,
    cycleTime: null,
    output: null,
    stdLineChangeTime: null,
    operator: null,

    durationList: null,
    restTimeInAct: null,
    restTimeInChangeover: null,
    changeOverList: null,
    nonProdTimeListByAct: null,
    nonProdTimeListByChangeover: null,
    nonFixedExceptionList: null,
    rdProduction: null,
    nonBarcodeProduction: null,
  };

  totalDurationTime = 0;
  totalRestTimeInAct = 0;
  totalRestTimeInChangeover = 0;
  totalChangeOverTime = 0;
  totalNonProdTimeTimeByAct = 0;
  totalNonProdTimeTimeByChangeover = 0;
  totalNonFixedExceptionTime = 0;
  totalRdProductionTime = 0;
  totalNonBarcodeProductionTime = 0;

  ngOnInit() {
    this.EfficiencyData = this.data;

    if (this.EfficiencyData.durationList.length > 0) {
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.filter(item => item.duration !== null);
      this.totalDurationTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.durationList);
    }
    if (this.EfficiencyData.restTimeInAct.length > 0) {
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.filter(item => item.duration !== null);
      this.totalRestTimeInAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInAct);
    }
    if (this.EfficiencyData.restTimeInChangeover.length > 0) {
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.filter(item => item.duration !== null);
      this.totalRestTimeInChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInChangeover);
    }
    if (this.EfficiencyData.changeOverList.length > 0) {
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.filter(item => item.duration !== null);
      this.totalChangeOverTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.changeOverList);
    }
    if (this.EfficiencyData.nonProdTimeListByAct.length > 0) {
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByAct);
    }
    if (this.EfficiencyData.nonProdTimeListByChangeover.length > 0) {
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByChangeover);
    }
    if (this.EfficiencyData.nonFixedExceptionList.length > 0) {
      this.EfficiencyData.nonFixedExceptionList = this.EfficiencyData.nonFixedExceptionList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonFixedExceptionList = this.EfficiencyData.nonFixedExceptionList.filter(item => item.duration !== null);
      this.totalNonFixedExceptionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonFixedExceptionList);
    }
    if (this.EfficiencyData.rdProduction.length > 0) {
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.filter(item => item.duration !== null);
      this.totalRdProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.rdProduction);
    }
    if (this.EfficiencyData.nonBarcodeProduction.length > 0) {
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.filter(item => item.duration !== null);
      this.totalNonBarcodeProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonBarcodeProduction);
    }
  }
}
