var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, Output } from '@angular/core';
import { take } from 'rxjs/operators';
var CalendarCell = (function () {
    function CalendarCell(value, displayValue, ariaLabel, enabled, out, cellClass) {
        if (out === void 0) { out = false; }
        if (cellClass === void 0) { cellClass = ''; }
        this.value = value;
        this.displayValue = displayValue;
        this.ariaLabel = ariaLabel;
        this.enabled = enabled;
        this.out = out;
        this.cellClass = cellClass;
    }
    return CalendarCell;
}());
export { CalendarCell };
var OwlCalendarBodyComponent = (function () {
    function OwlCalendarBodyComponent(elmRef, ngZone) {
        this.elmRef = elmRef;
        this.ngZone = ngZone;
        this.activeCell = 0;
        this.numCols = 7;
        this.cellRatio = 1;
        this.select = new EventEmitter();
    }
    Object.defineProperty(OwlCalendarBodyComponent.prototype, "owlDTCalendarBodyClass", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlCalendarBodyComponent.prototype, "isInSingleMode", {
        get: function () {
            return this.selectMode === 'single';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlCalendarBodyComponent.prototype, "isInRangeMode", {
        get: function () {
            return this.selectMode === 'range' || this.selectMode === 'rangeFrom'
                || this.selectMode === 'rangeTo';
        },
        enumerable: true,
        configurable: true
    });
    OwlCalendarBodyComponent.prototype.ngOnInit = function () {
    };
    OwlCalendarBodyComponent.prototype.selectCell = function (cell) {
        this.select.emit(cell);
    };
    OwlCalendarBodyComponent.prototype.isActiveCell = function (rowIndex, colIndex) {
        var cellNumber = rowIndex * this.numCols + colIndex;
        return cellNumber === this.activeCell;
    };
    OwlCalendarBodyComponent.prototype.isSelected = function (value) {
        if (!this.selectedValues || this.selectedValues.length === 0) {
            return false;
        }
        if (this.isInSingleMode) {
            return value === this.selectedValues[0];
        }
        if (this.isInRangeMode) {
            var fromValue = this.selectedValues[0];
            var toValue = this.selectedValues[1];
            return value === fromValue || value === toValue;
        }
    };
    OwlCalendarBodyComponent.prototype.isInRange = function (value) {
        if (this.isInRangeMode) {
            var fromValue = this.selectedValues[0];
            var toValue = this.selectedValues[1];
            if (fromValue !== null && toValue !== null) {
                return value >= fromValue && value <= toValue;
            }
            else {
                return value === fromValue || value === toValue;
            }
        }
    };
    OwlCalendarBodyComponent.prototype.isRangeFrom = function (value) {
        if (this.isInRangeMode) {
            var fromValue = this.selectedValues[0];
            return fromValue !== null && value === fromValue;
        }
    };
    OwlCalendarBodyComponent.prototype.isRangeTo = function (value) {
        if (this.isInRangeMode) {
            var toValue = this.selectedValues[1];
            return toValue !== null && value === toValue;
        }
    };
    OwlCalendarBodyComponent.prototype.focusActiveCell = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            _this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(function () {
                _this.elmRef.nativeElement.querySelector('.owl-dt-calendar-cell-active').focus();
            });
        });
    };
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlCalendarBodyComponent.prototype, "activeCell", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Array)
    ], OwlCalendarBodyComponent.prototype, "rows", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlCalendarBodyComponent.prototype, "numCols", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlCalendarBodyComponent.prototype, "cellRatio", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Number)
    ], OwlCalendarBodyComponent.prototype, "todayValue", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Array)
    ], OwlCalendarBodyComponent.prototype, "selectedValues", void 0);
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], OwlCalendarBodyComponent.prototype, "selectMode", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], OwlCalendarBodyComponent.prototype, "select", void 0);
    __decorate([
        HostBinding('class.owl-dt-calendar-body'),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OwlCalendarBodyComponent.prototype, "owlDTCalendarBodyClass", null);
    OwlCalendarBodyComponent = __decorate([
        Component({
            selector: '[owl-date-time-calendar-body]',
            exportAs: 'owlDateTimeCalendarBody',
            template: "<tr *ngFor=\"let row of rows; let rowIndex = index\" role=\"row\"><td *ngFor=\"let item of row; let colIndex = index\" class=\"owl-dt-calendar-cell {{item.cellClass}}\" [tabindex]=\"isActiveCell(rowIndex, colIndex) ? 0 : -1\" [class.owl-dt-calendar-cell-active]=\"isActiveCell(rowIndex, colIndex)\" [class.owl-dt-calendar-cell-disabled]=\"!item.enabled\" [class.owl-dt-calendar-cell-in-range]=\"isInRange(item.value)\" [class.owl-dt-calendar-cell-range-from]=\"isRangeFrom(item.value)\" [class.owl-dt-calendar-cell-range-to]=\"isRangeTo(item.value)\" [attr.aria-label]=\"item.ariaLabel\" [attr.aria-disabled]=\"!item.enabled || null\" [style.width.%]=\"100 / numCols\" [style.paddingTop.%]=\"50 * cellRatio / numCols\" [style.paddingBottom.%]=\"50 * cellRatio / numCols\" (click)=\"selectCell(item)\"><span class=\"owl-dt-calendar-cell-content\" [ngClass]=\"{\n                'owl-dt-calendar-cell-out': item.out,\n                'owl-dt-calendar-cell-today': item.value === todayValue,\n                'owl-dt-calendar-cell-selected': isSelected(item.value)\n              }\">{{item.displayValue}}</span></td></tr>",
            styles: [""],
            preserveWhitespaces: false,
            changeDetection: ChangeDetectionStrategy.OnPush,
        }),
        __metadata("design:paramtypes", [ElementRef,
            NgZone])
    ], OwlCalendarBodyComponent);
    return OwlCalendarBodyComponent;
}());
export { OwlCalendarBodyComponent };
