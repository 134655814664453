<div
  (window:resize)="onWindowResize($event)"
  [ngStyle]="{
    width: getInnerWidth + 'px',
    height: getInnerHeight + 'px',
    overflow: 'hidden',
    position: 'relative'
  }"
>
  <div
    class="main_board"
    style="transform-origin: left top; transform: scale(0.5)"
    [style.transform]="'scale(' + scaleWindowSize + ')'"
  >
    <!-- Title & menu -->
    <div class="title_group">
      <h1 class="mat-title color-silver">AcBel Plant Overview</h1>

      <div class="time">
        <h3 class="mat-subheading-2 color-silver">
          {{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}
        </h3>
        <img
          class="menu-bt"
          routerLink="/menu"
          src="../../assets/img/btn_menu.svg"
          alt="menu"
        />
      </div>
    </div>

    <!-- container -->
    <div class="container_box">
      <!-- 廠區圖區塊 -->
      <div class="floor_box">
        <!-- Alarms box -->
        <div class="alarms_box">
          <div class="alarm_title">
            <h4 class="mat-body-1 color-silver">Alarms</h4>
            <!-- <a class="history" href="#">History</a> -->
          </div>

          <!-- alarm group -->
          <div class="alarm_group">
            <!-- item -->
            <div class="alarm_item" *ngFor="let item of alarmData">
              <!-- light & line name -->
              <div class="item_title">
                <span class="light_red"></span>
                <p class="mat-caption color-white">{{ item.name }}</p>
              </div>

              <!-- btn icon group -->
              <div class="item_icon_group">
                <div class="icon_report">
                  <!-- Button report first -->
                  <!-- <div
                  class="icon_btn"
                  [ngClass]="{
                    notyetReport: item.isReport === false
                  }"
                  (click)="
                    apiReportIssue('isreport', item);
                    this.OpenPopupObj.isReport = !this.OpenPopupObj.isReport
                  "
                ></div> -->
                </div>

                <!-- Button report first -->
                <div
                  class="icon_btn"
                  [ngClass]="{
                    notyetReport: item.isReport === false
                  }"
                  (click)="
                    apiReportIssue('isreport', item);
                    this.OpenPopupObj.isReport = !this.OpenPopupObj.isReport
                  "
                ></div>

                <!-- Button report again -->
                <div
                  class="icon_btn"
                  [ngClass]="{
                    isReport: item.isReport === true
                  }"
                  (click)="
                    apiReportIssue('reportAgain', item);
                    this.OpenPopupObj.isReportAgain =
                      !this.OpenPopupObj.isReportAgain;
                    (this.OpenPopupObj.isRemove === true
                      ? !this.OpenPopupObj.isRemove
                      : this.OpenPopupObj.isRemove)
                  "
                ></div>
                <!-- <div class="icon_btn icon_floor"></div> -->

                <!-- Button remove -->
                <div
                  class="icon_btn icon_remove"
                  (click)="
                    apiReportIssue('remove', item);
                    this.OpenPopupObj.isRemove = !this.OpenPopupObj.isRemove
                  "
                ></div>
              </div>
            </div>
          </div>

          <!-- Popup => Remove alarm item -->
          <div
            class="popup_style"
            [ngClass]="{
              popup_isRemove: OpenPopupObj.isRemove
            }"
          >
            <div class="pop_title mb_14">
              <p class="mat-caption color-white text_des text_a_c">
                Remove this alarm?
              </p>
            </div>

            <div class="text_group mb_14">
              <h4 class="mat-body-2 color-silver">Alarm time</h4>
              <h5 class="mat-caption color-white">
                {{ reportJSON.logTime | date: "yyyy/MM/dd HH:mm" }}
              </h5>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Alarm item</div>
              <div class="mat-caption color-white">{{ reportJSON.name }}</div>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Last report time</div>
              <div
                class="mat-caption color-white"
                *ngIf="reportJSON.reportTime === null"
              >
                -
              </div>
              <div
                class="mat-caption color-white"
                *ngIf="reportJSON.reportTime !== null"
              >
                {{ reportJSON.reportTime | date: "YYYY/MM/dd HH:mm" }}
              </div>
            </div>

            <!-- Button -->
            <div class="button_group">
              <div
                class="b_style b_yes"
                (click)="alarmRemove(reportJSON.sourceId, reportJSON.dbLogTime)"
              >
                Yes
              </div>
              <div
                class="b_style b_no"
                (click)="
                  this.OpenPopupObj.isRemove = !this.OpenPopupObj.isRemove
                "
              >
                No
              </div>
            </div>
          </div>

          <!-- Popup  => Report again -->
          <div
            class="popup_style"
            [ngClass]="{ popup_isReportAgain: OpenPopupObj.isReportAgain }"
          >
            <div class="pop_title mb_14">
              <p class="mat-caption color-white text_des text_a_c">
                Report this alarm again?
              </p>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Alarm time</div>
              <div class="mat-caption color-white">
                {{ reportJSON.logTime | date: "YYYY/MM/dd HH:mm" }}
              </div>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Alarm item</div>
              <div class="mat-caption color-white">{{ reportJSON.name }}</div>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Last report time</div>
              <div class="mat-caption color-white">
                {{ reportJSON.reportTime | date: "YYYY/MM/dd HH:mm" }}
              </div>
            </div>

            <!-- Button -->
            <div class="button_group">
              <div
                class="b_style b_yes"
                (click)="alarmReport(reportJSON.sourceId, reportJSON.dbLogTime)"
              >
                Yes
              </div>
              <div
                class="b_style b_no"
                (click)="
                  this.OpenPopupObj.isReportAgain =
                    !this.OpenPopupObj.isReportAgain
                "
              >
                No
              </div>
            </div>
          </div>

          <!-- Popup => Report this alarm -->
          <div
            class="popup_style"
            [ngClass]="{ popup_isReport: OpenPopupObj.isReport }"
          >
            <div class="pop_title mb_14">
              <p class="mat-caption color-white text_a_c">Report this alarm?</p>
              <p class="text_tip c_white"></p>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Alarm time</div>
              <div class="mat-caption color-white">
                {{ reportJSON.logTime | date: "YYYY/MM/dd HH:mm" }}
              </div>
            </div>

            <div class="text_group mb_14">
              <div class="mat-body-2 color-silver">Alarm item</div>
              <div class="mat-caption color-white">{{ reportJSON.name }}</div>
            </div>

            <!-- Button -->
            <div class="button_group">
              <div
                class="b_style b_yes"
                (click)="alarmReport(reportJSON.sourceId, reportJSON.dbLogTime)"
              >
                Yes
              </div>
              <div
                class="b_style b_no"
                (click)="
                  this.OpenPopupObj.isReport = !this.OpenPopupObj.isReport
                "
              >
                No
              </div>
            </div>
          </div>
        </div>

        <!-- Floor group -->
        <!-- <div class="floor_level">
        <p class="level level_active">1F</p>
        <p class="level">2F</p>
        <p class="level">3F</p>
        <p class="level">4F</p>
        <p class="level">5F</p>
        <p class="level">6F</p>
      </div> -->

        <!-- Img box -->
        <div class="img_group">
          <!-- map bg img -->
          <div
            class="map_img smt_bg"
            [ngClass]="{ smt_bg_hide: !smtData.smtData_checkbox_active }"
          ></div>
          <div
            class="map_img loading_bg"
            [ngClass]="{
              loading_bg_hide: !loadingData.loadingData_checkbox_active
            }"
          ></div>
          <div
            class="map_img energy_bg"
            [ngClass]="{
              energy_bg_hide: !energyData.energyData_checkbox_active
            }"
          ></div>
          <div
            class="map_img env_bg"
            [ngClass]="{
              env_bg_hide: !envData.envData_checkbox_active
            }"
          ></div>
          <div
            class="map_img cctv_bg"
            [ngClass]="{
              cctv_bg_hide: !cctvData.cctvData_checkbox_active
            }"
          ></div>

          <!-- name circle -->
          <div class="img_name_circle mat-subheading-2 color-white">
            <span class="icon_assy border_3">ASSY</span>
            <span class="icon_ate border_3">ATE</span>
          </div>

          <!-- SMT circle -->
          <div
            class="img_smt_circle"
            [ngClass]="{
              img_smt_circle_hide: !smtData.smtData_checkbox_active
            }"
          >
            <!-- A -->
            <div class="img_circle smt_a c_bg_blue mat-display-1 color-white">
              <span
                class="img_light"
                [ngClass]="getEquipmentStatusLight(smtData.lineObj['SMT-A'])"
              ></span>
              A
            </div>
            <!-- B -->
            <div class="img_circle smt_b c_bg_blue mat-display-1 color-white">
              <span
                class="img_light"
                [ngClass]="getEquipmentStatusLight(smtData.lineObj['SMT-B'])"
              ></span>
              B
            </div>
            <!-- C -->
            <div class="img_circle smt_c c_bg_blue mat-display-1 color-white">
              <span
                class="img_light"
                [ngClass]="getEquipmentStatusLight(smtData.lineObj['SMT-C'])"
              ></span>
              C
            </div>
            <!-- D -->
            <div class="img_circle smt_d c_bg_blue mat-display-1 color-white">
              <span
                class="img_light"
                [ngClass]="getEquipmentStatusLight(smtData.lineObj['SMT-D'])"
              ></span>
              D
            </div>
            <!-- F -->
            <div class="img_circle smt_f c_bg_blue mat-display-1 color-white">
              <span
                class="img_light"
                [ngClass]="getEquipmentStatusLight(smtData.lineObj['SMT-F'])"
              ></span>
              F
            </div>
          </div>

          <!-- Loading circle -->
          <div
            class="img_loading_circle"
            [ngClass]="{
              img_loading_circle_hide: !loadingData.loadingData_checkbox_active
            }"
          >
            <!-- A -->
            <div
              class="img_circle load_a c_bg_purple mat-display-1 color-white"
            >
              <span
                class="img_light"
                [ngClass]="
                  getEquipmentStatusLight(loadingData.lineObj['LOD-A'])
                "
              ></span>
              A
            </div>
            <!-- B -->
            <div
              class="img_circle load_b c_bg_purple mat-display-1 color-white"
            >
              <span
                class="img_light"
                [ngClass]="
                  getEquipmentStatusLight(loadingData.lineObj['LOD-B'])
                "
              ></span>
              B
            </div>
            <!-- C -->
            <div
              class="img_circle load_c c_bg_purple mat-display-1 color-white"
            >
              <span
                class="img_light"
                [ngClass]="
                  getEquipmentStatusLight(loadingData.lineObj['LOD-C'])
                "
              ></span>
              C
            </div>
            <!-- D -->
            <div
              class="img_circle load_d c_bg_purple mat-display-1 color-white"
            >
              <span
                class="img_light"
                [ngClass]="
                  getEquipmentStatusLight(loadingData.lineObj['LOD-D'])
                "
              ></span>
              D
            </div>
            <!-- E -->
            <div
              class="img_circle load_e c_bg_purple mat-display-1 color-white"
            >
              <span
                class="img_light"
                [ngClass]="
                  getEquipmentStatusLight(loadingData.lineObj['LOD-E'])
                "
              ></span>
              E
            </div>
          </div>

          <!-- Energy circle -->
          <div
            class="img_energy_circle"
            [ngClass]="{
              img_energy_circle_hide: !energyData.energyData_checkbox_active
            }"
          >
            <!-- A -->
            <div class="img_circle energy_a c_bg_brown">
              <span
                class="img_light"
                [ngClass]="getSensorStatusLight(energyData.lineObj?.alarm)"
              ></span>
              <img
                src="/assets/img/newMainBoard/icon_energy.svg"
                alt="energy"
              />
            </div>
          </div>

          <!-- Environment circle -->
          <div
            class="img_environment_circle"
            [ngClass]="{
              img_environment_circle_hide: !envData.envData_checkbox_active
            }"
          >
            <!-- A -->
            <div class="img_circle environment_a c_bg_brown">
              <span
                class="img_light"
                [ngClass]="
                  getSensorStatusLight(envData.lineObj['acbeldso-iaq-10'])
                "
              ></span>
              <img
                src="/assets/img/newMainBoard/icon_environment.svg"
                alt="environment"
              />
            </div>
            <!-- B -->
            <div class="img_circle environment_b c_bg_brown">
              <span
                class="img_light"
                [ngClass]="
                  getSensorStatusLight(envData.lineObj['acbeldso-iaq-11'])
                "
              ></span>
              <img
                src="/assets/img/newMainBoard/icon_environment.svg"
                alt="environment"
              />
            </div>
          </div>

          <!-- CCTV circle -->
          <div
            class="img_cctv_circle"
            [ngClass]="{
              img_cctv_circle_hide: !cctvData.cctvData_checkbox_active
            }"
          >
            <!-- A -->
            <div class="img_circle cctv_a c_bg_brown">
              <span
                class="img_light"
                [ngClass]="{
                  img_light_green: cctvData.lineObj.cctvA === 1,
                  img_light_red: cctvData.lineObj.cctvA === 2,
                  img_light_yellow: cctvData.lineObj.cctvA === 3
                }"
              ></span>
              <img src="/assets/img/newMainBoard/icon_cctv.svg" alt="cctv" />
            </div>
            <!-- B -->
            <div class="img_circle cctv_b c_bg_brown">
              <span
                class="img_light"
                [ngClass]="{
                  img_light_green: cctvData.lineObj.cctvB === 1,
                  img_light_red: cctvData.lineObj.cctvB === 2,
                  img_light_yellow: cctvData.lineObj.cctvB === 3
                }"
              ></span>
              <img src="/assets/img/newMainBoard/icon_cctv.svg" alt="cctv" />
            </div>
          </div>
        </div>

        <!-- button group -->
        <div class="icon_group mat-display-1 color-silver">
          <label class="checkbox_gourp">
            <input
              [checked]="smtData.smtData_checkbox_active"
              (change)="
                smtData.smtData_checkbox_active =
                  !smtData.smtData_checkbox_active
              "
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="OrderStartTime"
              id=""
            />
            <span class="checkbox_span"></span>
            <span class="circle border_3 circle_c c_bg_blue"></span>
            SMT
          </label>

          <label class="checkbox_gourp">
            <input
              [checked]="loadingData.loadingData_checkbox_active"
              (change)="
                loadingData.loadingData_checkbox_active =
                  !loadingData.loadingData_checkbox_active
              "
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="OrderStartTime"
              id=""
            />
            <span class="checkbox_span"></span>
            <span class="circle border_3 circle_c c_bg_purple"></span>
            Loading
          </label>

          <label class="checkbox_gourp">
            <input
              [checked]="energyData.energyData_checkbox_active"
              (change)="
                energyData.energyData_checkbox_active =
                  !energyData.energyData_checkbox_active
              "
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="OrderStartTime"
              id=""
            />
            <span class="checkbox_span"></span>
            <span class="circle border_3 circle_c c_bg_brown">
              <img
                class="icon_img"
                src="/assets/img/newMainBoard/icon_energy.svg"
                alt=""
              />
            </span>
            Energy
          </label>

          <label class="checkbox_gourp">
            <input
              [checked]="envData.envData_checkbox_active"
              (change)="
                envData.envData_checkbox_active =
                  !envData.envData_checkbox_active
              "
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="OrderStartTime"
              id=""
            />
            <span class="checkbox_span"></span>
            <span class="circle border_3 circle_c c_bg_brown">
              <img
                class="icon_img"
                src="/assets/img/newMainBoard/icon_environment.svg"
                alt=""
              />
            </span>
            Environment
          </label>

          <label class="checkbox_gourp">
            <input
              [checked]="cctvData.cctvData_checkbox_active"
              (change)="
                cctvData.cctvData_checkbox_active =
                  !cctvData.cctvData_checkbox_active
              "
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="OrderStartTime"
              id=""
            />
            <span class="checkbox_span"></span>
            <span class="circle border_3 circle_c c_bg_brown">
              <img
                class="icon_img"
                src="/assets/img/newMainBoard/icon_cctv.svg"
                alt=""
              />
            </span>
            CCTV
          </label>
        </div>
      </div>

      <!-- 數據區塊 -->
      <div class="data_box">
        <!-- MO Status Overview -->
        <div class="mo_overview">
          <h5 class="mat-subheading-1 color-silver" style="text-align: center">
            MO Status Overview
          </h5>
          <div class="content_box">
            <div class="box">
              <p class="mat-body-1 color-silver">MO Closed (Month)</p>
              <p class="mat-headline color-white">
                {{ moOverviewObj.moClose }}
              </p>
            </div>
            <div class="box">
              <p class="mat-body-1 color-silver">MO to be Processed</p>
              <p class="mat-headline color-white">{{ moOverviewObj.moToBe }}</p>
            </div>
          </div>
        </div>

        <!-- MO Status Detail -->
        <div class="mo_detail">
          <h5 class="mat-subheading-1 color-silver" style="text-align: center">
            MO Status Detail (Month)
            <a
              class="debug"
              target="_blank"
              *ngIf="isOpenDebugMode"
              (click)="downloadFile()"
            >
              下載
            </a>
          </h5>
          <div class="pie_group">
            <!-- pie title & num -->
            <div class="pie_title_group">
              <div class="pie_item">
                <div class="pie_color">
                  <span class="pie_c pie_bg_green"></span>
                  <p class="mat-body-1 color-silver" style="width: 270px">
                    Closed
                  </p>
                </div>
                <div
                  class="mat-subheading-1 color-white"
                  style="width: 250px; text-align: right"
                >
                  {{ moStatusDetailObj.closedPercent | number: "0.2-2" }}%
                  (<span>{{ moStatusDetailObj.closed }}</span
                  >)
                </div>
              </div>

              <div class="pie_item">
                <div class="pie_color">
                  <span class="pie_c pie_bg_yellow"></span>
                  <p class="mat-body-1 color-silver" style="width: 270px">
                    Closed (Overdue)
                  </p>
                </div>
                <div
                  class="mat-subheading-1 color-white"
                  style="width: 250px; text-align: right"
                >
                  {{
                    moStatusDetailObj.closedOverduePercent | number: "0.2-2"
                  }}% (<span>{{ moStatusDetailObj.closedOverdue }}</span
                  >)
                </div>
              </div>

              <div class="pie_item">
                <div class="pie_color">
                  <span class="pie_c pie_bg_brown"></span>
                  <p class="mat-body-1 color-silver" style="width: 270px">
                    In Progress
                  </p>
                </div>
                <div
                  class="mat-subheading-1 color-white"
                  style="width: 250px; text-align: right"
                >
                  {{ moStatusDetailObj.InProgressPercent | number: "0.2-2" }}%
                  (<span>{{ moStatusDetailObj.InProgress }}</span
                  >)
                </div>
              </div>

              <div class="pie_item">
                <div class="pie_color">
                  <span class="pie_c pie_bg_red"></span>
                  <p class="mat-body-1 color-silver" style="width: 270px">
                    Overdue
                  </p>
                </div>
                <div
                  class="mat-subheading-1 color-white"
                  style="width: 250px; text-align: right"
                >
                  {{ moStatusDetailObj.OverduePercent | number: "0.2-2" }}%
                  (<span>{{ moStatusDetailObj.Overdue }}</span
                  >)
                </div>
              </div>
            </div>
            <!-- pie chart -->
            <div class="pie_chart" *ngFor="let item of pieChartDataArr">
              <canvas
                baseChart
                [datasets]="item.pieChartData"
                [labels]="item.pieChartLabels"
                [chartType]="item.pieChartType"
                [options]="item.pieChartOptions"
                [colors]="item.donutColors"
              >
              </canvas>
            </div>
          </div>
          <p class="mat-subheading-2 color-silver" style="text-align: right">
            MO On Time Rate
            <span class="mat-headline color-white" style="margin-left: 25px">
              {{ +moStatusDetailObj.deliveryRate | number: "0.2-2" }}
            </span>
            <span class="rate_percent">%</span>
          </p>
        </div>

        <!-- Alarm Record -->
        <div class="alarm_record">
          <h5 class="mat-subheading-1 color-silver" style="text-align: center">
            Alarm Record
          </h5>
          <div class="chart_group">
            <div class="chart" *ngFor="let item of newAlarmChartDataArr">
              <p
                class="mat-body-1 color-silver"
                style="text-align: center; margin-bottom: 5px"
              >
                {{ item.chartTitle }}
              </p>
              <div class="chart_img">
                <canvas
                  baseChart
                  height="85"
                  [datasets]="item.barChartData"
                  [labels]="item.barChartLabels"
                  [options]="item.barChartOptions"
                  [colors]="item.chartColors"
                  [legend]="item.barChartLegend"
                  [chartType]="item.barChartType"
                  (chartHover)="item.chartHovered($event)"
                  (chartClick)="item.chartClicked($event)"
                >
                </canvas>
              </div>
              <!-- <span class="chart_Y_name">Alarm Times</span> -->
            </div>
            <!-- <div class="chart"></div> -->
            <!-- <div class="chart"></div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Auto Refresh -->
    <div class="refresh">
      <div class="refresh_btn">
        <label>
          <span class="text">Auto Refresh</span>
          <input
            type="checkbox"
            class="checkbox"
            [checked]="pageAutoRefresh"
            (change)="switchPageAutoRefresh()"
          />
          <span class="btn-box">
            <span class="btn"></span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
