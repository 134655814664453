import { Pipe, PipeTransform } from '@angular/core';

// moment js
import * as moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'to_local_timezone',
})
export class TranToLocalTimeZonePipe implements PipeTransform {

  // This Pipe it trans timezone to browser local timezone

  transform(value) {
    const zoneName = moment.tz.guess();
    // const timeZone = moment.tz(zoneName).zoneAbbr();
    // console.log('Browser time zone Name :', zoneName);

    // const getUSTime = moment(value).tz('America/New_York').format('YYYY/MM/DD HH:mm');
    // console.log('America/New_York Time Zone :', getUSTime);

    const getLocalTimeZone = moment(value, moment.ISO_8601).tz(zoneName).format('YYYY/MM/DD HH:mm');
    // console.log('get Local TimeZone :', getLocalTimeZone);
    return getLocalTimeZone;

  }

}
