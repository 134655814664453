import numeral from 'numeral';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ApiService, DotnetApi } from 'src/app/_services/apiSvc.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { DataFormatService } from 'src/app/_services/data-format.service';
import { Option } from 'src/components/dropdown/dropdown.component';
import { TextInputComponent } from 'src/components/text-input/text-input.component';

const PollingIntervalInSeconds = 60;

const TABLE_PAGE_SIZE = 10;

const MO_TABLE_COLUMNS = {
  key: 'MO No.',
  partNo: 'Part No.',
  defectQty: 'RC Qty',
  completeQty: 'Complete Qty',
  overdue3to7Qty: 'Overdue Qty(3~7 days)',
  overdue8to30Qty: 'Overdue Qty(8~30 days)',
  overdueGt30Qty: 'Overdue Qty(30~ days)',
};

type MoTableDataType = DotnetApi.DefectiveProductManagement.DefectiveMoDetail & {
  key: string;
  partNo: string;
  defectQty: number;
  completeQty: number;
  overdue3to7Qty: number;
  overdue8to30Qty: number;
  overdueGt30Qty: number;
};

const MO_DEFECT_TABLE_COLUMNS = {
  key: 'Unit',
  startTimeString: 'Start Time',
  endTimeString: 'End Time',
  defectiveCode: 'Defect Code'
};

type MoDefectTableDataType = DotnetApi.DefectiveProductManagement.DefectiveMoUnitDetail & {
  key: string;
  startTimeString: string;
  endTimeString: string;
  defectiveCode: string;
};

interface RootCause {
  key: string;
  label: string;
  value: number;
  ratio: string;
}

const serviceName = DotnetApi.DefectiveProductManagement.ServiceName;

const getCellTextColor = (repairStatus: DotnetApi.DefectiveProductManagement.RepairStatus): 'red' | 'yellow' | null => {
  switch (repairStatus) {
    case DotnetApi.DefectiveProductManagement.RepairStatus.OVERDUE:
      return 'red';
    case DotnetApi.DefectiveProductManagement.RepairStatus.WARNING:
      return 'yellow';
    default:
      return null;
  }
};

class Poller {
  constructor() { }

  timer: ReturnType<typeof setInterval> | null = null;

  reset(callback: () => void, milliSeconds: number) {
    if (this.timer != null) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      callback();
    }, milliSeconds);
  }

  stop() {
    if (this.timer == null) { return; }
    clearInterval(this.timer);
  }
}

@Component({
  selector: 'app-new-repair-management',
  templateUrl: './new-repair-management.component.html',
  styleUrls: ['./new-repair-management.component.scss'],
})
export class NewRepairManagementComponent implements OnInit {

  constructor(
    public service: DotnetApi.DefectiveProductManagement.Service,
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,
  ) {
    this.apiPoller = new Poller();
  }

  pageAutoRefresh = true;

  apiPoller: Poller;

  readonly tableConfig = {
    pageSize: TABLE_PAGE_SIZE,
  };

  filterOptions: {
    category?: string;
    model?: string;
  } = {};

  categories: Option[] | undefined = undefined;

  models: Option[] | undefined = undefined;

  rootCauseRanking: {
    startTime: string;
    endTime: string;
    ranking: RootCause[];
  } | undefined = undefined;

  defectiveMoObject: DotnetApi.DefectiveProductManagement.DefectiveInfo | undefined = undefined;

  moTable: {
    columns: Record<string, string>;
    columnsToDisplay: string[];
    data: MoTableDataType[];
    pageIndex: number;
    total: number;
    readonly pageSize: number;
    loading: boolean;
    onChangePage: (obj: { pageIndex: number }) => Promise<void>;
    onChangeValue: (value: string) => Promise<void>;
    text?: string;
  } = {
      columns: MO_TABLE_COLUMNS,
      columnsToDisplay: Object.keys(MO_TABLE_COLUMNS),
      data: [],
      pageIndex: 0,
      total: 0,
      pageSize: TABLE_PAGE_SIZE,
      loading: false,
      onChangePage: async ({ pageIndex }) => {
        this.moTable = {
          ...this.moTable,
          loading: true,
          pageIndex
        };
        const { category, model } = this.filterOptions;
        const { text } = this.moTable;
        await this.update(category, model, pageIndex, text);
        this.moTable = {
          ...this.moTable,
          loading: false,
        };
      },
      onChangeValue: _.debounce(async (text: string) => {
        if (typeof text !== 'string') { return; }
        const upperText = text.toUpperCase();
        const { category, model } = this.filterOptions;
        this.moTable = {
          ...this.moTable,
          loading: true,
        };
        if (text.length === 0) {
          this.moTable = {
            ...this.moTable,
            text: undefined,
            loading: false,
          };
        } else {
          this.moTable = {
            ...this.moTable,
            text: upperText,
            loading: false,
          };
        }
        await this.update(category, model, 0, this.moTable.text);
        this.moDefectTablePaginator.firstPage();
        this.moTable.pageIndex = 0;

      }, 1000)
    };

  moDefectTable: {
    columns: Record<string, string>;
    columnsToDisplay: string[];
    data: MoDefectTableDataType[] | undefined;
  } = {
      columns: MO_DEFECT_TABLE_COLUMNS,
      columnsToDisplay: Object.keys(MO_DEFECT_TABLE_COLUMNS),
      data: undefined,
    };

  clickedDefectiveMo: DotnetApi.DefectiveProductManagement.DefectiveMoDetail | null = null;

  @ViewChild('textInput') textInput: TextInputComponent;
  @ViewChild('moDefectTablePaginator') moDefectTablePaginator: MatPaginator;

  getMoTableTextColor(key: keyof MoTableDataType, element: MoTableDataType): 'red' | 'yellow' | null {
    if (this.moTable?.columnsToDisplay?.indexOf(key) === -1) { return null; }
    return getCellTextColor(element.repairStatus);
  }

  getMoDefectTableTextColor(key: keyof MoDefectTableDataType, element: MoDefectTableDataType): 'red' | 'yellow' | null {
    if (this.moTable?.columnsToDisplay?.indexOf(key) === -1) { return null; }
    return getCellTextColor(element.repairStatus);
  }

  subscribeCategories() {
    return new Promise<Option[]>((resolve) => {
      this.apiSvc.getDefectiveProductManagementCategories().subscribe(res => {
        resolve([{
          label: 'All',
          value: '',
          selected: true,
        }, ...res.map(org => ({
          label: org.name,
          value: org.value,
          selected: false,
        }))]);
      });
    });
  }

  subscribeModels(category?: string) {
    return new Promise<Option[]>((resolve) => {
      this.apiSvc.getDefectiveProductManagementModels(category).subscribe(res => {
        resolve([{
          label: 'All',
          value: '',
          selected: true,
        }, ...res.map(org => ({
          label: org.name,
          value: org.value,
          selected: false,
        }))]);
      });
    });
  }

  subscribeDefectiveInfo(category?: string, model?: string) {
    return new Promise<DotnetApi.DefectiveProductManagement.DefectiveInfo>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveInfo(category, model)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  subscribeDefectiveRootCauseRanking(category?: string, model?: string) {
    return new Promise<{
      startTime: string;
      endTime: string;
      ranking: RootCause[];
    }>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveRootCauseRanking(category, model).subscribe(({
        startTime,
        endTime,
        ranking
      }) => {
        resolve({
          startTime: moment(startTime).format('YYYY/MM/DD HH:mm:ss'),
          endTime: moment(endTime).format('YYYY/MM/DD HH:mm:ss'),
          ranking: ranking.map(item => ({
            key: item.key,
            label: item.name,
            value: item.rootCauseCount,
            ratio: numeral(item.rootCauseRatio).multiply(100).format('0.00'),
          }))
        });
      });
    });
  }

  subscribeDefectiveMoList(category?: string, model?: string, pageIndex: number = 0, text?: string) {
    return new Promise<DotnetApi.DefectiveProductManagement.DefectiveMoListInfo>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveMoList(category, model, TABLE_PAGE_SIZE, pageIndex * TABLE_PAGE_SIZE, text).subscribe(res => {
        resolve(res);
      });
    });
  }

  subscribeDefectiveUnitsByMoList(moList: string[]) {
    if (moList.length === 0) { return []; }
    return new Promise<DotnetApi.DefectiveProductManagement.DefectiveMoUnitDetail[]>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveUnitsByMoList(moList).subscribe(res => {
        resolve(res);
      });
    });
  }

  // Update the rendering part.
  async update(category?: string, model?: string, pageIndex?: number, text?: string) {
    this.apiPoller.stop();
    const f = () => {
      return Promise.all([
        this.subscribeDefectiveInfo(category, model),
        this.subscribeDefectiveRootCauseRanking(category, model),
        this.subscribeDefectiveMoList(category, model, pageIndex, text)
      ]).then(([
        res1,
        res2,
        res3
      ]) => {
        this.defectiveMoObject = res1;
        this.rootCauseRanking = res2;
        const { data: moTableData, total } = res3;
        this.moTable = {
          ...this.moTable,
          total,
          data: moTableData.map(d => ({
            ...d,
            timeToRepair: this.dataFormatSvc.secToDDhhmmss(d.timeToRepairInSeconds)
          }))
        };
        return this.subscribeDefectiveUnitsByMoList(moTableData.map(d => d.key));
      }).then(res => {
        this.moDefectTable = {
          ...this.moDefectTable,
          data: res.map(d => ({
            ...d,
            startTimeString: d.startTime != null ? this.dataFormatSvc.pipeDate(d.startTime * 1000, 'yyyy/M/dd HH:mm') : '-',
            endTimeString: d.endTime != null ? this.dataFormatSvc.pipeDate(d.endTime * 1000, 'yyyy/M/dd HH:mm') : '-',
            defectiveCode: d.defectiveCode != null ? d.defectiveCode : '-'
          }))
        };
      });
    };
    this.apiPoller.reset(() => {
      f().then(() => undefined);
    }, PollingIntervalInSeconds * 1000);
    return f();
  }

  async onChange(type: 'category' | 'model', data?: Option) {
    let {
      category,
      model,
    } = this.filterOptions;
    switch (type) {
      case 'category':
        this.textInput.input.nativeElement.value = '';
        this.isSvLoading.loading = true;
        category = data?.value;
        this.filterOptions = {
          ...this.filterOptions,
          category,
          model: undefined
        };
        this.models = await this.subscribeModels(category);
        await this.update(category, undefined, 0, this.moTable.text);
        this.moDefectTablePaginator.firstPage();
        this.moTable.pageIndex = 0;
        this.isSvLoading.loading = false;
        break;
      case 'model':
        this.textInput.input.nativeElement.value = '';
        this.isSvLoading.loading = true;
        model = data?.value;
        this.filterOptions = {
          ...this.filterOptions,
          category,
          model,
        };
        await this.update(category, model, 0, this.moTable.text);
        this.moDefectTablePaginator.firstPage();
        this.moTable.pageIndex = 0;
        this.isSvLoading.loading = false;
        break;
      default:
    }
  }

  ngOnInit() {
    console.log(moment().format('YYYY/MM/DD HH'));
    setTimeout(() => {
      this.isSvLoading.loading = true;
      const self = this;
      Promise.all([
        this.subscribeCategories(),
        this.subscribeModels()
      ]).then(([categories, models]) => {
        self.categories = categories;
        self.models = models;
        return self.update();
      }).then(() => {
        this.isSvLoading.loading = false;
      });
    }, 0);
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.apiPoller.stop(); }
    if (isOpenRefresh) { this.update(); }
  }

  getCurrentTime() {
    return moment(new Date()).format('YYYY/MM/DD HH:mm A');
  }

  getBarChartData() {
    return (this.defectiveMoObject?.wipHistory ?? []).map(wip => ({
      x: wip.name,
      y: wip.qty,
    }));
  }

  findMoUnits() {
    const mo = this.clickedDefectiveMo;
    if (mo == null) { return []; }
    return [...this.moDefectTable.data?.filter(d => d.moNo === mo.key)].map(d => ({
      ...d,
    }));
  }

  toggleElement(mo: DotnetApi.DefectiveProductManagement.DefectiveMoDetail | null) {
    this.clickedDefectiveMo = mo;
  }

  onClickRootCause(rootCause: RootCause) {
    const { category, model } = this.filterOptions;
    this.dialog.open(RootCauseUnitListDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '1690px',
      maxHeight: 'calc(100vh, 32px)',
      data: {
        rootCauseCode: rootCause.key,
        category,
        model,
      },
    });
  }
}

const ROOT_CAUSE_UNITS_TABLE_COLUMNS = {
  unitNo: 'Unit',
  startTimeString: 'Start Time',
  endTimeString: 'End Time',
  defectiveCode: 'Defect Code',
  partNo: 'Part No.',
  moNo: 'MO No.',
  section: 'Section',
  side: 'Side',
};

type RootCauseUnitsTableDataType = {
  unitNo: string;
  startTimeString: string;
  endTimeString: string
  defectiveCode: string;
  partNo: string;
  moNo: string;
  section: string;
  side: string
};

interface RootCauseUnitListDialogData {
  rootCauseCode: string;
  category?: string;
  model?: string;
}

@Component({
  selector: 'app-root-cause-unit-list-dialog',
  templateUrl: './dialog/app-root-cause-unit-list-dialog.component.html',
  styleUrls: ['./dialog/app-root-cause-unit-list-dialog.component.scss']
})
export class RootCauseUnitListDialogComponent implements OnInit {
  constructor(
    public service: DotnetApi.DefectiveProductManagement.Service,
    public apiSvc: ApiService,
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<RootCauseUnitListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RootCauseUnitListDialogData,
  ) { }

  rootCauseTable: {
    columns: Record<string, string>;
    columnsToDisplay: string[];
    data: RootCauseUnitsTableDataType[];
    pageIndex: number;
    total: number;
    loading: boolean;
    readonly pageSize: number;
    onChangePage: (obj: { pageIndex }) => Promise<void>;
    getSlicedData: () => RootCauseUnitsTableDataType[];
  } = {
      columns: ROOT_CAUSE_UNITS_TABLE_COLUMNS,
      columnsToDisplay: Object.keys(ROOT_CAUSE_UNITS_TABLE_COLUMNS),
      data: [],
      pageIndex: 0,
      total: 0,
      loading: false,
      pageSize: TABLE_PAGE_SIZE,
      onChangePage: async ({ pageIndex }) => {
        this.rootCauseTable = {
          ...this.rootCauseTable,
          pageIndex
        };
        await this.update();
      },
      getSlicedData: () => this.rootCauseTable.data.slice(0, TABLE_PAGE_SIZE),
    };

  subscribeDefectiveUnits(
    rootCauseCode: string,
    page: number,
    category?: string,
    model?: string
  ) {
    return new Promise<DotnetApi.DefectiveProductManagement.DefectiveRootCauseUnitListInfo>((resolve) => {
      this.apiSvc.getDefectiveProductManagementDefectiveUnitsByRootCause(rootCauseCode, TABLE_PAGE_SIZE, page * TABLE_PAGE_SIZE, category, model).subscribe((res) => {
        resolve(res);
      });
    });
  }

  async update() {
    const {
      rootCauseCode,
      category,
      model
    } = this.data;
    const { pageIndex } = this.rootCauseTable;

    const {
      total,
      data,
    } = await this.subscribeDefectiveUnits(rootCauseCode, pageIndex, category, model);

    this.rootCauseTable = {
      ...this.rootCauseTable,
      total,
      data: data.map(d => ({
        ...d,
        startTimeString: d.startTime != null ? this.dataFormatSvc.pipeDate(d.startTime * 1000, 'yyyy/M/dd HH:mm') : '-',
        endTimeString: d.endTime != null ? this.dataFormatSvc.pipeDate(d.endTime * 1000, 'yyyy/M/dd HH:mm') : '-',
        side: d.side != null ? d.side : '-'
      })),
    };
  }

  ngOnInit() {
    setTimeout(() => {
      this.rootCauseTable = {
        ...this.rootCauseTable,
        loading: true,
      };

      // TODO: abstract to util
      const delay = (sec) => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, numeral(sec).multiply(1000).value());
        });
      };
      Promise.all([this.update(), delay(2)])
        .then(() => {
          this.rootCauseTable = {
            ...this.rootCauseTable,
            loading: false,
          };
        });
    }, 0);
  }

  onChangePageIndex(pageIndex: number): void {
    this.rootCauseTable = {
      ...this.rootCauseTable,
      pageIndex,
    };
    this.update();
  }
}
