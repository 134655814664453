var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, InjectionToken, Input, NgZone, Optional, Output, ViewContainerRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ESCAPE, UP_ARROW } from '@angular/cdk/keycodes';
import { coerceArray, coerceBooleanProperty } from '@angular/cdk/coercion';
import { OwlDateTimeContainerComponent } from './date-time-picker-container.component';
import { DateTimeAdapter } from './adapter/date-time-adapter.class';
import { OWL_DATE_TIME_FORMATS } from './adapter/date-time-format.class';
import { OwlDateTime } from './date-time.class';
import { OwlDialogService } from '../dialog';
import { merge, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
export var OWL_DTPICKER_SCROLL_STRATEGY = new InjectionToken('owl-dtpicker-scroll-strategy');
export function OWL_DTPICKER_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay) {
    return function () { return overlay.scrollStrategies.block(); };
}
export var OWL_DTPICKER_SCROLL_STRATEGY_PROVIDER = {
    provide: OWL_DTPICKER_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: OWL_DTPICKER_SCROLL_STRATEGY_PROVIDER_FACTORY,
};
var OwlDateTimeComponent = (function (_super) {
    __extends(OwlDateTimeComponent, _super);
    function OwlDateTimeComponent(overlay, viewContainerRef, dialogService, ngZone, changeDetector, dateTimeAdapter, defaultScrollStrategy, dateTimeFormats, document) {
        var _this = _super.call(this, dateTimeAdapter, dateTimeFormats) || this;
        _this.overlay = overlay;
        _this.viewContainerRef = viewContainerRef;
        _this.dialogService = dialogService;
        _this.ngZone = ngZone;
        _this.changeDetector = changeDetector;
        _this.dateTimeAdapter = dateTimeAdapter;
        _this.defaultScrollStrategy = defaultScrollStrategy;
        _this.dateTimeFormats = dateTimeFormats;
        _this.document = document;
        _this.backdropClass = [];
        _this.panelClass = [];
        _this._pickerType = 'both';
        _this._pickerMode = 'popup';
        _this._opened = false;
        _this.afterPickerClosed = new EventEmitter();
        _this.afterPickerOpen = new EventEmitter();
        _this.yearSelected = new EventEmitter();
        _this.monthSelected = new EventEmitter();
        _this.confirmSelectedChange = new EventEmitter();
        _this.disabledChange = new EventEmitter();
        _this.dtInputSub = Subscription.EMPTY;
        _this.hidePickerStreamSub = Subscription.EMPTY;
        _this.confirmSelectedStreamSub = Subscription.EMPTY;
        _this.pickerOpenedStreamSub = Subscription.EMPTY;
        _this.focusedElementBeforeOpen = null;
        _this._selecteds = [];
        return _this;
    }
    Object.defineProperty(OwlDateTimeComponent.prototype, "startAt", {
        get: function () {
            if (this._startAt) {
                return this._startAt;
            }
            if (this._dtInput) {
                if (this._dtInput.selectMode === 'single') {
                    return this._dtInput.value || null;
                }
                else if (this._dtInput.selectMode === 'range' ||
                    this._dtInput.selectMode === 'rangeFrom') {
                    return this._dtInput.values[0] || null;
                }
                else if (this._dtInput.selectMode === 'rangeTo') {
                    return this._dtInput.values[1] || null;
                }
            }
            else {
                return null;
            }
        },
        set: function (date) {
            this._startAt = this.getValidDate(this.dateTimeAdapter.deserialize(date));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "pickerType", {
        get: function () {
            return this._pickerType;
        },
        set: function (val) {
            if (val !== this._pickerType) {
                this._pickerType = val;
                if (this._dtInput) {
                    this._dtInput.formatNativeInputValue();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "pickerMode", {
        get: function () {
            return this._pickerMode;
        },
        set: function (mode) {
            if (mode === 'popup') {
                this._pickerMode = mode;
            }
            else {
                this._pickerMode = 'dialog';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "disabled", {
        get: function () {
            return this._disabled === undefined && this._dtInput ?
                this._dtInput.disabled : !!this._disabled;
        },
        set: function (value) {
            value = coerceBooleanProperty(value);
            if (value !== this._disabled) {
                this._disabled = value;
                this.disabledChange.next(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (val) {
            val ? this.open() : this.close();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "dtInput", {
        get: function () {
            return this._dtInput;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            this._selected = value;
            this.changeDetector.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "selecteds", {
        get: function () {
            return this._selecteds;
        },
        set: function (values) {
            this._selecteds = values;
            this.changeDetector.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "minDateTime", {
        get: function () {
            return this._dtInput && this._dtInput.min;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "maxDateTime", {
        get: function () {
            return this._dtInput && this._dtInput.max;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "dateTimeFilter", {
        get: function () {
            return this._dtInput && this._dtInput.dateTimeFilter;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "selectMode", {
        get: function () {
            return this._dtInput.selectMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "isInSingleMode", {
        get: function () {
            return this._dtInput.isInSingleMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OwlDateTimeComponent.prototype, "isInRangeMode", {
        get: function () {
            return this._dtInput.isInRangeMode;
        },
        enumerable: true,
        configurable: true
    });
    OwlDateTimeComponent.prototype.ngOnInit = function () {
    };
    OwlDateTimeComponent.prototype.ngOnDestroy = function () {
        this.close();
        this.dtInputSub.unsubscribe();
        this.disabledChange.complete();
        if (this.popupRef) {
            this.popupRef.dispose();
        }
    };
    OwlDateTimeComponent.prototype.registerInput = function (input) {
        var _this = this;
        if (this._dtInput) {
            throw Error('A Owl DateTimePicker can only be associated with a single input.');
        }
        this._dtInput = input;
        this.dtInputSub = this._dtInput.valueChange.subscribe(function (value) {
            if (Array.isArray(value)) {
                _this.selecteds = value;
            }
            else {
                _this.selected = value;
            }
        });
    };
    OwlDateTimeComponent.prototype.open = function () {
        var _this = this;
        if (this._opened || this.disabled) {
            return;
        }
        if (!this._dtInput) {
            throw Error('Attempted to open an DateTimePicker with no associated input.');
        }
        if (this.document) {
            this.focusedElementBeforeOpen = this.document.activeElement;
        }
        if (this.isInSingleMode) {
            this.selected = this._dtInput.value;
        }
        else if (this.isInRangeMode) {
            this.selecteds = this._dtInput.values;
        }
        if (this.selected && this.pickerType !== 'calendar' && this._startAt) {
            this.selected = this.dateTimeAdapter.createDate(this.dateTimeAdapter.getYear(this.selected), this.dateTimeAdapter.getMonth(this.selected), this.dateTimeAdapter.getDate(this.selected), this.dateTimeAdapter.getHours(this._startAt), this.dateTimeAdapter.getMinutes(this._startAt), this.dateTimeAdapter.getSeconds(this._startAt));
        }
        this.pickerMode === 'dialog' ?
            this.openAsDialog() :
            this.openAsPopup();
        this.pickerContainer.picker = this;
        this.hidePickerStreamSub = this.pickerContainer.hidePickerStream
            .subscribe(function () {
            _this.close();
        });
        this.confirmSelectedStreamSub = this.pickerContainer.confirmSelectedStream
            .subscribe(function (event) {
            _this.confirmSelect(event);
        });
    };
    OwlDateTimeComponent.prototype.select = function (date) {
        if (Array.isArray(date)) {
            this.selecteds = date.slice();
        }
        else {
            this.selected = date;
        }
        if (this.pickerMode !== 'dialog' &&
            this.pickerType === 'calendar' &&
            ((this.selectMode === 'single' && this.selected) ||
                (this.selectMode === 'rangeFrom' && this.selecteds[0]) ||
                (this.selectMode === 'rangeTo' && this.selecteds[1]) ||
                (this.selectMode === 'range' && this.selecteds[0] && this.selecteds[1]))) {
            this.confirmSelect();
        }
    };
    OwlDateTimeComponent.prototype.selectYear = function (normalizedYear) {
        this.yearSelected.emit(normalizedYear);
    };
    OwlDateTimeComponent.prototype.selectMonth = function (normalizedMonth) {
        this.monthSelected.emit(normalizedMonth);
    };
    OwlDateTimeComponent.prototype.close = function () {
        var _this = this;
        if (!this._opened) {
            return;
        }
        if (this.popupRef && this.popupRef.hasAttached()) {
            this.popupRef.detach();
        }
        if (this.pickerContainerPortal && this.pickerContainerPortal.isAttached) {
            this.pickerContainerPortal.detach();
        }
        if (this.hidePickerStreamSub) {
            this.hidePickerStreamSub.unsubscribe();
            this.hidePickerStreamSub = null;
        }
        if (this.confirmSelectedStreamSub) {
            this.confirmSelectedStreamSub.unsubscribe();
            this.confirmSelectedStreamSub = null;
        }
        if (this.pickerOpenedStreamSub) {
            this.pickerOpenedStreamSub.unsubscribe();
            this.pickerOpenedStreamSub = null;
        }
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
        var completeClose = function () {
            if (_this._opened) {
                _this._opened = false;
                _this.afterPickerClosed.emit(null);
                _this.focusedElementBeforeOpen = null;
            }
        };
        if (this.focusedElementBeforeOpen &&
            typeof this.focusedElementBeforeOpen.focus === 'function') {
            this.focusedElementBeforeOpen.focus();
            setTimeout(completeClose);
        }
        else {
            completeClose();
        }
    };
    OwlDateTimeComponent.prototype.confirmSelect = function (event) {
        if (this.isInSingleMode) {
            var selected = this.selected || this.startAt || this.dateTimeAdapter.now();
            this.confirmSelectedChange.emit(selected);
        }
        else if (this.isInRangeMode) {
            this.confirmSelectedChange.emit(this.selecteds);
        }
        this.close();
        return;
    };
    OwlDateTimeComponent.prototype.openAsDialog = function () {
        var _this = this;
        this.dialogRef = this.dialogService.open(OwlDateTimeContainerComponent, {
            autoFocus: false,
            backdropClass: ['cdk-overlay-dark-backdrop'].concat(coerceArray(this.backdropClass)),
            paneClass: ['owl-dt-dialog'].concat(coerceArray(this.panelClass)),
            viewContainerRef: this.viewContainerRef,
            scrollStrategy: this.scrollStrategy || this.defaultScrollStrategy(),
        });
        this.pickerContainer = this.dialogRef.componentInstance;
        this.dialogRef.afterOpen().subscribe(function () {
            _this.afterPickerOpen.emit(null);
            _this._opened = true;
        });
        this.dialogRef.afterClosed().subscribe(function () { return _this.close(); });
    };
    OwlDateTimeComponent.prototype.openAsPopup = function () {
        var _this = this;
        if (!this.pickerContainerPortal) {
            this.pickerContainerPortal = new ComponentPortal(OwlDateTimeContainerComponent, this.viewContainerRef);
        }
        if (!this.popupRef) {
            this.createPopup();
        }
        if (!this.popupRef.hasAttached()) {
            var componentRef = this.popupRef.attach(this.pickerContainerPortal);
            this.pickerContainer = componentRef.instance;
            this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(function () {
                _this.popupRef.updatePosition();
            });
            this.pickerOpenedStreamSub =
                this.pickerContainer.pickerOpenedStream.pipe(take(1)).subscribe(function () {
                    _this.afterPickerOpen.emit(null);
                    _this._opened = true;
                });
        }
    };
    OwlDateTimeComponent.prototype.createPopup = function () {
        var _this = this;
        var overlayConfig = new OverlayConfig({
            positionStrategy: this.createPopupPositionStrategy(),
            hasBackdrop: true,
            backdropClass: ['cdk-overlay-transparent-backdrop'].concat(coerceArray(this.backdropClass)),
            scrollStrategy: this.scrollStrategy || this.defaultScrollStrategy(),
            panelClass: ['owl-dt-popup'].concat(coerceArray(this.panelClass)),
        });
        this.popupRef = this.overlay.create(overlayConfig);
        merge(this.popupRef.backdropClick(), this.popupRef.detachments(), this.popupRef.keydownEvents().pipe(filter(function (event) { return event.keyCode === ESCAPE ||
            (_this._dtInput && event.altKey && event.keyCode === UP_ARROW); }))).subscribe(function () { return _this.close(); });
    };
    OwlDateTimeComponent.prototype.createPopupPositionStrategy = function () {
        return this.overlay.position()
            .flexibleConnectedTo(this._dtInput.elementRef)
            .withTransformOriginOn('.owl-dt-container')
            .withFlexibleDimensions(false)
            .withPush(false)
            .withPositions([
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: -176 },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: -352 },
        ]);
    };
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "backdropClass", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "panelClass", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [Object])
    ], OwlDateTimeComponent.prototype, "startAt", null);
    __decorate([
        Input(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], OwlDateTimeComponent.prototype, "pickerType", null);
    __decorate([
        Input(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], OwlDateTimeComponent.prototype, "pickerMode", null);
    __decorate([
        Input(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [Boolean])
    ], OwlDateTimeComponent.prototype, "disabled", null);
    __decorate([
        Input(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [Boolean])
    ], OwlDateTimeComponent.prototype, "opened", null);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "scrollStrategy", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "afterPickerClosed", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "afterPickerOpen", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "yearSelected", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], OwlDateTimeComponent.prototype, "monthSelected", void 0);
    OwlDateTimeComponent = __decorate([
        Component({
            selector: 'owl-date-time',
            exportAs: 'owlDateTime',
            template: "",
            styles: [""],
            changeDetection: ChangeDetectionStrategy.OnPush,
            preserveWhitespaces: false,
        }),
        __param(5, Optional()),
        __param(6, Inject(OWL_DTPICKER_SCROLL_STRATEGY)),
        __param(7, Optional()), __param(7, Inject(OWL_DATE_TIME_FORMATS)),
        __param(8, Optional()), __param(8, Inject(DOCUMENT)),
        __metadata("design:paramtypes", [Overlay,
            ViewContainerRef,
            OwlDialogService,
            NgZone,
            ChangeDetectorRef,
            DateTimeAdapter, Function, Object, Object])
    ], OwlDateTimeComponent);
    return OwlDateTimeComponent;
}(OwlDateTime));
export { OwlDateTimeComponent };
