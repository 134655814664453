export enum EquipmentStatus {
  RUNNING = 0,
  IDLE = 1,
  ALARM = 2,
}

export enum SensorStatus {
  NORMAL = 0,
  ALARM = 1,
}
