var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var OwlDateTimeIntl = (function () {
    function OwlDateTimeIntl() {
        this.changes = new Subject();
        this.upSecondLabel = 'Add a second';
        this.downSecondLabel = 'Minus a second';
        this.upMinuteLabel = 'Add a minute';
        this.downMinuteLabel = 'Minus a minute';
        this.upHourLabel = 'Add a hour';
        this.downHourLabel = 'Minus a hour';
        this.prevMonthLabel = 'Previous month';
        this.nextMonthLabel = 'Next month';
        this.prevYearLabel = 'Previous year';
        this.nextYearLabel = 'Next year';
        this.prevMultiYearLabel = 'Previous 21 years';
        this.nextMultiYearLabel = 'Next 21 years';
        this.switchToMonthViewLabel = 'Change to month view';
        this.switchToMultiYearViewLabel = 'Choose month and year';
        this.cancelBtnLabel = 'Cancel';
        this.setBtnLabel = 'Set';
        this.rangeFromLabel = 'From';
        this.rangeToLabel = 'To';
        this.hour12AMLabel = 'AM';
        this.hour12PMLabel = 'PM';
    }
    OwlDateTimeIntl.ngInjectableDef = i0.defineInjectable({ factory: function OwlDateTimeIntl_Factory() { return new OwlDateTimeIntl(); }, token: OwlDateTimeIntl, providedIn: "root" });
    OwlDateTimeIntl = __decorate([
        Injectable({ providedIn: 'root' })
    ], OwlDateTimeIntl);
    return OwlDateTimeIntl;
}());
export { OwlDateTimeIntl };
