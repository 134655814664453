<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 32px;
  "
>
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <h1 class="mat-title color-silver" style="margin-bottom: 0">
      <button class="logo-btn" style="margin-right: 8px" title="logo">
        <img
          style="width: 114px"
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>
      Defective Product Management
    </h1>
    <div style="display: flex; align-items: center; position: relative">
      <h3 class="mat-subheading-2 color-silver" style="margin: 0 36px 0 0">
        {{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}
      </h3>
      <button (click)="isMenuOpen = !isMenuOpen">
        <img src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div
        class="login-menu"
        *ngIf="isMenuOpen"
        style="z-index: 150; top: 55px"
      >
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div style="margin-top: 20px; display: flex; align-items: center">
      <h4 class="mat-subheading-1 color-silver" style="margin: 0 16px 0 0">
        Category
      </h4>
      <app-custom-component-dropdown
        [disabled]="categories == null"
        [options]="categories"
        [multiple]="true"
        (optionChange)="onChange('category', $event)"
      ></app-custom-component-dropdown>
      <h4 class="mat-subheading-1 color-silver" style="margin: 0 16px 0 40px">
        Model
      </h4>
      <app-custom-component-dropdown
        [disabled]="models == null"
        [options]="models"
        (optionChange)="onChange('model', $event)"
      ></app-custom-component-dropdown>
    </div>

    <div class="flex-row" style="margin-top: 24px">
      <div class="left-col" style="display: flex; margin: 0 -12px">
        <div style="width: 20%">
          <mat-card class="kpi-info">
            <h4 class="mat-body-2 color-silver" style="margin-bottom: 5px">
              Remaining WIP
            </h4>
            <h4 class="mat-body-2 color-silver">(Yesterday)</h4>
            <h2 class="mat-title color-white">
              {{ defectiveMoObject?.remainingWipQty }}
            </h2>
          </mat-card>
        </div>
        <div style="width: 20%">
          <mat-card class="kpi-info">
            <h4 class="mat-body-2 color-silver" style="margin-bottom: 5px">
              To Be Repaired
            </h4>
            <h4 class="mat-body-2 color-silver">(Today Input)</h4>
            <h2 class="mat-title color-white">
              {{ defectiveMoObject?.toBeRepairedQty }}
            </h2>
          </mat-card>
        </div>
        <div style="width: 20%">
          <mat-card class="kpi-info">
            <h4 class="mat-body-2 color-silver" style="margin-bottom: 5px">
              Complete
            </h4>
            <h4 class="mat-body-2 color-silver">(Today Output)</h4>
            <h2 class="mat-title color-white">
              {{ defectiveMoObject?.complete }}
            </h2>
          </mat-card>
        </div>
        <div style="width: 20%">
          <mat-card class="kpi-info">
            <h4 class="mat-body-2 color-silver" style="margin-bottom: 5px">
              WIP
            </h4>
            <h4 class="mat-body-2 color-silver">(RC)</h4>
            <h2 class="mat-title color-white" style="position: relative">
              {{ defectiveMoObject?.wipQty }}
              <button
                style="
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                "
                (click)="onClickDownload('WIP (RC)')"
              >
                <img
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button>
            </h2>
          </mat-card>
        </div>
        <div style="width: 20%">
          <mat-card class="kpi-info">
            <h4 class="mat-body-2 color-silver" style="margin-bottom: 5px">
              Wait For Repair
            </h4>
            <h4 class="mat-body-2 color-silver">(Production Line)</h4>
            <h2 class="mat-title color-white" style="position: relative">
              {{ defectiveMoObject?.ngQty }}
              <button
                style="
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                "
                (click)="onClickDownload('Wait For Repair (Production Line)')"
              >
                <img
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button>
            </h2>
          </mat-card>
        </div>
      </div>
      <div class="right-col">
        <mat-card class="stats-chart">
          <h3
            class="mat-body-2 color-silver"
            style="margin-bottom: 5px; text-align: center"
          >
            WIP Statistic Chart
          </h3>
          <app-custom-component-bar-chart
            [data]="getBarChartData()"
            [yTicks]="7"
            [xLabel]="'(Days)'"
            [yLabel]="'(Qty)'"
          >
          </app-custom-component-bar-chart>
        </mat-card>
      </div>
    </div>

    <div
      class="flex-row"
      style="
        margin-top: 24px;
        margin-bottom: 5px;
        height: 100%;
        width: 100%;
        min-height: 590px;
      "
    >
      <div
        class="left-col"
        style="height: 100%; min-height: inherit; overflow: hidden"
      >
        <mat-card
          style="
            padding: 30px 25px 5px;
            height: 100%;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            class="ranking-title"
            style="
              margin: 0 0 25px;
              display: flex;
              justify-content: space-between;
            "
          >
            <h3 class="mat-subheading-1 color-white">
              WIP Ranking
              <button
                style="margin-left: 20px"
                (click)="onClickDownload('WIP Ranking')"
              >
                <img
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button>
            </h3>
            <div style="display: flex; justify-content: space-between">
              <div class="mat-body-2 color-silver" style="margin-right: 24px">
                <!-- 下拉選單 Section -->
                <div class="select_tag">
                  <label style="margin-right: 20px; line-height: 48px"
                    >Section</label
                  >
                  <select
                    (change)="moTable.onChangeSection($event.target.value)"
                    [(value)]="moTable.section"
                    [disabled]="moTable.loading"
                  >
                    <option
                      *ngFor="let item of allSectionName"
                      [value]="item.columnName"
                      [selected]="item.columnName === moTable.section"
                    >
                      {{ item.displayName }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- TODO: reset value while changeing category/model -->
              <app-custom-component-text-input
                #textInput
                (keyupEnter)="moTable.onChangeValue($event)"
                [fullWidth]="true"
                [placeholder]="'Search'"
                [disabled]="moTable.loading"
              ></app-custom-component-text-input>
            </div>
          </div>
          <div style="overflow-y: auto; flex: 1">
            <div class="table_scroll_box">
              <!-- 第一層 Table -->
              <div class="table_container mat-elevation-z0">
                <!-- API RES 沒資料 -->
                <div *ngIf="moTable.data.length === 0">
                  <h4
                    class="mat-subheading-1 color-white"
                    style="width: 100%; text-align: center; margin: 32px 0"
                  >
                    No data found.
                  </h4>
                </div>

                <!-- Table 吃 DataSource資料 -->
                <mat-table
                  #table
                  *ngIf="moTable.data.length > 0"
                  [dataSource]="moTable.data"
                  matSort
                  [matSortActive]="moTable.sort"
                  [matSortDirection]="
                    moTable.descending === true ? 'desc' : 'asc'
                  "
                  (matSortChange)="moTable.sortData($event)"
                  multiTemplateDataRows
                >
                  <!-- Part No. Column -->
                  <ng-container matColumnDef="_id">
                    <mat-header-cell
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      *matHeaderCellDef
                      class="fs-16"
                      style="flex-grow: 1.25"
                    >
                      Part No.
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-16 card_link"
                      style="flex-grow: 1.25"
                      (click)="onClickTotalWip(element, 'rankingWip')"
                    >
                      {{ dataFormatSvc.checkValDash(element._id) }}
                    </mat-cell>
                  </ng-container>

                  <!--  Total Column -->
                  <ng-container matColumnDef="ttlTotal">
                    <mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-16"
                      style="flex-grow: 0.4"
                    >
                      Total
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-20 card_link"
                      style="flex-grow: 0.4"
                      (click)="onClickTotalWip(element, 'rankingWip')"
                    >
                      {{ dataFormatSvc.checkValDash(element.ttlTotal) }}
                    </mat-cell>
                  </ng-container>

                  <!--  TTL Input Column -->
                  <ng-container matColumnDef="ttlInput">
                    <mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-16"
                      style="flex-grow: 0.45"
                    >
                      Today Input
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-20"
                      style="flex-grow: 0.45"
                    >
                      {{ dataFormatSvc.checkValDash(element.ttlInput) }}
                    </mat-cell>
                  </ng-container>

                  <!--  TTL COMP. Column -->
                  <ng-container matColumnDef="ttlComp">
                    <mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-16"
                      style="flex-grow: 0.45"
                    >
                      Today COMP.
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-20"
                      style="flex-grow: 0.45"
                    >
                      {{ dataFormatSvc.checkValDash(element.ttlComp) }}
                    </mat-cell>
                  </ng-container>

                  <!-- #1 Column -->
                  <ng-container matColumnDef="function1">
                    <mat-header-cell
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-20"
                      style="flex-grow: 0.95"
                    >
                      #1
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-18"
                      style="flex-grow: 0.95"
                      [ngClass]="{ card_link: element.function1 }"
                      (click)="
                        onClickTotalWip(
                          element,
                          'function',
                          element.groupNameRank[0]?.groupName
                        )
                      "
                    >
                      {{ dataFormatSvc.checkValDash(element.function1) }}
                    </mat-cell>
                  </ng-container>

                  <!-- #2 Column -->
                  <ng-container matColumnDef="function2">
                    <mat-header-cell
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-20"
                      style="flex-grow: 0.95"
                    >
                      #2
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-18"
                      style="flex-grow: 0.95"
                      [ngClass]="{ card_link: element.function2 }"
                      (click)="
                        onClickTotalWip(
                          element,
                          'function',
                          element.groupNameRank[1]?.groupName
                        )
                      "
                    >
                      {{ dataFormatSvc.checkValDash(element.function2) }}
                    </mat-cell>
                  </ng-container>

                  <!-- #3 Column -->
                  <ng-container matColumnDef="function3">
                    <mat-header-cell
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-20"
                      style="flex-grow: 0.95"
                    >
                      #3
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-18"
                      style="flex-grow: 0.95"
                      [ngClass]="{ card_link: element.function3 }"
                      (click)="
                        onClickTotalWip(
                          element,
                          'function',
                          element.groupNameRank[2]?.groupName
                        )
                      "
                    >
                      {{ dataFormatSvc.checkValDash(element.function3) }}
                    </mat-cell>
                  </ng-container>

                  <!-- #4 Column -->
                  <ng-container matColumnDef="function4">
                    <mat-header-cell
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-20"
                      style="flex-grow: 0.95"
                    >
                      #4
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-18"
                      style="flex-grow: 0.95"
                      [ngClass]="{ card_link: element.function4 }"
                      (click)="
                        onClickTotalWip(
                          element,
                          'function',
                          element.groupNameRank[3]?.groupName
                        )
                      "
                    >
                      {{ dataFormatSvc.checkValDash(element.function4) }}
                    </mat-cell>
                  </ng-container>

                  <!-- #5 Column -->
                  <ng-container matColumnDef="function5">
                    <mat-header-cell
                      *matHeaderCellDef
                      disableClear="true"
                      start="desc"
                      class="fs-20"
                      style="flex-grow: 0.95"
                    >
                      #5
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let element; let i = dataIndex"
                      class="fs-18"
                      style="flex-grow: 0.95"
                      [ngClass]="{ card_link: element.function5 }"
                      (click)="
                        onClickTotalWip(
                          element,
                          'function',
                          element.groupNameRank[4]?.groupName
                        )
                      "
                    >
                      {{ dataFormatSvc.checkValDash(element.function5) }}
                    </mat-cell>
                  </ng-container>

                  <!-- 表頭 sticky: true => fix ".mat-header-row" -->
                  <mat-header-row
                    *matHeaderRowDef="moTable.columnsToDisplay; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: moTable.columnsToDisplay"
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>
          </div>
          <mat-paginator
            #moTablePaginator
            *ngIf="moTable.data.length > 0"
            [showFirstLastButtons]="true"
            [length]="moTable.total"
            [pageIndex]="0"
            [pageSize]="moTable.pageSize"
            [disabled]="moTable.loading"
            (page)="moTable.onChangePage($event)"
            class="fs-20"
          >
          </mat-paginator>
        </mat-card>
      </div>
      <div class="right-col">
        <mat-card
          style="
            padding: 25px 30px 5px;
            height: 100%;
            display: flex;
            flex-direction: column;
          "
        >
          <div class="list-title" style="display: flex; flex-direction: column">
            <h3
              class="mat-body-1 color-white"
              style="margin: 0 0 8px"
              [title]=""
            >
              Overdue MO List
              <button
                style="margin-left: 20px"
                (click)="onClickDownload('Overdue MO List')"
              >
                <img
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button>
            </h3>
            <div
              class="overdue-selector"
              style="display: flex; justify-content: space-evenly"
            >
              <div
                class="selector"
                [ngClass]="overdueMoSelectionStatus('almostOverdue')"
                (click)="overdueMoSelection('almostOverdue')"
              >
                Almost Overdue: {{ defectiveMoObject?.almostOverdueQty }}
              </div>
              <div
                class="selector"
                [ngClass]="overdueMoSelectionStatus('overdue')"
                (click)="overdueMoSelection('overdue')"
              >
                Overdue: {{ defectiveMoObject?.overdueQty }}
              </div>
            </div>
          </div>

          <div class="table_scroll_box">
            <!-- 第一層 Table -->
            <div class="table_container mat-elevation-z0">
              <!-- API RES 沒資料 -->
              <div *ngIf="overdueMoDataSource.data.length === 0">
                <h4
                  class="mat-subheading-1 color-white"
                  style="width: 100%; text-align: center; margin: 32px 0"
                >
                  No data found.
                </h4>
              </div>

              <!-- Table 吃 DataSource資料 -->
              <mat-table
                #table
                *ngIf="overdueMoDataSource.data.length > 0"
                [dataSource]="overdueMoDataSource"
                multiTemplateDataRows
                matSort
                [matSortActive]="overdueMoSort.sort"
                [matSortDirection]="
                  overdueMoSort.descending === true ? 'desc' : 'asc'
                "
                (matSortChange)="overdueMoDataSortData($event)"
              >
                <!-- No Column -->
                <ng-container matColumnDef="index">
                  <mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    disableClear="true"
                    start="desc"
                    class=""
                    style="flex-grow: 0.45"
                  >
                    No.
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = dataIndex"
                    class=""
                    style="flex-grow: 0.45"
                  >
                    {{ dataFormatSvc.checkValDash(element.index) }}
                  </mat-cell>
                </ng-container>

                <!-- MO No. Column -->
                <ng-container matColumnDef="mo">
                  <mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 1.6"
                  >
                    MO No.
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = dataIndex"
                    style="flex-grow: 1.6"
                  >
                    {{ dataFormatSvc.checkValDash(element.mo) }}
                  </mat-cell>
                </ng-container>

                <!-- Part No. Column -->
                <ng-container matColumnDef="product">
                  <mat-header-cell
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    *matHeaderCellDef
                    class=""
                    style="flex-grow: 2"
                  >
                    Part No.
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = dataIndex"
                    class=""
                    style="flex-grow: 2"
                  >
                    {{ dataFormatSvc.checkValDash(element.product) }}
                  </mat-cell>
                </ng-container>

                <!--  Total WIP Column -->
                <ng-container matColumnDef="count">
                  <mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    disableClear="true"
                    start="desc"
                    class=""
                    style="flex-grow: 0.6"
                  >
                    Total WIP
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = dataIndex"
                    class="card_link"
                    style="flex-grow: 0.6"
                    (click)="onClickTotalWip(element, 'overdueWip')"
                  >
                    {{ dataFormatSvc.checkValDash(element.count) }}
                  </mat-cell>
                </ng-container>

                <!-- Overdue Days Column -->
                <ng-container matColumnDef="duractionDay">
                  <mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    disableClear="true"
                    start="desc"
                    class=""
                    style="flex-grow: 0.9"
                  >
                    Overdue Days
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = dataIndex"
                    class=""
                    style="flex-grow: 0.9"
                  >
                    {{
                      dataFormatSvc.checkValDash(
                        element.duractionDay === null
                          ? element.duractionDay
                          : element.duractionDay.toFixed(1)
                      )
                    }}
                  </mat-cell>
                </ng-container>

                <!-- 表頭 sticky: true => fix ".mat-header-row" -->
                <mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns">
                </mat-row>
              </mat-table>
            </div>
          </div>
          <!-- page navigation-->
          <mat-paginator
            #MatPaginator
            *ngIf="overdueMoDataSource.data.length > 0"
            [showFirstLastButtons]="true"
            [length]="overdueMoList.total"
            [pageIndex]="overdueMoList.offset / 10"
            [pageSize]="10"
            (page)="overdueMoDataOnPaginateChange($event)"
            style="font-size: 16px; line-height: 19px"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
