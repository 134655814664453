import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from 'src/app/_services/apiSvc.service';

import { HttpClient, } from '@angular/common/http';
import { SaveMonoInformationService } from '../_services/save-mono-information.service';
import { SpinnerService } from '../_services/spinner.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { debounceTime, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
  selector: 'app-quality-overview',
  templateUrl: './quality-overview.component.html',
  styleUrls: ['./quality-overview.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})

export class QualityOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    private moSvc: SaveMonoInformationService,
    private httpClient: HttpClient,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;
  }

  upDateTimer;
  isApiTableData: any;
  filterValue = '';

  isCriticalStationArr = ['Yelllllll', 'Yellll222222222222222lll', 'Yelllllll', 'Yelllllll', 'Yelllllll', 'Yelllllll', 'Yelllllll', 'Yelllllll', ];

  isFirstModel;
  isFirstStation;
  selectValueChange;

  isRankingModelName = '';
  isRankingStationName = '';

  rankingModelNameArr: any = [];
  rankingStationNameArr: any = [];
  rankingListData: any = [
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '0',
      percent: '0',
    }
  ];

  dataSource;
  expandedElement: any = {};
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // 第一層標題
  // displayedColumns: string[] = ['product', 'order', 'startTime', 'yieldRate', 'criticalStation'];
  displayedColumns: string[] = ['product', 'order', 'inputQuantity', 'defectQuantity', 'yieldRate', 'criticalStation', ];

  rxTimer$ = function() {
    return interval(1000 * 60).pipe(
      tap(res => console.log('rxTimer => ', res)),
      switchMap(res => this.getAllApiFn$())
    ).subscribe();
  };

  ngOnInit(): void {
    this.rxTimer$();
    this.getAllApiFn$().subscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    }, 600);
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
    this.rxTimer$().unsubscribe();
  }

  // Arr null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }

  // table Search input filter
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  // check mono. from mo service
  getMoItem(item) {
    console.log('item:', item);
    this.moSvc.customer = item.customer === '' ? '-' : item.customer;
    this.moSvc.product = item.product === '' ? '-' : item.product;
    this.moSvc.order = item.order === '' ? '-' : item.order;
    this.moSvc.startTime = item.startTime === '' ? '-' : item.startTime;
    this.moSvc.inputQty = item.inputQuantity === '' ? '-' : item.inputQuantity;
    this.moSvc.defectQuantity = item.defectQuantity === '' ? '-' : item.defectQuantity;
    this.moSvc.yieldRate = item.yieldRate === '' ? '-' : item.yieldRate;
    this.moSvc.lowestYield = item.criticalStation === '' ? '-' : item.criticalStation;
    console.log('item.criticalStation:', item.criticalStation);
  }

  // Defect Ranking 抓下拉選單變動 => modelName
  getModelName(value) {
    this.isFirstModel = value;
    this.getApiStationName$(this.isFirstModel).pipe(
      switchMap(res => this.getRankTopTenData$(this.isFirstModel, this.isFirstStation)),
    ).subscribe();
  }

  // Defect Ranking  抓下拉選單變動 => stationName
  getStationName(value) {
    this.selectValueChange = '';
    this.selectValueChange = value;
    // call api function
    this.getRankTopTenData$(this.isFirstModel, this.selectValueChange).pipe(
      take(1)
    ).subscribe();
  }

  // table 資料處理 依時間排序
  tableSortTime(Arr) {
    this.isApiTableData = Arr.sort((a, b) => {
      return (b.startTime < a.startTime) ? -1 : ((b.startTime > a.startTime) ? 1 : 0) || a.order.localeCompare(b.order);
    });
    return this.isApiTableData;
  }

  // table Pipe資料處理
  tablePipe(Arr) {
    Arr.map((item) => {
      if (item.startTime !== '-') {
        item.startTime = this.datePipe.transform(item.startTime, 'yyyy/M/dd HH:mm');
      }
      if (item.yieldRate !== '-') {
        item.yieldRate = this.numberPipe.transform(item.yieldRate * 100, '0.2-2');
      }

      item.isCriticalStationArr = item.criticalStation;
      item.criticalStation = item.criticalStation === null ? '-' : item.criticalStation[0];

      return item.criticalStation;
    });
    return Arr;
  }

  // table 將資料帶入 tableDataSource
  giveTableDataSource(Arr) {
    this.dataSource = new MatTableDataSource<any>(this.isApiTableData);
    this.ngAfterViewInit();
    return Arr;
  }

  // API modelName 資料
  getApiModelData$() {
    return this.apiSvc.getQualityOverviewModelData().pipe(
      // tap(res => { console.log('getApiModelData row data => ', res); }),
      map(res => {
        res.sort((a, b) => (a + '').localeCompare(b + ''));
        this.isFirstModel = res[0];
        this.rankingModelNameArr = res;
        this.rankingModelNameArr.shift();
        return res;
      }),
      // tap(res => { console.log('getApiModelData res:', res); }),
    );
  }

  // API stationName 資料
  getApiStationName$(modelName) {
    return this.apiSvc.getQualityOverviewStationData(modelName).pipe(
      // tap(res => { console.log('getApiStationName row data => ', res); }),
      map(res => {
        res.sort((a, b) => (a + '').localeCompare(b + ''));
        this.rankingStationNameArr = res;
        this.isFirstStation = res[0];
        this.selectValueChange = this.isFirstStation;
        return res;
      }),
      // tap(res => { console.log('getApiStationName res:', res); }),
    );
  }

  // API rankin 前十資料
  getRankTopTenData$(modelName, stationName) {
    return this.apiSvc.getQualityOverviewRankTopTenData(modelName, stationName).pipe(
      // tap(res => { console.log('getRankTopTenData row data => ', res); }),
      map(res => {
        res.map(x => {
          return x.percent = +x.percent * 100;
        });
        this.rankingListData = res;
        this.isSvLoading.loading = false;
        return res;
      }),
      // tap(res => { console.log('getRankTopTenData res:', res); }),
    );
  }

  // Call API$ table 資料
  getTableData$() {
    return this.apiSvc.getQualityOverviewTableData().pipe(
      tap(res => console.log('getTableData row data res =>', res)),
      map(res => this.nullToDash(res)),
      map(res => this.tablePipe(res)),
      map(res => this.tableSortTime(res)),
      map(res => this.giveTableDataSource(res)),
      tap(res => console.log('getTableData final res =>', res)),
    );
  }

  // Call API$ ModelStation
  getAllApiFn$() {
    return this.getApiModelData$().pipe(
      switchMap((res) => this.getTableData$()),
      switchMap((res) => this.getApiStationName$(this.isFirstModel)),
      switchMap((res) => this.getRankTopTenData$(this.isFirstModel, this.isFirstStation)),
    );
  }

}
