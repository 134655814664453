<h1 class="mat-title color-silver" style="margin-bottom: 0">Unit List</h1>
<!-- TODO: font styles -->
<ng-container *ngIf="rootCauseTable.loading">
  <h3 style="font-size: 24px; margin: 32px; text-align: center; color: white">
    Loading...
  </h3>
</ng-container>

<ng-container *ngIf="!rootCauseTable.loading">
  <app-custom-component-table
    [outerTableColumns]="rootCauseTable.columns"
    [outerTableColumnsToDisplay]="rootCauseTable.columnsToDisplay"
    [outerTableHeaderSticky]="true"
    [outerTableDataSource]="rootCauseTable.getSlicedData()"
  >
  </app-custom-component-table>
</ng-container>
<div style="margin-top: 56px">
  <mat-paginator
    [showFirstLastButtons]="true"
    [length]="rootCauseTable.total"
    [pageIndex]="rootCauseTable.pageIndex"
    [pageSize]="rootCauseTable.pageSize"
    (page)="rootCauseTable.onChangePage($event)"
  >
  </mat-paginator>
</div>
