import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-custom-component-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() placeholder: string;

  @Input() fullWidth: boolean;

  @Input() disabled: boolean;

  @Output() valueChange = new EventEmitter<string>();

  @Output() keyupEnter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      const value = event.target.value;
      this.valueChange.emit(value);
    }
  }

  onEnter(value: string): void {
    this.keyupEnter.emit(value);
  }

}
