import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isFocus;

  curModule = {
    moduleName: '',
    img: '',
    linkUrl: '',
    queryParams: Object
  };

  menuList = [
    {
      moduleName: 'Acbel Plant Overview',
      img: '../../assets/img/menu/img1_main_board_phase3.png',
      linkUrl: 'main-board'
    },
    {
      moduleName: 'Production Efficiency Overview',
      img: '../../assets/img/menu/img2_equipment_efficiency_overview_phase3.png',
      linkUrl: 'equipment-efficiency-overview'
    },
    {
      moduleName: 'Production Overview',
      img: '../../assets/img/menu/img3_production_overview_management_phase3.png',
      linkUrl: 'production-overview-management'
    },
    {
      moduleName: 'MES Closed Orders Overview',
      img: '../../assets/img/menu/img4_close_order_overview_phase3.png',
      linkUrl: 'close-order-overview'
    },
    {
      moduleName: 'Equipments Alarm',
      img: '../../assets/img/menu/img5_equip_alarm_phase3.png',
      linkUrl: 'equip-alarm-all',
      queryParams: { section: 'SMT' }
    },
    {
      moduleName: 'Defective Product Management',
      img: '../../assets/img/menu/img6_repair_management_phase3.png',
      linkUrl: 'repair-management'
    },
    {
      moduleName: 'Quality Overview',
      img: '../../assets/img/menu/img7_quality_overview_phase3.png',
      linkUrl: 'quality-overview'
    },
    {
      moduleName: 'SMT Drop Rate Overview',
      img: '../../assets/img/menu/img8_drop_rate_overview_phase3.png',
      linkUrl: 'drop-rate-overview'
    },
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  changePage() {
    if (this.curModule.queryParams !== null) {
      const navigationExtras: NavigationExtras = {
        queryParams: this.curModule.queryParams
      };
      this.router.navigate([this.curModule.linkUrl], navigationExtras);
    }
    else {
      this.router.navigate([this.curModule.linkUrl]);
    }
  }
}
