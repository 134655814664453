import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForTestDataService {

  constructor(

  ) { }

  // 新主看板 ************************


  // Phase3 主看板 ************************
  devicesStatus = {
    SMT: [
      {
        sourceId: 'ACBT1CM016',
        section: 'SMT',
        line: '6S-C',
        type: 'chipMounter',
        manufacture: 'Panasonic',
        model: 'NPM-W2',
        deviceType: 'CM',
        deviceName: 'NPM-W2',
        building: 'BUILD-A',
        floor: '6F',
        area: 'BuA-6F-01',
        xTop: 898,
        yLeft: 1307,
        widthScalePx: 80,
        heightScalePx: 60,
        status: 0
      },
      {
        sourceId: 'ACBT1CM021',
        section: 'SMT',
        line: '6S-D',
        type: 'chipMounter',
        manufacture: 'JUKI',
        model: 'KE-2070',
        deviceType: 'CM',
        deviceName: 'KE-2070',
        building: 'BUILD-A',
        floor: '6F',
        area: 'BuA-6F-01',
        xTop: 941,
        yLeft: 1392,
        widthScalePx: 80,
        heightScalePx: 60,
        status: 1
      }
    ],
    LOD: [
      {
        sourceId: 'ACBT1WS012',
        section: 'LOD',
        line: '6L-D',
        type: 'waveSoldering',
        manufacture: '日樁',
        model: 'JC-350LFL',
        deviceType: 'WS',
        deviceName: 'JC-350LFL',
        building: 'BUILD-A',
        floor: '6F',
        area: 'BuA-6F-02',
        xTop: 1149,
        yLeft: 1934,
        widthScalePx: 80,
        heightScalePx: 60,
        status: 2
      },
      {
        sourceId: 'ACBT1WS013',
        section: 'LOD',
        line: '6L-A',
        type: 'waveSoldering',
        manufacture: 'Nordson',
        model: 'Integra(TM) 508.2PD',
        deviceType: 'WS',
        deviceName: 'Integra(TM) 508.2PD',
        building: 'BUILD-A',
        floor: '6F',
        area: 'BuA-6F-02',
        xTop: 1079,
        yLeft: 1631,
        widthScalePx: 80,
        heightScalePx: 60,
        status: null
      }
    ],
    pressLight: [
      {
        area: 'BuA-6F-02',
        status: 1,
        xTop: 1079,
        yLeft: 1631,
        widthScalePx: 80,
        heightScalePx: 60
      },
      {
        area: 'BuA-6F-02',
        status: 1,
        xTop: 941,
        yLeft: 1031,
        widthScalePx: 30,
        heightScalePx: 20
      }
    ]
  };


  // 環境模組 ************************


  // 總用電量 ************************


  // 品質履歷 第一頁 Quality Overview************************


  // 品質履歷 內頁 MO Quality************************
  stationPerformancesData = [
    {
      functionName: 'ATE1',
      totalQty: 7,
      passQty: 1,
      defectQty: 6,
      fpy: 0.1
    },
    {
      functionName: 'ATE2',
      totalQty: 8,
      passQty: 2,
      defectQty: 7,
      fpy: 0.2
    },
    {
      functionName: 'ATE3',
      totalQty: 9,
      passQty: 3,
      defectQty: 8,
      fpy: 0.30000000000000004
    },
    {
      functionName: 'ATE4',
      totalQty: 10,
      passQty: 4,
      defectQty: 9,
      fpy: 0.4
    },
    {
      functionName: 'ATE5',
      totalQty: 11,
      passQty: 5,
      defectQty: 10,
      fpy: 0.5
    },
    {
      functionName: 'ATE6',
      totalQty: 12,
      passQty: 6,
      defectQty: 11,
      fpy: 0.6000000000000001
    },
    {
      functionName: 'ATE7',
      totalQty: 13,
      passQty: 7,
      defectQty: 12,
      fpy: 0.7000000000000001
    },
    {
      functionName: 'ATE8',
      totalQty: 14,
      passQty: 8,
      defectQty: 13,
      fpy: 0.8
    },
    {
      functionName: 'ATE9',
      totalQty: 15,
      passQty: 9,
      defectQty: 14,
      fpy: 0.9
    },
    {
      functionName: 'ATE10',
      totalQty: 16,
      passQty: 10,
      defectQty: 15,
      fpy: 1
    }
  ];
  stationPerformancesChildData = [
    {
      stationName: 'Child_ATE1',
      totalQty: 7,
      passQty: 1,
      defectQty: 6,
      fpy: 0.1
    },
    {
      stationName: 'Child_ATE2',
      totalQty: 8,
      passQty: 2,
      defectQty: 7,
      fpy: 0.2
    },
    {
      stationName: 'Child_ATE3',
      totalQty: 9,
      passQty: 3,
      defectQty: 8,
      fpy: 0.30000000000000004
    },
    {
      stationName: 'Child_ATE4',
      totalQty: 10,
      passQty: 4,
      defectQty: 9,
      fpy: 0.4
    },
    {
      stationName: 'Child_ATE5',
      totalQty: 11,
      passQty: 5,
      defectQty: 10,
      fpy: 0.5
    },
    {
      stationName: 'Child_ATE6',
      totalQty: 12,
      passQty: 6,
      defectQty: 11,
      fpy: 0.6000000000000001
    },
    {
      stationName: 'Child_ATE7',
      totalQty: 13,
      passQty: 7,
      defectQty: 12,
      fpy: 0.7000000000000001
    },
    {
      stationName: 'Child_ATE8',
      totalQty: 14,
      passQty: 8,
      defectQty: 13,
      fpy: 0.8
    },
    {
      stationName: 'Child_ATE9',
      totalQty: 15,
      passQty: 9,
      defectQty: 14,
      fpy: 0.9
    },
    {
      stationName: 'Child_ATE10',
      totalQty: 16,
      passQty: 10,
      defectQty: 15,
      fpy: 1
    }
  ];

  moQualityDefectRankStationName = [
    {
      value: 'ATE1',
      name: 'ATE1'
    },
    {
      value: 'ATE2',
      name: 'ATE2'
    },
    {
      value: 'ATE3',
      name: 'ATE3'
    },
    {
      value: 'ATE4',
      name: 'ATE4'
    },
    {
      value: 'ATE5',
      name: 'ATE5'
    },
    {
      value: 'ATE6',
      name: 'ATE6'
    },
    {
      value: 'ATE7',
      name: 'ATE7'
    },
    {
      value: 'ATE8',
      name: 'ATE8'
    },
    {
      value: 'ATE9',
      name: 'ATE9'
    },
    {
      value: 'ATE10',
      name: 'ATE10'
    }
  ];
  moQualityDefectRankTopTen = [
    {
      causeName: '1',
      defectQty: 10,
      defectPercentage: 0.1
    },
    {
      causeName: '2',
      defectQty: 20,
      defectPercentage: 0.2
    },
    {
      causeName: '3',
      defectQty: 30,
      defectPercentage: 0.30000000000000004
    },
    {
      causeName: '4',
      defectQty: 40,
      defectPercentage: 0.4
    },
    {
      causeName: '5',
      defectQty: 50,
      defectPercentage: 0.5
    },
    {
      causeName: '6',
      defectQty: 60,
      defectPercentage: 0.6000000000000001
    },
    {
      causeName: '7',
      defectQty: 70,
      defectPercentage: 0.7000000000000001
    },
    {
      causeName: '8',
      defectQty: 80,
      defectPercentage: 0.8
    },
    {
      causeName: '9',
      defectQty: 90,
      defectPercentage: 0.9
    },
    {
      causeName: '10',
      defectQty: 100,
      defectPercentage: 1
    }
  ];

  // 人員工時 ************************
  newProductHourData = {
    totalDataLength: 100,
    msg: 'Efficiency can not count result. ',
    infos: [
      {
        sn: 'CALASH140002',
        product: 'OTL002-D01G',
        targetOutput: 483,
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        efficiency: 0,
        child: [
          {
            duration: 15400,
            section: 'SMT',
            line: 'SMT-A',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 110,
            downTime: 2990,
            changeover: 3,
            actProductionTime: 999,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.95,
            output: 19,
            efficiency: 1.85
          },
          {
            duration: 15400,
            section: 'SMT',
            line: 'SMT-B',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 3620,
            downTime: 2150,
            changeover: 3,
            actProductionTime: 185,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.99,
            output: 19,
            efficiency: 0.85
          },
          {
            duration: 15400,
            section: 'SMT',
            line: 'SMT-C',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T12:33:53.000Z',
            restTime: 1110,
            downTime: 1220,
            changeover: 3,
            actProductionTime: 1325,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.933,
            output: 19,
            efficiency: 0.96
          },
        ],
      },
      {
        sn: 'CALCNJ010001',
        product: 'Load_OTL002-D01G',
        targetOutput: 483,
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        efficiency: 0,
        child: [
          {
            duration: 15400,
            section: 'Load',
            line: 'Load-A',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 111000,
            downTime: 1520,
            changeover: 3,
            actProductionTime: 999,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.92,
            output: 19,
            efficiency: 0.85
          },
          {
            duration: 15400,
            section: 'Load',
            line: 'Load-B',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 11150,
            downTime: 2220,
            changeover: 3,
            actProductionTime: 185,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.92,
            output: 19,
            efficiency: 0.85
          },
          {
            duration: 15400,
            section: 'Load',
            line: 'Load-C',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T12:33:53.000Z',
            restTime: 111,
            downTime: 2150,
            changeover: 3,
            actProductionTime: 1325,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.92,
            output: 19,
            efficiency: 0.97
          },
        ],
      },
      {
        sn: 'CALCK0840001',
        product: 'ATE_OTL002-D01G',
        targetOutput: 483,
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        efficiency: 0,
        child: [
          {
            duration: 15400,
            section: 'ATE',
            line: 'ATE-A',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 10,
            downTime: 2019,
            changeover: 3,
            actProductionTime: 999,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.97,
            output: 19,
            efficiency: 0.110
          },
          {
            duration: 15400,
            section: 'ATE',
            line: 'ATE-B',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T09:33:53.000Z',
            restTime: 368555,
            downTime: 1120,
            changeover: 3,
            actProductionTime: 185,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.92,
            output: 19,
            efficiency: 0.92
          },
          {
            duration: 15400,
            section: 'ATE',
            line: 'ATE-C',
            targetOutput: 483,
            side: 'A',
            startTime: '2021-09-13T05:07:33.000Z',
            endTime: '2024-09-13T12:33:53.000Z',
            restTime: 2500,
            downTime: 200,
            changeover: 3,
            actProductionTime: 1325,
            operators: 2,
            actTime: 188,
            cycleTime: 8.397,
            standardTime: 79.92,
            output: 19,
            efficiency: 0.85
          },
        ],
      },
      // {
      //   sn: 'CALASH1400024',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH140012',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH1410002',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH1400202',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH1401002',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH140772',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH141402',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH140662',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH144002',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH140092',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH148002',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALASH140555',
      //   product: 'OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 110,
      //       downTime: 2990,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.95,
      //       output: 19,
      //       efficiency: 1.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 3620,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.99,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'SMT',
      //       line: 'SMT-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 1110,
      //       downTime: 1220,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.933,
      //       output: 19,
      //       efficiency: 0.96
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCNJ010001',
      //   product: 'Load_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 111000,
      //       downTime: 1520,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 11150,
      //       downTime: 2220,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //     {
      //       duration: 15400,
      //       section: 'Load',
      //       line: 'Load-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 111,
      //       downTime: 2150,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.97
      //     },
      //   ],
      // },
      // {
      //   sn: 'CALCK0840001',
      //   product: 'ATE_OTL002-D01G',
      //   targetOutput: 483,
      //   startTime: '2021-09-13T05:07:33.000Z',
      //   endTime: '2024-09-13T09:33:53.000Z',
      //   efficiency: 0,
      //   child: [
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-A',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 10,
      //       downTime: 2019,
      //       changeover: 3,
      //       actProductionTime: 999,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.97,
      //       output: 19,
      //       efficiency: 0.110
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-B',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T09:33:53.000Z',
      //       restTime: 368555,
      //       downTime: 1120,
      //       changeover: 3,
      //       actProductionTime: 185,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.92
      //     },
      //     {
      //       duration: 15400,
      //       section: 'ATE',
      //       line: 'ATE-C',
      //       targetOutput: 483,
      //       side: 'A',
      //       startTime: '2021-09-13T05:07:33.000Z',
      //       endTime: '2024-09-13T12:33:53.000Z',
      //       restTime: 2500,
      //       downTime: 200,
      //       changeover: 3,
      //       actProductionTime: 1325,
      //       operators: 2,
      //       actTime: 188,
      //       cycleTime: 8.397,
      //       standardTime: 79.92,
      //       output: 19,
      //       efficiency: 0.85
      //     },
      //   ],
      // }
    ]
  };


  // 生產管理 ************************
  newProductOverviewStatusNameArr = [
    'In production',
    'Not in production',
    'Waiting',
    'Complete',
  ];

  newProductOverviewData = {
    totalDataLength: 100,
    msg: 'Efficiency can not count result. ',
    infos: [
      {
        product: 'OTL002-99SV',
        sn: 'CALAK0810003',
        status: 'Complete',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        actProductionTime: 4520,
        targetOutput: 483,
        input: 1000,
        output: 155,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 0.95,
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'Waiting',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 5000,
          },
          {
            line: 'SMT-B',
            section: 'Loading',
            side: 'B',
            status: 'In production',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 5500,
          },
          {
            line: 'SMT-C',
            section: 'ATE',
            side: 'B',
            status: 'Complete',
            startTime: '2021-09-02T05:07:33.000Z',
            endTime: '2024-09-11T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 4000,
            standardTime: 8,
          },
          {
            line: 'ASSY-A',
            section: 'ASSY',
            side: 'A',
            status: 'Not in production',
            startTime: '2021-09-02T05:07:33.000Z',
            endTime: '2024-09-11T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'ASSY-B',
            section: 'ASSY',
            side: 'B',
            status: 'Not in production',
            startTime: '2021-09-02T05:07:33.000Z',
            endTime: '2024-09-11T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'PKG-A',
            section: 'PKG',
            side: 'A',
            status: 'Complete',
            startTime: '2021-09-02T05:07:33.000Z',
            endTime: '2024-09-11T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
        ],
      },
      {
        product: 'OTL002-AAA8',
        sn: 'CALAK0810003',
        status: 'In production',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        actProductionTime: 250,
        targetOutput: 483,
        input: 3344,
        output: 1200,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 0.88,
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'In production',
            startTime: '2021-09-22T05:07:33.000Z',
            endTime: '2024-09-28T09:33:53.000Z',
            actProductionTime: 4320,
            targetOutput: 359,
            input: 1000,
            output: 999,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 0.344555,
            standardTime: 5000,
          },
          {
            line: 'SMT-B',
            section: 'SMT',
            side: 'B',
            status: 'Waiting',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 420,
            targetOutput: 359,
            input: 1592,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 0.344555,
            standardTime: 100,
          },
        ],
      },
      {
        product: 'OTL002-A582',
        sn: 'CALAK0810003',
        status: 'Not in production',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        actProductionTime: 522,
        targetOutput: 483,
        input: 33,
        output: 9,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 1.08,
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'Waiting',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 4520,
            targetOutput: 359,
            input: 1125,
            output: 1505,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
        ],
      },
      {
        product: 'OTL002-1115',
        sn: 'CALAK0810003',
        status: 'Not in production',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        actProductionTime: 4520,
        targetOutput: 483,
        input: 330,
        output: 94,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 0.97,
        child: [],
      },
      {
        product: 'OTL002-D0154',
        sn: 'CALAK0810003',
        status: 'In production',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-15T09:33:53.000Z',
        actProductionTime: 85020,
        targetOutput: 483,
        input: 33,
        output: 9,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 1.20,
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'Waiting',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 41520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'SMT-B',
            section: 'SMT',
            side: 'B',
            status: 'Waiting',
            startTime: '2021-09-21T05:07:33.000Z',
            endTime: '2024-09-11T09:33:53.000Z',
            actProductionTime: 45210,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
        ],
      },
      {
        product: 'OTL002-1524',
        sn: 'CALAK0810003',
        status: 'Waiting',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-13T09:33:53.000Z',
        actProductionTime: 45129,
        targetOutput: 483,
        input: 33,
        output: 9,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: 0,
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'In production',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 45219,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'SMT-B',
            section: 'SMT',
            side: 'B',
            status: 'In production',
            startTime: '2021-09-21T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 2520,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'SMT-C',
            section: 'SMT',
            side: 'B',
            status: 'Waiting',
            startTime: '2021-09-21T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 4519,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
        ],
      },
      {
        product: 'OTL015-D0154',
        sn: 'CALAK0810003',
        status: 'In production',
        startTime: '2021-09-13T05:07:33.000Z',
        endTime: '2024-09-15T09:33:53.000Z',
        actProductionTime: 4920,
        targetOutput: 483,
        input: 33,
        output: 9,
        achieveRate: 0.652,
        outputYield: 0.787,
        efficiency: '-',
        child: [
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'Waiting',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 45129,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
          {
            line: 'SMT-A',
            section: 'SMT',
            side: 'A',
            status: 'Complete',
            startTime: '2021-09-20T05:07:33.000Z',
            endTime: '2024-09-31T09:33:53.000Z',
            actProductionTime: 35220,
            targetOutput: 359,
            input: 25,
            output: 15,
            achieveRate: 0.35112,
            outputYield: 0.78117,
            efficiency: 0.8820,
            cycleTime: 9952.344555,
            standardTime: 82.5454,
          },
        ],
      }
    ]
  };



  // 維修管理 ************************


  // 人員環境安全 ************************


  // 設備效能總覽看板 ************************
  allLinesArr =
    [
      {
        section: 'SMT',
        lines: [
          '1C123AAY',
          'FLY PROBE',
          'ICT SMT',
          'MI',
          'Record',
          'SMT-A',
          'SMT-B',
          'SMT-C',
          'SMT-D',
          'SMT-F',
          'Testing PCBA SMT'
        ]
      },
      {
        section: 'Loading',
        lines: [
          '1C123AAY',
          'ASSY-BE-A',
          'ASSY-BE-B',
          'ASSY-BE-C',
          'ASSY-BE-D',
          'ASSY-BE-E',
          'ASSY-BE-F',
          'ASSY-KPE',
          'ASSY-PDU',
          'COATING',
          'COM',
          'Connector-1',
          'Connector-2',
          'H.S.加工',
          'ICT TU',
          'LOD-A',
          'LOD-B',
          'LOD-C',
          'LOD-D',
          'LOD-E',
          'Routing 1',
          'Routing 2',
          'SMT-B',
          'SMT-CUT',
          'TUA',
          'TUB',
          'TUC',
          'TUD',
          'TUE',
          'Testing FUN-BE',
          'Testing PCBA',
          '湧錫爐'
        ]
      },
      {
        section: 'PKG',
        lines: [
          'PACK-A',
          'PACK-AMAZON',
          'PACK-B',
          'PACK-BE',
          'PACK-BE-KPE',
          'PACK-C',
          'PACK-GOGORO',
          'PACK-PDU',
          'PACK-Turntide',
          'PACK-電表',
          'PPR',
          'Testing FUN-NORMAL',
          'eRMA'
        ]
      },
      {
        section: 'ASY',
        lines: [
          'ASSY-A',
          'ASSY-AMAZON',
          'ASSY-B',
          'ASSY-BE-A',
          'ASSY-BE-B',
          'ASSY-BE-C',
          'ASSY-BE-D',
          'ASSY-BE-E',
          'ASSY-BE-F',
          'ASSY-C',
          'ASSY-D',
          'ASSY-Gogoro',
          'ASSY-JABIL',
          'ASSY-KPE',
          'ASSY-MAZDA',
          'ASSY-PDU',
          'ASSY-VRM',
          'ASSY-電表A',
          'ASSY-電表B',
          'ASSY-電表C',
          'LOD-B',
          'PACK-A',
          'Testing BI HASS',
          'Testing FUN-BE',
          'Testing FUN-NORMAL',
          'Testing PCBA',
          'Testing PCBA SMT'
        ]
      }
    ];

  allLinesObjStatusArr = [
    {
      section: 'SMT',
      lines: [
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'Loaing',
      lines: [
        {
          lineName: 'LOD-A',
          isChecked: false,
        },
        {
          lineName: 'LOD-B',
          isChecked: false,
        },
        {
          lineName: 'LOD-C',
          isChecked: false,
        },
        {
          lineName: 'LOD-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'ASY',
      lines: [
        {
          lineName: 'ASY-A',
          isChecked: false,
        },
        {
          lineName: 'ASY-B',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'PKG',
      lines: [
        {
          lineName: 'PKG-A',
          isChecked: false,
        },
        {
          lineName: 'PKG-B',
          isChecked: false,
        },
        {
          lineName: 'PKG-C',
          isChecked: false,
        },
        {
          lineName: 'PKG-D',
          isChecked: false,
        },
      ]
    }
  ];


  // 已結工單 看板 ************************


  // 設備異常 看板 By Line ************************
  allSectionName = ['SMT', 'Loading', 'TEST'];
  allLineName = ['Line-A', 'Line-B', 'Line-C', 'Line-D'];
  equipResDataArr = {
    line: 'Line A',
    latestAlarm: 'Line-2-2070-22070-2',
    alarmCode: 'No money',
    latestAlarmTime: 1636187154689,
    lineRunning: 0.54111,
    lineIdle: 0.485111,
    lineDownTime: 1.542211,
    equipInfo: [
      {
        equip: ' CM2070 ',
        status: 1,
        downtime: 652, // (sec)
        recordNum: 15,
        utilization: 0.54111,
        downTimeRate: 0.54111,
        idleRate: 0.54111,
      },
      {
        equip: ' CM2071 ',
        status: 2,
        downtime: 125, // (sec)
        recordNum: 15,
        utilization: 0.54111,
        downTimeRate: 0.54111,
        idleRate: 0.54111,
      },
      {
        equip: ' CM2072 ',
        status: 3,
        downtime: 152, // (sec)
        recordNum: 15,
        utilization: 0.54111,
        downTimeRate: 0.54111,
        idleRate: 0.54111,
      },
      {
        equip: ' CM2033-33 ',
        status: 1,
        downtime: 52, // (sec)
        recordNum: 12,
        utilization: 0.5781,
        downTimeRate: 0.14111,
        idleRate: 0.111,
      },
      {
        equip: ' CM2034-444',
        status: 1,
        downtime: 300, // (sec)
        recordNum: 10,
        utilization: 0.3781,
        downTimeRate: 0.45111,
        idleRate: 0.211,
      },
      {
        equip: ' CM2035-55',
        status: 1,
        downtime: 520, // (sec)
        recordNum: 51,
        utilization: 0.1781,
        downTimeRate: 0.45111,
        idleRate: 0.311,
      },
    ],
  };

  rankingModelNameArray = ['CM2070', 'CM2071', 'CM2072', 'CM2073', 'CM2074', 'CM2075', 'SP6666', 'SP7777777', 'SP88888', 'SP99999', 'SP10101010'];
  rankingListData = [
    {
      errorCode: 'Error code-1',
      count: '90.89',
    },
    {
      errorCode: 'Error 75757575 code-2',
      count: '80.89',
    },
    {
      errorCode: 'Error 57575 code-3',
      count: '70.89',
    },
    {
      errorCode: 'Error 7575 code-4',
      count: '60.89',
    },
    {
      errorCode: 'Error 7575 code-5',
      count: '50.89',
    },
    {
      errorCode: 'Error 757 code-6',
      count: '40.89',
    },
    {
      errorCode: 'Error 7575757 code-7',
      count: '38.89',
    },
    {
      errorCode: 'Error 7557575 code-8',
      count: '24.89',
    },
    {
      errorCode: 'Error 484848 code-9',
      count: '18.89',
    },
    {
      errorCode: 'Error 4575757575 code-10',
      count: '08.89',
    }
  ];

  rankingModelNameArrEmpty = ['-'];
  rankingListDataEmpty = [
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
  ];

  rankingListDataEmptyPhase3 = [
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
    {
      errorCode: '-',
      count: '-',
    },
  ];

  // 設備異常 看板 By MO ************************
  newEquipOverviewResTestData = {
    limit: 10,
    offset: 0,
    total: 10,
    data: [
      {
        partNo: 'pn001',
        moNo: 'mo001',
        moQty: 10,
        inputQty: 5,
        defectQty: 2,
        rty: 0.1,
        lowestYield: 'moNo1_lowestYield',
        children: [
          {
            line: 'mo001_1_line',
            section: 'mo001_1_section',
            side: 'mo001_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn002',
        moNo: 'mo002',
        moQty: 20,
        inputQty: 10,
        defectQty: 3,
        rty: 0.2,
        lowestYield: 'moNo2_lowestYield',
        children: [
          {
            line: 'mo002_1_line',
            section: 'mo002_1_section',
            side: 'mo002_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo002_2_line',
            section: 'mo002_2_section',
            side: 'mo002_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn003',
        moNo: 'mo003',
        moQty: 30,
        inputQty: 15,
        defectQty: 4,
        rty: 0.3,
        lowestYield: null,
        children: [
          {
            line: 'mo003_1_line',
            section: 'mo003_1_section',
            side: 'mo003_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo003_2_line',
            section: 'mo003_2_section',
            side: 'mo003_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo003_3_line',
            section: 'mo003_3_section',
            side: 'mo003_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          }
        ]
      },
      {
        partNo: 'pn004',
        moNo: 'mo004',
        moQty: 40,
        inputQty: 20,
        defectQty: 5,
        rty: 0.4,
        lowestYield: 'moNo4_lowestYield',
        children: [
          {
            line: 'mo004_1_line',
            section: 'mo004_1_section',
            side: 'mo004_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo004_2_line',
            section: 'mo004_2_section',
            side: 'mo004_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo004_3_line',
            section: 'mo004_3_section',
            side: 'mo004_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo004_4_line',
            section: 'mo004_4_section',
            side: 'mo004_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn005',
        moNo: 'mo005',
        moQty: 50,
        inputQty: 25,
        defectQty: 6,
        rty: 0.5,
        lowestYield: 'moNo5_lowestYield',
        children: [
          {
            line: 'mo005_1_line',
            section: 'mo005_1_section',
            side: 'mo005_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo005_2_line',
            section: 'mo005_2_section',
            side: 'mo005_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo005_3_line',
            section: 'mo005_3_section',
            side: 'mo005_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo005_4_line',
            section: 'mo005_4_section',
            side: 'mo005_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo005_5_line',
            section: 'mo005_5_section',
            side: 'mo005_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn006',
        moNo: 'mo006',
        moQty: 60,
        inputQty: 30,
        defectQty: 7,
        rty: 0.6,
        lowestYield: null,
        children: [
          {
            line: 'mo006_1_line',
            section: 'mo006_1_section',
            side: 'mo006_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo006_2_line',
            section: 'mo006_2_section',
            side: 'mo006_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo006_3_line',
            section: 'mo006_3_section',
            side: 'mo006_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo006_4_line',
            section: 'mo006_4_section',
            side: 'mo006_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo006_5_line',
            section: 'mo006_5_section',
            side: 'mo006_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          },
          {
            line: 'mo006_6_line',
            section: 'mo006_6_section',
            side: 'mo006_6_side',
            inputQty: 6,
            defectQty: 7,
            fpy: 0.6,
            lowestYield: null
          }
        ]
      },
      {
        partNo: 'pn007',
        moNo: 'mo007',
        moQty: 70,
        inputQty: 35,
        defectQty: 8,
        rty: 0.7,
        lowestYield: 'moNo7_lowestYield',
        children: [
          {
            line: 'mo007_1_line',
            section: 'mo007_1_section',
            side: 'mo007_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo007_2_line',
            section: 'mo007_2_section',
            side: 'mo007_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo007_3_line',
            section: 'mo007_3_section',
            side: 'mo007_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo007_4_line',
            section: 'mo007_4_section',
            side: 'mo007_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo007_5_line',
            section: 'mo007_5_section',
            side: 'mo007_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          },
          {
            line: 'mo007_6_line',
            section: 'mo007_6_section',
            side: 'mo007_6_side',
            inputQty: 6,
            defectQty: 7,
            fpy: 0.6,
            lowestYield: null
          },
          {
            line: 'mo007_7_line',
            section: 'mo007_7_section',
            side: 'mo007_7_side',
            inputQty: 7,
            defectQty: 8,
            fpy: 0.7,
            lowestYield: 'moNo7_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn008',
        moNo: 'mo008',
        moQty: 80,
        inputQty: 40,
        defectQty: 9,
        rty: 0.8,
        lowestYield: 'moNo8_lowestYield',
        children: [
          {
            line: 'mo008_1_line',
            section: 'mo008_1_section',
            side: 'mo008_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo008_2_line',
            section: 'mo008_2_section',
            side: 'mo008_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo008_3_line',
            section: 'mo008_3_section',
            side: 'mo008_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo008_4_line',
            section: 'mo008_4_section',
            side: 'mo008_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo008_5_line',
            section: 'mo008_5_section',
            side: 'mo008_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          },
          {
            line: 'mo008_6_line',
            section: 'mo008_6_section',
            side: 'mo008_6_side',
            inputQty: 6,
            defectQty: 7,
            fpy: 0.6,
            lowestYield: null
          },
          {
            line: 'mo008_7_line',
            section: 'mo008_7_section',
            side: 'mo008_7_side',
            inputQty: 7,
            defectQty: 8,
            fpy: 0.7,
            lowestYield: 'moNo7_lowestYield'
          },
          {
            line: 'mo008_8_line',
            section: 'mo008_8_section',
            side: 'mo008_8_side',
            inputQty: 8,
            defectQty: 9,
            fpy: 0.8,
            lowestYield: 'moNo8_lowestYield'
          }
        ]
      },
      {
        partNo: 'pn009',
        moNo: 'mo009',
        moQty: 90,
        inputQty: 45,
        defectQty: 10,
        rty: 0.90000004,
        lowestYield: null,
        children: [
          {
            line: 'mo009_1_line',
            section: 'mo009_1_section',
            side: 'mo009_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo009_2_line',
            section: 'mo009_2_section',
            side: 'mo009_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo009_3_line',
            section: 'mo009_3_section',
            side: 'mo009_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo009_4_line',
            section: 'mo009_4_section',
            side: 'mo009_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo009_5_line',
            section: 'mo009_5_section',
            side: 'mo009_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          },
          {
            line: 'mo009_6_line',
            section: 'mo009_6_section',
            side: 'mo009_6_side',
            inputQty: 6,
            defectQty: 7,
            fpy: 0.6,
            lowestYield: null
          },
          {
            line: 'mo009_7_line',
            section: 'mo009_7_section',
            side: 'mo009_7_side',
            inputQty: 7,
            defectQty: 8,
            fpy: 0.7,
            lowestYield: 'moNo7_lowestYield'
          },
          {
            line: 'mo009_8_line',
            section: 'mo009_8_section',
            side: 'mo009_8_side',
            inputQty: 8,
            defectQty: 9,
            fpy: 0.8,
            lowestYield: 'moNo8_lowestYield'
          },
          {
            line: 'mo009_9_line',
            section: 'mo009_9_section',
            side: 'mo009_9_side',
            inputQty: 9,
            defectQty: 10,
            fpy: 0.90000004,
            lowestYield: null
          }
        ]
      },
      {
        partNo: 'pn010',
        moNo: 'mo010',
        moQty: 100,
        inputQty: 50,
        defectQty: 11,
        rty: 1,
        lowestYield: 'moNo10_lowestYield',
        children: [
          {
            line: 'mo010_1_line',
            section: 'mo010_1_section',
            side: 'mo010_1_side',
            inputQty: 1,
            defectQty: 2,
            fpy: 0.1,
            lowestYield: 'moNo1_lowestYield'
          },
          {
            line: 'mo010_2_line',
            section: 'mo010_2_section',
            side: 'mo010_2_side',
            inputQty: 2,
            defectQty: 3,
            fpy: 0.2,
            lowestYield: 'moNo2_lowestYield'
          },
          {
            line: 'mo010_3_line',
            section: 'mo010_3_section',
            side: 'mo010_3_side',
            inputQty: 3,
            defectQty: 4,
            fpy: 0.3,
            lowestYield: null
          },
          {
            line: 'mo010_4_line',
            section: 'mo010_4_section',
            side: 'mo010_4_side',
            inputQty: 4,
            defectQty: 5,
            fpy: 0.4,
            lowestYield: 'moNo4_lowestYield'
          },
          {
            line: 'mo010_5_line',
            section: 'mo010_5_section',
            side: 'mo010_5_side',
            inputQty: 5,
            defectQty: 6,
            fpy: 0.5,
            lowestYield: 'moNo5_lowestYield'
          },
          {
            line: 'mo010_6_line',
            section: 'mo010_6_section',
            side: 'mo010_6_side',
            inputQty: 6,
            defectQty: 7,
            fpy: 0.6,
            lowestYield: null
          },
          {
            line: 'mo010_7_line',
            section: 'mo010_7_section',
            side: 'mo010_7_side',
            inputQty: 7,
            defectQty: 8,
            fpy: 0.7,
            lowestYield: 'moNo7_lowestYield'
          },
          {
            line: 'mo010_8_line',
            section: 'mo010_8_section',
            side: 'mo010_8_side',
            inputQty: 8,
            defectQty: 9,
            fpy: 0.8,
            lowestYield: 'moNo8_lowestYield'
          },
          {
            line: 'mo010_9_line',
            section: 'mo010_9_section',
            side: 'mo010_9_side',
            inputQty: 9,
            defectQty: 10,
            fpy: 0.90000004,
            lowestYield: null
          },
          {
            line: 'mo010_10_line',
            section: 'mo010_10_section',
            side: 'mo010_10_side',
            inputQty: 10,
            defectQty: 11,
            fpy: 1,
            lowestYield: 'moNo10_lowestYield'
          }
        ]
      }
    ]
  };


  // 設備效能 看板 By Line ************************


  // TEST 設備效能 看板 ************************
  ateEfficiencyByMoData = {
    sn: 'CE8VG04010',
    product: 'VRG004-030G',
    startTime: 1651174391603,
    moStartTime: 1651174391603,
    moEndTime: 1658974391603,
    targetOutput: 132,
    output: 132,
    achieveRate: 1,
    stationInfo: [
      {
        itemName: 'function1',
        groupname: 'gm1',
        equipmentQty: 1,
        startTime: 1651974391603,
        toBeTestedQty: 100,
        outputQty: 100,
        efficiency: 0.77,
        cycleTime: 1234556,
        avgCycleTime: 1234567,
        failQty: 10,
        failReport: 20,
        stationList: []
      },
      {
        itemName: 'function2',
        groupname: 'gm2',
        equipmentQty: 1,
        startTime: 1653374391603,
        toBeTestedQty: 100,
        outputQty: 100,
        efficiency: 0.77,
        cycleTime: 1234556,
        avgCycleTime: 1234567,
        failQty: 10,
        failReport: 20,
        stationList: []
      },
      {
        itemName: 'function3',
        groupname: 'gm3',
        equipmentQty: 1,
        startTime: null,
        toBeTestedQty: 100,
        outputQty: 100,
        efficiency: null,
        cycleTime: 1234556,
        avgCycleTime: 1234567,
        failQty: 10,
        failReport: 20,
        stationList: []
      },
    ]
  };

  ateStationEfficiencyData = [
    {
      stationName: 'LOADING HI-POT',
      efficiency: '1.5',
      standardTime: '6000',
      actProdTime: '5600'
    },
    {
      stationName: 'LOADING HI-POT',
      efficiency: '1.25',
      standardTime: '6000',
      actProdTime: '5600'
    },
    {
      stationName: 'HASS A Station',
      efficiency: '1',
      standardTime: '6000',
      actProdTime: '5600'
    },
    {
      stationName: 'SMT HI-POT 1',
      efficiency: '0.98',
      standardTime: '6000',
      actProdTime: '5600'
    },
    {
      stationName: 'SMT HI-POT 1',
      efficiency: '0.68',
      standardTime: '6000',
      actProdTime: '5600'
    },
    {
      stationName: 'SMT HI-POT 1',
      efficiency: '0.45',
      standardTime: '6000',
      actProdTime: '5600'
    },
  ];

  // WIP OVERVIEW 看板 ************************
  wipOverviewTestData = [
    {
      moNo: 'CE1AM222C-001',
      product: 'RSMT-35420100-1',
      targetOutput: 1200,
      line: ['SMT-A', 'SMT-B', 'LOD-A', 'LOD-B', 'LOD-C'],
      startTime: '1617848827358',
      lines: [
        {
          lineLName: 'SMT-A',
          sideName: 'A',
          inputCount: 1600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'Reflow out',
              countNum: 201,
            },
            {
              stationName: 'AOI',
              countNum: 201,
            },
            {
              stationName: 'FPT',
              countNum: 201,
            },
            {
              stationName: 'SMT-OUT',
              countNum: 20,
            },
          ]
        },
        {
          lineLName: 'SMT-B',
          sideName: 'B',
          inputCount: 1000,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 742,
            },
          ]
        },
        {
          lineLName: 'SMT-C',
          sideName: '-',
          inputCount: 600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 520,
            },
          ]
        },
        {
          lineLName: 'SMT-B',
          sideName: 'B',
          inputCount: 1000,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 742,
            },
          ]
        },
        {
          lineLName: 'SMT-C',
          sideName: '-',
          inputCount: 600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 520,
            },
          ]
        },
        {
          lineLName: 'SMT-B',
          sideName: 'B',
          inputCount: 1000,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 742,
            },
          ]
        },
        {
          lineLName: 'SMT-C',
          sideName: '-',
          inputCount: 600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 520,
            },
          ]
        }
      ]
    },
    {
      moNo: 'CE1AM222C-002',
      product: 'RSMT-3542010-2',
      targetOutput: 1200,
      line: ['SMT-A'],
      startTime: '1637841127358',
      lines: [
        {
          lineLName: 'LOD-A',
          sideName: 'A',
          inputCount: 1100,
          pointStation: [
            {
              stationName: 'SMT-Start',
              countNum: 201,
            },
            {
              stationName: 'Reflow out',
              countNum: 201,
            },
            {
              stationName: 'Reflow out',
              countNum: 201,
            },
            {
              stationName: 'Reflow out',
              countNum: 201,
            },
            {
              stationName: 'Reflow out',
              countNum: 201,
            },
            {
              stationName: 'AOI',
              countNum: 201,
            },
            {
              stationName: 'FPT',
              countNum: 201,
            },
            {
              stationName: 'SMT-OUT',
              countNum: 20,
            },
          ]
        }
      ]
    },
    {
      moNo: 'CE1AM222C-003',
      product: 'RSMT-35420100-3',
      targetOutput: 1200,
      line: ['SMT-A', 'SMT-B'],
      startTime: '1617848807358',
      lines: [
        {
          lineLName: 'SMT-A',
          sideName: 'A',
          inputCount: 1600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'FPT',
              countNum: 201,
            },
            {
              stationName: 'FPT',
              countNum: 201,
            },
            {
              stationName: 'SMT-OUT',
              countNum: 20,
            },
          ]
        },
        {
          lineLName: 'SMT-B',
          sideName: 'B',
          inputCount: 1000,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 742,
            },
          ]
        },
        {
          lineLName: 'SMT-C',
          sideName: '-',
          inputCount: 600,
          pointStation: [
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 201,
            },
            {
              stationName: 'SMT-IN',
              countNum: 520,
            },
          ]
        }
      ]
    },
  ];

  // PHASE3 WIP OVERVIEW 看板 ************************
  wipMockData =
    {
      mo: 'CALASD100007',
      product: 'RLOD-SGD010-9G0G-1',
      targetOutput: 8500,
      line: ['SMT-A', 'SMT-B', 'LOD-A'],
      startTime: 1658359068000,
      DaysRemainingUntilClosing: -100,
      routeInfo: [
        {
          index: '1',
          output: 8500,
          groupName: '1040 SMT IN A',
          status: 1,
        },
        {
          status: 1,
          output: 8500,
          index: '1',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '2',
          groupName: '1000 PRE-SMT'
        },
        {
          status: 1,
          output: 8500,
          index: '3',
          groupName: '1062 POWER ON'
        },
        {
          status: 1,
          output: 8500,
          index: '4',
          groupName: 'BURN-OUT'
        },
        {
          status: 1,
          output: 8500,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '1',
          groupName: 'PACKING STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: '2',
          groupName: 'QC維修'
        },
        {
          status: 1,
          output: 8500,
          index: '3',
          groupName: 'SMT Reflow outA'
        },
        {
          status: 1,
          output: 8500,
          index: '4',
          groupName: 'TEST STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '1',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '2',
          groupName: '1000 PRE-SMT'
        },
        {
          status: 1,
          output: 8500,
          index: '3',
          groupName: '1062 POWER ON'
        },
        {
          status: 1,
          output: 8500,
          index: '4',
          groupName: 'BURN-OUT'
        },
        {
          status: 1,
          output: 8500,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '1',
          groupName: 'PACKING STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: '2',
          groupName: 'QC維修'
        },
        {
          status: 1,
          output: 8500,
          index: '3',
          groupName: 'SMT Reflow outA'
        },
        {
          status: 1,
          output: 8500,
          index: '4',
          groupName: 'TEST STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '39',
          groupName: 'PACKING STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: '50',
          groupName: 'QC維修'
        },
        {
          status: 1,
          output: 8500,
          index: '23',
          groupName: 'SMT Reflow outA'
        },
        {
          status: 1,
          output: 8500,
          index: '4',
          groupName: 'TEST STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 1,
          output: 8500,
          index: '1',
          groupName: 'PACKING STOCK'
        },
        {
          status: 1,
          output: 8500,
          index: '12',
          groupName: 'QC維修'
        },
        {
          status: 2,
          output: 300,
          index: '13',
          groupName: 'SMT Reflow outA'
        },
        {
          status: 3,
          output: 0,
          index: '14',
          groupName: 'TEST STOCK'
        },
        {
          status: 3,
          output: 0,
          index: 'out',
          groupName: '1020 AI'
        },
        {
          status: 3,
          output: 0,
          index: '11',
          groupName: 'PACKING STOCK'
        },
        {
          status: 4,
          output: null,
          index: '2',
          groupName: 'QC維修'
        },
        {
          status: 4,
          output: null,
          index: '3',
          groupName: 'SMT Reflow outA'
        },
        {
          status: null,
          output: null,
          index: '4',
          groupName: 'TEST STOCK'
        },
        {
          status: 3,
          output: 0,
          index: 'out',
          groupName: '1020 AI'
        },
      ]
    };

  // PHASE3 SMT DROP RATE OVERVIEW 看板 ************************
  dropRateMockData = [
    {
      lineName: '5S-A',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          monthPn: 600000,
          monthDpr: 40000,
          weekPn: 20000,
          weekDpr: 1200,
          dayPn: 2000,
          dayDpr: 10,
        }
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM3
          equipInx: 2,
          equipName: 'JUKI KE-2080',
          dpr: 700,
          dpn: 10,
          pn: 2000,
        }
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 10178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2055.134292396003
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1511.3104524180967
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1312.877452267527
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 2164.5021645021643
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 3028.336577979667
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1886.317907444668
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1624.9593760155994
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1350.1350135013502
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1250.7817385866167
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 7407.407407407408
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 2159.3608291945584
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 1602.8209648982208
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 974.6588693957115
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 1032.9244673983217
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 889.6331571510807
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 754.6164772727273
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 2643.3173632909297
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 2732.516951725534
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1999.1727561009236
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1617.7619100747518
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1554.8979284746954
            }
          ]
        }
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          dpr: 2000,
          dpn: 4,
          pn: 2000,
        }
      ],
    },
    {
      lineName: '5S-B',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          monthPn: 600000,
          monthDpr: 40000,
          weekPn: 20000,
          weekDpr: 1200,
          dayPn: 2000,
          dayDpr: 10,
        }
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM3
          equipInx: 2,
          equipName: 'JUKI KE-2080',
          dpr: 700,
          dpn: 10,
          pn: 2000,
        }
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 10178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2055.134292396003
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1511.3104524180967
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1312.877452267527
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 2164.5021645021643
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 3028.336577979667
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1886.317907444668
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1624.9593760155994
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1350.1350135013502
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1250.7817385866167
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 7407.407407407408
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 2159.3608291945584
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 1602.8209648982208
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 974.6588693957115
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 1032.9244673983217
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 889.6331571510807
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 754.6164772727273
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 2643.3173632909297
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 2732.516951725534
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1999.1727561009236
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1617.7619100747518
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1554.8979284746954
            }
          ]
        }
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          dpr: 2000,
          dpn: 4,
          pn: 2000,
        }
      ],
    },
    {
      lineName: '6S-A',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 10178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
      ],
    },
    {
      lineName: '6S-B',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          monthPn: 600000,
          monthDpr: 40000,
          weekPn: 20000,
          weekDpr: 1200,
          dayPn: 2000,
          dayDpr: 10,
        }
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM3
          equipInx: 2,
          equipName: 'JUKI KE-2080',
          dpr: 700,
          dpn: 10,
          pn: 2000,
        }
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 10178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2055.134292396003
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1511.3104524180967
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1312.877452267527
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 2164.5021645021643
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 3028.336577979667
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1886.317907444668
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1624.9593760155994
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1350.1350135013502
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1250.7817385866167
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 7407.407407407408
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 2159.3608291945584
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 1602.8209648982208
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 974.6588693957115
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 1032.9244673983217
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 889.6331571510807
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 754.6164772727273
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 2643.3173632909297
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 2732.516951725534
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1999.1727561009236
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1617.7619100747518
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1554.8979284746954
            }
          ]
        }
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          dpr: 2000,
          dpn: 4,
          pn: 2000,
        }
      ],
    },
    {
      lineName: '6S-C',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          monthPn: 600000,
          monthDpr: 40000,
          weekPn: 20000,
          weekDpr: 1200,
          dayPn: 2000,
          dayDpr: 10,
        }
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM3
          equipInx: 2,
          equipName: 'JUKI KE-2080',
          dpr: 700,
          dpn: 10,
          pn: 2000,
        }
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 1178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2055.134292396003
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1511.3104524180967
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1312.877452267527
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 2164.5021645021643
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 3028.336577979667
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1886.317907444668
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1624.9593760155994
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1350.1350135013502
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1250.7817385866167
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 7407.407407407408
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 2159.3608291945584
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 1602.8209648982208
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 974.6588693957115
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 1032.9244673983217
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 889.6331571510807
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 754.6164772727273
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 2643.3173632909297
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 2732.516951725534
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1999.1727561009236
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1617.7619100747518
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1554.8979284746954
            }
          ]
        }
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          dpr: 2000,
          dpn: 4,
          pn: 2000,
        }
      ],
    },
    {
      lineName: '6S-D',
      compare: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          monthPn: 600000,
          monthDpr: 354,
          weekPn: 20000,
          weekDpr: 200,
          dayPn: 2000,
          dayDpr: 150,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          monthPn: 600000,
          monthDpr: 2000,
          weekPn: 20000,
          weekDpr: 2500,
          dayPn: 2000,
          dayDpr: 1050,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          monthPn: 600000,
          monthDpr: 40000,
          weekPn: 20000,
          weekDpr: 1200,
          dayPn: 2000,
          dayDpr: 10,
        }
      ],
      nowStatus: [
        {
          // CM1
          equipInx: 0,
          equipName: 'JUKI KE-750',
          dpr: 650,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM2
          equipInx: 1,
          equipName: 'JUKI KE-2070',
          dpr: 50,
          dpn: 0,
          pn: 2000,
        },
        {
          // CM3
          equipInx: 2,
          equipName: 'JUKI KE-2080',
          dpr: 700,
          dpn: 10,
          pn: 2000,
        }
      ],
      chart: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2148.8432060740633
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 2418.9646831156265
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 2028.547191384207
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 930.6654257794323
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1241.955515411539
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1199.9494758115447
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1226.938107788785
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1438.6366151462614
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1703.8234954372185
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 1862.7755355479665
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 2525.2525252525256
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 252.39777889954567
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 180.2451333813987
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 420.47724166929464
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 409.1653027823241
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 603.2686190632884
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 779.6734544120345
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 747.1049682480389
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 735.5259010192287
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 804.1523503180057
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 997.1259311396562
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 955.7691286571444
            }
          ]
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2062.5099159130573
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1451.6045414484938
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1309.5238095238094
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 1037.6134889753566
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 737.9083651975582
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 689.4853484363457
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 794.0289026520566
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 617.5698625907056
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 798.7632053594434
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 530.5039787798408
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 10178.117048346057
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 6294.964028776978
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 4690.048939641109
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 3146.3599869805794
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 2657.8550391494864
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 2407.014728637744
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 2408.728097104803
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 2550.308826459454
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 212.58503401360542
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 793.6507936507936
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1082.2510822510822
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1229.256299938537
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1085.4227721697603
            }
          ]
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          data: [
            {
              time: '2022-11-02T07:00:00.000Z',
              value: 2055.134292396003
            },
            {
              time: '2022-11-02T08:00:00.000Z',
              value: 1511.3104524180967
            },
            {
              time: '2022-11-02T09:00:00.000Z',
              value: 1312.877452267527
            },
            {
              time: '2022-11-02T10:00:00.000Z',
              value: 2164.5021645021643
            },
            {
              time: '2022-11-02T11:00:00.000Z',
              value: 3028.336577979667
            },
            {
              time: '2022-11-02T12:00:00.000Z',
              value: 1886.317907444668
            },
            {
              time: '2022-11-02T13:00:00.000Z',
              value: 1624.9593760155994
            },
            {
              time: '2022-11-02T14:00:00.000Z',
              value: 1350.1350135013502
            },
            {
              time: '2022-11-02T15:00:00.000Z',
              value: 1250.7817385866167
            },
            {
              time: '2022-11-02T16:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T17:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-02T18:00:00.000Z',
              value: 7407.407407407408
            },
            {
              time: '2022-11-02T19:00:00.000Z',
              value: 2159.3608291945584
            },
            {
              time: '2022-11-02T20:00:00.000Z',
              value: 1602.8209648982208
            },
            {
              time: '2022-11-02T21:00:00.000Z',
              value: 974.6588693957115
            },
            {
              time: '2022-11-02T22:00:00.000Z',
              value: 1032.9244673983217
            },
            {
              time: '2022-11-02T23:00:00.000Z',
              value: 889.6331571510807
            },
            {
              time: '2022-11-03T00:00:00.000Z',
              value: 754.6164772727273
            },
            {
              time: '2022-11-03T01:00:00.000Z',
              value: 0
            },
            {
              time: '2022-11-03T02:00:00.000Z',
              value: 2643.3173632909297
            },
            {
              time: '2022-11-03T03:00:00.000Z',
              value: 2732.516951725534
            },
            {
              time: '2022-11-03T04:00:00.000Z',
              value: 1999.1727561009236
            },
            {
              time: '2022-11-03T05:00:00.000Z',
              value: 1617.7619100747518
            },
            {
              time: '2022-11-03T06:00:00.000Z',
              value: 1554.8979284746954
            }
          ]
        }
      ],
      dayHistory: [
        {
          // CM1
          equipName: 'JUKI KE-750',
          dpr: 0,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM2
          equipName: 'JUKI KE-2070',
          dpr: 500,
          dpn: 1,
          pn: 2000,
        },
        {
          // CM3
          equipName: 'JUKI KE-2080',
          dpr: 2000,
          dpn: 4,
          pn: 2000,
        }
      ],
    },
  ];
}
