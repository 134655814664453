<div class="production_hours_container">
  <div class="title_group">
    <h1 class="title_name">
      <button class="logo-btn" style="margin-right: 24px" title="logo">
        <img
          style="width: 114px"
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>
      Equipments Alarm
    </h1>
    <div class="dropdown_box">
      <label>Category:</label>
      <!-- TODO: get sections by API -->
      <select (change)="switchSection($event.target.value)">
        <option
          *ngFor="let item of sectionNameArr"
          [selected]="item === apiParamsObj.sectionName"
          [value]="item"
        >
          {{ sectionDisplayMappingObj[item] }}
        </option>
      </select>
    </div>
    <div class="time">
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
        <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>

  <!-- Line & Time-Range group -->
  <div class="line_box">
    <!-- 下拉選單 Line -->
    <div class="select_tag">
      <label>Floor:</label>
      <select (change)="SWSctOptF($event.target.value)">
        <option *ngFor="let item of FOptArr" [selected]="item === FOptTmp">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="select_tag">
      <label>Device Type:</label>
      <select (change)="SWSctOptDT($event.target.value)">
        <option *ngFor="let item of DTArr" [selected]="item === DTTmp">
          {{ item }}
        </option>
      </select>
  <div *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div class="container-fluid container_box">
      <!-- Computation between Time range -->
      <div class="time_range">
        <label>Date Range:</label>
        <input
          [(ngModel)]="selectedMoments"
          [min]="minDate"
          [max]="maxDate"
          [selectMode]="'range'"
          [owlDateTime]="dtPicker"
          (dateTimeChange)="getRangeDate()"
        />
        <mat-datepicker-toggle
          [owlDateTimeTrigger]="dtPicker"
          style="margin-left: 12px"
        ></mat-datepicker-toggle>
        <img
          class="default_button"
          src="../../../assets/img/btn_date_default.svg"
          alt="Default"
          style="margin-left: 12px"
          (click)="defaultDateTime()"
        />
      </div>
      <owl-date-time
        #dtPicker
        (afterPickerOpen)="defaultDateTimeRange()"
      ></owl-date-time>
    </div>

    <!-- Computation between Time range -->
    <div class="time_range">
      <label>Date Range:</label>
      <input
        [(ngModel)]="selectedMoments"
        [min]="minDate"
        [max]="maxDate"
        [selectMode]="'range'"
        [owlDateTime]="dtPicker"
        (dateTimeChange)="getRangeDate()"
      />
      <mat-datepicker-toggle
        [owlDateTimeTrigger]="dtPicker"
        style="margin-left: 12px"
      ></mat-datepicker-toggle>
      <img
        class="default_button"
        src="../../../assets/img/btn_date_default.svg"
        alt="Default"
        style="margin-left: 12px"
        (click)="defaultDateTime()"
      />
    </div>
  </div>
  <owl-date-time
    #dtPicker
    (afterPickerOpen)="defaultDateTimeRange()"
  ></owl-date-time>

  <div class="container_box">
    <ng-container
      *ngIf="
        apiSvc.ifSSO('Administrator,1E-Editor,Viewer');
        else PermissionDenied
      "
    >
      <div class="table_group">
        <table
          mat-table
          *ngIf="dataSource.data.length > 0"
          [dataSource]="dataSource"
          matSort
          (matSortChange)="SctSort($event)"
          [matSortActive]="List.sort"
          [matSortDirection]="List.descending === true ? 'desc' : 'asc'"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="floor">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by floor"
              disableClear="true"
              start="desc"
            >
              Floor
            </th>
            <td mat-cell *matCellDef="let element">
              {{ dataFormatSvc.checkValDash(element?.floor) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deviceType">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by deviceType"
              disableClear="true"
              start="desc"
            >
              Device Type
            </th>
            <td mat-cell *matCellDef="let element">
              {{ dataFormatSvc.checkValDash(element?.deviceType) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deviceName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by deviceName"
              disableClear="true"
              start="desc"
            >
              Device Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ dataFormatSvc.checkValDash(element?.deviceName) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="runLight">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by runLight"
              disableClear="true"
              start="desc"
            >
              Run
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.runLight; else runLight">
                <div class="b_r rc_g"></div>
              </div>
              <ng-template #runLight><div class="b_r"></div></ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="idleLight">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by idleLight"
              disableClear="true"
              start="desc"
            >
              Idle
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.idleLight; else idleLight">
                <div class="b_r rc_y"></div>
              </div>
              <ng-template #idleLight><div class="b_r"></div></ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="errorLight">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by errorLight"
              disableClear="true"
              start="desc"
            >
              Error
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.errorLight; else errorLight">
                <div class="b_r rc_r"></div>
              </div>
              <ng-template #errorLight><div class="b_r"></div></ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="runRate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by runRate"
              disableClear="true"
              start="desc"
            >
              Run %
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.runRate === null
                  ? "-"
                  : (element?.runRate * 100 | number: "1.2-2")
              }}
              %
            </td>
          </ng-container>

          <ng-container matColumnDef="idleRate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by idleRate"
              disableClear="true"
              start="desc"
            >
              Idle %
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.idleRate === null
                  ? "-"
                  : (element?.idleRate * 100 | number: "1.2-2")
              }}
              %
            </td>
          </ng-container>

          <ng-container matColumnDef="downRate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by downRate"
              disableClear="true"
              start="desc"
            >
              Down %
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.downRate === null
                  ? "-"
                  : (element?.downRate * 100 | number: "1.2-2")
              }}
              %
            </td>
          </ng-container>

          <ng-container matColumnDef="downTime">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by downTime"
              disableClear="true"
              start="desc"
            >
              Down T.
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.downTime === null
                  ? "-"
                  : dataFormatSvc.secToDDhhmmss(element?.downTime)
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="pic">
            <th mat-header-cell *matHeaderCellDef>PIC</th>
            <td mat-cell *matCellDef="let element">
              {{ dataFormatSvc.checkValDash(element?.pic) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="errorCode">
            <th mat-header-cell *matHeaderCellDef>Error Code</th>
            <td mat-cell *matCellDef="let element">
              {{ dataFormatSvc.checkValDash(element?.errorCode) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          *ngIf="dataSource.data.length > 0"
          [showFirstLastButtons]="true"
          [length]="List.total"
          [pageIndex]="List.offset / 12"
          [pageSize]="12"
          (page)="onChangePage($event)"
        >
        </mat-paginator>
      </div>
    </ng-container>
    <ng-template #PermissionDenied>
      <div style="text-align: center; font-size: 48px">Permission denied</div>
    </ng-template>

    <!-- Auto Refresh -->
    <div class="refresh">
      <!-- Kanban Renewed & Snap Shot -->
      <div class="update_time">
        <p class="renewed">
          <span class="title">Kanban Renewed:</span>
          <span class="value">{{
            renewedTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
        <p class="snapshot">
          <span class="title">Snap Shot:</span>
          <span class="value">{{
            snapshotTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
      </div>
      <button class="relaod_btn" (click)="reloadCurrentPageClick()">
        <img src="../../assets/img/btn_relaod.svg" alt="reload" />
      </button>
      <div class="refresh_btn">
        <label>
          <span class="text">Auto Refresh</span>
          <input
            type="checkbox"
            class="checkbox"
            [checked]="pageAutoRefresh"
            (change)="switchPageAutoRefresh()"
          />
          <span class="btn-box">
            <span class="btn"></span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
