import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { DataSource } from '@angular/cdk/collections';
import { fromEvent, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, } from 'rxjs/operators';

// angular-material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { animate, query, state, style, transition, trigger } from '@angular/animations';

import { HttpClient, } from '@angular/common/http';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

// import variables
import { SORT_KEY, SORT_DIRECTION } from 'src/app/_variable/production-overview-types';

// app module.ts
// 日期
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
// Component
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; // 日期
import * as moment from 'moment';
import * as _ from 'lodash';

// interface
import { EfficiencyApiData, DetailListType } from '../core/models/popupInfo.model';

@Component({
  // 設定 changeDetection 屬性,當設定為 OnPush 時，
  // 只有在元件的 @Input 變更，且真正有變更時，才會進行變更偵測。
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-phase3-production-overview',
  templateUrl: './phase3-production-overview.component.html',
  styleUrls: ['./phase3-production-overview.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('iconExpand', [
      state('collapsed, void', style({ transform: 'rotate(90deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})
export class Phase3ProductionOverviewComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,

    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
    // 監測所有元件控制變更時機
    private changeDetectorRef: ChangeDetectorRef,

    private FB: FormBuilder,
  ) {
    this.isSvLoading.loading = true;
  }

  isExpandChildStatus = false;
  isMenuOpen = false;
  isLogin = false;

  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  range = new FormGroup({
    start: new FormControl(),
    // start: new FormControl(moment(new Date().setHours(8, 0, 0)).subtract(7, 'days')),
    end: new FormControl(),
    // end: new FormControl(moment(new Date().setHours(7, 59, 0))),
  });

  isRangeStartTime = this.range.controls.start.value;
  isRangeEndTime = this.range.controls.end.value;

  startDate = moment();
  minDate = moment().subtract(7, 'days');
  maxDate = moment();

  isTotalPageNum = null;
  queryObj = {
    search: null,
    status: null,
    section: null,
    startTime: null,
    endTime: moment().endOf('day').add(1, 'days').hours(7).valueOf(),
    sort: null,
    offset: 1,
    limit: 12,
  };


  // dataSource: MatTableDataSource<UserData>;
  DATA = [];
  outDataSource = new MatTableDataSource(); // 初始化MatTable 表格資料
  usersData = [];

  tempSaveItemExpandeStatusArr = [];

  // Select icon choices
  dataConditionsSelected = false;
  columnPickerSelected = false;
  filterValue = '';

  statusSelectedTempAry: string[] = ['In production'];
  statusAry: string[] = ['In production'];
  allStatusAry: string[] = ['In production', 'Not in production', 'Waiting', 'DSF Complete', 'Force Closed', 'Closed'];

  sectionSelectedTempAry: string[] = ['SMT', 'LOD', 'ASY', 'PKG', 'TEST'];
  sectionAry: string[] = ['SMT', 'LOD', 'ASY', 'PKG', 'TEST'];
  allSectionAry: string[] = ['SMT', 'LOD', 'ASY', 'PKG', 'TEST'];

  apiEncodeStatusStr = null;
  apiEncodeSectionStr = null;

  dataConditionsStatusSelectionStatus = [
    { columnName: 'In production', checked: true, displayName: 'In production' },
    { columnName: 'Not in production', checked: false, displayName: 'Not in production' },
    { columnName: 'Waiting', checked: false, displayName: 'Waiting' },
    { columnName: 'DSF Complete', checked: false, displayName: 'DSF Complete' },
    { columnName: 'Force Closed', checked: false, displayName: 'Force Closed' },
    { columnName: 'Closed', checked: false, displayName: 'Closed' },
  ];
  dataConditionsSectionSelectionStatus = [
    {
      columnName: 'SMT', checked: true, displayName: 'SMT', lineList: [], lineSelected: []
    },
    {
      columnName: 'LOD', checked: true, displayName: 'LOD', lineList: [], lineSelected: []
    },
    {
      columnName: 'ASY', checked: true, displayName: 'ASSY', lineList: [], lineSelected: []
    },
    {
      columnName: 'PKG', checked: true, displayName: 'PKG', lineList: [], lineSelected: []
    },
    { columnName: 'TEST', checked: true, displayName: 'TEST', lineList: [], lineSelected: [] },
  ];

  dispForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;


  // 第一層標題 - ( 要有子項目增加 'active' )
  // ['Part No.', 'MO No.', 'Start Time', 'Cycle Time', 'Act Time', 'Efficiency', 'Mo Qty', 'Output Qty']
  // displayedColumns: string[] = ['action', 'product', 'order', 'startTime', 'cycleTime', 'actualTime', 'efficiency', 'targetOutput', 'output'];
  displayedColumns: string[] = ['action', 'sn', 'product', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];

  displayedColumnsTemp: string[] = ['action', 'sn', 'product', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];


  // 第二層標題
  // ['Section', 'Line', 'Side', 'Start Time', 'Act Time', 'Output Qty', 'Efficiency']
  // innerDisplayedColumns: string[] = ['action', 'section', 'line', 'side', 'startTime', 'actualTime', 'output', 'efficiency'];
  innerDisplayedColumns: string[] = ['action', 'section', 'line', 'side', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];

  innerSelectedColumnsTemp: string[] = ['action', 'section', 'line', 'side', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];


  columnPickerSelectionStatus = [
    // { columnName: 'section', checked: true, displayName: 'Section' },
    // { columnName: 'line', checked: true, displayName: 'Line' },
    // { columnName: 'side', checked: true, displayName: 'Side' },
    // { columnName: 'targetOutput', checked: true, displayName: 'MO Qty' },
    // { columnName: 'status', checked: true, displayName: 'Status' },
    { columnName: 'startTime', checked: true, displayName: 'Start T. (Start Time)' },
    { columnName: 'endTime', checked: true, displayName: 'End T. (End Time)' },
    { columnName: 'input', checked: true, displayName: 'INP (Input Qty)' },
    { columnName: 'output', checked: true, displayName: 'OUT (Output Qty)' },
    { columnName: 'achieveRate', checked: true, displayName: 'ACHV R. (Achieve Rate)' },
    { columnName: 'outputYield', checked: true, displayName: 'OUT YLD (Output Yield)' },
    { columnName: 'efficiency', checked: true, displayName: 'EFF (Efficiency)' },
    { columnName: 'actProductionTime', checked: true, displayName: 'ACT Prod T. (Actural Production Time)' },
    { columnName: 'standardProdTime', checked: true, displayName: 'STD Prod T. (Standard Production Time)' },
    { columnName: 'operator', checked: true, displayName: 'OPR (Operators)' },
    { columnName: 'standardTime', checked: true, displayName: 'STD(IE) (Standard time without OPR)' },
    { columnName: 'cycleTime', checked: true, displayName: 'CT(IE) (Cycle Time)' },
    { columnName: 'changeover', checked: true, displayName: 'CHG-OV (Changeover)' },
    { columnName: 'changeover_ie', checked: true, displayName: 'CHG-OV(IE) (IE Changeover)' },
    { columnName: 'machineDownTime', checked: true, displayName: 'M. Down T. (Machine Down Time)' },
    { columnName: 'restTime', checked: true, displayName: 'Rest T. (Reset Time)' },
    { columnName: 'downTime', checked: true, displayName: 'Down T. (Down Time)' },
  ];

  displaySelectedColumnNum: number;

  defaultHiddenColumn: string[] = ['standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];

  displayedColumnsArrIndex: string[] = ['action', 'sn', 'product', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];
  innerDisplayedColumnsArrIndex: string[] = ['action', 'section', 'line', 'side', 'targetOutput', 'status', 'startTime', 'endTime', 'input', 'output', 'achieveRate', 'outputYield', 'efficiency', 'actProductionTime', 'standardProdTime', 'operator', 'standardTime', 'cycleTime', 'changeover', 'changeover_ie', 'machineDownTime', 'restTime', 'downTime'];

  // DateRangePicker filter 六日不能選
  familyDayFilter(date: moment.Moment): boolean {
    const day = date.day();
    // return !this.filter.find(x => moment(x) === date);
    // return date === moment(new Date(2021, 9, 8).getTime());
    // return day !== 6 && day !== 0;
    // return day !== 0;
    return true;
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate(dateRangeStart, dateRangeEnd) {
    if (dateRangeStart === null || dateRangeEnd === null) { return; }
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    this.queryObj.startTime = moment(this.range.value.start).startOf('day').hours(8).valueOf();
    this.queryObj.endTime = moment(this.range.value.end).endOf('day').add(1, 'days').hours(7).valueOf();
    this.queryObj.offset = 1;
    console.log('選取日期 this.queryObj:', this.queryObj);
    console.log('getRangeDate Call API:');
    this.getAllApiFn(this.queryObj);
  }

  // DateRangePicker 設定 Defaulte range
  setDefaultRangeDate() {
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    // 不帶時間
    this.range.setValue({
      start: '',
      end: '',
    });
    this.queryObj.startTime = null;
    this.queryObj.endTime = moment().endOf('day').add(1, 'days').hours(7).valueOf();
    this.queryObj.offset = 1;
    console.log('預設日期 Call API:', this.queryObj);
    console.log('getRangeDate Call API:');
    this.getAllApiFn(this.queryObj);
  }


  private rxTimerStop() {
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      console.log('Sort排序 Call API:', this.queryObj);
      this.getAllApiFn(this.queryObj);
    });
  }

  ngOnInit(): void {
    this.rxTimerStart();

    this.dispForm = this.FB.group({
      smtLineList: [''],
      lodLineList: [''],
      asyLineList: [''],
      pkgLineList: [''],
      testLineList: [''],
    });

    // 子工單 Duration 預設隱藏
    this.defaultHiddenColumn.map((columnName) => {
      this.selectColumnPickerCheckbox({ target: { value: columnName, checked: false } });
    });

    this.selectColumnPickerCheckboxDone();
    this.selectionParameterEncode();
  }

  get toppings(): FormControl {
    return this.dispForm.get('toppings') as FormControl;
  }

  get smtLineList(): FormControl {
    return this.dispForm.get('smtLineList') as FormControl;
  }

  get lodLineList(): FormControl {
    return this.dispForm.get('lodLineList') as FormControl;
  }

  get asyLineList(): FormControl {
    return this.dispForm.get('asyLineList') as FormControl;
  }

  get pkgLineList(): FormControl {
    return this.dispForm.get('pkgLineList') as FormControl;
  }

  get testLineList(): FormControl {
    return this.dispForm.get('testLineList') as FormControl;
  }

  ngAfterViewInit() {
    // 呼叫API => 將回傳陣列資料 傳進 MatTableDataSource 的 data
    console.log('ngAfterViewInit this.queryObj:', this.queryObj);
    this.getAllApiFn(this.queryObj);
    this.outDataSource.sortingDataAccessor = (data, header) => data[header];
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // to Unix time
  toUnixTime(str) {
    return new Date(str).getTime();
  }

  // 分頁切換
  swapPageNum(str) {
    if (str === '+1') { this.queryObj.offset++; }
    if (str === '-1') { this.queryObj.offset--; }
  }

  // Table 箭頭 方向切換
  iconSwitch(element) {
    // 預設 關閉
    return element.isExpanded ? 'collapsed' : 'expanded';
  }

  // 子層展開控制
  expandHandler(row) {
    if (row.child.length === 0) { return 'collapsed'; }
    return row.isExpanded ? 'expanded' : 'collapsed';
  }

  // Click 子層全部 展開／關閉
  switchAllChildExpanded() {
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    this.usersData = this.usersData.map((x) => {
      this.isExpandChildStatus ? x.isExpanded = true : x.isExpanded = false;
      return x;
    });
  }

  // API RES 子層 row 設定預設值 ( 展開／關閉 )
  setChildExpanded(arr) {
    console.log('子層 展開設定 (展開／關閉)', this.tempSaveItemExpandeStatusArr);
    const isHaveTempSaveData = this.tempSaveItemExpandeStatusArr.length !== 0;
    const isNoTempSaveData = this.tempSaveItemExpandeStatusArr.length === 0;

    if (isHaveTempSaveData) {
      // API回來 預設Expand狀態 from 暫存 Arr
      this.tempSaveItemExpandeStatusArr = arr = arr.map((item) => {
        item.selfID = item.product + '-' + item.sn;
        this.tempSaveItemExpandeStatusArr.map((x) => {
          item.selfID === x.selfID ? item.isExpanded = x.isExpanded : x.isExpanded = x.isExpanded;
          return item;
        });
        return item;
      });
    }

    if (isNoTempSaveData) {
      // API回來 預設Expand狀態 設定 並存入 暫存 Arr
      this.tempSaveItemExpandeStatusArr = arr = arr.map((item) => {
        this.isExpandChildStatus ? item.isExpanded = true : item.isExpanded = false;
        item.selfID = item.product + '-' + item.sn;
        return item;
      });
    }
    return arr;
  }

  // Click 控制暫存切換 isExpanded => true/false ,
  rowClickSwitchIsExpanded(element) {
    console.log('rowClickSwitchIsExpanded ******************');
    const isHaveTempSaveData = this.tempSaveItemExpandeStatusArr.length !== 0;
    const isNoTempSaveData = this.tempSaveItemExpandeStatusArr.length === 0;

    if (isHaveTempSaveData) {
      this.tempSaveItemExpandeStatusArr = this.tempSaveItemExpandeStatusArr.map((item) => {
        if (item.selfID === element.selfID) {
          item.isExpanded = element.isExpanded = !element.isExpanded;
        }
        return item;
      });
    }

    if (isNoTempSaveData) {
      this.tempSaveItemExpandeStatusArr = this.usersData = this.usersData.map((item) => {
        if (item.selfID === element.selfID) {
          item.isExpanded = !item.isExpanded;
        }
        return item;
      });
    }

  }

  // table event sort排序 觸發排序事件 merge cell function --- step 3
  sortData(element) {
    console.log('sortData element:', element);
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    this.queryObj.offset = 1;
    this.queryObj.sort = SORT_KEY[element.active] + SORT_DIRECTION[element.direction];
    console.log('Sort排序 Call API:', this.queryObj);
    this.getAllApiFn(this.queryObj);
  }

  // table event 換頁觸發 Paginate Change
  onPaginateChange(e) {
    console.log('onPaginateChange e:', e);
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    this.queryObj.offset = e.pageIndex + 1;
    console.log('換頁觸發 this.queryObj:', this.queryObj.offset);
    console.log('Sort排序 Call API:', this.queryObj);
    this.getAllApiFn(this.queryObj);

    // setTimeout(() => {
    //   this.isSvLoading.loading = false;
    // }, 900);
  }

  // 搜尋 table event Search input filter
  onEnterSearch(value: string) {
    const lowerCaseValue = value.trim().toLowerCase();
    // if (lowerCaseValue === this.filterValue) { console.log('Compare equal: ', lowerCaseValue); return; }
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    this.filterValue = lowerCaseValue;
    this.queryObj.search = this.filterValue;
    this.queryObj.offset = 1;
    console.log('搜尋 Call API => ', this.queryObj);
    this.getAllApiFn(this.queryObj);
  }

  // 下拉選單 Section
  getModelName(e) {
    this.isSvLoading.loading = true;
    this.tempSaveItemExpandeStatusArr = []; // 清空暫存
    console.log('getModelName e:', e);
    this.queryObj.section = e;
    this.queryObj.offset = 1;
    console.log('下拉選單 Call API => ', this.queryObj);
    this.getAllApiFn(this.queryObj);
  }

  // subscribe 訂閱 陣列變動， 拿到 row data 更新排序的陣列
  subscribeDataSource() {
    return this.outDataSource.connect().pipe().subscribe(
      (newSortArr) => {
        // 將新 Array 放回 this.DATA
        this.usersData = newSortArr;
      });
  }

  // 檢查 efficiency &  Add class color
  efficiencyFontColor(efficiency) {
    if (efficiency === '-') { return; }
    if (efficiency < 98) { return 'box_red'; }
    if (efficiency > 102) { return 'box_yellow'; }
  }

  // check status return color for css
  moStatusBgColor(str: 'Waiting' | 'In production' | 'Not in production' | 'DSF Complete' | 'Closed' | 'Force Closed') {
    if (str === 'Waiting') { return 'status_color_gray'; }
    if (str === 'In production') { return 'status_color_orange'; }
    if (str === 'Not in production') { return 'status_color_blue'; }
    if (str === 'DSF Complete') { return 'status_color_green'; }
    if (str === 'Closed') { return 'status_color_yellow_green'; }
    if (str === 'Force Closed') { return 'status_color_pink'; }
  }

  // check 工作區段Act Prod T.超過STD Prod T.(IE)則會變為紅字
  actProductionTimeFontColor(element) {
    if (element.actProductionTime === '-' || element.standardProdTime === '-') { return false; }
    return moment(element.actProductionTime).valueOf() > moment(element.standardProdTime).valueOf();
  }

  // Efficiency pop-up event
  onClickEfficiency(element) {
    if (element.section === 'TEST') { return; }
    console.log('onClickEfficiency: ', element);
    this.dialog.open(Phase3ProductionOverviewEfficiencyApiDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '920px',
      maxHeight: 'calc(100vh, 32px)',
      data: {
        efficiency: element.efficiency,
        cycleTime: element.cycleTime,
        output: element.output,
        stdLineChangeTime: element.stdLineChangeTime,
        operator: element.operator,
        machineDownTime: element.machineDownTime,
        isOperatorMes: element.isOperatorMes,

        durationList: element.details[0].durationList,
        restTimeInAct: element.details[0].restTimeInAct,
        restTimeInChangeover: element.details[0].restTimeInChangeover,
        changeOverList: element.details[0].changeOverList,
        nonProdTimeListByAct: element.details[0].nonProdTimeListByAct,
        nonProdTimeListByChangeover: element.details[0].nonProdTimeListByChangeover,
        rdProduction: element.details[0].rdProduction,
        nonBarcodeProduction: element.details[0].nonBarcodeProduction,
      },
    });
  }

  // Download pop-up event
  onClickDownload(filter: string) {
    console.log('onClickDownload: ', filter);
    this.dialog.open(ProductionOverviewDownloadDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
      height: '280px',
      maxHeight: 'calc(100vh, 32px)',
      autoFocus: false,
      data: {
        option: filter,
        startTime: this.queryObj.startTime,
        endTime: this.queryObj.endTime,
      },
    });
  }

  // Pipe 資料處理
  pipeTranslateInfo(arr: any) {
    return arr.map((x) => {
      const xx = { ...x };
      if (xx.targetOutput !== '-') {
        xx.formattedTargetOutput = this.dataFormatSvc.pipeNumber(xx.targetOutput, '');
      }
      if (xx.input !== '-') {
        xx.formattedInput = this.dataFormatSvc.pipeNumber(xx.input, '');
      }
      if (xx.output !== '-') {
        xx.formattedOutput = this.dataFormatSvc.pipeNumber(xx.output, '');
      }
      if (xx.startTime !== '-') {
        xx.formattedStartTime = this.dataFormatSvc.pipeDate(xx.startTime, 'MM/dd HH:mm');
      }
      if (xx.endTime !== '-') {
        xx.formattedEndTime = this.dataFormatSvc.pipeDate(xx.endTime, 'MM/dd HH:mm');
      }
      if (xx.efficiency !== '-') {
        xx.formattedEfficiency = this.dataFormatSvc.pipeNumber(+(xx.efficiency * 100), '0.2-2');
      }
      if (xx.achieveRate !== '-') {
        xx.formattedAchieveRate = this.dataFormatSvc.pipeNumber(+(xx.achieveRate * 100), '0.2-2');
      }
      if (xx.outputYield !== '-') {
        xx.formattedOutputYield = this.dataFormatSvc.pipeNumber(+(xx.outputYield * 100), '0.2-2');
      }

      this.dataFormatSvc.nullToDash(xx.child);
      // 子層 Formater
      xx.child.map((i) => {
        if (i.targetOutput !== '-') {
          i.formattedTargetOutput = this.dataFormatSvc.pipeNumber(i.targetOutput, '');
        }
        if (i.startTime !== '-') {
          // i.startTime = this.dataFormatSvc.pipeDate(i.startTime, 'yyyy/M/dd HH:mm');
          i.formattedStartTime = this.dataFormatSvc.pipeDate(i.startTime, 'MM/dd HH:mm');
        }
        if (i.endTime !== '-') {
          // i.endTime = this.dataFormatSvc.pipeDate(i.endTime, 'yyyy/M/dd HH:mm');
          i.formattedEndTime = this.dataFormatSvc.pipeDate(i.endTime, 'MM/dd HH:mm');
        }
        if (i.input !== '-') {
          i.formattedInput = this.dataFormatSvc.pipeNumber(+i.input, '');
        }
        if (i.output !== '-') {
          i.formattedOutput = this.dataFormatSvc.pipeNumber(+i.output, '');
        }
        if (i.achieveRate !== '-') {
          i.formattedAchieveRate = this.dataFormatSvc.pipeNumber(+(i.achieveRate * 100), '0.2-2');
        }
        if (i.outputYield !== '-') {
          i.formattedOutputYield = this.dataFormatSvc.pipeNumber(+(i.outputYield * 100), '0.2-2');
        }
        if (i.restTime !== '-') {
          i.formattedRestTime = this.dataFormatSvc.secToDDhhmmss(i.restTime);
        }
        if (i.downTime !== '-') {
          i.formattedDownTime = this.dataFormatSvc.secToDDhhmmss(i.downTime);
        }
        if (i.changeOver !== '-') {
          i.formattedChangeOver = this.dataFormatSvc.secToDDhhmmss(i.changeOver);
        }
        if (i.stdLineChangeTime !== '-') {
          i.formattedChangeOverIE = this.dataFormatSvc.secToDDhhmmss(i.stdLineChangeTime);
        }
        if (i.machineDownTime !== '-') {
          i.formattedMachineDownTime = this.dataFormatSvc.secToDDhhmmss(i.machineDownTime);
        }
        // https://issue.ccau.co.th/issues/1576
        if (i.actProductionTime !== 0) {
          i.formattedActProductionTime = this.dataFormatSvc.secToDDhhmmss(i.actProductionTime);
        }
        else {
          const { section, input, output } = i;
          if (section === 'SMT') {
            i.formattedActProductionTime = (output === 0 || output === '-') ? '-' : this.dataFormatSvc.secToDDhhmmss(0);
          }
          if (section === 'LOD' || section === 'PKG' || section === 'ASY') {
            i.formattedActProductionTime = (input === 0 || input === '-') ? '-' : this.dataFormatSvc.secToDDhhmmss(0);
          }
          if (section === 'TEST') { i.formattedActProductionTime = this.dataFormatSvc.secToDDhhmmss(0); }
        }
        if (i.OPR !== '-') {
          i.formattedOperators = this.dataFormatSvc.pipeNumber(i.OPR, '');
        }
        if (i.cycleTime !== '-') {
          i.formattedCycleTime = this.dataFormatSvc.secToDDhhmmss(i.cycleTime);
        }
        if (i.standardProdTime !== '-') {
          i.formattedStandardProdTime = this.dataFormatSvc.secToDDhhmmss(i.standardProdTime);
        }
        if (i.standardTime !== '-') {
          i.formattedStandardTime = this.dataFormatSvc.secToDDhhmmss(i.standardTime);
        }
        if (i.efficiency !== '-') {
          i.formattedEfficiency = this.dataFormatSvc.pipeNumber(+(i.efficiency * 100), '0.2-2');
        }
      });
      return xx;
    });
  }

  checkSelectAllStatus(target: string) {
    let checkStatus;
    if (target === 'status') {
      checkStatus = this.dataConditionsStatusSelectionStatus.filter((item) => item.checked === false);
      return checkStatus.length === 0 ? false : true;
    }
    if (target === 'section') {
      checkStatus = this.dataConditionsSectionSelectionStatus.filter((item) => item.checked === false);
      return checkStatus.length === 0 ? false : true;
    }
  }

  selectAllClick(target: string) {
    if (target === 'status') {
      this.dataConditionsStatusSelectionStatus.map(
        (item) => { item.checked = true; }
      );
      this.statusSelectedTempAry = [];
      this.statusSelectedTempAry = _.cloneDeep(this.allStatusAry);
    }
    if (target === 'section') {
      this.dataConditionsSectionSelectionStatus.map(
        (item) => { item.checked = true; }
      );
      this.sectionSelectedTempAry = [];
      this.sectionSelectedTempAry = _.cloneDeep(this.allSectionAry);
    }
  }

  selectionParameterEncode() {
    this.apiEncodeStatusStr = this.statusAry.length === 0 ? null : JSON.stringify(this.statusAry);
    this.queryObj.status = this.apiEncodeStatusStr;

    const sectionLineArray = [];
    this.dataConditionsSectionSelectionStatus.map(
      (item) => {
        if (item.checked) {
          sectionLineArray.push({
            section: item.columnName,
            line: item.lineSelected?.length > 0 ? item.lineSelected : null
          });
        }
      });
    console.log('sectionLineArray:', sectionLineArray);
    this.apiEncodeSectionStr = sectionLineArray.length === 0 ? null : JSON.stringify(sectionLineArray);
    this.queryObj.section = this.apiEncodeSectionStr;
  }

  // select data conditions section checkbox(狀態)
  selectSectionCheckbox(e) {
    const getValue = e.target.value;
    if (e.target.checked) {
      this.sectionSelectedTempAry.push(getValue);
      this.dataConditionsSectionSelectionStatus.map(
        (item) => {
          if (item.columnName === getValue) {
            item.checked = true;
          }
        });
    }
    if (e.target.checked === false) {
      this.sectionSelectedTempAry = this.sectionSelectedTempAry.filter((item) => item !== getValue);
      this.dataConditionsSectionSelectionStatus.map(
        (item) => {
          if (item.columnName === getValue) {
            item.checked = false;
          }
        });
    }
    console.log('this.dataConditionsSectionSelectionStatus:', this.dataConditionsSectionSelectionStatus);
    console.log('this.sectionSelectedTempAry:', this.sectionSelectedTempAry);
  }

  // select data conditions status checkbox(狀態)
  selectStatusCheckbox(e) {
    const getValue = e.target.value;
    if (e.target.checked) {
      this.statusSelectedTempAry.push(getValue);
      this.dataConditionsStatusSelectionStatus.map(
        (item) => {
          if (item.columnName === getValue) {
            item.checked = true;
          }
        });
    }
    if (e.target.checked === false) {
      this.statusSelectedTempAry = this.statusSelectedTempAry.filter((item) => item !== getValue);
      this.dataConditionsStatusSelectionStatus.map(
        (item) => {
          if (item.columnName === getValue) {
            item.checked = false;
          }
        });
    }
    console.log('this.dataConditionsStatusSelectionStatus:', this.dataConditionsStatusSelectionStatus);
    console.log('this.statusSelectedTempAry:', this.statusSelectedTempAry);
  }

  dataConditionsSectionCheckedBinding(value: string) {
    const target = this.dataConditionsSectionSelectionStatus.filter((item) => item.columnName === value);
    return target[0].checked;
  }

  dataConditionsStatusCheckedBinding(value: string) {
    const target = this.dataConditionsStatusSelectionStatus.filter((item) => item.columnName === value);
    return target[0].checked;
  }

  selectDataConditionsCancel() {
    this.dataConditionsSelected = false;

    this.sectionSelectedTempAry = [];
    this.sectionSelectedTempAry = _.cloneDeep(this.sectionAry);

    this.statusSelectedTempAry = [];
    this.statusSelectedTempAry = _.cloneDeep(this.statusAry);

    this.dataConditionsSectionSelectionStatus.map(
      (item) => {
        if (this.sectionAry.includes(item.columnName)) {
          item.checked = true;
        }
        else {
          item.checked = false;
        }

        switch (item.columnName) {
          case 'SMT':
            this.dispForm.patchValue({ smtLineList: item.lineSelected });
            break;
          case 'LOD':
            this.dispForm.patchValue({ lodLineList: item.lineSelected });
            break;
          case 'ASY':
            this.dispForm.patchValue({ asyLineList: item.lineSelected });
            break;
          case 'PKG':
            this.dispForm.patchValue({ pkgLineList: item.lineSelected });
            break;
          case 'TEST':
            this.dispForm.patchValue({ testLineList: item.lineSelected });
            break;
          default:
            break;
        }
      }
    );

    this.dataConditionsStatusSelectionStatus.map(
      (item) => {
        if (this.statusAry.includes(item.columnName)) {
          item.checked = true;
        }
        else {
          item.checked = false;
        }
      }
    );

    console.log('section cancel:', this.dataConditionsSectionSelectionStatus);
    console.log('status cancel:', this.dataConditionsStatusSelectionStatus);
  }

  selectDataConditionsDone() {
    this.dataConditionsSelected = false;

    this.sectionAry = [];
    this.statusAry = [];

    this.sectionAry = _.cloneDeep(this.sectionSelectedTempAry);
    this.statusAry = _.cloneDeep(this.statusSelectedTempAry);

    console.log('this.sectionAry:', this.sectionAry);
    console.log('this.statusAry:', this.statusAry);

    this.dataConditionsSectionSelectionStatus.map(
      (item) => {
        switch (item.columnName) {
          case 'SMT':
            item.lineSelected = this.dispForm.get('smtLineList').value;
            break;
          case 'LOD':
            item.lineSelected = this.dispForm.get('lodLineList').value;
            break;
          case 'ASY':
            item.lineSelected = this.dispForm.get('asyLineList').value;
            break;
          case 'PKG':
            item.lineSelected = this.dispForm.get('pkgLineList').value;
            break;
          case 'TEST':
            item.lineSelected = this.dispForm.get('testLineList').value;
            break;
          default:
            break;
        }
      }
    );

    console.log('this.dataConditionsSectionSelectionStatus:', this.dataConditionsSectionSelectionStatus);

    this.selectionParameterEncode();
    this.queryObj.offset = 1;
    this.isSvLoading.loading = true;
    this.getAllApiFn(this.queryObj);
    console.log('this.queryObj:', this.queryObj);
  }

  // select click checkbox(子工單)
  selectColumnPickerCheckbox(e) {
    const getValue = e.target.value;
    const columnSelectedNum = this.columnPickerSelectionStatus.filter((item) => item.checked === true);
    if (e.target.checked) {
      if (columnSelectedNum.length >= 10) {
        e.target.checked = false;
        return;
      }
      else {
        this.displaySelectedColumnNum = columnSelectedNum.length + 1;
        this.displayedColumnsTemp.push(getValue);
        this.innerSelectedColumnsTemp.push(getValue);
        this.columnPickerSelectionStatus.map(
          (item) => {
            if (item.columnName === getValue) {
              item.checked = true;
            }
          });
      }
    }
    if (e.target.checked === false) {
      this.displaySelectedColumnNum = columnSelectedNum.length - 1;
      this.displayedColumnsTemp = this.displayedColumnsTemp.filter((item) => item !== getValue);
      this.innerSelectedColumnsTemp = this.innerSelectedColumnsTemp.filter((item) => item !== getValue);
      this.columnPickerSelectionStatus.map(
        (item) => {
          if (item.columnName === getValue) {
            item.checked = false;
          }
        });
    }
    console.log('this.columnPickerSelectionStatus:', this.columnPickerSelectionStatus);
  }

  columnPickerCheckedBinding(value: string) {
    const target = this.columnPickerSelectionStatus.filter((item) => item.columnName === value);
    return target[0].checked;
  }

  selectColumnPickerCheckboxCancel() {
    this.columnPickerSelected = false;
    this.displayedColumnsTemp = [];
    this.displayedColumnsTemp = _.cloneDeep(this.displayedColumns);
    this.innerSelectedColumnsTemp = [];
    this.innerSelectedColumnsTemp = _.cloneDeep(this.innerDisplayedColumns);

    this.columnPickerSelectionStatus.map(
      (item) => {
        if (this.innerDisplayedColumns.includes(item.columnName)) {
          item.checked = true;
        }
        else {
          item.checked = false;
        }
      }
    );
    console.log('cancel:', this.columnPickerSelectionStatus);
  }

  // select click checkbox(子工單)
  selectColumnPickerCheckboxDone() {
    this.columnPickerSelected = false;
    this.displayedColumns = [];
    this.displayedColumns = _.cloneDeep(this.displayedColumnsTemp);
    this.displayedColumns.sort((a, b) => {
      return this.displayedColumnsArrIndex.indexOf(a) - this.displayedColumnsArrIndex.indexOf(b);
    });
    this.innerDisplayedColumns = [];
    this.innerDisplayedColumns = _.cloneDeep(this.innerSelectedColumnsTemp);
    this.innerDisplayedColumns.sort((a, b) => {
      return this.innerDisplayedColumnsArrIndex.indexOf(a) - this.innerDisplayedColumnsArrIndex.indexOf(b);
    });
    console.log('this.innerDisplayedColumns:', this.innerDisplayedColumns);
  }

  // // select click checkbox(子工單)
  // selectColumnPickerCheckbox(e) {
  //   // console.log('e:checked ====>', e.target.value);
  //   // console.log('e:checked ====>', e.target.checked);
  //   const getValue = e.target.value;
  //   if (e.target.checked) {
  //     // e.target.checked = false;．
  //     this.innerDisplayedColumns.push(getValue);
  //     // Mapping 更新 index 排序
  //     this.innerDisplayedColumns.sort((a, b) => {
  //       return this.innerDisplayedColumnsArrIndex.indexOf(a) - this.innerDisplayedColumnsArrIndex.indexOf(b);
  //     });
  //     console.log('this.innerDisplayedColumns:', this.innerDisplayedColumns);
  //   }
  //   if (e.target.checked === false) {
  //     this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
  //     // this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
  //   }
  // }

  // build res.child Arr to new MatTableDataSource
  buildChildArrToMatTableData(res) {
    // empty orginal arr
    this.usersData = [];

    // 將要 子層陣列 轉化成 MatTableDataSource
    res.forEach((item) => {
      const checkIsArray = item.child.length > 0 && Array.isArray(item.child);
      // console.log('item:', item);
      if (checkIsArray) {
        this.usersData = [
          ...this.usersData, { ...item, child: new MatTableDataSource(item.child) },
        ];
      } else {
        this.usersData = [...this.usersData, item];
      }
    });

    // 將組合好的 usersData 塞進初始化 MatTableDataSource 完成的 outDataSource
    this.outDataSource.data = this.usersData;

    // 更新 Sort 排序資料 index
    this.changeDetectorRef.detectChanges();
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
    );

    return this.usersData;
  }

  // API Update Line Dropdown Content Value
  mappingLineDropdown(lineArray) {
    this.dataConditionsSectionSelectionStatus.map((item) => {
      lineArray.map((lineObj) => {
        if (item.columnName === lineObj?.section) {
          item.lineList = lineObj?.lines;
        }
      });
    });
  }

  // API get all lineName
  getAllLineName$() {
    return this.apiSvc.getAllLineNameData().pipe(
      tap(res => {
        console.log('getAllLineName row data res:', res);
      }),
    );
  }

  // API＄
  getTableData$(dataObj) {
    return this.apiSvc.getPhase3ProductOverviewTableData(
      dataObj.search,
      dataObj.status,
      dataObj.section,
      dataObj.startTime,
      dataObj.endTime,
      dataObj.sort,
      dataObj.offset,
      dataObj.limit
    ).pipe(
      map((res) => {
        console.log('getTableData row data => ', res);
        this.snapshotTime = res?.updateTime;
        this.isTotalPageNum = res.totalDataLength;
        if (res.msg) {
          console.log(`%cMSG => ${res.msg}`, 'background: #222;font-size:18px; color: red');
        }
        return res.infos;
      }),
      map((res) => this.dataFormatSvc.nullToDash(res)),
      map((res) => this.pipeTranslateInfo(res)),
      map(res => this.setChildExpanded(res)),
      tap(res => {

        // console.log('getTableData$ Formatted res =>', res);
      }),
    );
  }

  // Call API
  getAllApiFn(dataObj) {
    this.renewedTime = moment().valueOf();
    // if (this.pageAutoRefresh) { this.rxTimerStop(); }
    return this.getAllLineName$().pipe(
      tap((res) => {
        this.mappingLineDropdown(res);
      }),
      switchMap(res => this.getTableData$(dataObj)),
      map((res => this.buildChildArrToMatTableData(res))),
      tap(res => {
        console.log('getAllApiFn -> ', res);
        // if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }),
    ).subscribe((res) => {
      if (moment().diff(moment().startOf('day').hours(8)) < 0) {
        this.maxDate = moment().subtract(1, 'days');
      }
      // this.subscribeDataSource();
      // changeDetectorRef detectChanges 監測 Dom 元素變更檢測
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.isSvLoading.loading = false;
      }, 800);
    });
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.getAllApiFn(this.queryObj);
  }

}

interface DownloadParam {
  option: string;
  startTime: number;
  endTime: number;
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './dialog/app-download-dialog.component.html',
  styleUrls: ['./dialog/app-download-dialog.component.scss']
})
export class ProductionOverviewDownloadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ProductionOverviewDownloadDialogComponent>,
    public apiSvc: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DownloadParam) { }

  ngOnInit() {
    console.log('DownloadParam:', this.data);
  }

  onClickDownload(option: string) {
    // call download api
    if (!this.data.startTime) {
      return window.open(`${this.apiSvc.APIUrl}/download/production?endTime=${this.data.endTime}`);
    }
    else {
      return window.open(`${this.apiSvc.APIUrl}/download/production?startTime=${this.data.startTime}&endTime=${this.data.endTime}`);
    }
  }
}

@Component({
  selector: 'app-efficiency-api-data-dialog',
  templateUrl: './dialog/app-efficiency-api-data-dialog.component.html',
  styleUrls: ['./dialog/app-efficiency-api-data-dialog.component.scss']
})
export class Phase3ProductionOverviewEfficiencyApiDataDialogComponent implements OnInit {
  constructor(
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<Phase3ProductionOverviewEfficiencyApiDataDialogComponent>,
    public dialogEmployee: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EfficiencyApiData,
  ) { }

  EfficiencyData: EfficiencyApiData = {
    efficiency: null,
    cycleTime: null,
    output: null,
    stdLineChangeTime: null,
    operator: null,
    machineDownTime: null,
    isOperatorMes: null,

    durationList: null,
    restTimeInAct: null,
    restTimeInChangeover: null,
    changeOverList: null,
    nonProdTimeListByAct: null,
    nonProdTimeListByChangeover: null,
    rdProduction: null,
    nonBarcodeProduction: null,
  };

  totalDurationTime = 0;
  totalRestTimeInAct = 0;
  totalRestTimeInChangeover = 0;
  totalChangeOverTime = 0;
  totalNonProdTimeTimeByAct = 0;
  totalNonProdTimeTimeByChangeover = 0;
  totalRdProductionTime = 0;
  totalNonBarcodeProductionTime = 0;

  ngOnInit() {
    this.EfficiencyData = this.data;

    if (this.EfficiencyData.durationList.length > 0) {
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.filter(item => item.duration !== null);
      this.totalDurationTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.durationList);
    }
    if (this.EfficiencyData.restTimeInAct.length > 0) {
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.filter(item => item.duration !== null);
      this.totalRestTimeInAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInAct);
    }
    if (this.EfficiencyData.restTimeInChangeover.length > 0) {
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.filter(item => item.duration !== null);
      this.totalRestTimeInChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInChangeover);
    }
    if (this.EfficiencyData.changeOverList.length > 0) {
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.filter(item => item.duration !== null);
      this.totalChangeOverTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.changeOverList);
    }
    if (this.EfficiencyData.nonProdTimeListByAct.length > 0) {
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByAct);
    }
    if (this.EfficiencyData.nonProdTimeListByChangeover.length > 0) {
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByChangeover);
    }
    if (this.EfficiencyData.rdProduction.length > 0) {
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.filter(item => item.duration !== null);
      this.totalRdProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.rdProduction);
    }
    if (this.EfficiencyData.nonBarcodeProduction.length > 0) {
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.filter(item => item.duration !== null);
      this.totalNonBarcodeProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonBarcodeProduction);
    }
  }
}
