export enum SORT_KEY {
  product = 'PRODUCT',
  sn = 'SN',
  status = 'STATUS',
  startTime = 'STARTTIME',
  endTime = 'ENDTIME',
  actProductionTime = 'ACTPRODUCTIONTIME',
  targetOutput = 'TARGETOUTPUT',
  input = 'INPUT',
  output = 'OUTPUT',
  achieveRate = 'ACHIEVERATE',
  outputYield = 'OUTPUTYIELD',
  efficiency = 'EFFICIENCY',
}

export enum SORT_DIRECTION {
  asc = '',
  desc = '-',
}
