<div
  style="
    max-width: 1920px;
    height: 100%;
    max-height: 1080px;
    padding: 40px 40px 7px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  "
>
  <div class="header">
    <div class="left-group">
      <!-- <button class="logo-btn" title="logo">
        <img
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button> -->
      <button class="back-btn" title="back">
        <img
          src="../../assets/img/smart_factory/btn_back.svg"
          alt="arrow"
          [routerLink]="['/smartFactory/web/production-overview-management']"
        />
      </button>
      <h1>MO Route Overview</h1>
    </div>
    <div class="right-group">
      <h3>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</h3>
      <button class="menu-btn" title="menu" (click)="isMenuOpen = !isMenuOpen">
        <img src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="apiSvc.ifSSO('Administrator,1E-Editor'); else PermissionDenied"
  >
    <div class="mo-container">
      <div class="remain-box">
        <p class="title">Remaining Days</p>
        <p class="value">
          <span
            [ngStyle]="{
              'background-color': remainingBgColor(
                wipApiData.daysRemainingUntilClosing
              )
            }"
            >{{
              wipApiData.daysRemainingUntilClosing === null
                ? "-"
                : wipApiData.daysRemainingUntilClosing >= 0
                ? "+" + wipApiData.daysRemainingUntilClosing
                : wipApiData.daysRemainingUntilClosing
            }}</span
          >
        </p>
      </div>
      <div class="mo-box">
        <div class="mo-item">
          <p class="title">MO No.</p>
          <p class="value">{{ dataFormatSvc.checkValDash(wipApiData.mo) }}</p>
        </div>
        <div class="mo-item">
          <p class="title">Part No.</p>
          <p class="value" [ngStyle]="productFontSize()">
            {{ dataFormatSvc.checkValDash(wipApiData.product) }}
          </p>
        </div>
        <div class="mo-item">
          <p class="title">MO Qty.</p>
          <p class="value">
            {{
              wipApiData.targetOutput === null
                ? "-"
                : (wipApiData.targetOutput | number)
            }}
          </p>
        </div>
        <div class="mo-item" style="max-width: 500px">
          <p class="title">Line</p>
          <p class="value" [ngStyle]="lineFontSize()">
            {{
              wipApiData.line.length === 0 ? "-" : wipApiData.line.join(", ")
            }}
          </p>
        </div>
        <div class="mo-item">
          <p class="title">Start Time</p>
          <p class="value">
            {{
              wipApiData.startTime === null
                ? "-"
                : (wipApiData.startTime | date: "yyyy/MM/dd HH:mm")
            }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="wipApiData.routeInfo.length === 0" class="wip-container">
      <p class="no-data">No Data</p>
    </div>

    <div *ngIf="wipApiData.routeInfo.length > 0" class="wip-container">
      <div class="flex-column-center">
        <div
          class="wip-groups"
          *ngFor="
            let lineItem of wipItemArray;
            let oddLine = odd;
            let firstWip = first;
            let lastWip = last
          "
          [ngStyle]="{ 'flex-direction': oddLine ? 'row-reverse' : 'row' }"
        >
          <div
            class="wip-item"
            *ngFor="
              let item of lineItem;
              let firstItem = first;
              let lastItem = last
            "
          >
            <div *ngIf="item.groupName.groupName !== 'REPAIR'" class="info">
              <p class="output">
                <span class="title">Produced: </span>
                <span class="value-lg">{{
                  item.output === null ? "-" : item.output
                }}</span>
              </p>
              <p class="repair">
                <span class="wip">
                  <span class="title">WIP in: </span>
                  <span class="value-sm">{{
                    item.wip === null ? "-" : item.wip
                  }}</span>
                </span>
                <span class="scrap">
                  <span class="title">Scrap: </span>
                  <span class="value-sm">{{
                    item.scrap === null ? "-" : item.scrap
                  }}</span>
                </span>
              </p>
            </div>
            <div *ngIf="item.groupName.groupName === 'REPAIR'" class="info">
              <div class="repair" style="display: block; height: 50px">
                <p class="wip">
                  <span class="title" style="width: 40%; text-align: end"
                    >WIP in:
                  </span>
                  <span class="value-md" style="width: 60%">{{
                    item.wip === null ? "-" : item.wip
                  }}</span>
                </p>
                <p class="scrap">
                  <span class="title" style="width: 40%; text-align: end"
                    >Scrap:
                  </span>
                  <span class="value-md" style="width: 60%">{{
                    item.scrap === null ? "-" : item.scrap
                  }}</span>
                </p>
              </div>
            </div>
            <div
              class="connection"
              [ngClass]="{
                first: firstWip && firstItem,
                'last-right': lastWip && lastItem && !oddLine,
                'last-left': lastWip && lastItem && oddLine
              }"
            >
              <div
                [ngClass]="{
                  'right-end':
                    lastItem && !oddLine && !(lastWip && lastItem && !oddLine),
                  'right-start': firstItem && oddLine,
                  'left-end':
                    lastItem && oddLine && !(lastWip && lastItem && oddLine),
                  'left-start':
                    firstItem && !oddLine && !(firstWip && firstItem)
                }"
              ></div>
              <div class="backline"></div>
              <div
                class="circle"
                [ngClass]="{
                  out: item.index === 'out',
                  repair: item.index === 'R'
                }"
                [ngStyle]="wipStatusColor(item.status)"
              >
                {{ item.index === null ? "-" : item.index }}
              </div>
            </div>
            <div class="name">
              <p *ngIf="item.groupName.groupId" class="p1">
                {{ item.groupName.groupId }}
              </p>
              <p class="p2">{{ item.groupName.groupName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
