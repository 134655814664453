<!-- <app-floor-menu></app-floor-menu> -->

<!-- <div class="mask" *ngIf="isLoading$ | async">
  <div class="loader elem-margin-lg"></div>
</div> -->

<!-- <div class="mask" *ngIf="this.isSvLoading.loading">
  <div class="loader elem-margin-lg"></div>
</div> -->

<router-outlet></router-outlet>
