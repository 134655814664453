<div
  class="search show-icon"
  [class.full-width]="fullWidth"
  (input)="onChange($event)"
>
  <input
    #input
    matInput
    [placeholder]="placeholder"
    [disabled]="disabled"
    (keyup.enter)="onEnter(input.value)"
  />
</div>
