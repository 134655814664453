import { environment } from './../../environments/environment.stage';
import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/_services/apiSvc.service';
import { DotnetApi } from 'src/app/_services/apiSvc.service';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { forkJoin, interval } from 'rxjs';
import { RealTimeClockService } from 'src/app/_services/real-time-clock.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { group } from '@angular/animations';
import { EquipmentStatus, SensorStatus } from '../_variable/main-panel-type';
import * as moment from 'moment';
import * as _ from 'lodash';

// api interface
import { AlarmLineChartApi, EquipStatusApi, MoDetailApi, ActivedAlarms, PostAlarmReport, PostAlarmRemove } from '../core/models/newMainBoard.model';

const serviceName = DotnetApi.MainPanel.ServiceName;
@Component({
  selector: 'app-new-main-board',
  templateUrl: './new-main-board.component.html',
  styleUrls: ['./new-main-board.component.scss'],
  providers: [DatePipe, DecimalPipe]
})


export class NewMainBoardComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public service: DotnetApi.MainPanel.Service,
    private httpClient: HttpClient,
    public clock: RealTimeClockService,
    private datePipe: DatePipe,
  ) {
    this.isSvLoading.loading = true;
  }

  isOpenDebugMode = false;

  getInnerWidth = window.innerWidth;
  scaleWindowSize = this.getInnerWidth / 3765;
  getInnerHeight = 2160 * this.scaleWindowSize;

  pageAutoRefresh = true;
  intervalSeconds = 60;
  rxTimer$;

  // Alarm List START**************
  OpenPopupObj: {
    isReport: boolean;
    isReportAgain: boolean;
    isRemove: boolean;
  } = {
      isReport: false,
      isReportAgain: false,
      isRemove: false,
    };

  reportJSON: {
    sourceId: string;
    isReport: boolean;
    isDelete: boolean;
    name: string;
    logTime: string;
    reportTime: string;
    dbLogTime: number;
  } = {
      sourceId: null,
      isReport: null,
      isDelete: null,
      name: null,
      logTime: null,
      reportTime: null,
      dbLogTime: null
    };

  reportDotnetApiJSON: {
    sourceId: string;
    alarmTime: number;
  } = {
      sourceId: null,
      alarmTime: null
    };

  alarmData: {
    sourceId: string;
    name: string;
    logTime: string;
    reportTime: string;
    isReport: boolean;
    isDelete: boolean;
    dbLogTime: number;
  }[] = [{
    sourceId: null,
    name: null,
    logTime: null,
    reportTime: null,
    isReport: null,
    isDelete: null,
    dbLogTime: null,
  }];
  // Alarm List END**************

  // MO status Overview & Detail START**************
  moStatusDetailObj: {
    closed: number;
    closedPercent: number;
    closedOverdue: number;
    closedOverduePercent: number;
    InProgress: number;
    InProgressPercent: number;
    Overdue: number;
    OverduePercent: number;
    totalDetailNum: number;
    deliveryRate: number;
  } = {
      closed: null,
      closedPercent: null,
      closedOverdue: null,
      closedOverduePercent: null,
      InProgress: null,
      InProgressPercent: null,
      Overdue: null,
      OverduePercent: null,
      totalDetailNum: null,
      deliveryRate: null,
    };

  moOverviewObj: {
    moClose: number;
    moToBe: number;
  } = {
      moClose: null,
      moToBe: null
    };
  // MO status Overview & Detail END**************

  // 站點Alarm  START**************
  // Line Circle 燈號顏色 type: num
  // 0 => 綠燈, 1 => 黃燈, 2 => 紅燈
  smtData: {
    smtData_checkbox_active: boolean;
    lineObj: {
      'SMT-A': number;
      'SMT-B': number;
      'SMT-C': number;
      'SMT-D': number;
      'SMT-F': number;
    };
  } = {
      smtData_checkbox_active: true,
      lineObj: {
        'SMT-A': null,
        'SMT-B': null,
        'SMT-C': null,
        'SMT-D': null,
        'SMT-F': null,
      }
    };

  loadingData: {
    loadingData_checkbox_active: boolean;
    lineObj: {
      'LOD-A': number;
      'LOD-B': number;
      'LOD-C': number;
      'LOD-D': number;
      'LOD-E': number;
    };
  } = {
      loadingData_checkbox_active: true,
      lineObj: {
        'LOD-A': null,
        'LOD-B': null,
        'LOD-C': null,
        'LOD-D': null,
        'LOD-E': null,
      }
    };

  energyData: {
    energyData_checkbox_active: boolean;
    lineObj: {
      alarm: number;
    };
  } = {
      energyData_checkbox_active: true,
      lineObj: {
        alarm: null
      }
    };

  envData: {
    envData_checkbox_active: boolean;
    lineObj: {
      'acbeldso-iaq-10': number;
      'acbeldso-iaq-11': number;
    };
  } = {
      envData_checkbox_active: true,
      lineObj: {
        'acbeldso-iaq-10': null,
        'acbeldso-iaq-11': null
      }
    };

  cctvData: {
    cctvData_checkbox_active: boolean;
    lineObj?: {
      cctvA: number;
      cctvB: number;
    };
  } = {
      cctvData_checkbox_active: true,
      lineObj: {
        cctvA: null,
        cctvB: null
      }
    };

  // 站點Alarm  END**************

  // Pie-Chart => Mo Status Detail START***************************************
  public pieChartDataArr = [
    {
      pieChartType: 'pie',
      // Pie
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          p1: false   // not show unit name "kWh" / 移除 阿舍預設 "kWh"
        },
        // label 文字
        legend: {
          display: false,
        },
        // Pie 顯示單位百分比
        tooltips: {
          enabled: false, // 關閉 tooltips
        }
      },
      // pie color
      donutColors: [
        {
          backgroundColor: ['#11E834', '#FFE500', '#CD911C', '#FF3A3A']
        }
      ],
      pieChartLabels: [
        'Closed',
        'Closed (Overdue)',
        'In Progress',
        'Overdue'
      ],
      pieChartData: [{ data: [] }]
      // pieChartData: [{ data: [100, 200, 500, 200] }]
    }
  ];
  // Pie-Chart => Mo Status Detail END***************************************

  // Alarm-Chart => Alarm Record START***************************************
  public newAlarmChartDataArr: {
    chartTitle: string;
    barChartType: string;
    barChartLegend: boolean;
    barChartData: {
      data: {
        x: string;
        y: number;
      }[];
      fill: boolean;
      borderColor: string;
      borderWidth: number;
      yAxisID: string;
    }[];
    barChartOptions: {
      responsive: boolean;
      elements: {
        line: {
          tension: number;
        };
      };
      scales: {
        xAxes: {
          display: boolean;
          type: string;
          time: {
            unit: boolean;
            stepSize: number;
            displayFormats: {
              hour: string;
            };
            tooltipFormat: string;
          };
          scaleLabel: {
            display: boolean;
          };
          ticks: {
            stepSize: number;
            fontColor: string;
            padding: number;
            fontSize: number;
            maxRotation: number;
          };
          gridLines: {
            drawTicks: boolean;
            color: string;
            lineWidth: number;
          };
        }[];
        yAxes: {
          position: string;
          id: string;
          display: boolean;
          labelString: string;
          ticks: {
            min: number;
            fontColor: string;
            stepSize: number;
            padding: number;
            fontSize: number;
          };
          gridLines: {
            drawTicks: boolean;
            color: string;
            lineWidth: number;
          };
          scaleLabel: {
            display: boolean;
            labelString: string;
            fontColor: string;
            fontSize: number;
          };
        }[];
        pan: {
          enabled: boolean;
          mode: string;
        };
      };
      tooltips: {
        titleFontSize: number;
        bodyFontSize: number;
        borderColor: string;
      };
      plugins: {
        p1: boolean;
      };
      legend: {
        display: boolean;
      }
    };
    chartColors: {
      backgroundColor: string;
    }[];
    chartClicked;
    chartHovered;
  }[] = [];
  // Alarm-Chart => Alarm Record START***************************************

  downloadFile() {
    return window.open(`${this.apiSvc.APIUrl}/download/moStatusDetail`);
    // return window.open('http://localhost:5555/smartFactory/apis/download/moStatusDetail');
  }

  // Open Debug Mode, press " ctl + alt + shilt + m "
  @HostListener('window:keydown', ['$event']) keydownEvent(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.altKey && event.shiftKey && event.keyCode === 77) {
      console.log('OPEN DEBUG MODE');
      this.isOpenDebugMode = !this.isOpenDebugMode;
    }
  }

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 60秒 Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      this.isSvLoading.loading = true;
      this.getAllApiFn();
    });
  }

  @HostListener('window:resize', ['$event'])

  onWindowResize(event) {
    this.getInnerWidth = event.target.innerWidth;
    this.scaleWindowSize = this.getInnerWidth / 3765;
    this.getInnerHeight = 2160 * this.scaleWindowSize;
  }

  ngOnInit(): void {
    this.rxTimerStart();
  }

  ngAfterViewInit() {
    this.getAllApiFn();
  }

  ngOnDestroy() {
    this.rxTimer$.unsubscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  getEquipmentStatusLight(status: EquipmentStatus) {
    switch (status) {
      case EquipmentStatus.RUNNING:
        return 'img_light_green';
      case EquipmentStatus.IDLE:
        return 'img_light_yellow';
      case EquipmentStatus.ALARM:
        return 'img_light_red';
      default:
        return undefined;
    }
  }

  getSensorStatusLight(status: SensorStatus) {
    switch (status) {
      case SensorStatus.NORMAL:
        return 'img_light_green';
      case SensorStatus.ALARM:
        return 'img_light_red';
      default:
        return undefined;
    }
  }

  // Alarm => 通報或刪除
  apiReportIssue(status, item: any) {
    console.log('apiReportIssue status:', status);
    console.log('apiReportIssue item:', item);

    // reset popupShowObj
    this.OpenPopupObj = {
      isReport: false,
      isReportAgain: false,
      isRemove: false,
    };

    // resete obj
    this.reportJSON = {
      sourceId: null,
      isReport: null,
      isDelete: null,
      name: null,
      logTime: null,
      reportTime: null,
      dbLogTime: null
    };

    this.reportJSON = {
      sourceId: item.sourceId,
      isReport: item.isReport,
      isDelete: item.isDelete,
      name: item.name,
      logTime: item.logTime,
      reportTime: item.reportTime,
      dbLogTime: item.dbLogTime
    };

    if (status === 'isreport') {
      // console.log('isreport ================');
      this.reportJSON.isReport = !item.isReport;
    }
    if (status === 'reportAgain') {
      // console.log('reportAgain ================');
      this.reportJSON.isReport = item.isReport;
    }
    if (status === 'remove') {
      // console.log('remove ================');
      this.reportJSON.isDelete = !item.isDelete;
    }

  }

  // build line chart Array
  buildLinCharArr(resDataArr) {
    // console.log('resDataArr:', resDataArr);
    this.newAlarmChartDataArr = [];
    for (const element of resDataArr) {
      this.newAlarmChartDataArr.push(
        {
          chartTitle: element.chartTitle,
          barChartType: 'line',
          barChartLegend: true,
          barChartData: [
            {
              // type: 'line',
              data: element.barChartData,
              // label: 'Accepted',
              fill: false,
              borderColor: '#fff',
              borderWidth: 2,
              yAxisID: 'y-axis-1',
            }
          ],
          barChartOptions: {
            responsive: true,
            elements: {
              line: {
                tension: 0
              }
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  type: 'time',
                  time: {
                    // isoWeekday: true,
                    // unit: 'hour',
                    unit: false,
                    stepSize: 1,
                    displayFormats: {
                      hour: 'HH',
                      // week: 'D MMM'
                    },
                    tooltipFormat: 'HH:mm'
                  },
                  scaleLabel: {
                    display: true,
                    // labelString: 'Date'
                  },
                  ticks: {
                    // min: 0,
                    // max: 24,
                    // fontSize: 10,
                    stepSize: 1,
                    fontColor: '#BABABA',
                    padding: 15,
                    fontSize: 24,
                    maxRotation: 0
                  },
                  gridLines: {
                    drawTicks: false,
                    color: '#6A6A6A',
                    lineWidth: 1
                  }
                }
              ],
              yAxes: [
                {
                  position: 'left',
                  id: 'y-axis-1',
                  display: true,
                  labelString: 'value',
                  ticks: {
                    min: 0,
                    // max: 8,
                    fontColor: '#BABABA',
                    stepSize: 2,
                    padding: 15,
                    fontSize: 24,
                  },
                  gridLines: {
                    drawTicks: false,
                    color: '#6A6A6A',
                    lineWidth: 1
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Alarm Times',
                    fontColor: '#BABABA',
                    fontSize: 24,
                  }
                }
              ],
              pan: {
                enabled: true,
                mode: 'xy'
              },
            },
            tooltips: {
              titleFontSize: 30,
              bodyFontSize: 34,
              borderColor: 'white'
            },
            plugins: {
              p1: false   // not show unit name "kWh"
            },
            legend: {
              display: false // hide chart label name
            },
          },
          chartColors: [
            // all colors in order
            { backgroundColor: '#fff' },
            { backgroundColor: '#fff' }
          ],

          // Chart events
          chartClicked({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          },

          chartHovered({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          }
        }
      );
    }
    // console.log('this.newAlarmChartDataArr:', this.newAlarmChartDataArr);
  }

  subscribeAlarmRecords(startTime: number, endTime: number) {
    return new Promise<AlarmLineChartApi>((resolve, reject) => {
      this.apiSvc.getMainBoardAlarmLineChartData(startTime, endTime)
        .subscribe(
          data => { resolve(data); },
          err => { reject(err); });
    });
  }

  subscribeEquipmentStatus() {
    return new Promise<EquipStatusApi>((resolve, reject) => {
      this.apiSvc.getMainBoardMapLineData()
        .subscribe(
          data => { resolve(data); },
          err => { reject(err); });
    });
  }

  subscribeMoDetail(startTime: number) {
    return new Promise<MoDetailApi>((resolve, reject) => {
      this.apiSvc.getMoDetailData(startTime)
        .subscribe(
          data => { resolve(data); },
          err => { reject(err); });
    });
  }

  subscribeActiveAlarms() {
    return new Promise<ActivedAlarms[]>((resolve, reject) => {
      this.apiSvc.getMainBoardActivedAlarms()
        .subscribe(
          data => { resolve(data); },
          err => { reject(err); });
    });
  }

  // API report alarm issue 已通報-回傳
  reportAnnounceData(reportJSON: PostAlarmReport) {
    this.isSvLoading.loading = true;
    this.apiSvc.postMainBoardAlarmReport(reportJSON).subscribe((data) => {
      console.log('POST_ALARM_REPORT: ', data);
      if (data) {
        // reset popupShowObj
        this.OpenPopupObj = {
          isReport: false,
          isReportAgain: false,
          isRemove: false,
        };

        // call api to update the list
        this.subscribeActiveAlarms().then(res => {
          // this.getMainPanelActivedAlarms(res);
          this.isSvLoading.loading = false;
        });

        alert('通報成功');

      } else {
        alert('通報失敗 => API回傳成功，但通報失敗');
      }
    });
  }

  // API remove alarm issue 已通報-回傳
  removeAnnounceData(reportJSON: PostAlarmRemove) {
    this.isSvLoading.loading = true;
    this.apiSvc.postMainBoardAlarmRemove(reportJSON).subscribe((data) => {
      console.log('POST_ALARM_REMOVE: ', data);
      if (data) {
        // reset popupShowObj
        this.OpenPopupObj = {
          isReport: false,
          isReportAgain: false,
          isRemove: false,
        };

        // call api to update the list
        this.subscribeActiveAlarms().then(res => {
          // this.getMainPanelActivedAlarms(res);
          this.isSvLoading.loading = false;
        });

        alert('通報成功');

      } else {
        alert('通報失敗 => API回傳成功，但通報失敗');
      }
    });
  }

  // reportIssue
  alarmReport(sourceId: string, logtime: number) {
    this.reportDotnetApiJSON.sourceId = sourceId;
    this.reportDotnetApiJSON.alarmTime = logtime;
    this.reportAnnounceData(this.reportDotnetApiJSON);
    console.log('API Report: ', this.reportDotnetApiJSON);
  }

  // removeIssue
  alarmRemove(sourceId: string, logtime: number) {
    this.reportDotnetApiJSON.sourceId = sourceId;
    this.reportDotnetApiJSON.alarmTime = logtime;
    this.removeAnnounceData(this.reportDotnetApiJSON);
    console.log('API Remove: ', this.reportDotnetApiJSON);
  }

  getMainPanelAlarmRecords(alarmRecord: AlarmLineChartApi) {
    console.log('getAlarmLineChart res:', alarmRecord);

    const equipmentObj: { chartTitle: string; barChartData: { x: string; y: number; }[]; } = { chartTitle: null, barChartData: [] };
    const energyObj: { chartTitle: string; barChartData: { x: string; y: number; }[]; } = { chartTitle: null, barChartData: [] };
    const environmentObj: { chartTitle: string; barChartData: { x: string; y: number; }[]; } = { chartTitle: null, barChartData: [] };
    const resArray: { chartTitle: string; barChartData: { x: string; y: number; }[]; }[] = [];

    equipmentObj.chartTitle = 'Equipment';
    alarmRecord.equipmentAlarms.map((o) => { equipmentObj.barChartData.push({ x: o.label, y: o.value }); });
    resArray.push(equipmentObj);

    energyObj.chartTitle = 'Energy';
    alarmRecord.energyAlarms.map((o) => { energyObj.barChartData.push({ x: o.label, y: o.value }); });
    resArray.push(energyObj);

    environmentObj.chartTitle = 'Environment';
    alarmRecord.environmentAlarms.map((o) => { environmentObj.barChartData.push({ x: o.label, y: o.value }); });
    resArray.push(environmentObj);

    this.buildLinCharArr(resArray);
    console.log('lineChartArray: ', resArray);
  }


  getMainPanelActivedAlarms(activedAlarms: DotnetApi.MainPanel.ActivedAlarms[]) {
    console.log('ActiveAlarms: ', activedAlarms);
    this.alarmData.length = 0;
    for (const alarmRecord of activedAlarms) {
      let lastReportTime: string = null;
      if (alarmRecord.lastReportTime !== null) {
        lastReportTime = this.datePipe.transform(alarmRecord.lastReportTime, 'yyyy/M/dd HH:mm');
      }
      const obj = {
        sourceId: alarmRecord.sourceId,
        name: alarmRecord.line + ' - ' + alarmRecord.deviceType + ' - ' + alarmRecord.deviceName,
        logTime: this.datePipe.transform(alarmRecord.alarmTime, 'yyyy/M/dd HH:mm'),
        reportTime: lastReportTime,
        isReport: alarmRecord.isReported,
        isDelete: false,
        dbLogTime: alarmRecord.alarmTime
      };
      this.alarmData.push(obj);
    }
  }

  getMainPanelEquipmentStatus(status: EquipStatusApi) {
    console.log('EquipmentStatus: ', status);
    this.smtData.lineObj = Object.assign(status.SMT);
    this.loadingData.lineObj = Object.assign(status.LOD);
    // this.energyData.lineObj = Object.assign(status.energy);
    // this.envData.lineObj = Object.assign(status.environment);
  }

  getMainPanelMoDetail(moDetail: MoDetailApi) {
    console.log('getMoDetailData:', moDetail);

    let detailTotal: number;
    detailTotal = moDetail.detail.closed.onTime + moDetail.detail.closed.overdue + moDetail.detail.inProgress.onTime + moDetail.detail.inProgress.overdue;

    this.pieChartDataArr[0].pieChartData[0].data = [];

    this.moOverviewObj.moClose = moDetail.closeMo;
    this.moOverviewObj.moToBe = moDetail.toBeProcessed;

    this.moStatusDetailObj.closed = moDetail.detail.closed.onTime;
    this.moStatusDetailObj.closedPercent = moDetail.detail.closed.onTime / detailTotal * 100;
    this.pieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.closed);

    this.moStatusDetailObj.closedOverdue = moDetail.detail.closed.overdue;
    this.moStatusDetailObj.closedOverduePercent = moDetail.detail.closed.overdue / detailTotal * 100;
    this.pieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.closedOverdue);

    this.moStatusDetailObj.InProgress = moDetail.detail.inProgress.onTime;
    this.moStatusDetailObj.InProgressPercent = moDetail.detail.inProgress.onTime / detailTotal * 100;
    this.pieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.InProgress);

    this.moStatusDetailObj.Overdue = moDetail.detail.inProgress.overdue;
    this.moStatusDetailObj.OverduePercent = moDetail.detail.inProgress.overdue / detailTotal * 100;
    this.pieChartDataArr[0].pieChartData[0].data.push(this.moStatusDetailObj.Overdue);

    this.moStatusDetailObj.deliveryRate = moDetail.detail.onTimeRatio * 100;
  }

  getAllApiFn() {
    this.rxTimerStop();
    const now = moment();
    const before24hr = _.cloneDeep(now).subtract(1, 'days');
    Promise.all([
      this.subscribeAlarmRecords(before24hr.valueOf(), now.valueOf()),
      this.subscribeEquipmentStatus(),
      this.subscribeMoDetail(now.valueOf()),
      this.subscribeActiveAlarms()
    ]).then(res => {
      this.getMainPanelAlarmRecords(res[0]);
      this.getMainPanelEquipmentStatus(res[1]);
      this.getMainPanelMoDetail(res[2]);
      // this.getMainPanelActivedAlarms(res[3]);
      this.isSvLoading.loading = false;
      this.rxTimerStart();
    }).catch((error) => {
      console.log('API RESPONSE ERROR: ', error);
      this.isSvLoading.loading = false;
      this.rxTimerStart();
    });
  }

}
