<div class="production_hours_container">
  <div class="title_group">
    <h1 class="title_name">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      Production Hours
    </h1>

    <!-- <p>{{ DATA | json }}</p> -->

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container_box">
    <div class="table_group">
      <!-- search group-->
      <div class="search_group">
        <div class="search">
          <img
            class="search_icon"
            src="../../assets/img/smart_factory/bi_search.svg"
            alt="search-icon"
          />
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search"
            #input
          />
        </div>

        <!-- select -->
        <div class="select">
          <img
            class="select_icon"
            src="../../../assets/img/btn_table_column.svg"
            alt="Group"
            (click)="selectClickIcon()"
          />
          <div
            class="select_choice"
            [ngClass]="{ select_choice_show: selected }"
            *ngIf="displayedColumns as item"
          >
            <p class="select_tip">Column display:</p>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="startTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Start Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="cycleTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Cycle Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="actualTime"
                id=""
              />
              <span class="checkbox_span"></span>
              Act Time
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="efficiency"
                id=""
              />
              <span class="checkbox_span"></span>
              Efficiency
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="targetOutput"
                id=""
              />
              <span class="checkbox_span"></span>
              Mo Qty
            </label>

            <label class="checkbox_gourp">
              <input
                (change)="selectCheckbox($event)"
                checked
                class="checkbox_input"
                type="checkbox"
                name="select_name1"
                value="output"
                id=""
              />
              <span class="checkbox_span"></span>
              Output Qty
            </label>
          </div>
        </div>
      </div>

      <div class="table_scroll_box">
        <!-- 第一層 Table -->
        <div class="table_container mat-elevation-z8">
          <!-- Table 吃 DataSource資料 -->
          <mat-table
            #table
            [dataSource]="outDataSource"
            multiTemplateDataRows
            matSort
            #sort="matSort"
            matSortActive="startTime"
            matSortDirection="desc"
            (matSortChange)="sortData($event)"
          >
            <!-- Part No. Column -->
            <ng-container matColumnDef="product">
              <mat-header-cell
                mat-sort-header
                disableClear="true"
                start="desc"
                *matHeaderCellDef
                class=""
                style="flex-grow: 1.7"
              >
                Part No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1.7"
              >
                {{ element.product }}
              </mat-cell>
            </ng-container>

            <!-- MONo Column -->
            <ng-container matColumnDef="order">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 1.5"
              >
                MO No.
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.5"
              >
                {{ element.order }}
              </mat-cell>
            </ng-container>

            <!-- Start Time Column -->
            <ng-container matColumnDef="startTime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                class=""
                style="flex-grow: 1.3"
              >
                Start time
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                class=""
                style="flex-grow: 1.3"
              >
                <span>
                  {{ element.startTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Cycle Time(IE) Column -->
            <ng-container matColumnDef="cycleTime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 1.5"
              >
                <!-- Std Time(IE) -->
                Cycle Time<br />(IE)
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.5"
              >
                <span class="cell_text_right" [title]="element.cycleTime + 's'">
                  {{ element.formattedCycleTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Act Time Column -->
            <ng-container matColumnDef="actualTime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 1.2"
              >
                Act Time <br />
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.2"
              >
                <span
                  class="cell_text_right"
                  [title]="
                    element.saveActualTime === undefined
                      ? '-'
                      : element.actualTime + 's'
                  "
                >
                  {{ element.formattedActualTime }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Efficiency Column -->
            <ng-container matColumnDef="efficiency">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 1.5"
              >
                Efficiency
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 1.5"
              >
                <span
                  class="cell_text_right"
                  [ngClass]="{
                    c_yellow:
                      +element.formattedEfficiency !== 0 &&
                      element.formattedEfficiency !== '-'
                        ? !+element.formattedEfficiency ||
                          +element.formattedEfficiency >= 105
                        : false,
                    c_red: +element.formattedEfficiency < 95
                  }"
                >
                  {{ element.formattedEfficiency }} %
                </span>
              </mat-cell>
            </ng-container>

            <!-- MO_Quantity Column -->
            <ng-container matColumnDef="targetOutput">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 0.8"
              >
                MO Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 0.8"
              >
                <span class="cell_text_right">
                  {{ element.formattedTargetOutput }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Output Qty Column -->
            <ng-container matColumnDef="output">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear="true"
                start="desc"
                style="flex-grow: 0.9"
              >
                Output Qty
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = dataIndex"
                style="flex-grow: 0.9"
              >
                <span class="cell_text_right">
                  {{ element.formattedOutput }}
                </span>

                <!-- 數據異常 -->
                <!-- <span
                  class="cell_text_right"
                  [ngClass]="{
                    warn_c_red: element.MO_Quantity < element.Finish_Quantity
                  }"
                >
                  {{ element.Finish_Quantity | number }}
                </span> -->
              </mat-cell>
            </ng-container>

            <!-- 第二層 table   Start ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->
            <ng-container matColumnDef="expandedDetail">
              <mat-cell
                *matCellDef="let row"
                class="innerTable"
                [@detailExpand]="
                  row.selfID === expandedElement.selfID &&
                  expandedElement.isExpanded
                    ? 'expanded'
                    : 'collapsed'
                "
              >
                <mat-table
                  [dataSource]="row.child"
                  multiTemplateDataRows
                  matSort
                  #innerTables
                  #innerSort="matSort"
                  matSortActive="startTime"
                  matSortDirection="desc"
                  (matSortChange)="sortData($event)"
                  style="width: 100%"
                >
                  <!-- action Column -->
                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      <!-- action -->
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                  </ng-container>

                  <!-- line Column -->
                  <ng-container matColumnDef="line">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Line
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ element.line }}
                    </mat-cell>
                  </ng-container>

                  <!-- Section Column -->
                  <ng-container matColumnDef="section">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ element.section }}
                    </mat-cell>
                  </ng-container>

                  <!-- Side Column -->
                  <ng-container matColumnDef="side">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Side
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ element.side }}
                    </mat-cell>
                  </ng-container>

                  <!-- Start Time Column -->
                  <ng-container matColumnDef="startTime">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Start Time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{ element.startTime }}
                    </mat-cell>
                  </ng-container>

                  <!-- Act Time Column -->
                  <ng-container matColumnDef="actualTime">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Act Time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      <span class="cell_text_right">
                        {{ element.formattedActualTime }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <!-- Output Qty Column -->
                  <ng-container matColumnDef="output">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Output Qty
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      <span class="cell_text_right">
                        {{ element.formattedOutput }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <!-- efficiency Column -->
                  <ng-container matColumnDef="efficiency">
                    <mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header
                      disableClear="true"
                      start="desc"
                      style="flex-grow: 1"
                    >
                      Efficiency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      <span
                        class="cell_text_right"
                        [ngClass]="{
                          c_yellow:
                            +element.formattedEfficiency !== 0 &&
                            element.formattedEfficiency !== '-'
                              ? !+element.formattedEfficiency ||
                                +element.formattedEfficiency >= 105
                              : false,
                          c_red: +element.formattedEfficiency < 95
                        }"
                      >
                        {{ element.formattedEfficiency }} %
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="innerDisplayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: innerDisplayedColumns"
                    matRipple
                    class="element-row"
                  >
                  </mat-row>
                </mat-table>
              </mat-cell>
            </ng-container>
            <!-- 第二層 table   END ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->

            <!-- action "^" -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef style="flex-grow: 0.1">
                <!-- action -->
              </mat-header-cell>
              <mat-cell *matCellDef="let element" style="flex-grow: 0.1">
                <!-- 判斷有沒有 children -->
                <ng-container *ngIf="element.child">
                  <button
                    mat-icon-button
                    *ngIf="expandedElement.selfID !== element.selfID"
                    [class.expanded]="expandedElement === element"
                    (click)="expendClick(element)"
                  >
                    <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                    <mat-icon>
                      <img
                        src="/assets/img/smart_factory/Arrow_right.svg"
                        alt=""
                      />
                    </mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    *ngIf="expandedElement.selfID === element.selfID"
                    [class.expanded]="expandedElement === false"
                    (click)="expandedElement = false"
                  >
                    <!-- <mat-icon>keyboard_arrow_up</mat-icon> -->
                    <mat-icon>
                      <img
                        src="/assets/img/smart_factory/Arrow_down.svg"
                        alt=""
                      />
                    </mat-icon>
                  </button>
                </ng-container>
              </mat-cell>
            </ng-container>

            <!-- 表頭 sticky: true => fix ".mat-header-row" -->
            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              matRipple
              class="element-row"
            >
            </mat-row>
            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              style="overflow: hidden"
              class="example-detail-row"
            >
            </mat-row>
          </mat-table>
        </div>

        <!-- page navigation-->
        <mat-paginator
          class="paginator"
          #MatPaginator
          [showFirstLastButtons]="true"
          [length]="outDataSource"
          [pageIndex]="0"
          [pageSize]="12"
          (page)="onPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
