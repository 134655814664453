<div class="main_board">
  <div class="map_box">
    <!-- logo -->
    <img
      class="logo"
      routerLink="/menu"
      src="/assets/img/logo_larg.png"
      alt="logo"
    />
    <!-- icon control -->
    <div class="map_button_group">
      <div
        class="icon_hover elec_icon_svg"
        (click)="iconClick('elec')"
        [ngClass]="{ elec_icon_svg_active: elec.elec_icon_svg_active }"
      ></div>
      <div
        class="icon_hover cctv_icon_svg"
        (click)="iconClick('cctv')"
        [ngClass]="{ cctv_icon_svg_active: cctv.cctv_icon_svg_active }"
      ></div>
      <div
        class="icon_hover equipment_icon_svg"
        (click)="iconClick('equipment')"
        [ngClass]="{
          equipment_icon_svg_active: equipment.equipment_icon_svg_active
        }"
      ></div>
      <div
        class="icon_hover env_icon_svg"
        (click)="iconClick('env')"
        [ngClass]="{ env_icon_svg_active: env.env_icon_svg_active }"
      ></div>
    </div>

    <!-- Bg img -->
    <div class="map_img_group">
      <div class="map_img elec_bg" [ngClass]="{ elec_bg: elec.elec_bg }"></div>
      <div class="map_img cctv_bg" [ngClass]="{ cctv_bg: cctv.cctv_bg }"></div>
      <div
        class="map_img equipment_bg"
        [ngClass]="{ equipment_bg: equipment.equipment_bg }"
      ></div>
      <div class="map_img env_bg" [ngClass]="{ env_bg: env.env_bg }"></div>

      <!-- Aler messages Energy -->
      <div
        class="map_msg elec_msg"
        [ngClass]="{
          elec_msg_active: elec.elec_msg_active,
          map_msg_bg_red: elec.Energy.Energy_red_light === true
        }"
      >
        <div class="msg_title">
          <div
            class="light elec_msg_light"
            [ngClass]="{
              light_red: elec.Energy.Energy_red_light === true
            }"
          ></div>
          Energy
        </div>

        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show: elec.Energy.Energy_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="let item of elec.Energy.Energy_data; trackBy: trackByFn"
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.sensorId }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aler messages cctv -->
      <div
        class="map_msg cctv_msg"
        [ngClass]="{
          cctv_msg_active: cctv.cctv_msg_active,
          map_msg_bg_red: cctv.cctvData.cctvData_red_light === true
        }"
      >
        <div class="msg_title">
          &emsp;CCTV
        </div>

        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show: cctv.cctvData.cctvData_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="let item of cctv.cctvData.cctvData_data; trackBy: trackByFn"
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.status_name }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aler messages smt -->
      <div
        class="map_msg equipment_smt_msg"
        [ngClass]="{
          equipment_msg_active: equipment.equipment_msg_active,
          map_msg_bg_red: equipment.SMT.SMT_red_light === true
        }"
      >
        <div class="msg_title">
          <div
            class="light elec_msg_light"
            [ngClass]="{
              light_red: equipment.SMT.SMT_red_light === true
            }"
          ></div>
          SMT
        </div>
        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show: equipment.SMT.SMT_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="let item of equipment.SMT.SMT_data; trackBy: trackByFn"
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.station }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aler messages ATE -->
      <div
        class="map_msg equipment_ate_msg"
        [ngClass]="{
          equipment_msg_active: equipment.equipment_msg_active,
          map_msg_bg_red: equipment.ATE.ATE_red_light === true
        }"
      >
        <div class="msg_title">
          &emsp;ATE
        </div>
        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show: equipment.ATE.ATE_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="let item of equipment.ATE.ATE_data; trackBy: trackByFn"
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.station }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aler messages load -->
      <div
        class="map_msg equipment_load_msg"
        [ngClass]="{
          equipment_msg_active: equipment.equipment_msg_active,
          map_msg_bg_red: equipment.Load.Load_red_light === true
        }"
      >
        <div class="msg_title">
          <div
            class="light elec_msg_light"
            [ngClass]="{
              light_red: equipment.Load.Load_red_light === true
            }"
          ></div>
          Loading
        </div>
        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show: equipment.Load.Load_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="let item of equipment.Load.Load_data; trackBy: trackByFn"
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.station }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aler messages env -->
      <div
        class="map_msg env_msg"
        [ngClass]="{
          env_msg_active: env.env_msg_active,
          map_msg_bg_red: env.Environment.Environment_red_light === true
        }"
      >
        <div class="msg_title">
          <div
            class="light elec_msg_light"
            [ngClass]="{
              light_red: env.Environment.Environment_red_light === true
            }"
          ></div>
          Environment
        </div>
        <div
          class="msg_status_group"
          [ngClass]="{
            msg_status_group_show:
              env.Environment.Environment_red_light === true
          }"
        >
          <div
            class="status_group"
            *ngFor="
              let item of env.Environment.Environment_data;
              trackBy: trackByFn
            "
          >
            <div class="status_titile">
              <div
                class="status_light status_light_blue"
                [ngClass]="{ status_light_red: item.status_red_light === true }"
              ></div>
              {{ item.sensorId }}
            </div>

            <div class="status_button_groups">
              <div
                class="status_button_report_issue"
                [ngClass]="{
                  status_button_report_issue_show:
                    item.isReportIssue === true &&
                    item.status_red_light === true
                }"
                (click)="openReportQuestion(item)"
              >
                Report Issue
              </div>

              <div
                class="status_button_is_reported"
                [ngClass]="{
                  status_button_is_reported_show:
                    item.isReported === true && item.status_red_light === true
                }"
              >
                Issue Reported
              </div>

              <div
                class="status_button_question"
                [ngClass]="{
                  status_button_question_show: item.openQuestion === true
                }"
              >
                <div
                  class="status_question_report"
                  (click)="apiReportIssue(item)"
                >
                  Report
                </div>
                <div
                  class="status_question_cancel"
                  (click)="cancelAapiRepo(item)"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 本日總進度 -->
  <div class="data_box">
    <div class="time">
      <p>{{ this.clock.isRealTimeClock | date: "y/M/dd EEEE" }}</p>
    </div>

    <div class="total_percent">
      <div class="total_title_groups">
        <div class="title_group">
          <p class="title_name">Closed MO</p>
          <p class="title_number">{{ closeMoNum | number }}</p>
        </div>
        <div class="title_group">
          <p class="title_name">Total MO</p>
          <p class="title_number">{{ totalMoNum | number }}</p>
        </div>
      </div>
      <div class="total_bar">
        <div class="bar_color" [style]="'width:' + totalDayPercent + '%'"></div>
        <div class="bar_percent">{{ totalDayPercent }} %</div>
      </div>
    </div>

    <!-- 工單 percent bar -->
    <div class="mo_percent_group">
      <div class="mo_title_group">
        <div class="title_mo">MO Number</div>
        <div class="title_pro">Status</div>
      </div>
      <div class="mo_group_box">
        <div class="mo_group" *ngFor="let item of moProgressData">
          <div class="mo_title">{{ item.order }}</div>
          <div class="mo_bar">
            <div
              class="bar_color"
              [style]="'width:100%'"
              [ngClass]="{
                bar_color_proccess: item.status === 'In production',
                bar_color_close: item.status === 'Close'
              }"
            ></div>

            <div class="bar_percent">
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Aler表單 現在時間往前 24h資料 ngx-moment -->
    <div class="mo_status_group">
      <div class="table_group">
        <div class="title_time">Time</div>
        <div class="title_equ">Equipment</div>
        <div class="title_status">Status</div>
      </div>
      <div class="mo_status_table">
        <div class="table_group" *ngFor="let item of befor24HourData">
          <div class="title_time">
            <!--  ngx-moment pipe -->
            {{ item.time | amLocal | amDateFormat: "YYYY/MM/DD HH:mm" }}
          </div>
          <div class="title_equ">{{ item.name }}</div>
          <div class="title_status">
            {{ item.isReported === true ? "Issue Reported" : item.status }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
