<div class="menu">
  <div class="feature1">
    <div
      class="item"
      *ngFor="let item of menuList; let i = index"
      (click)="isFocus = i; curModule = item"
      [ngClass]="{ isfocus: isFocus == i }"
    >
      <p>{{ item.moduleName }}</p>
      <img src="{{ item.img }}" />
    </div>
  </div>

  <div class="feature2">
    <div class="contnet" *ngIf="curModule.linkUrl != ''">
      <p>{{ curModule.moduleName }}</p>
      <img src="{{ curModule.img }}" />
      <a
        class="submit-bt"
        [routerLink]="'/smartFactory/web/' + curModule.linkUrl"
        [queryParams]="curModule.queryParams"
      >
        Selection
      </a>
    </div>
  </div>
</div>
