<div class="production_overview">
  <div class="title_group">
    <h1 class="production_overview_title">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      Production Overview
    </h1>

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <!-- <p>{{ DATA | json }}</p> -->

  <div class="table_group">
    <!-- search group-->
    <div class="search_group">
      <div class="search">
        <img
          class="search_icon"
          src="../../assets/img/smart_factory/bi_search.svg"
          alt="search-icon"
        />
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          #input
        />
      </div>

      <!-- select -->
      <div class="select">
        <img
          class="select_icon"
          src="../../../assets/img/btn_table_column.svg"
          alt="Group"
          (click)="selectClickIcon()"
        />
        <div
          class="select_choice"
          [ngClass]="{ select_choice_show: selected }"
          *ngIf="displayedColumns as item"
        >
          <p class="select_tip">Column display:</p>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="startTime"
              id=""
            />
            <span class="checkbox_span"></span>
            Start Time
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="endTime"
              id=""
            />
            <span class="checkbox_span"></span>
            End Time
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="productTime"
              id=""
            />
            <span class="checkbox_span"></span>
            Duration
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="targetOutput"
              id=""
            />
            <span class="checkbox_span"></span>
            MO Qty
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="input"
              id=""
            />
            <span class="checkbox_span"></span>
            Input Qty.
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="output"
              id=""
            />
            <span class="checkbox_span"></span>
            Output Qty.
          </label>

          <label class="checkbox_gourp">
            <input
              (change)="selectCheckbox($event)"
              checked
              class="checkbox_input"
              type="checkbox"
              name="select_name1"
              value="achieveRate"
              id=""
            />
            <span class="checkbox_span"></span>
            Achieve Rate
          </label>
        </div>
      </div>
    </div>

    <div class="table_scroll_box">
      <!-- 第一層 Table -->
      <div class="table_container mat-elevation-z8">
        <!-- Table 吃 DataSource資料 -->
        <mat-table
          #table
          [dataSource]="outDataSource"
          multiTemplateDataRows
          matSort
          #sort="matSort"
          matSortActive="startTime"
          matSortDirection="desc"
          (matSortChange)="sortData($event)"
        >

          <!-- MO No. Column -->
          <ng-container matColumnDef="order">
            <mat-header-cell
              class=""
              style="flex-grow: 2"
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
            >
              MO No.
            </mat-header-cell>
            <mat-cell
              class=""
              style="flex-grow: 2"
              *matCellDef="let element; let i = dataIndex"
            >
              {{ element.order }}
            </mat-cell>
            <!-- <mat-cell
              class=""
              style="flex-grow: 2"
              *matCellDef="let element; let i = dataIndex"
            >
              <a
                class="text_blue"
                [href]="element.gfUrlPath"
                target="_blank"
                *ngIf="element.Section === 'ATE' || element.gfUrlPath"
              >
                {{ element.order }}
              </a>
              <p
                *ngIf="
                  element.Section === 'Assembly' ||
                  element.Section === 'Packing' ||
                  element.Section === 'N/A'
                "
              >
                {{ element.order }}
              </p>
              <p
                *ngIf="
                  element.Section === 'SMT' && element.gfUrlPath === undefined
                "
              >
                {{ element.order }}
              </p>
              <p
                *ngIf="
                  element.Section === 'Loading' &&
                  element.gfUrlPath === undefined
                "
              >
                {{ element.order }}
              </p>
              <p *ngIf="element.Section === '-'">
                {{ element.order }}
              </p>
            </mat-cell> -->
          </ng-container>

                    <!-- Part No. Column -->
          <ng-container matColumnDef="product">
            <mat-header-cell
              class=""
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              style="flex-grow: 2.6"
            >
              Part No.
            </mat-header-cell>
            <mat-cell
              class=""
              style="flex-grow: 2.6"
              *matCellDef="let element; let i = dataIndex"
            >
              {{ element.product }}
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="orderStatus">
            <mat-header-cell
              class=""
              style="flex-grow: 2.5"
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
            >
              Production State
            </mat-header-cell>
            <mat-cell class="" style="flex-grow: 2.5" *matCellDef="let element">
              <span
                class="status_color"
                [ngClass]="{
                  status_color_orange: element.orderStatus === 'In production',
                  status_color_blue:
                    element.orderStatus === 'Not in production',
                  status_color_red: element.orderStatus === 'Overdue',
                  status_color_green: element.orderStatus === 'Complete',
                  status_color_gray: element.orderStatus === 'Waiting'
                }"
              >
                {{ element.orderStatus }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Start date -->
          <ng-container matColumnDef="startTime">
            <mat-header-cell
              class=""
              style="flex-grow: 2"
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
            >
              Start time
            </mat-header-cell>
            <mat-cell class="" style="flex-grow: 2" *matCellDef="let element">
              <!-- {{
                element.startTime === "-"
                  ? "-"
                  : (element.startTime | date: "yyyy/M/dd HH:mm")
              }} -->
              {{ element.startTime }}
            </mat-cell>
          </ng-container>

          <!-- End date -->
          <ng-container matColumnDef="endTime">
            <mat-header-cell
              class=""
              style="flex-grow: 2"
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
            >
              End time
            </mat-header-cell>
            <mat-cell class="" style="flex-grow: 2" *matCellDef="let element">
              {{ element.endTime }}
            </mat-cell>
          </ng-container>

          <!-- Duration (D) -->
          <ng-container matColumnDef="productTime">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              class="f_6_Percent"
            >
              Duration (Day)
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="f_6_Percent">
              <span class="cell_text_right">
                {{ element.formattedProductTime }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- MO_Quantity Column -->
          <ng-container matColumnDef="targetOutput">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              class="f_6_Percent"
            >
              MO Qty
            </mat-header-cell>
            <mat-cell
              class="f_6_Percent"
              *matCellDef="let element; let i = dataIndex"
            >
              <span class="cell_text_right">
                {{ element.formattedTargetOutput }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Input_Quantity Column -->
          <ng-container matColumnDef="input">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              class="cell_align_right"
            >
              Input Qty
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="cell_text_right">
                {{ element.formattedInput }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Output Quantity Column -->
          <ng-container matColumnDef="output">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              class="cell_align_right"
            >
              Output Qty
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="cell_text_right">
                {{ element.formattedOutput }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- achieve_Rate Column -->
          <ng-container matColumnDef="achieveRate">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear="true"
              start="desc"
              class="cell_align_right"
              style="flex-grow: 1.5"
            >
              Achieve Rate
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex-grow: 1.5">
              <span class="cell_text_right">
                {{ element.formattedAchieveRate }} %
              </span>
            </mat-cell>
          </ng-container>

          <!-- 第二層 table   Start ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->
          <ng-container matColumnDef="expandedDetail">
            <mat-cell
              *matCellDef="let row"
              class="innerTable"
              [@detailExpand]="
                row.selfID === expandedElement.selfID &&
                expandedElement.isExpanded
                  ? 'expanded'
                  : 'collapsed'
              "
            >
              <mat-table
                [dataSource]="row.child"
                multiTemplateDataRows
                matSort
                #innerTables
                #innerSort="matSort"
                matSortActive="startTime"
                matSortDirection="desc"
                style="width: 100%"
              >
                <!-- action Column -->
                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <!-- action -->
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element"> </mat-cell>
                </ng-container>

                <!-- line Column -->
                <ng-container matColumnDef="line">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.37"
                  >
                    Line
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.38">
                    {{ element.line }}
                  </mat-cell>
                </ng-container>

                <!-- Section Column -->
                <ng-container matColumnDef="section">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.43"
                  >
                    Section
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.45">
                    {{ element.section }}
                  </mat-cell>
                </ng-container>

                <!-- Side Column -->
                <ng-container matColumnDef="side">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.37"
                  >
                    Side
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.38">
                    {{ element.side }}
                  </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="orderStatus">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 1"
                  >
                    Status
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 1.03">
                    <span
                      class="status_color"
                      [ngClass]="{
                        status_color_orange:
                          element.orderStatus === 'In production',
                        status_color_blue:
                          element.orderStatus === 'Not in production',
                        status_color_red: element.orderStatus === 'Overdue',
                        status_color_green: element.orderStatus === 'Complete',
                        status_color_gray: element.orderStatus === 'Waiting'
                      }"
                    >
                      {{ element.orderStatus }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Start Time Column -->
                <ng-container matColumnDef="startTime">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.8"
                  >
                    Start Time
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.8">
                    {{ element.startTime }}
                  </mat-cell>
                </ng-container>

                <!-- End Time Column -->
                <ng-container matColumnDef="endTime">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.8"
                  >
                    End Time
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.8">
                    {{ element.endTime }}
                  </mat-cell>
                </ng-container>

                <!-- Duration Column -->
                <ng-container matColumnDef="productTime">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.5"
                  >
                    Duration
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.5">
                    <span class="cell_text_right">
                      {{ element.formattedProductTime }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- MO Qty Column -->
                <ng-container matColumnDef="targetOutput">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.5"
                  >
                    MO Qty
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.5">
                    <span class="cell_text_right">
                      {{ element.formattedTargetOutput }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Input Qty Column -->
                <ng-container matColumnDef="input">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.4"
                  >
                    Input Qty
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.4">
                    <span class="cell_text_right">
                      {{ element.formattedInput }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Output Qty Column -->
                <ng-container matColumnDef="output">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.5"
                  >
                    Output Qty
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.5">
                    <span class="cell_text_right">
                      {{ element.formattedOutput }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Achieve Rate Column -->
                <ng-container matColumnDef="achieveRate">
                  <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear="true"
                    start="desc"
                    style="flex-grow: 0.5"
                  >
                    Achieve Rate
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="flex-grow: 0.5">
                    <span class="cell_text_right">
                      {{ element.formattedAchieveRate }} %
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="innerDisplayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: innerDisplayedColumns"
                  matRipple
                  class="element-row"
                >
                </mat-row>
              </mat-table>
            </mat-cell>
          </ng-container>
          <!-- 第二層 table   END ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->

          <!-- action "^" -->
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef style="flex-grow: 0.3">
              <!-- action -->
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex-grow: 0.3">
              <!-- 判斷有沒有 children -->
              <ng-container *ngIf="element.child">
                <button
                  mat-icon-button
                  *ngIf="expandedElement.selfID !== element.selfID"
                  [class.expanded]="expandedElement === element"
                  (click)="expendClick(element)"
                >
                  <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                  <mat-icon>
                    <img
                      src="/assets/img/smart_factory/Arrow_right.svg"
                      alt=""
                    />
                  </mat-icon>
                </button>

                <button
                  mat-icon-button
                  *ngIf="expandedElement.selfID === element.selfID"
                  [class.expanded]="expandedElement === false"
                  (click)="expandedElement = false"
                >
                  <!-- <mat-icon>keyboard_arrow_up</mat-icon> -->
                  <mat-icon>
                    <img
                      src="/assets/img/smart_factory/Arrow_down.svg"
                      alt=""
                    />
                  </mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- 表頭 sticky: true => fix ".mat-header-row" -->
          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            matRipple
            class="element-row"
          >
          </mat-row>
          <mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            style="overflow: hidden"
            class="example-detail-row"
          >
          </mat-row>
        </mat-table>
      </div>

      <!-- page navigation-->
      <mat-paginator
        class="paginator"
        #MatPaginator
        [showFirstLastButtons]="true"
        [length]="outDataSource"
        [pageIndex]="0"
        [pageSize]="12"
        (page)="onPaginateChange($event)"
      >
        <mat-icon color="primary">accessibility</mat-icon>
        <mat-icon color="accent">accessibility</mat-icon>
        <mat-icon color="warn">accessibility</mat-icon>
      </mat-paginator>
    </div>
  </div>
</div>
