import { Chart } from 'chart.js';
import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';


import { ApiService } from '../_services/apiSvc.service';
import { HttpClient } from '@angular/common/http';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SaveMonoInformationService } from '../_services/save-mono-information.service';
import { SpinnerService } from '../_services/spinner.service';

@Component({
  selector: 'app-mo-quality',
  templateUrl: './mo-quality.component.html',
  styleUrls: ['./mo-quality.component.scss'],
  providers: [DatePipe]
})
export class MoQualityComponent implements OnInit, OnDestroy {

  upDateTimer;

  isDefectFirstStationName;

  begingTime;
  before24Time;

  getStationDataNoData = false;
  getHourlyDataNoData = false;

  moDataArry: any = [
    {
      mo_no: 'CDA37EAY003',
      model: 'PBC011',
      customer: 'IBM',
      yieldRate: '90',
      defect_qty: '12',
      defect_cause: '002',
    }
  ];

  stationDataArr: any = [
  ];

  // ng2-charts
  public createArr: any = [];
  public chartHourlyDataArr: any = [];
  // public chartHourlyDataArr: any = [
  //   {
  //     chartTitle: 'ATE hiPotTest2',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [10, 15, 18, 31, 21, 1, 51, 1, 21, 51, 21, 31, 1, 61, 1, 51, 15, 19, 81, 1, 51, 18, 16, 14,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [10, 0, 20, 0, 40, 0, 20, 10, 50, 0, 20, 0, 78, 15, 6, 80, 20, 20, 0, 0, 0, 80, 0, 80,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               // min: 0,
  //               // max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               // min: 0,
  //               // max: 40,
  //               stepSize: 7,
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       // console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       // console.log(event, active);
  //     }
  //   },

  //   {
  //     chartTitle: 'ATE functionTest1',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 17, 13, 8, 10, 0, 0, 0,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     }
  //   },

  //   {
  //     chartTitle: 'ATE functionTest2',
  //     barChartData: [
  //       {
  //         type: 'line',
  //         data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
  //         label: 'Accepted',
  //         borderColor: '#FF6B00',
  //         borderWidth: 2,
  //         yAxisID: 'y-axis-1',
  //       },
  //       {
  //         stack: 'stack_A',
  //         data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
  //         label: 'Accepted_bar',
  //         borderWidth: 1,
  //         yAxisID: 'y-axis-0',
  //       }
  //     ],
  //     barChartLabels: [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13',
  //       '14',
  //       '15',
  //       '16',
  //       '17',
  //       '18',
  //       '19',
  //       '20',
  //       '21',
  //       '22',
  //       '23',
  //       '24'
  //     ],
  //     barChartOptions: {
  //       responsive: true,
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 24,
  //               stepSize: 1,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             position: 'left',
  //             id: 'y-axis-0',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#00FFF0',
  //               padding: 15
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           },
  //           {
  //             position: 'right',
  //             id: 'y-axis-1',
  //             display: true,
  //             ticks: {
  //               min: 0,
  //               max: 40,
  //               stepSize: 10,
  //               fontColor: '#FF5C00',
  //               padding: 15,
  //               callback: label => {
  //                 return label + '%';
  //               }
  //             },
  //             gridLines: {
  //               drawTicks: false,
  //               color: 'rgba(171,171,171,1)',
  //               lineWidth: 0.5
  //             }
  //           }
  //         ]
  //       },
  //       tooltips: {
  //         borderColor: 'green'
  //       },
  //       plugins: {
  //         p1: false   // not show unit name "kWh"
  //       },
  //       legend: {
  //         display: false // hide chart label name
  //       },
  //     },
  //     barChartLegend: true,
  //     barChartType: 'bar',
  //     chartColors: [
  //       // all colors in order
  //       { backgroundColor: 'rgba(255, 107, 0, 0)' },
  //       { backgroundColor: '#00FFF0' }
  //     ],

  //     // Chart events
  //     chartClicked({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     },

  //     chartHovered({
  //       event,
  //       active
  //     }: {
  //       event: MouseEvent;
  //       active: {}[];
  //     }): void {
  //       console.log(event, active);
  //     }
  //   }
  // ];

  // rankStationName Data

  rankStationName: any = [];
  // rankStationName: any = ['function1', 'function2', 'function3', 'function4', 'function5', 'function6'];

  // rankBar Data
  rankBar: any = [
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
    {
      testStation: '-',
      occurrence: '-',
      percent: '0',
    },
  ];

  dataDefectRankOutstripFive = false;

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public moSvc: SaveMonoInformationService,
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public clock: RealTimeClockService
  ) {

    // 直接進入 Mo Quality 導回 Quality Overview
    if (this.moSvc.order === null) {
      this.router.navigate(['/smartFactory/web/quality-overview']);
    }

  }

  getCurrentSn(callback: (sn: string) => void) {
    this.route.queryParams.subscribe(params => {
      const sn = params['sn'];
      callback(sn);
    });
  }

  ngOnInit(): void {
    this.isSvLoading.loading = true;

    this.getCurrentSn((sn) => {
      this.getPerformanceData(sn);
      this.getDefectStationNameData();
    });
    this.getBegingEndTime();
    // this.getDefectRankingTop10Data(this.isOrderNumber, stationName);

    // this.getHourlyData('CDBD829001', '2020-12-09T16:00:00.000Z', '2020-12-11T16:00:00.000Z');
    // this.getPerformanceData('CD8SJ02011');

    this.upDateTimer = setInterval(() => {
      this.getCurrentSn((sn) => {
        this.getHourlyData(sn, this.begingTime, this.before24Time);
      });
    }, 30000);
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
  }

  // get beging & endTime
  getBegingEndTime() {
    this.before24Time = new Date().toISOString();
    this.begingTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString();
    console.log('this.begingTime:', this.begingTime);
    console.log('this.before24Time:', this.before24Time);
  }

  callRankingTop10(stationName) {
    console.log('stationName:', stationName);
    this.getCurrentSn((sn) => {
      console.log(`query param 'sn':`, sn);
      this.getDefectRankingTop10Data(sn, stationName);
    });
  }

  // Group chart data by function name
  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  // 整理 Hourly Data 成 Object 資料
  arrangeHourlyDataObject(arr) {
    let newArr = this.groupBy(arr, 'function');
    return newArr = Object.keys(newArr).map((key) => {
      return newArr[key];
    });
  }

  // 整理 Hourly Data 成 Array資料
  arrangeHourlyDataArr(arr) {
    return Object.keys(arr).map((key) => {
      arr[key].functionName = '';
      arr[key].yieldRate = [];
      arr[key].ouput = [];
      arr[key].logTime = [];

      return arr[key].map((x) => {
        // console.log('for x:', x);
        arr[key].functionName = x.processSection + ' ' + x.function;
        arr[key].yieldRate.push((+x.yieldRate * 100).toFixed(2));
        arr[key].ouput.push(+x.ouput.toFixed(0));
        arr[key].logTime.push(this.datePipe.transform(x.logTime, 'HH'));
        return x;
      });
    });

  }

  // API Station Performance 資料
  getPerformanceData(orderNum) {
    this.apiSvc.getMoQualityPerformanceData(orderNum).subscribe(res => {
      console.log('getPerformanceData res:', res);
      if (res.length === 0) {
        console.log('getPerformanceData res is Empty');
        this.getStationDataNoData = true;
        return;
      }
      res.map((item) => {
        // item.yieldRate = Math.round(+item.yieldRate * 100);
        console.log('yieldRate:', item.yieldRate);
        item.yieldRate = (Math.fround(item.yieldRate * 100));
        return item;
      });
      this.stationDataArr = res;
    });
  }

  // API Hourly 資料
  getHourlyData(orderNum, startTime, endTime) {
    this.apiSvc.getMoQualityHourlyData(orderNum, startTime, endTime).subscribe(res => {
      this.createArr = [];
      // request is empty array
      if (res.length === 0) {
        console.log('getHourlyData res is Empty');
        this.getHourlyDataNoData = true;
        return;
      }
      // Original res
      console.log('get Original HourlyData res:', res);

      // 時間排序
      res.sort((a, b) => {
        return a.logTime.localeCompare(b.logTime);
      });

      const isObjGroupByName = this.arrangeHourlyDataObject(res);
      const isArrGroupByName = this.arrangeHourlyDataArr(isObjGroupByName);

      console.log('isArrGroupByName:', isArrGroupByName);

      // Chart表，跑陣列
      for (let i = 0; i < isArrGroupByName.length; i++) {
        // isObjGroupByName[i].yieldRate.shift();
        // isObjGroupByName[i].ouput.shift();
        // isObjGroupByName[i].logTime.shift();

        const isYieldRateMaxNum = Math.max.apply(null, isObjGroupByName[i].yieldRate);
        const isOuputMaxNum = Math.max.apply(null, isObjGroupByName[i].ouput);

        const chartData = {
          chartTitle: isObjGroupByName[i].functionName,
          barChartData: [
            {
              type: 'line',
              data: isObjGroupByName[i].yieldRate,
              label: 'Yield Rate',
              borderColor: '#FF6B00',
              borderWidth: 2,
              yAxisID: 'y-axis-right',
            },
            {
              stack: 'stack_A',
              data: isObjGroupByName[i].ouput,
              label: 'Ouput',
              borderWidth: 1,
              yAxisID: 'y-axis-left',
            }
          ],
          barChartLabels: isObjGroupByName[i].logTime,
          barChartOptions: {
            responsive: true,
            scales: {
              xAxes: [
                {
                  display: true,
                  ticks: {
                    // min: 0,
                    // max: 24,
                    stepSize: 1,
                    fontColor: '#fff',
                    // fontColor: '#00FFF0',
                    padding: 15,
                    fontSize: 20
                  },
                  gridLines: {
                    drawTicks: false,
                    color: 'rgba(171,171,171,1)',
                    lineWidth: 0.5
                  },
                }
              ],
              yAxes: [
                {
                  position: 'left',
                  id: 'y-axis-left',
                  display: true,
                  ticks: {
                    min: 0,
                    max: Math.ceil(isOuputMaxNum / 5) * 5 + 10, // 取五的倍數 + 30
                    stepSize: 10,
                    fontColor: '#00FFF0',
                    padding: 15,
                    fontSize: 20
                  },
                  gridLines: {
                    drawTicks: false,
                    color: 'rgba(171,171,171,1)',
                    lineWidth: 0.5
                  }
                },
                {
                  position: 'right',
                  id: 'y-axis-right',
                  display: true,
                  ticks: {
                    min: 0,
                    max: Math.ceil(isYieldRateMaxNum * 1.25),
                    stepSize: 25,
                    fontColor: '#FF5C00',
                    padding: 15,
                    fontSize: 20,
                    callback: label => {
                      return label + '%';
                    }
                  },
                  gridLines: {
                    drawTicks: false,
                    color: 'rgba(171,171,171,1)',
                    lineWidth: 0.5
                  }
                }
              ]
            },
            tooltips: {
              borderColor: 'green',
              titleFontSize: 22,
              bodyFontSize: 18
            },
            plugins: {
              p1: false   // not show unit name "kWh"
            },
            legend: {
              display: false // hide chart label name
            },
          },
          barChartLegend: true,
          barChartType: 'bar',
          chartColors: [
            // all colors in order
            { backgroundColor: 'rgba(255, 107, 0, 0)' },
            { backgroundColor: '#00FFF0' }
          ],

          // Chart events
          chartClicked({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          },

          chartHovered({
            event,
            active
          }: {
            event: MouseEvent;
            active: {}[];
          }): void {
            // console.log(event, active);
          }
        };

        this.createArr.push(chartData);
      }

      this.chartHourlyDataArr = this.createArr;
      console.log('this.createArr:', this.createArr);
    });
  }

  // API Defect Ranking station Name 資料
  getDefectStationNameData() {
    this.getCurrentSn((sn) => {
      this.apiSvc.getMoQualityDefectStationNameData(sn).subscribe(res => {
        console.log('getDefectStationNameData res:', res);
        if (res.length === 0) {
          this.isSvLoading.loading = false;
          return;
        }
        res.sort();
        this.isDefectFirstStationName = res[0];
        this.callRankingTop10(this.isDefectFirstStationName);
        res.shift();
        this.rankStationName = res;
  
        this.isSvLoading.loading = false;
      });
    });
  }

  // API Defect Ranking Top10 資料
  getDefectRankingTop10Data(orderNum, stationName) {
    this.apiSvc.getMoQualityDefectRankingTop10Data(orderNum, stationName).subscribe(res => {
      console.log('getDefectRankingTop10Data res => 11111', res);
      res.map((item) => {
        item.percent = +item.percent * 100;
        return item;
      });
      this.rankBar = res.sort((a, b) => {
        return b.percent - a.percent || b.occurrence - a.occurrence || b.testStation.localeCompare(a.testStation);
      });
      +res.length > 5 ? this.dataDefectRankOutstripFive = true : this.dataDefectRankOutstripFive = false;

      console.log('getDefectRankingTop10Data res => 22222', res);
      // this.rankStationName = res;
    });
  }

}



//  {functionName:[{},{},{}],functionName:[{},{},{}]}
// isObjGroupByName = this.groupBy(res, 'function');
// // [[{functionName},{functionName},{functionName}],]
// isObjGroupByName = Object.keys(isObjGroupByName).map((key) => {
//   return isObjGroupByName[key];
// });
// {functionName:[{},{},{}],functionName:[{},{},{}]}
// tslint:disable-next-line:prefer-for-of
// for (let i = 0; i < isArrGroupByName.length; i++) {
//   Object.keys(isObjGroupByName).map((key) => {
//     isObjGroupByName[key].functionName = '';
//     isObjGroupByName[key].yieldRate = [];
//     isObjGroupByName[key].ouput = [];
//     isObjGroupByName[key].logTime = [];

//     isObjGroupByName[key].map((x) => {
//       // console.log('for x:', x);
//       isObjGroupByName[key].functionName = x.processSection + ' ' + x.function;
//       isObjGroupByName[key].yieldRate.push((+x.yieldRate * 100).toFixed(2));
//       isObjGroupByName[key].ouput.push(+x.ouput.toFixed(0));
//       isObjGroupByName[key].logTime.push(this.datePipe.transform(x.logTime, 'HH'));
//       return x;
//     });
//   });
// }

// console.log('轉陣列, isObjGroupByName:', isObjGroupByName);

// stationDataArr: any = [
//   {
//     function: '-',
//     processSection: '',
//     yieldRate: 0
//   },
//   {
//     function: 'ATE',
//     processSection: 'Initial Test1',
//     yieldRate: 35
//   },
//   {
//     function: 'ATE',
//     processSection: 'Function1',
//     yieldRate: 91
//   },
//   {
//     function: 'ATE',
//     processSection: 'Function5',
//     yieldRate: 36
//   },
//   {
//     function: 'ATE HASS',
//     processSection: '',
//     yieldRate: 8
//   },
//   {
//     function: 'SMT ICT',
//     processSection: '',
//     yieldRate: 41
//   },
//   {
//     function: 'ATE',
//     processSection: 'Initial Test2',
//     yieldRate: 12
//   },
//   {
//     function: 'ATE',
//     processSection: 'function2',
//     yieldRate: 25
//   }
// ];
