import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

export interface Option {
  label: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'app-custom-component-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  constructor(
    private FB: FormBuilder,
  ) { }

  @Input() disabled = false;
  @Input() options: Option[];
  @Input() multiple = false;
  @Output() optionChange = new EventEmitter<Option>();

  dispForm: FormGroup = this.FB.group({
    optionList: [''],
  });

  get optionList(): FormControl {
    return this.dispForm.get('optionList') as FormControl;
  }

  tempArrayString: string = null;

  onChange(event: Event): void {
    // 下拉選單可複選
    if (this.multiple) {
      // when dropdown collection closed would be false
      if (!event) {
        const valueArray = this.dispForm.get('optionList').value;
        // check if array is empty
        if (valueArray?.length > 0) {
          const valueString = JSON.stringify(valueArray);
          // compare if selection is change
          if (this.tempArrayString !== valueString) {
            this.tempArrayString = valueString;
            const option = {
              label: valueString,
              value: valueString,
              selected: true
            };
            this.optionChange.emit(option);
          }
        }
        else {
          // compare if selection is change
          if (this.tempArrayString !== null) {
            this.tempArrayString = null;
            const option = {
              label: null,
              value: null,
              selected: false
            };
            this.optionChange.emit(option);
          }
        }
      }
    }
    // 下拉選單僅單選
    else {
      if (event.target instanceof HTMLSelectElement) {
        const value = event.target.value;
        const option = this.options.find((o) => {
          return o.value === value;
        });
        this.optionChange.emit(option);
      }
    }
  }
}
