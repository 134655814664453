<div class="container-fluid new_production_overview_container">
  <div class="container-fluid title_group">
    <h1 class="title_name">
      <button class="logo-btn" title="logo">
        <img
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>
      Production Overview
    </h1>

    <!-- <p>{{ DATA | json }}</p> -->

    <div class="time">
      <button
        class="download-btn"
        (click)="onClickDownload('Meeting Download')"
      >
        <img
          src="../../../assets/img/btn_meeting.svg"
          alt="meeting download button"
        />
        <p>Meeting</p>
      </button>
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
        <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="apiSvc.ifSSO('Administrator,1E-Editor,2E-Editor,3E-Editor,Viewer'); else PermissionDenied">
    <div class="container-fluid container_box">
      <div class="table_group">
        <!-- search group-->
        <div class="search_group">
          <div class="search">
            <img
              class="search_icon"
              src="../../assets/img/smart_factory/bi_search.svg"
              alt="search-icon"
            />
            <input
              matInput
              placeholder="Search"
              #input
              (keyup.enter)="onEnterSearch(input.value)"
              [disabled]="isSvLoading.loading"
            />
          </div>

          <!-- Data Conditions (Status <=> Section CheckList) -->
          <div class="data_conditions">
            <h3>Data Conditions</h3>
            <img
              class="btn_img"
              src="../../../assets/img/btn_data_conditions.svg"
              alt="Group"
              (click)="this.dataConditionsSelected = true"
            />
            <div
              class="checkbox_panel"
              [ngClass]="{ checkbox_panel_show: dataConditionsSelected }"
            >
              <label>Data Conditions</label>
              <div class="block">
                <div class="status_group">
                  <label>Status</label>
                  <div class="select_all_btn">
                    <button
                      class=""
                      (click)="selectAllClick('status')"
                      [ngClass]="
                        checkSelectAllStatus('status')
                          ? 'light_gray'
                          : 'dark_gray'
                      "
                      [disabled]="!checkSelectAllStatus('status')"
                    >
                      Select All
                    </button>
                  </div>
                  <div class="selection_group">
                    <div
                      *ngFor="let column of dataConditionsStatusSelectionStatus"
                    >
                      <label class="checkbox_gourp">
                        <input
                          (change)="selectStatusCheckbox($event)"
                          [checked]="
                            dataConditionsStatusCheckedBinding(
                              column.columnName
                            )
                          "
                          class="checkbox_input"
                          type="checkbox"
                          name="select_name1"
                          [value]="column.columnName"
                          id=""
                        />
                        <span class="checkbox_span"></span>
                        {{ column.displayName }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="arrow_sign"></div>
                <div class="section_group" style="width: 380px">
                  <label>
                    <span style="width: 40%">Section</span>
                    <span style="width: 60%">Line</span>
                  </label>
                  <div class="select_all_btn">
                    <button
                      class=""
                      (click)="selectAllClick('section')"
                      [ngClass]="
                        checkSelectAllStatus('section')
                          ? 'light_gray'
                          : 'dark_gray'
                      "
                      [disabled]="!checkSelectAllStatus('section')"
                    >
                      Select All
                    </button>
                  </div>
                  <div class="selection_group">
                    <div
                      style="display: flex"
                      *ngFor="
                        let column of dataConditionsSectionSelectionStatus
                      "
                    >
                      <label class="checkbox_gourp" style="width: 40%">
                        <input
                          (change)="selectSectionCheckbox($event)"
                          [checked]="
                            dataConditionsSectionCheckedBinding(
                              column.columnName
                            )
                          "
                          class="checkbox_input"
                          type="checkbox"
                          name="select_name1"
                          [value]="column.columnName"
                          id=""
                        />
                        <span class="checkbox_span"></span>
                        {{ column.displayName }}
                      </label>
                      <mat-form-field floatLabel="always" style="width: 60%">
                        <form [formGroup]="dispForm">
                          <mat-label></mat-label>
                          <!-- SMT Line Dropdown -->
                          <mat-select
                            *ngIf="column.columnName === 'SMT'"
                            formControlName="smtLineList"
                            [placeholder]="
                              column.lineList.length > 0 ? 'All' : 'None'
                            "
                            [disabled]="
                              !column.checked || column.lineList.length == 0
                            "
                            multiple
                          >
                            <mat-select-trigger>
                              <span style="font-size: 18px">{{
                                smtLineList.value ? smtLineList.value[0] : ""
                              }}</span>
                              <span
                                *ngIf="(smtLineList.value?.length || 0) > 1"
                                style="font-size: 16px; opacity: 0.75"
                              >
                                (+{{ (smtLineList.value?.length || 0) - 1 }}
                                {{
                                  smtLineList.value?.length === 2
                                    ? "other"
                                    : "others"
                                }})
                              </span>
                            </mat-select-trigger>
                            <mat-option
                              *ngFor="let lines of column.lineList"
                              [value]="lines"
                              >{{ lines }}</mat-option
                            >
                          </mat-select>
                          <!-- LOD Line Dropdown -->
                          <mat-select
                            *ngIf="column.columnName === 'LOD'"
                            formControlName="lodLineList"
                            [placeholder]="
                              column.lineList.length > 0 ? 'All' : 'None'
                            "
                            [disabled]="
                              !column.checked || column.lineList.length == 0
                            "
                            multiple
                          >
                            <mat-select-trigger>
                              <span style="font-size: 18px">{{
                                lodLineList.value ? lodLineList.value[0] : ""
                              }}</span>
                              <span
                                *ngIf="(lodLineList.value?.length || 0) > 1"
                                style="font-size: 16px; opacity: 0.75"
                              >
                                (+{{ (lodLineList.value?.length || 0) - 1 }}
                                {{
                                  lodLineList.value?.length === 2
                                    ? "other"
                                    : "others"
                                }})
                              </span>
                            </mat-select-trigger>
                            <mat-option
                              *ngFor="let lines of column.lineList"
                              [value]="lines"
                              >{{ lines }}</mat-option
                            >
                          </mat-select>
                          <!-- ASY Line Dropdown -->
                          <mat-select
                            *ngIf="column.columnName === 'ASY'"
                            formControlName="asyLineList"
                            [placeholder]="
                              column.lineList.length > 0 ? 'All' : 'None'
                            "
                            [disabled]="
                              !column.checked || column.lineList.length == 0
                            "
                            multiple
                          >
                            <mat-select-trigger>
                              <span style="font-size: 18px">{{
                                asyLineList.value ? asyLineList.value[0] : ""
                              }}</span>
                              <span
                                *ngIf="(asyLineList.value?.length || 0) > 1"
                                style="font-size: 16px; opacity: 0.75"
                              >
                                (+{{ (asyLineList.value?.length || 0) - 1 }}
                                {{
                                  asyLineList.value?.length === 2
                                    ? "other"
                                    : "others"
                                }})
                              </span>
                            </mat-select-trigger>
                            <mat-option
                              *ngFor="let lines of column.lineList"
                              [value]="lines"
                              >{{ lines }}</mat-option
                            >
                          </mat-select>
                          <!-- PKG Line Dropdown -->
                          <mat-select
                            *ngIf="column.columnName === 'PKG'"
                            formControlName="pkgLineList"
                            [placeholder]="
                              column.lineList.length > 0 ? 'All' : 'None'
                            "
                            [disabled]="
                              !column.checked || column.lineList.length == 0
                            "
                            multiple
                          >
                            <mat-select-trigger>
                              <span style="font-size: 18px">{{
                                pkgLineList.value ? pkgLineList.value[0] : ""
                              }}</span>
                              <span
                                *ngIf="(pkgLineList.value?.length || 0) > 1"
                                style="font-size: 16px; opacity: 0.75"
                              >
                                (+{{ (pkgLineList.value?.length || 0) - 1 }}
                                {{
                                  pkgLineList.value?.length === 2
                                    ? "other"
                                    : "others"
                                }})
                              </span>
                            </mat-select-trigger>
                            <mat-option
                              *ngFor="let lines of column.lineList"
                              [value]="lines"
                              >{{ lines }}</mat-option
                            >
                          </mat-select>
                          <!-- TEST Line Dropdown -->
                          <mat-select
                            *ngIf="column.columnName === 'TEST'"
                            formControlName="testLineList"
                            [placeholder]="
                              column.lineList.length > 0 ? 'All' : 'None'
                            "
                            [disabled]="
                              !column.checked || column.lineList.length == 0
                            "
                            multiple
                          >
                            <mat-select-trigger>
                              <span style="font-size: 18px">{{
                                testLineList.value ? testLineList.value[0] : ""
                              }}</span>
                              <span
                                *ngIf="(testLineList.value?.length || 0) > 1"
                                style="font-size: 16px; opacity: 0.75"
                              >
                                (+{{ (testLineList.value?.length || 0) - 1 }}
                                {{
                                  testLineList.value?.length === 2
                                    ? "other"
                                    : "others"
                                }})
                              </span>
                            </mat-select-trigger>
                            <mat-option
                              *ngFor="let lines of column.lineList"
                              [value]="lines"
                              >{{ lines }}</mat-option
                            >
                          </mat-select>
                        </form>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer_btn_group">
                <div
                  class="select_btn cancel"
                  (click)="selectDataConditionsCancel()"
                >
                  Cancel
                </div>
                <div
                  class="select_btn submit"
                  (click)="selectDataConditionsDone()"
                >
                  Submit
                </div>
              </div>
            </div>
          </div>

          <!-- 下拉選單 Section -->
          <!-- <div class="select_tag">
            <label>Section</label>
            <select (change)="getModelName($event.target.value)">
              <option selected="selected">All</option>
              <option *ngFor="let item of allSectionName">{{ item }}</option>
            </select>
          </div> -->

          <!-- select 欄位篩選器 -->
          <div class="select">
            <label>Column picker</label>
            <img
              class="select_icon"
              src="../../../assets/img/btn_column_picker.svg"
              alt="Group"
              (click)="this.columnPickerSelected = true"
            />
            <div
              class="select_choice"
              [ngClass]="{ select_choice_show: columnPickerSelected }"
              *ngIf="displayedColumns as item"
            >
              <div class="title">
                <p class="select_tip">Column picker</p>
                <span class="select_status"
                  >Selection limit {{ displaySelectedColumnNum }}/10</span
                >
              </div>
              <div class="checkbox_container">
                <div
                  style="display: flex"
                  *ngFor="
                    let column of columnPickerSelectionStatus;
                    let index = index
                  "
                >
                  <span
                    class="index"
                    [ngStyle]="{
                      'margin-right.px': index + 1 >= 10 ? '3' : '16'
                    }"
                    >{{ index + 1 }}</span
                  >
                  <label class="checkbox_gourp">
                    <input
                      (change)="selectColumnPickerCheckbox($event)"
                      [checked]="columnPickerCheckedBinding(column.columnName)"
                      class="checkbox_input"
                      type="checkbox"
                      name="select_name1"
                      [value]="column.columnName"
                      id=""
                    />
                    <span class="checkbox_span"></span>
                    {{ column.displayName }}
                  </label>
                </div>
              </div>
              <div class="footer_btn_group">
                <div
                  class="select_btn cancel"
                  (click)="selectColumnPickerCheckboxCancel()"
                >
                  Cancel
                </div>
                <div
                  class="select_btn submit"
                  (click)="selectColumnPickerCheckboxDone()"
                >
                  Done
                </div>
              </div>
            </div>
          </div>

          <!-- 子層全部 展開/關閉 -->
          <div class="switch_expand">
            <label for="expand" class="checkbox_gourp">
              Expand:
              <input
                type="checkbox"
                name=""
                id="expand"
                class="checkbox_input"
                [checked]="isExpandChildStatus"
                [(ngModel)]="isExpandChildStatus"
                (change)="switchAllChildExpanded()"
              />
              <span class="checkbox_span"></span>
            </label>
          </div>

          <!-- 日期篩選器 -->
          <div class="date_range_picker">
            <h6 class="date_title">Date range:</h6>

            <div class="date_group">
              <p class="date_value">
                {{
                  range.value.start === null
                    ? "- - -"
                    : (range.value.start | date: "YYYY/MM/dd")
                }}
              </p>
              <span class="date_span"> ~ </span>
              <p class="date_value">
                {{
                  range.value.end === null
                    ? "- - -"
                    : (range.value.end | date: "YYYY/MM/dd")
                }}
              </p>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>

              <mat-date-range-input
                [formGroup]="range"
                [min]="minDate"
                [max]="maxDate"
                [dateFilter]="familyDayFilter"
                [rangePicker]="picker"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                  (dateChange)="
                    getRangeDate(range.value.start, range.value.end)
                  "
                />
              </mat-date-range-input>

              <mat-datepicker-toggle matSuffix [for]="picker">
                <!-- <mat-icon matDatepickerToggleIcon>
                  <img src="../../../assets/img/btn_table_column.svg" />
                </mat-icon> -->
              </mat-datepicker-toggle>

              <mat-date-range-picker
                #picker
                startView="month"
                [startAt]="startDate"
              ></mat-date-range-picker>
            </mat-form-field>

            <img
              class="default_button"
              src="../../../assets/img/btn_date_default.svg"
              alt="Group"
              (click)="setDefaultRangeDate()"
              style="margin-left: 12px"
            />
            <!-- <div class="default_button" (click)="setDefaultRangeDate()">
              Default
            </div> -->
          </div>
        </div>

        <div class="table_scroll_box">
          <!-- 第一層 Table -->
          <div class="table_container mat-elevation-z8">
            <!-- API 回傳沒資料 -->
            <div
              *ngIf="outDataSource.data.length === 0"
              class="api_res_no_data"
            >
              No Data
            </div>

            <!-- Table 吃 DataSource資料 -->
            <mat-table
              *ngIf="outDataSource.data.length > 0"
              #table
              [dataSource]="outDataSource"
              multiTemplateDataRows
              matSort
              #sort="matSort"
              matSortActive="startTime"
              matSortDirection="desc"
              (matSortChange)="sortData($event)"
            >
              <!-- MONo Column -->
              <ng-container matColumnDef="sn">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg fs_22"
                  style="flex: none; width: 250px"
                >
                  MO No.
                </mat-header-cell>
                <!-- Tooltip 兩種訊息換行 -->
                <!-- <mat-cell
                  mat-raised-button
                  matTooltipPosition="below"
                  [matTooltip]="
                    'SN :' +
                    element.sn +
                    '
                    SN =>' +
                    element.product
                  "
                  [matTooltipClass]="'mat-tooltip-class-here'"
                  *matCellDef="let element; let i = dataIndex"
                  style="flex-grow: 1.5"
                >
                  {{ this.dataFormatSvc.checkValDash(element.sn) }}
                </mat-cell> -->
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="fs_22"
                  style="flex: none; width: 250px"
                >
                  <!-- <div
                    class="card_link"
                    [routerLink]="['/smartFactory/web/wip-overview']"
                    [queryParams]="{
                      mo: element.sn
                    }"
                  >
                    {{ this.dataFormatSvc.checkValDash(element.sn) }}
                  </div> -->
                  <div *ngIf="element?.mainNo === '-'">
                    <a
                      class="card_link"
                      [routerLink]="['/smartFactory/web/wip-overview']"
                      [queryParams]="{
                        mo: element.sn
                      }"
                      target="_blank"
                    >
                      {{ dataFormatSvc.checkValDash(element.sn) }}
                    </a>
                  </div>
                  <div
                    *ngIf="
                      element?.mainNo !== '-' && element.sn === element?.mainNo
                    "
                    style="width: 100%"
                  >
                    <p class="main_mo">
                      <a
                        class="card_link"
                        [routerLink]="['/smartFactory/web/wip-overview']"
                        [queryParams]="{
                          mo: element.sn
                        }"
                        target="_blank"
                        >{{ dataFormatSvc.checkValDash(element.sn) }}
                      </a>
                      <span class="main_tag">main</span>
                    </p>
                  </div>
                  <div
                    *ngIf="
                      element?.mainNo !== '-' && element.sn !== element?.mainNo
                    "
                  >
                    <p class="sub_title">
                      {{ dataFormatSvc.checkValDash(element.mainNo) }}-
                    </p>
                    <p>
                      <a
                        class="card_link"
                        [routerLink]="['/smartFactory/web/wip-overview']"
                        [queryParams]="{
                          mo: element.sn
                        }"
                        target="_blank"
                      >
                        {{ dataFormatSvc.checkValDash(element.sn) }}
                      </a>
                    </p>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Part No. Column -->
              <ng-container matColumnDef="product">
                <mat-header-cell
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  *matHeaderCellDef
                  class="mo_title_bg fs_22"
                  style="flex: none; width: 236px"
                >
                  Part No.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="fs_22"
                  style="flex: none; width: 236px"
                >
                  {{ this.dataFormatSvc.checkValDash(element.product) }}
                </mat-cell>
              </ng-container>

              <!-- MO_Quantity Column -->
              <ng-container matColumnDef="targetOutput">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg fs_22"
                  style="flex: none; width: 80px"
                  matTooltipPosition="below"
                  [matTooltip]="'MO Qty'"
                >
                  MO
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="fs_22"
                  style="flex: none; width: 80px"
                >
                  <span>
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedTargetOutput
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg fs_22"
                  style="flex: none; width: 210px"
                >
                  Status
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="fs_22"
                  style="flex: none; width: 210px"
                >
                  <span
                    class="status_color"
                    [ngClass]="moStatusBgColor(element.status)"
                  >
                    {{ dataFormatSvc.checkValDash(element.status) }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Start Time Column -->
              <ng-container matColumnDef="startTime">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_120 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Start Time'"
                >
                  Start T.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="width_120 fs_18"
                >
                  <span>
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedStartTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- End Time Column -->
              <ng-container matColumnDef="endTime">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_120 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'End Time'"
                >
                  End T.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="width_120 fs_18"
                >
                  <span>
                    {{
                      this.dataFormatSvc.checkValDash(element.formattedEndTime)
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Input Qty Column -->
              <ng-container matColumnDef="input">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_90 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Input Qty'"
                >
                  INP
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_18">
                  <span>
                    {{ dataFormatSvc.checkValDash(element.formattedInput) }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Output Qty Column -->
              <ng-container matColumnDef="output">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_90 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Output Qty'"
                >
                  OUT
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_18">
                  <span>
                    {{ dataFormatSvc.checkValDash(element.formattedOutput) }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Achieve Rate Column -->
              <ng-container matColumnDef="achieveRate">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_90 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Achieve Rate'"
                >
                  ACHV R.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="width_90 fs_18"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(element.formattedAchieveRate)
                    }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Output Yield Column -->
              <ng-container matColumnDef="outputYield">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_90 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Output Yield'"
                >
                  OUT YLD
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="width_90 fs_18"
                >
                  <span>
                    {{
                      dataFormatSvc.checkValDash(element.formattedOutputYield)
                    }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Efficiency Column -->
              <ng-container matColumnDef="efficiency">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="mo_title_bg width_90 fs_18"
                  matTooltipPosition="below"
                  [matTooltip]="'Efficiency'"
                >
                  EFF
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = dataIndex"
                  class="width_90 fs_18"
                >
                  <span [title]="element.efficiency">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedEfficiency
                      )
                    }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Act Production Time Column -->
              <ng-container matColumnDef="actProductionTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Act Prod Time'"
                >
                  Act Prod T.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  matTooltipPosition="below"
                  [matTooltip]="
                    'standardProdTime : ' + element.standardProdTime + 's'
                  "
                  class="width_90 fs_16"
                >
                  <span
                    class=""
                    [ngClass]="{
                      c_red: actProductionTimeFontColor(element)
                    }"
                  >
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedActProductionTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Standard Production Time -->
              <ng-container matColumnDef="standardProdTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Standard Prod Time'"
                >
                  STD Prod T.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedStandardProdTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Operators Column -->
              <ng-container matColumnDef="operator">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Operator'"
                >
                  OPR
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="" *ngIf="element.section === 'SMT'">
                    {{ element.OPR !== "-" ? 1 : "-" }}
                  </span>
                  <span class="" *ngIf="element.section !== 'SMT'">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedOperators
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- StandardTime Column -->
              <ng-container matColumnDef="standardTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Standard Time'"
                >
                  STD T.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedStandardTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- CycleTime Column -->
              <ng-container matColumnDef="cycleTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Cycle Time(IE)'"
                >
                  CT(IE)
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  matTooltipPosition="below"
                  [matTooltip]="element.cycleTime + 's'"
                  class="width_90 fs_16"
                >
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedCycleTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Changeover Column -->
              <ng-container matColumnDef="changeover">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Change-over'"
                >
                  CHG-OV
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedChangeOver
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Changeover(IE) Column -->
              <ng-container matColumnDef="changeover_ie">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Change-over(IE)'"
                >
                  CHG-OV(IE)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedChangeOverIE
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Machine Down Time -->
              <ng-container matColumnDef="machineDownTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Machine Down Time'"
                >
                  M. Down T.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(
                        element.formattedMachineDownTime
                      )
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Rest Time Column -->
              <ng-container matColumnDef="restTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Rest Time'"
                >
                  Rest T.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  {{
                    this.dataFormatSvc.checkValDash(element.formattedRestTime)
                  }}
                </mat-cell>
              </ng-container>

              <!-- Downtime Column -->
              <ng-container matColumnDef="downTime">
                <mat-header-cell
                  *matHeaderCellDef
                  disableClear="true"
                  start="desc"
                  class="sec_title_bg width_90 fs_16"
                  matTooltipPosition="below"
                  [matTooltip]="'Down Time'"
                >
                  Down T.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="width_90 fs_16">
                  <span class="">
                    {{
                      this.dataFormatSvc.checkValDash(element.formattedDownTime)
                    }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- 第二層 table   Start ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->
              <ng-container matColumnDef="expandedDetail">
                <mat-cell
                  *matCellDef="let row"
                  class="innerTable"
                  [@detailExpand]="expandHandler(row)"
                >
                  <mat-table
                    [dataSource]="row.child"
                    multiTemplateDataRows
                    matSort
                    #innerTables
                    #innerSort="matSort"
                    matSortActive=""
                    matSortDirection=""
                    style="width: 100%"
                  >
                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: none; width: 28px"
                      >
                        <!-- action -->
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: none; width: 28px"
                      ></mat-cell>
                    </ng-container>

                    <!-- Section Column -->
                    <ng-container matColumnDef="section">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="fs_22"
                        style="flex: none; width: 140px"
                        matTooltipPosition="below"
                        [matTooltip]="'Section'"
                      >
                        SEC
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: none; width: 140px"
                      >
                        <div
                          *ngIf="
                            dataFormatSvc.shouldShowLink(element.section) &&
                            dataFormatSvc.isStatusWaiting(
                              element.status,
                              element.section
                            )
                          "
                        >
                          <a
                            [routerLink]="[
                              dataFormatSvc.getSectionLink(element.section)
                            ]"
                            [queryParams]="
                              dataFormatSvc.getSectionParams(element)
                            "
                            class="card_link fs_22"
                            target="_blank"
                          >
                            {{
                              this.dataFormatSvc.checkValDash(element.section)
                            }}
                          </a>
                        </div>

                        <div
                          *ngIf="
                            !dataFormatSvc.shouldShowLink(element.section) ||
                            !dataFormatSvc.isStatusWaiting(
                              element.status,
                              element.section
                            )
                          "
                        >
                          {{ this.dataFormatSvc.checkValDash(element.section) }}
                        </div>
                      </mat-cell>
                    </ng-container>

                    <!-- line Column -->
                    <ng-container matColumnDef="line">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="fs_22"
                        style="flex: none; width: 225px"
                      >
                        Line
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="fs_22"
                        style="flex: none; width: 225px"
                      >
                        {{ this.dataFormatSvc.checkValDash(element.line) }}
                      </mat-cell>
                    </ng-container>

                    <!-- Side Column -->
                    <ng-container matColumnDef="side">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="fs_22"
                        style="flex: none; width: 121px"
                      >
                        Side
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="fs_22"
                        style="flex: none; width: 121px"
                      >
                        {{ this.dataFormatSvc.checkValDash(element.side) }}
                      </mat-cell>
                    </ng-container>

                    <!-- Mo Qty Column -->
                    <ng-container matColumnDef="targetOutput">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="fs_22"
                        style="flex: none; width: 80px"
                        matTooltipPosition="below"
                        [matTooltip]="'MO Qty'"
                      >
                        MO
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="fs_22"
                        style="flex: none; width: 80px"
                      >
                        {{
                          this.dataFormatSvc.checkValDash(
                            element.formattedTargetOutput
                          )
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="fs_22"
                        style="flex: none; width: 210px"
                      >
                        Status
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="fs_22"
                        style="flex: none; width: 210px; color: #fff"
                      >
                        <span
                          class="status_color"
                          [ngClass]="moStatusBgColor(element.status)"
                        >
                          {{ dataFormatSvc.checkValDash(element.status) }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Start Time Column -->
                    <ng-container matColumnDef="startTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_120 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Start Time'"
                      >
                        Start T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_120 fs_18"
                      >
                        {{
                          this.dataFormatSvc.checkValDash(
                            element.formattedStartTime
                          )
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- End Time Column -->
                    <ng-container matColumnDef="endTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_120 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'End Time'"
                      >
                        End T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_120 fs_18"
                      >
                        {{
                          this.dataFormatSvc.checkValDash(
                            element.formattedEndTime
                          )
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- Input Qty Column -->
                    <ng-container matColumnDef="input">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Input Qty'"
                      >
                        INP
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_18"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(element.formattedInput)
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Output Qty Column -->
                    <ng-container matColumnDef="output">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Output Qty'"
                      >
                        OUT
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_18"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(element.formattedOutput)
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Achieve Rate Column -->
                    <ng-container matColumnDef="achieveRate">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Achieve Rate'"
                      >
                        ACHV R.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element; let i = dataIndex"
                        class="width_90 fs_18"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedAchieveRate
                            )
                          }}
                          %
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Output Yield Column -->
                    <ng-container matColumnDef="outputYield">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Output Yield'"
                      >
                        OUT YLD
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element; let i = dataIndex"
                        class="width_90 fs_18"
                      >
                        <span>
                          {{
                            dataFormatSvc.checkValDash(
                              element.formattedOutputYield
                            )
                          }}
                          %
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- efficiency Column -->
                    <ng-container matColumnDef="efficiency">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_18"
                        matTooltipPosition="below"
                        [matTooltip]="'Efficiency'"
                      >
                        EFF
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_18"
                      >
                        <span
                          class=""
                          (click)="onClickEfficiency(element)"
                          [title]="element.efficiency"
                        >
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedEfficiency
                            )
                          }}%
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Act Production Time Column -->
                    <ng-container matColumnDef="actProductionTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Act Prod Time'"
                      >
                        Act Prod T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        matTooltipPosition="below"
                        [matTooltip]="
                          'standardProdTime : ' + element.standardProdTime + 's'
                        "
                        class="width_90 fs_16"
                      >
                        <span
                          class=""
                          [ngClass]="{
                            c_red: actProductionTimeFontColor(element)
                          }"
                        >
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedActProductionTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Standard Production Time -->
                    <ng-container matColumnDef="standardProdTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Standard Prod Time'"
                      >
                        STD Prod T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedStandardProdTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Operators Column -->
                    <ng-container matColumnDef="operator">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Operator'"
                      >
                        OPR
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="" *ngIf="element.section === 'SMT'">
                          <!-- {{ element.OPR !== "-" ? 1 : "-" }} -->
                          1
                        </span>
                        <span
                          class=""
                          *ngIf="element.section !== 'SMT'"
                          [style.color]="
                            element?.isOPRMes ? '#ff3a3a' : '#bababa'
                          "
                        >
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedOperators
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- StandardTime Column -->
                    <ng-container matColumnDef="standardTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Standard Time'"
                      >
                        STD T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedStandardTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- CycleTime Column -->
                    <ng-container matColumnDef="cycleTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Cycle Time(IE)'"
                      >
                        CT(IE)
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        matTooltipPosition="below"
                        [matTooltip]="element.cycleTime + 's'"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedCycleTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Changeover Column -->
                    <ng-container matColumnDef="changeover">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Change-over'"
                      >
                        CHG-OV
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedChangeOver
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Changeover(IE) Column -->
                    <ng-container matColumnDef="changeover_ie">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Change-over(IE)'"
                      >
                        CHG-OV(IE)
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedChangeOverIE
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Machine Down Time -->
                    <ng-container matColumnDef="machineDownTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Machine Down Time'"
                      >
                        M. Down T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedMachineDownTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <!-- Rest Time Column -->
                    <ng-container matColumnDef="restTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Rest Time'"
                      >
                        Rest T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        {{
                          this.dataFormatSvc.checkValDash(
                            element.formattedRestTime
                          )
                        }}
                      </mat-cell>
                    </ng-container>

                    <!-- Downtime Column -->
                    <ng-container matColumnDef="downTime">
                      <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        disableClear="true"
                        start="desc"
                        class="width_90 fs_16"
                        matTooltipPosition="below"
                        [matTooltip]="'Down Time'"
                      >
                        Down T.
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        class="width_90 fs_16"
                      >
                        <span class="">
                          {{
                            this.dataFormatSvc.checkValDash(
                              element.formattedDownTime
                            )
                          }}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="innerDisplayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: innerDisplayedColumns"
                      matRipple
                      class="element-row"
                    >
                    </mat-row>
                  </mat-table>
                </mat-cell>
              </ng-container>
              <!-- 第二層 table   END ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊ -->

              <!-- action "^" -->
              <ng-container matColumnDef="action">
                <mat-header-cell
                  *matHeaderCellDef
                  style="flex: none; width: 28px; background-color: #ebebeb"
                >
                  <!-- action -->
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  style="flex: none; width: 28px"
                >
                  <!-- 判斷有沒有 children -->
                  <ng-container *ngIf="element.child.length !== 0">
                    <button
                      style="display: flex"
                      [@iconExpand]="iconSwitch(element)"
                    >
                      <mat-icon>
                        <img
                          src="/assets/img/smart_factory/Arrow_right.svg"
                          alt=""
                        />
                      </mat-icon>
                    </button>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <!-- 表頭 sticky: true => fix ".mat-header-row" -->
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
                matRipple
                class="element-row"
                (click)="rowClickSwitchIsExpanded(row)"
              >
              </mat-row>
              <mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                style="overflow: hidden"
                class="example-detail-row"
              >
              </mat-row>
            </mat-table>
          </div>
        </div>

        <!-- page navigation-->
        <mat-paginator
          class="paginator"
          #MatPaginator
          [showFirstLastButtons]="true"
          [length]="isTotalPageNum"
          [pageIndex]="queryObj.offset - 1"
          [pageSize]="12"
          (page)="onPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
