import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  loading = false;

  constructor() {
    // console.log('SpinnerService isLoading :', this.isLoading);
  }

  // refreshLoading = new ReplaySubject<boolean>();
  private refreshLoading = new BehaviorSubject<boolean>(false);
  refreshLoading$ = this.refreshLoading.asObservable();

  showeLoad() {
    this.refreshLoading.next(true);
  }

  hideLoad() {
    this.refreshLoading.next(false);
  }


  setRefreshData(isRefresh: boolean) {
    this.refreshLoading.next(isRefresh);
  }


  // isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());

  // /**
  //  * 如果有取得token，表示使用者有登入系統
  //  * @returns {boolean}
  //  */
  // private hasToken(): boolean {
  //   return !!localStorage.getItem('token');
  // }

  // /**
  // *  登入使用者，並通知所有訂閱者
  // */
  // login(): void {
  //   localStorage.setItem('token', 'JWT');
  //   this.isLoginSubject.next(true);
  // }

  // /**
  // * 登出使用者，並通知所有訂閱者
  // */
  // logout(): void {
  //   localStorage.removeItem('token');
  //   this.isLoginSubject.next(false);
  // }

  // /**
  // *
  // * @returns {Observable<T>}
  // */
  // isLoggedIn(): Observable<boolean> {
  //   return this.isLoginSubject.asObservable();
  // }


}
