import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds_to_hhmmss'
})
export class SecondsToHhmmssPipe implements PipeTransform {

  // This Pipe it for Seconds To hhmm

  transform(value: number) {
    if (value === 0) { return value + ' min.'; }
    if (value !== 0) {
      const seconds = Number(value);
      const hh = (seconds / 3600) >= 1 ? Math.floor(seconds / 3600) : 0;
      const mm = ((seconds - (hh * 3600)) / 60) >= 1 ? Math.floor((seconds - (hh * 3600)) / 60) : 0;
      const getCycleTime = hh + ' hr. ' + mm + ' min.';
      return getCycleTime;
    }
  }

}
