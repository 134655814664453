<div class="quality_overview">
  <div class="title_group">
    <h1 class="quality_overview_title">
      <!-- <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/menu.svg"
        alt="menu"
      /> -->
      Quality Overview
    </h1>

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <!-- Quality Overview -->
  <div class="container_box">
    <div class="table_box">
      <div class="table_group">
        <!-- search group-->
        <div class="search_group">
          <div class="search">
            <img
              class="search_icon"
              src="../../assets/img/smart_factory/bi_search.svg"
              alt="search-icon"
            />
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
              #input
            />
          </div>
        </div>

        <div class="table_scroll_box">
          <!-- 第一層 Table -->
          <div class="table_container mat-elevation-z8">
            <!-- Table 吃 DataSource資料 -->
            <mat-table
              #table
              [dataSource]="dataSource"
              multiTemplateDataRows
              matSort
              #sort="matSort"
              matSortActive="startTime"
              matSortDirection="desc"
            >
              <!-- Customer Column -->
              <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Customer
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.customer }}
                </mat-cell>
              </ng-container>

              <!-- Product Column -->
              <ng-container matColumnDef="product">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-23percent'"
                >
                  Part No.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="'mat-cell-23percent'"
                >
                  {{ element.product }}
                </mat-cell>
              </ng-container>

              <!-- MONo Column -->
              <ng-container matColumnDef="order">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-19percent'"
                >
                  MO No.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="'mat-cell-19percent'"
                >
                  <a
                    [routerLink]="element.order"
                    (click)="getMoItem(element)"
                    [ngClass]="'text_blue'"
                  >
                    {{ element.order }}
                  </a>
                </mat-cell>
              </ng-container>

              <!-- StartTime Column -->
              <ng-container matColumnDef="startTime">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-22percent'"
                >
                  Start time
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.startTime }}
                </mat-cell>
              </ng-container>

              <!-- Input Qty. Column -->
              <ng-container matColumnDef="inputQuantity">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-9percent'"
                >
                  Input Qty.
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="'mat-cell-9percent'"
                >
                  <span class="cell_text_right">
                    {{ element.inputQuantity | number }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Defect Qty. Column -->
              <ng-container matColumnDef="defectQuantity">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-9percent'"
                >
                  Defect Qty.
                  <!-- Fail Qty. -->
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="'mat-cell-9percent'"
                >
                  <span class="cell_text_right">
                    {{ element.defectQuantity | number }}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Rolled Throughput Yield => RTY Column -->
              <ng-container matColumnDef="yieldRate">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-19percent mat-cell-flex-center'"
                >
                  RTY
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="'mat-cell-19percent'"
                >
                  <span class="cell_text_right">
                    {{ element.yieldRate }}
                    %
                  </span>
                </mat-cell>
              </ng-container>

              <!-- Lowest Yield Column -->
              <ng-container matColumnDef="criticalStation">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="'mat-cell-19percent'"
                >
                  Lowest Yield
                </mat-header-cell>
                <!-- 加入 matTooltipPosition + matTooltip + matTooltipClass -->
                <!-- style ::ng-deep .mat-tooltip -->
                <!-- element.isCriticalStationArr.join('\n') 陣列插入換行 -->
                <mat-cell
                  *matCellDef="let element"
                  matTooltipPosition="below"
                  matTooltip="{{
                    element.isCriticalStationArr.length > 1
                      ? element.isCriticalStationArr.join('\n')
                      : ''
                  }}"
                  [matTooltipClass]="'mat-tooltip-class-here'"
                  [ngClass]="'mat-cell-19percent'"
                >
                  <p class="lowest_yield">
                    {{
                      element.criticalStation === undefined
                        ? "-"
                        : element.criticalStation
                    }}
                    <span
                      *ngIf="
                        element.isCriticalStationArr.length > 1 &&
                        element.isCriticalStationArr !== '-'
                      "
                      class="yield_tip"
                      >*</span
                    >
                  </p>
                </mat-cell>
              </ng-container>

              <!-- 表頭 sticky: true => fix ".mat-header-row" -->
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
                matRipple
                class="element-row"
              >
              </mat-row>
            </mat-table>
          </div>

          <!-- page navigation-->
          <mat-paginator
            class="paginator"
            showFirstLastButtons
            #MatPaginator
            [length]="dataSource"
            [pageIndex]="0"
            [pageSize]="13"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="defect_box">
      <div class="defect_box_title">Defect Ranking</div>

      <div class="select_tag model_select">
        <label>Model</label>
        <select (change)="getModelName($event.target.value)">
          <option selected="selected" disable>{{ isFirstModel }}</option>
          <!-- <option value="" disable>--Model--</option> -->
          <option *ngFor="let item of rankingModelNameArr">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="select_tag station_select">
        <label>Station</label>
        <select
          [(ngModel)]="selectValueChange"
          (change)="getStationName($event.target.value)"
        >
          <option *ngFor="let item of rankingStationNameArr">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="defect_data_list">
        <div class="scroll_box">
          <div
            class="defect_data_group"
            *ngFor="let item of rankingListData; let i = index"
          >
            <p class="list_number">{{ i + 1 }}</p>
            <div class="defect_cause_group">
              <p class="defect_data_cause">{{ item.testStation }}</p>
              <!-- <p class="defect_data_occurrence">
                Count : {{ +item.occurrence | number }}
              </p> -->
            </div>

            <div class="number_group">
              <p class="defect_data_percent">
                {{ +item.percent | number: "0.2-2" }}
                <span class="percent">%</span>
              </p>
              <p class="defect_data_occurrence">
                ( {{ +item.occurrence | number }} )
              </p>
            </div>
            <!-- <p class="defect_data_cause">{{ item.testStation }}</p>
        <p class="defect_data_occurrence">
          Count : {{ +item.occurrence | number }}
        </p> -->
            <div class="red_bar" [style]="'width:' + +item.percent + '%'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
