import { Component, Input, OnChanges } from '@angular/core';
import { ProgressBarColor } from 'src/design/palette';

// 未作：bar尚無法正常顯示於 new-repair-management.component.html

export interface ProgressBar {
  color?: ProgressBarColor; // set default
  value: number; // 0-100
  height?: number | string; // the bar height, default 64px
}

@Component({
  selector: 'app-custom-component-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  bgColor = ProgressBarColor.GREY;
  @Input() color: ProgressBar['color'];
  // may add '%' sign or set value: string
  @Input() value: ProgressBar['value'];
  @Input() height: ProgressBar['height'];

  ngOnChanges() {
    const defaultColor = ProgressBarColor.BLUE;
    this.color = ProgressBarColor[(this.color ?? '').toUpperCase()] ?? defaultColor;
  }
}
