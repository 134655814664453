<div class="container-fluid equipment_alarm">
  <!-- BY Line ***************************** -->
  <div class="by_line">
    <!-- Top Menu side -->
    <div class="title_group">
      <div class="title_box">
        <!-- Title -->
        <div class="title_box">
          <h1
            class="wip_overview_title"
            (click)="changeSectionName = !changeSectionName"
          >
            {{ apiParamsObj.sectionName }} Equipment Alarm by {{ byType }}
            <div class="circle">
              <div class="triangle"></div>
            </div>
            <div class="choice_title" *ngIf="changeSectionName">
              <ng-container *ngFor="let item of sectionNameArr">
                <p
                  class="choice_text"
                  *ngIf="item.isActive === false"
                  (click)="switchSectionTitle(item.sectionName)"
                >
                  {{ item.sectionName }}
                </p>
              </ng-container>
            </div>
          </h1>
        </div>

        <!-- button group -->
        <div class="button_box">
          <!-- Date Range -->
          <div class="b_group mr-4" *ngIf="byType === 'Line'">
            <div
              class="button b_radius_left"
              [ngClass]="{ button_active: btnDateSetActiveObj[0].active }"
              (click)="
                switchDateRangeBtn('Day', 'btnDateSetActiveObj');
                switchDateRangeTime('Day')
              "
            >
              Day
            </div>
            <div
              class="button"
              [ngClass]="{ button_active: btnDateSetActiveObj[1].active }"
              (click)="
                switchDateRangeBtn('Week', 'btnDateSetActiveObj');
                switchDateRangeTime('Week')
              "
            >
              Week
            </div>
            <div
              class="button b_radius_right"
              [ngClass]="{ button_active: btnDateSetActiveObj[2].active }"
              (click)="
                switchDateRangeBtn('Month', 'btnDateSetActiveObj');
                switchDateRangeTime('Month')
              "
            >
              Month
            </div>
          </div>

          <!-- 切換Type MO/Line -->
          <!-- <div class="b_group">
            <div
              class="button b_radius_left"
              [ngClass]="{ button_active: btnTypeActiveObj[0].active }"
              (click)="switchDateRangeBtn('Mo', 'btnTypeActiveObj')"
              [routerLink]="['/smartFactory/web/equip-alarm']"
              [queryParams]="{
                type: 'mo'
              }"
              style="pointer-events: none"
            >
              By MO
            </div>
            <div
              class="button b_radius_right"
              [ngClass]="{ button_active: btnTypeActiveObj[1].active }"
              (click)="switchDateRangeBtn('Line', 'btnTypeActiveObj')"
              [routerLink]="['/smartFactory/web/equip-alarm']"
              [queryParams]="{
                type: 'line'
              }"
            >
              By Line
            </div>
          </div> -->
        </div>

        <!-- time -->
        <div class="time">
          <!-- <p>10:23 <span>AM</span></p> -->
          <div class="date_group">
            <p class="small_date">
              {{ this.clock.isRealTimeClock | date: "yyyy/MM/dd" }}
            </p>
            <p>{{ this.clock.isRealTimeClock | date: "HH:mm:ss a" }}</p>
          </div>
          <img
            class="menu-bt"
            routerLink="/menu"
            src="/assets/img/btn_menu.svg"
            alt="menu"
          />
        </div>
      </div>

      <!-- Line & Time-Range group -->
      <div class="line_box" *ngIf="byType === 'Line'">
        <!-- 下拉選單 Line -->
        <div class="select_tag">
          <label>{{ byType }}</label>
          <select (change)="switchSelectOptionName($event.target.value)">
            <option
              *ngFor="let item of allOptionNameArr"
              [selected]="item === apiParamsObj.selectOptionName"
            >
              {{ item }}
            </option>
          </select>
        </div>

        <!-- Computation between Time range -->
        <div class="time_range">
          Computation between :
          {{ apiParamsObj.startTime | date: "yyyy/M/dd HH:mm" }} ~
          {{ apiParamsObj.endTime | date: "yyyy/M/dd HH:mm" }}
        </div>
      </div>
    </div>

    <!-- Content Box -->
    <div class="content row">
      <!-- latest Alarm & Down box -->
      <div class="col-3 left_box">
        <!-- latest Alarm info -->
        <div class="info_box">
          <p class="title">Latest Alarm Info</p>

          <div class="text_group">
            <p class="key">Equipment Name</p>
            <p class="value">
              {{
                dataFormatSvc.checkValDash(equipAlarmDataCompose.latestAlarm)
              }}
            </p>
          </div>

          <div class="text_group">
            <p class="key">Alarm Code</p>
            <p class="value">
              {{ dataFormatSvc.checkValDash(equipAlarmDataCompose.alarmCode) }}
            </p>
          </div>

          <div class="text_group">
            <p class="key">Latest Alarm Time</p>
            <p class="value">
              {{
                dataFormatSvc.checkValDash(
                  equipAlarmDataCompose.latestAlarmTime
                )
              }}
            </p>
          </div>

          <div class="text_group">
            <p class="key">Latest Alarm Duration</p>
            <p class="value">
              {{
                dataFormatSvc.checkValDash(equipAlarmDataCompose.alarmDuration)
              }}
            </p>
          </div>
        </div>

        <!-- Down box - pie chart -->
        <div class="down_time_box">
          <p class="title">Down Time</p>

          <!-- pie chart -->
          <div class="pie_chart" *ngFor="let item of pieChartDataArr">
            <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
              No Data
            </div>
            <canvas
              baseChart
              [datasets]="item.pieChartData"
              [labels]="item.pieChartLabels"
              [chartType]="item.pieChartType"
              [options]="item.pieChartOptions"
              [colors]="item.donutColors"
            >
            </canvas>
          </div>

          <!-- Down Time info -->
          <div class="pie_info">
            <div class="info_group">
              <p class="name"></p>
              <p class="mins">Mins</p>
              <p class="percent">%</p>
            </div>

            <div
              class="info_group"
              *ngFor="let item of equipAlarmDataCompose.pieChartDownTimeInfo"
            >
              <p class="name">
                <span
                  class="name_color"
                  [style]="'background:' + item.color"
                ></span>
                {{ dataFormatSvc.checkValDash(item.name) }}
              </p>
              <p class="mins">
                {{ dataFormatSvc.checkValDash(item.downtime) }}
              </p>
              <p class="percent">
                {{ dataFormatSvc.checkValDash(item.percent) }}
              </p>
            </div>

            <!-- <div class="info_group">
              <p class="name">
                <span class="name_color"></span>
                CM2070
              </p>
              <p class="mins">13.63</p>
              <p class="percent">42.64</p>
            </div>

            <div class="info_group">
              <p class="name">
                <span class="name_color"></span>
                CM2070
              </p>
              <p class="mins">13.63</p>
              <p class="percent">42.64</p>
            </div>

            <div class="info_group">
              <p class="name">
                <span class="name_color"></span>
                CM2070
              </p>
              <p class="mins">13.63</p>
              <p class="percent">42.64</p>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 百分比 ＆ Error Record & Error Code Ranking -->
      <div class="col-9 right_box">
        <!-- 百分比 Val -->
        <div class="row val_gorup">
          <!-- Line Running / Mo Utilization -->
          <div class="val_box">
            <p class="val_title">Line Running %</p>
            <p class="val_value">
              {{
                dataFormatSvc.checkValDash(equipAlarmDataCompose?.lineRunning)
              }}
              %
            </p>
            <p class="val_time">
              ( {{ equipAlarmDataCompose?.lineRunningFormatted }} )
            </p>
          </div>

          <!-- Line Idle / Mo Idle -->
          <div class="val_box">
            <p class="val_title">Line Idle %</p>
            <p class="val_value">
              {{ dataFormatSvc.checkValDash(equipAlarmDataCompose?.lineIdle) }}
              %
            </p>
            <p class="val_time">
              ( {{ equipAlarmDataCompose?.lineIdleFormatted }} )
            </p>
          </div>

          <!-- Line Downtime / Mo Downtime -->
          <div class="val_box">
            <p class="val_title">Line Downtime %</p>
            <p class="val_value">
              {{
                dataFormatSvc.checkValDash(equipAlarmDataCompose?.lineDownTime)
              }}
              %
            </p>
            <p class="val_time">
              ( {{ equipAlarmDataCompose?.lineDownTimeFormatted }} )
            </p>
          </div>
        </div>

        <!--  -->
        <div class="row flex_box">
          <!-- Error box -->
          <div class="error_group">
            <!-- Error Record Bar Chart -->
            <div class="chart_box">
              <p class="title">Error Record</p>

              <!-- <p *ngIf="isApiResEmptyArrShowNoData">No data</p> -->

              <div class="bar_chart" *ngFor="let item of barChartDataArr">
                <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
                  No Data
                </div>
                <canvas
                  baseChart
                  height="280"
                  [datasets]="item.barChartData"
                  [labels]="item.barChartLabels"
                  [chartType]="item.barChartType"
                  [options]="item.barChartOptions"
                  [legend]="item.barChartLegend"
                  [colors]="item.donutColors"
                >
                </canvas>
              </div>
            </div>

            <!-- Error Table -->
            <div class="table_box">
              <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
                No Data
              </div>
              <table class="table table-striped table-dark">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th
                      scope="col"
                      *ngFor="let item of equipAlarmDataCompose.equipInfo"
                      style="text-align: center"
                    >
                      <!-- 設備燈號 => 1) Running - 綠色 2) Alarm - 紅色 3) Idle - 黃色 -->
                      <span
                        class="equip_light"
                        [ngClass]="checkEquipLightColor(item.status)"
                      ></span>
                      {{ item.equip }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <ng-container *ngIf="apiParamsObj.type === 'Line'">
                    <tr *ngFor="let item of typeLineTableDataArr">
                      <td style="text-align: center">
                        {{ item.name }}
                      </td>

                      <td
                        style="text-align: center"
                        *ngFor="let itemChild of item.equipValArr"
                      >
                        {{ itemChild === "-" ? "-" : (itemChild | number) }}
                        <span *ngIf="item.name !== 'Error record'">%</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Error Code Ranking -->
          <div class="error_code_tanking">
            <div class="defect_box_title">Error Code Ranking</div>

            <div class="select_tag model_select">
              <label>Equip</label>
              <select (change)="switchEquipOptionName($event.target.value)">
                <option
                  *ngFor="let item of rankingModelNameArray; let idx = index"
                  [selected]="idx === 0"
                  [value]="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>

            <div class="root_cause_box">
              <div class="defect_data_list">
                <div
                  class="defect_data_group"
                  *ngFor="let item of rankingListData; let i = index"
                >
                  <p class="list_number">{{ i + 1 }}</p>
                  <div class="defect_cause_group">
                    <p class="defect_data_cause">
                      {{ dataFormatSvc.checkValDash(item.errCode) }}
                    </p>
                  </div>
                  <div class="defect_num_group">
                    <p class="defect_data_percent">
                      {{ dataFormatSvc.checkValDash(item.percent) }}
                      <span>%</span>
                    </p>
                    <p class="defect_data_occurrence">
                      ({{ dataFormatSvc.checkValDash(item.count) }})
                    </p>
                  </div>
                  <div
                    class="red_bar"
                    [style]="'width:' + [item.percent] + '%'"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BY Mo ***************************** -->
  <div class="by_mo" *ngIf="byType === 'Mo'">
    <div class="fixed">Under Construction...</div>
  </div>

  <!-- Auto Refresh -->
  <div class="refresh">
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
