import { MainBoardComponent } from './main_board/main-board/main-board.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnvironmentComponent } from '../app/environment/environment.component';
import { ElectricityComponent } from '../app/electricity/electricity.component';
import { LoginComponent } from '../app/login/login.component';
import { MenuComponent } from '../app/menu/menu.component';
import { ProductionOverviewComponent } from './production-overview/production-overview/production-overview.component';
import { QualityOverviewComponent } from './quality-overview/quality-overview.component';
import { MoQualityComponent } from './mo-quality/mo-quality.component';
import { CctvComponent } from './cctv/cctv.component';
import { ProductionHoursComponent } from './production-hours/production-hours.component';
import { DefectiveProductComponent } from './defective-product/defective-product.component';
import { WipOverviewComponent } from './wip-overview/wip-overview.component';
import { NewMainBoardComponent } from './new-main-board/new-main-board.component';
import { EquipmentEfficiencyOverviewComponent } from './equipment-efficiency-overview/equipment-efficiency-overview.component';
import { SortTableComponent } from './sort-table/sort-table.component';
import { EquipEfficiencyComponent } from './equip-efficiency/equip-efficiency.component';
import { NewProductionHoursComponent } from './new-production-hours/new-production-hours.component';
import { NewProductionOverviewComponent } from './new-production-overview/new-production-overview.component';
import { CloseOrderOverviewComponent } from './close-order-overview/close-order-overview.component';
import { EquipmentAlarmComponent } from './equipment-alarm/equipment-alarm.component';
import { NewRepairManagementComponent } from './new-repair-management/new-repair-management.component';
import { NewQualityOverviewComponent } from './new-quality-overview/new-quality-overview.component';
import { NewMoOverviewComponent } from './new-mo-overview/new-mo-overview.component';
import { NewWipOverviewComponent } from './new-wip-overview/new-wip-overview.component';
import { AteEquipEfficiencyComponent } from './ate-equip-efficiency/ate-equip-efficiency.component';
import { Phase3ProductionOverviewComponent } from './phase3-production-overview/phase3-production-overview.component';
import { Phase3EquipAlarmComponent } from './phase3-equip-alarm/phase3-equip-alarm.component';
import { Phase3RepairManagementComponent } from './phase3-repair-management/phase3-repair-management.component';
import { Phase3QualityOverviewComponent } from './phase3-quality-overview/phase3-quality-overview.component';
import { Phase3MoOverviewComponent } from './phase3-mo-overview/phase3-mo-overview.component';
import { Phase3EquipmentEfficiencyOverviewComponent } from './phase3-equipment-efficiency-overview/phase3-equipment-efficiency-overview.component';
import { Phase3CloseOrderOverviewComponent } from './phase3-close-order-overview/phase3-close-order-overview.component';
import { Phase3MainBoardComponent } from './phase3-main-board/phase3-main-board.component';
import { Phase3WipOverviewComponent } from './phase3-wip-overview/phase3-wip-overview.component';
import { Phase3AteComponent } from './phase3-ate/phase3-ate.component';
import { Phase3DroComponent } from './phase3-dro/phase3-dro.component';
import { DropRateOverviewComponent } from './drop-rate-overview/drop-rate-overview.component';
import { Phase3EquipAlarmAllComponent } from './phase3-equip-alarm-all/phase3-equip-alarm-all.component';

import { path } from 'd3';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'smartFactory/web/menu' },
  {
    path: 'smartFactory/web',
    children: [
      { path: '', component: MenuComponent },
      { path: 'menu', component: MenuComponent, data: { title: 'Menu' } },
      { path: 'login', component: LoginComponent },
      { path: 'sortTable', component: SortTableComponent },
      { path: 'cctv', component: CctvComponent, data: { title: 'CCTV' } },
      { path: 'environment-dashboard', component: EnvironmentComponent, data: { title: 'Environment' } },
      { path: 'electricity-dashboard', component: ElectricityComponent, data: { title: 'Power Consumption' } },
      // Phase 2 ***************************************
      // { path: 'new-wip-overview', component: NewWipOverviewComponent, data: { title: 'WIP Overview' } }, // 途程管理
      // Phase 3 ***************************************
      { path: 'production-overview-management', component: Phase3ProductionOverviewComponent, data: { title: 'Production Overview' } },  // 生產管理
      { path: 'equip-alarm', component: Phase3EquipAlarmComponent, data: { title: 'Equipments Alarm' } },  // 設備異常 SMT / LOD
      { path: 'repair-management', component: Phase3RepairManagementComponent, data: { title: 'Defective Product Management' } },  // 維修管理
      { path: 'quality-overview', component: Phase3QualityOverviewComponent, data: { title: 'Quality Overview' } },  // 品質履歷
      { path: 'mo-overview', component: Phase3MoOverviewComponent, data: { title: 'Mo Overview' } },  // 品質履歷-內頁
      { path: 'equipment-efficiency-overview', component: Phase3EquipmentEfficiencyOverviewComponent, data: { title: 'Production Efficiency Overview' } },  // 設備效能總覽
      { path: 'close-order-overview', component: Phase3CloseOrderOverviewComponent, data: { title: 'MES Closed Orders Overview' } },  // 已結工單
      { path: 'main-board', component: Phase3MainBoardComponent, data: { title: 'Acbel Plant Overview' } },  // 主畫面
      { path: 'wip-overview', component: Phase3WipOverviewComponent, data: { title: 'MO Route Overview' } }, // 途程管理
      { path: 'ate-equip-efficiency', component: AteEquipEfficiencyComponent, data: { title: 'TEST Efficiency' } }, // TEST 設備效能
      { path: 'phase3-ate', component: Phase3AteComponent, data: { title: 'Equipments Alarm' } },  // 設備異常 ATE
      { path: 'phase3-drop', component: Phase3DroComponent, data: { title: 'SMT Drop Rate Overview' } },  // 關鍵參數看板
      { path: 'drop-rate-overview', component: DropRateOverviewComponent, data: { title: 'SMT Drop Rate Overview' } },  // 關鍵參數看板 New Layout
      { path: 'equip-alarm-all', component: Phase3EquipAlarmAllComponent, data: { title: 'Equipments Alarm' } },  // 設備異常 SMT / LOD
    ],
  },
  { path: '**', redirectTo: 'smartFactory/web/menu', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
