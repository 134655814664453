<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 32px;
  "
>
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <h1 class="mat-title color-silver" style="margin-bottom: 0">
      Defective Product Management
    </h1>
    <div style="display: flex; align-items: center">
      <h3 class="mat-subheading-2 color-silver" style="margin: 0 36px 0 0">
        {{ getCurrentTime() }}
      </h3>
      <a
        mat-icon-button
        color="primary"
        aria-label="direct to menu page"
        routerLink="/menu"
      >
        <mat-icon color="primary" style="height: 36px; width: 36px">
          <img
            src="/assets/img/btn_menu.svg"
            alt="menu"
            style="height: 100%; width: 100%"
          />
        </mat-icon>
      </a>
    </div>
  </div>

  <div style="margin-top: 20px; display: flex; align-items: center">
    <h4 class="mat-subheading-1 color-silver" style="margin: 0 16px 0 0">
      Category
    </h4>
    <app-custom-component-dropdown
      [disabled]="categories == null"
      [options]="categories"
      (optionChange)="onChange('category', $event)"
    ></app-custom-component-dropdown>
    <h4 class="mat-subheading-1 color-silver" style="margin: 0 16px 0 20px">
      Model
    </h4>
    <app-custom-component-dropdown
      [disabled]="models == null"
      [options]="models"
      (optionChange)="onChange('model', $event)"
    ></app-custom-component-dropdown>
  </div>

  <div class="flex-row" style="margin-top: 24px">
    <div class="left-col" style="display: flex; margin: 0 -12px">
      <div style="width: 25%">
        <mat-card class="kpi-info">
          <h4 class="mat-subheading-1 color-silver" style="margin-bottom: 0">
            Remaining WIP
          </h4>
          <h4 class="mat-subheading-1 color-silver">(Yesterday)</h4>
          <h2 class="mat-headline color-white">
            {{ defectiveMoObject?.remainingWipQty }}
          </h2>
        </mat-card>
      </div>
      <div style="width: 25%">
        <mat-card class="kpi-info">
          <h4 class="mat-subheading-1 color-silver" style="margin-bottom: 0">
            To Be Repaired
          </h4>
          <h4 class="mat-subheading-1 color-silver">(Today Input)</h4>
          <h2 class="mat-headline color-white">
            {{ defectiveMoObject?.toBeRepairedQty }}
          </h2>
        </mat-card>
      </div>
      <div style="width: 25%">
        <mat-card class="kpi-info">
          <h4 class="mat-subheading-1 color-silver" style="margin-bottom: 0">
            Complete
          </h4>
          <h4 class="mat-subheading-1 color-silver">(Today Output)</h4>
          <h2 class="mat-headline color-white">
            {{ defectiveMoObject?.complete }}
          </h2>
        </mat-card>
      </div>
      <div style="width: 25%">
        <mat-card class="kpi-info">
          <h4 class="mat-subheading-1 color-silver" style="line-height: 76px">
            WIP
          </h4>
          <h2 class="mat-headline color-white">
            {{ defectiveMoObject?.wipQty }}
          </h2>
        </mat-card>
      </div>
    </div>
    <div class="right-col">
      <mat-card class="stats-chart">
        <h3 class="mat-subheading-1 color-silver">WIP Statistic Chart</h3>
        <app-custom-component-bar-chart
          [data]="getBarChartData()"
          [yTicks]="7"
          [xLabel]="'(Days)'"
          [yLabel]="'(Qty)'"
        >
        </app-custom-component-bar-chart>
      </mat-card>
    </div>
  </div>

  <div
    class="flex-row"
    style="
      margin-top: 24px;
      margin-bottom: 5px;
      height: 100%;
      width: 100%;
      min-height: 590px;
    "
  >
    <div
      class="left-col"
      style="height: 100%; min-height: inherit; overflow: hidden"
    >
      <mat-card
        style="
          padding: 30px 32px;
          height: 100%;
          display: flex;
          flex-direction: column;
        "
      >
        <!-- TODO: reset value while changeing category/model -->
        <app-custom-component-text-input
          #textInput
          (valueChange)="moTable.onChangeValue($event)"
          [fullWidth]="true"
          [placeholder]="'Search'"
          [disabled]="moTable.loading"
        ></app-custom-component-text-input>
        <div style="overflow-y: auto; flex: 1; margin-bottom: 32px">
          <ng-container *ngIf="moTable.data.length > 0">
            <app-custom-component-table
              [outerTableColumns]="moTable.columns"
              [outerTableColumnsToDisplay]="moTable.columnsToDisplay"
              [outerTableHeaderSticky]="true"
              [innerTableColumns]="moDefectTable.columns"
              [innerTableColumnsToDisplay]="moDefectTable.columnsToDisplay"
              [outerTableDataSource]="moTable.data"
              [innerTableDataSource]="findMoUnits()"
              [getOuterTextColor]="getMoTableTextColor"
              [getInnerTextColor]="getMoDefectTableTextColor"
              (changeExpandedElement)="toggleElement($event)"
            >
            </app-custom-component-table>
          </ng-container>
          <ng-container *ngIf="moTable.data.length === 0">
            <h4
              class="mat-subheading-1 color-white"
              style="width: 100%; text-align: center; margin: 32px 0"
            >
              No data found.
            </h4>
          </ng-container>
        </div>
        <mat-paginator
          #moDefectTablePaginator
          *ngIf="moTable.data.length > 0"
          [showFirstLastButtons]="true"
          [length]="moTable.total"
          [pageIndex]="0"
          [pageSize]="moTable.pageSize"
          [disabled]="moTable.loading"
          (page)="moTable.onChangePage($event)"
        >
        </mat-paginator>
      </mat-card>
    </div>
    <div class="right-col">
      <mat-card
        style="
          padding: 30px;
          height: 100%;
          display: flex;
          flex-direction: column;
        "
      >
        <h3
          class="mat-subheading-1 color-silver"
          style="margin: 0 0 24px"
          [title]=""
        >
          Root Cause Ranking
        </h3>
        <mat-list style="flex-grow: 1; overflow-y: auto">
          <mat-list-item
            *ngFor="let item of rootCauseRanking?.ranking; let i = index"
            style="margin: 12px 0; height: initial"
          >
            <div style="display: flex; align-items: center; width: 100%">
              <p class="mat-body-1 color-silver" style="min-width: 44px">
                {{ i + 1 }}
              </p>
              <div
                style="position: relative; flex-grow: 1; cursor: pointer"
                (click)="onClickRootCause(item)"
              >
                <div
                  style="
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <app-custom-component-progress-bar
                    [color]="'BLUE'"
                    [height]="'100%'"
                    [value]="item.ratio"
                  ></app-custom-component-progress-bar>
                </div>
                <div
                  style="
                    padding: 6px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                  "
                >
                  <p class="mat-body-2 color-white">{{ item.label }}</p>
                  <div style="text-align: right">
                    <p class="mat-body-1 color-white">{{ item.ratio }}</p>
                    <p class="mat-caption color-white">({{ item.value }})</p>
                  </div>
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card>
    </div>
  </div>

  <!-- Auto Refresh -->
  <div class="refresh">
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
