import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { HttpClient, } from '@angular/common/http';
import { ApiService } from 'src/app/_services/apiSvc.service';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';


import { map } from 'rxjs/operators';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';



@Component({
  selector: 'app-defective-product',
  templateUrl: './defective-product.component.html',
  styleUrls: ['./defective-product.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})
export class DefectiveProductComponent implements OnInit, AfterViewInit, OnDestroy {
  upDateTimer;

  isRootFirstModelName;

  stayTimeHr;
  stayTimeDay;
  stayTimeMin;
  stayTimeSec;

  dataSource;
  filterValue = '';
  expandedElement: any = {
    isExpanded: false
  };
  saveElement: any;


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // 第一層標題
  displayedColumns: string[] = ['action', 'order', 'startTime', 'endTime', 'timeToRepair', 'defect_qty'];
  // displayedColumns: string[] = ['action', 'order', 'startTime', 'endTime', 'timeToRepair', 'defect_qty'];
  // 第二層標題
  innerDisplayedColumns: string[] = ['action', 'serialNumber', 'startTime', 'endTime', 'defectCode'];
  // innerDisplayedColumns: string[] = ['action', 'serialNumber', 'startTime', 'endTime', 'defectCode'];

  defactiveMoObeject = {
    moFinishedOutput: 12830,
    moInRepair: 212,
    moCycleTime: 4510,
    moCycleTimeUnit: '',
    moRepairToday: 283,
  };

  isRankingModelName = '';
  rankingModelNameArray = [];
  rankingListData = [
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: '',
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    },
    {
      dutyCode: null,
      occurrence: '0',
      percent: '0',
    }
  ];

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    private httpClient: HttpClient,
    public clock: RealTimeClockService,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;
  }

  ngOnInit(): void {

    this.getTableData();
    this.getModelStationData();

    // this.dataSource = new MatTableDataSource<any>(testData);

    // this.upDateTimer = setInterval(() => {
    //   this.getTableData();
    // }, 60000);

    this.upDateTimer = setInterval(() => {
      this.getTableData();
      this.getModelStationData();

      this.expendClick(this.expandedElement);

    }, 20000);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    }, 600);

  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
  }

  expendClick(element) {
    const oldelement = element;
    console.log('element:', oldelement);
    element.isExpanded = true;
    // this.saveElement = element;
    this.expandedElement = element;
  }

  // Search input filter
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  transtMoCycleTime(getTimeSecs) {
    if (getTimeSecs === null) {
      this.defactiveMoObeject.moCycleTime = 0;
      this.defactiveMoObeject.moCycleTimeUnit = 'sec';
      return;
    }

    // Got 秒 轉成 天時分秒
    const day = Math.floor(getTimeSecs / 86400);
    const hr = Math.floor((getTimeSecs % 86400) / 3600);
    const min = Math.floor(((getTimeSecs % 86400) % 3600) / 60);
    const sec = Math.floor(((getTimeSecs % 86400) % 3600) % 60);
    console.log('day:', day, 'hr:', hr, '//////', 'min:', min, '//////', 'sec:', sec);

    this.stayTimeDay = day;
    this.stayTimeHr = hr;
    this.stayTimeMin = min;
    this.stayTimeSec = sec;

    // const isHr = getTimeSecs >= 3600;
    // const isMin = 3600 > getTimeSecs && getTimeSecs >= 60;
    // const isSec = getTimeSecs < 60;

    // if (isHr) {
    //   // console.log('isHr:', isHr);
    //   const getHr = getTimeSecs / 3600;
    //   // console.log('getHr:', getHr);
    //   this.defactiveMoObeject.moCycleTime = getHr;
    //   this.defactiveMoObeject.moCycleTimeUnit = 'hr';
    //   return;
    // }
    // if (isMin) {
    //   // console.log('isMin:', isMin);
    //   const getMin = getTimeSecs / 60;
    //   // console.log('getMin:', getMin);
    //   this.defactiveMoObeject.moCycleTime = getMin;
    //   this.defactiveMoObeject.moCycleTimeUnit = 'min';
    //   return;
    // }
    // if (isSec) {
    //   // console.log('isSec:', isSec);
    //   const getSec = getTimeSecs;
    //   // console.log('getSec:', getSec);
    //   this.defactiveMoObeject.moCycleTime = getSec;
    //   this.defactiveMoObeject.moCycleTimeUnit = 'sec';
    //   return;
    // }
  }


  getModelName(value) {
    console.log('getModelName value:', value);
    this.isRankingModelName = value;

    this.getRankTopTenData(this.isRankingModelName);
  }

  // 組陣列
  getModelNameData(modelName) {
    this.rankingModelNameArray = [];
    modelName.map((item) => {
      this.rankingModelNameArray.push(item.model);
    });
    this.rankingModelNameArray.sort();
    this.isRootFirstModelName = this.rankingModelNameArray[0];
    this.rankingModelNameArray.shift();
    this.getModelName(this.isRootFirstModelName);
  }


  // null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }
  // 處理 TimeToRepair 秒 轉換成 時分
  transform(value: number) {
    if (value === 0) { return value + ' min.'; }
    if (value !== 0) {
      const seconds = Number(value);
      const hh = (seconds / 3600) >= 1 ? Math.floor(seconds / 3600) : 0;
      const mm = ((seconds - (hh * 3600)) / 60) >= 1 ? Math.floor((seconds - (hh * 3600)) / 60) : 0;
      const getCycleTime = hh + ' hr. ' + mm + ' min.';
      return getCycleTime;
    }
  }

  // api => MoObejectData & TableData
  getTableData() {
    this.apiSvc.getDefectiveTableData().pipe().subscribe((res) => {
      // console.log('getTableData res:', res);
      this.defactiveMoObeject.moFinishedOutput = res.finishedOutput;
      this.defactiveMoObeject.moInRepair = res.unitsInRepair;
      this.defactiveMoObeject.moCycleTime = res.totalRepairTime;
      this.defactiveMoObeject.moRepairToday = res.unitsRepairedToday;

      res.orders = this.nullToDash(res.orders);

      res.orders.map((item) => {
        // console.log('item:', item);
        // Pipe 資料處理
        if (item.startTime !== '-') {
          item.startTime = this.datePipe.transform(item.startTime, 'yyyy/M/dd HH:mm');
        }
        if (item.endTime !== '-') {
          item.endTime = this.datePipe.transform(item.endTime, 'yyyy/M/dd HH:mm');
        }
        if (item.duration !== '-') {
          item.duration = this.numberPipe.transform(item.duration / 86400, '0.1-1');
        }
        if (item.defect_qty !== '-') {
          item.defect_qty = this.numberPipe.transform(item.defect_qty);
        }
        // 處理 TimeToRepair 秒 轉換成 時分
        if (item.timeToRepair !== '-') {
          item.timeToRepair = this.transform(item.timeToRepair);
        }


        // item.units = this.nullToDash(item.units);
        item.units.map((x) => {
          x.defectCode = x.defectCode.toString();
          return x;
        });
        return item;
      });

      res.orders.sort((a, b) => {
        // return b.orderStartTime.localeCompare(a.orderStartTime);
        return (b.orderStartTime < a.orderStartTime) ? -1 : ((b.orderStartTime > a.orderStartTime) ? 1 : 0);
      });

      res.orders.map((item) => {
        // set item expanded status
        item.isExpanded = false;
        item.selfID = item.startTime;
        // sort time
        item.units.sort((a, b) => {
          return (b.startTime < a.startTime) ? -1 : ((b.startTime > a.startTime) ? 1 : 0);
        });
        return item;
      });


      console.log('rebuild getTableData res:', res);
      this.dataSource = new MatTableDataSource<any>(res.orders);
      this.ngAfterViewInit();
      // console.log('res.order:', res.orders);

      this.transtMoCycleTime(this.defactiveMoObeject.moCycleTime);
    });
  }

  // api => Model name Data
  getModelStationData() {
    this.apiSvc.getDefectiveModelData().pipe().subscribe((res) => {
      console.log('getModelStationData res:', res);
      res.sort((a, b) => { a.model.localeCompare(b.model); });
      res.unshift({ model: 'ALL' });
      this.getModelNameData(res);
    });

  }

  // api => Rankin top 10 Data
  getRankTopTenData(modelName) {
    this.apiSvc.getDefectiveRankTopTenData(modelName).pipe().subscribe((res) => {
      console.log('getRankTopTenData res:', res);
      res.map((item) => {
        // return item.percent = (+Math.round(item.percent * 100) / 100) * 100;
        return item.percent = +item.percent * 100;
      });
      this.rankingListData = res;
      this.isSvLoading.loading = false;
    });
  }

}

// 2020-11-18T02:32:44.000+00:00

// ['action', 'MONo', 'Start_Time', 'End_Time', 'timeToRepair', 'Defect_Qty']
// [' ', 'Unit', 'Start_Time', 'End_Time', 'Defect_Code']
const testData: any[] = [
  {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-18T02:32:44.000+00:00',
    timeToRepair: '451852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021311',
        Start_Time: '2020-11-19T02:52:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021355',
        Start_Time: '2020-11-19T02:40:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  },
  {
    MONo: 'DD3SJ04AL112',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '4852',
    Defect_Qty: '5',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021348',
        Start_Time: '2020/11/20 22:15',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020/11/30 15:52',
        Defect_Code: '001, 002, 003...'
      }
    ],
  },
  {
    MONo: 'DD3SJ04AL200',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '45182',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
    ],
  }, {
    MONo: 'DD3SJ04AL999',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '1852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  },
  {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '4852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  },
  {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '4852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '45852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '4512',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '45112',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '45852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '45852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '451852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '451852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  }, {
    MONo: 'DD3SJ04AL002',
    Start_Time: '2020-11-19T02:32:44.000+00:00',
    End_Time: '2020-11-19T02:32:44.000+00:00',
    timeToRepair: '451852',
    Defect_Qty: '3',
    children: [
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: null,
        Defect_Code: null
      },
      {
        Unit: 'pcba131820-20-25-021324',
        Start_Time: '2020-11-19T02:32:44.000+00:00',
        End_Time: '2020-11-19T02:32:44.000+00:00',
        Defect_Code: '001, 002, 003...'
      }
    ],
  },
];

const getModelNameData: any[] = [
  {
    model: 'VEJ003-FV0G-2',
    stations: [
      'functionTest'
    ]
  },
  {
    model: 'WLB001-M78G-1',
    stations: [
      'functionTest1'
    ]
  },
  {
    model: 'OTD019-260G',
    stations: [
      'functionTest3'
    ]
  },
  {
    model: 'GEJ001-00AG',
    stations: [
      'functionTest2',
      'functionTest1'
    ]
  },
  {
    model: 'WLB001-D7CG',
    stations: [
      'functionTest2'
    ]
  },
  {
    model: 'OTG037-030G',
    stations: [
      'functionTest1'
    ]
  }
];
