import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import numeral from 'numeral';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class DataFormatService {

  constructor(
    // pipe
    public datePipe: DatePipe,
    public numberPipe: DecimalPipe,
  ) { }

  // Chart Color group
  chartColorsArr = [
    '#E3B953', '#E1894C', '#86CEDE',
    '#89B074', '#D2574A', '#AC4DA5',
    '#3A78BB', '#42F84A', '#F14DF5',
    '#18a8FF',
  ];

  // check values
  checkValDash(value: null | undefined | string) {
    // console.log('value:', value);
    // if (value === undefined) { return '-'; }
    // if (value === '-') { return '-'; }
    return value ?? '-';
  }

  // Pipe Date
  pipeDate(day, str) {
    return this.datePipe.transform(day, str);
  }

  // Pipe number
  pipeNumber(num, str: string) {
    return this.numberPipe.transform(+num, str);
  }


  // 格式化時間
  secToDDhhmmss(value: number) {
    if (value === 0) { return value + 's'; }
    if (value !== 0) {
      const timeInSeconds = Number(value);
      let formatTime = '';

      const days = Math.floor((timeInSeconds / 86400));
      const hours = Math.floor((timeInSeconds / 3600 % 24));
      const minutes = Math.floor(timeInSeconds / 60 % 60);
      const seconds = Math.floor(timeInSeconds % 60);
      // console.log('hours:', hours, 'minutes:', minutes, 'seconds:', seconds);

      const showDay = timeInSeconds >= 86400;
      const showHour = timeInSeconds >= 3600;
      const showMin = 60 <= timeInSeconds && timeInSeconds < 3600;

      if (showDay) { return formatTime = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's'; }
      if (showHour) { return formatTime = hours + 'h ' + minutes + 'm ' + seconds + 's'; }
      if (showMin) { return formatTime = minutes + 'm ' + seconds + 's'; }
      return formatTime = seconds + 's';
    }
  }

  miliSecToDays(value: number | string, fixed: number = 2) {
    const timeInMiliSeconds = Number(value);
    if (!_.isFinite(timeInMiliSeconds)) { throw new TypeError('Not a finite number.'); }
    const days = numeral(timeInMiliSeconds).divide(1000).divide(86400).value();
    return days.toFixed(fixed);
  }

  // Date to Unix time
  dateToUnixTime(str) {
    return new Date(str).getTime();
  }

  // Unix to Date
  unixToDayTime(num) {
    return new Date(num);
  }

  // 陣列 null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }

  // Object 回傳 null to '-'
  objValueNulltoDash(obj) {
    Object.keys(obj).map((key) => {
      if (obj[key] === null) { return obj[key] = '-'; }
    });
    return obj;
  }

  // Search 欄位，正規Regex
  searchTextRegexCheck(str) {
    if (str === '') { return true; } // 欄位為空時
    const regex = new RegExp('^[0-9A-Za-z\s\/\-]+$');
    const result = regex.test(str);
    console.log('result:', result);
    return result;
  }

  // 檢查 Section 是否要 Link 其他頁面
  shouldShowLink(section) {
    if (section !== 'TEST') { return false; }
    return section === 'TEST';
  }

  // 檢查 Status 是否為 Waiting
  isStatusWaiting(status, section) {
    if (status === 'Waiting') { return false; }
    return section === 'TEST';
  }

  // 檢查 Section 取得 routerLink
  getSectionLink(section) {
    switch (section) {
      case 'TEST': return '/smartFactory/web/ate-equip-efficiency';
      default: return;
    }
  }

  // 檢查 Section 取得 queryParams
  getSectionParams(paramsObj) {
    console.log('>>>>>>>>>',paramsObj,'<<<<<<<<');
    switch (paramsObj.section) {
      case 'TEST':
        return {
          section: paramsObj.section,
          line: paramsObj.line,
          sn: paramsObj.sn,
          side: paramsObj.side
        };
      default: return;
    }
  }

  // 計算 list 內 from 與 to 的時間區間，回傳 duration
  getDuration({ from, to }: { from: string; to: string; } = { from: '', to: '' }) {
    if (!moment(from).isValid() || !moment(to).isValid()) { return null; }
    return numeral(0).add(moment(to).valueOf()).subtract(moment(from).valueOf()).divide(1000).value();
  }

  // 計算 list 內 duration 總和回傳
  getDurationTotal(list: { duration?: number; }[]) {
    return list.reduce((total, item) => total + item.duration, 0);
  }

  // Function Name 最後一位為數字且前一個字元不是'-'，在數字前加空白後再回傳
  AddSpaceBeforeFunctionNameNumber(functionName) {
    if (!functionName) { return '-'; }
    const isNumber = functionName.charAt(functionName.length - 1);
    const isDash = functionName.charAt(functionName.length - 2) === '-';
    if (_.isFinite(+isNumber) && !isDash) {
      const sliceName = functionName.slice(0, -1);
      return sliceName + ' ' + isNumber;
    }
    else {
      return functionName;
    }
  }
}
