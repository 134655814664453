import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// 設定 Docker 變數 => API URL
import { environment } from '../../environments/environment';
import { method } from 'lodash';

// interface
import { CloseOrder, Info, Child } from '../core/models/closeOrder.model';
import { ProductionOverview } from '../core/models/producOverview.model';
import { EquipEfficiencyOverview } from '../core/models/equipEfficiencyOverview.model';
import { NewWIP } from '../core/models/newWIP.model';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // APIUrl = 'http://13.76.171.71:9091/smartBuilding';
  // TestAPIUrl = 'http://localhost:5555/smartFactory/order/defect/orders';

  // TestAPIUrl = 'http://localhost:5555/smartFactory';
  // apiTest = '/smartFactory/apis';

  // cctvListAPIUrl = '';
  cctvListAPIUrl = 'http://localhost:9002';

  APIUrl: string;
  SSO: boolean;
  APIUrl3E: string;
  DOTNETAPIURL: string;
  apiCctvIpsfUrl: string;

  constructor(
    private http: HttpClient,
    private CookieSvc: CookieService
  ) {
    // console.log('ApiService:');
    // api url from .env control
    this.ifSSO('');
    this.APIUrl = environment.apiUrl;
    this.APIUrl3E = 'https://www-smart-building.apitech.com.tw/api5/api';
    this.DOTNETAPIURL = environment.dotnetApiUrl;
    this.apiCctvIpsfUrl = environment.apiCctvIpsfUrl;
    console.log(environment.isEnvironment);
    console.log('API SERVICE this.APIUrl:', this.APIUrl);
  }

  onClickSSO(s) {
    /*if (s) {
      location.href = 'https://www-member-sso.apitech.com.tw/login?referral=' + location.href;
      this.SSO = true;
    } else {
      location.href = 'https://www-member-sso.apitech.com.tw/logout?referral=' + location.href;
      this.SSO = false;
    }*/
    this.SSO = true;

  }
  ifSSO(gp) {//*ngIf="ifSSO('Administrator,1E-Editor,Viewer')" this.apiSvc.ifSSO('');
    /*if (this.CookieSvc.get('userobj') !== '') {
      var cgp = JSON.parse(this.CookieSvc.get('userobj')).mem_role;
      if (cgp !== undefined) {
        this.SSO = true;
        if (gp.split(",").indexOf(cgp) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        this.SSO = false;
        return false;
      }
    } else {
      this.SSO = false;
      this.onClickSSO(1);
      return false;
    }*/
    return true;
  }

  // Catch Err function
  isCatchErrCode(x, apiName) {
    console.log(`%c API ${apiName} Error Code =>`, 'color:#24b5aa;', x.error.statusCode);
  }

  // remove HttpParams empty query
  removeNullValuesFromQueryParams(params: HttpParams) {
    const paramsKeysAux = params.keys();
    paramsKeysAux.forEach((key) => {
      const value = params.get(key);
      if (value === null || value === undefined || value === '') {
        params = params.delete(key);
      }
    });
    return params;
  }

  // Section ************************
  getSectionData() {
    return this.http.get<any>(`${this.APIUrl}/section`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getSectionData');
        return of();
      }));
  }

  // getSectionData() {
  //   return this.http.get<any>(`http://localhost:7777/smartFactory/apis/section`).pipe(
  //     catchError((x) => {
  //       this.isCatchErrCode(x, 'getSectionData');
  //       return of();
  //     }));
  // }

  // 新主看板 ************************
  getMainBoardMapLineData() {
    return this.http.get<any>(`${this.APIUrl}/equips/realtimeStatus`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardMapLineData');
        return throwError(x);
      }));
  }

  getMainBoardActivedAlarms() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/getActivedAlarms`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardActivedAlarms');
        return throwError(x);
      }));
  }

  getMoDetailData(startTime) {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/getMoDetail?time=${startTime}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoDetail');
        return throwError(x);
      }));
  }

  getMainBoardAlarmLineChartData(startTime, endTime) {
    return this.http.get<any>(`${this.APIUrl}/equips/alarmHistory?startTime=${encodeURIComponent(startTime)}&endTime=${encodeURIComponent(endTime)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardAlarmLineChartData');
        return throwError(x);
      }));
  }

  postMainBoardAlarmReport(repoJSON) {
    return this.http.post<any>(`${this.APIUrl}/mainpanel/alarmReport`, repoJSON).pipe(
      catchError((x) => {
        alert('通報失敗 => 請檢查網路設定');
        this.isCatchErrCode(x, 'postMainBoardAlarmReport');
        return of();
      }));
  }

  postMainBoardAlarmRemove(repoJSON) {
    return this.http.post<any>(`${this.APIUrl}/mainpanel/alarmRemove`, repoJSON).pipe(
      catchError((x) => {
        alert('通報失敗 => 請檢查網路設定');
        this.isCatchErrCode(x, 'postMainBoardAlarmRemove');
        return of();
      }));
  }

  // Phase3 主畫面看板 ************************
  getPhase3MoDetailData(startTime) {
    return this.http.get<any>(`${this.APIUrl}/phase3/mainpanel/getMoDetail?time=${startTime}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoDetail');
        return of();
      }));
  }

  getPhase3MainBoardAlarmLineChartData(startTime, endTime) {
    return this.http.get<any>(`${this.APIUrl}/phase3/equips/alarmHistory?startTime=${encodeURIComponent(startTime)}&endTime=${encodeURIComponent(endTime)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardAlarmLineChartData');
        return of();
      }));
  }

  getPhase3MainBoard3EFloorAlarms() {
    return this.http.get<any>(`${this.APIUrl3E}/Alert/getAlarmOverview`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoard3EFloorAlarms');
        return of();
      }));
  }

  getPhase3MainBoard1EFloorAlarms() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/fetchEachFloorAlram`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoard1EFloorAlarms');
        return throwError(x);
      }));
  }

  getPhase3MainBoardMapLineData(floor: string) {
    return this.http.get<any>(`${this.APIUrl}/phase3/equips/realtimeStatus?floor=${floor}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardMapLineData');
        return throwError(x);
      }));
  }

  getPhase3LatestFileUploadTime() {
    return this.http.get<any>(`${this.APIUrl}/getUploadInfoList`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getLatestFileUploadTime');
        return throwError(x);
      }));
  }

  postPhase3MainBoardUplaodFile(category: string, jsonObj: object) {
    return this.http.post<any>(`${this.APIUrl}/phase3/upload/${category}`, jsonObj).pipe(
      catchError((x) => {
        // alert('上傳失敗 => 請檢查網路設定');
        this.isCatchErrCode(x, 'postPhase3MainBoardUplaodFile');
        return throwError(x);
      }));
  }

  // 主看板 ************************
  getMainBoardMapMsgEquipmentData(processSection, area) {
    return this.http.get<any>(`${this.APIUrl}/equipment/realtimeStatus?processSection=${encodeURIComponent(processSection)}&area=${encodeURIComponent(area)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardMapMsgEquipmentData');
        return of();
      }));
  }

  getMainBoardMapMsgSensorData(mod) {
    return this.http.get<any>(`${this.APIUrl}/equipment/sensor/realtimeStatus?mod=${encodeURIComponent(mod)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardMapMsgSensorData');
        return of();
      }));
  }

  getMainBoardReportIssue(repoJSON) {
    return this.http.post<any>(`${this.APIUrl}/equipment/announceRecord`, repoJSON).pipe(
      catchError((x) => {
        alert('通報失敗 => 請檢查網路設定');
        this.isCatchErrCode(x, 'getMainBoardReportIssue');
        return of();
      }));
  }

  getMainBoardMoListData() {
    return this.http.get<any>(`${this.APIUrl}/order`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardMoListData');
        return of();
      }));
  }

  getMainBoardTimListData(startTime, endTime) {
    return this.http.get<any>(`${this.APIUrl}/equipment/alarmList?endTime=${encodeURIComponent(endTime)}&startTime=${encodeURIComponent(startTime)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainBoardTimListData');
        return of();
      }));
  }

  // 環境模組 ************************
  getEnvironmentHistoryData(type, timeFrame, timeScope, areaType) {
    return this.http.get<any>(`${this.APIUrl}/environmentSafety/airQualityDetect/history?filter=${encodeURIComponent(type)}&timeFrame=${encodeURIComponent(timeFrame)}&timeScope=${encodeURIComponent(timeScope)}&area=${encodeURIComponent(areaType)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEnvironmentHistoryData');
        return of();
      }));
  }
  getEnvironmentCurrentData(areaType) {
    return this.http.get<any>(`${this.APIUrl}/environmentSafety/airQualityDetect/realtime?area=${encodeURIComponent(areaType)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEnvironmentCurrentData');
        return of();
      }));
  }

  // 總用電量 ************************
  getElectricityHistoryData(type, timeFrame, timeScope) {
    return this.http.get<any>(`${this.APIUrl}/energy/equipmentConsumption/?area=${encodeURIComponent(type)}&timeFrame=${encodeURIComponent(timeFrame)}&timeScope=${encodeURIComponent(timeScope)}&typeOfTime=history`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getElectricityHistoryData');
        return of();
      }));
  }
  getElectricityCurrentData(type) {
    return this.http.get<any>(`${this.APIUrl}/energy/equipmentConsumption/?area=${encodeURIComponent(type)}&timeFrame=1d&timeScope=15m&typeOfTime=realtime`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getElectricityCurrentData');
        return of();
      }));
  }

  // 品質履歷 第一頁************************
  getQualityOverviewTableData() {
    return this.http.get<any>(`${this.APIUrl}/order/quality`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getQualityOverviewTableData');
        return of();
      }));
  }

  getQualityOverviewModelData() {
    return this.http.get<any>(`${this.APIUrl}/order/quality/modelList`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getQualityOverviewModelData');
        return of();
      }));
  }

  getQualityOverviewStationData(model) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/stationList?model=${encodeURIComponent(model)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getQualityOverviewStationData');
        return of();
      }));
  }


  getQualityOverviewRankTopTenData(modelName, stationName) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/ranking?model=${encodeURIComponent(modelName)}&station=${encodeURIComponent(stationName)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getQualityOverviewRankTopTenData');
        return of();
      }));
  }



  // 品質履歷 內頁************************
  getMoQualityPerformanceData(orderNum) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/stationInformation?order=${encodeURIComponent(orderNum)}&type=yieldRate`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityPerformanceData');
        return of();
      }));
  }

  getMoQualityHourlyData(orderNum, startTime, endTime) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/stationInformation?order=${encodeURIComponent(orderNum)}&type=all&startTime=${encodeURIComponent(startTime)}&endTime=${encodeURIComponent(endTime)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityHourlyData');
        return of();
      }));
  }

  getMoQualityDefectStationNameData(orderNum) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/stationList?order=${encodeURIComponent(orderNum)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityDefectStationNameData');
        return of();
      }));
  }

  getMoQualityDefectRankingTop10Data(orderNum, stationName) {
    return this.http.get<any>(`${this.APIUrl}/order/quality/ranking?order=${encodeURIComponent(orderNum)}&station=${encodeURIComponent(stationName)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityDefectRankingTop10Data');
        return of();
      }));
  }



  // 人員工時 ************************
  // http://localhost:5555/smartFactory/apis/order/workinghour?search=CALASJ002?section=SMT?startTime=20211002?endTime=20211009?sort=actTime+?childSrot=side-?offset=1?limit=12
  getProductHourTableData() {
    return this.http.get<any>(`${this.APIUrl}/order/workinghour`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getProductHourTableData');
        return of();
      }));
  }

  getNewProductHourTableData(search, section, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/order/workinghour`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getNewProductHourTableData');
        return of();
      }));
  }




  // 生產管理 ************************
  getProductOverviewData() {
    return this.http.get<any>(`${this.APIUrl}/order/production`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getProductOverviewData');
        return of();
      }));
  }

  getProductOverviewGrafanaLink() {
    return this.http.get<any>(`${this.APIUrl}/order/production/currentOrders`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getProductOverviewGrafanaLink');
        return of();
      }));
  }

  // 新生產管理
  getNewProductOverviewStatusName() {
    return this.http.get<ProductionOverview>(`${this.APIUrl}/statusList`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getNewProductOverviewStatusName');
        return of();
      }));

    // return this.http.get<any>(`http://10.2.107.49:7777/smartFactory/apis/section`).pipe(
    //   catchError((x) => {
    //     this.isCatchErrCode(x, 'getNewProductOverviewStatusName');
    //     return of();
    //   }));
  }

  getNewProductOverviewTableData(search, status, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('status', status);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/order/production`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getNewProductOverviewTableData');
        return of();
      }));
  }

  // Phase3 生產管理
  getPhase3ProductOverviewTableData(search, status, section, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('status', status);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/phase3/order/production`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getNewProductHourTableData');
        return of();
      }));
  }

  // 維修管理 ************************
  getDefectiveTableData() {
    return this.http.get<any>(`${this.APIUrl}/order/repair`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveTableData');
        return of();
      }));
  }

  getDefectiveModelData() {
    return this.http.get<any>(`${this.APIUrl}/order/repair/modelList`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveModelData');
        return of();
      }));
  }

  getDefectiveRankTopTenData(modelName) {
    return this.http.get<any>(`${this.APIUrl}/order/repair/defectCodeRanking?model=${encodeURIComponent(modelName)}`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveRankTopTenData');
        return of();
      }));
  }

  // 人員環境安全 ************************
  getCctvHistoryData() {
    return this.http.get<any>(`${this.APIUrl}/cctv/alarmImage`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getCctvHistoryData');
        return of();
      }));
  }


  // 設備效能總覽看板 ************************
  // // http://localhost:8888/smartFactory/apis/section/lines/:section
  // // http://localhost:5555/smartFactory/apis/section/lines/smt
  getAllLineNameData() {
    return this.http.get<string[]>(`${this.APIUrl}/section/lines`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getAllLineNameData');
        return of();
      }));
  }

  getEquipOverviewData(section, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<CloseOrder>(`${this.APIUrl}/section/overview`, { params: queryParams }).pipe(
      // return this.http.get<any>(`${this.APIUrl}/section`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getcloseOrderOverviewData');
        return of();
      }));
  }

  // getAllLineNameData(section) {
  //   return this.http.get<string[]>(`${this.APIUrl}/section/lines/${section}`).pipe(
  //     catchError((x) => {
  //       this.isCatchErrCode(x, 'getAllLineNameData');
  //       return of();
  //     }));
  // }

  // getEquipOverviewData(section, offset, limit, filter) {
  //   return this.http.get<EquipEfficiencyOverview>(`${this.APIUrl}/section/overview/${encodeURIComponent(section)}?offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}&filter=${encodeURIComponent(filter)}`).pipe(
  //     catchError((x) => {
  //       this.isCatchErrCode(x, 'getEquipOverviewData');
  //       return of();
  //     }));
  // }


  // 已結工單  ************************
  getcloseOrderOverviewData(search, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<CloseOrder>(`${this.APIUrl}/closedOrders`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getcloseOrderOverviewData');
        return of();
      }));
  }

  // Phase3 已結工單  ************************
  getPhase3CloseOrderOverviewData(search, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<CloseOrder>(`${this.APIUrl}/phase3/closedOrders`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3CloseOrderOverviewData');
        return of();
      }));
  }


  // 設備異常看版 - By Line  ************************
  getEquipAlarmSection(type) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', type);
    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips/sections`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmSection');
        return of();
      }));
  }

  getEquipAlarmOption(section) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips/lines`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmOption');
        return of();
      }));
  }

  getEquipAlarmTypeInfoData(type, section, selectValue, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', type);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('select', selectValue);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips/alarm`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmTypeInfoData');
        return of();
      }));
  }

  getEquipAlarmAllEquipName(type, section, selecedOption) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', type);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('select', selecedOption);
    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmAllEquipName');
        return of();
      }));
  }

  getEquipAlarmRankingTopTen(type, section, selecedOption, equipName, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', type);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('select', selecedOption);
    queryParams = queryParams.append('equip', equipName);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);
    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips/errCodeRanking`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmAllEquipName');
        return of();
      }));
  }

  // Phase3 設備異常看版 - By Line  ************************
  getPhase3EquipAlarmAllEquipName(type, section, selecedOption) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', type);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('select', selecedOption);

    return this.http.get<any>(`${this.APIUrl}/equips/sourceId`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmAllEquipName');
        return of();
      }));
  }

  getPhase3EquipAlarmRanking(sortType, selecedOption, equipName, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sortType', sortType);
    queryParams = queryParams.append('select', selecedOption);
    queryParams = queryParams.append('equip', equipName);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equips/errorCodeRanking`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmRanking');
        return of();
      }));
  }

  getPhase3ErrorRecordExpandList(startTime, endTime, line) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('line', line);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equipsAlarm/statusAndAlarmCodeList`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getErrorRecordExpandList');
        return of();
      }));
  }

  getPhase3EquipAlarmAllLine(section, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equips/alarmAll`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipAlarmAllLine');
        return of();
      }));
  }

  // 設備效能看版 - SMT/Loading  ************************
  getEquipEfficiencyData(section, sn, line, side) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('sn', sn);
    queryParams = queryParams.append('line', line);
    queryParams = queryParams.append('side', side);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/equips/efficiency`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipEfficiencyData');
        return of();
      }));
  }

  // TEST設備效能看版 ************************
  getAteEquipEfficiencyData(section, sn, line, side, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('sn', sn);
    queryParams = queryParams.append('line', line);
    queryParams = queryParams.append('side', side);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equips/efficiency`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipEfficiencyData');
        return of();
      }));
  }

  getAteEquipStationFunction(sn) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sn', sn);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equips/functions`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipStationFunction');
        return of();
      }));
  }

  getAteEquipStationEfficiency(sn, groupname, startTime, endTime) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sn', sn);
    queryParams = queryParams.append('groupname', groupname);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/equips/station/efficiency`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getEquipStationEfficiency');
        return of();
      }));
  }

  // 品質履歷 Quality Overview 第一頁 - SMT/Loading  ************************
  getQualityData(search, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filter', search);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/qualityoverview/GetMOQuality`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getQualityData');
        return of();
      }));
  }

  // 品質履歷 Mo Quality 內頁 ************************
  getMoQualityStationPerformanceData(moNo) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/qualityoverview/GetStationPerformances`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityStationPerformanceData');
        return of();
      }));
  }

  getMoQualityStationPerformanceChildData(moNo, functionName) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);
    queryParams = queryParams.append('functionName', functionName);
    // queryParams = queryParams.append('moNo', moNo);
    // queryParams = queryParams.append('functionName', functionName);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/qualityoverview/GetStationDetails`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityStationPerformanceChildData');
        return of();
      }));
  }

  getMoQualityDefectStationListData(moNo) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/qualityoverview/GetFunctionStation`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityDefectStationListData');
        return of();
      }));
  }

  getMoQualityDefectTopTenData(moNo, station) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);
    queryParams = queryParams.append('station', station);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/qualityoverview/GetDefectRanking`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMoQualityDefectStationListData');
        return of();
      }));
  }

  // Phase3品質履歷 Quality Overview  ************************
  getPhase3QualityData(search, startTime, endTime, sort, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filter', search);
    queryParams = queryParams.append('startTime', startTime);
    queryParams = queryParams.append('endTime', endTime);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/quality/GetMOQuality`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3QualityData');
        return of();
      }));
  }

  // Phase3品質履歷 Mo Quality 內頁 ************************
  getPhase3MoQualityStationPerformanceData(moNo) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/quality/GetStationPerformances`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3MoQualityStationPerformanceData');
        return of();
      }));
  }

  getPhase3MoQualityStationPerformanceChildData(moNo, functionName) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);
    queryParams = queryParams.append('functionName', functionName);
    // queryParams = queryParams.append('moNo', moNo);
    // queryParams = queryParams.append('functionName', functionName);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/quality/GetStationDetails`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3MoQualityStationPerformanceChildData');
        return of();
      }));
  }

  getPhase3MoQualityDefectStationListData(moNo) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/quality/GetFunctionStation`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3MoQualityDefectStationListData');
        return of();
      }));
  }

  getPhase3MoQualityDefectTopTenData(moNo, station) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moNo', moNo);
    queryParams = queryParams.append('station', station);

    // return this.http.get<any>(`${this.APIUrl}/section`).pipe(   // 暫時ＡＰＩ
    return this.http.get<any>(`${this.APIUrl}/quality/GetDefectRanking`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3MoQualityDefectTopTenData');
        return of();
      }));
  }

  // 新 WIP Overview 看板  ************************
  geWipOverviewData(section, offset, limit) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('offset', offset);
    queryParams = queryParams.append('limit', limit);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<NewWIP>(`${this.APIUrl}/WIP`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'geWipOverviewData');
        return of();
      }));
  }

  // Phase3 WIP Overview 看板  ************************
  getPhase3WipOverviewData(mo) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('mo', mo);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/phase3/order/route`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getPhase3WipOverviewData');
        return of();
      }));
  }

  // for new repair management dashboard use
  // for category dropdown use
  getDefectiveProductManagementCategories() {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getCategories`;

    return this.http.get<any>(uri).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementCategories');
        return of();
      }));
  }

  // for model dropdown use
  getDefectiveProductManagementModels(category?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getModels`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('category', category);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementModels');
        return of();
      }));
  }

  // for banners and WIP barchart use
  getDefectiveProductManagementDefectiveInfo(category?: string, model?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectiveInfo`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementDefectiveInfo');
        return of();
      }));
  }

  // for MOs table use
  getDefectiveProductManagementDefectiveMoList(category?: string, model?: string, limit?: number, offset?: number, filter?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectiveMOs`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = queryParams.append('limit', limit.toString());  // type casting number to string, or default type set string
    queryParams = queryParams.append('offset', offset.toString());
    queryParams = queryParams.append('filter', filter);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementDefectiveMOs');
        return of();
      }));
  }

  // for nested table use
  // tslint:disable-next-line:variable-name
  getDefectiveProductManagementDefectiveUnitsByMoList(mo_list: string[]) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectiveUnitsByMOs`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('mo_list', mo_list.join(','));

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementDefectiveUnitsByMOs');
        return of();
      }));
  }

  // for ranking list use
  getDefectiveProductManagementDefectiveRootCauseRanking(category?: string, model?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectiveRootCauseRanking`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementDefectiveRootCauseRanking');
        return of();
      }));
  }

  // for progress-bar pop-up page use
  // tslint:disable-next-line:variable-name
  getDefectiveProductManagementDefectiveUnitsByRootCause(root_cause_code: string, limit: number, offset: number, category?: string, model?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectiveUnitsByRootCause`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('root_cause_code', root_cause_code);
    queryParams = queryParams.append('limit', limit.toString());  // type casting number to string, or default type set string
    queryParams = queryParams.append('offset', offset.toString());  // type casting number to string, or default type set string
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementDefectiveUnitsByRootCause');
        return of();
      }));
  }

  // Phase3 維修看板
  getDefectiveProductManagementOverdueMOData(offset: number, limit: number, filter: string, sort?: string, descending?: boolean, category?: string, model?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getOverdueMOData`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('offset', offset.toString());
    queryParams = queryParams.append('limit', limit.toString());
    queryParams = queryParams.append('filter', filter);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('descending', descending?.toString());
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementOverdueMOData');
        return of();
      }));
  }

  getDefectiveProductManagementWipRank(offset: number, limit: number, section: string, filter: string, sort?: string, descending?: boolean, category?: string, model?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getWipRank`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('offset', offset.toString());
    queryParams = queryParams.append('limit', limit.toString());
    queryParams = queryParams.append('filter', filter);
    queryParams = queryParams.append('section', section);
    queryParams = queryParams.append('sort', sort);
    queryParams = queryParams.append('descending', descending?.toString());
    queryParams = queryParams.append('category', category);
    queryParams = queryParams.append('model', model);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDefectiveProductManagementWipRank');
        return of();
      }));
  }

  getDefectiveProductManagementDefectUnitsByMo(mo: string, partNo: string, offset: number, limit: number, category?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectUnitsByMo`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('mo', mo);
    queryParams = queryParams.append('partNo', partNo);
    queryParams = queryParams.append('offset', offset.toString());
    queryParams = queryParams.append('limit', limit.toString());
    queryParams = queryParams.append('category', category);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'defectiveproductmanagement/getDefectUnitsByMo');
        return of();
      }));
  }

  getDefectiveProductManagementDefectUnitsByMoSnFunc(mo: string, partNo: string, func: string, offset: number, limit: number, category?: string) {
    const uri = `${this.APIUrl}/defectiveproductmanagement/getDefectUnitsByMoSnFunc`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('moData', mo);
    queryParams = queryParams.append('partNo', partNo);
    queryParams = queryParams.append('groupNameFilter', func);
    queryParams = queryParams.append('offset', offset.toString());
    queryParams = queryParams.append('limit', limit.toString());
    queryParams = queryParams.append('category', category);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(uri, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'defectiveproductmanagement/getDefectUnitsByMoSnFunc');
        return of();
      }));
  }

  // for new main panel dashboard use
  // for Alarm Record line chart use
  getMainPanelAlarmRecords() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/GetAlarmRecords`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainPanelAlarmRecords');
        return of();
      }));
  }

  // for Alarms list use
  getMainPanelReportedAlarms() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/GetReportedAlarms`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainPanelReportedAlarms');
        return of();
      }));
  }

  // for all module status use
  getMainPanelEquipmentStatus() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/GetEquipmentStatus`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainPanelGetEquipmentStatus');
        return of();
      }));
  }

  // for MO Status Overview & Detail use the
  getMainPanelMoDetail() {
    return this.http.get<any>(`${this.APIUrl}/mainpanel/GetMoDetail`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getMainPanelGetMoDetail');
        return of();
      }));
  }


  getDroH(date: string) {
    let P = new HttpParams();
    P = P.append('time', date);
    return this.http.get<any>(`${this.APIUrl}/dropRate/history`, { params: this.removeNullValuesFromQueryParams(P) }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'history');
        return of();
      }));
  }
  getDroEQ() {
    return this.http.get<any>(`${this.APIUrl}/dropRate/equips`).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'equips');
        return of();
      }));
  }
  getAtef(f) {
    let P = new HttpParams();
    P = P.append('floor', f);
    return this.http.get<any>(`${this.APIUrl}/equips/deviceType`, { params: this.removeNullValuesFromQueryParams(P) }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'history');
        return of();
      }));
  }
  /*
    參數
    startTime:
    endTime:
    offset: 頁碼
    limit: 預設12，一頁幾項
    sortBy: equipCode, equipName, nrunLight, idleLight, errorLight, downTime, runRate, idleRate, downRate

    加上了floor, deviceType, PIC, ErrorCode欄位
    參數 {startTime, endTime, floor, deviceType, sort, descending, offset, limit}
    篩選器 floor, deviceType
    sort: 可以用floor, deviceType, deviceName, runLight, idleLight, errorLight, runRate, idleRate, downRate, downTime
    descending: 降冪 true/false
    */
  getAte(startTime, endTime, floor, deviceType, sort, descending, offset, limit) {
    let P = new HttpParams();
    P = P.append('startTime', startTime);
    P = P.append('endTime', endTime);
    if (floor !== 'All') { P = P.append('floor', floor); } else { P = P.append('floor', 'all'); }
    if (deviceType !== 'All') { P = P.append('deviceType', deviceType); }
    P = P.append('sort', sort);
    P = P.append('descending', descending);
    P = P.append('offset', offset);
    P = P.append('limit', limit);
    return this.http.get<any>(`${this.APIUrl}/equips/ateAlarm`, { params: this.removeNullValuesFromQueryParams(P) }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'history');
        return of();
      }));
  }


  getDropRateOverviewData(date) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('time', date);
    queryParams = this.removeNullValuesFromQueryParams(queryParams);

    return this.http.get<any>(`${this.APIUrl}/dropRate`, { params: queryParams }).pipe(
      catchError((x) => {
        this.isCatchErrCode(x, 'getDropRateOverviewData');
        return throwError(x);
      }));
  }

}

class QueryBuilder {
  static build(map: Record<string, string | number>): string {
    const keys = Object.keys(map);
    const paramStrings = keys
      .filter(v => map[v] !== undefined)
      .map(key => `${key}=${encodeURIComponent(map[key])}`);
    if (paramStrings.length === 0) { return ''; }
    return `?${paramStrings.join('&')}`;
  }
}

class Fetcher {
  constructor(private http: HttpClient, private baseUrl: string) { }

  fetch<T>(endpoint: string, options: {
    method?: 'get' | 'post',
    data?: object | string | number,
    onError?: (error: any) => void,
  } = {}) {
    const url = `${this.baseUrl}${endpoint}`;
    const { method = 'get', data, onError = () => undefined } = options;

    // tslint:disable-next-line:no-shadowed-variable
    const pipeError = <T>(f: Observable<T>) => {
      return f.pipe(
        catchError((x) => {
          onError(x);
          return of<T>();
        }));
    };
    switch (method) {
      case 'post':
        return pipeError<T>(this.http.post<T>(url, data));
      case 'get':
      default:
        return pipeError<T>(this.http.get<T>(url));
    }
  }
}


// tslint:disable-next-line: no-namespace
export namespace DotnetApi {
  export namespace Common {
    export interface ListDetail<T> {
      limit: number;
      offset: number;
      total: number;
      data: T;
    }

    export class Service {
      fetcher: Fetcher;

      constructor(http: HttpClient) {
        this.fetcher = new Fetcher(http, environment.dotnetApiUrl);
      }
    }
  }
  export namespace DefectiveProductManagement {
    export enum ServiceName {
      GET_CATEGORIES = 'GET_CATEGORIES',
      GET_MODELS = 'GET_MODELS',
      GET_DEFECTIVE_INFO = 'GET_DEFECTIVE_INFO',
      GET_DEFECTIVE_MO_LIST = 'GET_DEFECTIVE_MO_LIST',
      GET_DEFECTIVE_UNITS_BY_MO_LIST = 'GET_DEFECTIVE_UNITS_BY_MO_LIST',
      GET_DEFECTIVE_ROOT_CAUSE_RANKING = 'GET_DEFECTIVE_ROOT_CAUSE_RANKING',
      GET_DEFECTIVE_UNITS_BY_ROOT_CAUSE = 'GET_DEFECTIVE_UNITS_BY_ROOT_CAUSE',
    }

    export enum RepairStatus {
      NORMAL = 0,
      WARNING = 1,
      OVERDUE = 2
    }

    export interface DefectiveInfo {
      remainingWipQty: number;
      toBeRepairedQty: number;
      wipQty: number;
      complete: number;
      ngQty: number;
      overdueQty: number;
      almostOverdueQty: number;
      wipHistory: {
        key: string;
        qty: number;
        name: string;
      }[];
    }

    export interface DefectiveRootCause {
      key: string;
      name: string;
      rootCauseCount: number;
      rootCauseRatio: number;
    }

    export interface DefectiveMoDetail {
      key: string;
      partNo: string;
      timeToRepairInSeconds: number;
      defectQty: number;
      completeQty: number;
      overdueQty: number;
      overdue3to7Qty: number;
      overdue8to30Qty: number;
      overdueGt30Qty: number;
      repairStatus: RepairStatus;
      overdueTime: number;
    }

    export type DefectiveMoListInfo = DotnetApi.Common.ListDetail<DefectiveMoDetail[]>;

    export interface DefectiveMoUnitDetail {
      key: string;
      moNo: string;
      startTime: number;
      endTime: number | null;
      defectiveCode: string;
      repairStatus: RepairStatus;
    }

    export interface DefectiveRootCauseUnitDetail {
      unitNo: string;
      startTime: number;
      endTime: number;
      defectiveCode: string;
      partNo: string;
      moNo: string;
      section: string;
      side: string;
    }

    export type DefectiveRootCauseUnitListInfo = DotnetApi.Common.ListDetail<DefectiveRootCauseUnitDetail[]>;

    const GetCategories = (fetcher: Fetcher) => () => {
      return fetcher.fetch<{
        value: string;
        name: string;
      }[]>('/defectiveproductmanagement/getCategories');
    };

    const GetModels = (fetcher: Fetcher) => (query: { category?: string }) => {
      return fetcher.fetch<{
        value: string;
        name: string;
      }[]>(`/defectiveproductmanagement/getModels${QueryBuilder.build(query)}`);
    };

    const GetDefectiveInfo = (fetcher: Fetcher) => (query: { category?: string, model?: string }) => fetcher.fetch<DefectiveInfo>(`/defectiveproductmanagement/getDefectiveInfo${QueryBuilder.build(query)}`);

    const GetDefectiveMoList = (fetcher: Fetcher) => (query: {
      category?: string;
      model?: string;
      limit?: number;
      offset?: number;
      filter?: string;
    }) => fetcher.fetch<DefectiveMoListInfo>(`/defectiveproductmanagement/getDefectiveMOs${QueryBuilder.build(query)}`);

    const GetDefectiveUnitsByMoList = (fetcher: Fetcher) => (query: { moList: string[] }) => fetcher.fetch<DefectiveMoUnitDetail[]>(`/defectiveproductmanagement/getDefectiveUnitsByMOs${QueryBuilder.build({
      mo_list: query.moList.join(','),
    })}`);

    const GetDefectiveRootCauseRanking = (fetcher: Fetcher) => (query: { category?: string; model?: string; }) => fetcher.fetch<{
      startTime: number; // milliseconds
      endTime: number; // milliseconds
      ranking: DefectiveRootCause[];
    }>(`/defectiveproductmanagement/getDefectiveRootCauseRanking${QueryBuilder.build(query)}`);

    const GetDefectiveUnitsByRootCause = (fetcher: Fetcher) =>
      ({
        rootCauseCode,
        limit,
        offset,
        category,
        model,
      }: {
        rootCauseCode: string;
        limit: number;
        offset: number;
        category?: string;
        model?: string;
      }) => fetcher.fetch<DefectiveRootCauseUnitListInfo>(`/defectiveproductmanagement/getDefectiveUnitsByRootCause${QueryBuilder.build({
        root_cause_code: rootCauseCode,
        limit,
        offset,
        category,
        model,
      })}`);

    @Injectable({
      providedIn: 'root'
    })
    export class Service extends DotnetApi.Common.Service {
      constructor(http: HttpClient) {
        super(http);
      }

      getService(name: ServiceName.GET_CATEGORIES): ReturnType<typeof GetCategories>;
      getService(name: ServiceName.GET_MODELS): ReturnType<typeof GetModels>;
      getService(name: ServiceName.GET_DEFECTIVE_INFO): ReturnType<typeof GetDefectiveInfo>;
      getService(name: ServiceName.GET_DEFECTIVE_MO_LIST): ReturnType<typeof GetDefectiveMoList>;
      getService(name: ServiceName.GET_DEFECTIVE_UNITS_BY_MO_LIST): ReturnType<typeof GetDefectiveUnitsByMoList>;
      getService(name: ServiceName.GET_DEFECTIVE_ROOT_CAUSE_RANKING): ReturnType<typeof GetDefectiveRootCauseRanking>;
      getService(name: ServiceName.GET_DEFECTIVE_UNITS_BY_ROOT_CAUSE): ReturnType<typeof GetDefectiveUnitsByRootCause>;
      getService(name: ServiceName) {
        switch (name) {
          case ServiceName.GET_CATEGORIES:
            return GetCategories(this.fetcher);
          case ServiceName.GET_MODELS:
            return GetModels(this.fetcher);
          case ServiceName.GET_DEFECTIVE_INFO:
            return GetDefectiveInfo(this.fetcher);
          case ServiceName.GET_DEFECTIVE_MO_LIST:
            return GetDefectiveMoList(this.fetcher);
          case ServiceName.GET_DEFECTIVE_UNITS_BY_MO_LIST:
            return GetDefectiveUnitsByMoList(this.fetcher);
          case ServiceName.GET_DEFECTIVE_ROOT_CAUSE_RANKING:
            return GetDefectiveRootCauseRanking(this.fetcher);
          case ServiceName.GET_DEFECTIVE_UNITS_BY_ROOT_CAUSE:
            return GetDefectiveUnitsByRootCause(this.fetcher);
        }
      }
    }
  }

  export namespace MainPanel {

    export enum ServiceName {
      GET_MO_DETAIL = 'GET_MO_DETAIL',
      GET_ACTIVE_ALARMS = 'GET_ACTIVE_ALARMS',
      POST_ALARM_REPORT = 'POST_ALARM_REPORT',
      POST_ALARM_REMOVE = 'POST_ALARM_REMOVE',
    }

    export interface MoDetail {
      closeMo: number;
      toBeProcessed: number;
      detail: Detail;
    }

    export interface Detail {
      closed: Closed;
      inProgress: InProgress;
      onTimeRatio: number;
    }

    export interface Closed {
      onTime: number;
      overdue: number;
    }

    export interface InProgress {
      onTime: number;
      overdue: number;
    }

    export interface ActivedAlarms {
      sourceId: string;
      alarmTime: number;
      deviceName: string;
      deviceType: string;
      line: string;
      section: string;
      lastReportTime: number;
      isReported: boolean;
      floor: string;
      area: string;
    }

    export interface PostAlarmReport {
      sourceId: string;
      alarmTime: number;
    }

    export interface PostAlarmRemove {
      sourceId: string;
      alarmTime: number;
    }

    const GetMoDetail = (fetcher: Fetcher) => () => {
      return fetcher.fetch<MoDetail>('/mainpanel/getMoDetail');
    };

    const GetActivedAlarms = (fetcher: Fetcher) => () => {
      return fetcher.fetch<ActivedAlarms[]>('/mainpanel/getActivedAlarms');
    };

    const PostAlarmReport = (fetcher: Fetcher) => (item: PostAlarmReport) => {
      return fetcher.fetch<PostAlarmReport>('/mainpanel/alarmReport', {
        method: 'post',
        data: item
      });
    };

    const PostAlarmRemove = (fetcher: Fetcher) => (item: PostAlarmRemove) => {
      return fetcher.fetch<PostAlarmRemove>('/mainpanel/alarmRemove', {
        method: 'post',
        data: item
      });
    };


    @Injectable({
      providedIn: 'root'
    })

    export class Service extends DotnetApi.Common.Service {

      constructor(http: HttpClient) {
        super(http);
      }

      getService(name: ServiceName.GET_MO_DETAIL): ReturnType<typeof GetMoDetail>;
      getService(name: ServiceName.GET_ACTIVE_ALARMS): ReturnType<typeof GetActivedAlarms>;
      getService(name: ServiceName.POST_ALARM_REPORT): ReturnType<typeof PostAlarmReport>;
      getService(name: ServiceName.POST_ALARM_REMOVE): ReturnType<typeof PostAlarmRemove>;
      getService(name: ServiceName) {
        switch (name) {
          case ServiceName.GET_MO_DETAIL:
            return GetMoDetail(this.fetcher);
          case ServiceName.GET_ACTIVE_ALARMS:
            return GetActivedAlarms(this.fetcher);
          case ServiceName.POST_ALARM_REPORT:
            return PostAlarmReport(this.fetcher);
          case ServiceName.POST_ALARM_REMOVE:
            return PostAlarmRemove(this.fetcher);
        }
      }
    }
  }

}
