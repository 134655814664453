<div class="login">
  <div class="content">
    <img class="logo" src="../../assets/img/logo.svg">

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <fieldset formGroupName="login">
        <div class="alerm-content" [ngClass]="{'show': isError}">
          <p>錯誤的使用者名稱或密碼</p>
          <img src="../../assets/img/alarm.svg">
        </div>
        <div class="form-content flex">
          <div class="img-box flex">
            <img src="../../assets/img/account.svg">
          </div>
          <input type="text" formControlName="username" class="form-control" [ngClass]="{'error': isError}" placeholder="帳號"/>
        </div>
        <div class="form-content flex">
          <div class="img-box flex">
            <img src="../../assets/img/password.svg">
          </div>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{'error': isError}" placeholder="密碼"/>
        </div>
        <div class="form-content text-right">
          <button class="save-btn">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <span *ngIf="!loading">登入</span>
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</div>
