<div class="wip_overview">
  <!-- Top Menu side -->
  <div class="title_group">
    <h1 class="wip_overview_title" (click)="changeWIP = !changeWIP">
      {{ isNowWipName }} WIP Overview
      <div class="circle">
        <div class="triangle"></div>
      </div>
      <div class="choice_title" *ngIf="changeWIP">
        <ng-container *ngFor="let item of wipNameArr">
          <p
            class="choice_text"
            *ngIf="item.isActive === false"
            (click)="clickWipTitle(item.wipName)"
          >
            {{ item.wipName }} WIP Overview
          </p>
        </ng-container>
        <!-- <p class="choice_text">SMT WIP Overview</p>
        <p class="choice_text">Loading WIP Overview</p>
        <p class="choice_text">ATE WIP Overview</p> -->
      </div>
    </h1>
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="/assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <!-- Slide View -->
  <div class="container_box">
    <div class="line_info_group">
      <div class="line_box" *ngFor="let item of resItemDataArr">
        <!-- line Info box -->
        <div class="info_text_block">
          <div class="line_name">{{ item.lineName }}</div>
          <div class="title">MO No.</div>
          <div class="title_value">{{ item.moNum }}</div>
          <div class="title">Product</div>
          <div class="title_value">{{ item.product }}</div>
          <div class="title">MO Qty</div>
          <div class="title_value">{{ item.moQty | number }}</div>
        </div>

        <!-- line point box-->
        <div class="line_point_block">
          <!-- 站點 => 第一站 -->
          <div class="point_start">
            <p
              class="point_start_num"
              [class]="{
                'text-c-red': item.startPointObj?.numWarn
              }"
            >
              {{ item.startPointObj?.num | number }}
            </p>
            <img
              class="point_icon"
              [class]="{
                'icon-bg-green': item.startPointObj?.iconStatus === 1,
                'icon-bg-blue': item.startPointObj?.iconStatus === 2,
                'icon-bg-red': item.startPointObj?.iconBgWarn
              }"
              [src]="
                '/assets/img/icon_scan_0' +
                item.startPointObj?.iconStatus +
                '.svg'
              "
              alt=""
            />
          </div>

          <!-- 中間站點 -->
          <div class="point_box_group">
            <div
              class="point_box"
              *ngFor="let point of item.pointInsideArr"
              [style.width]="100 / item.pointInsideNum + '%'"
            >
              <div class="line_group">
                <p
                  class="point_num"
                  [class]="{
                    'text-c-red': point?.numWarn
                  }"
                >
                  {{ point.num }}
                </p>
                <div
                  class="gray_line"
                  [class]="{
                    'icon-bg-red': point?.numWarn
                  }"
                >
                  <div
                    class="gray_point"
                    *ngFor="let point of point.gray_point"
                  ></div>
                </div>
                <div class="point_name_group">
                  <p *ngIf="point.lineNameSingle !== null">
                    {{ point.lineNameSingle }}
                  </p>
                  <p *ngIf="point.lineNameMultipleStart !== null">
                    {{ point.lineNameMultipleStart }}
                    <span class="point_names">‧‧‧</span>
                    {{ point.lineNameMultipleEnd }}
                  </p>
                </div>
              </div>
              <img
                class="point_icon"
                [class]="{
                  'icon-bg-green': point.iconStatus === 1,
                  'icon-bg-blue': point.iconStatus === 2,
                  'icon-bg-red': point?.iconBgWarn
                }"
                [src]="'/assets/img/icon_scan_0' + point.iconStatus + '.svg'"
                alt=""
              />
            </div>
          </div>

          <!-- 站點 => 最後一站 -->
          <div class="point_end">
            <p
              class="point_end_num"
              [class]="{
                'text-c-red': item.startPointObj?.numWarn
              }"
            >
              {{ item.endPointObj?.num | number }}
            </p>
          </div>
        </div>

        <!-- 圖釘 -->
        <div
          class="btn_pin"
          *ngIf="item.isPinItem === false"
          (click)="openPopup = true; popupMode = '1'"
        >
          <img src="/assets/img/btn_pin.svg" alt="pin" />
        </div>

        <div
          class="btn_pin_active"
          *ngIf="item.isPinItem === true"
          (click)="openPopup = true; popupMode = '2'"
        >
          <img src="/assets/img/btn_pin.svg" alt="pin" />
        </div>
      </div>

      <!-- <div class="line_box">
        <div class="info_text_block">
          <div class="line_name">SMT Line A</div>
          <div class="title">MO No.</div>
          <div class="title_value">DE1BH01QL004 (A)</div>
          <div class="title">Product</div>
          <div class="title_value">RLOD-PBH0014C0G-16</div>
          <div class="title">MO Qty</div>
          <div class="title_value">12,000</div>
        </div>
        <div class="line_point_block"></div>

        <div class="btn_pin" (click)="openPopup = true; popupMode = '1'">
          <img src="/assets/img/btn_pin.svg" alt="pin" />
        </div>

        <div class="btn_pin_active" (click)="openPopup = true; popupMode = '2'">
          <img src="/assets/img/btn_pin.svg" alt="pin" />
        </div>
      </div> -->
    </div>
  </div>

  <!-- Footer -->
  <div class="foot">
    <div class="page_num">
      <img
        class="page_arrow page_arrow_left"
        src="assets/img/page_arrow_left.svg"
        alt=""
        (click)="swapSecs(isNowSecSave)"
      />
      <p class="number">{{ isPageNum }} / {{ isTotalPageNum }}</p>
      <img
        class="page_arrow page_arrow_right"
        src="assets/img/page_arrow_right.svg"
        alt=""
        (click)="swapSecs(isNowSecSave)"
      />
    </div>

    <!-- Auto swap -->
    <div class="auto_swap" (click)="this.choiceSecShow = !this.choiceSecShow">
      <!-- {{ isNowSecSave }} sec /  -->
      Auto swap
      <img class="swap_img" src="assets/img/arrow_swap_top.svg" alt="" />
      <div class="sec_group" *ngIf="choiceSecShow">
        <p
          class="btn_sec"
          *ngFor="let item of swapSecArr"
          (click)="swapSecs(item.secNum)"
        >
          <img *ngIf="item.isActive" src="assets/img/swap_check.svg" alt="" />
          <span *ngIf="item.isActive === false"> </span>
          {{ item.secNum * 10 }} sec
        </p>
        <!-- <p class="btn_sec" (click)="swapSecs(3)">
            <img src="assets/img/swap_check.svg" alt="" />
            30 sec
          </p> -->
      </div>
    </div>
  </div>

  <!-- Popup window -->
  <div class="popup_window" *ngIf="openPopup">
    <div class="popup_box isPinItem" *ngIf="popupMode === '1'">
      <p>Pin this item to the first page? The pinned item will be replaced.</p>
      <div class="btn_group">
        <div class="btn btn_yes">Yes</div>
        <div class="btn btn_no" (click)="openPopup = false">No</div>
      </div>
    </div>

    <div class="popup_box isPinItemCancel" *ngIf="popupMode === '2'">
      <p>Unpin this item ?</p>
      <div class="btn_group">
        <div class="btn btn_yes">Yes</div>
        <div class="btn btn_no" (click)="openPopup = false">No</div>
      </div>
    </div>
  </div>
</div>
