import * as d3 from 'd3';
import { isEqual } from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FontColor, BarChartColor, BorderColor } from 'src/design/palette';

const BarColors = [
  BarChartColor.PURPLE,
  BarChartColor.BLUE,
  BarChartColor.RED,
  BarChartColor.ORANGE,
  BarChartColor.CYAN,
];

export interface Data {
  x: string;
  y: number;
}

@Component({
  selector: 'app-custom-component-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {

  constructor() {
    this.onResize$.subscribe();
  }
  @ViewChild('barChart')
  private chartContainer: ElementRef;

  @Input()
  yTicks = 7;

  @Input()
  data: Data[];

  @Input()
  xLabel = '';

  @Input()
  yLabel = '';

  margin = { top: 20, right: 20, bottom: 30, left: 40 };

  /**
   * debounce limitation while resizing window
   */
  onResize$ = fromEvent(window, 'resize')
    .pipe(
      debounceTime(300),
      tap(() => {
        this.createChart();
      })
    );

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    const { data: {
      currentValue,
      previousValue
    } } = changes;

    /**
     * prevent redundant re-render
     */
    if (!isEqual(currentValue, previousValue)) { this.createChart(); }
  }

  private createChart(): void {
    if (this.data == null || this.data.length === 0) {
      console.log('There is no input data.');
      return;
    }

    d3.select('svg').remove();

    const element = this.chartContainer?.nativeElement;
    const data = this.data;

    if (!(element instanceof HTMLElement)) { return; }

    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .paddingOuter(0.8)
      .paddingInner(0.4)
      .rangeRound([0, contentWidth])
      .domain(data.map(d => d.x));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0]) // BUG: adjust the y axis numbers
      .domain([0, d3.max(data, d => d.y)]);

    // draw the bar chart grid lines
    svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('color', BorderColor.GREY)
      .call(
        d3.axisLeft(y)
          .ticks(this.yTicks)
          .tickSize(-contentWidth)
          .tickFormat(() => '')
      )
      .select('.domain').remove(); // remove the top tick

    // draw the bar chart container
    const g = svg.append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // draw x-axis ticks
    const xAxis = d3.axisBottom(x).tickSize(0).tickPadding(8);
    g.append('g')
      .call(xAxis)
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${contentHeight})`)
      .attr('color', FontColor.GREY);

    // draw y-axis ticks
    const yAxis = d3.axisLeft(y).tickSize(0).tickPadding(14).ticks(this.yTicks);
    g.append('g')
      .call(yAxis)
      .attr('class', 'axis axis-y')
      .attr('color', FontColor.GREY)
      .attr('text-anchor', 'end');

    // draw bars
    const bar = g.selectAll('.app-custom-component-bar-chart .bar')
      .data(data)
      .enter()
      .append('g');
    bar.append('title')
      .text(d => d.y);
    bar.append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.x))
      .attr('y', d => y(d.y)) // not hides the x-axis
      .attr('width', x.bandwidth())
      .attr('height', d => contentHeight - y(d.y))
      .attr('fill', (d, index) => BarColors[index % BarColors.length]);

    // draw x-axis text label
    svg.append('text')
      .attr('transform', `translate(${contentWidth}, ${contentHeight + 35})`)
      .attr('fill', FontColor.GREY)
      .text(this.xLabel);

    // draw y-axis text label
    svg.append('text')
      .attr('transform', `translate(0, 10)`)
      .attr('fill', FontColor.GREY)
      .text(this.yLabel);
  }

}
