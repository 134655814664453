import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RealTimeClockService {

  isRealTimeClock;
  constructor() {

    setInterval(() => {
      this.isRealTimeClock = new Date();
      // console.log('this.isRealTimeClock:', this.isRealTimeClock);
    }, 1000);

  }



}
