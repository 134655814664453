import { Component, OnDestroy, OnInit } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';
import { timer, Subject, } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import { ActivatedRoute, Router } from '@angular/router';


// interface
import { NewWIP } from '../core/models/newWIP.model';

@Component({
  selector: 'app-new-wip-overview',
  templateUrl: './new-wip-overview.component.html',
  styleUrls: ['./new-wip-overview.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class NewWipOverviewComponent implements OnInit, OnDestroy {


  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    // route
    private route: ActivatedRoute,
    private router: Router,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = true;
  }

  subject = new Subject();
  // status => 1:green, 2:yellow, 3:red

  changeSectionName;
  isNowSectionName: string;
  pingSaveLineName: string;
  sectionNameArr = [{
    sectionName: 'SMT/Loading',
    isActive: false,
  },
  {
    sectionName: 'ATE/ASY/PKG',
    isActive: false,
  }];

  sectionNameToParameters = null;
  mappingSectionNameToParameters = {
    'SMT/Loading': 'SMT,Loading',
    'ATE/ASY/PKG': 'ATE,ASY,PKG'
  };

  equipsLightsObj = {
    // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
    0: 'green',
    1: 'yellow',
    2: 'red',
  };

  isPageNum = 1;
  isTotalPageNum = 1;  // from api response totalPage

  resItemDataArr = [];

  timerFn;
  isSaveSec;
  choiceSecShow;
  swapSecArr = [
    {
      secNum: 'Stop',
      isActive: false,
    },
    {
      secNum: 3,
      isActive: false,
    },
    {
      secNum: 6,
      isActive: false,
    },
    {
      secNum: 18,
      isActive: false,
    }
  ];

  ngOnInit(): void {
    this.clickWipTitle('SMT/Loading');
    this.swapSecs(3);
  }

  ngOnDestroy() {
    this.timerFn.unsubscribe();
  }

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.subject.next();
  }

  // 設定計時 & Call API
  secTimer(sec) {
    this.isSaveSec = sec;
    // console.log('this.isSaveSec:', this.isSaveSec);

    this.timerFn = timer(0, sec * 10000).pipe(
      takeUntil(this.subject)
    ).subscribe(
      (t) => {
        this.isSvLoading.loading = true;
        console.log(`計時器 sec => ${sec} ,`, t, '次數');
        // this.rxTimerStop();
        this.swapPageNum('+1');
        this.slidePageNum(this.isPageNum);

        // Call API
        this.getAllApiFn(this.sectionNameToParameters, this.isPageNum, 3);
      }
    );
  }

  // 切換秒數
  swapSecs(sec) {
    for (const item of this.swapSecArr) {
      if (sec === item.secNum) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    }
    if (sec === 'Stop') {
      this.rxTimerStop();
      return;
    }
    this.rxTimerStop();

    console.log('swapSecs to sec:', sec);
    this.secTimer(sec);
  }

  // 切換 title section name
  clickWipTitle(name) {
    this.isSvLoading.loading = true;

    this.isNowSectionName = name;
    this.sectionNameArr.map(x => {
      x.isActive = false;
      if (x.sectionName === name) {
        x.isActive = true;
      }
      return x;
    });
    console.log('this.isNowSectionName => ', this.isNowSectionName);
    this.sectionNameToParameters = this.mappingSectionNameToParameters[name];
    console.log('this.sectionNameToParameters:', this.sectionNameToParameters);

    this.isPageNum = 1;

    // 清空資料 Call API
    // this.resItemDataArr = [];
    this.getAllApiFn(this.sectionNameToParameters, 1, 3);
    // console.log('this.sectionNameArr:', this.sectionNameArr);

  }

  // 分頁切換
  swapPageNum(str) {
    this.isSvLoading.loading = true;
    if (str === '+1') { this.isPageNum++; }
    if (str === '-1') { this.isPageNum--; }
  }

  // 確認輪播分頁
  slidePageNum(num) {
    if (num > this.isTotalPageNum) { this.isPageNum = 1; }
    // if (num < 0) { this.isPageNum = 1; }
    console.log('num:', num);
    console.log('this.isPageNum:', this.isPageNum);
  }

  // 檢查當前頁數 有沒有 大於總頁數／大於總頁數時顯示最後一頁
  checkPageNum() {
    // 目前頁數大於總頁數，顯示最後一頁
    if (this.isPageNum > this.isTotalPageNum) { this.isPageNum = this.isTotalPageNum; }
  }

  // API Card Data
  getCardData$(section, offset, limit) {
    return this.apiSvc.geWipOverviewData(section, offset, limit).pipe(
      tap(res => {
        // console.log('getCardData row data res:', res);
      }),
    );
  }

  // API All
  getAllApiFn(section, offset, limit) {
    return this.getCardData$(section, offset, limit).pipe(
      tap((res) => {
        res = this.resItemDataArr;
      }),
    ).subscribe((res: NewWIP) => {
      console.log('getAllApiFn res:', res);
      if (res.totalPage === 0) { res.totalPage++; }

      if (res.infos.length !== 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        this.resItemDataArr = res.infos;

      }

      if (res.infos.length === 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        // this.resItemDataArr
        console.log('this.resItemDataArr:', this.resItemDataArr);
      }

      this.isSvLoading.loading = false;
    });
  }
}
