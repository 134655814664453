import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';
import { timer, Subject, interval, } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// angular-material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import numeral from 'numeral';

// interface
import { EquipEfficiencyOverview, Info } from '../core/models/equipEfficiencyOverview.model';
import { EfficiencyApiData, DetailListType } from '../core/models/popupInfo.model';

@Component({
  selector: 'app-phase3-equipment-efficiency-overview',
  templateUrl: './phase3-equipment-efficiency-overview.component.html',
  styleUrls: ['./phase3-equipment-efficiency-overview.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class Phase3EquipmentEfficiencyOverviewComponent implements OnInit, OnDestroy {

  isMenuOpen = false;
  isLogin = false;

  subject = new Subject();
  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  changeSectionName;
  isNowSectionName: string;
  pingSaveLineName: string;
  sectionNameArr = [];

  equipsLightsObj = {
    // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
    0: 'green',
    1: 'yellow',
    2: 'red',
  };

  nowPageNum = 1;
  isTotalPageNum = 1;  // from api response totalPage

  resLineNameArr;


  // resItemDataArr: Info[] = [];
  resItemDataArr = [
    {
      routeCode:'123',
      groupName: '456',
      inGroup: '789',
      section: 'TEST',
      outGroup: '12',
      nowInput:99,
      moInput:88,
      nowOutput:86,
      moOutput:66,
      nowEstEndTime:22.0,
      line: 'Line A',
      sn: 'DE1BH01QL004 (A)',
      product: 'RLOD-PBH0014C0G-44',
      side: 'A',
      targetOutput: 1200,
      input: 999,
      output: 873,
      achieveRate: 97.30,
      moAchieveRate:66.68,
      nowEfficiency:50,
      standardTime: 10.0,
      actProductionTime:16.0,
      actualTime: 10.2,
      estEndTime: 1634092810384,
      efficiency: 1.060,
      equips: [
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 1,
          name: 'CM2070-2',
        },
        {
          status: 2,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-33',
        },
      ]
    },
    {
      routeCode:'123',
      groupName: '456',
      inGroup: '789',
      section: 'TEST',
      outGroup: '12',
      nowInput:99,
      moInput:88,
      nowOutput:86,
      moOutput:66,
      nowEstEndTime:22.0,
      line: 'Line B',
      sn: 'DE1BH01QL006 (B)',
      product: 'RLOD-PBH0014C0G-66',
      side: 'B',
      targetOutput: 888,
      input: 999,
      output: 873,
      achieveRate: 97.30,
      moAchieveRate:66.68,
      nowEfficiency:90,
      standardTime: 10.0,
      actProductionTime:16.0,
      actualTime: 10.2,
      estEndTime: 1634092810384,
      efficiency: 1.060,
      equips: [
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 1,
          name: 'CM2070-2',
        },
        {
          status: 2,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-33',
        },
      ]
    },
    {
      routeCode:'123',
      groupName: '456',
      inGroup: '789',
      section: 'TEST',
      outGroup: '12',
      nowInput:99,
      moInput:88,
      nowOutput:86,
      moOutput:66,
      nowEstEndTime:22.0,
      line: 'Line C',
      sn: 'DE1BH01QL002 (C)',
      product: 'RLOD-PBH0014C0G-41',
      side: 'C',
      targetOutput: 666,
      input: 333,
      output: 873,
      achieveRate: 97.30,
      moAchieveRate:66.68,
      nowEfficiency:110,
      standardTime: 10.0,
      actProductionTime:16.0,
      actualTime: 10.2,
      estEndTime: 1634092810384,
      efficiency: 1.060,
      equips: [
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 1,
          name: 'CM2070-2',
        },
        {
          status: 2,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-2',
        },
        {
          status: 0,
          name: 'CM2070-33',
        },
      ]
    }
  ];

  openPopup = false;
  popupMode = '1'; // 0 default, 1 pin, 2 cancel

  timerFn;
  isSaveSec;
  choiceSecShow;
  swapSecArr = [
    {
      secNum: 'Stop',
      isActive: false,
    },
    {
      secNum: 30,
      isActive: false,
    },
    {
      secNum: 60,
      isActive: false,
    },
    {
      secNum: 180,
      isActive: false,
    }
  ];

  // 選到的 Name.toString()
  apiEncodeFilterStr: string;
  apiEncodeFilterStackStr: string;

  // 搜集選到的 Name
  // selectSectionNameArr = [
  //   {
  //     section: 'SMT', line: 'SMT-A'
  //   },
  //   {
  //     section: 'SMT', line: 'SMT-B'
  //   },
  //   {
  //     section: 'SMT', line: 'SMT-C'
  //   },
  //   {
  //     section: 'PKG', line: 'PACK-A'
  //   }
  // ];
  selectSectionNameArr = [];
  selectSectionNameStackArr = []; // stack 暫存狀態用
  // selectSectionNameArr = [ {section: 'SMT,line: SMT-A},{ section: SMT, line: SMT - B }]

  viewStackLineNameArr = []; // stack 暫存狀態用
  viewLineNameStatusArr = [
    {
      section: 'SMT',
      sectionSelected: false,
      lines: [
        {
          lineName: 'SMT-AASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-DASY-CASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-A',
          isChecked: false,
        },
        {
          lineName: 'SMT-B',
          isChecked: false,
        },
        {
          lineName: 'SMT-C',
          isChecked: false,
        },
        {
          lineName: 'SMT-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'Loaing',
      sectionSelected: false,
      lines: [
        {
          lineName: 'LOD-A',
          isChecked: false,
        },
        {
          lineName: 'LOD-BASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'LOD-C',
          isChecked: false,
        },
        {
          lineName: 'LOD-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'ASY',
      sectionSelected: false,
      lines: [
        {
          lineName: 'ASY-A',
          isChecked: false,
        },
        {
          lineName: 'ASY-BASY-CASY-CASY-CASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
        {
          lineName: 'ASY-C',
          isChecked: false,
        },
        {
          lineName: 'ASY-D',
          isChecked: false,
        },
      ]
    },
    {
      section: 'PKG',
      sectionSelected: false,
      lines: [
        {
          lineName: 'PKG-A',
          isChecked: false,
        },
        {
          lineName: 'PKG-B',
          isChecked: false,
        },
        {
          lineName: 'PKG-C',
          isChecked: false,
        },
        {
          lineName: 'PKG-D',
          isChecked: false,
        },
      ]
    }
  ];


  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    public dialog: MatDialog,
    // route
    private route: ActivatedRoute,
    private router: Router,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {
    this.isSvLoading.loading = false;
  }

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.subject.next();
  }

  private rxTimerStart() {
    // 計時器 intervalSeconds 秒數 Call API 更新資料
    return this.timerFn = timer(0, this.intervalSeconds * 1000).pipe(
      takeUntil(this.subject)
    ).subscribe(
      (t) => {
        console.log(`計時器 sec => ${this.intervalSeconds} , `, t, '次數');
        // Call API
        this.checkSaveFilterLineNameArrLength();
        // console.log('計時器 －－－', this.apiEncodeFilterStr);
        this.getAllApiFn(this.apiEncodeFilterStackStr, this.nowPageNum, 3);
      }
    );
  }

  ngOnInit(): void {
    // this.getAllSectionName$().subscribe();
    // this.clickWipTitle('SMT');
    // this.getAllApiFn(null, 1, 3);
    this.swapSecs(30);
  }

  ngOnDestroy() {
    this.timerFn.unsubscribe();
  }

  // Auto Refresh open/close
  switchPageAutoRefresh() {
    this.pageAutoRefresh = !this.pageAutoRefresh;
    console.log('this.pageAutoRefresh:', this.pageAutoRefresh);
    const isStopRefresh = !this.pageAutoRefresh;
    const isOpenRefresh = this.pageAutoRefresh;
    if (isStopRefresh) { this.rxTimerStop(); }
    if (isOpenRefresh) { this.rxTimerStart(); }
  }

  // 設定計時 & Call API
  secTimer(sec) {
    this.isSaveSec = sec;
    console.log('this.isSaveSec:', this.isSaveSec);
    const isSecStop = sec === 'Stop';
    const isSecSwitch = sec !== 'Stop';

    if (isSecStop) {
      console.log('sec:', sec);
      this.pageAutoRefresh = true;
      this.rxTimerStart();
      return;
    }

    if (isSecSwitch) {
      this.timerFn = timer(0, sec * 1000).pipe(
        takeUntil(this.subject)
      ).subscribe(
        (t) => {
          console.log(`計時器 sec => ${sec} ,`, t, '次數');
          // this.rxTimerStop();
          this.swapPageNum('+1');
          this.slidePageNum(this.nowPageNum);

          // this.saveIsCheckLineNameStatus('Yes');
          // this.openPopup = false;
          // Call API
          this.checkSaveFilterLineNameArrLength();
          // console.log('計時器 －－－', this.apiEncodeFilterStr);
          // this.getAllApiFn(this.apiEncodeFilterStr, this.nowPageNum, 3);
          this.getAllApiFn(this.apiEncodeFilterStackStr, this.nowPageNum, 3);
        }
      );
    }
  }

  // 切換秒數
  swapSecs(sec) {
    for (const item of this.swapSecArr) {
      if (sec === item.secNum) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    }
    this.rxTimerStop();

    console.log('swapSecs to sec:', sec);
    this.secTimer(sec);
  }

  // 分頁切換
  swapPageNum(str) {
    if (str === '+1') { this.nowPageNum++; }
    if (str === '-1') { this.nowPageNum--; }
  }

  // 確認輪播分頁
  slidePageNum(num) {
    if (num > this.isTotalPageNum) { this.nowPageNum = 1; }
    // if (num < 0) { this.nowPageNum = 1; }
    console.log('num:', num);
    console.log('this.nowPageNum:', this.nowPageNum);
  }

  // 檢查當前頁數 有沒有 大於總頁數／大於總頁數時顯示最後一頁
  checkPageNum() {
    // 目前頁數大於總頁數，顯示最後一頁
    if (this.nowPageNum > this.isTotalPageNum) { this.nowPageNum = this.isTotalPageNum; }
  }

  // 檢查 efficiency &  Add class color
  efficiencyFontColor(efficiency) {
    if (!efficiency) { return; }
    // efficiency = efficiency.replace(/[,]+/g, '');  // 移除千分位符號
    if (efficiency === '-') { return '#fff'; }
    else if (efficiency < 98) { return '#ff3a3a'; }  // font red
    else if (efficiency > 102) { return '#ffe500'; }  // font yellow
    else { return '#fff'; }
    // return '#ffe500';
  }

  // Efficiency pop-up event
  onClickEfficiency(element) {
    console.log('onClickEfficiency: ', element);
    this.dialog.open(Phase3EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '920px',
      maxHeight: 'calc(100vh, 32px)',
      data: {
        efficiency: element.efficiency,
        cycleTime: element.cycleTime,
        output: element.moOutput === null ? '-' : element.moOutput,
        stdLineChangeTime: element.stdLineChangeTime,
        operator: element.operator,
        isOperatorMes: element.isOperatorMes,

        durationList: element.details[0].durationList,
        restTimeInAct: element.details[0].restTimeInAct,
        restTimeInChangeover: element.details[0].restTimeInChangeover,
        changeOverList: element.details[0].changeOverList,
        nonProdTimeListByAct: element.details[0].nonProdTimeListByAct,
        nonProdTimeListByChangeover: element.details[0].nonProdTimeListByChangeover,
        rdProduction: element.details[0].rdProduction,
        nonBarcodeProduction: element.details[0].nonBarcodeProduction,
      },
    });
  }

  // 檢查 estEndTime &  Add class color
  estEndTimeFontColor(estEndTime) {
    if (!estEndTime) { return; }
    if (estEndTime === '-') { return '#fff'; }
    const nowTime = new Date().getTime();
    return nowTime > estEndTime ? '#ff3a3a' : '#fff';
  }

  mappingEquipStatus(arr) {
    // equipsLightsObj = {
    //   // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
    //   0: 'green',
    //   1: 'yellow',
    //   2: 'red',
    // };
    return arr.map((item) => ({
      ...item,
      status: this.equipsLightsObj[item.status]
    }));
  }

  // 暫時顯示的 selectCheckbox 狀態, 按下 yes 才真正更新／ no 不更新
  selectCheckbox(section, line) {
    console.log('section, line:', section, line);

    // click 切換 checkbox 狀態 true/false
    this.viewLineNameStatusArr.map((x) => {
      x.lines.map((item) => {
        // 改變 checkbox 狀態 true/false
        if (x.section === section && item.lineName === line) {
          item.isChecked = !item.isChecked;
        }
      });
    });

    // isChecked true => push 進 selectSectionNameArr / push 前清空陣列
    this.selectSectionNameArr = [];
    this.viewLineNameStatusArr.map((x) => {
      x.lines.map((item) => {
        if (item.isChecked) {
          this.selectSectionNameArr.push({
            section: x.section,
            line: item.lineName
          });
        }
      });
    });
  }

  // 暫存目前 勾選狀態
  saveIsCheckLineNameStatus(str) {
    if (str === 'Yes') {
      this.nowPageNum = 1;
      this.viewStackLineNameArr = JSON.parse(JSON.stringify(this.viewLineNameStatusArr));
      this.selectSectionNameStackArr = JSON.parse(JSON.stringify(this.selectSectionNameArr));
    }
    if (str === 'cancel') {
      this.viewLineNameStatusArr = JSON.parse(JSON.stringify(this.resLineNameArr));
      this.selectSectionNameArr = JSON.parse(JSON.stringify(this.selectSectionNameStackArr));
    }
  }

  // mapping lineName checkbox
  mappingLineNameCheckbox(arr) {
    this.resLineNameArr = [];

    // 給 線別加狀態 line checked
    this.resLineNameArr = arr.map((x) => {
      x.lines = x.lines.map((item) => {
        let includeLineName = null;
        // 與目前暫存的 checked狀態 重新比對
        includeLineName = this.selectSectionNameArr.some(i => x.section === i.section && item === i.line);
        const obj = {
          lineName: item,
          isChecked: includeLineName,
        };
        return obj;
      });
      return x;
    });
    // 給 section加狀態 line checked
    console.log('API回傳線別陣列:', this.resLineNameArr);

    // deepCopyArr
    this.viewLineNameStatusArr = JSON.parse(JSON.stringify(this.resLineNameArr));
    this.viewStackLineNameArr = JSON.parse(JSON.stringify(this.viewLineNameStatusArr));
  }

  // Encode 判斷選到的 Name 陣列有沒有資料,
  checkSaveFilterLineNameArrLength() {
    this.apiEncodeFilterStr = this.selectSectionNameArr.length === 0 ? null : JSON.stringify(this.selectSectionNameArr);
    this.apiEncodeFilterStackStr = this.selectSectionNameStackArr.length === 0 ? null : JSON.stringify(this.selectSectionNameStackArr);
  }


  // Pipe 資料處理
  pipeTranslateInfo(arr) {
    const deepCopyArr = JSON.parse(JSON.stringify(arr));
    deepCopyArr.map((x) => {
      x.totalItems = x.equips.length;
      if (x.equips === '-') {
        x.equips = [
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          },
          {
            status: '-',
            name: '-',
          }
        ];
      }
      if (x.equips !== '-') {
        x.equips = this.mappingEquipStatus(x.equips);
        // console.log('x.equips:', x.equips);
      }
      if (x.targetOutput !== '-') {
        x.targetOutput = this.numberPipe.transform(x.targetOutput);
      }
      if (x.nowInput !== '-') {
        x.nowInput = this.numberPipe.transform(x.nowInput);
      }
      if (x.moInput !== '-') {
        x.moInput = this.numberPipe.transform(x.moInput);
      }
      if (x.nowOutput !== '-') {
        x.nowOutput = this.numberPipe.transform(x.nowOutput);
      }
      if (x.moOutput !== '-') {
        x.moOutput = this.numberPipe.transform(x.moOutput);
      }
      if (x.achieveRate !== '-') {
        x.achieveRate = this.numberPipe.transform(x.achieveRate * 100, '0.2-2');
      }
      if (x.moAchieveRate !== '-') {
        x.moAchieveRate = this.numberPipe.transform(x.moAchieveRate * 100, '0.2-2');
      }
      if (x.standardTime !== '-') {
        x.formattedStandardTime = this.dataFormatSvc.secToDDhhmmss(x.standardTime);
      }
      if (x.actProductionTime !== '-') {
        // x.actProductionTime = this.numberPipe.transform(x.actProductionTime / 60, '0.1-1');
        x.formattedActProductionTime = this.dataFormatSvc.secToDDhhmmss(x.actProductionTime);
      }
      // if (x.nowEstEndTime !== '-') {
      //   x.nowEstEndTime = this.datePipe.transform(new Date(x.nowEstEndTime), 'yyyy/M/dd HH:mm:ss');
      // }
      // if (x.estEndTime !== '-') {
      //   x.estEndTime = this.datePipe.transform(new Date(x.estEndTime), 'yyyy/M/dd HH:mm:ss');
      // }
      if (x.nowEfficiency !== '-') {
        x.nowEfficiency = this.numberPipe.transform(x.nowEfficiency * 100, '0.2-2');
      }
      if (x.efficiency !== '-') {
        x.efficiency = this.numberPipe.transform(x.efficiency * 100, '0.2-2');
      }
    });
    return deepCopyArr;
  }

  // API get all sectionName
  getAllSectionName$() {
    return this.apiSvc.getSectionData().pipe(
      tap(res => {
        // console.log('getAllSectionName row data res:', res);
        this.sectionNameArr = [];
        res.map((x) => {
          this.sectionNameArr.push({
            sectionName: x,
            isActive: false,
          });
        });
      }),
    );
  }

  // API get all lineName
  getAllLineName$() {
    return this.apiSvc.getAllLineNameData().pipe(
      tap(res => {
        console.log('getAllLineName row data res:', res);
        this.isSvLoading.loading = true;
      }),
    );
  }

  // API Card Data
  getCardData$(section, offset, limit) {
    return this.apiSvc.getEquipOverviewData(section, offset, limit).pipe(
      tap(res => {
        // console.log('getCardData row data res:', res);
      }),
    );
  }

  // API All
  getAllApiFn(section, offset, limit) {
    this.renewedTime = moment().valueOf();
    return this.getAllLineName$().pipe(
      tap((res) => {
        // res = JSON.parse(JSON.stringify(this.forTestDataSvc.allLinesArr));
        this.mappingLineNameCheckbox(res);
      }),
      switchMap((res) => this.getCardData$(section, offset, limit)),
    ).subscribe((res: EquipEfficiencyOverview) => {
      console.log('getAllApiFn res:', res);
      this.snapshotTime = this.renewedTime;
      if (res.totalPage === 0) { res.totalPage++; }

      if (res.infos.length !== 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        this.resItemDataArr = this.dataFormatSvc.nullToDash(this.resItemDataArr);
        this.resItemDataArr = this.pipeTranslateInfo(this.resItemDataArr);
      }

      if (res.infos.length === 0) {
        this.resItemDataArr = res.infos;
        this.isTotalPageNum = res.totalPage;
        this.checkPageNum();
        // this.resItemDataArr
        console.log('this.resItemDataArr:', this.resItemDataArr);
      }

      this.isSvLoading.loading = false;
    });
  }

  reloadCurrentPageClick() {
    this.getAllApiFn(this.apiEncodeFilterStackStr, this.nowPageNum, 3);
  }

}

@Component({
  selector: 'app-efficiency-api-data-dialog',
  templateUrl: './dialog/app-efficiency-api-data-dialog.component.html',
  styleUrls: ['./dialog/app-efficiency-api-data-dialog.component.scss']
})
export class Phase3EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent implements OnInit {
  constructor(
    public dataFormatSvc: DataFormatService,
    public dialogRef: MatDialogRef<Phase3EquipmentEfficiencyOverviewEfficiencyApiDataDialogComponent>,
    public dialogEmployee: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EfficiencyApiData,
  ) { }

  EfficiencyData: EfficiencyApiData = {
    efficiency: null,
    cycleTime: null,
    output: null,
    stdLineChangeTime: null,
    operator: null,
    isOperatorMes: null,

    durationList: null,
    restTimeInAct: null,
    restTimeInChangeover: null,
    changeOverList: null,
    nonProdTimeListByAct: null,
    nonProdTimeListByChangeover: null,
    rdProduction: null,
    nonBarcodeProduction: null,
  };

  totalDurationTime = 0;
  totalRestTimeInAct = 0;
  totalRestTimeInChangeover = 0;
  totalChangeOverTime = 0;
  totalNonProdTimeTimeByAct = 0;
  totalNonProdTimeTimeByChangeover = 0;
  totalRdProductionTime = 0;
  totalNonBarcodeProductionTime = 0;

  ngOnInit() {
    this.EfficiencyData = this.data;

    if (this.EfficiencyData.durationList.length > 0) {
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.durationList = this.EfficiencyData.durationList.filter(item => item.duration !== null);
      this.totalDurationTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.durationList);
    }
    if (this.EfficiencyData.restTimeInAct.length > 0) {
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInAct = this.EfficiencyData.restTimeInAct.filter(item => item.duration !== null);
      this.totalRestTimeInAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInAct);
    }
    if (this.EfficiencyData.restTimeInChangeover.length > 0) {
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.restTimeInChangeover = this.EfficiencyData.restTimeInChangeover.filter(item => item.duration !== null);
      this.totalRestTimeInChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.restTimeInChangeover);
    }
    if (this.EfficiencyData.changeOverList.length > 0) {
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.changeOverList = this.EfficiencyData.changeOverList.filter(item => item.duration !== null);
      this.totalChangeOverTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.changeOverList);
    }
    if (this.EfficiencyData.nonProdTimeListByAct.length > 0) {
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByAct = this.EfficiencyData.nonProdTimeListByAct.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByAct = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByAct);
    }
    if (this.EfficiencyData.nonProdTimeListByChangeover.length > 0) {
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonProdTimeListByChangeover = this.EfficiencyData.nonProdTimeListByChangeover.filter(item => item.duration !== null);
      this.totalNonProdTimeTimeByChangeover = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonProdTimeListByChangeover);
    }
    if (this.EfficiencyData.rdProduction.length > 0) {
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.rdProduction = this.EfficiencyData.rdProduction.filter(item => item.duration !== null);
      this.totalRdProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.rdProduction);
    }
    if (this.EfficiencyData.nonBarcodeProduction.length > 0) {
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.map(item => ({
        ...item,
        duration: this.dataFormatSvc.getDuration(item)
      }));
      this.EfficiencyData.nonBarcodeProduction = this.EfficiencyData.nonBarcodeProduction.filter(item => item.duration !== null);
      this.totalNonBarcodeProductionTime = this.dataFormatSvc.getDurationTotal(this.EfficiencyData.nonBarcodeProduction);
    }
  }
}
