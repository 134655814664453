import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, } from 'rxjs/operators';

// service
import { ApiService } from 'src/app/_services/apiSvc.service';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';
import { interval } from 'rxjs';



@Component({
  selector: 'app-equip-efficiency',
  templateUrl: './equip-efficiency.component.html',
  styleUrls: ['./equip-efficiency.component.scss']
})
export class EquipEfficiencyComponent implements OnInit, OnDestroy, AfterViewInit {

  // 計時器 60秒 Call API 更新資料
  rxTimer$ = interval(60000).subscribe((data) => {
    this.isSvLoading.loading = true;
    this.clearArrData();
    // console.log('60秒 Call API:', this.orderInfoObj);
    this.apiGetEquipEfficiencyInfo$(this.orderInfoObj).subscribe();
  });

  orderInfoObj = {
    Section: null,
    Line: null,
    Sn: null,
    Side: null
  };

  resInfo = {
    sn: null,
    product: null,
    startTime: null,
    actProductionTime: null,
    line: null,
    side: null,

    targetOutput: null,
    input: null,
    output: null,
    achieveRate: null,
    outYield: null,
    efficiency: null,

    equipInfo: null,
  };


  tableInfo = {
    cycleTimeValArr: [],
    standardTimeValArr: [],
    equipEfficiencyValArr: [],

    tableEquipNameArr: [],
  };

  tableDataArr = [
    {
      name: 'Real cycle Time',
      equipValArr: this.tableInfo.cycleTimeValArr,
    },
    {
      name: 'Standard Cycle Time(IE)',
      equipValArr: this.tableInfo.standardTimeValArr,
    },
    {
      name: 'Efficiency',
      equipValArr: this.tableInfo.equipEfficiencyValArr,
    },
  ];

  whenTableResEmptyData = [
    {
      equip: '-',
      status: '-',
      cycleTime: '-',
      standardTime: '-',
      equipEfficiency: '-',
    },
    {
      equip: '-',
      status: '-',
      cycleTime: '-',
      standardTime: '-',
      equipEfficiency: '-',
    },
    {
      equip: '-',
      status: '-',
      cycleTime: '-',
      standardTime: '-',
      equipEfficiency: '-',
    },
    {
      equip: '-',
      status: '-',
      cycleTime: '-',
      standardTime: '-',
      equipEfficiency: '-',
    },
    {
      equip: '-',
      status: '-',
      cycleTime: '-',
      standardTime: '-',
      equipEfficiency: '-',
    }
  ];

  // testData = {
  //   sn: 'CE8VG04010',
  //   product: 'VRG004-030G',
  //   startTime: null,
  //   actProductionTime: 283921,
  //   line: 'SMT-C',
  //   side: '',
  //   targetOutput: 132,
  //   input: 103,
  //   output: null,
  //   achieveRate: 1,
  //   outYield: 1.2815,
  //   efficiency: 0.61,
  //   equipInfo: [
  //     {
  //       equip: 'CM3010',
  //       status: 'RUNNING',
  //       cycleTime: null,
  //       standardTime: 129345,
  //       equipEfficiency: 0.88
  //     },
  //     {
  //       equip: null,
  //       status: 'ALARM',
  //       cycleTime: 99999,
  //       standardTime: 123456,
  //       equipEfficiency: 0.85
  //     },
  //     {
  //       equip: 'SPI',
  //       status: 'RUNNING',
  //       cycleTime: 92348,
  //       standardTime: 99111,
  //       equipEfficiency: 0.90
  //     }
  //   ]
  // };


  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,

    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,

    private router: Router,
    private rout: ActivatedRoute,

  ) {
    this.isSvLoading.loading = true;
    // 直接進入 Mo Quality 導回 Quality Overview
    // if (this.moSvc.order === null) {
    //   this.router.navigate(['/smartFactory/web/quality-overview']);
    // }
  }

  ngOnInit(): void {
    this.orderInfoObj.Section = this.rout.snapshot.queryParamMap.get('section');
    this.orderInfoObj.Line = this.rout.snapshot.queryParamMap.get('line');
    this.orderInfoObj.Sn = this.rout.snapshot.queryParamMap.get('sn');
    this.orderInfoObj.Side = this.rout.snapshot.queryParamMap.get('side');

    console.log('this.Section:', this.orderInfoObj.Section);

    this.apiGetEquipEfficiencyInfo$(this.orderInfoObj).subscribe();

    // setTimeout(() => {
    //   this.isSvLoading.loading = false;
    // }, 0);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.rxTimer$.unsubscribe();
  }

  clearArrData() {
    this.tableInfo.tableEquipNameArr = [];
    this.tableInfo.cycleTimeValArr = [];
    this.tableInfo.standardTimeValArr = [];
    this.tableInfo.equipEfficiencyValArr = [];
  }

  // 回傳燈號顏色
  // 0:running 綠色 , 1:Idle 黃色 , 2:Alarm 紅色
  checkEquipLightColor(num) {
    if (+num === 0) { return 'equip_light_running'; }
    if (+num === 1) { return 'equip_light_idle'; }
    if (+num === 2) { return 'equip_light_alarm'; }
  }

  // Pipe 資料處理
  pipeTranslateInfo(objData: any) {
    console.log('objData:', objData);

    const copyData = objData;

    if (copyData.startTime !== '-') {
      copyData.startTime = this.dataFormatSvc.pipeDate(copyData.startTime, 'yyyy/M/dd HH:mm');
    }
    if (copyData.actProductionTime !== '-') {
      copyData.actProductionTime = this.dataFormatSvc.secToDDhhmmss(copyData.actProductionTime);
    }

    if (copyData.targetOutput !== '-') {
      copyData.targetOutput = this.dataFormatSvc.pipeNumber(copyData.targetOutput, '');
    }
    if (copyData.input !== '-') {
      copyData.input = this.dataFormatSvc.pipeNumber(copyData.input, '');
    }
    if (copyData.output !== '-') {
      copyData.output = this.dataFormatSvc.pipeNumber(copyData.output, '');
    }

    if (copyData.achieveRate !== '-') {
      copyData.achieveRate = this.dataFormatSvc.pipeNumber(+(copyData.achieveRate * 100), '0.2-2');
    }
    if (copyData.outYield !== '-') {
      copyData.outYield = this.dataFormatSvc.pipeNumber(+(copyData.outYield * 100), '0.2-2');
    }
    if (copyData.efficiency !== '-') {
      copyData.efficiency = this.dataFormatSvc.pipeNumber(+(copyData.efficiency * 100), '0.2-2');
    }

    if (copyData.equipInfo === '-') {
      copyData.equipInfo = this.whenTableResEmptyData;
    }

    if (copyData.equipInfo !== '-') {
      copyData.equipInfo.map((x) => {
        if (x.cycleTime !== '-') {
          x.cycleTime = this.dataFormatSvc.secToDDhhmmss(x.cycleTime);
        }
        if (x.standardTime !== '-') {
          x.standardTime = this.dataFormatSvc.secToDDhhmmss(x.standardTime);
        }
        if (x.equipEfficiency !== '-') {
          x.equipEfficiency = this.dataFormatSvc.pipeNumber(+(x.equipEfficiency * 100), '0.2-2');
        }

        return x;
      });
    }

    console.log('copyData:', copyData);
    return copyData;
  }

  // 設定 Table 資料
  createTableData(arr) {
    const copyData = arr;
    copyData.map((x) => {
      this.tableInfo.tableEquipNameArr.push({ equipName: x.equip, status: x.status });

      this.tableInfo.cycleTimeValArr.push(x.cycleTime);
      this.tableInfo.standardTimeValArr.push(x.standardTime);
      this.tableInfo.equipEfficiencyValArr.push(x.equipEfficiency);
    });
    return copyData;
  }

  // API Get Data
  apiGetEquipEfficiencyInfo$(orderInfoObj) {
    const section = this.orderInfoObj.Section;
    const sn = this.orderInfoObj.Sn;
    const line = this.orderInfoObj.Line;
    const side = this.orderInfoObj.Side;
    return this.apiSvc.getEquipEfficiencyData(section, sn, line, side).pipe(
      tap((res) => {
        const tableHaveData = res.equipInfo !== null;
        console.log('apiGetEquipEfficiencyInfo res:', res);

        if (tableHaveData) { res.equipInfo = this.dataFormatSvc.nullToDash(res.equipInfo); }
        this.dataFormatSvc.objValueNulltoDash(res);
        this.pipeTranslateInfo(res);

        this.resInfo = { ...res };
        console.log('this.resInfo :', this.resInfo);
        this.createTableData(this.resInfo.equipInfo);

        this.isSvLoading.loading = false;
      }),
    );
  }

}
