<div class="outer-container">
  <div class="container-title">
    <div class="topic">
      <h1 class="mat-title color-silver title">
        <!-- <button class="logo-btn" title="logo">
          <img
            routerLink="/smartFactory/web/main-board"
            src="../../assets/img/homeico.png"
            alt="logo"
          />
        </button> -->
        <button class="back-btn" title="back">
          <img
            src="../../assets/img/smart_factory/btn_back.svg"
            alt="arrow"
            [routerLink]="['/smartFactory/web/production-overview-management']"
          />
        </button>
        TEST Efficiency
      </h1>
    </div>
    <div style="display: flex">
      <div
        class="mat-body-2 color-white date-filter"
        style="margin-right: 45px"
      >
        <span class="mat-body-1 color-silver">Date Filter:</span>
        <div class="date_group">
          <p class="date_value">
            {{
              range.value.start === null
                ? "- - -"
                : (range.value.start | date: "YYYY/MM/dd")
            }}
          </p>
          <span class="date_span"> ~ </span>
          <p class="date_value">
            {{
              range.value.end === null
                ? "- - -"
                : (range.value.end | date: "YYYY/MM/dd")
            }}
          </p>
        </div>

        <mat-form-field appearance="fill">
          <mat-label>Enter a date range</mat-label>

          <mat-date-range-input
            [formGroup]="range"
            [min]="minDate"
            [max]="maxDate"
            [dateFilter]="familyDayFilter"
            [rangePicker]="picker"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="getRangeDate(range.value.start, range.value.end)"
            />
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker">
            <!-- <mat-icon matDatepickerToggleIcon>
                <img src="../../../assets/img/btn_table_column.svg" />
              </mat-icon> -->
          </mat-datepicker-toggle>

          <mat-date-range-picker
            #picker
            startView="month"
            [startAt]="startDate"
          ></mat-date-range-picker>
        </mat-form-field>

        <img
          class="default_button"
          src="../../../assets/img/btn_date_default.svg"
          alt="Group"
          (click)="setDefaultRangeDate()"
          style="margin-left: 12px"
        />
      </div>
      <div class="mat-subheading-2 color-silver current-time">
        <p>
          {{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}
        </p>
        <button
          class="menu-btn"
          title="menu"
          (click)="isMenuOpen = !isMenuOpen"
        >
          <img src="../../assets/img/btn_menu.svg" alt="menu" />
        </button>
        <div class="login-menu" *ngIf="isMenuOpen">
          <button routerLink="/menu">
            <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
            Dashboard Menu
          </button>
          <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
            <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
          </button>
          <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
            <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign
            out
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="apiSvc.ifSSO('Administrator,1E-Editor'); else PermissionDenied"
  >
    <div class="container-mo">
      <div class="mo-bar-title">
        <span class="mat-subheading-1 color-silver title">Part No.</span>
        <span class="mat-body-1 color-white value">{{
          dataFormatSvc.checkValDash(apiResponseObj?.product)
        }}</span>
      </div>
      <div class="mo-bar-title">
        <span class="mat-subheading-1 color-silver title">MO No.</span>
        <span class="mat-body-1 color-white value">{{
          dataFormatSvc.checkValDash(apiResponseObj?.sn)
        }}</span>
      </div>
      <div class="mo-bar-title">
        <span
          class="mat-subheading-1 color-silver title"
          matTooltipPosition="below"
          [matTooltip]="'Start Time'"
          >Start T.</span
        >
        <span class="mat-body-1 color-white value">{{
          apiResponseObj?.startTime === null
            ? "-"
            : (apiResponseObj?.startTime | date: "yyyy/MM/dd HH:mm")
        }}</span>
      </div>
      <div class="mo-bar-title">
        <span class="mat-subheading-1 color-silver title">MO Qty.</span>
        <span class="mat-body-1 color-white value">{{
          dataFormatSvc.checkValDash(apiResponseObj?.targetOutput)
        }}</span>
      </div>
      <div class="mo-bar-title">
        <span class="mat-subheading-1 color-silver title">Output Qty.</span>
        <span class="mat-body-1 color-white value">{{
          dataFormatSvc.checkValDash(apiResponseObj?.output)
        }}</span>
      </div>
      <div class="mo-bar-title">
        <span class="mat-subheading-1 color-silver title">Achieve Rate</span>
        <span class="mat-body-1 color-white value"
          >{{
            apiResponseObj?.achieveRate === null
              ? "-"
              : (apiResponseObj?.achieveRate * 100 | number: "0.2-2")
          }}%</span
        >
      </div>
    </div>

    <div class="container-table">
      <div class="mo-tab-group">
        <input
          checked
          type="radio"
          name="radio-control"
          id="radio1"
          class="penal-control"
        />
        <!-- <input
          type="radio"
          name="radio-control"
          id="radio2"
          class="penal-control"
        /> -->
        <div class="mat-body-1 color-silver tab-group">
          <label for="radio1" class="active">Station Performance</label>
          <!-- <label for="radio2">tab2</label> -->
          <div class="content-group">
            <div class="content content1 penal-control">
              <!-- API 回傳沒資料 -->
              <div class="no-data" *ngIf="!tableDataSource">
                <span>No Data</span>
              </div>
              <div class="mat-table-container" *ngIf="tableDataSource">
                <mat-table
                  [dataSource]="tableDataSource"
                  class="mat-body-2 color-silver mat-elevation-z8"
                >
                  <!-- Item Column -->
                  <ng-container matColumnDef="itemName">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 1.3"
                      >Item</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 1.3">
                      {{ dataFormatSvc.checkValDash(element?.groupname) }}
                    </mat-cell>
                  </ng-container>

                  <!-- Equipment Qty Column -->
                  <ng-container matColumnDef="equipmentQty">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 1.25"
                      >Equipment Qty. / Avail Equip Qty.</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 1.25">
                      {{ dataFormatSvc.checkValDash(element?.equipmentQty) }}/{{
                        dataFormatSvc.checkValDash(element?.availEquipQty)
                      }}
                    </mat-cell>
                  </ng-container>

                  <!-- Start Time Column -->
                  <ng-container matColumnDef="startTime">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 1.15"
                      >Start Time</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 1.15">
                      {{
                        element?.startTime === null
                          ? "-"
                          : (element?.startTime | date: "MM/dd HH:mm")
                      }}
                    </mat-cell>
                  </ng-container>

                  <!-- To be tested Qty Column -->
                  <ng-container matColumnDef="toBeTestedQty">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.9"
                      >To Be Tested Qty.</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 0.9">
                      {{ dataFormatSvc.checkValDash(element?.toBeTestedQty) }}
                    </mat-cell>
                  </ng-container>

                  <!-- Output Qty(Pass) Column -->
                  <ng-container matColumnDef="outputQty">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.8"
                      >Output Qty. (Pass)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 0.8">
                      {{ dataFormatSvc.checkValDash(element?.outputQty) }}
                    </mat-cell>
                  </ng-container>

                  <!-- Fail Qty / Fail Report Column -->
                  <ng-container matColumnDef="failQty">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.85">
                      Fail Qty. / Fail Report
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex-grow: 0.85">
                      {{ dataFormatSvc.checkValDash(element?.failQty) }}/{{
                        dataFormatSvc.checkValDash(element?.failReport)
                      }}
                    </mat-cell>
                  </ng-container>

                  <!-- Efficiency Column -->
                  <ng-container matColumnDef="efficiency">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.9"
                      >Efficiency</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 0.9">
                      {{
                        element?.efficiency === null
                          ? "-"
                          : (element?.efficiency * 100 | number: "0.2-2")
                      }}%
                    </mat-cell>
                  </ng-container>

                  <!-- Cycle Time(IE) Column -->
                  <ng-container matColumnDef="cycleTime">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 0.8"
                      >Cycle Time (IE)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 0.8">
                      {{
                        element?.cycleTime === null
                          ? "-"
                          : dataFormatSvc.secToDDhhmmss(element?.cycleTime)
                      }}
                    </mat-cell>
                  </ng-container>

                  <!-- Avg Cycle Time Column -->
                  <ng-container matColumnDef="avgCycleTime">
                    <mat-header-cell *matHeaderCellDef style="flex-grow: 1"
                      >Avg. Cycle Time</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex-grow: 1">
                      {{
                        element?.avgCycleTime === null
                          ? "-"
                          : dataFormatSvc.secToDDhhmmss(element?.avgCycleTime)
                      }}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
            <!-- <div
              class="content content2 penal-control mat-title color-silver tab2"
            >
              No Data
            </div> -->
          </div>
        </div>
      </div>

      <div class="list-container">
        <h3 class="mat-body-1 color-white" style="margin-bottom: 35px">
          Station Performance
        </h3>
        <div
          class="dropdown-group"
          style="display: flex; justify-content: flex-end; margin-bottom: 30px"
        >
          <span
            class="mat-body-2 color-silver"
            style="margin-right: 15px; line-height: 40px"
            >Function</span
          >
          <select
            class="mat-body-2 color-white"
            style="background-color: #6c6c6c"
            (change)="switchEquipFunction($event.target.value)"
          >
            <option
              *ngIf="ateEquipFunction.length === 0"
              value=""
              disabled
              selected
              hidden
            >
              None
            </option>
            <option
              *ngFor="let item of ateEquipFunction; let idx = index"
              [selected]="idx === 0"
              [value]="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div
          style="
            max-height: calc(100vh - 530px);
            min-height: 413px;
            overflow-y: auto;
            padding-right: 2px;
          "
        >
          <div
            *ngIf="ateStationData.length === 0"
            style="
              display: flex;
              min-height: 413px;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="font-size: 40px; color: #bababa">No Data</div>
          </div>
          <div
            *ngIf="ateStationData.length !== 0"
            class="percent-bar-group"
            style="display: flex; flex-direction: column"
          >
            <div class="bar" *ngFor="let item of ateStationData">
              <div
                class="background-percent"
                *ngIf="item?.efficiency !== null"
                [style]="'width:' + [200 * item?.efficiency] + 'px'"
              ></div>
              <div class="left-group">
                <p class="station-name">
                  {{ dataFormatSvc.checkValDash(item?.stationName) }}
                </p>
              </div>
              <div class="right-group">
                <div class="info-group">
                  <p class="efficiency">
                    {{
                      item?.efficiency === null
                        ? "-"
                        : (item?.efficiency * 100 | number: "0.2-2")
                    }}%
                  </p>
                  <p class="working-time">
                    ({{ dataFormatSvc.checkValDash(item?.standardTime) }}/{{
                      dataFormatSvc.checkValDash(item?.actProdTime)
                    }})
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
