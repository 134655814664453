<div class="electricity">
  <div class="feature1">
    <!-- <div class="menu-bt" routerLink="/menu">
      <img src="../../assets/img/menu.svg" />
    </div> -->
    <div class="float-left">Power Consumption / kWh</div>
    <!-- <div class="float-right">樓層 : 1 樓</div> -->
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="container_box">
    <div class="feature2">
      <div class="chart-container">
        <!-- 圓餅圖 -->
        <div class="total">{{ +currentValue.All | number: "0.1-2" }}</div>
        <div>
          <canvas id="canvas_Pie" height="185vh">
            {{ powerConsumptionPie }}
          </canvas>
        </div>
        <div class="chart-container-info">
          <div class="padding">
            <p class="font-size-N">SMT</p>
            <p class="font-size-B">
              {{ +currentValue.L_P | number: "0.1-2" }}
              <span class="font-size-S float-right">%</span>
            </p>
            <div class="color-box color-box-Y"></div>
          </div>
          <div class="padding">
            <p class="font-size-N">AC System</p>
            <p class="font-size-B">
              {{ +currentValue.A_P | number: "0.1-2" }}
              <span class="font-size-S float-right">%</span>
            </p>
            <div class="color-box color-box-B"></div>
          </div>
          <div class="padding">
            <p class="font-size-N">ATE</p>
            <p class="font-size-B">
              {{ +currentValue.P_P | number: "0.1-2" }}
              <span class="font-size-S float-right">%</span>
            </p>
            <div class="color-box color-box-P"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sector_group">
      <div class="feature3">
        <p class="title">Consumption by Sector</p>
        <div class="type-info">
          <div class="type-item border-Y">
            <p class="font-size-N">SMT</p>
            <p class="font-size-B">{{ +currentValue.L | number: "0.1-2" }}</p>
            <p class="sector_unit">kWh</p>
          </div>
          <div class="type-item border-B">
            <p class="font-size-N">AC System</p>
            <p class="font-size-B">{{ +currentValue.A | number: "0.1-2" }}</p>
            <p class="sector_unit">kWh</p>
          </div>
          <div class="type-item border-P">
            <p class="font-size-N">ATE</p>
            <p class="font-size-B">{{ +currentValue.P | number: "0.1-2" }}</p>
            <p class="sector_unit">kWh</p>
          </div>
        </div>
      </div>

      <div class="feature4">
        <div class="float-left">History</div>
        <div class="float-right dayCtr">
          <div
            class="time-bt"
            [ngClass]="{ select: timeFrame == '1d' }"
            (click)="changeScale('1d')"
          >
            Day
          </div>
          <div
            class="time-bt"
            [ngClass]="{ select: timeFrame == '1w' }"
            (click)="changeScale('1w')"
          >
            Week
          </div>
          <div
            class="time-bt"
            [ngClass]="{ select: timeFrame == '1M' }"
            (click)="changeScale('1M')"
          >
            Month
          </div>
        </div>
      </div>

      <div class="feature5">
        <div class="chart-container">
          <canvas id="canvas_History" height="100">
            {{ allElectricityHistory }}
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
