
export enum SORT_KEY {
  partNo = 'PARTNO',
  moNo = 'MONO',
  status = 'STATUS',
  startTime = 'STARTTIME',
  endTime = 'ENDTIME',
  moQty = 'MOQTY',
  inputQty = 'INPUTQTY',
  defectQty = 'DEFECTQTY',
  avgrty = 'AVGRTY',
  lowestYield = 'LOWESTYIELD'
}

export enum SORT_DIRECTION {
  asc = '',
  desc = '-',
}
