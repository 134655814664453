<div class="container-fluid equipment_efficiency_overview">
  <!-- Top Menu side -->
  <div class="title_group">
    <!-- Title -->
    <div class="title_box">
      <button class="logo-btn" title="logo">
        <img
          routerLink="/smartFactory/web/main-board"
          src="../../assets/img/homeico.png"
          alt="logo"
        />
      </button>

      <h1 class="wip_overview_title">Production Efficiency Overview</h1>

      <!-- Select LineName Checkboxs 選單 -->
      <div class="filter_line" (click)="openPopup = true; popupMode = '1'">
        Filter Line
      </div>
    </div>

    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <button style="margin-left: 20px" (click)="isMenuOpen = !isMenuOpen">
        <img class="menu-bt" src="../../assets/img/btn_menu.svg" alt="menu" />
      </button>
      <div class="login-menu" *ngIf="isMenuOpen">
        <button routerLink="/menu">
          <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
          Dashboard Menu
        </button>
        <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
          <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
        </button>
        <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
          <img src="../../assets/img/btn_sign_out.svg" alt="signout" />Sign out
        </button>
      </div>
    </div>
  </div>

  <!-- Slide View -->
  <ng-container
    *ngIf="apiSvc.ifSSO('Administrator,1E-Editor'); else PermissionDenied"
  >
    <div class="container_box">
      <div
        class="row noData"
        [ngClass]="{ noDataShow: resItemDataArr.length === 0 }"
      >
        <p>No data.</p>
      </div>
      <div class="line_info_group" *ngIf="resItemDataArr.length !== 0">
        <div class="row line_box" *ngFor="let item of resItemDataArr">
          <!-- line Info box -->
          <div class="col-2 info_text_block">
            <!-- <div class="line_name">
              {{ item.processSection + " " + item.line }}
            </div> -->
            <!-- <div class="title">Section</div>
            <div class="title_value">{{ item.section }}</div> -->

            <div class="title">Line</div>
            <div
              *ngIf="dataFormatSvc.shouldShowLink(item.section)"
              [routerLink]="[dataFormatSvc.getSectionLink(item.section)]"
              [queryParams]="dataFormatSvc.getSectionParams(item)"
              class="card_link title_value"
            >
              {{ item.section }} / {{ item.line }}
            </div>
            <div
              *ngIf="!dataFormatSvc.shouldShowLink(item.section)"
              class="title_value"
            >
              {{ item.section }} / {{ item.line }}
            </div>

            <div class="title">MO No.</div>
            <div class="title_value">{{ item.sn }}</div>
            <div class="title">Part No.</div>
            <div class="title_value">
              {{ dataFormatSvc.checkValDash(item.product) }}
            </div>
            <div class="title">Current Side</div>
            <div class="title_value">
              {{ dataFormatSvc.checkValDash(item.side) }} Side
            </div>
            <div class="title">MO Qty</div>
            <div class="title_value">
              {{ dataFormatSvc.checkValDash(item.targetOutput) }}
            </div>
          </div>

          <!-- line point box-->
          <div class="col-10 line_point_block">
            <!-- Qty 數值 -->
            <div class="qty_group">
              <div class="box">
                <div class="hover_box c_point">
                  <p class="box_title">MO</p>
                  <p class="origin_name">MO Qty</p>
                </div>
                <p class="box_num">
                  {{ dataFormatSvc.checkValDash(item.targetOutput) }}
                </p>
              </div>
              <div class="box box_between">
                <div class="left_box">
                  <div class="hover_box c_point">
                    <p class="box_title">INP</p>
                    <p class="origin_name">Input Qty</p>
                  </div>
                  <p class="box_num">
                    {{ dataFormatSvc.checkValDash(item.nowInput) }}
                  </p>
                </div>
                <div class="right_box">
                  <div class="hover_box c_point">
                    <p class="box_title">(MO INP)</p>
                    <p class="origin_name">MO Input Qty</p>
                  </div>
                  <p class="box_num">
                    ({{ dataFormatSvc.checkValDash(item.moInput) }})
                  </p>
                </div>
              </div>
              <div class="box box_between">
                <div class="left_box">
                  <div class="hover_box c_point">
                    <p class="box_title">OUT</p>
                    <p class="origin_name">Output Qty</p>
                  </div>
                  <p class="box_num">
                    {{ dataFormatSvc.checkValDash(item.nowOutput) }}
                  </p>
                </div>
                <div class="right_box">
                  <div class="hover_box c_point">
                    <p class="box_title">(MO OUT)</p>
                    <p class="origin_name">MO Output Qty</p>
                  </div>
                  <p class="box_num">
                    ({{ dataFormatSvc.checkValDash(item.moOutput) }})
                  </p>
                </div>
              </div>
              <div class="box box_between">
                <div class="left_box">
                  <div class="hover_box c_point">
                    <p class="box_title">ACHV R.</p>
                    <p class="origin_name">Achieve Rate</p>
                  </div>
                  <p class="box_num">
                    {{ dataFormatSvc.checkValDash(item.achieveRate) }} %
                  </p>
                </div>
                <div class="right_box">
                  <div class="hover_box c_point">
                    <p class="box_title">(MO ACHV R.)</p>
                    <p class="origin_name">MO Achieve Rate</p>
                  </div>
                  <p class="box_num">
                    ({{ dataFormatSvc.checkValDash(item.moAchieveRate) }} %)
                  </p>
                </div>
              </div>
            </div>

            <!-- 表格 -->
            <div class="table_group">
              <!-- <div class="table_title_group">
                <div class="title title_hidden">-</div>
                <div class="title table_border">Cycle Time (s)</div>
                <div class="title">Utilization (%)</div>
              </div> -->
              <div class="table_item_group">
                <ng-container *ngIf="item.equips === '-'">
                  <ng-container *ngFor="let x of item.equips">
                    <div class="item_box">
                      <p class="item_title">
                        <span class="light"></span>
                        {{ x.name }}
                      </p>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="item.equips !== '-'">
                  <ng-container *ngFor="let x of item.equips">
                    <div
                      class="item_box"
                      [style]="'width:' + [100 / item.totalItems] + '%'"
                    >
                      <!-- <div class="item_box" style="width: 300px"> -->
                      <p class="item_title">
                        <!-- <span class="light light_yellow"></span> -->
                        <span
                          class="light"
                          [class]="'light_' + x.status"
                        ></span>
                        {{ x.name }}
                      </p>
                      <!-- <p class="item_num table_border">
                      {{ x.standardTime }}
                    </p>
                    <p class="item_num">
                      {{ x.utilization }}
                    </p> -->
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <!-- Qty 數值 -->
            <div class="qty_group">
              <div class="box">
                <div class="hover_box c_point">
                  <p class="box_title">STD Prod T. (IE)</p>
                  <p class="origin_name">Standard Time</p>
                </div>
                <p class="box_num">
                  {{ dataFormatSvc.checkValDash(item.formattedStandardTime) }}
                </p>
                <!-- <p class="origin_num">{{ item.standardTime + " s" }}</p> -->
              </div>
              <div class="box">
                <div class="hover_box c_point">
                  <p class="box_title">Act Prod T.</p>
                  <p class="origin_name">Act Prod Time</p>
                </div>
                <div class="hover_box c_point">
                  <p class="box_num">
                    {{
                      dataFormatSvc.checkValDash(
                        item.formattedActProductionTime
                      )
                    }}
                  </p>
                  <p class="origin_num">{{ item.actProductionTime + " s" }}</p>
                </div>
              </div>
              <div class="box" style="padding: 5px 2px">
                <div class="hover_box c_point">
                  <p class="box_title" style="margin-bottom: 5px">
                    Est. End T.
                  </p>
                  <p class="origin_name">Estimated End Time</p>
                </div>
                <p
                  class="box_fs_24"
                  [ngStyle]="{ color: estEndTimeFontColor(item.nowEstEndTime) }"
                >
                  {{
                    item.nowEstEndTime === "-" || null
                      ? "-"
                      : (item.nowEstEndTime | date: "yyyy/MM/dd HH:mm")
                  }}
                </p>
                <p
                  class="box_fs_24"
                  [ngStyle]="{ color: estEndTimeFontColor(item.estEndTime) }"
                >
                  ({{
                    item.estEndTime === "-" || null
                      ? "-"
                      : (item.estEndTime | date: "yyyy/MM/dd HH:mm")
                  }})
                </p>
              </div>
              <div
                class="box"
                style="padding: 5px 2px; cursor: pointer"
                (click)="onClickEfficiency(item)"
              >
                <div class="hover_box c_point">
                  <p class="box_title" style="margin-bottom: 5px">EFF</p>
                  <p class="origin_name">Efficiency</p>
                </div>
                <p
                  class="box_fs_24"
                  [ngStyle]="{ color: efficiencyFontColor(item.nowEfficiency) }"
                >
                  {{ dataFormatSvc.checkValDash(item.nowEfficiency) }} %
                </p>
                <p
                  class="box_fs_24"
                  [ngStyle]="{ color: efficiencyFontColor(item.efficiency) }"
                >
                  ({{ dataFormatSvc.checkValDash(item.efficiency) }} %)
                </p>
              </div>
            </div>
          </div>

          <!-- 圖釘 -->
          <!-- <div
            class="btn_pin"
            *ngIf="item.isPinItem === false"
            (click)="
              openPopup = true; popupMode = '1'; this.pingSaveLineName = item.line
            "
          >
            <img src="/assets/img/btn_pin.svg" alt="pin" />
          </div>

          <div
            class="btn_pin_active"
            *ngIf="item.isPinItem === true"
            (click)="
              openPopup = true; popupMode = '2'; this.pingSaveLineName = item.line
            "
          >
            <img src="/assets/img/btn_pin.svg" alt="pin" />
          </div> -->
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="foot">
      <!-- Auto Refresh -->
      <div
        class="refresh"
        style="padding: 0"
        [ngClass]="{ refresh_showe: this.isSaveSec === 'Stop' }"
      >
        <button
          class="relaod_btn"
          style="margin-left: 0"
          (click)="reloadCurrentPageClick()"
        >
          <img src="../../assets/img/btn_relaod.svg" alt="reload" />
        </button>
        <div class="refresh_btn">
          <label>
            <span class="text">Auto Refresh:</span>
            <input
              type="checkbox"
              class="checkbox"
              [checked]="pageAutoRefresh"
              (change)="switchPageAutoRefresh()"
            />
            <span class="btn-box">
              <span class="btn"></span>
            </span>
          </label>
        </div>
      </div>

      <div class="page_num">
        <img
          [ngClass]="{ page_arrow_disabled: nowPageNum === 1 }"
          class="page_arrow page_arrow_left"
          src="assets/img/page_arrow_left.svg"
          alt=""
          (click)="
            swapPageNum('-1'); getAllApiFn(apiEncodeFilterStr, nowPageNum, 3)
          "
        />
        <p class="number">{{ nowPageNum }} / {{ isTotalPageNum }}</p>
        <img
          [ngClass]="{ page_arrow_disabled: nowPageNum === isTotalPageNum }"
          class="page_arrow page_arrow_right"
          src="assets/img/page_arrow_right.svg"
          alt=""
          (click)="
            swapPageNum('+1'); getAllApiFn(apiEncodeFilterStr, nowPageNum, 3)
          "
        />
      </div>

      <!-- Kanban Renewed & Snap Shot -->
      <div class="update_time" style="position: absolute; right: 145px">
        <p class="renewed">
          <span class="title">Kanban Renewed:</span>
          <span class="value">{{
            renewedTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
        <p class="snapshot">
          <span class="title">Snap Shot:</span>
          <span class="value">{{
            snapshotTime | date: "yyyy/MM/dd HH:mm a"
          }}</span>
        </p>
      </div>

      <!-- Auto swap -->
      <div class="auto_swap" (click)="this.choiceSecShow = !this.choiceSecShow">
        <!-- {{ isSaveSec }} sec /  -->
        Auto swap
        <img class="swap_img" src="assets/img/arrow_swap_top.svg" alt="" />
        <div class="sec_group" *ngIf="choiceSecShow">
          <p
            class="btn_sec"
            *ngFor="let item of swapSecArr"
            (click)="swapSecs(item.secNum)"
          >
            <img *ngIf="item.isActive" src="assets/img/swap_check.svg" alt="" />
            <span *ngIf="item.isActive === false"> </span>
            {{ item.secNum === "Stop" ? "Stop" : item.secNum }}
            <span *ngIf="item.secNum !== 'Stop'">sec</span>
          </p>
          <!-- <p class="btn_sec" (click)="swapSecs(3)">
              <img src="assets/img/swap_check.svg" alt="" />
              30 sec
            </p> -->
        </div>
      </div>
    </div>

    <!-- Popup window -->
    <div class="popup_window" *ngIf="openPopup">
      <!-- <div class="popup_window" *ngIf="true"> -->
      <div class="popup_box isPinItem" *ngIf="popupMode === '1'">
        <!-- <ng-container *ngIf="resLineNameArr.length === 0">
            <div class="no_line_name">No data...</div>
          </ng-container> -->

        <!-- <div
          class="popup_Close"
          (click)="openPopup = false; saveIsCheckLineNameStatus('cancel')"
        >
          X
        </div> -->

        <div class="all_line_groups">
          <!-- Line 線別 -->
          <div class="line_group" *ngFor="let item of viewLineNameStatusArr">
            <p class="popup_title">{{ item.section }}</p>
            <div class="check_group">
              <div class="check_item">
                <!-- <ng-container *ngIf="item.lines.length === 0">
                    <div class="no_line_name">No data...</div>
                </ng-container> -->
                <ng-container *ngIf="item.lines.length !== 0">
                  <label
                    class="checkbox_gourp"
                    *ngFor="let child of item.lines"
                  >
                    <input
                      (change)="selectCheckbox(item.section, child.lineName)"
                      [checked]="child.isChecked"
                      class="checkbox_input"
                      type="checkbox"
                      name="select_name1"
                      [value]="child.lineName"
                      [id]="item.section + child.lineName"
                    />
                    <span class="checkbox_span"></span>
                    {{ child.lineName }}
                  </label>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- <p class="popup_title">Selcet line name.</p>
        <div class="check_group">
          <div class="check_item">
            <ng-container *ngIf="resLineNameArr.length !== 0">
              <label
                class="checkbox_gourp"
                *ngFor="let item of viewLineNameStatusArr"
              >
                <input
                  (change)="selectCheckbox(item.lineName)"
                  [checked]="item.isChecked"
                  class="checkbox_input"
                  type="checkbox"
                  name="select_name1"
                  [value]="item.lineName"
                  id=""
                />
                <span class="checkbox_span"></span>
                {{ item.lineName }}
              </label>
            </ng-container>
          </div>
        </div> -->

        <div class="btn_group">
          <div
            class="btn btn_yes"
            (click)="
              openPopup = false;
              saveIsCheckLineNameStatus('Yes');
              checkSaveFilterLineNameArrLength();
              getAllApiFn(apiEncodeFilterStr, 1, 3)
            "
          >
            Yes
          </div>
          <div
            class="btn btn_no"
            (click)="openPopup = false; saveIsCheckLineNameStatus('cancel')"
          >
            No
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>
</div>
