import { Component, OnDestroy, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';
import { timer, Subject, interval, Subscription } from 'rxjs';
import { concatMap, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../_services/apiSvc.service';

import { ActivatedRoute, Router } from '@angular/router';
import { ForTestDataService } from '../_services/for-test-data.service';
import { DataFormatService } from '../_services/data-format.service';

import * as moment from 'moment';
import numeral from 'numeral';
import * as _ from 'lodash';
import { Chart } from '../../../node_modules/chart.js';

@Component({
  selector: 'app-phase3-equip-alarm-all',
  templateUrl: './phase3-equip-alarm-all.component.html',
  styleUrls: ['./phase3-equip-alarm-all.component.scss']
})
export class Phase3EquipAlarmAllComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedMoments = [new Date(), new Date()];
  minDate = new Date(2021, 6, 1, 0, 0);
  maxDate = moment().startOf('day').add(1, 'days').toDate();
  isDateTimePickerDefault = true;
  isMenuOpen = false;
  isLogin = false;

  updatingDataSubscription: Subscription | null = null;
  pageAutoRefresh = true;
  intervalSeconds = 300;
  rxTimer$;

  renewedTime;
  snapshotTime;

  orderInfoObj = {
    Section: null,
    Type: null,
    Sn: null,
    Side: null
  };

  isApiResEmptyArrShowNoData = false;

  btnTypeActiveObj = [
    {
      key: 'Mo',
      active: false,
    },
    {
      key: 'Line',
      active: false,
    }
  ];

  sectionNameArr = [];
  allOptionNameArr = [];

  sectionDisplayMappingObj = {
    SMT: 'SMT',
    LOD: 'LOD',
    TEST: 'Equipments'
  };

  apiParamsObj = {
    type: 'Line',
    sectionName: null || 'SMT',
    selectOptionName: null || 'All',
    countRankingSelectEquip: null,
    timeRankingSelectEquip: null,
    startTime: null,
    endTime: null,
  };



  chartArray = [];

  chartColorMappingObj = {
    runningRate: '#0E8E58',
    idleRate: '#E3B953',
    downtimeRate: '#D2574A'
  };

  MOCK_DATA = {
    runningRate: {
      rate: 0.9235,
      data: [
        {
          line: '5S-A',
          rate: 0.7555,
        },
        {
          line: '5S-B',
          rate: 0.7585,
        },
        {
          line: '6S-A',
          rate: 0.8692,
        },
        {
          line: '6S-B',
          rate: 0.95887,
        },
        {
          line: '6S-C',
          rate: 0.89883,
        },
        {
          line: '6S-D',
          rate: 0.757798,
        },
      ]
    },
    idleRate: {
      rate: 0.0535,
      data: [
        {
          line: '5S-A',
          rate: 0.10,
        },
        {
          line: '5S-B',
          rate: 0.15,
        },
        {
          line: '6S-A',
          rate: 0.20,
        },
        {
          line: '6S-B',
          rate: 0.05,
        },
        {
          line: '6S-C',
          rate: 0.20,
        },
        {
          line: '6S-D',
          rate: 0.15,
        },
      ]
    },
    downtimeRate: {
      rate: 0.023,
      data: [
        {
          line: '5S-A',
          rate: 0.10,
        },
        {
          line: '5S-B',
          rate: 0.05,
        },
        {
          line: '6S-A',
          rate: 0.10,
        },
        {
          line: '6S-B',
          rate: 0.15,
        },
        {
          line: '6S-C',
          rate: 0.05,
        },
        {
          line: '6S-D',
          rate: 0.05,
        },
      ]
    },
  };

  apiResData = {
    runningRate: {
      rate: 0.9235,
      data: [
        {
          line: '5S-A',
          rate: 0.7555,
        },
        {
          line: '5S-B',
          rate: 0.7585,
        },
        {
          line: '6S-A',
          rate: 0.8692,
        },
        {
          line: '6S-B',
          rate: 0.95887,
        },
        {
          line: '6S-C',
          rate: 0.89883,
        },
        {
          line: '6S-D',
          rate: 0.757798,
        },
      ]
    },
    idleRate: {
      rate: 0.0535,
      data: [
        {
          line: '5S-A',
          rate: 0.10,
        },
        {
          line: '5S-B',
          rate: 0.15,
        },
        {
          line: '6S-A',
          rate: 0.20,
        },
        {
          line: '6S-B',
          rate: 0.05,
        },
        {
          line: '6S-C',
          rate: 0.20,
        },
        {
          line: '6S-D',
          rate: 0.15,
        },
      ]
    },
    downtimeRate: {
      rate: 0.023,
      data: [
        {
          line: '5S-A',
          rate: 0.10,
        },
        {
          line: '5S-B',
          rate: 0.05,
        },
        {
          line: '6S-A',
          rate: 0.10,
        },
        {
          line: '6S-B',
          rate: 0.15,
        },
        {
          line: '6S-C',
          rate: 0.05,
        },
        {
          line: '6S-D',
          rate: 0.05,
        },
      ]
    },
  };

  private rxTimerStop() {
    // console.log('Fomate sec time *********');
    this.rxTimer$.unsubscribe();
  }

  private rxTimerStart() {
    // 計時器 intervalSeconds Call API 更新資料
    this.rxTimer$ = interval(this.intervalSeconds * 1000).subscribe((data) => {
      if (this.isDateTimePickerDefault) {
        this.defaultDateTime();
      }
      else {
        this.isSvLoading.loading = true;
        this.getUpdatDataApiFn$().subscribe({
          error: error => {
            this.isSvLoading.loading = false;
            console.log('catchError: 錯誤 - ', error);
            if (this.pageAutoRefresh) { this.rxTimerStart(); }
          },
          complete: () => {
            this.isSvLoading.loading = false;
            if (this.pageAutoRefresh) { this.rxTimerStart(); }
          }
        });
      }
    });
  }

  constructor(
    public apiSvc: ApiService,
    public isSvLoading: SpinnerService,
    public clock: RealTimeClockService,
    public forTestDataSvc: ForTestDataService,
    public dataFormatSvc: DataFormatService,
    // route
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.isSvLoading.loading = true;
  }

  ngOnInit(): void {

    // this.buildChartData(this.MOCK_DATA);


    // this.getUrlParam();
    // if (this.isDateTimePickerDefault) { this.initialDateTime(); }
    // else { this.selectedMoments = [moment(this.apiParamsObj.startTime).toDate(), moment(this.apiParamsObj.endTime).toDate()]; }
    // this.rxTimerStart();
  }



  ngAfterViewInit() {
    this.sectionNameArr = this.forTestDataSvc.allSectionName.reverse();
    this.allOptionNameArr = this.forTestDataSvc.allLineName;
    this.buildChartData(this.MOCK_DATA); // 或在這裡呼叫建立圖表的邏輯

    // this.getAllApiFn$().subscribe({
    //   error: error => {
    //     this.isSvLoading.loading = false;
    //     console.log('catchError: 錯誤 - ', error);
    //     if (this.pageAutoRefresh) { this.rxTimerStart(); }
    //   },
    //   complete: () => {
    //     this.isSvLoading.loading = false;
    //     if (this.pageAutoRefresh) { this.rxTimerStart(); }
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.pageAutoRefresh) { this.rxTimer$.unsubscribe(); }
  }

  // Url 參數設定
  getUrlParam() {
    this.apiParamsObj.sectionName = this.route.snapshot.queryParamMap.get('section');

    this.apiParamsObj.startTime = this.route.snapshot.queryParamMap.get('startTime');
    this.apiParamsObj.endTime = this.route.snapshot.queryParamMap.get('endTime');
    if (this.apiParamsObj.startTime && this.apiParamsObj.endTime) {
      this.isDateTimePickerDefault = false;
      this.apiParamsObj.startTime = Number(this.apiParamsObj.startTime);
      this.apiParamsObj.endTime = Number(this.apiParamsObj.endTime);
    }
  }

  // DateRangePicker 設定 選到時間的 時間
  getRangeDate() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = false;
    this.selectedMoments = this.clearDateTimeMinites(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeEqual(this.selectedMoments);
    this.selectedMoments = this.checkDateTimeRangeLimit(this.selectedMoments);
    console.log('時間篩選器 選取時間 過濾後:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
    this.apiParamsObj.startTime = this.selectedMoments[0].valueOf();
    this.apiParamsObj.endTime = this.selectedMoments[1].valueOf();
    this.getUpdatDataApiFn$().subscribe({
      error: error => {
        this.isSvLoading.loading = false;
        console.log('catchError: 錯誤 - ', error);
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }

  // Default DateRangePicker 設定 預設的 時間, 24小時內的資料
  defaultDateTime() {
    this.isSvLoading.loading = true;
    this.isDateTimePickerDefault = true;
    this.initialDateTime();
    this.getUpdatDataApiFn$().subscribe({
      error: error => {
        this.isSvLoading.loading = false;
        console.log('catchError: 錯誤 - ', error);
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }

  // 初始化時間, 24小時內
  initialDateTime() {
    if (this.isDateTimePickerDefault) {
      const currentHour = moment().get('hour');
      const endTime = moment().startOf('day').set('hour', currentHour).add(1, 'hours');
      const startTime = _.cloneDeep(endTime).subtract(1, 'day');
      this.selectedMoments = [startTime.toDate(), endTime.toDate()];
      console.log('時間篩選器 預設時間:', this.selectedMoments, [this.selectedMoments[0].valueOf(), this.selectedMoments[1].valueOf()]);
      this.apiParamsObj.startTime = startTime.valueOf();
      this.apiParamsObj.endTime = endTime.valueOf();
    }
  }

  // 檢查選到的時間區間是否相同, 若相同取其時間往前24小時的時間區間
  checkDateTimeRangeEqual(timeArray) {
    if (timeArray[0].valueOf() === timeArray[1].valueOf()) {
      timeArray[0] = moment(timeArray[0]).subtract(1, 'day').toDate();
    }
    return timeArray;
  }

  // 將所選時間的分鐘歸零
  clearDateTimeMinites(timeArray) {
    return timeArray.map(item => {
      return item = moment(item).set('minute', 0).toDate();
    });
  }

  // 檢查所選時間區間是否超過 31 天, 若超過將 endTime 改為 startTime + 31 天
  checkDateTimeRangeLimit(timeArray) {
    const startTime = moment(timeArray[0]);
    let endTime = moment(timeArray[1]);
    const dayDiff = endTime.diff(startTime, 'days', true);   // true => 取到小數位
    console.log('dayDiff:', dayDiff);
    if (dayDiff > 31) {
      const hour = startTime.get('hour');
      endTime = _.cloneDeep(startTime).add(31, 'day').set('hour', hour);
      timeArray[0] = startTime.toDate();
      timeArray[1] = endTime.toDate();
    }
    return timeArray;
  }

  // 設定 DateTimePicker 可被選的時間區間, 從 2021/07/01 00:00 到 明天 00:00
  defaultDateTimeRange() {
    this.minDate = new Date(2021, 6, 1, 0, 0);
    this.maxDate = moment().startOf('day').add(1, 'days').toDate();
  }

  switchSection(selectedSection: string) {
    console.log('switchSection:', selectedSection);
    if (selectedSection === 'TEST') {
      if (this.isDateTimePickerDefault) {
        this.router.navigate(['/smartFactory/web/phase3-ate']);
      }
      else {
        this.router.navigate(['/smartFactory/web/phase3-ate'], { queryParams: { startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime } });
      }
      return;
    }
    else {
      this.isSvLoading.loading = true;
      this.apiParamsObj.sectionName = selectedSection;
      // call SMT / LOD api
      this.getAllOptionName$(this.apiParamsObj.sectionName).subscribe(
        (res) => this.getUpdatDataApiFn$().subscribe({
          error: error => {
            this.isSvLoading.loading = false;
            console.log('catchError: 錯誤 - ', error);
            if (this.pageAutoRefresh) { this.rxTimerStart(); }
          },
          complete: () => {
            this.isSvLoading.loading = false;
            if (this.pageAutoRefresh) { this.rxTimerStart(); }
          }
        }));
    }
  }

  switchSelectOptionName(selectedLine: string) {
    console.log('switchSelectOptionName:', selectedLine);
    if (this.isDateTimePickerDefault) {
      this.router.navigate(['/smartFactory/web/equip-alarm'], { queryParams: { section: this.apiParamsObj.sectionName, line: selectedLine } });
    }
    else {
      this.router.navigate(['/smartFactory/web/equip-alarm'], { queryParams: { section: this.apiParamsObj.sectionName, line: selectedLine, startTime: this.apiParamsObj.startTime, endTime: this.apiParamsObj.endTime } });
    }
    return;
  }

  buildChartData(res) {
    const runningRateObj = Object.assign(res?.runningRate);
    const idleRateObj = Object.assign(res?.idleRate);
    const downtimeRateObj = Object.assign(res?.downtimeRate);
    const lineData = [];
    if (this.chartArray.length > 0) { this.chartArray.forEach(e => e.destroy()); }
    lineData.push({
      chartId: 'runningRate',
      data: {
        labels: runningRateObj.data.map((item) => item.line),
        datasets: [{
          data: runningRateObj.data.map((item) => (item.rate * 100).toFixed(2)),
          backgroundColor: this.chartColorMappingObj.runningRate,
          borderColor: this.chartColorMappingObj.runningRate,
          borderWidth: 1
        }]
      }
    });
    lineData.push({
      chartId: 'idleRate',
      data: {
        labels: idleRateObj.data.map((item) => item.line),
        datasets: [{
          data: idleRateObj.data.map((item) => (item.rate * 100).toFixed(2)),
          backgroundColor: this.chartColorMappingObj.idleRate,
          borderColor: this.chartColorMappingObj.idleRate,
          borderWidth: 1
        }]
      }
    });
    lineData.push({
      chartId: 'downtimeRate',
      data: {
        labels: downtimeRateObj.data.map((item) => item.line),
        datasets: [{
          data: downtimeRateObj.data.map((item) => (item.rate * 100).toFixed(2)),
          backgroundColor: this.chartColorMappingObj.downtimeRate,
          borderColor: this.chartColorMappingObj.downtimeRate,
          borderWidth: 1
        }]
      }
    });
    lineData.forEach((item) => this.initChart(item?.chartId, item?.data));
  }

  initChart(id, dataObj) {
    console.log('------->',id, dataObj,'<--------');
    this.chartArray.push(new Chart(id, {
      type: 'bar',
      data: dataObj,
      options: {
        tooltips: {
          titleFontSize: 24,
          bodyFontSize: 24,
          borderColor: 'white',
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                // max: 24,
                fontSize: 18,
                stepSize: 10,
                fontColor: '#BABABA',
              },
              // 設定 底線
              gridLines: {
                display: false,
                color: '#868686',
                drawBorder: false,
              },
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                max: 100,
                fontSize: 24,
                stepSize: 20,
                // maxTicksLimit: 5,
                fontColor: '#BABABA',
                padding: 10
              },
              // 設定 底線
              gridLines: {
                display: true,
                color: '#868686',
                drawBorder: false,
                drawTicks: true,
                lineWidth: 2,
              },
            }
          ]
        },

        plugins: {
          p1: false,   // disable plugin 'p1' for this instance
        },
      },
      plugins: [{
        beforeDraw: (chart) => {
          const ctx = chart.chart.ctx;
          ctx.restore();
          ctx.font = 14 + 'px Roboto';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#bababa';
          const height = chart.chartArea.bottom;
          ctx.fillText('(%)', chart.chartArea.left - 60, height);
          ctx.save();
        }
      }],
    }));
  }

  switchPageAutoRefresh() { }

  // API get all sectionName
  getAllSectionName$(type) {
    return this.apiSvc.getEquipAlarmSection(type).pipe(
      tap(res => {
        // console.log('getAllSectionName row data res:', res);
        // res = this.forTestDataSvc.allSectionName;
        res.reverse();  // smt -> lod
        res.push('TEST');  // ADD TEST SECTION
        this.sectionNameArr = res;
        console.log('拿到所有 Section Name res:', res);
      }),
    );
  }

  // API get all optionName
  getAllOptionName$(section) {
    return this.apiSvc.getEquipAlarmOption(section).pipe(
      tap(res => {
        this.allOptionNameArr = res;
        this.allOptionNameArr.unshift('All');
        console.log('拿到所有 Option Name res:', res);
      }),
    );
  }

  // get initial dropdown data
  getAllApiFn$() {
    return this.getAllSectionName$(this.apiParamsObj.type).pipe(
      switchMap(res => this.getAllOptionName$(
        this.apiParamsObj.sectionName)
      ),
      concatMap(res => this.getUpdatDataApiFn$())
    );
  }

  // get update info data
  getUpdatDataApiFn$() {
    if (this.pageAutoRefresh) { this.rxTimerStop(); }
    this.renewedTime = moment().valueOf();
    return this.apiSvc.getPhase3EquipAlarmAllLine(this.apiParamsObj.sectionName, this.apiParamsObj.startTime, this.apiParamsObj.endTime).pipe(
      tap(res => {
        console.log('getAllLineName row data res:', res);
        this.snapshotTime = this.renewedTime;
        // this.apiResData = this.MOCK_DATA;  // USING MOCK_DATA
        this.apiResData = res;
        this.buildChartData(this.apiResData);
      }),
    );
  }

  reloadCurrentPageClick() {
    this.isSvLoading.loading = true;
    this.getUpdatDataApiFn$().subscribe({
      error: error => {
        this.isSvLoading.loading = false;
        console.log('catchError: 錯誤 - ', error);
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      },
      complete: () => {
        this.isSvLoading.loading = false;
        if (this.pageAutoRefresh) { this.rxTimerStart(); }
      }
    });
  }
}
