<div class="mo_quality">
  <div class="title_group">
    <h1 class="mo_quality_title">
      <a
        class="mo_table_link"
        [routerLink]="['/smartFactory/web/quality-overview']"
      >
        <img
          class="btn_back"
          src="../../assets/img/smart_factory/btn_back.svg"
          alt="arrow"
        />
      </a>
      MO Quality
    </h1>
    <div class="time">
      <!-- <p>10:23 <span>AM</span></p> -->
      <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
      <img
        class="menu-bt"
        routerLink="/menu"
        src="../../assets/img/btn_menu.svg"
        alt="menu"
      />
    </div>
  </div>

  <div class="mo_table_group">
    <div class="mo_table">
      <div class="mo_table_title_group">
        <!-- <div class="table_w mo_table_customer">Customer</div> -->
        <div class="table_w mo_table_model">Part No.</div>
        <div class="table_w mo_table_mo">MO No.</div>
        <div class="table_w mo_table_cause">Input Qty.</div>
        <!-- <div class="table_w mo_table_startTime">Start time</div> -->
        <div class="table_w mo_table_defect">Defect Qty.</div>
        <div class="table_w mo_table_yield">RTY</div>
        <div class="table_w mo_table_lowestYield">Lowest Yield</div>
      </div>

      <div class="mo_table_data_group" *ngFor="let item of moDataArry">
        <!-- <div class="table_w mo_table_data_customer">
          {{ this.moSvc.customer === undefined ? "-" : this.moSvc.customer }}
        </div> -->
        <div class="table_w mo_table_data_product">
          {{ this.moSvc.product }}
        </div>
        <div class="table_w mo_table_data_mo">{{ this.moSvc.order }}</div>
        <div class="table_w mo_table_data_inputQty">
          {{ this.moSvc.inputQty | number }}
        </div>
        <!-- <div class="table_w mo_table_data_startTime">
          {{ this.moSvc.startTime | date: "yyyy/M/dd HH:mm" }}
        </div> -->
        <div class="table_w mo_table_data_defect">
          {{ this.moSvc.defectQuantity | number }}
        </div>
        <div class="table_w mo_table_data_yield">
          {{ this.moSvc.yieldRate | number: "0.2-2" }}%
        </div>
        <div class="table_w mo_table_data_cause">
          {{ this.moSvc.lowestYield === "" ? "-" : this.moSvc.lowestYield }}
        </div>
      </div>
    </div>
  </div>

  <div class="mo_tab_group">
    <input
      checked
      type="radio"
      name="radio_control"
      id="radio1"
      class="penal_control"
    />
    <input
      type="radio"
      name="radio_control"
      id="radio2"
      class="penal_control"
    />
    <input
      type="radio"
      name="radio_control"
      id="radio3"
      class="penal_control"
    />
    <div class="tab_group">
      <label for="radio1">Station Performance</label>
      <!-- <label
        for="radio2"
        (click)="getHourlyData(isOrderNumber, begingTime, before24Time)"
      >
        Hourly Data
      </label> -->
      <label for="radio3" (click)="getDefectStationNameData()">
        Defect Ranking
      </label>
    </div>

    <div class="conten_group">
      <!-- Station Performance % -->
      <div class="content content_performance">
        <div class="performance_no_data" *ngIf="getStationDataNoData">
          <span>No Data</span>
        </div>
        <!-- <div class="progress_group" *ngFor="let item of stationDataArr">
          <div class="progress_title">
            {{ item.processSection }} <br />
            <span>{{ item.function }}</span>

            <div class="qty_group">
              <div class="title_name_group">
                <p>Total Qty</p>
                <p>Qualified Qty.</p>
                <p>Defective Qty.</p>
                <p>Pass Qty</p>
                <p>Fail Qty</p>
              </div>

              <div class="title_data">
                <p>{{ item.totalSize | number }}</p>
                <p>{{ item.passSize | number }}</p>
                <p>{{ item.failSize | number }}</p>
              </div>
            </div>
          </div>

          <div class="progress_circle">
            <circle-progress
              [percent]="item.yieldRate"
              [radius]="100"
              [animation]="true"
              [animationDuration]="300"
            ></circle-progress>
          </div>
        </div> -->

        <div class="progress_item" *ngFor="let item of stationDataArr">
          <div class="station_title">
            {{ item.function }}
          </div>
          <div class="text_box">
            <div class="text_group">
              <div class="text_title">Total Qty</div>
              <div class="text_value">{{ item.totalSize | number }}</div>
            </div>
            <div class="text_group">
              <div class="text_title">FPY</div>
              <div class="text_value">{{ item.yieldRate | number }} %</div>
            </div>
          </div>

          <div class="text_box">
            <div class="text_group">
              <div class="text_title">Pass Qty</div>
              <div class="text_value">{{ item.passSize | number }}</div>
            </div>
            <div class="text_group">
              <div class="text_title">DPPM</div>
              <div class="text_value">{{ item.dppm | number }}</div>
            </div>
          </div>

          <div class="text_box">
            <div class="text_group">
              <!-- <div class="text_title">Fail Qty</div> -->
              <div class="text_title">Defect Qty</div>
              <div class="text_value">{{ item.failSize | number }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Hourly Data -->
      <div class="content content_data">
        <div class="hourly_no_data" *ngIf="getHourlyDataNoData">
          <span>No Data</span>
        </div>
        <div class="data_chart_group" *ngFor="let item of chartHourlyDataArr">
          <div class="data_chart_title">{{ item.chartTitle }}</div>
          <div class="data_chart_img">
            <canvas
              baseChart
              height="85"
              [datasets]="item.barChartData"
              [labels]="item.barChartLabels"
              [options]="item.barChartOptions"
              [legend]="item.barChartLegend"
              [chartType]="item.barChartType"
              [colors]="item.chartColors"
              (chartHover)="item.chartHovered($event)"
              (chartClick)="item.chartClicked($event)"
            >
            </canvas>
            <div class="chart_label_group">
              <p class="chart_output">( Output )</p>
              <p class="chart_last24hr">( Last 24hr )</p>
              <p class="chart_yield">( Yield )</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Defect Ranking -->
      <div class="content content_defect_ranking">
        <div class="data_chart_group">
          <!-- select -->
          <div class="defect_station_select">
            <label>Station</label>
            <select (change)="callRankingTop10($event.target.value)">
              <option selected="selected">
                {{ isDefectFirstStationName }}
              </option>
              <option *ngFor="let item of rankStationName">
                {{ item }}
              </option>
            </select>
          </div>

          <!-- Ranking group -->
          <div class="defect_ranking_groups">
            <div class="ranking_title_groups">
              <div class="title_ranking_group">
                <!-- <div class="title_ranking">Ranking</div> -->
                <div class="title_cause">Cause</div>
                <div class="title_count">Count</div>
                <div class="title_percent">%</div>
              </div>

              <div
                class="title_ranking_group"
                *ngIf="dataDefectRankOutstripFive"
              >
                <!-- <div class="title_ranking">Ranking</div> -->
                <div class="title_cause">Cause</div>
                <div class="title_count">Count</div>
                <div class="title_percent">%</div>
              </div>
            </div>

            <!-- <ul class="ranking_bar_groups">
              <li
                class="ranking_bar"
                *ngFor="let item of rankBar; let idx = index"
              >
                <div class="bar_number">{{ idx + 1 }}</div>

                <div
                  class="bar_color"
                  [style]="'width:' + item.percent + '%'"
                ></div>
                <div class="bar_cause">{{ item.testStation }}</div>
                <div class="bar_count">{{ item.occurrence }}</div>
                <div class="bar_percent">
                  {{ item.percent | number: "0.2-2" }} %
                </div>
              </li>
            </ul> -->

            <ul class="ranking_bar_groups">
              <li
                class="ranking_bar"
                *ngFor="let item of rankBar; let idx = index"
              >
                <div class="bar_number">{{ idx + 1 }}</div>

                <div
                  class="bar_color"
                  [style]="'width:' + item.percent + '%'"
                ></div>
                <div class="bar_cause">{{ item.testStation }}</div>
                <div class="bar_count">{{ item.occurrence }}</div>
                <div class="bar_percent">
                  {{ item.percent | number: "0.2-2" }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
