<div class="container-fluid equipment_alarm">
  <ng-container
    *ngIf="
      apiSvc.ifSSO('Administrator,1E-Editor,Viewer');
      else PermissionDenied
    "
  >
    <!-- BY Line ***************************** -->
    <div class="by_line">
      <!-- Top Menu side -->
      <div class="title_group">
        <div class="title_box">
          <!-- Title -->
          <div class="title_box">
            <button class="logo-btn" style="margin-right: 24px" title="logo">
              <img
                style="width: 114px"
                routerLink="/smartFactory/web/main-board"
                src="../../assets/img/homeico.png"
                alt="logo"
              />
            </button>
            <h1 class="wip_overview_title">Equipments Alarm</h1>
          </div>

          <!-- Category dropdown -->
          <div class="dropdown_box">
            <label>Category:</label>
            <!-- TODO: get sections by API -->
            <select (change)="switchSection($event.target.value)">
              <option
                *ngFor="let item of sectionNameArr"
                [selected]="item === apiParamsObj.sectionName"
                [value]="item"
              >
                {{ sectionDisplayMappingObj[item] }}
              </option>
            </select>
          </div>

          <!-- time -->
          <div class="time">
            <!-- <p>10:23 <span>AM</span></p> -->
            <p>{{ this.clock.isRealTimeClock | date: "yyyy/MM/dd HH:mm a" }}</p>
            <button
              style="margin-left: 20px"
              (click)="isMenuOpen = !isMenuOpen"
            >
              <img
                class="menu-bt"
                src="../../assets/img/btn_menu.svg"
                alt="menu"
              />
            </button>
            <div class="login-menu" *ngIf="isMenuOpen">
              <button routerLink="/menu">
                <img src="../../assets/img/btn_kanban_menu.svg" alt="menu" />1E
                Dashboard Menu
              </button>
              <button *ngIf="!apiSvc.SSO" (click)="apiSvc.onClickSSO(true)">
                <img src="../../assets/img/btn_log_in.svg" alt="login" />Log in
              </button>
              <button *ngIf="apiSvc.SSO" (click)="apiSvc.onClickSSO(false)">
                <img
                  src="../../assets/img/btn_sign_out.svg"
                  alt="signout"
                />Sign out
              </button>
            </div>
          </div>
        </div>

        <!-- Line & Time-Range group -->
        <div class="line_box">
          <!-- 下拉選單 Line -->
          <div class="select_tag">
            <label>Line</label>
            <select (change)="switchSelectOptionName($event.target.value)">
              <option
                *ngFor="let item of allOptionNameArr"
                [selected]="item === apiParamsObj.selectOptionName"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <!-- Computation between Time range -->
          <div class="time_range">
            <label>Date Range:</label>
            <input
              [(ngModel)]="selectedMoments"
              [min]="minDate"
              [max]="maxDate"
              [selectMode]="'range'"
              [owlDateTime]="dtPicker"
              (dateTimeChange)="getRangeDate()"
            />
            <mat-datepicker-toggle
              [owlDateTimeTrigger]="dtPicker"
              style="margin-left: 12px"
            ></mat-datepicker-toggle>
            <img
              class="default_button"
              src="../../../assets/img/btn_date_default.svg"
              alt="Default"
              style="margin-left: 12px"
              (click)="defaultDateTime()"
            />
          </div>
        </div>
        <owl-date-time
          #dtPicker
          (afterPickerOpen)="defaultDateTimeRange()"
        ></owl-date-time>
      </div>

      <!-- Content Box -->
      <div class="content row">
        <!-- latest Alarm & Error box -->
        <div class="col-3 left_box">
          <!-- latest Alarm info -->
          <div class="info_box">
            <p class="title">Latest Alarm Info</p>

            <div class="text_group">
              <p class="key">Equipment Name</p>
              <p class="value">
                {{
                  dataFormatSvc.checkValDash(equipAlarmDataCompose.latestAlarm)
                }}
              </p>
            </div>

            <div class="text_group">
              <p class="key">Alarm Code</p>
              <p class="value">
                {{
                  dataFormatSvc.checkValDash(equipAlarmDataCompose.alarmCode)
                }}
              </p>
            </div>

            <div class="text_group">
              <p class="key">Latest Alarm Time</p>
              <p class="value">
                {{
                  dataFormatSvc.checkValDash(
                    equipAlarmDataCompose.latestAlarmTime
                  )
                }}
              </p>
            </div>

            <div class="text_group">
              <p class="key">Latest Alarm Duration</p>
              <p class="value">
                {{
                  dataFormatSvc.checkValDash(
                    equipAlarmDataCompose.alarmDuration
                  )
                }}
              </p>
            </div>
          </div>

          <!-- Error Record box - pie chart -->
          <div class="error_record_box">
            <div
              class="div"
              style="display: flex; justify-content: space-between"
            >
              <p class="title">Error Record</p>
              <button
                class="expand_button"
                (click)="onClickExpandList(apiParamsObj)"
              >
                Expand
                <img
                  class="select_icon"
                  src="../../../assets/img/btn_column_picker.svg"
                  alt="Expand"
                />
              </button>
            </div>

            <!-- pie chart -->
            <div class="pie_chart" *ngFor="let item of pieChartDataArr">
              <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
                No Data
              </div>
              <button
                style="position: absolute; top: 0; right: 0"
                (click)="onClickDownload('Error Record', apiParamsObj)"
              >
                <img
                  class="download_button"
                  style="width: 16px"
                  src="../../../assets/img/btn_download.svg"
                  alt="download_button"
                />
              </button>
              <canvas
                baseChart
                [datasets]="item.pieChartData"
                [labels]="item.pieChartLabels"
                [chartType]="item.pieChartType"
                [options]="item.pieChartOptions"
                [colors]="item.donutColors"
              >
              </canvas>
            </div>

            <!-- Error Record info -->
            <div class="pie_info">
              <div class="info_group">
                <p class="name"></p>
                <p class="mins" style="color: #bababa">Times</p>
                <p class="percent" style="color: #bababa">%</p>
              </div>

              <div
                class="info_group"
                *ngFor="
                  let item of equipAlarmDataCompose.pieChartErrorRecordInfo
                "
              >
                <p class="name">
                  <span
                    class="name_color"
                    [style]="'background:' + item.color"
                  ></span>
                  {{ dataFormatSvc.checkValDash(item.name) }}
                </p>
                <p class="mins">
                  {{ dataFormatSvc.checkValDash(item.recordNum) }}
                </p>
                <p class="percent">
                  {{ dataFormatSvc.checkValDash(item.percent) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- 百分比 ＆ Error Record & Error Code Ranking -->
        <div class="col-9 right_box">
          <!-- 百分比 Val -->
          <div class="row val_gorup">
            <!-- Line Running / Mo Utilization -->
            <div class="val_box">
              <p class="val_title">Line Running %</p>
              <p class="val_value">
                {{
                  dataFormatSvc.checkValDash(equipAlarmDataCompose?.lineRunning)
                }}
                %
              </p>
              <p class="val_time">
                {{ equipAlarmDataCompose?.lineRunningFormatted }}
              </p>
            </div>

            <!-- Line Idle / Mo Idle -->
            <div class="val_box">
              <p class="val_title">Line Idle %</p>
              <p class="val_value">
                {{
                  dataFormatSvc.checkValDash(equipAlarmDataCompose?.lineIdle)
                }}
                %
              </p>
              <p class="val_time">
                {{ equipAlarmDataCompose?.lineIdleFormatted }}
              </p>
            </div>

            <!-- Line Downtime / Mo Downtime -->
            <div class="val_box">
              <p class="val_title">Line Downtime %</p>
              <p class="val_value">
                {{
                  dataFormatSvc.checkValDash(
                    equipAlarmDataCompose?.lineDownTime
                  )
                }}
                %
              </p>
              <p class="val_time">
                {{ equipAlarmDataCompose?.lineDownTimeFormatted }}
              </p>
            </div>

            <!-- Balanece Rate -->
            <div class="val_box">
              <p class="val_title">Balanece Rate %</p>
              <p class="val_value">
                {{
                  equipAlarmDataCompose?.balanceRate === null
                    ? "-"
                    : (equipAlarmDataCompose?.balanceRate * 100
                      | number: "0.2-2")
                }}
                %
              </p>
              <p class="val_time" style="width: 100%; height: 21px"></p>
            </div>
          </div>

          <!--  -->
          <div class="row flex_box">
            <!-- Down Time box -->
            <div class="dowm_time_group">
              <!-- Down Time Bar Chart -->
              <div class="chart_box">
                <p class="title">Down Time</p>

                <!-- <p *ngIf="isApiResEmptyArrShowNoData">No data</p> -->

                <div class="bar_chart" *ngFor="let item of barChartDataArr">
                  <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
                    No Data
                  </div>
                  <canvas
                    baseChart
                    height="280"
                    [datasets]="item.barChartData"
                    [labels]="item.barChartLabels"
                    [chartType]="item.barChartType"
                    [options]="item.barChartOptions"
                    [legend]="item.barChartLegend"
                    [colors]="item.donutColors"
                    [plugins]="item.plugins"
                  >
                  </canvas>
                </div>
              </div>

              <!-- Error Table -->
              <div class="table_box">
                <div class="no_data_mask" *ngIf="isApiResEmptyArrShowNoData">
                  No Data
                </div>
                <button
                  style="float: right; margin: 12px 12px 0 0"
                  (click)="onClickDownload('Equipment Data', apiParamsObj)"
                >
                  <img
                    class="download_button"
                    style="width: 16px"
                    src="../../../assets/img/btn_download.svg"
                    alt="download_button"
                  />
                </button>
                <table class="table table-striped table-dark">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th
                        scope="col"
                        *ngFor="let item of equipAlarmDataCompose.equipInfo"
                        matTooltipPosition="below"
                        [matTooltip]="'Error Code: ' + item.lastAlarmCode"
                      >
                        <!-- 設備燈號 => 1) Running - 綠色 2) Alarm - 紅色 3) Idle - 黃色 -->
                        <span
                          class="equip_light"
                          [ngClass]="checkEquipLightColor(item.status)"
                        ></span>
                        {{ item.equip }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container>
                      <tr *ngFor="let item of typeLineTableDataArr">
                        <td style="text-align: center">
                          {{ item.name }}
                        </td>

                        <td
                          style="text-align: center"
                          *ngFor="let itemChild of item.equipValArr"
                        >
                          {{ itemChild === "-" ? "-" : (itemChild | number) }}
                          <span *ngIf="item.name !== 'Error Record'">%</span>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Error Code Ranking Group -->
            <div class="error_code_ranking_group">
              <!-- Error Code Ranking 次數 -->
              <div class="error_code_ranking">
                <div class="defect_box_title">Error Code Ranking (次數)</div>

                <div class="select_tag model_select">
                  <label>Equip</label>
                  <select
                    (change)="
                      switchEquipOptionName($event.target.value, 'count')
                    "
                    [(value)]="apiParamsObj.countRankingSelectEquip"
                  >
                    <option
                      *ngFor="let item of rankingModelNameArray"
                      [value]="item.sourceId"
                      [selected]="
                        item.sourceId === apiParamsObj.countRankingSelectEquip
                      "
                    >
                      {{ item.equip }}
                    </option>
                  </select>
                </div>

                <div class="root_cause_box">
                  <div class="defect_data_list">
                    <div
                      class="defect_data_group"
                      *ngFor="let item of rankingListCountData; let i = index"
                    >
                      <p class="list_number">{{ i + 1 }}</p>
                      <div class="defect_cause_group">
                        <p
                          class="defect_data_cause"
                          *ngIf="apiParamsObj.countRankingSelectEquip === ''"
                        >
                          {{ dataFormatSvc.checkValDash(item.equip) }}
                        </p>
                        <p class="defect_data_cause">
                          {{ dataFormatSvc.checkValDash(item.alarmCode) }}
                        </p>
                      </div>
                      <div class="defect_num_group">
                        <p class="defect_data_percent">
                          {{ dataFormatSvc.checkValDash(item.percent) }}
                          <span>%</span>
                        </p>
                        <p class="defect_data_occurrence">
                          ({{ dataFormatSvc.checkValDash(item.count) }})
                        </p>
                      </div>
                      <div
                        class="red_bar"
                        [style]="'width:' + [item.percent] + '%'"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Error Code Ranking 時長 -->
              <div class="error_code_ranking">
                <div class="defect_box_title">Error Code Ranking (時長)</div>

                <div class="select_tag model_select">
                  <label>Equip</label>
                  <select
                    (change)="
                      switchEquipOptionName($event.target.value, 'time')
                    "
                    [(value)]="apiParamsObj.timeRankingSelectEquip"
                  >
                    <option
                      *ngFor="let item of rankingModelNameArray"
                      [value]="item.sourceId"
                      [selected]="
                        item.sourceId === apiParamsObj.timeRankingSelectEquip
                      "
                    >
                      {{ item.equip }}
                    </option>
                  </select>
                </div>

                <div class="root_cause_box">
                  <div class="defect_data_list">
                    <div
                      class="defect_data_group"
                      *ngFor="let item of rankingListTimeData; let i = index"
                    >
                      <p class="list_number">{{ i + 1 }}</p>
                      <div class="defect_cause_group">
                        <p
                          class="defect_data_cause"
                          *ngIf="apiParamsObj.timeRankingSelectEquip === ''"
                        >
                          {{ dataFormatSvc.checkValDash(item.equip) }}
                        </p>
                        <p class="defect_data_cause">
                          {{ dataFormatSvc.checkValDash(item.alarmCode) }}
                        </p>
                      </div>
                      <div class="defect_num_group">
                        <p class="defect_data_percent">
                          {{ dataFormatSvc.checkValDash(item.percent) }}
                          <span>%</span>
                        </p>
                        <p class="defect_data_occurrence">
                          ({{ dataFormatSvc.checkValDash(item.stayTime) }})
                        </p>
                      </div>
                      <div
                        class="red_bar"
                        [style]="'width:' + [item.percent] + '%'"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PermissionDenied>
    <div style="text-align: center; font-size: 48px">Permission denied</div>
  </ng-template>

  <!-- Auto Refresh -->
  <div class="refresh">
    <!-- Kanban Renewed & Snap Shot -->
    <div class="update_time">
      <p class="renewed">
        <span class="title">Kanban Renewed:</span>
        <span class="value">{{
          renewedTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
      <p class="snapshot">
        <span class="title">Snap Shot:</span>
        <span class="value">{{
          snapshotTime | date: "yyyy/MM/dd HH:mm a"
        }}</span>
      </p>
    </div>
    <button class="relaod_btn" (click)="reloadCurrentPageClick()">
      <img src="../../assets/img/btn_relaod.svg" alt="reload" />
    </button>
    <div class="refresh_btn">
      <label>
        <span class="text">Auto Refresh</span>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="pageAutoRefresh"
          (change)="switchPageAutoRefresh()"
        />
        <span class="btn-box">
          <span class="btn"></span>
        </span>
      </label>
    </div>
  </div>
</div>
