import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveMonoInformationService {

  customer = null;
  product = null;
  order = null;
  startTime = null;
  inputQty = null;
  defectQuantity = null;
  yieldRate = null;
  lowestYield = null;
  avgrty = null;

  model = null;

  criticalStation = null;

  constructor() { }
}
