import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// pipe
import { DatePipe, DecimalPipe } from '@angular/common';

import { DataSource } from '@angular/cdk/collections';
import { interval } from 'rxjs';
import { map, tap, } from 'rxjs/operators';

// angular-material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiService } from 'src/app/_services/apiSvc.service';

import { HttpClient, } from '@angular/common/http';
import { RealTimeClockService } from '../_services/real-time-clock.service';
import { SpinnerService } from '../_services/spinner.service';

// import variables
import { SORT_KEY, SORT_DIRECTION } from 'src/app/_variable/production-hours-types';


@Component({
  // 設定 changeDetection 屬性,當設定為 OnPush 時，
  // 只有在元件的 @Input 變更，且真正有變更時，才會進行變更偵測。
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-production-hours',
  templateUrl: './production-hours.component.html',
  styleUrls: ['./production-hours.component.scss'],
  // angular-material animations
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DatePipe, DecimalPipe]
})
export class ProductionHoursComponent implements OnInit, OnDestroy, AfterViewInit {


  upDateTimer;
  // 計時器 15秒 Call API 更新資料
  rxTimer$ = interval(60000).subscribe((data) => {
    this.isSvLoading.loading = true;
    this.getAllApiFn();
  });

  // dataSource: MatTableDataSource<UserData>;
  DATA = [];
  outDataSource = new MatTableDataSource(); // 初始化MatTable 表格資料
  usersData = [];
  expandedElement: any = {};

  // Select icon choices
  selected = false;
  newData = [];

  filterValue = '';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;


  // 第一層標題 - ( 要有子項目增加 'active' )
  // ['Part No.', 'MO No.', 'Start Time', 'Cycle Time', 'Act Time', 'Efficiency', 'Mo Qty', 'Output Qty']
  displayedColumns: string[] = ['action', 'product', 'order', 'startTime', 'cycleTime', 'actualTime', 'efficiency', 'targetOutput', 'output'];



  // 第二層標題
  // ['Section', 'Line', 'Side', 'Start Time', 'Act Time', 'Output Qty', 'Efficiency']
  innerDisplayedColumns: string[] = ['action', 'section', 'line', 'side', 'startTime', 'actualTime', 'output', 'efficiency'];


  isRuningOrder;
  spans = [];
  displayedColumnsArrIndex: string[] = ['product', 'order', 'startTime', 'cycleTime', 'actualTime', 'efficiency', 'targetOutput', 'output'];


  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    // pipe
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
    // 監測所有元件控制變更時機
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    // this.getAllApiFn();
  }

  ngAfterViewInit() {
    // 呼叫API => 將回傳陣列資料 傳進 MatTableDataSource 的 data
    this.getAllApiFn();

    this.outDataSource.paginator = this.paginator;
    this.outDataSource.sort = this.sort;
    this.outDataSource.sortingDataAccessor = (data, header) => data[header];
    this.outDataSource.filter = this.filterValue.trim().toString().toLowerCase();
  }

  ngOnDestroy() {
    clearTimeout(this.upDateTimer);
    this.rxTimer$.unsubscribe();
  }

  // 子層展開
  expendClick(element) {
    console.log('expendClick ******************');
    // console.log('oldelement:', oldelement);
    element.isExpanded = true;
    const oldelement = element;
    // this.saveElement = element;
    this.expandedElement = oldelement;

    // 更新 Sort 排序資料 index
    // this.changeDetectorRef.detectChanges();
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
      // ((table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index])
    );
  }

  // 子層 展開設定
  setChildExpanded(arr) {
    return arr.map((item) => {
      // set item expanded status
      item.isExpanded = false;
      item.selfID = item.product + '-' + item.order;
      return item;
    });
  }

  /**
   * merge row array  --- step 1
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.DATA.length;) {
      const currentValue = accessor(this.DATA[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.DATA.length; j++) {
        if (currentValue !== accessor(this.DATA[j])) {
          break;
        }
        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
    this.isSvLoading.loading = false;
  }

  // step 2  ---  merge row array
  getRowSpan(col, index) {
    const result = this.spans[index] && this.spans[index][col];
    // console.log('result: *********************', result);
    return result;
  }

  // table event sort排序 觸發排序事件 merge cell function --- step 3
  sortData(element) {
    console.log('sortData element:', element);
    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }

  // table event 換頁觸發 Paginate Change
  onPaginateChange(e) {
    console.log('onPaginateChange e:', e);
    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }

  // table event Search input filter
  applyFilter(event: Event) {
    this.isSvLoading.loading = true;
    this.filterValue = (event.target as HTMLInputElement).value;
    this.outDataSource.filter = this.filterValue.trim().toString().toLowerCase();

    this.isSvLoading.loading = true;
    setTimeout(() => {
      this.isSvLoading.loading = false;
    }, 900);
  }


  // subscribe 訂閱 陣列變動， 拿到 row data 更新排序的陣列
  subscribeDataSource() {
    return this.outDataSource.connect().pipe().subscribe((newSortArr) => {
      // 將新 Array 放回 this.DATA
      this.usersData = newSortArr;
    });
  }

  // null to '-'
  nullToDash(arr) {
    arr.map((item) => {
      for (const key in item) {
        if (item[key] === null || item[key] === '') { item[key] = '-'; }
      }
      return item;
    });
    return arr;
  }

  // 格式化時間
  formatsecToDDhhmmss(value: number) {
    if (value === 0) { return value + 's'; }
    if (value !== 0) {
      const timeInSeconds = Number(value);
      let formatTime = '';

      const hours = Math.floor((timeInSeconds / 3600));
      const minutes = Math.floor(timeInSeconds / 60 % 60);
      const seconds = Math.floor(timeInSeconds % 60);
      // console.log('hours:', hours, 'minutes:', minutes, 'seconds:', seconds);

      const showHour = timeInSeconds >= 3600;
      const showMin = 60 <= timeInSeconds && timeInSeconds < 3600;

      if (showHour) { return formatTime = hours + 'h ' + minutes + 'm ' + seconds + 's'; }
      if (showMin) { return formatTime = minutes + 'm ' + seconds + 's'; }
      return formatTime = seconds + 's';
    }
  }

  // Pipe 資料處理
  pipeTranslateInfo(arr: any) {
    return arr.map((x) => {
      const xx = { ...x };
      if (xx.startTime !== '-') {
        xx.startTime = this.datePipe.transform(xx.startTime, 'yyyy/M/dd HH:mm');
      }
      if (xx.cycleTime !== '-') {
        xx.formattedCycleTime = this.formatsecToDDhhmmss(xx.cycleTime);
        // console.log('xx.cycleTime:', xx.cycleTime);
      }

      if (xx.actualTime === '-') {
        // console.log('xx:', xx);
        xx.efficiency = '-';
      }
      if (xx.actualTime !== '-') {
        xx.formattedActualTime = this.formatsecToDDhhmmss(xx.actualTime);
        // console.log('xx.actualTime:', xx.actualTime);
      }

      if (xx.efficiency !== '-') {
        xx.formattedEfficiency = this.numberPipe.transform(+(xx.efficiency * 100), '0.2-2');
      }

      if (xx.targetOutput !== '-') {
        xx.formattedTargetOutput = this.numberPipe.transform(xx.targetOutput);
      }

      if (xx.output !== '-') {
        xx.formattedOutput = this.numberPipe.transform(xx.output);
      }

      this.nullToDash(xx.child);
      // 子層 Formater
      xx.child.map((i) => {
        if (i.startTime !== '-') {
          i.startTime = this.datePipe.transform(i.startTime, 'yyyy/M/dd HH:mm');
        }
        if (i.actualTime === '-') {
          // console.log('xx:', xx);
          i.efficiency = '-';
        }
        if (i.actualTime !== '-') {
          i.formattedActualTime = this.formatsecToDDhhmmss(i.actualTime);
        }
        if (i.output !== '-') {
          i.formattedOutput = this.numberPipe.transform(i.output);
        }
        if (i.efficiency !== '-') {
          i.formattedEfficiency = this.numberPipe.transform(+(i.efficiency * 100), '0.2-2');
        }
      });
      return xx;
    });
  }

  // select click icon
  selectClickIcon() {
    this.selected = !this.selected;
    // console.log(this.selected);
  }

  // select click checkbox
  selectCheckbox(e) {
    // console.log('e:checked ====>', e.target.value);
    // console.log('e:checked ====>', e.target.checked);
    const getValue = e.target.value;
    if (e.target.checked) {
      // e.target.checked = false;．
      this.displayedColumns.push(getValue);
      // Mapping 更新 index 排序
      this.displayedColumns.sort((a, b) => {
        return this.displayedColumnsArrIndex.indexOf(a) - this.displayedColumnsArrIndex.indexOf(b);
      });
      console.log('this.displayedColumns:', this.displayedColumns);
    }
    if (e.target.checked === false) {
      this.displayedColumns = this.displayedColumns.filter((item) => item !== getValue);
      // this.innerDisplayedColumns = this.innerDisplayedColumns.filter((item) => item !== getValue);
    }
  }

  // build res.child Arr to new MatTableDataSource
  buildChildArrToMatTableData(res) {
    // empty orginal arr
    this.usersData = [];

    // 將要 子層陣列 轉化成 MatTableDataSource
    res.forEach((item) => {
      const checkIsArray = item.child.length > 0 && Array.isArray(item.child);
      // console.log('item:', item);
      if (checkIsArray) {
        this.usersData = [
          ...this.usersData, { ...item, child: new MatTableDataSource(item.child) },
        ];
      } else {
        this.usersData = [...this.usersData, item];
      }
    });

    // 將組合好的 usersData 塞進初始化 MatTableDataSource 完成的 outDataSource
    this.outDataSource.data = this.usersData;

    // 更新 Sort 排序資料 index
    this.changeDetectorRef.detectChanges();
    this.innerTables.forEach(
      (table, index) => {
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
      }
    );

    return this.usersData;
  }

  // API＄
  getTableData$() {
    return this.apiSvc.getProductHourTableData().pipe(
      tap((res) => { console.log('row data =>', res); }),
      map((res) => this.nullToDash(res)),
      map((res) => this.pipeTranslateInfo(res)),
      map(res => this.setChildExpanded(res)),
      tap(res => {
        // console.log('getTableData$ res:', res);
      }),
    );
  }

  // Call API
  getAllApiFn() {
    return this.getTableData$().pipe(
      map((res => this.buildChildArrToMatTableData(res))),
      tap(res => {
        console.log('getAllApiFn -> ', res);
      }),
    ).subscribe((res) => {
      // this.subscribeDataSource();
      // changeDetectorRef detectChanges 監測 Dom 元素變更檢測
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.isSvLoading.loading = false;
      }, 800);
    });
  }


}

// ['action', 'MONo', 'product', 'Customer', 'Standard_Hr', 'Actual_Hr', 'moQuantity', 'Finish_Output'];
const testData: any[] = [
  {
    MONo: 'CDAVG05A001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVG05AS001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '6',
    Actual_Hr: '5',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVG06AS001',
    product: 'VRG006- 030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '6',
    Actual_Hr: '5',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVG05AS001',
    product: 'VRG005-030G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVG06AS001',
    product: 'VRG006-030G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '1',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVG05A001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11A005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11AL005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11AS003',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11AS005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11BL003',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDABC11BL005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDABC11BS005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDABC11CS005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDABC11DS005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDABC11ES005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDABC11FS005',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  }, {
    MONo: 'CDALB07AL002',
    product: 'PBC011-4C0G',
    Operators: '6', Customer: 'HITACHI',

    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVJ03A004',
    product: 'VEJ003-FV0G',
    Operators: '6', Customer: 'PARADIGM'
    ,
    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDAVJ03AL004',
    product: 'VEJ003-FV0G',
    Operators: '6', Customer: 'PARADIGM'
    ,
    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },
  {
    MONo: 'CDALB07002',
    product: 'VEJ003-FV0G',
    Operators: '6', Customer: 'PARADIGM'
    ,
    Standard_Hr: '6',
    Actual_Hr: '44',
    moQuantity: '100',
    Finish_Output: '20',
  },



  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '100',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '150',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  }, {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '60',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  }, {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  }, {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '50',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      }, {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  }, {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05144',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05102',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG15003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '66',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
];

const Arr = [
  {
    MONo: 'CDAVG05001',
    product: 'VRG005-030G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '100',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05002',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05003',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
  {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [],
  },
  {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [],
  },
  {
    MONo: 'CDAVG05054',
    product: 'VRG005-039G',
    Customer: 'IBM',
    Operators: '6',
    Standard_Hr: '44',
    Actual_Hr: '38',
    moQuantity: '180',
    Finish_Output: '20',
    children: [
      {
        MONo: 'CDAVG05112',
        product: 'VRG005-031G',
        Customer: 'IBM',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '28',
        moQuantity: '200',
        Finish_Output: '10',
      },
      {
        MONo: 'CDAVG05203',
        product: 'VRG005-035G',
        Customer: 'CCT',
        Operators: '65',
        Standard_Hr: '41',
        Actual_Hr: '100',
        moQuantity: '150',
        Finish_Output: '10',
      },
    ],
  },
];

const mergeCell = [
  {
    finishQuantity: 116,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL004',
    orderEndTime: null,
    orderStartTime: '2021-04-26T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-04-25T16:00:00.000Z',
    status: 3,
    side: 'A',
  },
  {
    finishQuantity: 110,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL004',
    orderEndTime: null,
    orderStartTime: '2021-04-30T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-04-25T16:00:00.000Z',
    status: 3,
    side: 'B',
  },
  {
    finishQuantity: 110,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL110',
    orderEndTime: null,
    orderStartTime: '2021-05-20T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-04-25T16:00:00.000Z',
    status: 3,
    side: 'A',
  },
  {
    finishQuantity: 110,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL110',
    orderEndTime: null,
    orderStartTime: '2021-05-25T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-04-25T16:00:00.000Z',
    status: 3,
    side: 'B',
  },
  {
    finishQuantity: 110,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL220',
    orderEndTime: null,
    orderStartTime: '2021-07-25T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-04-25T16:00:00.000Z',
    status: 3,
    side: 'A',
  },
  {
    finishQuantity: 110,
    moQuantity: 117,
    actualTime: 32.44,
    customer: 'AMAZON',
    ieStandardTime: 3,
    mStandardTime: null,
    operators: 3,
    order: 'CE4GJ02AL330',
    orderEndTime: null,
    orderStartTime: '2021-05-25T14:18:03.000Z',
    efficiency: 0.09247842170160296,
    product: 'RLOD-GEJ002-00BG-1',
    scheduleDay: '2021-06-25T16:00:00.000Z',
    status: 3,
    side: 'A',
  }
];

