
export enum SORT_KEY {
  sn = 'SN',
  product = 'PRODUCT',
  actProductionTime = 'ACTPRODUCTIONTIME',
  targetOutput = 'TARGETOUTPUT',
  input = 'INPUT',
  output = 'OUTPUT',
  achieveRate = 'ACHIEVERATE',
  outputYield = 'OUTPUTYIELD',
  efficiency = 'EFFICIENCY',
  avgrty = 'AVGRTY',
  overdueDate = 'OVERDUEDATE'
}

export enum SORT_DIRECTION {
  asc = '',
  desc = '-',
}
