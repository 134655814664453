import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from '../_services/apiSvc.service';

// 設定 Docker 變數 => API URL
import { environment } from '../../environments/environment';
import { RealTimeClockService } from '../_services/real-time-clock.service';

// cctv js
import * as JSMpeg from '../../../src/assets/js/cctv_js/jsmpeg.min.js';
import { SpinnerService } from '../_services/spinner.service';

@Component({
  selector: 'app-cctv',
  templateUrl: './cctv.component.html',
  styleUrls: ['./cctv.component.scss']
})

export class CctvComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('video_01') videoCam01: ElementRef;
  @ViewChild('video_02') videoCam02: ElementRef;

  // url1 = 'ws://10.2.103.181:5001/mystream';
  // url2 = 'ws://10.2.103.181:5001/mystream9';
  url1 = 'ws://10.10.142.17:80/mystream1';
  url2 = 'ws://10.10.142.17:80/mystream2';

  videoPlayer1;
  videoPlayer2;

  upDateTimer;

  popupShow = false;
  isActive = false;

  apiCctvIpsfUrl: string;
  cctvSmtWorkShop01: string;
  cctvAisleSideDoor02: string;

  videoPath = 'http://10.2.102.94:8003/ipfs/QmdEjQifbESuvUaWQvVDYMHeanbfruZKHpX7R3cs7wxpam';

  // canvas_video = document.getElementById('video_01');
  // url1 = 'ws://10.2.103.181:5001/mystream';

  popupData: any = {
    area: '',
    createdAt: '',
    fileName: '',
    imageHash: '',
    noInArea: '',
    occuranceTime: '',
  };

  cctvData: any = [
    {
      time: '2020/10/13 23:14:00',
      no: 1,
      area: 'SMT',
    },
    {
      time: '2020-10-21T08:00:31Z',
      no: 2,
      area: 'Entrance',
    },
    {
      time: '2020/08/07 13:20',
      no: 2,
      area: 'ATE',
    },
    {
      time: 'Tue Nov 17 2020 19:08:44',
      no: 3,
      area: 'SMT',
    }
  ];

  constructor(
    public isSvLoading: SpinnerService,
    public apiSvc: ApiService,
    public clock: RealTimeClockService,
    private httpClient: HttpClient,
  ) {
    this.isSvLoading.loading = true;
    this.apiCctvIpsfUrl = environment.apiCctvIpsfUrl;
    this.cctvSmtWorkShop01 = environment.cctvSmtWorkShop01;
    this.cctvAisleSideDoor02 = environment.cctvAisleSideDoor02;

    // this.JSMpegWS
    // console.log('this.JSMpegWS:', this.JSMpegWS);

    // this.cctvRtcServerUrl = environment.cctvRtcServerUrl;
    // console.log('this.cctvRtcServerUrl:', this.cctvRtcServerUrl);
    // this.cctvCamIpPortUrl = environment.cctvCamIpPortUrl;
    // console.log('this.cctvCamIpPortUrl:', this.cctvCamIpPortUrl);
  }

  ngOnInit(): void {
    this.getCctvHistoryData();

    this.upDateTimer = setInterval(() => {
      this.getCctvHistoryData();
    }, 1000 * 60);
  }

  ngAfterViewInit() {
    this.videoPlayer1 = new JSMpeg.Player(this.cctvSmtWorkShop01, {
      canvas: this.videoCam01.nativeElement,
      decodeFirstFrame: true,
      disableWebAssembly: false,
      throttled: false, // 这里设置为false，不然不触发onSourceCompleted事件
      chunkSize: 4 * 1024 * 1024,
      disableGl: false,
      audio: true,
      autoplay: true,
      loop: false,
      onSourceCompleted: () => {
      }
    });

    this.videoPlayer2 = new JSMpeg.Player(this.cctvAisleSideDoor02, {
      canvas: this.videoCam02.nativeElement,
      decodeFirstFrame: true,
      disableWebAssembly: false,
      throttled: false, // 这里设置为false，不然不触发 onSourceCompleted 事件
      chunkSize: 4 * 1024 * 1024,
      disableGl: false,
      audio: true,
      autoplay: true,
      loop: false,
      onSourceCompleted: () => {
      }
    });
  }

  ngOnDestroy() {
    // destroy 中斷 WebSocket 連線
    this.videoPlayer1.destroy();
    this.videoPlayer2.destroy();

    clearTimeout(this.upDateTimer);
  }

  showPopUp(getData) {
    this.popupData = { ...getData };
    this.popupShow = !this.popupShow;
  }

  trackByFn(index, item) {
    return item.time;
  }

  getCctvHistoryData() {
    this.apiSvc.getCctvHistoryData().pipe().subscribe(
      (res) => {
        console.log('res:', res);
        res.sort((a, b) => {
          return b.occuranceTime.localeCompare(a.occuranceTime);
        });

        this.cctvData = res;
        this.isSvLoading.loading = false;
      }
    );
  }

  sortData(typeName) {
    console.log('typeName:', typeName);
  }

}


// const canvas_video = document.getElementById('video_01');
// const url1 = 'ws://10.2.103.181:5001/mystream';
// const player1: any = new JSMpeg.Player(url1, { canvas: canvas_video });
